import React from 'react';
import classnames from 'classnames/bind';
import RGL from 'react-grid-layout';
import { Control, Controller, useFieldArray, useForm } from 'react-hook-form';

import { generateId } from 'services/string';

import { useCameras } from 'cameras/hooks/useCameras';
import { useDeployments } from 'hooks/api/useDeployments';
import { useGateways } from 'hooks/api/useGateways';
import { usePipelines } from 'pipelines/hooks/usePipelines';
import { useStreams } from 'streams/hooks/useStreams';
import { useTags } from 'tags/hooks/useTags';
import { useUpdateDashboard } from 'dashboards/hooks/useUpdateDashboard';

import Camera from 'types/camera';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';
import Stream, { StreamType } from 'types/stream';
import { FilesWidget } from 'types/dashboard_widget';
import { UseFilesParams } from 'files/hooks/useFiles';

import { Button } from 'components/Button/Button';
import { CameraSelect } from 'cameras/components/CameraSelect/CameraSelect';
import { DeploymentSelect } from 'deployments/components/DeploymentSelect/DeploymentSelect';
import { Field } from 'components/Field/Field';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { Input } from 'components/Input/Input';
import { Loader } from 'components/Loader/Loader';
import { MultiSelectInput } from 'components/MultiSelectInput/MultiSelectInput';
import { PipelineSelect } from 'pipelines/components/PipelineSelect/PipelineSelect';
import { Select } from 'components/Select/Select';
import { StreamSelect } from 'streams/components/StreamSelect/StreamSelect';

import { WidgetFieldValues, WidgetFormProps } from './DataForm';
import { useWidgetModal } from '../WidgetModal';
import styles from './FilesForm.module.scss';

const c = classnames.bind(styles);

const FILTER_OPTIONS = [
  { label: 'Pipeline', value: 'pipeline_ids' },
  { label: 'Gateway', value: 'gateway_ids' },
  { label: 'Deployment', value: 'deployment_ids' },
  { label: 'Stream', value: 'stream_ids' },
  { label: 'Camera', value: 'camera_ids' },
  { label: 'Node IDs', value: 'node_ids' },
  { label: 'Labels', value: 'tagged_with' },
] as const;

type QueryFilter = {
  entityType: string | undefined;
  // FIXME Refactor types & remove any
  entities: any[];
};

export type FilesWidgetFieldValues = WidgetFieldValues & {
  filters: QueryFilter[];
};

export function FilesForm({
  widget,
  dashboard,
  onIsDirtyChange,
  onUpdate,
}: WidgetFormProps<FilesWidget>) {
  const initialPipelineIds = widget?.options?.pipeline_ids ?? [];
  const { data: initialPipelines, isInitialLoading: isPipelinesLoading } =
    usePipelines(
      [`${widget?.id}_form_pipelines`],
      {
        pipeline_ids: initialPipelineIds,
      },
      {
        enabled: initialPipelineIds.length > 0,
      }
    );

  const initialGatewayIds = widget?.options?.gateway_ids ?? [];

  const { data: initialGateways, isInitialLoading: isGatewaysLoading } =
    useGateways(
      [`${widget?.id}_form_gateways`],
      {
        gateway_ids: initialGatewayIds,
      },
      {
        enabled: initialGatewayIds.length > 0,
      }
    );

  const initialDeploymentIds = widget?.options?.deployment_ids ?? [];

  const { data: initialDeployments, isInitialLoading: isDeploymentsLoading } =
    useDeployments(
      [`${widget?.id}_form_deployments`],
      {
        deployment_ids: initialDeploymentIds,
      },
      {
        enabled: initialDeploymentIds.length > 0,
      }
    );

  const initialStreamIds = widget?.options?.stream_ids ?? [];

  const { data: initialStreams, isInitialLoading: isStreamsLoading } =
    useStreams(
      [`${widget?.id}_form_streams`],
      {
        stream_ids: initialStreamIds,
      },
      {
        enabled: initialStreamIds.length > 0,
      }
    );

  const initialCameraIds = widget?.options?.camera_ids ?? [];

  const { data: initialCameras, isInitialLoading: isCamerasLoading } =
    useCameras(
      [`${widget?.id}_form_cameras`],
      {
        camera_ids: initialCameraIds,
      },
      {
        enabled: initialCameraIds.length > 0,
      }
    );

  const initialTagIds = widget?.options?.tagged_with ?? [];

  const { data: initialTags, isInitialLoading: isTagsLoading } = useTags({
    params: { tag_ids: initialTagIds },
    enabled: initialTagIds.length > 0,
  });

  const isFilterLoading =
    isPipelinesLoading ||
    isGatewaysLoading ||
    isDeploymentsLoading ||
    isStreamsLoading ||
    isCamerasLoading ||
    isTagsLoading;

  const { onClose } = useWidgetModal();

  const { mutate, isLoading: isUpdating } = useUpdateDashboard({
    onSuccess: onClose,
  });

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    register,
    reset,
  } = useForm<FilesWidgetFieldValues>({
    defaultValues: {
      name: widget?.name,
    },
  });

  React.useEffect(() => {
    const pipelineFilters = initialPipelines
      ? [
          {
            entityType: 'pipeline_ids',
            entities: initialPipelines.data,
          },
        ]
      : [];

    const gatewayFilters = initialGateways
      ? [{ entityType: 'gateway_ids', entities: initialGateways.data }]
      : [];

    const deploymentFilters = initialDeployments
      ? [
          {
            entityType: 'deployment_ids',
            entities: initialDeployments.data,
          },
        ]
      : [];

    const streamFilters = initialStreams
      ? [{ entityType: 'stream_ids', entities: initialStreams.data }]
      : [];

    const cameraFilters = initialCameras
      ? [{ entityType: 'camera_ids', entities: initialCameras.data }]
      : [];

    const nodeIdFilters = widget?.options?.node_ids
      ? [
          {
            entityType: 'node_ids',
            entities: widget.options.node_ids,
          },
        ]
      : [];

    const tagIdFilters = initialTags
      ? [
          {
            entityType: 'tagged_with',
            entities: initialTags.data.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
          },
        ]
      : [];

    const initialFilters = [
      ...pipelineFilters,
      ...gatewayFilters,
      ...deploymentFilters,
      ...streamFilters,
      ...cameraFilters,
      ...nodeIdFilters,
      ...tagIdFilters,
    ];

    reset({ filters: initialFilters });
  }, [
    reset,
    initialPipelines,
    initialGateways,
    initialDeployments,
    initialStreams,
    initialCameras,
    initialTags,
    widget?.options?.node_ids,
  ]);

  const {
    fields: filterFields,
    append: appendFilter,
    remove: removeFilter,
  } = useFieldArray<FilesWidgetFieldValues>({
    control,
    name: 'filters',
  });

  React.useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  React.useEffect(() => {
    onUpdate(isUpdating || isFilterLoading);
  }, [isUpdating, onUpdate, isFilterLoading]);

  function onSubmit({ name, filters }: FilesWidgetFieldValues) {
    if (!dashboard) {
      return;
    }

    const id = widget?.id || generateId();

    const layout: Omit<RGL.Layout, 'i'> =
      widget?.layout || dashboard.getNewWidgetLayout(4, 5);

    const pipelineIds = filters
      .filter((filter) => filter.entityType === 'pipeline_ids')
      .at(0)
      ?.entities.map((entity) => entity.id);

    const gatewayIds = filters
      .filter((filter) => filter.entityType === 'gateway_ids')
      .at(0)
      ?.entities.map((entity) => entity.id);

    const deploymentIds = filters
      .filter((filter) => filter.entityType === 'deployment_ids')
      .at(0)
      ?.entities.map((entity) => entity.id);

    const streamIds = filters
      .filter((filter) => filter.entityType === 'stream_ids')
      .at(0)
      ?.entities.map((entity) => entity.id);

    const cameraIds = filters
      .filter((filter) => filter.entityType === 'camera_ids')
      .at(0)
      ?.entities.map((entity) => entity.id);

    const nodeIds = filters
      .filter((filter) => filter.entityType === 'node_ids')
      .at(0)
      ?.entities.map((entity) => entity);

    const tagIds = filters
      .filter((filter) => filter.entityType === 'tagged_with')
      .at(0)
      ?.entities.map((entity) => entity);

    const options: UseFilesParams = {
      pipeline_ids: pipelineIds,
      gateway_ids: gatewayIds,
      deployment_ids: deploymentIds,
      stream_ids: streamIds,
      camera_ids: cameraIds,
      node_ids: nodeIds,
      tagged_with: tagIds,
    };

    mutate(dashboard.upsertWidget(new FilesWidget(id, name, options, layout)));
  }

  function getAvailableSelects() {
    return FILTER_OPTIONS.filter(
      (option) =>
        !new Set(
          getValues()
            .filters?.filter((filter) => filter.entityType)
            .map((filter) => filter.entityType) ?? []
        ).has(option.value)
    );
  }

  const filters = watch('filters');
  const canAdd = filters?.length < FILTER_OPTIONS.length;

  return (
    <form id="files-widget-form" onSubmit={handleSubmit(onSubmit)}>
      <Field label="Name" error={errors.name}>
        <Input
          {...register('name', {
            required: 'Please choose a name for this widget.',
          })}
          type="text"
          autoComplete="off"
          spellCheck="false"
        />
      </Field>
      <Field label="Filters" error={errors.filters}>
        <div className={c('filters')}>
          {isFilterLoading && <Loader size="small" text="Loading filters" />}
          {!isFilterLoading &&
            filterFields.map((field, index) => (
              <div className={c('filter')} key={field.id}>
                <div>
                  <Controller
                    name={`filters.${index}.entityType`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      const selectedValue = FILTER_OPTIONS.find(
                        (option) => option.value === value
                      );

                      return (
                        <Select
                          aria-label="Select type"
                          options={getAvailableSelects()}
                          onChange={(option) => {
                            setValue(`filters.${index}.entities`, []);
                            onChange(option?.value);
                          }}
                          value={selectedValue}
                        />
                      );
                    }}
                    rules={{ required: true }}
                  />
                </div>
                <div>
                  <FilterSelect
                    type={watch(`filters.${index}.entityType`)}
                    control={control}
                    index={index}
                  />
                </div>
                <IconButton
                  icon="cancel"
                  label="Remove filter"
                  onClick={() => removeFilter(index)}
                />
              </div>
            ))}
        </div>
        <div>
          <Button
            variant="secondary"
            size="small"
            onClick={() =>
              appendFilter({
                entityType: undefined,
                entities: [],
              })
            }
            disabled={!canAdd || isFilterLoading}
            disabledTooltip={
              isFilterLoading
                ? 'Loading Filters...'
                : 'Maximum amount of filters selected.'
            }
          >
            <Icon name="plus" size="xsmall" />
            <span>Add filter</span>
          </Button>
        </div>
      </Field>
    </form>
  );
}

type FilterSelectProps = {
  type?: string;
  control: Control<FilesWidgetFieldValues>;
  index: number;
};

function FilterSelect({ type, control, index }: FilterSelectProps) {
  const { data: tags } = useTags();
  const tagsSuggestions = tags
    ? tags.data.map(({ id, name }) => ({ label: name, value: id }))
    : [];

  return (
    <Controller
      name={`filters.${index}.entities`}
      control={control}
      render={({ field: { value, onChange } }) => {
        switch (type) {
          case 'pipeline_ids':
            return (
              <PipelineSelect
                value={value as Pipeline[]}
                onChange={onChange}
                isMulti
              />
            );
          case 'gateway_ids':
            return (
              <GatewaySelect
                value={value as Gateway[]}
                onChange={onChange}
                isMulti
              />
            );
          case 'deployment_ids':
            return (
              <DeploymentSelect
                value={value as Deployment[]}
                onChange={onChange}
                isMulti
              />
            );
          case 'stream_ids':
            return (
              <StreamSelect
                queryFilters={{
                  stream_types: [StreamType.FILE, StreamType.RTSP],
                }}
                value={value as Stream[]}
                onChange={onChange}
                isMulti
              />
            );
          case 'camera_ids':
            return (
              <CameraSelect
                value={value as Camera[]}
                onChange={onChange}
                isMulti
              />
            );
          case 'node_ids':
            return (
              <MultiSelectInput
                id="node-ids-files-filter"
                selected={value}
                onChange={onChange}
                allowCreate
              />
            );
          case 'tagged_with':
            return (
              <MultiSelectInput
                id="tag-ids-files-filter"
                options={tagsSuggestions}
                selected={value}
                onChange={onChange}
              />
            );
          default:
            return <Select options={[]} isDisabled />;
        }
      }}
      rules={{ required: true }}
    />
  );
}
