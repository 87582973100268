import React from 'react';

import { OptionalTooltip } from 'components/Tooltip/Tooltip';

import { LinkTypeButton, LinkTypeButtonProps } from './LinkTypeButton';
import { ButtonTypeButton, ButtonTypeButtonProps } from './ButtonTypeButton';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'ghost';

export type ButtonIntent = 'default' | 'danger' | 'success';

export type ButtonSize = 'large' | 'small' | 'xsmall';

export type CommonButtonProps = React.PropsWithChildren<{
  variant?: ButtonVariant | 'link' | 'none';
  intent?: ButtonIntent;
  size?: ButtonSize;
  autoFocus?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  loading?: boolean;
  asChild?: boolean;
}> &
  Partial<Pick<HTMLElement, 'id' | 'className' | 'title'>>;

export type ButtonProps = LinkTypeButtonProps | ButtonTypeButtonProps;

function Button(
  {
    // Button props
    onClick,
    type,
    // Link props
    to,
    href,
    download,
    // Common props
    variant = 'secondary',
    intent = 'default',
    disabledTooltip,
    ...commonProps
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <OptionalTooltip
      showTooltip={commonProps.disabled}
      content={disabledTooltip}
    >
      {to !== undefined || href !== undefined ? (
        <LinkTypeButton
          {...commonProps}
          to={to}
          href={href}
          download={download}
          variant={variant}
          intent={intent}
          ref={ref as React.Ref<HTMLAnchorElement>}
        />
      ) : (
        <ButtonTypeButton
          {...commonProps}
          onClick={onClick}
          type={type}
          variant={variant}
          intent={intent}
          ref={ref}
        />
      )}
    </OptionalTooltip>
  );
}

const ForwardRef = React.forwardRef<HTMLElement, ButtonProps>(Button);

export { ForwardRef as Button };
