import React from 'react';
import { useIntercom } from 'react-use-intercom';
import classnames from 'classnames/bind';

import { NavRoute } from 'application/types/routes';

import { useHasAccess } from 'hooks/useHasAccess';
import { useIsLumeoAdmin, useUser } from 'hooks/useAuth';

import { CreateButton } from 'components/CreateButton/CreateButton';
import { Icon } from 'components/Icon/Icon';

import { ApplicationSelect } from './components/ApplicationSelect';
import { NavUserMenu } from './components/NavUserMenu';
import { PrimaryLink } from './PrimaryLink';
import styles from './Nav.module.scss';

const c = classnames.bind(styles);

type NavProps = {
  className?: string;
};

export function Nav({ className }: NavProps) {
  const { boot, show } = useIntercom();

  const user = useUser();
  const [hasAccess] = useHasAccess();
  const isLumeoAdmin = useIsLumeoAdmin();

  React.useEffect(() => {
    (window as any).lumeo.showMessenger = show;
  }, [show]);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    boot({
      name: user.name,
      email: user.email,
      customLauncherSelector: '.console-intercom-launcher',
      hideDefaultLauncher: true,
      actionColor: '#1ea3dc',
    });
  }, [boot, user]);

  return (
    <div className={c('nav', className)}>
      <div className={c('nav-top')}>
        <ApplicationSelect />

        <div className={c('secondary-links')}>
          <a
            className={c('secondary-link')}
            href="https://docs.lumeo.com"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="documentation" size="small" />
            <span>Docs</span>
          </a>
          <button className={c('secondary-link')} id="up-help">
            <Icon name="help" size="small" />
            <span>Help</span>
          </button>
          <NavUserMenu />
        </div>
      </div>
      <div className={c('nav-bottom')}>
        <ul className={c('primary-links')}>
          {hasAccess('overview') && (
            <PrimaryLink route={NavRoute.OVERVIEW} icon="dashboard" end>
              Overview
            </PrimaryLink>
          )}

          {hasAccess('design') && (
            <PrimaryLink route={NavRoute.DESIGN} icon="pipeline">
              Design
            </PrimaryLink>
          )}

          {hasAccess('deploy_edit') && (
            <PrimaryLink route={NavRoute.DEPLOY} icon="deployment">
              Deploy
            </PrimaryLink>
          )}

          {hasAccess('monitor') && (
            <PrimaryLink route={NavRoute.MONITOR} icon="event">
              Monitor
            </PrimaryLink>
          )}

          <PrimaryLink route={NavRoute.SETTINGS} icon="settings">
            Settings
          </PrimaryLink>

          {isLumeoAdmin && (
            <PrimaryLink route={NavRoute.ADMIN} icon="account-key">
              Admin
            </PrimaryLink>
          )}
        </ul>

        <CreateButton />
      </div>
    </div>
  );
}
