import { useAuthentication } from './api/useAuthentication';

export function useUser() {
  const { user } = useAuthentication();
  return user;
}

export function useIsLumeoAdmin() {
  const { user } = useAuthentication();
  return Boolean(user?.isAdmin);
}
