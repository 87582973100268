import { QueryKey, useQueryClient } from '@tanstack/react-query';

import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';
import {
  useBillingMutation,
  UseBillingMutationOptions,
} from 'hooks/api/useBillingMutation';

import APIError from 'types/api_error';
import Stream from 'types/stream';

export function useCreateStream(
  queryKey: QueryKey,
  {
    onMutate,
    onSuccess,
    onError,
    onSettled,
    ...options
  }: UseBillingMutationOptions<Stream, APIError, Stream>
) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useBillingMutation(api.streams.create, {
    ...options,
    async onMutate(stream) {
      await queryClient.cancelQueries(queryKey);
      onMutate?.(stream);
      return updateQueriesData({
        queryClient,
        listQueryKey: queryKey,
        singleQueryKey: ['stream'],
        ids: [stream.id],
        updateData: stream,
      });
    },
    async onSuccess(stream, ...args) {
      await queryClient.cancelQueries(queryKey);
      const streams = queryClient.getQueryData<Stream[]>(queryKey);

      if (streams) {
        const updatedStreams = [...streams, stream];
        queryClient.setQueryData(queryKey, updatedStreams);
        queryClient.invalidateQueries(queryKey);
      }

      if (onSuccess) {
        onSuccess(stream, ...args);
      }
    },
    onError: (error, updatedStream, context) => {
      onError?.(error, updatedStream, context);

      context?.previousLists?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSettled(...args) {
      onSettled?.(...args);
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(['video-sources']);
    },
  });
}
