import Camera from 'types/camera';
import Stream, { StreamType } from 'types/stream';

export function getVideoSourceDisplayInfo(
  videoSource: Camera | Stream
): string | undefined {
  if (videoSource.source_type === 'camera') {
    const camera = videoSource as Camera;

    if (camera.ip_ext) {
      return camera.uri ?? camera.ip_ext;
    }

    return camera.ip_local;
  }

  const stream = videoSource as Stream;

  switch (stream.stream_type) {
    case StreamType.FILE:
      return undefined;
    default:
      return stream.uri;
  }
}
