import classNames from 'classnames/bind';

import * as Stepper from 'components/Stepper';

import styles from '../Form.module.scss';

const c = classNames.bind(styles);

export type DeploymentBulkOverrideFormStepperProps = {
  count: number;
  currentStep: number;
  onNavigate: (goToStep: number) => void;
};

export function DeploymentBulkOverrideFormStepper({
  currentStep,
  count,
  onNavigate,
}: DeploymentBulkOverrideFormStepperProps) {
  return (
    <Stepper.Root className={c('stepper')}>
      <Stepper.Step
        label="Select deployments"
        icon="gateway"
        onClick={() => onNavigate(1)}
        isActive={currentStep === 1}
        state={count > 0 ? 'valid' : undefined}
        index={1}
      />

      <Stepper.Step
        label="Configure overrides"
        disabled={count === 0}
        onClick={() => onNavigate(2)}
        isActive={currentStep === 2}
        index={2}
      />
    </Stepper.Root>
  );
}
