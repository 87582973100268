import { list, object, serializable } from 'serializr';

import AccountRole from './account_role';
import Organization from './organization';
import { Timestamped } from './timestamp';

export default class Account extends Timestamped {
  @serializable id?: string;
  @serializable name: string;
  @serializable email: string;
  @serializable(list(object(AccountRole)))
  roles: AccountRole[] = [{ name: 'user' }];
  @serializable image_url?: string;

  constructor(
    id: string | undefined,
    name: string,
    email: string,
    roles: AccountRole[],
    imageURL?: string,
    createdAt?: string,
    updatedAt?: string
  ) {
    super(createdAt, updatedAt);
    this.id = id;
    this.name = name;
    this.email = email;
    this.roles = roles;
    this.image_url = imageURL;
  }

  copy() {
    return new Account(
      this.id,
      this.name,
      this.email,
      this.roles,
      this.image_url,
      this.created_at,
      this.updated_at
    );
  }

  isBilling(organizationId: Organization['id']) {
    return this.roles.some(
      ({ name, organization_id }) =>
        name === 'billing' &&
        (organization_id === organizationId ||
          // FIXME: backward compatibility
          organization_id === undefined)
    );
  }

  get isAdmin() {
    return this.roles.some(
      ({ name, organization_id, application_id }) =>
        name === 'admin' && !application_id && !organization_id
    );
  }
}
