import { Route, Routes } from 'react-router-dom';

import { useAuthentication } from 'hooks/api/useAuthentication';

import { AuthForgotPassword } from 'views/Auth/ForgotPassword';
import { AuthLogin } from 'views/Auth/Login';
import { AuthResetPassword } from 'views/Auth/ResetPassword';
import { Invite } from 'views/Auth/Invite/Invite';
import { Register } from 'views/Register/Register';
import { SamlReturn } from 'views/Auth/SamlReturn';
import { VerifyEmail } from 'views/Auth/VerifyEmail';
import { Welcome } from 'views/Register/Welcome';

import { Loader } from 'components/Loader/Loader';

import { Root } from './Root';

export function RootContainer() {
  const { isLoading: isAuthenticating } = useAuthentication();

  if (isAuthenticating) {
    return <Loader text="Authenticating..." />;
  }

  return (
    <Routes>
      <Route path="*" element={<Root />} />

      {/* Auth routes */}
      <Route path="/register" element={<Register />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/login" element={<AuthLogin />} />
      <Route path="/forgot-password" element={<AuthForgotPassword />} />
      <Route path="/reset-password" element={<AuthResetPassword />} />
      <Route path="/saml_return" element={<SamlReturn />} />
      <Route path="/invite/*" element={<Invite />} />
    </Routes>
  );
}
