import React from 'react';
import classnames from 'classnames/bind';
import { CellProps, Column } from 'react-table';
import { useParams } from 'react-router-dom';
import { useUncontrolled } from '@mantine/hooks';

import Application from 'types/application';
import { ApplicationRole, isApplicationRole } from 'types/account_role';
import { OrganizationParams } from 'organizations/types/organization_params';

import { useOrganizationApplications } from 'hooks/api/useApplications';

import { Checkbox } from 'components/Checkbox/Checkbox';
import { Heading } from 'components/Heading/Heading';
import { Table } from 'components/Table/Table';

import styles from './ApplicationRolesInput.module.scss';

const c = classnames.bind(styles);

type ApplicationRoleRecord = Record<Application['id'], ApplicationRole[]>;

type ApplicationRolesInputProps = {
  value?: ApplicationRoleRecord;
  defaultValue?: ApplicationRoleRecord;
  onChange?: (value: ApplicationRoleRecord) => void;
};

/** Input table to collect application roles */
export function ApplicationRolesInput({
  value = {},
  defaultValue,
  onChange,
}: ApplicationRolesInputProps) {
  const { organizationID } = useParams<OrganizationParams>();
  const { data: applications, isLoading } =
    useOrganizationApplications(organizationID);

  const [roles, setRoles] = useUncontrolled({
    value,
    defaultValue,
    onChange,
  });

  if (!applications) {
    return null;
  }

  function handleRoleChange(
    id: Application['id'],
    { target: { value, checked } }: React.ChangeEvent<HTMLInputElement>
  ) {
    if (!isApplicationRole(value)) {
      return;
    }

    const updatedRoles = new Set<ApplicationRole>(roles[id]);

    if (checked) {
      updatedRoles.add(value);
    } else {
      updatedRoles.delete(value);
    }

    setRoles({ ...roles, [id]: [...updatedRoles] });
  }

  const columns: Column<Application>[] = [
    {
      id: 'workspace',
      Header: <strong>Workspace</strong>,
      Cell({ row }: CellProps<Application>) {
        return <Heading level="4">{row.original.name}</Heading>;
      },
    },
    ...['designer', 'deployer', 'monitor'].map((role) => ({
      Header: role,
      Cell({ row }: CellProps<Application>) {
        const { id } = row.original;
        return (
          <Checkbox
            key={`${id}-${role}`}
            value={role}
            className={c('checkbox')}
            id={`${role}-role-input-${id}`}
            checked={isApplicationRole(role) && roles[id]?.includes(role)}
            onChange={(event) => handleRoleChange(id, event)}
          />
        );
      },
    })),
  ];

  return (
    <Table<Application>
      className={c('table')}
      id="application-roles-input-table"
      label="application-roles"
      columns={columns}
      data={applications}
      loading={isLoading}
      sortBy={[{ id: 'workspace' }]}
    />
  );
}
