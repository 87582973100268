import React from 'react';

import Deployment from 'types/deployment';

import { usePipelines } from 'pipelines/hooks/usePipelines';

export function useDeploymentsPipelines(deployments?: Deployment[]) {
  const pipelineIds = React.useMemo(
    () => [
      ...new Set(
        deployments?.map(({ pipeline_id }) => pipeline_id).filter(Boolean)
      ),
    ],
    [deployments]
  );

  return usePipelines(
    {
      pipeline_ids: pipelineIds,
      limit: pipelineIds?.length,
    },
    { enabled: Boolean(pipelineIds && pipelineIds.length > 0) }
  );
}
