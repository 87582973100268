import React from 'react';

import Rete from 'rete';
import AreaPlugin from 'rete-area-plugin';
import ConnectionPathPlugin from 'rete-connection-path-plugin';
import ConnectionPlugin from 'rete-connection-plugin';
import ReactRenderPlugin from 'rete-react-render-plugin';
import ReadonlyPlugin from 'rete-readonly-plugin';

import Component from './rete/nodes/component';
import CustomNodeIDPlugin from './rete/plugins/custom_node_id';
import DisableSelfConnectingNodesPlugin from './rete/plugins/disable_self_connect';
import DynamicSocketsPlugin from './rete/plugins/dynamic_sockets';
import InheritOutputTypePlugin from './rete/plugins/inherit_output_type';
import NodeCompatibilityPlugin from './rete/plugins/compatibility_highlight';

import { ZOOM_MAX, ZOOM_MIN } from '../components/Editor/Editor';

export type UseReteOptions = {
  component: React.FunctionComponent;
  components: Array<typeof Component>;
  getNodeID: (exportType: string) => string;
  readonly?: boolean;
};

export function injectRete(
  container: HTMLElement,
  { component, components, getNodeID, readonly = false }: UseReteOptions
) {
  const id = 'demo@0.1.0';
  const editor = new Rete.NodeEditor(id, container);

  editor.bind('nodeupdated');

  editor.use(ConnectionPlugin);

  editor.use(ConnectionPathPlugin, {
    curve: ConnectionPathPlugin.curveBundle.beta(0.625),
    options: { vertical: false, curvature: 0.25 },
  });

  editor.use(AreaPlugin, {
    background: true,
    scaleExtent: { min: ZOOM_MIN, max: ZOOM_MAX },
    translateExtent: { width: 2000, height: 2000 },
  });

  editor.use(ReactRenderPlugin, { component });

  editor.use(ReadonlyPlugin, { enabled: readonly } as any);

  // Custom Plugins
  editor.use(NodeCompatibilityPlugin);
  editor.use(DisableSelfConnectingNodesPlugin);
  editor.use(CustomNodeIDPlugin, { getNodeID } as any);
  editor.use(DynamicSocketsPlugin);
  editor.use(InheritOutputTypePlugin);

  const engine = new Rete.Engine(id);

  components.forEach((ComponentClass: any) => {
    try {
      editor.register(new ComponentClass());
      engine.register(new ComponentClass());
    } catch (error) {
      // Do not register faulty dynamic nodes.
    }
  });

  return editor;
}
