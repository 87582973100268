import { NodeEditor } from 'rete';

import { CodeEditor } from 'pipelines/components/CodeEditor/CodeEditor';
import ReteInput, {
  ReteInputParams,
} from 'pipelines/services/rete/controls/input';

export default class ReteCodeEditor extends ReteInput {
  constructor(editor: NodeEditor, key: string, params: ReteInputParams) {
    super(editor, key, { ...params, inputType: 'textarea' });
  }

  get component() {
    return CodeEditor;
  }
}
