import React from 'react';
import { Buffer } from 'buffer';
import { useInterval } from '@mantine/hooks';

import Deployment from 'types/deployment';

import { useAPI } from 'hooks/api/useAPI';
import { useMqttClient } from 'hooks/useMqttClient';
import { OnMessageCallback, useMqttSubscribe } from 'hooks/useMqttSubscribe';

export type DeploymentLog = { ts: string; msg: string };
type MqttDeploymentLog = { logs: Array<{ msg: string }> };

export function useMqttDeploymentLogs({ id, gateway_id }: Deployment) {
  const client = useMqttClient();

  const { applicationID } = useAPI();

  const [messages, setMessages] = React.useState<
    Array<{ ts: string; parsed: MqttDeploymentLog; buffer: Buffer }>
  >([]);
  const [, startTransition] = React.useTransition();

  const enableMqttDeploymentLogs = React.useCallback(() => {
    if (!client || !applicationID) {
      return;
    }

    const payload = JSON.stringify({
      body: {
        Logs: {
          command_kind: 'enable_function_node_logs',
          timestamp: new Date().toISOString(),
        },
      },
    });

    const topic = `apps/${applicationID}/gateways/${gateway_id}/deployment/${id}/enable_logs`;
    // Remote enables logs for 5 minutes, `retain` needs to be `true`
    client.publish(topic, payload, { retain: true });
  }, [applicationID, client, gateway_id, id]);

  // Enable every 4.8 minutes
  const interval = useInterval(enableMqttDeploymentLogs, 4.8 * 60000);

  React.useEffect(() => {
    enableMqttDeploymentLogs();
    interval.start();
    return interval.stop;
  }, [enableMqttDeploymentLogs, interval]);

  const onMessage = React.useCallback<OnMessageCallback>(
    ({ message, buffer, packet }) => {
      let parsed;

      try {
        parsed = JSON.parse(message);
      } catch (error) {
        console.error('Failed to parse deployment log.');
      }

      if (!isMqttDeploymentLog(parsed)) return;

      const ts = new Date().toISOString();
      const newMessage = { ts, parsed, buffer };

      startTransition(() => {
        setMessages((previousMessages) => {
          const previousMessage = previousMessages.at(-1);

          if (!previousMessage) {
            return [newMessage];
          }

          const isDuplicate =
            previousMessage.buffer.compare(buffer) === 0 && packet.dup;

          if (isDuplicate) {
            return previousMessages;
          }

          const updatedMessages = [...previousMessages, newMessage];

          // Throw away older logs if array reaches length > 5000
          return updatedMessages.length > 5000
            ? updatedMessages.slice(-5000)
            : updatedMessages;
        });
      });
    },
    []
  );

  const subscription = React.useMemo(
    () => ({
      [`apps/${applicationID}/gateways/${gateway_id}/deployment/${id}/logs`]: {
        onMessage,
      },
    }),
    [applicationID, gateway_id, id, onMessage]
  );

  useMqttSubscribe(subscription);

  return messages.flatMap(({ ts, parsed: { logs } }) =>
    logs.map(({ msg }) => ({ ts, msg }))
  );
}

function isMqttDeploymentLog(logs: unknown): logs is MqttDeploymentLog {
  return logs !== null && typeof logs === 'object' && 'logs' in logs;
}
