import {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';

import APIError from 'types/api_error';

import { addErrorNotification } from 'services/notification';

export type MutationErrorConfig = {
  action?: string;
  entity?: string;
  onRetry?: (state?: any) => void;
};

/**
 * Extension of react-query `useMutation` that adds an error notification.
 */
export function useMutationWithError<TInput, TVariables = unknown, C = unknown>(
  mutationFn: MutationFunction<TInput, TVariables>,
  {
    action,
    entity,
    onRetry,
    onError,
    ...options
  }: MutationErrorConfig & UseMutationOptions<TInput, APIError, TVariables, C>
): UseMutationResult<TInput, APIError, TVariables> {
  const mutation = useMutation<TInput, APIError, TVariables, C>(mutationFn, {
    retry: false,
    ...options,
    onError: (error, ...args) => {
      addErrorNotification({ title: `Unable to ${action} ${entity}.`, error });

      onError?.(error, ...args);
    },
  });

  return mutation;
}
