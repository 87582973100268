import React from 'react';
import classNames from 'classnames/bind';

import { PillProps } from './Pill';
import styles from './Pill.module.scss';

const c = classNames.bind(styles);

export type PillListProps = {
  className?: string;
  component?: keyof JSX.IntrinsicElements;
  children?: React.ReactElement<PillProps> | React.ReactElement<PillProps>[];
};

export function PillList({
  className,
  component: Component = 'div',
  children,
}: PillListProps) {
  if (!children) {
    return null;
  }

  function renderPill(pill: React.ReactElement<PillProps>) {
    const ChildComponent =
      Component === 'ul' || Component === 'ol' ? 'li' : 'span';
    return (
      <ChildComponent className={c('pill-list-item')}>{pill}</ChildComponent>
    );
  }

  return (
    <Component className={c('pill-list', className)}>
      {Array.isArray(children)
        ? React.Children.map(children, renderPill)
        : renderPill(children)}
    </Component>
  );
}
