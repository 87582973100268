import React from 'react';
import classNames from 'classnames/bind';
import { deserialize } from 'serializr';
import { useForm } from 'react-hook-form';

import Dashboard from 'types/dashboard';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useUpdateDashboard } from 'dashboards/hooks/useUpdateDashboard';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import * as Dialog from 'components/Dialog';

import styles from './RenameDashboardDialog.module.scss';

const c = classNames.bind(styles);

export type RenameDashboardDialogProps = Pick<
  Dialog.RootProps,
  'open' | 'onOpenChange'
> & {
  dashboard: Dashboard;
};

type RenameDashboardFieldValues = {
  name: string;
};

export function RenameDashboardDialog({
  dashboard,
  open,
  onOpenChange,
}: RenameDashboardDialogProps) {
  const { data: currentPlan } = useCurrentPlan();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<RenameDashboardFieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      name: dashboard.name,
    },
  });

  const {
    isLoading: isRenaming,
    error,
    mutate: updateDashboard,
  } = useUpdateDashboard({
    onSuccess() {
      onOpenChange?.(false);
      reset();
    },
  });

  // TODO: missing permissions handling
  if (!currentPlan?.can_create_dashboards) {
    return null;
  }

  function handleOpenChange(open: boolean) {
    reset();
    onOpenChange?.(open);
  }

  function onSubmit({ name }: RenameDashboardFieldValues) {
    const updatedDashboard = deserialize(Dashboard, {
      ...dashboard,
      name,
    });

    updateDashboard(updatedDashboard);
  }

  return (
    <Dialog.Root
      className={c('dialog')}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Dialog.Title asChild>
        <Heading level="2">Rename dashboard</Heading>
      </Dialog.Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormErrorMessage error={error} />

        <Field
          id="dashboard-name"
          label="Dashboard name"
          error={errors.name}
          required
        >
          <Input
            {...register('name', {
              required: 'Please enter a name for this dashboard.',
            })}
            id="dashboard-name"
            type="text"
            autoComplete="off"
            spellCheck="false"
            placeholder={dashboard.name}
            autoFocus
          />
        </Field>

        <ButtonGroup>
          <Dialog.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
          <Button variant="primary" type="submit" loading={isRenaming}>
            Save
          </Button>
        </ButtonGroup>
      </form>
    </Dialog.Root>
  );
}
