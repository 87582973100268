import React from 'react';
import classnames from 'classnames/bind';
import { Column, usePagination, useRowSelect, useTable } from 'react-table';
import { Skeleton } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Deployment from 'types/deployment';
import { DeploymentPaginatedAPIResponse } from 'types/api';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useDeploymentsGateways } from 'deployments/hooks/useDeploymentsGateways';

import { Button } from 'components/Button/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Heading } from 'components/Heading/Heading';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { TableLabelsCell, getRowId } from 'components/Table/Table';
import { Text } from 'components/Text/Text';

import styles from '../Form.module.scss';

const c = classnames.bind(styles);

export function useDeploymentsFormSelectTable(
  queryResult: UseQueryResult<DeploymentPaginatedAPIResponse, APIError>,
  pageSize: number,
  defaultValue: Record<Deployment['id'], boolean>,
  onSelectAll: () => void
) {
  const { data: currentPlan, isSuccess } = useCurrentPlan();
  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);

  const { data: gateways, isLoading: isLoadingGateways } =
    useDeploymentsGateways(queryResult.data?.data);

  const columns = React.useMemo<Column<Deployment>[]>(
    () => [
      {
        accessor: 'id',
        Header({ getToggleAllRowsSelectedProps }) {
          return (
            <Checkbox
              {...getToggleAllRowsSelectedProps()}
              id="override-deployments_all-page"
            />
          );
        },
        Cell({ row: { original, getToggleRowSelectedProps } }) {
          return (
            <Checkbox
              {...getToggleRowSelectedProps()}
              id={`override-deployments_${original.id}`}
            />
          );
        },
        width: 0,
      },
      {
        accessor: 'name',
        Header() {
          return (
            <Text asChild>
              <label htmlFor="override-deployments_all-page">
                Select all deployments on this page
              </label>
            </Text>
          );
        },
        Cell({ row: { original } }) {
          const gateway = gateways?.data.find(
            ({ id }) => id === original.gateway_id
          );

          return (
            <label
              htmlFor={`override-deployments_${original.id}`}
              className={c('override-form-deployments-cell')}
            >
              <StatusIndicator size="small" status={original.state} />
              <Heading level="4">{original.name}</Heading>
              &nbsp;
              <Skeleton visible={isLoadingGateways}>
                <Text type="paragraph">
                  {isLoadingGateways ? 'Gateway name' : gateway?.name}
                </Text>
              </Skeleton>
            </label>
          );
        },
      },
      {
        accessor: 'tags',
        Header() {
          return (
            <Button variant="link" onClick={onSelectAll}>
              Select all deployments for pipeline
            </Button>
          );
        },
        Cell(props) {
          if (!canCreateTags) {
            return null;
          }

          return (
            <TableLabelsCell<Deployment>
              {...props}
              entity="deployments"
              listQueryKey={['deployments']}
              singleQueryKey={['deployment']}
              readOnly
            />
          );
        },
        width: 0,
      },
    ],
    [gateways, isLoadingGateways, canCreateTags, onSelectAll]
  );

  return useTable(
    {
      columns,
      data: queryResult.data?.data ?? [],
      manualPagination: true,
      pageCount: queryResult.data?.total_pages,
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
      initialState: {
        pageIndex: queryResult.data ? queryResult.data?.current_page : 1,
        pageSize,
        selectedRowIds: defaultValue,
      },
      getRowId,
    },
    usePagination,
    useRowSelect
  );
}
