import React from 'react';
import classnames from 'classnames/bind';

import { Icon, IconType } from 'components/Icon/Icon';

import styles from './AttributeList.module.scss';
import { OptionalTooltip } from 'components/Tooltip/Tooltip';
import { truncateMiddle } from 'services/string';

const c = classnames.bind(styles);

export type AttributeListItemProps = React.PropsWithChildren<{
  icon?: IconType;
  label: string;
  children: React.ReactElement;
}>;

const MAX_CONTENT_DISPLAY_LENGTH = 50;

export function AttributeListItem({
  icon,
  label,
  children,
}: AttributeListItemProps) {
  const isTruncated =
    typeof children === 'string' &&
    children.length > MAX_CONTENT_DISPLAY_LENGTH;
  const content =
    typeof children === 'string'
      ? truncateMiddle(children, MAX_CONTENT_DISPLAY_LENGTH)
      : children;

  return (
    <div className={c('item')}>
      <dt className={c('label')}>
        {icon && <Icon name={icon} size="small" />}
        <span className={c({ 'sr-only': icon })}>{label}</span>
      </dt>
      <OptionalTooltip content={content} showTooltip={isTruncated}>
        <dd className={c('value')}>{content}</dd>
      </OptionalTooltip>
    </div>
  );
}
