import React from 'react';
import classNames from 'classnames/bind';
import { CellProps, Column } from 'react-table';

import Category from 'types/category';
import Pipeline from 'types/pipeline';
import PipelineTemplate from 'types/pipeline_template';

import { generateId } from 'services/string';
import { useCreatePipeline } from 'pipelines/hooks/useCreatePipeline';
import { usePipelineTemplates } from 'pipelines/hooks/usePipelineTemplates';

import {
  CategorySelect,
  HighlightedCategoryFilter,
} from 'components/CategorySelect';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';
import { PaginatedTable } from 'components/Table/PaginatedTable';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Text } from 'components/Text/Text';

import styles from '../RapidDeploy.module.scss';

const c = classNames.bind(styles);

const PAGE_SIZE = 20;

const columns: Column<PipelineTemplate>[] = [
  {
    Cell({ row: { original } }: CellProps<PipelineTemplate>) {
      return (
        <div className={c('template-img-wrap')}>
          {original.gallery_img_url ? (
            <img
              className={c('template-img')}
              src={original.gallery_img_url}
              alt=""
            />
          ) : (
            <div className={c('template-img')}>Preview unavailable</div>
          )}
        </div>
      );
    },
    accessor: 'gallery_img_url',
  },
  {
    Cell({ row: { original } }: CellProps<PipelineTemplate>) {
      return (
        <div className={c('template-content')}>
          {original.categories.length > 0 && (
            <div className={c('template-categories')}>
              {original.categories.map(({ label, icon_name }) => (
                <Text
                  className={c('template-category')}
                  size="small"
                  key={label}
                  inline
                >
                  <Icon name={icon_name} size="small" />
                  <span>{label}</span>
                </Text>
              ))}
            </div>
          )}
          <Heading level="4" asChild>
            <strong>{original.name}</strong>
          </Heading>
          <Text type="paragraph">{original.description}</Text>
        </div>
      );
    },
    accessor: 'name',
  },
];

export type RapidDeployPipelineTemplatesListProps = {
  onSelect: (pipeline: Pipeline) => void;
};

export function RapidDeployPipelineTemplatesList({
  onSelect,
}: RapidDeployPipelineTemplatesListProps) {
  const [page, setPage] = React.useState(1);

  const [highlightedCategories, setHighlightedCategories] = React.useState<
    Category[]
  >([]);
  const [moreCategories, setMoreCategories] = React.useState<Category[]>([]);

  const categories = [...highlightedCategories, ...moreCategories];

  const queryResult = usePipelineTemplates(
    {
      page,
      limit: PAGE_SIZE,
      category_labels: categories.map(({ label }) => label),
    },
    { keepPreviousData: true }
  );

  const { mutate: createPipeline, isLoading: isCreating } = useCreatePipeline({
    onSuccess: onSelect,
  });

  // FIXME: Pipeline templates should also get a <QuerySearch /> but searching is not supported on API yet
  // https://app.shortcut.com/lumeo/story/10729

  const { isLoading } = queryResult;

  if (isLoading) {
    return <>Loading...</>;
  }

  function handleSelectTemplateClick({ name, definition }: PipelineTemplate) {
    createPipeline({
      name: `Pipeline ${generateId().substring(1, 7)} – ${name}`,
      definition,
    });
  }

  return (
    <div className={c('rapid-deploy')}>
      {isCreating && (
        <div className={c('templates-loader')}>
          <Loader text="Copying template to application..." />
        </div>
      )}

      <div className={c('filters')}>
        <HighlightedCategoryFilter
          id="rapid-deploy_categories-filter"
          value={highlightedCategories}
          onChange={(categories) => {
            setHighlightedCategories(categories);
            setPage(1);
          }}
        />
        <CategorySelect
          value={moreCategories}
          onChange={(values) => {
            setMoreCategories([...values]);
            setPage(1);
          }}
          queryFilters={{
            only_non_highlighted: true,
          }}
          isMulti
        />
      </div>

      {isLoading ? (
        <div className={c('table', 'full-width')}>
          <SkeletonList min={10} max={15} component={<LoaderRow />} />
        </div>
      ) : (
        <PaginatedTable<PipelineTemplate>
          className={c('table', 'full-width', 'templates')}
          id="rapid-deploy-templates"
          label="Templates"
          queryResult={queryResult}
          columns={columns}
          pageSize={PAGE_SIZE}
          page={page}
          onRowClick={handleSelectTemplateClick}
          onPageChange={setPage}
        />
      )}
    </div>
  );
}

function LoaderRow() {
  return (
    <div className={c('loader-table-row')}>
      <div className={c('loader-table-cell', 'auto')}>
        <div className={c('loader-image', 'skeleton-image')} />
      </div>

      <div className={c('loader-table-cell')}>
        <div className={c('template-content')}>
          <div className={c('template-categories')}>
            <Text
              className={c('template-category', 'skeleton-text')}
              size="small"
              asChild
            >
              <span aria-hidden="true">Basics</span>
            </Text>
          </div>
          <Heading level="4" asChild>
            <div className="skeleton-text" />
          </Heading>
          <Text type="paragraph">
            <div className="skeleton-text" />
            <div className="skeleton-text" />
          </Text>
        </div>
      </div>
    </div>
  );
}
