import React from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import classnames from 'classnames/bind';

import styles from './Tabs.module.scss';

const c = classnames.bind(styles);

function TabsButton(
  { children, disabled, asChild = true, ...props }: RadixTabs.TabsTriggerProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <RadixTabs.Trigger
      {...props}
      asChild={asChild}
      disabled={disabled}
      ref={ref}
    >
      <button className={c('tab-button', { disabled })}>{children}</button>
    </RadixTabs.Trigger>
  );
}

const forwardRef = React.forwardRef<
  HTMLButtonElement,
  RadixTabs.TabsTriggerProps
>(TabsButton);

export { forwardRef as TabsButton };
