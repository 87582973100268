import {
  QueryKey,
  UseMutationOptions,
  useMutation,
} from '@tanstack/react-query';

import { useAPI } from 'hooks/api/useAPI';

import Stream from 'types/stream';
import APIError from 'types/api_error';

import { useAppQueryClient } from 'hooks/useAppQueryClient';
import {
  updateQueriesData,
  UpdateQueriesDataContext,
} from 'services/update_queries_data';

export function useUpdateStream(
  queryKey: QueryKey,
  {
    onMutate,
    onError,
    onSettled,
    ...options
  }: UseMutationOptions<
    Stream,
    APIError,
    Stream,
    UpdateQueriesDataContext<Stream>
  > = {}
) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useMutation(api.streams.update, {
    ...options,
    onMutate: async (stream) => {
      await queryClient.cancelQueries(queryKey);
      onMutate?.(stream);

      return updateQueriesData({
        queryClient,
        listQueryKey: queryKey,
        singleQueryKey: ['stream'],
        ids: [stream.id],
        updateData: stream,
      });
    },
    onError: (error, updatedStream, context) => {
      onError?.(error, updatedStream, context);

      context?.previousLists?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSettled: (...args) => {
      onSettled?.(...args);
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(['video-sources']);
    },
  });
}
