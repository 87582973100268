const rtf = new (Intl as any).RelativeTimeFormat('en-US', { numeric: 'auto' });

/** @deprecated
 * Use {@link luxon} instead.
 */
export function relativeTimeFromNow(date?: string | number | Date): string {
  const now = Date.now();
  const timestamp = date ? +new Date(date) : now;
  const elapsed = now - timestamp;

  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  if (elapsed < msPerMinute) {
    return rtf.format(0, 'seconds');
  } else if (elapsed < msPerHour) {
    return rtf.format(-Math.floor(elapsed / msPerMinute), 'minutes');
  } else if (elapsed < msPerDay) {
    return rtf.format(-Math.floor(elapsed / msPerHour), 'hours');
  } else if (elapsed < msPerMonth) {
    return rtf.format(-Math.floor(elapsed / msPerDay), 'days');
  } else if (elapsed < msPerYear) {
    return rtf.format(-Math.floor(elapsed / msPerMonth), 'months');
  }

  return new Date(timestamp).toLocaleDateString('en-US');
}
