import React from 'react';

import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useAPI } from './api/useAPI';
import { useAuthenticatedQueries } from './api/useAuthenticatedQuery';

export type PlanNodes = {
  starterNodes: string[] | undefined;
  businessNodes: string[] | undefined;
};

export function usePlanIncludedNodes() {
  const { data: organization } = useCurrentOrganization();

  const api = useAPI();
  const planIDs = [
    process.env.REACT_APP_STARTER_PLAN_ID!,
    process.env.REACT_APP_BUSINESS_PLAN_ID!,
  ];

  const [
    {
      data: starterNodes,
      isInitialLoading: isLoadingStarterNodes,
      isSuccess: isStarterNodesSuccess,
      error: starterNodesError,
    },
    {
      data: businessNodes,
      isInitialLoading: isLoadingBusinessNodes,
      isSuccess: isBusinessNodesSuccess,
      error: businessNodesError,
    },
  ] = useAuthenticatedQueries(
    planIDs.map((planID) => {
      return {
        queryKey: ['included_nodes', planID],
        staleTime: Infinity,
        queryFn: () =>
          api.organizations.plans.listIncludedNodes(organization!.id, planID),
        enabled: Boolean(organization),
      };
    })
  );

  return React.useMemo(
    () =>
      [
        { starterNodes, businessNodes } as PlanNodes,
        {
          isLoading: isLoadingStarterNodes || isLoadingBusinessNodes,
          isSuccess: isStarterNodesSuccess || isBusinessNodesSuccess,
          error: starterNodesError || businessNodesError,
        },
      ] as const,
    [
      starterNodes,
      isLoadingStarterNodes,
      isStarterNodesSuccess,
      starterNodesError,
      businessNodes,
      isLoadingBusinessNodes,
      isBusinessNodesSuccess,
      businessNodesError,
    ]
  );
}
