import { ROICanvasType } from '../types';

export function isValidShape(shape: number[], type: ROICanvasType): boolean {
  if (type === 'polygon') {
    // At least 3 coordinates pairs
    return shape.length >= 3 * 2;
  }

  return shape.length >= 2 * 2;
}
