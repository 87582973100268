import classnames from 'classnames/bind';
import React from 'react';
import { OptionProps } from 'react-select';

import Stream from 'types/stream';

import { isNotNullOrUndefined } from 'services/nullable';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import { useDeployments } from 'hooks/api/useDeployments';
import { useStreams, UseStreamsFilterParams } from 'streams/hooks/useStreams';

import { Icon } from 'components/Icon/Icon';
import { Select, SelectOption, SelectProps } from 'components/Select/Select';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';

import styles from './StreamSelect.module.scss';

const c = classnames.bind(styles);

export type StreamSelectProps<IsMulti extends boolean = false> = Omit<
  SelectProps<Stream, IsMulti>,
  'queryResult'
> & {
  queryFilters?: UseStreamsFilterParams;
};

export function StreamSelect<IsMulti extends boolean = false>({
  placeholder = 'Stream',
  isLoading,
  queryFilters = {},
  components,
  ...props
}: StreamSelectProps<IsMulti>) {
  const [filters, setFilters] = React.useState<UseStreamsFilterParams>({});

  const {
    data,
    isLoading: isLoadingOptions,
    isSuccess,
  } = useStreams(
    ['stream-select-streams'],
    { ...queryFilters, ...filters },
    {
      keepPreviousData: true,
    }
  );

  const { data: deployments, isLoading: isLoadingDeployments } = useDeployments(
    {
      deployment_ids: data?.data
        .map(({ deployment_id }) => deployment_id)
        .filter(isNotNullOrUndefined),
    },
    {
      keepPreviousData: true,
      enabled: isSuccess,
    }
  );

  const handleInputChange = useDebouncedCallback(
    (value: string) => {
      setFilters((previousFilters) => ({
        ...previousFilters,
        stream_names: [value],
      }));
    },
    [],
    { wait: 100 }
  );

  function Option({ data, children, ...rest }: OptionProps<Stream, IsMulti>) {
    const { name, node, deployment_id, status, source } = data;
    const deployment = deployments?.data.find(({ id }) => id === deployment_id);

    return (
      <SelectOption {...rest} data={data}>
        <div>
          <div className={c('stream-select-container')}>
            <StatusIndicator status={status} size="xsmall" />
            <div>
              <Text>{name}</Text>
              {source === 'pipeline_stream' && (
                <Text size="small">
                  <Icon name="deployment" size="small" />
                  <span>
                    {isLoadingDeployments
                      ? 'Loading...'
                      : (deployment?.name ?? 'Unknown deployment')}
                  </span>
                  {node && <span>&nbsp;/ {node}</span>}
                </Text>
              )}
            </div>
          </div>
        </div>
      </SelectOption>
    );
  }

  return (
    <Select
      {...props}
      options={data?.data}
      totalElements={data?.total_elements}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      getOptionLabel={({ name }) => name}
      getOptionValue={({ id }) => id}
      isLoading={isLoading || isLoadingOptions}
      components={{ Option, ...components }}
    />
  );
}
