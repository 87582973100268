import React from 'react';
import classnames from 'classnames/bind';

import { Heading } from 'components/Heading/Heading';

import styles from './PipelineTemplateCard.module.scss';

const c = classnames.bind(styles);

export function PipelineTemplateCardSkeleton() {
  return (
    <div className={c('wrap')}>
      <div className={c('content')}>
        <div className={c('template', 'skeleton')} aria-label="Loading...">
          <Heading level="3" className={c('template-name', 'skeleton-text')} />

          <div className={c('template-img-wrap')}>
            <div
              className={c('template-img', 'skeleton-image')}
              style={{ '--aspect-ratio': '4 / 3' } as React.CSSProperties}
            />
          </div>

          <span className={c('template-description', 'skeleton-text')} />
        </div>
      </div>
    </div>
  );
}
