import classNames from 'classnames/bind';
import styles from './SeparatorWithLabel.module.scss';

const c = classNames.bind(styles);

export type SeparatorWithLabelProps = {
  label: string;
};

export function SeparatorWithLabel({ label }: SeparatorWithLabelProps) {
  return (
    <div className={c('wrapper')}>
      <hr className={c('line')} />
      <span>{label}</span>
      <hr className={c('line')} />
    </div>
  );
}
