import { UseMutationOptions } from '@tanstack/react-query';

import Camera from 'types/camera';

import { useAPI } from 'hooks/api/useAPI';
import { useAppQueryClient } from 'hooks/useAppQueryClient';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';

export function useDeleteCamera(
  camera: Camera,
  { onMutate, onError, onSettled, ...options }: UseMutationOptions = {}
) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete this camera?',
    () => api.cameras.delete(camera.id),
    {
      ...options,
      onMutate: async () => {
        await queryClient.cancelQueries(['cameras']);

        const cameras = queryClient.getQueryData<Camera[]>(['cameras']);

        if (cameras) {
          const updatedCameras = cameras.filter(({ id }) => id !== camera.id);
          queryClient.setQueryData(['cameras'], updatedCameras);
        }

        if (camera.gateway_id) {
          await queryClient.cancelQueries([
            'linked-cameras',
            camera.gateway_id,
          ]);

          const linkedCameras = queryClient.getQueryData<Camera[]>([
            'linked-cameras',
            camera.gateway_id,
          ]);

          if (linkedCameras) {
            const updatedLinkedCameras = linkedCameras.filter(
              (linkedCamera) => linkedCamera.id !== camera.id
            );
            queryClient.setQueryData(
              ['linked-cameras', camera.gateway_id],
              updatedLinkedCameras
            );
          }
        }

        if (onMutate) {
          onMutate();
        }

        return { previousCameras: cameras };
      },
      onError: (error, deletedCamera, context) => {
        if (context?.previousCameras) {
          queryClient.setQueryData(['cameras'], context.previousCameras);
        }

        if (onError) {
          onError(error, deletedCamera, context);
        }
      },
      onSettled: (...args) => {
        queryClient.invalidateQueries(['cameras']);

        if (camera?.gateway_id) {
          queryClient.invalidateQueries(['linked-cameras', camera.gateway_id]);
        }

        if (onSettled) {
          onSettled(...args);
        }
      },
    }
  );
}
