import { useQueryClient } from '@tanstack/react-query';

import Deployment, { DeploymentState } from 'types/deployment';
import { OffsetPaginated } from 'types/pagination';

import {
  OnMessagePayload,
  SubscriptionMap,
  useMqttSubscribe,
} from 'hooks/useMqttSubscribe';
import { isNotNullOrUndefined } from 'services/nullable';
import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';

export type MqttDeploymentState = {
  state: DeploymentState;
  state_details: string | null;
};

export function useMqttDeploymentsState(ids: Deployment['id'][]) {
  const queryClient = useQueryClient();
  const { applicationID } = useAPI();

  const invalidate = useDebouncedCallback(
    (deploymentId: string) => {
      queryClient.invalidateQueries(['deployments']);
      queryClient.invalidateQueries(['deployment', deploymentId]);
    },
    [queryClient]
  );

  async function onMessage({ topic, message }: OnMessagePayload) {
    const [, , , deploymentId] = topic.split('/');
    let mqttState;

    try {
      mqttState = JSON.parse(message);
    } catch (error) {
      console.error('Failed to parse mqtt message.');
    }

    if (!isMqttDeploymentState(mqttState)) {
      return;
    }

    let isStaleListQuery = false;
    let isStaleSingleQuery = false;

    const listQueries = queryClient.getQueriesData<OffsetPaginated<Deployment>>(
      ['deployments']
    );

    const singleQueries = queryClient.getQueriesData<Deployment>([
      'deployment',
      deploymentId,
    ]);

    if (listQueries.length) {
      const listQueryStates = listQueries
        .map(([queryKey]) =>
          queryClient.getQueryState<OffsetPaginated<Deployment>>(queryKey)
        )
        .filter(isNotNullOrUndefined);

      if (listQueryStates.length) {
        const { data: latestDeployments } = listQueryStates.reduce(
          (latest, current) =>
            latest.dataUpdatedAt > current.dataUpdatedAt ? latest : current
        );

        if (!latestDeployments || !latestDeployments.data) return;

        isStaleListQuery = latestDeployments.data.some(
          ({ id, state, state_details }) =>
            id === deploymentId &&
            (state !== mqttState.state ||
              state_details !== mqttState.state_details)
        );
      }
    }

    if (singleQueries.length) {
      const singleQueryStates = singleQueries
        .map(([queryKey]) => queryClient.getQueryState<Deployment>(queryKey))
        .filter(isNotNullOrUndefined);

      if (singleQueryStates.length) {
        const { data: latestDeployment } = singleQueryStates.reduce(
          (latest, current) =>
            latest.dataUpdatedAt > current.dataUpdatedAt ? latest : current
        );

        if (!latestDeployment) return;

        isStaleSingleQuery =
          latestDeployment.state !== mqttState.state ||
          latestDeployment.state_details !== mqttState.state_details;
      }
    }

    if (isStaleListQuery || isStaleSingleQuery) {
      await queryClient.cancelQueries(['deployments']);
      await queryClient.cancelQueries(['deployment', deploymentId]);

      updateQueriesData<Deployment>({
        queryClient,
        listQueryKey: ['deployments'],
        singleQueryKey: ['deployment'],
        ids: [deploymentId],
        updateData(deployment) {
          const updatedDeployment = deployment.copy();
          updatedDeployment.state = mqttState.state;
          updatedDeployment.state_details = mqttState.state_details;
          return updatedDeployment;
        },
      });

      invalidate(deploymentId);
    }
  }

  const subscriptions: SubscriptionMap = {};

  if (applicationID) {
    for (const id of ids) {
      const topic = `apps/${applicationID}/deployments/${id}/state_with_details`;
      subscriptions[topic] = { onMessage };
    }
  }

  useMqttSubscribe(subscriptions);
}

function isMqttDeploymentState(state: unknown): state is MqttDeploymentState {
  return (
    state !== null &&
    typeof state === 'object' &&
    'state' in state &&
    typeof state.state === 'string'
  );
}
