import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames/bind';
import { Menu, MenuDropdownProps } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';

import Pipeline from 'types/pipeline';

import downloadFile from 'services/download_file';

import { useAppRedirect } from 'hooks/useAppRedirect';
import { useCreatePipeline } from 'pipelines/hooks/useCreatePipeline';
import { useDeletePipeline } from 'hooks/api/usePipeline';

import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { Text } from 'components/Text/Text';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { usePipelineDetailStateOptional } from 'pipelines/context/pipeline_detail_view';
import styles from './Actions.module.scss';

const c = classnames.bind(styles);

export type PipelineActionsProps = Pick<MenuDropdownProps, 'children'> & {
  pipeline?: Pipeline;
};

export type UpdatePipelineLocationState = {
  updateDeployment?: string;
};

export function PipelineActions({ pipeline, children }: PipelineActionsProps) {
  const redirect = useAppRedirect();
  const { copied: isCopied, copy } = useClipboard();

  const pipelineState = usePipelineDetailStateOptional();

  const { mutate: createPipeline, isLoading } = useCreatePipeline({
    onSuccess: (pipeline) => {
      redirect(`/design/pipelines/${pipeline.id}`);
    },
  });

  const [requestDelete] = useDeletePipeline(pipeline, {
    onSuccess: () => redirect('/design/pipelines'),
  });

  if (!pipeline || !pipeline.hasId()) {
    return null;
  }

  function downloadPipeline() {
    if (!pipeline) {
      return;
    }

    downloadFile(
      `lumeo-pipeline_${pipeline.name.replaceAll(' ', '_')}.json`,
      new Blob([JSON.stringify(pipeline.definition)], {
        type: 'application/json',
      })
    );
  }

  function duplicatePipeline() {
    if (!pipeline) {
      return null;
    }

    createPipeline({
      name: `${pipeline.name} copy`,
      definition: pipeline.definition,
    });
  }

  function copyID() {
    if (!pipeline?.id) {
      return;
    }

    copy(pipeline.id);
  }

  return (
    <div className={c('more')}>
      <Tooltip content="Copied ID to clipboard!" open={isCopied}>
        <div>
          <Menu position="bottom-end" withinPortal>
            <Menu.Target>
              <IconButton
                icon="more-vertical"
                label="Reveal more actions"
                size="small"
                variant="tertiary"
              />
            </Menu.Target>

            <Menu.Dropdown>
              {children}
              {/* FIXME: With Mantine upgrade, move icons into leftSection prop */}
              <Menu.Item onClick={() => downloadPipeline()}>
                <Icon name="export" size="small" />
                <span>Export as JSON</span>
              </Menu.Item>
              <Menu.Item onClick={() => duplicatePipeline()}>
                <Icon name="copy" size="small" />
                <span>Duplicate</span>
              </Menu.Item>
              <Menu.Item onClick={copyID}>
                <Icon name="info" size="small" />
                <span>Copy ID</span>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                onClick={() => requestDelete()}
                className="theme danger"
                disabled={pipelineState?.isLocked}
              >
                <Icon name="delete" size="small" />
                <span>Delete pipeline</span>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </Tooltip>

      <ReactModal
        isOpen={isLoading}
        overlayClassName={c('overlay')}
        className={c('overlay-content')}
      >
        <div className="loading-spinner is-small"></div>
        <Text className="loading-spinner-message">Duplicating pipeline...</Text>
      </ReactModal>
    </div>
  );
}
