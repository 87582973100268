import React from 'react';
import classNames from 'classnames/bind';
import { useUncontrolled } from '@mantine/hooks';

import { Icon, IconType } from 'components/Icon/Icon';
import { OptionalTooltip } from 'components/Tooltip/OptionalTooltip';

import styles from './ToggleSelect.module.scss';

const c = classNames.bind(styles);

export type ToggleSelectOption<TData, TValue = string> = {
  value: TValue;
  label: string;
  icon?: IconType;
  description?: string;
  data: TData;
};

export type ToggleSelectProps<TData, TValue = string> = {
  id: string;
  options: ToggleSelectOption<TData, TValue>[];
  defaultValue?: ToggleSelectOption<TData, TValue>[];
  value?: ToggleSelectOption<TData, TValue>[];
  onChange?: (value: ToggleSelectOption<TData, TValue>[]) => void;
};

export function ToggleSelect<TData, TValue = string>({
  id,
  value,
  defaultValue,
  options,
  onChange,
}: ToggleSelectProps<TData, TValue>) {
  const [values, setValues] = useUncontrolled({
    value,
    defaultValue,
    finalValue: [],
    onChange,
  });

  return (
    <fieldset className={c('group')}>
      {options.map((option) => {
        const { label, value, icon, description } = option;

        return (
          <OptionalTooltip
            content={description}
            showTooltip={Boolean(description)}
            key={label}
          >
            <div className={c('item-wrap')} key={String(value)}>
              <input
                id={`${id}_${label}`}
                className={c('item-input')}
                type="checkbox"
                name={id}
                onChange={(event) => {
                  if (event.target.checked) {
                    setValues([...values, option]);
                    return;
                  }

                  const updatedSelection = values.filter(
                    (option) => option.label !== label
                  );
                  setValues(updatedSelection);
                }}
                checked={values.some((category) => category.label === label)}
              />
              <label className={c('item')} htmlFor={`${id}_${label}`}>
                {icon && <Icon className={c('item-icon')} name={icon} />}
                <span className={c('item-label')}>{label}</span>
                <Icon
                  name="check"
                  className={c('active-marker')}
                  size="small"
                />
              </label>
            </div>
          </OptionalTooltip>
        );
      })}
    </fieldset>
  );
}
