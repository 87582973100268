import React from 'react';
import { FormProvider, useFormState } from 'react-hook-form';

import Pipeline from 'types/pipeline';

import { ActivePipelineContext } from 'pipelines/context/active_pipeline';
import { useDeploymentFormContent } from 'deployments/components/Form/useDeploymentFormContent';
import { type VideoSource } from 'hooks/api/useVideoSources';

import {
  DeploymentFormContent,
  DeploymentFormContentRef,
} from 'deployments/components/Form/Content';

type RapidDeployConfigurationFormContentProps = {
  videoSource: VideoSource;
  pipeline: Pipeline;
  onIsValidChange: (isValid: boolean) => void;
};

function RapidDeployConfigurationFormContent(
  {
    videoSource,
    pipeline,
    onIsValidChange,
  }: RapidDeployConfigurationFormContentProps,
  ref: React.ForwardedRef<DeploymentFormContentRef>
) {
  const formMethods = useDeploymentFormContent(
    undefined,
    videoSource,
    pipeline
  );

  const { isValid } = useFormState({ control: formMethods.control });

  React.useEffect(() => {
    onIsValidChange(isValid);
  }, [isValid, onIsValidChange]);

  return (
    <ActivePipelineContext.Provider value={pipeline}>
      <FormProvider {...formMethods}>
        <DeploymentFormContent videoSource={videoSource} ref={ref} />
      </FormProvider>
    </ActivePipelineContext.Provider>
  );
}

const ForwardRef = React.forwardRef<
  DeploymentFormContentRef,
  RapidDeployConfigurationFormContentProps
>(RapidDeployConfigurationFormContent);

export { ForwardRef as RapidDeployConfigurationFormContent };
