import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  useMutation,
} from '@tanstack/react-query';
import { Userpilot } from 'userpilot';

import Account from 'types/account';
import APIError from 'types/api_error';

import { UpdatePasswordParams } from 'account/components/ChangePasswordForm/ChangePasswordForm';
import { UpdateAccountParams } from 'settings/views/Account/Account';
import { useAuthentication } from 'hooks/api/useAuthentication';
import { useAppQueryClient } from 'hooks/useAppQueryClient';

import { useAPI } from './useAPI';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';

export function useAccount({
  onSuccess,
  enabled: defaultEnabled,
  ...options
}: UseQueryOptions<Account | undefined, APIError, Account | undefined> = {}) {
  const { userID, setUser } = useAuthentication();

  const api = useAPI();

  const isEnabled =
    typeof defaultEnabled !== 'undefined'
      ? defaultEnabled && Boolean(userID)
      : Boolean(userID);

  return useAuthenticatedQuery<Account | undefined>(
    ['account'],
    () => {
      if (!userID) {
        return Promise.reject('Not authenticated.');
      }

      return api.accounts.read(userID);
    },
    {
      ...options,
      enabled: isEnabled,
      staleTime: Infinity,
      select(account) {
        if (!account) {
          return account;
        }

        if (
          Array.isArray(account.roles) &&
          account.roles.every((role) => typeof role === 'string')
        ) {
          account.roles = account.roles.map((name) => ({ name })) as any;
        }

        return account;
      },
      onSuccess: (account) => {
        if (onSuccess) {
          onSuccess(account);
        }

        if (account?.id) {
          setUser?.(account);

          Userpilot.identify(account.id, {
            name: account.name,
            email: account.email,
            created_at: account.created_at,
            roles: account.roles,
            isBilling: account.roles.some(({ name }) => name === 'billing'),
          });
        }
      },
    }
  );
}

export function useUpdateAccount({
  onSettled,
  onSuccess,
  ...options
}: UseMutationOptions<
  Account,
  APIError,
  UpdateAccountParams
> = {}): UseMutationResult<Account, APIError, Partial<Account>> {
  const api = useAPI();
  const queryClient = useAppQueryClient();
  const { userID } = useAuthentication();

  return useMutation(
    (params) => {
      if (!userID) {
        return Promise.reject('Not authenticated.');
      }

      return api.accounts.update({
        id: userID,
        ...params,
      });
    },
    {
      ...options,
      onSuccess: (account, ...args) => {
        queryClient.setQueryData(['account', account.id], account);

        if (onSuccess) {
          onSuccess(account, ...args);
        }
      },
      onSettled: (...args) => {
        if (onSettled) {
          onSettled(...args);
        }

        queryClient.invalidateQueries(['account']);
      },
    }
  );
}

export function useUpdateAccountPassword(
  options: UseMutationOptions<Account, APIError, UpdatePasswordParams> = {}
) {
  const api = useAPI();
  const { userID } = useAuthentication();

  return useMutation((params) => {
    if (!userID) {
      return Promise.reject('Not authenticated.');
    }

    return api.accounts.updatePassword(userID, params);
  }, options);
}
