import Component, { ComponentType } from './component';
import type { DockCategory } from './types';

export class NodeCategory {
  key: string;
  theme: string;
  name: string;
  components: ComponentType[] = [];

  constructor(name: string, theme: string) {
    this.key = name.toLocaleLowerCase();
    this.name = name;
    this.theme = theme;
  }

  addComponent(component: ComponentType, name: string) {
    if (this.components.some((cmp) => cmp.name === name)) {
      return;
    }

    this.components.push(component);
  }

  hasComponent(component: typeof Component): boolean {
    let categoryKey = component.category;

    // FIXME: Remove support for duplicate categories with different keys
    if (!(component.category in nodeCategories)) {
      categoryKey = DEPRECATED_KEY_MAP[component.category]!;
    }

    return this.key === categoryKey;
  }

  hasComponentWithName(componentName: string): boolean {
    return this.components.some((cmp) => cmp.name === componentName);
  }

  getComponentByName(componentName: string): typeof Component | undefined {
    return this.components.find((cmp) => cmp.name === componentName);
  }
}

export const nodeCategories: Record<string, NodeCategory> = {
  input: new NodeCategory('Input', 'sky'),
  recognition: new NodeCategory('Recognition', 'teal'),
  rules: new NodeCategory('Rules', 'wine'),
  integrations: new NodeCategory('Integrations', 'grass'),
  transformations: new NodeCategory('Transformations', 'tangerine'),
  media: new NodeCategory('Media', 'violet'),
  other: new NodeCategory('Other', 'default'),
};

// FIXME: Remove support for deprecated categories
export const DEPRECATED_KEY_MAP: Partial<
  Record<keyof typeof nodeCategories, keyof typeof nodeCategories>
> = {
  annotate: 'recognition',
  output: 'integrations',
  streaming: 'media',
  transform: 'transformations',
};

export function populateCategories(
  components: ComponentType[],
  _admin: boolean
) {
  components
    .sort((a, b) => a.key.localeCompare(b.key))
    .forEach((component) => {
      try {
        let { category } = component;

        if (!(component.category in nodeCategories)) {
          category = 'other';
        }

        // FIXME: Remove support for duplicate categories with different keys
        if (component.category in DEPRECATED_KEY_MAP) {
          category = DEPRECATED_KEY_MAP[component.category]!;
        }

        nodeCategories[category].addComponent(component, component.key);
      } catch (error) {
        // Do not handle error - only prevent breaking the editor.
        // An error would only occur on a faulty dynamic node.
      }
    });
}

export function hasPopulatedCategories() {
  return Object.values(nodeCategories).some(
    (category) => category.components.length
  );
}

export function getPopulatedCategories() {
  return Object.values(nodeCategories)
    .map(({ components }) => components)
    .flat();
}

export function getCategoryByName(categoryName: DockCategory): NodeCategory {
  return nodeCategories[categoryName];
}

export function getCategoryByNodeName(
  nodeName: string
): NodeCategory | undefined {
  return Object.values(nodeCategories).find(({ components }) =>
    components.some((component: any) => {
      try {
        return new component().name === nodeName;
      } catch (error) {
        // Only prevent breaking the editor.
        // An error would only occur on a faulty dynamic node.
        return false;
      }
    })
  );
}
