import { Box } from 'components/Box/Box';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import * as List from 'components/List';

export function Info() {
  return (
    <Box>
      <Heading level="4">
        <Icon name="file" size="small" />
        <span>Supported URI formats</span>
      </Heading>

      <List.Root>
        <List.Item>
          <Text>http://server/file.mp4</Text>
        </List.Item>
        <List.Item>
          <Text>file:///local/path/to/file.mp4</Text>
        </List.Item>
      </List.Root>
    </Box>
  );
}
