import React from 'react';
import classNames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';

import { readFile } from 'services/files';

import { Field } from 'components/Field/Field';
import { Loader } from 'components/Loader/Loader';
import { Textarea } from 'components/Textarea/Textarea';

import { FIELD_LABEL_WIDTH, Step1FieldValues } from '../Step1';
import { useModelFormState } from '../context/useModelFormState';

import styles from './../MultiStepForm.module.scss';

const c = classNames.bind(styles);

export type LabelsInputProps = {
  onChange?: (value: string) => void;
  value?: string;
  defaultValue?: string;
};

export function LabelsInput({
  value: initialValue,
  defaultValue,
  onChange,
}: LabelsInputProps) {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<Step1FieldValues>();
  const { labels, isLoadingLabels } = useModelFormState();

  React.useEffect(() => {
    setValue('labels', labels);
  }, [labels, setValue]);

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }

    const [file] = event.target.files;
    const contents = await readFile(file);
    setValue('labels', contents);
  }

  return (
    <Field
      id="model-labels"
      className={c('field', { loading: isLoadingLabels })}
      aria-label={isLoadingLabels ? 'Loading labels...' : undefined}
      label="Labels"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      error={errors.labels}
      required
    >
      <div className={c('field-content')}>
        <Textarea
          id="model-labels"
          defaultValue={labels || initialValue || defaultValue}
          {...register('labels', {
            required: 'Please enter labels.',
            onChange,
          })}
        />
        <p className="info">
          or{' '}
          <input
            id="model-labels_file"
            name="labels_file"
            type="file"
            className="sr-only"
            onChange={handleFileChange}
          />
          <label htmlFor="model-labels_file" className="link">
            input from file
          </label>
        </p>
      </div>

      {isLoadingLabels && (
        <div className={c('field-loader')}>
          <Loader text="Loading labels..." size="small" />
        </div>
      )}
    </Field>
  );
}
