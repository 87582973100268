import { FilterInput as PropelFilterInput } from '@propeldata/ui-kit';

import { FilterInput } from 'graphql/types/filter';

// Removes excess `id` fields only used in console.
export function sanitizeMetricFilters(
  filters: FilterInput[]
): PropelFilterInput[] {
  return filters.map(({ id, and, or, ...rest }) => ({
    ...rest,
    and: and ? sanitizeMetricFilters(and) : undefined,
    or: or ? sanitizeMetricFilters(or) : undefined,
  })) as FilterInput[];
}
