import React from 'react';
import classnames from 'classnames/bind';

import Category from 'types/category';

import {
  CategorySelect,
  HighlightedCategoryFilter,
} from 'components/CategorySelect';

import {
  PipelineTemplateGridContent,
  PipelineTemplateGridContentProps,
} from './Content';
import styles from './PipelineTemplateGrid.module.scss';

const c = classnames.bind(styles);

export type PipelineTemplateGridProps = Pick<
  PipelineTemplateGridContentProps,
  'filters' | 'onCreatePipeline'
> & {
  id: string;
};

export function PipelineTemplateGrid({
  id,
  filters,
  onCreatePipeline,
}: PipelineTemplateGridProps) {
  const [page, setPage] = React.useState(1);

  const [highlightedCategories, setHighlightedCategories] = React.useState<
    Category[]
  >([]);
  const [moreCategories, setMoreCategories] = React.useState<Category[]>([]);

  const categories = [...highlightedCategories, ...moreCategories];

  return (
    <div className={c('wrap')}>
      {/* 
        FIXME: Pipeline templates should also get a <QuerySearch /> but searching is not supported on API yet 
        https://app.shortcut.com/lumeo/story/10729
      */}
      <div className={c('filters')}>
        <HighlightedCategoryFilter
          id={`${id}_filter`}
          value={highlightedCategories}
          onChange={(categories) => {
            setHighlightedCategories(categories);
            setPage(1);
          }}
        />
        <CategorySelect
          value={moreCategories}
          onChange={(values) => {
            setMoreCategories([...values]);
            setPage(1);
          }}
          queryFilters={{
            only_non_highlighted: true,
          }}
          isMulti
        />
      </div>

      <PipelineTemplateGridContent
        categories={categories}
        filters={filters}
        page={page}
        onPageChange={setPage}
        onCreatePipeline={onCreatePipeline}
      />
    </div>
  );
}
