import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import Deployment, { DeploymentState } from 'types/deployment';
import { DeployRoute, NavRoute } from 'application/types/routes';

import { useAPI } from 'hooks/api/useAPI';
import { useDeleteDeployment } from 'hooks/api/useDeployment';
import { useIsMounted } from 'hooks/useIsMounted';
import { useMqttDeploymentsState } from 'deployments/hooks/useMqttDeploymentsState';
import { useToggleDeploymentState } from 'deployments/hooks/useDeployment';

import { Drawer } from 'components/Drawer/Drawer';
import { IconButton } from 'components/IconButton/IconButton';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';

import { deploymentFormDrawerClassName, NewDeploymentForm } from '../Form';

export type DeploymentDetailStatusProps = {
  deployment: Deployment;
};

export function DeploymentStatus({ deployment }: DeploymentDetailStatusProps) {
  const navigate = useNavigate();
  const api = useAPI();
  const { pathname, search } = useLocation();

  const isMounted = useIsMounted();

  useMqttDeploymentsState([deployment.id]);

  const [isDeploying, toggleIsDeploying] = useToggle();

  const [state, setState] = React.useState<DeploymentState>(deployment.state);
  const { action } = deployment;

  const [isToggleDisabled, setIsToggleDisabled] = React.useState(false);

  const { toggleState, isLoading } = useToggleDeploymentState();

  const [requestDelete] = useDeleteDeployment({
    onMutate() {
      if (pathname.includes('ref')) {
        navigate({
          pathname: '../..',
          search,
        });

        return;
      }

      navigate({
        pathname: `/applications/${api.applicationID}/deploy/deployments`,
        search,
      });
    },
  });

  React.useEffect(() => {
    if (!isMounted()) {
      return;
    }

    setState(deployment.state);
  }, [deployment.state, isMounted]);

  // When switching deployments, reset toggle state
  React.useEffect(() => {
    if (!isMounted()) {
      return;
    }

    setIsToggleDisabled(false);
  }, [deployment.id, isMounted]);

  // When switching deployments
  React.useEffect(() => {
    if (!isMounted()) {
      return;
    }

    setIsToggleDisabled(isLoading);
  }, [isLoading, isMounted]);

  function updateState() {
    if (!deployment || !isMounted()) {
      return;
    }

    toggleState(deployment);
  }

  function handleDeleteClick() {
    requestDelete(deployment.id);
  }

  return (
    <>
      <StatusIndicator id="up-deployment-detail_status" status={state} />
      <IconButton
        id="up-deployment-detail_toggle"
        icon={action === 'start' ? 'play' : action}
        label={action}
        onClick={updateState}
        loading={isToggleDisabled}
        size="small"
        variant="secondary"
        showLabel
      />
      <IconButton
        id="up-deployment-detail_duplicate"
        icon="copy"
        label="Duplicate"
        onClick={() => toggleIsDeploying()}
        size="small"
        variant="secondary"
        showLabel
      />
      <IconButton
        id="up-deployment-detail_delete"
        icon="delete"
        label="Delete"
        intent="danger"
        onClick={handleDeleteClick}
        disabled={state !== 'error' && state !== 'stopped'}
        disabledTooltip="Stop the deployment to delete it"
        size="small"
        variant="secondary"
        showLabel
      />

      <Drawer
        title="Copy Deployment"
        containerClassName={deploymentFormDrawerClassName}
        onClose={toggleIsDeploying}
        open={isDeploying}
      >
        <NewDeploymentForm
          onSuccess={({ id }) => {
            toggleIsDeploying(false);
            navigate({
              pathname: `/applications/${api.applicationID}/${NavRoute.DEPLOY}/${DeployRoute.DEPLOYMENTS}/${id}`,
              search,
            });
          }}
          pipelineId={deployment.pipeline_id}
          initialDeployment={deployment}
        />
      </Drawer>
    </>
  );
}
