import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import PricingPlan from 'types/pricing_plan';

import { useAPI } from 'hooks/api/useAPI';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import APIError from 'types/api_error';

export function useSwitchPricingPlan({
  onSettled,
  ...options
}: UseMutationOptions<void, APIError, string> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  const { data: organization } = useCurrentOrganization();

  return useMutation(
    (planID: PricingPlan['id']) => {
      if (!organization) {
        return Promise.reject(new Error('No organization.'));
      }

      return api.organizations.switchPlan(organization.id, planID);
    },
    {
      ...options,
      onSettled(data, error, variables, context) {
        queryClient.invalidateQueries([
          'pricing_plan',
          'org',
          organization?.id,
        ]);
        queryClient.invalidateQueries(['included_nodes']);
        onSettled?.(data, error, variables, context);
      },
    }
  );
}
