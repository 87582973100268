import React from 'react';

import Dashboard from 'types/dashboard';
import { DashboardWidget } from 'types/dashboard_widget';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Drawer, DrawerProps } from 'components/Drawer/Drawer';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';

import { WidgetForm } from '../Form';
import { WidgetModalHeader } from '../Header';

export type EditWidgetModalProps = Pick<DrawerProps, 'onClose'> & {
  dashboard: Dashboard;
  widget?: DashboardWidget;
};

export function EditWidgetModal({
  widget,
  dashboard,
  onClose,
}: EditWidgetModalProps) {
  const [isDirty, setIsDirty] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);

  const isOpen = Boolean(widget);

  return (
    <Drawer
      open={isOpen}
      closeOnClickOutside={false}
      closeOnEscape={false}
      header={
        widget?.type && (
          <WidgetModalHeader title="Edit dashboard widget" type={widget.type} />
        )
      }
      footer={
        <ButtonGroup align="end">
          {isDirty && <InlineNotification>Unsaved changes</InlineNotification>}

          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={`${widget?.type}-widget-form`}
            loading={isUpdating}
          >
            Save
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      {widget && (
        <WidgetForm
          widget={widget}
          dashboard={dashboard}
          onUpdate={setIsUpdating}
          onIsDirtyChange={setIsDirty}
        />
      )}
    </Drawer>
  );
}
