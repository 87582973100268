import React from 'react';

import Camera from 'types/camera';

import { useDeployments } from 'hooks/api/useDeployments';
import { useMqttDeploymentsState } from 'deployments/hooks/useMqttDeploymentsState';

import { Loader } from 'components/Loader/Loader';
import { Separator } from 'components/Separator/Separator';
import { Text } from 'components/Text/Text';

import { ReferenceDeploymentsList } from './List';

const limit = 3;

type UniversalBridgeDeploymentsProps = {
  camera: Camera;
};

export function UniversalBridgeDeployments({
  camera,
}: UniversalBridgeDeploymentsProps) {
  const isEnabled = Boolean(camera.reference_deployment_ids.length);

  const [page, setPage] = React.useState(1);

  const queryResult = useDeployments(
    ['deployments', 'reference-deployments'],
    { page, limit, deployment_ids: camera.reference_deployment_ids },
    { enabled: isEnabled }
  );

  const { data: referenceDeployments, isInitialLoading } = queryResult;

  useMqttDeploymentsState(
    referenceDeployments?.data
      ? referenceDeployments.data.map(({ id }) => id)
      : []
  );

  // FIXME A SkeletonTable would be better here
  if (!referenceDeployments && isInitialLoading) {
    return <Loader size="small" text="Loading..." />;
  }

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <Separator />
      <Text type="paragraph">
        Universal Bridge takes configurations from reference deployments when
        processing a clip.
        <br />
        Edit their configuration to change deploy-time properties.
      </Text>
      <ReferenceDeploymentsList
        queryResult={queryResult}
        pageSize={limit}
        page={page}
        onPageChange={setPage}
      />
    </>
  );
}
