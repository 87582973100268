import React from 'react';

import Component, {
  ComponentType,
} from 'pipelines/services/rete/nodes/component';
import { components as staticComponents } from 'pipelines/services/rete/nodes';
import { useAPI } from 'hooks/api/useAPI';
import { useIsLumeoAdmin } from 'hooks/useAuth';
import {
  getPopulatedCategories,
  hasPopulatedCategories,
  populateCategories,
} from 'pipelines/services/rete/nodes/categories';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { usePlanIncludedNodes } from 'hooks/usePlanIncludedNodes';

/**
 * Retrieves the currently saved pipeline components or fetches
 * them from the server if it's not been saved yet.
 */

export function usePipelineComponents() {
  const api = useAPI();
  const isAdmin = useIsLumeoAdmin();

  const [components, setComponents] = React.useState<ComponentType[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const dynamicComponents = useAuthenticatedQuery<Component[]>(
    ['dynamic-components'],
    api.pipelines.dynamicComponents.list,
    {
      staleTime: Infinity,
    }
  );

  const [planIncludedNodes, { isLoading: isLoadingPlanNodes }] =
    usePlanIncludedNodes();

  React.useEffect(() => {
    if (hasPopulatedCategories()) {
      setIsLoading(true);
      setComponents(getPopulatedCategories());
      setIsLoading(false);
      return;
    }

    if (dynamicComponents.data) {
      setIsLoading(true);
      const allComponents: any[] = [
        ...staticComponents,
        ...dynamicComponents.data,
      ];
      populateCategories(allComponents, isAdmin);
      setComponents(allComponents);
      setIsLoading(false);
    }
  }, [dynamicComponents.data, planIncludedNodes, isAdmin]);

  return [components, { isLoading: isLoading || isLoadingPlanNodes }] as const;
}
