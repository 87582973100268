import Node from 'types/node';
import { NodeEditor } from 'rete';

export type CustomNodeIDPluginParams = {
  getNodeID: (exportType: string) => string;
};

function install(editor: NodeEditor, { getNodeID }: CustomNodeIDPluginParams) {
  // Hook into the preventable node creation event so we can prevent the node
  // with original Rete ID from being created in the first place.
  editor.on('nodecreate', (node) => {
    const lumeoNode = Node.fromReteNode(node);
    const Component = lumeoNode.component;

    if (!Component) {
      return;
    }

    if (lumeoNode.hasExportID) {
      return true;
    }

    // Prevent node from being created, instead create node with updated ID
    node.id = getNodeID(Component.exportType) as any;
    editor.addNode(node);

    return false;
  });
}

const plugin = { name: 'CustomNodeID', install };

export default plugin;
