import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import { useAPI } from 'hooks/api/useAPI';

import APIError from 'types/api_error';
import Camera from 'types/camera';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Stream, { StreamSource, StreamType } from 'types/stream';
import { LumeoFile } from 'types/file';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';
import { TagParams } from 'tags/types/tagged';

import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export type UseStreamsFilterParams = OffsetPaginationParams &
  TagParams & {
    stream_ids?: Stream['id'][];
    stream_names?: Stream['name'][];
    stream_types?: StreamType[];
    sources?: StreamSource[];
    status?: Stream['status'][];

    camera_ids?: Camera['id'][];
    camera_names?: Camera['name'][];

    deployment_ids?: Deployment['id'][];

    gateway_ids?: Gateway['id'][];
    gateway_names?: Gateway['name'][];

    file_ids?: LumeoFile['id'][];
    file_names?: LumeoFile['name'][];
  };

/** Paginated list of streams */
export function useStreams(
  queryKey: QueryKey = ['streams'],
  {
    pagination = 'offset',
    page = 1,
    limit = 50,
    ...filters
  }: UseStreamsFilterParams = {},
  options: UseQueryOptions<PaginatedAPIResponse<Stream>, APIError> = {}
) {
  const api = useAPI();
  const params = {
    pagination,
    page,
    limit,
    ...filters,
  };

  return useAuthenticatedQuery(
    [...queryKey, api.applicationID, params],
    () => api.streams.list(params),
    {
      keepPreviousData: true,
      ...options,
    }
  );
}
