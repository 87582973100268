import React from 'react';

import type {
  Condition,
  LogicalOperator,
} from 'pipelines/services/condition_parser';

import { updateNestedCondition } from './services/updated_nested_conditions';
import { ConditionInputSuggestion } from './types';

import { getFirstConditionError } from './services/get_first_condition_error';
import { Group } from './Group';

export type ConditionInputProps = {
  name: string;
  value?: Condition;
  suggestions?: Record<string, ConditionInputSuggestion>;
  defaultValue?: Condition;
  onChange?: (value: Condition | undefined, errorMessage?: string) => void;
};

type ConditionContextProps = {
  name: string;
  suggestions: Record<string, ConditionInputSuggestion>;
  condition?: Condition;
  updateCondition: <TProperty extends keyof Condition>(
    path: number[],
    property: TProperty,
    value: Condition[TProperty],
    error?: string
  ) => void;
  addCondition: (path: number[], operator: LogicalOperator) => void;
  addGroup: (path: number[], operator: LogicalOperator) => void;
  removeCondition: (path: number[], index?: number) => void;
  updateLogicalOperator: (path: number[], operator: LogicalOperator) => void;
};

const ConditionContext = React.createContext<ConditionContextProps | undefined>(
  undefined
);

export function useConditionInputState() {
  const context = React.useContext(ConditionContext);
  if (!context) {
    throw new Error(
      'useConditionContext must be used within a ConditionProvider'
    );
  }
  return context;
}

/**
 * Condition input component that takes an object representation of
 * a trigger condition, e.g. a = 1 and b = 2 and c = 3 or d = 4
 * May only be used within react-hook-form for proper validation.
 */
export function ConditionInput({
  name,
  value,
  defaultValue,
  suggestions = {},
  onChange,
}: ConditionInputProps) {
  const condition = value ?? defaultValue;

  const [renderCount, setRenderCount] = React.useState(0);

  function insertCondition(
    path: number[],
    operator: LogicalOperator,
    newCondition: Condition
  ) {
    if (!onChange) {
      return;
    }

    if (!condition) {
      onChange?.(newCondition, 'Accessor cannot be empty.');
      return;
    }

    const updatedCondition = updateNestedCondition(
      condition,
      path,
      (targetCondition) => {
        const updatedGroup = { ...targetCondition };

        if (updatedGroup[operator]?.length) {
          updatedGroup[operator] = [...updatedGroup[operator]!, newCondition];
        } else {
          updatedGroup[operator] = [newCondition];
        }

        return updatedGroup;
      }
    );

    if (!updatedCondition) {
      return;
    }

    onChange?.(updatedCondition, getFirstConditionError(updatedCondition));
  }

  function addCondition(path: number[], operator: LogicalOperator) {
    const newCondition: Condition = {
      accessor: { text: '', error: 'Accessor cannot be empty.' },
      comparator: { text: '=' },
      value: { text: '', error: 'Value cannot be empty.' },
    };

    insertCondition(path, operator, newCondition);
  }

  function addGroup(path: number[], operator: LogicalOperator) {
    const newGroup: Condition = {
      accessor: { text: '', error: 'Accessor cannot be empty.' },
      comparator: { text: '=' },
      value: { text: '', error: 'Value cannot be empty.' },
      [operator === 'and' ? 'or' : 'and']: [],
    };
    insertCondition(path, operator, newGroup);
  }

  function updateLogicalOperator(
    path: number[],
    logicalOperator: LogicalOperator
  ) {
    if (!condition || !onChange) {
      return condition;
    }

    const updatedCondition = updateNestedCondition(
      condition,
      path,
      (targetCondition) => {
        const chainedConditions = targetCondition.and ?? targetCondition.or;

        if (chainedConditions) {
          const children = [...chainedConditions];
          delete targetCondition.and;
          delete targetCondition.or;
          targetCondition[logicalOperator] = children;
        } else {
          targetCondition[logicalOperator] = [];
        }

        return targetCondition;
      }
    );

    if (!updatedCondition) {
      return;
    }

    onChange(updatedCondition, getFirstConditionError(updatedCondition));
  }

  function updateCondition<TProperty extends keyof Condition>(
    path: number[],
    property: TProperty,
    value: Condition[TProperty],
    error?: string
  ) {
    if (!condition || !onChange) {
      return condition;
    }

    const updatedCondition = updateNestedCondition(
      condition,
      path,
      (targetCondition) => ({ ...targetCondition, [property]: value })
    );

    if (!updatedCondition) {
      return;
    }

    onChange(
      updatedCondition,
      error ?? getFirstConditionError(updatedCondition)
    );
  }

  function removeCondition(path: number[], index?: number) {
    if (!condition || !onChange) {
      return condition;
    }

    // Delete last condition
    if (index === undefined && path.length === 0) {
      if (!condition.and?.length && !condition.or?.length) {
        onChange(undefined);
        setRenderCount((n) => n + 1);
        return;
      }
    }

    // Delete parent condition and promote first child to new parent
    if (index === undefined) {
      const updatedCondition = updateNestedCondition(
        condition,
        path,
        (targetCondition) => {
          const { and, or } = targetCondition;

          if (and && and.length > 0) {
            const [newRoot, ...children] = and;
            newRoot.and = children;
            return newRoot;
          }

          if (or && or.length > 0) {
            const [newRoot, ...children] = or;
            newRoot.or = children;
            return newRoot;
          }

          return;
        }
      );

      if (!updatedCondition) {
        return;
      }

      onChange(updatedCondition, getFirstConditionError(updatedCondition));
      setRenderCount((n) => n + 1);

      return;
    }

    // Delete any child
    const updatedCondition = updateNestedCondition(
      condition,
      // Find and update parent condition
      path.slice(0, -1),
      (targetCondition) => {
        if (!targetCondition.and && !targetCondition.or) {
          return targetCondition;
        }

        const logicalOperator = targetCondition.and ? 'and' : 'or';
        const updatedChildren = [...targetCondition[logicalOperator]!];
        updatedChildren.splice(index, 1);

        return {
          ...targetCondition,
          [logicalOperator]: updatedChildren,
        };
      }
    );

    if (!updatedCondition) {
      return;
    }

    onChange(updatedCondition, getFirstConditionError(updatedCondition));
    setRenderCount((n) => n + 1);
  }

  return (
    <ConditionContext.Provider
      value={{
        name,
        suggestions,
        condition,
        updateCondition,
        addCondition,
        addGroup,
        removeCondition,
        updateLogicalOperator,
      }}
    >
      <Group path={[]} key={renderCount} />
    </ConditionContext.Provider>
  );
}
