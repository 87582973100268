import { UseMutationOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';
import { useMutationWithError } from 'hooks/api/useMutationWithError';
import { useUser } from 'hooks/useAuth';

import { useCurrentOrganization } from './useCurrentOrganization';

export type UseBillingOptions = {
  return_url: string;
};

export type UseBillingResponse = { data: string };

export function useBilling({
  onSuccess,
  ...options
}: UseMutationOptions<UseBillingResponse, APIError, void> = {}) {
  const api = useAPI();

  const { data: organization } = useCurrentOrganization();
  const user = useUser();

  return useMutationWithError(
    () => {
      if (!organization || !user?.isBilling(organization.id)) {
        return Promise.reject('Unauthorized.');
      }

      return api.organizations.billing.createUrl({
        organizationID: organization.id,
        return_url: window.location.href,
      });
    },
    {
      ...options,
      entity: 'billing',
      action: 'access',
      onSuccess: (res, ...args) => {
        const portalUrl = res.data;
        window.location.href = portalUrl;

        if (onSuccess) {
          onSuccess(res, ...args);
        }
      },
    }
  );
}
