import React from 'react';
import classNames from 'classnames/bind';

import { Input } from 'components/Input/Input';

import { Button } from 'components/Button/Button';
import { HelpTooltip } from 'components/HelpTooltip/HelpTooltip';

import { validateStrings } from '../../VideoSourceROI/services/validate';
import { getShapesFromString } from '../services/get_shapes_from_string';
import styles from './../ROICanvas.module.scss';

const c = classNames.bind(styles);

export type VideoSourceROIInputsProps = {
  shapes: Record<string, number[]> | null;
  onChange: (shapes: Record<string, number[]>) => void;
};

function stringify(values?: number[][] | string[]): string {
  return values?.join(';') || '';
}

const REGEX_TRAILING_SEMICOLON = /;+$/;

export function ROICanvasInputs({
  shapes,
  onChange,
}: VideoSourceROIInputsProps) {
  const [labels, setLabels] = React.useState<string>('');
  const [coordinates, setCoordinates] = React.useState<string>('');
  const [error, setError] = React.useState<string>();

  const labelsRef = React.useRef<HTMLInputElement>(null);
  const coordinatesRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!shapes) {
      return;
    }

    const labels = stringify(Object.keys(shapes));
    const coordinates = stringify(Object.values(shapes));

    setLabels(labels);
    setCoordinates(coordinates);
  }, [shapes]);

  function save() {
    const trimmedLabels = labels.replace(REGEX_TRAILING_SEMICOLON, '');
    const trimmedCoordinates = coordinates.replace(
      REGEX_TRAILING_SEMICOLON,
      ''
    );

    if (labels !== trimmedLabels) {
      setLabels(trimmedLabels);
    }

    if (coordinates !== trimmedCoordinates) {
      setCoordinates(trimmedCoordinates);
    }

    const error = validateStrings(trimmedLabels, trimmedCoordinates);
    setError(error);

    if (error) {
      return;
    }

    const shapes = getShapesFromString(trimmedLabels, trimmedCoordinates);
    onChange(shapes);
  }

  function handleKeyUp(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      save();

      labelsRef.current?.blur();
      coordinatesRef.current?.blur();
    }
  }

  return (
    <>
      <div className={c('mirror')}>
        <label htmlFor="roi_labels">
          <HelpTooltip content="Lumeo manages the labels for you. Edit to override.">
            Labels
          </HelpTooltip>
        </label>
        <Input
          className={c('mirror-input')}
          id="roi_labels"
          value={labels}
          onKeyDown={handleKeyUp}
          onValueChange={setLabels}
          ref={labelsRef}
        />
      </div>
      <div className={c('mirror')}>
        <label htmlFor="roi_coordinates">
          <HelpTooltip content="Lumeo manages the coordinates for you. Edit to override.">
            Coordinates
          </HelpTooltip>
        </label>
        <Input
          className={c('mirror-input')}
          id="roi_coordinates"
          value={coordinates}
          onKeyDown={handleKeyUp}
          onValueChange={setCoordinates}
          ref={coordinatesRef}
        />
      </div>

      {error && <p className="form-error theme danger">{error}</p>}

      <Button size="small" variant="secondary" onClick={save}>
        Update canvas
      </Button>
    </>
  );
}
