import { useToggle } from '@mantine/hooks';

import Gateway from 'types/gateway';

import { Box } from 'components/Box/Box';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { LinkFilesDialog } from 'files/components/LinkFilesDialog';
import { Text } from 'components/Text/Text';
import * as List from 'components/List';

export type StreamTypeInfoProps = {
  gateway?: Gateway;
  onClose?: () => void;
};

export function StreamTypeInfo({ gateway, onClose }: StreamTypeInfoProps) {
  const [isLinkingFiles, toggleIsLinkingFiles] = useToggle();

  return (
    <Box>
      <Heading level="4">
        <Icon name="file" size="small" />
        <span>Supported URI formats</span>
      </Heading>

      <Text>
        Live streams are processed in real time and restart automatically if
        they go offline.
      </Text>

      <List.Root>
        <List.Item>
          <Text>rtsp://user:password@server/path</Text>
        </List.Item>
        <List.Item>
          <Text>rtsps://user:password@server/path</Text>
        </List.Item>
        <List.Item>
          <Text>https://10.0.1.1/video</Text>
        </List.Item>
        <List.Item>
          <Text>http://user:password@10.0.1.1/video-1</Text>
        </List.Item>
      </List.Root>

      <Text>
        To use files from a gateway's file system,{' '}
        <button
          className="link"
          onClick={() => toggleIsLinkingFiles()}
          type="button"
        >
          add them as linked files
        </button>
      </Text>

      <LinkFilesDialog
        open={isLinkingFiles}
        onClose={() => {
          toggleIsLinkingFiles(false);
        }}
        onSuccess={() => {
          toggleIsLinkingFiles(false);
          onClose?.();
        }}
        gateway={gateway}
      />
    </Box>
  );
}
