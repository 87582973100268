import React from 'react';
import classnames from 'classnames/bind';

import { CodeBlock } from 'components/CodeBlock/CodeBlock';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton/CopyToClipboardButton';

import styles from './TerminalCommands.module.scss';

const c = classnames.bind(styles);

export type TerminalCommandsProps = {
  commands: string[];
  showCopyToClipboardButton?: boolean;
};

export function TerminalCommands({
  commands,
  showCopyToClipboardButton = true,
}: TerminalCommandsProps) {
  return (
    <div className={c('wrap')}>
      <CodeBlock className={c('commands')}>
        {commands.map((command) => (
          <p className={c('command')} key={command}>
            <span className={c('prefix')}>&gt; </span>
            {command}
          </p>
        ))}
      </CodeBlock>
      {showCopyToClipboardButton && (
        <CopyToClipboardButton text={commands.join(' ')} />
      )}
    </div>
  );
}
