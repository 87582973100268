import { gql } from 'graphql-request';

import { CursorVariables, Edge, PageInfo } from 'graphql/types/pagination';
import { Metric } from 'graphql/types/metric';

/**
 * Fetch metrics in a Propel application.
 * Uses cursor-based pagination.
 **/
export const METRICS = gql`
  query Metrics($first: Int, $after: String, $last: Int, $before: String) {
    metrics(first: $first, after: $after, last: $last, before: $before) {
      edges {
        cursor
        node {
          id
          type
          uniqueName
          dimensions {
            columnName
            type
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export type MetricsQueryVariables = CursorVariables;

export type MetricsResponse = {
  metrics: {
    edges: Edge<Metric>[];
    pageInfo: PageInfo;
  };
};

/**
 * Fetch a metric in a Propel application.
 */
export const METRIC = gql`
  query MetricByName($uniqueName: String!) {
    metricByName(uniqueName: $uniqueName) {
      id
      type
      uniqueName
      dimensions {
        columnName
        type
      }
    }
  }
`;

export type MetricQueryVariables = {
  uniqueName: string;
};

export type MetricResponse = {
  metricByName: Metric;
};
