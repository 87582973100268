import React from 'react';

import {
  CloseFunction,
  ModalKeyContext,
  ModalContext,
} from 'context/modal/ModalProvider';

export function useModal() {
  const context = React.useContext(ModalContext);
  const modalKey = React.useContext(ModalKeyContext);

  if (!context) {
    throw new Error('Hook `useModal` must be within a <ModalProvider />.');
  }

  const { open, close } = context;

  const closeModal: CloseFunction = React.useCallback(
    (key) => (!key && modalKey ? close(modalKey) : close(key)),
    [close, modalKey]
  );

  return { open, close: closeModal };
}
