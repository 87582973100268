import React from 'react';
import Rete, { NodeEditor, Node as ReteNode } from 'rete';

import { getPropertyValue, setPropertyValue } from 'types/node';

import { NodePropertyValueType } from '../nodes/types';

export type CommonReteInputParams = {
  initial?: any | null;
  label?: string;
  description?: string;
  info?: string;
  keyref?: string;
  requiresKeyrefValue?: unknown;
  deploymentParam?: boolean | 'always' | 'never';
  required?: boolean;
};

export type ReteInputParams = CommonReteInputParams & {
  inputType?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  type?: NodePropertyValueType;

  group?: string;
  placeholder?: string;
  unit?: string;
};

export type ReteInputProps = {
  value?: any | null;
  onChange?: (value: any | null) => void;
  placeholder?: string;
  unit?: string;
  type?: string;
  keyref?: string;
  keyrefValue?: string;
  requiresKeyrefValue?: unknown;
  [property: string]: any;
};

export type DisplayerProps = {
  node: ReteNode;
  property: string;
  value?: any;
  type?: NodePropertyValueType;
};

export default class ReteInput<
  T extends ReteInputProps = ReteInputProps
> extends Rete.Control {
  editor: NodeEditor;
  key: string;

  label?: string;
  description?: string;
  info?: string;
  group?: string;
  placeholder?: string;
  unit?: string;
  required?: boolean;

  keyref?: string;
  requiresKeyrefValue?: unknown;

  initial: any;
  type?: NodePropertyValueType;

  inputType?: React.InputHTMLAttributes<HTMLInputElement>['type'];

  /**
   * Default value to determine whether to allow editing property in
   * deployment dialog. The behavior can be changed in the pipeline editor.
   * `'always'` will never show the property in the pipeline editor.
   * `'never'` will never show the property in deployment dialog.
   */
  deploymentParam: boolean | 'always' | 'never' = false;

  props: ReteInputProps;

  constructor(
    editor: NodeEditor,
    key: string,
    {
      type,
      label,
      description,
      info,
      initial = null,
      inputType,
      group,
      placeholder,
      unit,
      deploymentParam = false,
      required = false,
      keyref,
      requiresKeyrefValue,
    }: ReteInputParams
  ) {
    super(key);
    this.editor = editor;
    this.key = key;
    this.label = label;
    this.initial = initial;
    this.type = type || 'string';
    this.inputType = inputType || 'input';
    this.group = group;
    this.description = description;
    this.info = info;
    this.deploymentParam = deploymentParam;
    this.keyref = keyref;
    this.requiresKeyrefValue = requiresKeyrefValue;

    this.props = {
      value: initial,
      placeholder,
      unit,
      keyref,
      required,
    };
  }

  get component(): (params: T) => JSX.Element {
    throw new Error('You must override `component` when extending `Input`');
  }

  setValue(val: unknown) {
    this.props.value = val;
    this.putData(this.key, val);
  }

  getData(key: string) {
    return getPropertyValue(this.getNode().data, key);
  }

  putData(key: string, value: unknown) {
    if (!key.includes('.')) {
      super.putData(key, value);
      return;
    }

    this.getNode().data = setPropertyValue(this.getNode().data, key, value);
  }
}
