import { useMutation } from '@tanstack/react-query';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames/bind';

import APIError from 'types/api_error';

import { addNotification } from 'services/notification';
import { postAuthAPI } from 'services/api_auth';
import { useAuthentication } from 'hooks/api/useAuthentication';

import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import { Logo } from 'components/Logo/Logo';

import styles from './Auth.module.scss';

const c = classNames.bind(styles);

type ForgotPasswordParams = {
  email: string;
};

export function AuthForgotPassword() {
  const auth = useAuthentication();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordParams>();

  const {
    mutate: sendResetEmail,
    isLoading,
    error,
  } = useMutation(
    (params: ForgotPasswordParams) =>
      postAuthAPI('/internal/auth/reset_password', params, {
        credentials: 'omit',
      }),
    {
      onSuccess() {
        navigate('/');
        addNotification({
          title: 'Please check your email',
          message:
            "We've sent instructions to reset your password to your email.",
          variant: 'success',
          icon: 'check-circle',
          timed: 30 * 1000, // 30 seconds
        });
      },
    }
  );

  if (auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  function onSubmit(params: ForgotPasswordParams) {
    sendResetEmail(params);
  }

  return (
    <div className={c('wrap')}>
      <div className={c('card', 'auth')}>
        <div className="card-content">
          <Logo className={c('logo')} aria-label="Lumeo logo." />

          <div className={c('auth-text')}>
            <Heading level="1">Forgot password</Heading>
            <p>
              Enter the email associated with your account and we will send you
              an email with instructions to reset your password.
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Field id="email" label="Email" error={errors.email}>
              <Input
                id="email"
                type="email"
                {...register('email', {
                  required: 'Please enter a valid email',
                })}
              />
            </Field>

            <FormErrorMessage error={error as APIError} />

            <Button
              className={c('auth-button')}
              type="submit"
              variant="primary"
              loading={isLoading}
            >
              Send reset instructions
            </Button>
          </form>

          <div className={c('auth-text-secondary')}>
            <Link to="/login" className="link">
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
