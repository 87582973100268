import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { FloatInput } from 'components/NumberInput/NumberInput';

import { FIELD_LABEL_WIDTH, Step3FieldValues } from '../Step3';

export function NmsClusteringIoUInput() {
  const {
    formState: { errors },
    control,
  } = useFormContext<Step3FieldValues>();

  return (
    <Field
      id="nms_iou_threshold"
      label="IoU threshold"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      info="Maximum Intersection over Union score between two proposals."
      error={errors.inference_config?.class_attributes?.nms_iou_threshold}
      required
    >
      <Controller
        control={control}
        name="inference_config.class_attributes.*.nms_iou_threshold"
        defaultValue={0.5}
        render={({ field: { value, onChange } }) => (
          <FloatInput
            id="nms_iou_threshold"
            min={0.01}
            value={value}
            onChange={onChange}
          />
        )}
        rules={{
          required: 'Please enter an IoU threshold.',
        }}
      />
    </Field>
  );
}
