import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Stream from 'types/stream';

import { useAPI } from 'hooks/api/useAPI';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';
import {
  updateQueriesData,
  UpdateQueriesDataContext,
} from 'services/update_queries_data';

export function useDeleteFileStreams({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<
  void[],
  APIError,
  Stream['id'][],
  UpdateQueriesDataContext<Stream> | void
> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete the selected files?',
    (fileStreamIDs) => {
      if (!fileStreamIDs) {
        return Promise.resolve([]);
      }

      return Promise.all(fileStreamIDs.map(api.streams.delete));
    },
    {
      ...options,
      async onMutate(fileStreamIDs) {
        await queryClient.cancelQueries(['streams']);
        onMutate?.(fileStreamIDs);

        return updateQueriesData<Stream>({
          queryClient,
          listQueryKey: ['streams'],
          ids: fileStreamIDs,
          updateData: null,
        });
      },
      onError(err, deletedStream, context) {
        onError?.(err, deletedStream, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      async onSettled(...args) {
        queryClient.invalidateQueries(['streams']);
        onSettled?.(...args);
      },
    }
  );
}
