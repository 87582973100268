import React from 'react';
import { CellProps, Column } from 'react-table';

import Pipeline from 'types/pipeline';

import { relativeTimeFromNow } from 'services/date';

import { useDeletePipelines } from 'hooks/api/usePipeline';
import { useUpdatePipeline } from 'pipelines/hooks/useUpdatePipeline';

import { NameCell } from 'components/Table/Table';

import { PipelineOverviewDeploymentsCell } from './TableDeploymentsCell';
import { PipelineOverviewActionsCell } from './TableActionsCell';
import {
  PaginatedTable,
  PaginatedTableProps,
} from 'components/Table/PaginatedTable';
import { EmptyView } from 'components/EmptyView/EmptyView';

export type PipelinesTableProps = Pick<
  PaginatedTableProps<Pipeline>,
  'queryResult' | 'pageSize' | 'page' | 'onPageChange'
> & {
  hasFilters: boolean;
  onCreate: () => void;
};

function PipelinesTable({
  hasFilters,
  onCreate,
  ...props
}: PipelinesTableProps) {
  const [requestDelete, { isLoading }] = useDeletePipelines();

  const [isRenamingPipeline, setIsRenamingPipeline] = React.useState<
    Pipeline['id'] | null
  >(null);
  const { mutate: updatePipeline } = useUpdatePipeline();

  const columns: Column<Pipeline>[] = React.useMemo(() => {
    return [
      {
        Header: 'Pipeline',
        Cell: (props: CellProps<Pipeline>) => {
          function handleNameChange(newName: string) {
            updatePipeline({
              id: props.row.original.id,
              name: newName,
            });
            setIsRenamingPipeline(null);
          }

          function cancelRename() {
            setIsRenamingPipeline(null);
          }

          return (
            <NameCell
              {...props}
              isRenaming={isRenamingPipeline === props.row.original.id}
              onNameChange={handleNameChange}
              onCancel={cancelRename}
              to={`/design/pipelines/${props.row.original.id}`}
              entity="pipeline"
            />
          );
        },
      },
      { Header: 'Deployments', Cell: PipelineOverviewDeploymentsCell },
      {
        id: 'updated_at',
        Header: 'Last Updated',
        accessor: (pipeline: Pipeline) =>
          relativeTimeFromNow(pipeline.updated_at),
        sortType: 'date',
      },
      {
        Header: ' ',
        Cell: (props: CellProps<Pipeline>) => (
          <PipelineOverviewActionsCell
            {...props}
            onRenameClick={setIsRenamingPipeline}
          />
        ),
      },
    ];
  }, [isRenamingPipeline, updatePipeline]);

  return (
    <PaginatedTable
      {...props}
      id="pipelines-table"
      label="pipelines"
      columns={columns}
      onRowNavigate={(pipeline) => `/design/pipelines/${pipeline.id}`}
      emptyMessage={
        hasFilters ? (
          <EmptyView title="No pipelines matching the filter criteria found.">
            Please try another filter combination or clear filters.
          </EmptyView>
        ) : (
          <EmptyView
            title="You have not created any pipelines yet."
            buttonText="Create a pipeline"
            onButtonClick={onCreate}
          >
            Pipelines define how video is routed and processed.
          </EmptyView>
        )
      }
      bulkActions={[
        {
          onClick: requestDelete,
          intent: 'danger',
          action: 'Delete',
          loading: isLoading,
        },
      ]}
    />
  );
}

const Memoized = React.memo(PipelinesTable);

export { Memoized as PipelinesTable };
