import React from 'react';
import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';

import { Tag } from 'types/tag';

import { useUpdateTag } from 'tags/hooks/useUpdateTag';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import * as Dialog from 'components/Dialog';

import styles from './UpdateTagDialog.module.scss';

const c = classNames.bind(styles);

type CreateTagFieldValues = {
  name: string;
};

export type UpdateTagDialogProps = Pick<
  Dialog.RootProps,
  'open' | 'onOpenChange' | 'trigger'
> & {
  tag: Tag;
};

export function UpdateTagDialog({
  tag,
  onOpenChange,
  ...props
}: UpdateTagDialogProps) {
  const {
    isLoading,
    error,
    mutate: updateTag,
  } = useUpdateTag({
    onSuccess() {
      onOpenChange?.(false);
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<CreateTagFieldValues>({
    mode: 'onBlur',
    defaultValues: {
      name: tag.name,
    },
  });

  function onSubmit({ name }: CreateTagFieldValues) {
    updateTag({ id: tag.id, name });
  }

  return (
    <Dialog.Root {...props} className={c('dialog')} onOpenChange={onOpenChange}>
      <Dialog.Title asChild>
        <Heading level="2">Edit label</Heading>
      </Dialog.Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormErrorMessage error={error} />

        <Field id="label-name" label="Label name" error={errors.name} required>
          <Input
            {...register('name', { required: 'Please enter a label name.' })}
            id="label-name"
            type="text"
            autoComplete="off"
            placeholder={tag.name}
            spellCheck="false"
            autoFocus
          />
        </Field>

        <ButtonGroup>
          <Dialog.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
          <Button variant="primary" type="submit" loading={isLoading}>
            Update
          </Button>
        </ButtonGroup>
      </form>
    </Dialog.Root>
  );
}
