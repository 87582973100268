import React from 'react';
import { useLocation } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import Pipeline from 'types/pipeline';

import { useAppRedirect } from 'hooks/useAppRedirect';
import {
  usePipelines,
  UsePipelinesFilterParams,
} from 'pipelines/hooks/usePipelines';

import * as PageLayout from 'components/PageLayout';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';

import { CreateNewPipeline } from 'pipelines/components/CreateNewPipeline/CreateNewPipeline';

import { PipelinesTable } from './Table/Table';

export function PipelineOverview() {
  const location = useLocation();
  const redirect = useAppRedirect();

  const [queryFilters, setQueryFilters] =
    React.useState<UsePipelinesFilterParams>({
      page: 1,
    });

  const queryResult = usePipelines(queryFilters, { keepPreviousData: true });

  const [searchFilters, setSearchFilters] =
    React.useState<UsePipelinesFilterParams>({});

  const searchQueryResult = usePipelines(
    ['pipelines-suggestions'],
    {
      ...searchFilters,
      limit: 20,
    },
    { enabled: Object.keys(searchFilters).length > 0, keepPreviousData: false }
  );

  const handleSearchInput = React.useCallback((value?: string) => {
    if (!value) {
      setSearchFilters({});
      setQueryFilters({ page: 1 });
      return;
    }

    setSearchFilters({
      pipeline_names: [value],
    });
  }, []);

  function handleApplyFilters() {
    setQueryFilters({ ...searchFilters, page: 1 });
  }

  function handleSuggestionSelect({ id }: Pipeline) {
    redirect(`/design/pipelines/${id}`);
  }

  const isCreatingFromState = Boolean(location.state?.isCreating);
  const [isCreating, toggleCreate] = useToggle([
    isCreatingFromState,
    !isCreatingFromState,
  ]);

  return (
    <PageLayout.Root>
      <QuerySearch
        entityName="pipeline"
        searchLabel="Search pipelines..."
        searchResultField="name"
        searchQueryResult={searchQueryResult}
        onApplyFilters={handleApplyFilters}
        onSuggestionSelect={handleSuggestionSelect}
        onValueChange={handleSearchInput}
        totalElements={queryResult.data?.total_elements}
      />

      <div id="up-pipelines-list">
        <PipelinesTable
          queryResult={queryResult}
          pageSize={50}
          page={queryFilters.page}
          onPageChange={(page) =>
            setQueryFilters((previous) => ({ ...previous, page }))
          }
          hasFilters={Object.entries(queryFilters).length > 0}
          onCreate={toggleCreate}
        />
      </div>

      <CreateNewPipeline open={isCreating} onClose={toggleCreate} />
    </PageLayout.Root>
  );
}
