import { Options } from 'react-select';
import { TimeSeriesGranularity } from '@propeldata/ui-kit';

import { ChartType } from 'types/dashboard_widget';

import { Select, SelectProps } from 'components/Select/Select';
import { HEATMAP_CHART_GRANULARITIES } from 'dashboards/charts/HeatmapChart';

export type GranularityOption = { value: TimeSeriesGranularity; label: string };

export const GRANULARITY_OPTIONS: Options<GranularityOption> = [
  { value: TimeSeriesGranularity.Day, label: 'Day' },
  { value: TimeSeriesGranularity.Hour, label: 'Hour' },
  { value: TimeSeriesGranularity.Week, label: 'Week' },
  { value: TimeSeriesGranularity.Month, label: 'Month' },
  { value: TimeSeriesGranularity.Minute, label: 'Minute' },
  { value: TimeSeriesGranularity.FifteenMinutes, label: '15 minutes' },
  { value: TimeSeriesGranularity.TenMinutes, label: '10 minutes' },
  { value: TimeSeriesGranularity.FiveMinutes, label: '5 minutes' },
  { value: TimeSeriesGranularity.Year, label: 'Year' },
];

type GranularitySelectProps = Omit<
  SelectProps<GranularityOption>,
  'value' | 'onChange'
> & {
  value?: TimeSeriesGranularity;
  onChange?: (value?: TimeSeriesGranularity) => void;
};

/** Timeseries granularity presets. */
export function GranularitySelect({
  value: granularity,
  onChange,
  ...props
}: GranularitySelectProps) {
  return (
    <Select
      {...props}
      value={GRANULARITY_OPTIONS.find(({ value }) => value === granularity)}
      onChange={(option) => onChange?.(option?.value)}
      options={GRANULARITY_OPTIONS}
    />
  );
}

export function isGranularityOptionDisabled(
  granularity: TimeSeriesGranularity,
  chartType: ChartType
) {
  switch (chartType) {
    case 'timeseries-heatmap':
      return !HEATMAP_CHART_GRANULARITIES.includes(granularity);
    default:
      return false;
  }
}
