import React from 'react';
import classNames from 'classnames/bind';
import { FilterInput } from '@propeldata/ui-kit';

import { useTimeRangeParams } from 'dashboards/hooks/useTimeRangeParams';
import { useCounter } from 'dashboards/hooks/useCounter';

import { ChartError } from 'dashboards/charts/components/ChartError';
import { ChartLoader } from 'dashboards/charts/components/ChartLoader';

import { WIDGET_REFETCH_INTERVAL } from '../services/interval';

import styles from './Counter.module.scss';

const c = classNames.bind(styles);

export type CounterProps = {
  query: { metric: string; filters?: FilterInput[] };
};

export function Counter({ query: { metric, filters } }: CounterProps) {
  const { start, stop, timezone } = useTimeRangeParams();

  const { data, isLoading, error } = useCounter(
    {
      metric: { name: metric },
      timeRange: { start, stop },
      filters,
      timeZone: timezone,
    },
    { refetchInterval: WIDGET_REFETCH_INTERVAL, keepPreviousData: true }
  );

  if (error) {
    return (
      <ChartError
        error={{ title: 'No data', body: 'Failed to fetch value.' }}
      />
    );
  }

  if (!data && isLoading) {
    return <ChartLoader />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className={c('counter')}>
      <span className={c('value')}>{data.counter.value ?? '-'}</span>
    </div>
  );
}
