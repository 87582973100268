import React from 'react';
import classNames from 'classnames/bind';
import platform from 'platform';

import styles from './Kbd.module.scss';

const c = classNames.bind(styles);

type Child = React.ReactText | React.ReactElement<KbdProps>;

export type KbdProps = {
  children: Child | Child[];
  metaKey?: boolean;
};

export function getMetaKey(): string {
  const { os } = platform;

  if (os?.family?.includes('OS X')) {
    return '⌘';
  }

  return 'Ctrl + ';
}

export function Kbd({ metaKey, children }: KbdProps) {
  return (
    <kbd className={c('kbd')}>
      {metaKey && getMetaKey()}
      {children}
    </kbd>
  );
}
