import { CellProps } from 'react-table';

import Gateway from 'types/gateway';
import Camera from 'types/camera';

import { TextAppLink } from 'components/TextAppLink/TextAppLink';

type CameraTableGatewayCellProps = CellProps<Camera> & {
  gateway?: Gateway;
};

export function CamerasTableGatewayCell({
  row,
  gateway,
}: CameraTableGatewayCellProps) {
  const { gateway_id } = row.original;

  if (!gateway) {
    return null;
  }

  return (
    <TextAppLink to={`/deploy/gateways/${gateway_id}`}>
      {gateway.name}
    </TextAppLink>
  );
}
