export function getWidthAndHeightFromResolutionString(
  resolution?: string | null
) {
  if (!resolution) {
    return [];
  }

  const values = resolution.split('x');

  if (values.length > 2 || values.some((x) => Number.isNaN(Number(x)))) {
    return [];
  }

  return values.map(Number);
}
