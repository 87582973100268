import React from 'react';
import classNames from 'classnames/bind';
import { useUncontrolled } from '@mantine/hooks';

import {
  FloatInput,
  NumberInputProps,
} from 'components/NumberInput/NumberInput';
import { Input, InputProps } from 'components/Input/Input';

import styles from './MultiInput.module.scss';

const c = classNames.bind(styles);

type ArrayType<TLength, TType> = TType[] & {
  length: TLength;
};

type ValueType = string | number;

type BaseProps<TLength extends number, TType extends ValueType> = {
  id?: string;
  length: TLength;
  type?: 'number' | 'string';

  defaultValue?: ArrayType<TLength, TType>;
  value?: ArrayType<TLength, TType>;
  onChange: (value: ArrayType<TLength, TType>) => void;
};

type MultiNumberInputProps<TLength extends number> = BaseProps<
  TLength,
  number
> &
  Omit<NumberInputProps, 'value' | 'defaultValue' | 'onChange' | 'type'>;

type MultiStringInputProps<TLength extends number> = BaseProps<
  TLength,
  string
> &
  Omit<InputProps, 'value' | 'defaultValue' | 'onChange' | 'type'>;

export type MultiInputProps<
  TLength extends number,
  TType extends ValueType
> = TType extends number
  ? MultiNumberInputProps<TLength>
  : TType extends string
  ? MultiStringInputProps<TLength>
  : never;

/**
 * Renders a series of inputs with independent values in a row.
 * Intended for use with array-type fields in API to allow for per-field
 * validation as opposed to a single input with user-required separators.
 */
export function MultiInput<TLength extends number, TType extends ValueType>({
  id,
  length,
  type,
  value,
  defaultValue,
  onChange,
  ...props
}: MultiInputProps<TLength, TType>) {
  const LENGTH_ARRAY = new Array(length).fill(undefined) as ArrayType<
    TLength,
    TType
  >;
  const [values, setValues] = useUncontrolled<ArrayType<TLength, TType>>({
    value: (value as ArrayType<TLength, TType>) ?? undefined,
    defaultValue: (defaultValue as ArrayType<TLength, TType>) ?? undefined,
    finalValue: LENGTH_ARRAY,
    onChange: onChange as BaseProps<TLength, TType>['onChange'],
  });

  function handleValueChange(value: TType, index: number) {
    const updatedValues = (
      values ? [...values] : [...LENGTH_ARRAY]
    ) as ArrayType<TLength, TType>;
    updatedValues[index] = value;
    setValues(updatedValues);
  }

  return (
    <div className={c('wrap')}>
      {LENGTH_ARRAY.map((_, index) =>
        type === 'number' ? (
          <FloatInput
            {...(props as NumberInputProps)}
            id={`${id}_${index}`}
            value={(values?.[index] as number) ?? ''}
            onChange={(number) =>
              handleValueChange((number === '' ? 0 : number) as TType, index)
            }
            key={index}
          />
        ) : (
          <Input
            {...(props as InputProps)}
            id={`${id}_${index}`}
            value={values?.[index] ?? ''}
            onChange={(event) =>
              handleValueChange(event.target.value as TType, index)
            }
            key={index}
          />
        )
      )}
    </div>
  );
}
