import { useQueryClient } from '@tanstack/react-query';

import Camera, { isCameraState } from 'types/camera';
import { OffsetPaginated } from 'types/pagination';

import {
  OnMessagePayload,
  SubscriptionMap,
  useMqttSubscribe,
} from 'hooks/useMqttSubscribe';
import { isNotNullOrUndefined } from 'services/nullable';
import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';

export function useMqttCamerasState(ids: Camera['id'][]) {
  const queryClient = useQueryClient();
  const { applicationID } = useAPI();

  const invalidate = useDebouncedCallback(
    (cameraId: Camera['id']) => {
      queryClient.invalidateQueries(['cameras']);
      queryClient.invalidateQueries(['camera', cameraId]);
    },
    [queryClient]
  );

  async function onMessage({ topic, message }: OnMessagePayload) {
    const [, , , cameraId] = topic.split('/');

    if (!isCameraState(message)) {
      return;
    }

    const listQueries = queryClient.getQueriesData<OffsetPaginated<Camera>>([
      'cameras',
    ]);

    const singleQueries = queryClient.getQueriesData<Camera>([
      'camera',
      cameraId,
    ]);

    let isStaleListQuery = false;
    let isStaleSingleQuery = false;

    if (listQueries.length) {
      const listQueryStates = listQueries
        .map(([queryKey]) =>
          queryClient.getQueryState<OffsetPaginated<Camera>>(queryKey)
        )
        .filter(isNotNullOrUndefined);

      if (listQueryStates.length) {
        const { data: latestCameras } = listQueryStates.reduce(
          (latest, current) =>
            latest.dataUpdatedAt > current.dataUpdatedAt ? latest : current
        );

        if (!latestCameras || !latestCameras.data) return;

        isStaleListQuery = latestCameras.data.some(
          ({ id, status }) => id === cameraId && status !== message
        );
      }
    }

    if (singleQueries.length) {
      const singleQueryStates = singleQueries
        .map(([queryKey]) => queryClient.getQueryState<Camera>(queryKey))
        .filter(isNotNullOrUndefined);

      if (singleQueryStates.length) {
        const { data: latestCamera } = singleQueryStates.reduce(
          (latest, current) =>
            latest.dataUpdatedAt > current.dataUpdatedAt ? latest : current
        );

        if (!latestCamera) return;

        isStaleSingleQuery = latestCamera.status !== message;
      }
    }

    if (isStaleListQuery || isStaleSingleQuery) {
      await queryClient.cancelQueries(['cameras']);
      await queryClient.cancelQueries(['camera', cameraId]);

      updateQueriesData<Camera>({
        queryClient,
        listQueryKey: ['cameras'],
        singleQueryKey: ['camera'],
        ids: [cameraId],
        updateData(camera) {
          const updatedCamera = camera.copy();
          updatedCamera.status = message;
          return updatedCamera;
        },
      });

      invalidate(cameraId);
    }
  }

  const subscriptions: SubscriptionMap = {};

  if (applicationID) {
    for (const id of ids) {
      const topic = `apps/${applicationID}/cameras/${id}/status`;
      subscriptions[topic] = { onMessage };
    }
  }

  useMqttSubscribe(subscriptions);
}
