import Camera from 'types/camera';
import Deployment from 'types/deployment';
import Stream from 'types/stream';

import { useStreams } from 'streams/hooks/useStreams';

import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { useAPI } from './useAPI';

export type VideoSource = Camera | Stream;

const EMPTY_CAMERAS: Camera[] = [];
const EMPTY_STREAMS: Stream[] = [];

export type UseDeploymentsVideoSourcesOptions = {
  keepPreviousData?: boolean;
};

export function useDeploymentsVideoSources(
  deployments: Deployment[],
  options?: UseDeploymentsVideoSourcesOptions
) {
  const api = useAPI();

  const { cameraIds, streamIds } = deployments.reduce(
    (ids, { configuration }) => {
      for (const { source_id, source_type } of Object.values(configuration)) {
        if (!source_id || !source_type) {
          continue;
        }

        switch (source_type) {
          case 'camera':
            ids.cameraIds.push(source_id);
            break;
          case 'stream':
            ids.streamIds.push(source_id);
            break;
        }
      }

      return ids;
    },
    { cameraIds: [] as Camera['id'][], streamIds: [] as Stream['id'][] }
  );

  const hasStreams = streamIds.length > 0;
  const hasCameras = cameraIds.length > 0;

  const { data: streams, isInitialLoading: isLoadingStreams } = useStreams(
    ['deployment-streams'],
    {
      stream_ids: streamIds,
      limit: streamIds.length,
    },
    { ...options, enabled: hasStreams }
  );

  const { data: cameras, isInitialLoading: isLoadingCameras } =
    useAuthenticatedQuery(
      ['deployment-cameras', deployments.map(({ id }) => id)],
      () =>
        api.cameras.list({
          camera_ids: cameraIds,
          limit: cameraIds.length,
          page: 1,
        }),
      { ...options, enabled: hasCameras }
    );

  return {
    cameras: hasCameras && cameras ? cameras.data : EMPTY_CAMERAS,
    streams: hasStreams && streams ? streams.data : EMPTY_STREAMS,
    isLoading: isLoadingCameras || isLoadingStreams,
  };
}
