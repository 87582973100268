import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import PricingPlan from 'types/pricing_plan';

import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useAPI } from 'hooks/api/useAPI';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

type UseCurrentPlanOptions = UseQueryOptions<PricingPlan | null, APIError>;

export function useCurrentPlan({ enabled }: UseCurrentPlanOptions = {}) {
  const api = useAPI();
  const { data: organization } = useCurrentOrganization();

  const organizationID = organization?.id;

  const queryResult = useAuthenticatedQuery(
    ['pricing_plan', 'org', organizationID],
    () => {
      if (!organizationID) {
        return Promise.reject(new Error('No organization ID provided.'));
      }

      return api.organizations.readCurrentPlan(organizationID);
    },
    {
      enabled:
        enabled !== undefined
          ? Boolean(organizationID) && enabled
          : Boolean(organizationID),
    }
  );

  const isStarterPlan =
    queryResult.data?.id === process.env.REACT_APP_STARTER_PLAN_ID;
  const isBusinessPlan =
    queryResult.data?.id === process.env.REACT_APP_BUSINESS_PLAN_ID;
  const isCustomPlan = queryResult.data === null;

  return {
    ...queryResult,
    isStarterPlan,
    isBusinessPlan,
    isCustomPlan,
  };
}
