import React from 'react';
import { QueryKey } from '@tanstack/react-query';
import classNames from 'classnames/bind';

import AIModel from 'types/ai_model';
import Camera from 'types/camera';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Stream from 'types/stream';
import { APIObject } from 'services/api';

import * as PageLayout from 'components/PageLayout';
import { EditableText } from 'components/EditableText/EditableText';
import { Heading } from 'components/Heading/Heading';

import { DetailViewTags } from './DetailViewTags';
import styles from './DetailViewHeader.module.scss';

const c = classNames.bind(styles);

export type DetailViewHeaderProps<
  T extends Gateway | Camera | AIModel | Deployment | Stream,
> = React.PropsWithChildren<{
  entity: T;
  entityType?: keyof APIObject;
  listQueryKey?: QueryKey;
  singleQueryKey?: QueryKey;
  titleSuffix?: JSX.Element | false;
  isRenaming?: boolean;
  onRename?: (name: string) => void;
}>;

export function DetailViewHeader<
  T extends Gateway | Camera | AIModel | Deployment | Stream,
>({
  entity,
  entityType,
  listQueryKey,
  singleQueryKey,
  children,
  titleSuffix,
  isRenaming,
  onRename,
}: DetailViewHeaderProps<T>) {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (isRenaming && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isRenaming]);

  if (!entity) {
    return null;
  }

  const displayName = String(entity.name || entity.id);

  return (
    <PageLayout.Header>
      <div>
        <div className={c('header-content')}>
          <div className={c('title')}>
            <Heading level="1">
              {onRename ? (
                <EditableText
                  defaultValue={displayName}
                  onSave={onRename}
                  ref={inputRef}
                />
              ) : (
                displayName
              )}
            </Heading>

            {/* TODO: Get rid of this */}
            {titleSuffix}
          </div>
        </div>

        <div className={c('content')}>{children}</div>

        {entityType && (
          <DetailViewTags
            entity={entity}
            entityType={entityType}
            listQueryKey={listQueryKey}
            singleQueryKey={singleQueryKey}
          />
        )}
      </div>
    </PageLayout.Header>
  );
}
