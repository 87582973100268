import React from 'react';
import classNames from 'classnames/bind';

import { Heading } from 'components/Heading/Heading';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Text } from 'components/Text/Text';

import styles from './Node/Node.module.scss';

const c = classNames.bind(styles);

export function DeploymentFormSkeleton() {
  return (
    <div className={c('wrap', 'node-loader')}>
      <div className={c('node')}>
        <span className={c('node-icon')} />

        <div className={c('node-header')}>
          <Heading level="3" asChild>
            <span className="skeleton-text" />
          </Heading>
        </div>

        <div className={c('node-properties')}>
          <SkeletonList min={3} max={8} component={<SkeletonNodeProperty />} />
        </div>
      </div>
    </div>
  );
}

function SkeletonNodeProperty() {
  return (
    <div className="form-row">
      <div className={c('node-loader-field')}>
        <div>
          <Text className="skeleton-text" />
        </div>
        <div>
          <Text className="skeleton-text" />
        </div>
      </div>
    </div>
  );
}
