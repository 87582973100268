import React from 'react';
import classnames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import {
  DeploymentState,
  DEPLOYMENT_ACTIVE_STATES,
  DEPLOYMENT_ERROR_STATES,
  DEPLOYMENT_INACTIVE_STATES,
} from 'types/deployment';
import Pipeline from 'types/pipeline';
import Gateway from 'types/gateway';

import {
  useDeployments,
  UseDeploymentsFilterParams,
} from 'hooks/api/useDeployments';
import { useHasAccess } from 'hooks/useHasAccess';

import { Button } from 'components/Button/Button';
import { DeploymentList } from 'deployments/components/List/List';
import { Drawer } from 'components/Drawer/Drawer';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { Loader } from 'components/Loader/Loader';
import {
  deploymentFormDrawerClassName,
  NewDeploymentForm,
} from 'deployments/components/Form';
import { PipelineSelect } from 'pipelines/components/PipelineSelect/PipelineSelect';
import { StateSelect } from 'components/StateSelect/StateSelect';

import styles from './DeploymentListSmall.module.scss';

const c = classnames.bind(styles);

export type DeploymentListSmallProps = {
  cameraId?: string;
  streamIds?: (string | undefined)[];
  gatewayId?: string;
};

const DEPLOYMENT_STATES = [
  ...DEPLOYMENT_ACTIVE_STATES,
  ...DEPLOYMENT_INACTIVE_STATES,
  ...DEPLOYMENT_ERROR_STATES,
];

export function DeploymentListSmall({
  cameraId,
  streamIds,
  gatewayId,
}: DeploymentListSmallProps) {
  const [hasAccess] = useHasAccess();

  const navigate = useNavigate();
  const { search } = useLocation();

  const allowedStreamIds = streamIds
    ? (streamIds.filter((element) => element !== undefined) as string[])
    : [];
  const allowedCameraIds = cameraId ? [cameraId] : [];
  const allowedGatewayIds = gatewayId ? [gatewayId] : [];

  const [queryFilters, setQueryFilters] =
    React.useState<UseDeploymentsFilterParams>({ page: 1 });
  const queryResult = useDeployments({
    video_source_ids: [...allowedStreamIds, ...allowedCameraIds],
    gateway_ids: allowedGatewayIds,
    ...queryFilters,
  });

  const [isDeploying, toggleIsDeploying] = useToggle();

  function handleStateFilterChange(state: string | null) {
    setQueryFilters((previousFilters) => ({
      ...previousFilters,
      states: state ? [state as DeploymentState] : undefined,
      page: 1,
    }));
  }

  function handleGatewayFilterChange(gateway: Gateway | null) {
    setQueryFilters((previousFilters) => ({
      ...previousFilters,
      gateway_ids: gateway ? [gateway.id] : undefined,
      page: 1,
    }));
  }

  function handlePipelineFilterChange(pipeline: Pipeline | null) {
    setQueryFilters((previousFilters) => ({
      ...previousFilters,
      pipeline_ids: pipeline ? [pipeline.id] : undefined,
      page: 1,
    }));
  }

  function handlePageChange(page: number) {
    setQueryFilters((previousFilters) => ({
      ...previousFilters,
      page,
    }));
  }

  if (queryResult.isLoading) {
    return <Loader text="Loading deployments..." />;
  }

  return (
    <div className={c('wrap')}>
      <div className={c('top')}>
        <div className={c('filter')}>
          <StateSelect
            className={c('filter-dropdown')}
            onChange={handleStateFilterChange}
            options={DEPLOYMENT_STATES}
            size="small"
          />
          <PipelineSelect
            className={c('filter-dropdown')}
            onChange={handlePipelineFilterChange}
            size="small"
          />
          <GatewaySelect
            className={c('filter-dropdown')}
            onChange={handleGatewayFilterChange}
            size="small"
          />
        </div>
        <div>{queryResult?.data?.total_elements} Deployments</div>
        {hasAccess('deploy_edit') && (
          <>
            <div className={c('spacer')}></div>

            <Button
              onClick={() => toggleIsDeploying()}
              variant="primary"
              size="small"
            >
              Deploy a Pipeline
            </Button>
          </>
        )}
      </div>
      <DeploymentList
        queryResult={queryResult}
        page={queryFilters.page}
        onPageChange={handlePageChange}
        compact
      />
      <Drawer
        title="Deploy pipeline"
        containerClassName={deploymentFormDrawerClassName}
        onClose={toggleIsDeploying}
        open={isDeploying}
      >
        <NewDeploymentForm
          onSuccess={({ id }) => {
            toggleIsDeploying(false);
            navigate({ pathname: `./ref/${id}`, search });
          }}
          allowedStreamIds={allowedStreamIds}
          allowedCameraIds={allowedCameraIds}
        />
      </Drawer>
    </div>
  );
}
