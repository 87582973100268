import React from 'react';
import classnames from 'classnames/bind';
import { CellProps } from 'react-table';

import Account from 'types/account';
import APIError from 'types/api_error';
import {
  ApplicationRole,
  isApplicationRole,
  isOrganizationRole,
  OrganizationRole,
} from 'types/account_role';
import OrganizationInvitation from 'types/invitation';

import { addErrorNotification } from 'services/notification';
import { useAccount } from 'hooks/api/useAccount';
import { useAddApplicationRoles } from 'settings/hooks/useAddApplicationRoles';
import { useAddOrganizationRoles } from 'settings/hooks/useAddOrganizationRoles';
import { useCurrentApplication } from 'application/hooks/useCurrentApplication';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useDeleteApplicationRoles } from 'settings/hooks/useDeleteApplicationRoles';
import { useDeleteOrganizationRoles } from 'settings/hooks/useDeleteOrganizationRoles';

import { Checkbox } from 'components/Checkbox/Checkbox';

import styles from './Table.module.scss';

const c = classnames.bind(styles);

type RoleCellProps<TRole extends ApplicationRole | OrganizationRole> = {
  props: CellProps<Account> | CellProps<OrganizationInvitation>;
  role: TRole;
};

export function OrganizationRoleCell({
  props: { row },
  role,
}: RoleCellProps<OrganizationRole>) {
  const { data: currentAccount } = useAccount();
  const { data: organization } = useCurrentOrganization();

  const { mutate: addOrganizationRoles } = useAddOrganizationRoles({
    onError: handleError,
  });

  const [requestDeleteOrganizationRoles] = useDeleteOrganizationRoles({
    onError: handleError,
  });

  function handleError(error: APIError) {
    addErrorNotification({ title: 'Failed to update role.', error });
  }

  const isReadOnly = row.original instanceof OrganizationInvitation;
  const isDisabled = !organization || !currentAccount || isReadOnly;

  // Owner & billing roles can only be changed by members with owner role
  const canEdit =
    currentAccount && organization
      ? currentAccount.roles.some(
          ({ name, organization_id }) =>
            organization_id &&
            organization_id === organization.id &&
            name === 'owner'
        ) || role === 'manager'
      : false;

  const isTrueOwner = Boolean(
    organization &&
      organization.account_id === row.original.id &&
      role === 'owner'
  );

  const isChecked =
    isOrganizationRole(role) && organization
      ? row.original.roles.some(
          ({ name, organization_id }) =>
            organization_id &&
            organization_id === organization.id &&
            name === role
        )
      : false;

  function handleToggleOrganizationRole({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (
      !isOrganizationRole(value) ||
      !(row.original instanceof Account) ||
      isDisabled
    ) {
      return;
    }

    if (checked) {
      addOrganizationRoles({ account: row.original, newRoles: [value] });
    } else {
      requestDeleteOrganizationRoles({
        account: row.original,
        deletedRoles: [value],
      });
    }
  }

  return (
    <Checkbox
      id={`${row.original.id}-${role}`}
      value={role}
      className={c('checkbox')}
      checked={isChecked}
      onChange={handleToggleOrganizationRole}
      disabled={isDisabled || isTrueOwner || !canEdit}
    />
  );
}

export function ApplicationRoleCell({
  props: { row },
  role,
}: RoleCellProps<ApplicationRole>) {
  const { data: application, isLoading: isLoading } = useCurrentApplication();

  const { mutate: addApplicationRoles } = useAddApplicationRoles({
    onError: handleError,
  });

  const [requestDeleteApplicationRoles] = useDeleteApplicationRoles({
    onError: handleError,
  });

  function handleError(error: APIError) {
    addErrorNotification({ title: 'Failed to update role.', error });
  }

  const isReadOnly = row.original instanceof OrganizationInvitation;
  const isDisabled = !application || isReadOnly || isLoading;

  const isChecked =
    isApplicationRole(role) && application
      ? row.original.roles.some(
          ({ name, application_id }) =>
            application_id && application_id === application.id && name === role
        )
      : false;

  function handleToggleApplicationRole({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (
      !isApplicationRole(value) ||
      !(row.original instanceof Account) ||
      isDisabled
    ) {
      return;
    }

    if (checked) {
      addApplicationRoles({ account: row.original, newRoles: [value] });
    } else {
      requestDeleteApplicationRoles({
        account: row.original,
        deletedRoles: [value],
      });
    }
  }

  return (
    <Checkbox
      id={`${row.original.id}-${role}`}
      value={role}
      className={c('checkbox')}
      checked={isChecked}
      onChange={handleToggleApplicationRole}
      disabled={isDisabled}
    />
  );
}
