import React from 'react';
import classnames from 'classnames/bind';

import { AppLink, AppLinkProps } from 'components/AppLink/AppLink';
import { Icon, IconType } from 'components/Icon/Icon';

import styles from './Sidebar.module.scss';

const c = classnames.bind(styles);

export type SidebarLinkProps = React.PropsWithChildren<{
  icon?: IconType;
  action?: JSX.Element;
}> &
  AppLinkProps;

export function SidebarLink({
  icon,
  children: label,
  action,
  ...props
}: SidebarLinkProps) {
  return (
    <li className={c('link-wrap')}>
      <AppLink
        {...props}
        className={({ isActive }) =>
          c('link', { selected: isActive, 'theme primary': isActive })
        }
      >
        {icon && <Icon name={icon} />}
        <span>{label}</span>
      </AppLink>

      <div className={c('link-action')}>{action}</div>
    </li>
  );
}
