import React from 'react';
import classNames from 'classnames/bind';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Icon, IconType } from 'components/Icon/Icon';

import { useContextMenuState } from './ContextMenu';
import styles from './ContextMenu.module.scss';

const c = classNames.bind(styles);

export type ContextMenuOptionProps = DropdownMenu.DropdownMenuRadioItemProps & {
  icon?: IconType;
  variant?: 'danger';
  action?: React.ReactElement;
};

export function ContextMenuOption({
  icon,
  variant,
  children,
  action,
  disabled,
  ...props
}: ContextMenuOptionProps) {
  const { setOpen } = useContextMenuState();

  function handleActionClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    setOpen(false);
  }

  return (
    <DropdownMenu.RadioItem
      {...props}
      className={c('option', 'type-option', `theme ${variant}`, { disabled })}
      disabled={disabled}
    >
      {icon && <Icon name={icon} />}
      <div className={c('option-content')}>{children}</div>
      {action && (
        <div className={c('action')} onClick={handleActionClick}>
          {action}
        </div>
      )}
      <div className={c('indicator')}>
        <DropdownMenu.ItemIndicator>
          <Icon name="check" size="small" />
        </DropdownMenu.ItemIndicator>
      </div>
    </DropdownMenu.RadioItem>
  );
}
