import React from 'react';
import classnames from 'classnames/bind';
import { NodeEditor } from 'rete';

import { ComponentType } from 'pipelines/services/rete/nodes/component';
import { NodeCategory } from 'pipelines/services/rete/nodes/categories';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { usePlanIncludedNodes } from 'hooks/usePlanIncludedNodes';

import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import { PricingPlanLabel } from 'pipelines/components/PricingPlanLabel/PricingPlanLabel';

import styles from './DockNode.module.scss';

const c = classnames.bind(styles);

export type DockNodeProps = {
  editor: NodeEditor;
  Component: ComponentType;
  category: NodeCategory;
};

export function DockNode({ editor, Component, category }: DockNodeProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [{ starterNodes, businessNodes }] = usePlanIncludedNodes();
  const { isStarterPlan, isBusinessPlan } = useCurrentPlan();

  if (!Component || Component.status === 'deprecated') {
    return null;
  }

  const { icon, description, status } = Component;

  if (!starterNodes || !businessNodes) {
    return null;
  }

  const isStarterPlanNode = starterNodes.includes(Component.exportType);
  const isBusinessPlanNode = businessNodes.includes(Component.exportType);

  let plan: 'starter' | 'business' | 'custom' = 'custom';

  if (isStarterPlanNode) {
    plan = 'starter';
  } else if (isBusinessPlanNode) {
    plan = 'business';
  }

  const isDisabled =
    (isStarterPlan && !isStarterPlanNode) ||
    (isBusinessPlan && !isBusinessPlanNode);

  async function createEditorNode() {
    if (isDisabled) {
      return;
    }

    const Cmp = new Component();
    const node = await Cmp.createNode({});
    node.position = [0, 0];
    editor.addNode(node);
  }

  async function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (!ref.current) {
      return;
    }

    // eslint-disable-next-line default-case
    switch (event.key) {
      case ' ':
      case 'Enter': {
        event.preventDefault();
        await createEditorNode();
        ref.current.blur();
      }
    }
  }

  function handleDragStart(event: React.DragEvent<HTMLDivElement>) {
    if (!event.dataTransfer || isDisabled) {
      return;
    }

    event.dataTransfer.setData('componentName', new Component().name);
  }

  return (
    <div
      className={c('wrap', 'dock-item', { disabled: isDisabled })}
      tabIndex={-1}
      draggable={!isDisabled}
      onClick={createEditorNode}
      onKeyDown={handleKeyDown}
      onDragStart={handleDragStart}
      aria-disabled={isDisabled}
      ref={ref}
    >
      <div
        className={c('node', 'card', {
          disabled: isDisabled,
        })}
      >
        <div className={c('node-icon', `theme ${category.theme}`)}>
          {icon && <Icon name={icon} />}
        </div>

        <div className={c('node-content')}>
          <strong className={c('node-title')} data-search>
            {Component.key}
          </strong>
          {description && (
            <Text size="small" data-search>
              {description}
            </Text>
          )}
        </div>

        {status && status === 'beta' && (
          <div className={c('node-status', status)}>Beta</div>
        )}

        <PricingPlanLabel
          className={c('node-plan-label')}
          plan={plan}
          hasLink
        />

        <Icon name="drag-indicator" className={c('dragger')} />
      </div>
    </div>
  );
}
