import React from 'react';
import classNames from 'classnames/bind';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { Controller, useForm, Validate } from 'react-hook-form';

import { useSpringState } from 'hooks/useSpringState';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Heading } from 'components/Heading/Heading';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { Meter, MeterValue } from 'components/Meter';
import {
  NumberInput,
  NumberInputProps,
} from 'components/NumberInput/NumberInput';
import { Text } from 'components/Text/Text';
import * as Dialog from 'components/Dialog';

import styles from './UtilizationLimitDialog.module.scss';

const c = classNames.bind(styles);

export type UtilizationLimitDialogProps = {
  trigger: JSX.Element;
  item: string;

  usage: number;
  userLimit: number | null | undefined;
  includedInPlan: number | null | undefined;
  includedInPlanInfo: string;

  unit?: 'filesize' | string;
  limitUnit?: string;
  step?: number;
  isLoading?: boolean;
  isSuccess?: boolean;

  InputComponent?: React.FunctionComponent<NumberInputProps>;

  onSave: (values: UtilizationLimitFieldValues) => Promise<number | null>;
  validate?:
    | Validate<number | '' | undefined, UtilizationLimitFieldValues>
    | Record<
        string,
        Validate<number | '' | undefined, UtilizationLimitFieldValues>
      >;
};

export type UtilizationLimitFieldValues = {
  shouldLimitUtilization: boolean;
  utilizationLimit?: number | '';
};

function getValue(
  value: number | null | undefined | '',
  includedInPlan: number | null | undefined
) {
  return Number.isFinite(value) ? Number(value) - (includedInPlan ?? 0) : '';
}

export function UtilizationLimitDialog({
  trigger,
  item,
  usage,
  userLimit,
  includedInPlan,
  includedInPlanInfo,
  unit,
  limitUnit,
  step,
  isLoading,
  isSuccess,
  InputComponent,
  validate,
  onSave,
}: UtilizationLimitDialogProps) {
  const {
    control,
    formState: { errors },
    register,
    watch,
    handleSubmit,
    reset,
  } = useForm<UtilizationLimitFieldValues>({
    defaultValues: {
      shouldLimitUtilization: Number.isFinite(userLimit),
      utilizationLimit: getValue(userLimit, includedInPlan),
    },
    mode: 'onSubmit',
  });

  const [isOpen, setIsOpen] = React.useState(false);
  const [didSave, setDidSave] = useSpringState(false, 2000);

  const shouldLimitUtilization = watch('shouldLimitUtilization');

  React.useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setDidSave(isSuccess);
  }, [isSuccess, setDidSave]);

  React.useEffect(() => {
    if (!didSave) {
      return;
    }

    return () => {
      setIsOpen(false);
    };
  }, [didSave]);

  async function onSubmit({
    shouldLimitUtilization,
    utilizationLimit,
  }: UtilizationLimitFieldValues) {
    let nextUtilizationLimit = utilizationLimit;

    if (shouldLimitUtilization && Number.isFinite(utilizationLimit)) {
      nextUtilizationLimit = (includedInPlan ?? 0) + Number(utilizationLimit);
    }

    const updatedUtilizationLimit = await onSave({
      shouldLimitUtilization,
      utilizationLimit: nextUtilizationLimit,
    });

    reset({
      shouldLimitUtilization: Number.isFinite(updatedUtilizationLimit),
      utilizationLimit: getValue(updatedUtilizationLimit, includedInPlan),
    });
  }

  return (
    <Dialog.Root
      className={c('dialog')}
      trigger={trigger}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Dialog.Title>
        <Heading level="3" asChild>
          <span>{item} utilization</span>
        </Heading>
      </Dialog.Title>

      {didSave && (
        <Dialog.Message intent="success">
          Utilization limits updated!
        </Dialog.Message>
      )}

      <div className={c('meter')}>
        <MeterValue value={usage} max={includedInPlan} unit={unit} />
        {includedInPlan && <Meter value={usage} max={includedInPlan} />}
      </div>

      <dl className={c('current-billing')}>
        <div className={c('current-billing-item')}>
          <dt className="input-label">Included in plan</dt>
          <Heading level="3" asChild>
            <dd>{includedInPlanInfo}</dd>
          </Heading>
        </div>
      </dl>

      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <div className={c('toggle')}>
            <LightSwitch
              id="max-limit-toggle"
              {...register('shouldLimitUtilization')}
            />
            <label htmlFor="max-limit-toggle">
              <p className="input-label">
                Limit
                {Number.isFinite(includedInPlan) ? ' additional' : ''}{' '}
                utilization
              </p>
              <Text>Overage pricing according to your selected plan.</Text>
            </label>
          </div>

          {shouldLimitUtilization && (
            <div
              className={c('toggle-input', { error: errors.utilizationLimit })}
            >
              <VisuallyHidden>
                <label htmlFor="max-limit">
                  Maximum additional utilization{' '}
                  {limitUnit && `in ${limitUnit}`}
                </label>
              </VisuallyHidden>
              <Controller
                name="utilizationLimit"
                control={control}
                render={({ field }) => {
                  if (InputComponent) {
                    return (
                      <InputComponent
                        {...field}
                        id="max-limit"
                        unit={limitUnit}
                        min={0}
                        step={step}
                      />
                    );
                  }

                  return (
                    <NumberInput
                      {...field}
                      id="max-limit"
                      unit={limitUnit}
                      min={0}
                      step={step}
                    />
                  );
                }}
                rules={{
                  required: 'Please enter a utilization limit.',
                  min: {
                    value: 0,
                    message:
                      'Please enter a number greater than or equal to 0.',
                  },
                  validate,
                }}
              />
              {errors.utilizationLimit && (
                <Text intent="danger">{errors.utilizationLimit.message}</Text>
              )}
            </div>
          )}
        </fieldset>

        <ButtonGroup>
          <Button type="submit" variant="primary" loading={isLoading}>
            Save changes
          </Button>

          <Dialog.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
        </ButtonGroup>
      </form>
    </Dialog.Root>
  );
}
