import Pipeline from 'types/pipeline';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { usePlanIncludedNodes } from 'hooks/usePlanIncludedNodes';

/**
 * Check if the pipeline does not contain nodes from a higher plan
 */
export function useCanDeployPipeline(pipeline?: Pipeline | null): boolean {
  const { data: currentPlan } = useCurrentPlan();
  const [{ starterNodes, businessNodes }] = usePlanIncludedNodes();

  if (
    !pipeline ||
    // Users shouldn't be able to quickly click the deploy button while loading
    currentPlan === undefined ||
    !starterNodes ||
    !businessNodes
  ) {
    return false;
  }

  if (currentPlan === null) {
    return true;
  }

  const isStarterPlan =
    currentPlan.id === process.env.REACT_APP_STARTER_PLAN_ID;
  const isBusinessPlan =
    currentPlan.id === process.env.REACT_APP_BUSINESS_PLAN_ID;

  const pipelineNodes = pipeline.nodes
    .map(({ component }) => component?.exportType)
    .filter(Boolean) as string[];

  const hasNodesFromHigherPlan = pipelineNodes.some((exportType) => {
    const isStarterPlanNode = starterNodes.includes(exportType);
    const isBusinessPlanNode = businessNodes.includes(exportType);

    if (isStarterPlan && !isStarterPlanNode) {
      return true;
    }

    if (isBusinessPlan && !isStarterPlanNode && !isBusinessPlanNode) {
      return true;
    }

    return false;
  });

  return !hasNodesFromHigherPlan;
}
