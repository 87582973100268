import { type IconType } from 'components/Icon/Icon';
import { ICON_NAMES } from 'components/Icon/types/Icon';

export const FILES_SEARCH_ICONS_MAP: Record<string, IconType> = {
  ...Object.fromEntries(ICON_NAMES.map((icon) => [icon, icon])),
  tvmonitor: 'stream',
  boat: 'ferry',
  motorcycle: 'motorbike',
  bicycle: 'bike',
  backpack: 'bag',

  'cell phone': 'phone',
  mouse: 'laptop',
  keyboard: 'laptop',

  handbag: 'bag',

  'tennis racket': 'tennis',
  'sports ball': 'soccer',
  'baseball bat': 'baseball-bat',
  'baseball glove': 'baseball-bat',

  plate: 'cutlery',
  knife: 'cutlery',
  spoon: 'cutlery',
  fork: 'cutlery',
  bowl: 'cutlery',
  cup: 'cutlery',
  'wine glass': 'cutlery',
  bottle: 'cutlery',
  'dining table': 'cutlery',

  orange: 'food',
  banana: 'food',
  sandwich: 'food',
  broccoli: 'food',
  carrot: 'food',
  apple: 'food',
  donut: 'food',
  cake: 'food',
  'hot dog': 'food',
  pizza: 'food',
};
