import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import { SharedStream } from 'types/shared_stream';

import { useAPI } from 'hooks/api/useAPI';

type UseCreateSharedStreamParams = Pick<
  SharedStream,
  'name' | 'stream_id' | 'expires_at'
>;

export function useCreateSharedStream({
  onSettled,
  ...options
}: UseMutationOptions<
  SharedStream,
  APIError,
  UseCreateSharedStreamParams
> = {}) {
  const queryClient = useQueryClient();
  const { client, applicationID } = useAPI();

  return useMutation({
    mutationFn: async (params) => {
      const { data } = await client.post<SharedStream>(
        `/apps/${applicationID}/shared_streams`,
        params
      );

      return data;
    },
    ...options,
    onSettled: (...args) => {
      onSettled?.(...args);

      queryClient.invalidateQueries(['shared-streams']);
    },
  });
}
