import React from 'react';
import classnames from 'classnames/bind';
import { Pagination } from '@mantine/core';

import Category from 'types/category';
import PipelineTemplate from 'types/pipeline_template';

import { generateId } from 'services/string';
import { useAppRedirect } from 'hooks/useAppRedirect';
import { useCreatePipeline } from 'pipelines/hooks/useCreatePipeline';
import {
  UsePipelineTemplatesFilterParams,
  usePipelineTemplates,
} from 'pipelines/hooks/usePipelineTemplates';

import { EmptyView } from 'components/EmptyView/EmptyView';
import {
  PipelineTemplateCard,
  PipelineTemplateCardSkeleton,
} from 'analytics-library/components/PipelineTemplateCard/PipelineTemplateCard';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { TableManager } from 'components/Table/Table';

import { ReactComponent as EmptyIllustration } from 'analytics-library/images/empty-models-illustration.svg';
import styles from './PipelineTemplateGrid.module.scss';

const c = classnames.bind(styles);

export type PipelineTemplateGridContentProps = {
  categories: Category[];
  filters?: Omit<UsePipelineTemplatesFilterParams, 'category_labels'>;
  page: number;
  onPageChange: (page: number) => void;
  onCreatePipeline?: () => void;
};

export function PipelineTemplateGridContent({
  page,
  onPageChange,
  categories,
  filters,
  onCreatePipeline,
}: PipelineTemplateGridContentProps) {
  const { data, isLoading, isPreviousData } = usePipelineTemplates(
    {
      ...filters,
      page,
      category_labels: categories.map(({ label }) => label),
    },
    {
      keepPreviousData: true,
    }
  );

  const hasFilters = categories.length > 0;

  const redirect = useAppRedirect();

  const { mutate: createPipeline, isLoading: isCreating } = useCreatePipeline({
    onSuccess(pipeline) {
      redirect(`/design/pipelines/${pipeline.id}`);
      onCreatePipeline?.();
    },
  });
  const [creatingFromTemplateID, setCreatingFromTemplateID] =
    React.useState<PipelineTemplate['id']>();

  function createPipelineFromTemplate({
    id,
    name,
    definition,
  }: PipelineTemplate) {
    createPipeline({
      name: `Pipeline ${generateId().substring(1, 7)} – ${name}`,
      definition,
    });
    setCreatingFromTemplateID(id);
  }

  if (isLoading) {
    return (
      <div className={c('template-grid')}>
        <SkeletonList
          component={<PipelineTemplateCardSkeleton />}
          min={7}
          max={22}
        />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={c('empty')}>
        <EmptyView
          image={EmptyIllustration}
          title={
            hasFilters
              ? 'No solution templates matching your search input found.'
              : 'No solution templates available.'
          }
        />
      </div>
    );
  }

  return (
    <>
      <div className={c('template-grid')}>
        {isLoading || isPreviousData ? (
          <SkeletonList
            component={<PipelineTemplateCardSkeleton />}
            min={7}
            max={22}
          />
        ) : (
          data.data.map((template) => (
            <PipelineTemplateCard
              template={template}
              onClick={createPipelineFromTemplate}
              isLoading={creatingFromTemplateID === template.id && isCreating}
              key={template.id}
              showCreateButton
            />
          ))
        )}
      </div>

      <TableManager>
        <Pagination
          total={data.total_pages}
          value={page}
          onChange={onPageChange}
        />
      </TableManager>
    </>
  );
}
