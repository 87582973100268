import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { FloatInput } from 'components/NumberInput/NumberInput';

import { FIELD_LABEL_WIDTH, Step2FieldValues } from '../Step2';

export function ClassifierThresholdInput() {
  const {
    control,
    formState: { errors },
  } = useFormContext<Step2FieldValues>();

  return (
    <Field
      id="classifier_threshold"
      label="Classifier threshold"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      info="Minimum confidence score needed to consider an object."
      error={errors.inference_config?.classifier_threshold}
      required
    >
      <Controller
        name="inference_config.classifier_threshold"
        control={control}
        defaultValue={0.1}
        render={({ field: { value, onChange } }) => (
          <FloatInput
            value={value}
            onChange={onChange}
            id="classifier_threshold"
            step={0.01}
            min={0}
          />
        )}
        rules={{ required: 'Please enter a value.' }}
      />
    </Field>
  );
}
