import React from 'react';

import AIModel from 'types/ai_model';
import APIError from 'types/api_error';

import {
  UseCreateModelVariables,
  UseUpdateModelVariables,
} from 'hooks/api/useModel';
import { useReadFilesFromURL } from 'hooks/useReadFilesFromURL';

import { getUpdatedModelFromValues } from 'analytics-library/services/get_updated_model_from_values';
import { getModelFromValues } from 'analytics-library/services/get_model_from_values';

import type { Step1FieldValues, Step2FieldValues, Step3FieldValues } from '..';

export type ModelFormData =
  | (Step1FieldValues & Step2FieldValues & Step3FieldValues)
  | null;

type UpdateAction = {
  type: 'update';
  data: Step1FieldValues | Step2FieldValues | Step3FieldValues;
};

type ClearAction = {
  type: 'clear';
  data?: never;
};

type Action = UpdateAction | ClearAction;

export type ModelFormStateProps = {
  baseUrl?: string;
  model?: AIModel;
  data: ModelFormData;
  isSaving: boolean;
  labels?: string;
  isMarketplace?: boolean;
  isLoadingLabels: boolean;
  error?: APIError | null;
  save: (formData: Partial<ModelFormData>) => Promise<AIModel | undefined>;
  dispatch: React.Dispatch<Action>;
};

const ModelFormContext = React.createContext<ModelFormStateProps | undefined>(
  undefined
);

function dataReducer(
  state: ModelFormData,
  { type, data }: Action
): ModelFormData {
  switch (type) {
    case 'clear': {
      return null;
    }

    case 'update': {
      return {
        ...state,
        ...data,
      } as ModelFormData;
    }
  }
}

export type ModelFormProviderProps = React.PropsWithChildren<{
  /**
   * Do not prompt user on navigation if the target URL contains the baseUrl.
   * Leave blank to always prompt (e.g. if the form is on one page)
   */
  baseUrl?: string;
  model?: AIModel;
  onSave:
    | ((params: UseCreateModelVariables) => Promise<AIModel | undefined>)
    | ((params: UseUpdateModelVariables) => Promise<AIModel | undefined>);
  isSaving: boolean;
  isMarketplace?: boolean;
  error?: APIError | null;
}>;

export function ModelFormProvider({
  children,
  model,
  onSave,
  ...props
}: ModelFormProviderProps) {
  const [data, dispatch] = React.useReducer(dataReducer, null);
  // Only use the first labels file URL. Ignore file URL updates on subsequent
  // model refreshes.
  const [labelsFileURL, setLabelsFileURL] = React.useState(
    model?.labels_file_url
  );
  const [{ data: labels, isInitialLoading: isLoadingLabels }] =
    useReadFilesFromURL(labelsFileURL);

  React.useEffect(() => {
    if (!model || !model.labels_file_url) {
      return;
    }

    setLabelsFileURL((url) => url ?? model.labels_file_url);
  }, [model]);

  const save = React.useCallback(
    (formData: Partial<ModelFormData>) => {
      if (!formData) {
        return Promise.resolve(undefined);
      }

      const files = {
        weights_file: formData.weights_file,
        metadata_file: formData.metadata_file,
        labels_file: formData.labels_file,
      };

      // Save new model
      if (!model) {
        const newModel = getModelFromValues(formData);
        return onSave({ model: newModel, files });
      }

      // Update existing model
      const updatedModel = getUpdatedModelFromValues(model, formData);
      return onSave({ model: updatedModel, files });
    },
    [model, onSave]
  );

  return (
    <ModelFormContext.Provider
      value={{
        ...props,
        model,
        data,
        labels,
        isLoadingLabels,
        save,
        dispatch,
      }}
    >
      {children}
    </ModelFormContext.Provider>
  );
}

export function useModelFormState() {
  const context = React.useContext(ModelFormContext);

  if (context === undefined) {
    throw new Error(
      'Hook `useModelFormState` may only be used within a <ModelFormProvider />.'
    );
  }

  return context;
}
