import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  LineElement,
  Tooltip,
  TimeSeriesScale,
  BarElement,
  PointElement,
} from 'chart.js';
import { FilterInput, TimeSeriesGranularity } from '@propeldata/ui-kit';
import { Bar, Line } from 'react-chartjs-2';

import { useTimeSeries } from 'dashboards/hooks/useTimeSeries';
import { useTimeRangeParams } from 'dashboards/hooks/useTimeRangeParams';

import { ChartError } from './components/ChartError';
import { ChartLoader } from './components/ChartLoader';
import { TimeUnitMap } from '../types/time_unit';
import { hslaToString, MAIN_CHART_COLOR } from '../services/color';

ChartJS.register(
  BarElement,
  LineElement,
  PointElement,
  LinearScale,
  Tooltip,
  TimeSeriesScale
);

export type TimeSeriesProps = {
  query: {
    metric: string;
    granularity: TimeSeriesGranularity;
    filters?: FilterInput[];
  };
  variant: 'bar' | 'line';
};

export function TimeSeries({
  query: { metric, granularity, filters },
  variant,
}: TimeSeriesProps) {
  const { start, stop, timezone } = useTimeRangeParams();

  const { data, isLoading, error } = useTimeSeries({
    metric: { name: metric },
    granularity,
    timeRange: { start, stop },
    filters,
    timeZone: timezone,
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'timeseries' as const,
        time: {
          unit: TimeUnitMap[granularity],
        },
        adapters: {
          date: {
            zone: timezone,
          },
        },
      },
      y: { beginAtZero: true },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  if (error) {
    return (
      <ChartError
        error={{ title: 'No data', body: 'Failed to fetch values.' }}
      />
    );
  }

  if (!data && isLoading) {
    return <ChartLoader />;
  }

  if (!data) {
    return null;
  }

  const [{ labels }] = Object.values(data);
  const datasets = Object.entries(data).map(([_, timeseries]) => ({
    borderRadius: 2,
    backgroundColor: hslaToString(MAIN_CHART_COLOR),
    borderColor: hslaToString(MAIN_CHART_COLOR),
    data: timeseries.values,
  }));

  if (variant === 'bar') {
    return <Bar options={options} data={{ labels, datasets }} />;
  }

  return <Line options={options} data={{ labels, datasets }} />;
}
