function isElementVisible(element: HTMLElement) {
  return getComputedStyle(element).display !== 'none';
}

/**
 * Locate the first visible child of an element, based on display property.
 */
export function findFirstChild(el: HTMLElement): HTMLElement | undefined {
  const first = el.firstElementChild as HTMLElement | undefined;

  if (!first) {
    return undefined;
  }

  if (isElementVisible(first)) {
    return first;
  }

  return findNextSibling(first);
}

/**
 * Locate the last visible child of an element, based on display property.
 */
export function findLastChild(el: HTMLElement): HTMLElement | undefined {
  const last = el.lastElementChild as HTMLElement | undefined;

  if (!last) {
    return undefined;
  }

  if (isElementVisible(last)) {
    return last;
  }

  return findPreviousSibling(last);
}

/**
 * Locate the next visible sibling of an element, based on display property.
 */
export function findNextSibling(el: HTMLElement): HTMLElement | undefined {
  const next = el.nextElementSibling as HTMLElement | undefined;

  if (!next) {
    return undefined;
  }

  if (isElementVisible(next)) {
    return next;
  }

  return findNextSibling(next);
}

/**
 * Locate the previous visible child of an element, based on display property.
 */
export function findPreviousSibling(el: HTMLElement): HTMLElement | undefined {
  const previous = el.previousElementSibling as HTMLElement | undefined;

  if (!previous) {
    return undefined;
  }

  if (isElementVisible(previous)) {
    return previous;
  }

  return findPreviousSibling(previous);
}
