import React from 'react';
import classNames from 'classnames/bind';

import Camera from 'types/camera';

import { Button } from 'components/Button/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';

import styles from './DiscoveredCameraCard.module.scss';

const c = classNames.bind(styles);

type DiscoveredCameraCardProps = {
  camera: Camera;
  isSelected: boolean;
  displaySource: (camera: Camera) => string;
  onLinkButtonClick: () => void;
  onSelect: (isSelected: boolean) => void;
};

export function DiscoveredCameraCard({
  camera,
  isSelected,
  displaySource,
  onLinkButtonClick,
  onSelect,
}: DiscoveredCameraCardProps) {
  const id = `discovery_${camera.id ?? displaySource(camera)}`;

  function handleSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
    onSelect(event.target.checked);
  }

  return (
    <div className={c('camera-card')}>
      <Checkbox
        id={id}
        className={c('camera-check')}
        onChange={handleSelectChange}
        checked={isSelected}
      />

      <label htmlFor={id} className={c('camera-label')}>
        <Heading className={c('camera-name')} level="4">
          {camera.model || camera.name}
        </Heading>
        <Text>{displaySource(camera)}</Text>
      </label>
      <div className={c('button-wrap', 'up-add-camera_link-button')}>
        <Button size="small" variant="secondary" onClick={onLinkButtonClick}>
          Link
        </Button>
      </div>
    </div>
  );
}
