import React from 'react';
import classnames from 'classnames/bind';

import styles from './EntityList.module.scss';

const c = classnames.bind(styles);

export type EntityListProps = React.PropsWithChildren<{
  className?: string;
}>;

export function EntityList({ children, className }: EntityListProps) {
  return <dl className={c('list', className)}>{children}</dl>;
}
