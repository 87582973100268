import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import Camera from 'types/camera';
import Pipeline from 'types/pipeline';

import { useUpdateCamera } from 'cameras/hooks/useUpdateCamera';
import { useCreateReferencePipelines } from 'cameras/hooks/useCreateReferencePipelines';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Input } from 'components/Input/Input';
import { PipelineSelect } from 'pipelines/components/PipelineSelect/PipelineSelect';
import { Text } from 'components/Text/Text';

import { UniversalBridgeDeployments } from './UniversalBridgeDeployments';
import { UniversalBridgePipelines } from './UniversalBridgePipelines';

type UniversalBridgeSettingsFieldValues = {
  external_id: string | null;
  reference_pipeline_ids: any[];
};

type UniversalBridgeSettingsProps = {
  camera: Camera;
};

export function UniversalBridgeSettings({
  camera,
}: UniversalBridgeSettingsProps) {
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    getValues,
    register,
    reset,
    watch,
  } = useForm<UniversalBridgeSettingsFieldValues>({
    defaultValues: {
      external_id: camera.external_id,
      reference_pipeline_ids: [],
    },
  });

  const {
    mutate: createReferencePipelines,
    isLoading: isCreatingReferencePipelines,
    isSuccess: didCreateReferencePipelines,
    error: createError,
  } = useCreateReferencePipelines({
    onSuccess() {
      reset({ reference_pipeline_ids: [] });
    },
  });

  const {
    mutate: updateCamera,
    isLoading: isUpdatingCamera,
    isSuccess: didUpdateCamera,
    error: updateError,
  } = useUpdateCamera({
    onSuccess({ external_id }) {
      reset({ external_id, reference_pipeline_ids: [] });
    },
  });

  const isSuccess = Boolean(watch('reference_pipeline_ids')?.length)
    ? didUpdateCamera && didCreateReferencePipelines
    : didUpdateCamera;
  const isLoading = isUpdatingCamera || isCreatingReferencePipelines;
  const error = updateError || createError;

  function onSubmit({ external_id }: UniversalBridgeSettingsFieldValues) {
    const updatedCamera = camera.copy();
    // Set to null in case string is empty
    updatedCamera.external_id = external_id || null;

    const referencePipelines: Pipeline[] = getValues('reference_pipeline_ids');

    if (referencePipelines) {
      updatedCamera.reference_pipeline_ids = [
        ...new Set(referencePipelines.map(({ id }) => id).filter(Boolean)),
      ] as string[];
    }

    if (updatedCamera.reference_pipeline_ids.length) {
      createReferencePipelines(updatedCamera);
    }

    updateCamera(updatedCamera);
  }

  return (
    <section>
      <Heading level="3">
        Universal Bridge <br />
        <Text type="paragraph">
          <ExternalLink href="https://docs.lumeo.com/docs/universal-bridge">
            Universal bridge docs
          </ExternalLink>
        </Text>
      </Heading>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Field label="External source identifier">
          <Input {...register('external_id')} />
          <Text>
            Universal Bridge will associate incoming clips with this camera
            using this identifier.
          </Text>
        </Field>

        <Field label="Default pipelines">
          <Controller
            name="reference_pipeline_ids"
            control={control}
            render={({ field: { value, onChange } }) => (
              <PipelineSelect value={value} onChange={onChange} isMulti />
            )}
          />
          <Text>
            Universal Bridge will deploy these pipelines for each incoming clip
            associated with this camera.
          </Text>
        </Field>

        <UniversalBridgePipelines camera={camera} />

        <FormErrorMessage error={error} />

        <ButtonGroup>
          <Button
            variant="primary"
            type="submit"
            size="small"
            loading={isLoading}
          >
            Save Universal Bridge settings
          </Button>

          {isDirty && <InlineNotification>Unsaved changes</InlineNotification>}

          {isSuccess && !isDirty && (
            <InlineNotification intent="success">Saved!</InlineNotification>
          )}
        </ButtonGroup>
      </form>

      <UniversalBridgeDeployments camera={camera} />
    </section>
  );
}
