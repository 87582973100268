import classnames from 'classnames/bind';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { IconButton } from 'components/IconButton/IconButton';
import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';
import * as Dialog from 'components/Dialog';

import LockedPipelineImg from 'pipelines/images/locked-pipeline.svg';

import styles from './LockButton.module.scss';

const c = classnames.bind(styles);

export function PipelineDetailLockButton() {
  return (
    <Text>
      <Dialog.Root
        className={c('dialog')}
        trigger={
          <IconButton
            className={c('lock-button')}
            icon="lock"
            size="small"
            label="Locked"
          />
        }
        imageSrc={LockedPipelineImg}
        imageAlt=""
        defaultOpen
      >
        <Dialog.Title>
          <Heading level="2" asChild>
            <span>This pipeline is locked.</span>
          </Heading>
        </Dialog.Title>
        <Dialog.Description className={c('dialog-text')}>
          <Text>
            Another user in your workspace is currently editing this pipeline.
          </Text>
          <Text>
            You will be able to edit once they finish making their changes.
          </Text>
        </Dialog.Description>

        <ButtonGroup align="center">
          <Dialog.Close asChild>
            <Button variant="primary">Ok</Button>
          </Dialog.Close>
        </ButtonGroup>
      </Dialog.Root>

      <span>Locked by another user. Unable to edit.</span>
    </Text>
  );
}
