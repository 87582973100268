import React from 'react';
import classnames from 'classnames/bind';

import { Button } from 'components/Button/Button';

import Row, { emptyRow } from './JsonObjectInputRow';
import {
  deserializeJsonObjectValues,
  serializeJsonObjectValues,
} from './serialize';
import { validateAreKeysUnique } from './validate';
import { JsonObjectInputRowField } from './RowField';
import styles from './JsonObjectInput.module.scss';

const c = classnames.bind(styles);

export type JsonObjectInputProps = {
  value: object;
  onChange: (value: Record<string, string>) => void;
};

export function JsonObjectInput({
  value: initialValues,
  onChange,
}: JsonObjectInputProps) {
  const [rows, setRows] = React.useState<Row[]>(
    deserializeJsonObjectValues(initialValues)
  );
  const [errorRows, setErrorRows] = React.useState<number[]>([]);

  function handleAddClick() {
    setRows((rows) => [...rows, emptyRow]);
  }

  function validate() {
    setErrorRows(validateAreKeysUnique(rows));
  }

  function handleRowChange(row: Row, index: number) {
    setRows((rows) => [...rows.slice(0, index), row, ...rows.slice(index + 1)]);
  }

  React.useEffect(() => {
    setErrorRows(validateAreKeysUnique(rows));
    onChange(serializeJsonObjectValues(rows));
  }, [rows, onChange]);

  return (
    <fieldset className={c('wrap', 'form')}>
      {rows.map((row, index) => (
        <JsonObjectInputRowField
          id={`row_${index}`}
          error={errorRows.includes(index)}
          value={row}
          onChange={(row) => handleRowChange(row, index)}
          onBlur={validate}
          key={index}
        />
      ))}
      <Button
        className={c('button')}
        variant="secondary"
        onClick={handleAddClick}
        size="small"
      >
        Add parameter
      </Button>
    </fieldset>
  );
}
