import { DeploymentConfiguration } from 'deployments/types/DeploymentForm';

export function mergeConfigurations(
  baseConfig: DeploymentConfiguration,
  overrideConfig: DeploymentConfiguration
): DeploymentConfiguration {
  return Object.keys(overrideConfig).reduce(
    (acc, key) => {
      acc[key] = {
        ...baseConfig[key],
        ...overrideConfig[key],
      };
      return acc;
    },
    { ...baseConfig }
  );
}
