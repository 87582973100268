import React from 'react';
import classNames from 'classnames/bind';
import { Menu } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';

import { AppEntityObject } from 'hooks/useEntities';

import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './DetailViewStatus.module.scss';

const c = classNames.bind(styles);

export type DetailViewStatusProps<T extends AppEntityObject> =
  React.PropsWithChildren<{
    entity: T;
    onRename?: () => void;
  }>;

export function DetailViewStatus<T extends AppEntityObject>({
  children,
  entity,
  onRename,
}: DetailViewStatusProps<T>) {
  const { copy, copied: isCopied } = useClipboard({ timeout: 2000 });

  return (
    <div className={c('status')}>
      {children}

      <Tooltip side="right" content="Copied ID to clipboard!" open={isCopied}>
        <div>
          <Menu position="bottom-start">
            <Menu.Target>
              <IconButton
                icon="more-vertical"
                label="More actions"
                variant="tertiary"
                size="small"
                showLabel
              />
            </Menu.Target>

            {/* TODO: Upgrade Mantine, move icon into leftSection prop */}
            <Menu.Dropdown>
              <Menu.Item onClick={() => copy(entity.id)}>
                <Icon name="copy" size="small" />
                <span>Copy ID</span>
              </Menu.Item>
              {onRename && (
                <Menu.Item onClick={onRename}>
                  <Icon name="edit" size="small" />
                  <span>Rename</span>
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        </div>
      </Tooltip>
    </div>
  );
}
