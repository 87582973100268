export function getScrollParent(element: HTMLElement | null): HTMLElement {
  const isElement = element instanceof HTMLElement;
  const overflowY = isElement && window.getComputedStyle(element).overflowY;
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

  if (!element) {
    return document.body;
  }

  if (isScrollable && element.scrollHeight >= element.clientHeight) {
    return element;
  }

  return getScrollParent(element.parentNode as HTMLElement) || document.body;
}
