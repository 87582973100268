import Camera from 'types/camera';
import Stream, { StreamType } from 'types/stream';

import { IconType } from 'components/Icon/Icon';

export function getVideoSourceIcon(videoSource: Camera | Stream): IconType {
  if (videoSource.source_type === 'camera') {
    return 'camera';
  }

  switch ((videoSource as Stream).stream_type) {
    case StreamType.FILE:
      return 'file';
    default:
      return 'stream';
  }
}
