import {
  DimensionInput,
  FilterInput,
  Propeller,
  TimeRangeInput,
  TimeSeriesGranularity,
} from '@propeldata/ui-kit';
import { gql } from 'graphql-request';

export type TimeSeriesQueryVariables = {
  metric: { name: string };
  granularity: TimeSeriesGranularity;
  timeRange: TimeRangeInput;
  filters?: FilterInput[];
  propeller?: Propeller;
  timeZone: string;
};

export type TimerSeriesResponse = {
  [key: string]: { labels: string[]; values: number[] };
};

// timeseries-grouped charts
export type CustomTimeSeriesQueryVariables = {
  metric: { name: string };
  dimensions: DimensionInput[];
  granularity: TimeSeriesGranularity;
  timeRange: TimeRangeInput;
  filters?: FilterInput[];
  propeller?: Propeller;
  timeZone: string;
};

/**
 * Fetch a metric in time series format.
 */
export const TIMESERIES = gql`
  query TimeSeries($input: TimeSeriesInput!) {
    timeSeries(input: $input) {
      labels
      values
    }
  }
`;
