import React from 'react';
import { useParams } from 'react-router-dom';

import { MqttClientProvider } from 'context/mqtt/MqttClientProvider';

import { useAPI } from 'hooks/api/useAPI';
import { useApplication } from 'application/hooks/useApplication';

import { Loader } from 'components/Loader/Loader';

import { App } from './App';
import { AppNotFound } from './AppNotFound';

export type ApplicationParams = {
  applicationID: string;
};

export function AppContainer() {
  const { applicationID } = useParams<ApplicationParams>();
  const { setApplicationID } = useAPI();
  const { error, isLoading } = useApplication(applicationID);

  const hasApplicationError =
    error && (error.status === 404 || error.status === 401);

  React.useEffect(() => {
    if (isLoading || hasApplicationError || !applicationID) {
      return;
    }

    setApplicationID(applicationID);
  }, [isLoading, hasApplicationError, applicationID, setApplicationID]);

  if (isLoading) {
    return <Loader text="Loading your workspace..." showLogo />;
  }

  if (hasApplicationError) {
    return <AppNotFound />;
  }

  return (
    <MqttClientProvider>
      <App />
    </MqttClientProvider>
  );
}

export default AppContainer;
