import { Radio, RadioOption, RadioProps } from 'components/Radio/Radio';

export type LogicalOperator = 'AND' | 'OR';

export type LogicalOperatorSelectProps = Pick<
  RadioProps,
  'id' | 'value' | 'onChange'
>;

export function LogicalOperatorSelect(props: LogicalOperatorSelectProps) {
  return (
    <Radio {...props}>
      <RadioOption value="AND">AND</RadioOption>
      <RadioOption value="OR">OR</RadioOption>
    </Radio>
  );
}
