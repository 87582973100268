import { captureMessage } from '@sentry/react';

import { ROI_CONFIG } from '../config';

/** Round with 4 decimal points */
function roundFloat(num: number): number {
  return Math.round(num * 10_000) / 10_000;
}

/**
 * Normalize shape coordinates to values between 0 and 1.
 */
export function normalizeROIShapes(
  shapes: Record<string, number[]>,
  width: number,
  height: number
): Record<string, number[]> {
  const innerWidth = width - ROI_CONFIG.padding * 2;
  const innerHeight = height - ROI_CONFIG.padding * 2;

  if (innerWidth <= 0 || innerHeight <= 0) {
    return shapes;
  }

  return Object.entries(shapes).reduce<Record<string, number[]>>(
    (normalized, [label, shape]) => {
      if (shape) {
        normalized[label] = shape.map((num, i) => {
          const offsetNum = num - ROI_CONFIG.padding;

          // X
          if (i % 2 === 0) {
            return roundFloat(offsetNum / innerWidth);
          }

          // Y
          return roundFloat(offsetNum / innerHeight);
        });
      } else {
        captureMessage(
          `Shape for label '${label}' is invalid: ${shape}`,
          'debug'
        );
      }

      return normalized;
    },
    {}
  );
}

/**
 * Scale normalized coordinates to canvas size.
 */
export function denormalizeROIShapes(
  shapes: Record<string, number[]>,
  width: number,
  height: number
): Record<string, number[]> {
  if (width === 0 || height === 0) {
    return shapes;
  }

  const innerWidth = width - ROI_CONFIG.padding * 2;
  const innerHeight = height - ROI_CONFIG.padding * 2;

  return Object.entries(shapes).reduce<Record<string, number[]>>(
    (denormalized, [label, shape]) => {
      if (shape) {
        denormalized[label] = shape.map((num, i) => {
          // X
          if (i % 2 === 0) {
            return roundFloat(num * innerWidth + ROI_CONFIG.padding);
          }

          // Y
          return roundFloat(num * innerHeight + ROI_CONFIG.padding);
        });
      } else {
        captureMessage(
          `Shape for label '${label}' is invalid: ${shape}`,
          'debug'
        );
      }

      return denormalized;
    },
    {}
  );
}
