import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import { GatewayConfiguration } from 'types/gateway_configuration';

import { useAPI } from 'hooks/api/useAPI';
import { useCurrentApplication } from 'application/hooks/useCurrentApplication';
import { useUpdateApplication } from 'application/hooks/useUpdateApplication';
import { updateQueriesData } from 'services/update_queries_data';

export type UseUpdateGatewayConfigurationParams = Pick<
  GatewayConfiguration,
  'id' | 'metrics'
>;

export function useUpdateGatewayConfiguration({
  onMutate,
  onSettled,
  onError,
  ...options
}: UseMutationOptions<
  GatewayConfiguration,
  APIError,
  UseUpdateGatewayConfigurationParams
> = {}) {
  const queryClient = useQueryClient();
  const api = useAPI();

  const { data: application } = useCurrentApplication();
  const { mutate: updateApplication } = useUpdateApplication();

  function updateDefaultGatewayConfigurationID(
    gatewayConfiguration: GatewayConfiguration
  ) {
    if (
      !gatewayConfiguration.id ||
      !gatewayConfiguration.metrics ||
      !application
    ) {
      return;
    }

    const updatedApplication = application.copy();
    updatedApplication.default_gateway_configuration_id =
      gatewayConfiguration.id;

    updateApplication(updatedApplication);
  }

  return useMutation(api.applications.gatewayConfiguration.update, {
    ...options,
    async onMutate(configuration) {
      if (application?.id) {
        await queryClient.cancelQueries([
          'gateway-configuration',
          configuration.id,
          application?.id,
        ]);
      }
      await queryClient.cancelQueries(['gateway-configurations']);

      return updateQueriesData({
        queryClient,
        listQueryKey: ['gateway-configurations'],
        singleQueryKey: ['gateway-configuration'],
        ids: [configuration.id],
        updateData: configuration,
      });
    },
    onError(error, variables, context) {
      onError?.(error, variables, context);

      context?.previousLists?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSettled: (gatewayConfiguration, ...args) => {
      // Currently there can only be one gateway configuration. It is set as the default automatically.
      // In the future this should be user controlled.
      if (gatewayConfiguration) {
        updateDefaultGatewayConfigurationID(gatewayConfiguration);
      }

      onSettled?.(gatewayConfiguration, ...args);

      queryClient.invalidateQueries(['gateway-configurations']);
    },
  });
}
