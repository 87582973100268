import React from 'react';
import classNames from 'classnames/bind';

import { LumeoFile } from 'types/file';

import { useFile } from 'files/hooks/useFile';

import { Icon } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/Text/Text';

import styles from './Lightbox.module.scss';

const c = classNames.bind(styles);

export function Preview({ id }: { id: LumeoFile['id'] }) {
  const { data: file, isLoading } = useFile(id);

  if (isLoading) {
    return (
      <div className={c('preview')}>
        <Loader text="Loading media..." size="small" />
      </div>
    );
  }

  if (!file) {
    return null;
  }

  const { data_url, mime_type } = file;

  if (!data_url) {
    return (
      <div className={c('preview')}>
        <Icon name="error" className="theme danger" size="large" />
        <Text>Unable to load media.</Text>
      </div>
    );
  }

  if (mime_type.includes('video')) {
    return (
      <div className={c('preview')}>
        <video controls muted autoPlay>
          <source src={data_url} type={mime_type} />
          Failed to load the video.
        </video>
      </div>
    );
  }

  if (mime_type.includes('image')) {
    return (
      <div className={c('preview')}>
        <img src={data_url} alt="" />
      </div>
    );
  }

  return (
    <div className={c('preview')}>
      <Icon name="error" className="theme danger" size="large" />
      <Text>Unable to view file of type {mime_type}.</Text>
    </div>
  );
}
