import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Gateway from 'types/gateway';

import { useAPI } from 'hooks/api/useAPI';
import { useMutationWithError } from 'hooks/api/useMutationWithError';

export type UseCreateCloudGatewayParams = {
  name: string;
  type?: 'trial';
};

export function useCreateCloudGateway({
  onSettled,
  ...options
}: UseMutationOptions<Gateway, APIError, UseCreateCloudGatewayParams> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutationWithError(
    ({ name, type }) => api.gateways.cloud.create(name, type),
    {
      ...options,
      action: 'create',
      entity: 'cloud gateway',
      async onSettled(...args) {
        await queryClient.invalidateQueries(['gateways']);
        await queryClient.invalidateQueries(['usage_statistics']);
        onSettled?.(...args);
      },
    }
  );
}
