import React from 'react';

import { StreamType } from 'types/stream';

import { useCameras } from 'cameras/hooks/useCameras';
import { useFileStreams } from 'files/hooks/useFileStreams';
import { useInputStreams } from 'streams/hooks/useInputStreams';
import { useStreams } from 'streams/hooks/useStreams';

import { VideoSource } from 'hooks/api/useDeploymentsVideoSources';

import { TypeFilterValue, VideoSourceFilters } from './types';

export type UseVideoSourceSelectItemsOptions = {
  filters: VideoSourceFilters;
  inputValue: string | undefined;
  typeFilter: TypeFilterValue;
};

export function useVideoSourceSelectItems({
  filters,
  inputValue,
  typeFilter,
}: UseVideoSourceSelectItemsOptions) {
  // this checks for the use case where we only want to have video sources from a specific camera
  // for example in the camera detail deployments (create new deployment) where we only want to have video sources
  // from this specific camera. We apply a special videoSourceFilter conditionally to "global streams and files"
  const isConstrainedByVideoSources =
    filters.camera_ids?.some((el) => el !== undefined) ||
    filters.stream_ids?.some((el) => el !== undefined);

  const videoSourceFilter = {
    camera_ids: filters.camera_ids,
    stream_ids: filters.stream_ids,
  };

  const { data: cameras, isLoading: isLoadingCameras } = useCameras(
    inputValue
      ? {
          camera_names: [inputValue],
          ...filters,
        }
      : filters,
    {
      enabled: typeFilter === null || typeFilter === 'cameras',
      keepPreviousData: true,
      select(response) {
        // FIXME Filter via API params 'conn_type' once available
        // Only allow local cameras as video source
        response.data = response.data.filter(
          ({ conn_type }) => conn_type === 'local'
        );

        response.page_elements = response.data.length;

        if (response.total_pages === 1) {
          response.total_elements = response.data.length;
        }

        return response;
      },
    }
  );

  const { data: gatewayInputStreams, isLoading: isLoadingInputStreams } =
    useInputStreams(
      inputValue
        ? {
            stream_names: [inputValue],
            ...filters,
          }
        : filters,
      {
        enabled: typeFilter === null || typeFilter === 'streams',
        keepPreviousData: true,
        select(response) {
          // filter out global streams because they are already handled below, otherwise we get duplicates
          // if filters.gateway_ids is undefined
          response.data = response.data.filter(({ gateway_id }) => gateway_id);

          response.page_elements = response.data.length;

          if (response.total_pages === 1) {
            response.total_elements = response.data.length;
          }

          return response;
        },
      }
    );

  const { data: globalInputStreams, isLoading: isLoadingGlobalInputStreams } =
    useStreams(
      // separate key to prevent it from colliding with the useInputStreams above
      ['global-input-streams'],
      {
        stream_names: inputValue ? [inputValue] : undefined,
        stream_types: [StreamType.RTSP],
        sources: ['uri_stream'],
        ...(isConstrainedByVideoSources ? videoSourceFilter : {}),
      },
      {
        enabled: typeFilter === null || typeFilter === 'streams',
        keepPreviousData: true,
        select(response) {
          response.data = response.data.filter(
            ({ gateway_id }) => gateway_id === null
          );

          response.page_elements = response.data.length;

          if (response.total_pages === 1) {
            response.total_elements = response.data.length;
          }

          return response;
        },
      }
    );

  const { data: outputStreams, isLoading: isLoadingOutputStreams } = useStreams(
    ['streams'],
    inputValue
      ? {
          stream_types: [StreamType.RTSP, StreamType.WEBRTC],
          sources: ['camera_stream'],
          stream_names: [inputValue],
          ...filters,
        }
      : {
          stream_types: [StreamType.RTSP, StreamType.FILE],
          sources: ['camera_stream'],
          ...filters,
        },
    {
      enabled: typeFilter === null || typeFilter === 'streams',
      keepPreviousData: true,
    }
  );

  const { data: fileStreams, isLoading: isLoadingFileStreams } = useFileStreams(
    inputValue
      ? {
          stream_names: [inputValue],
          ...(isConstrainedByVideoSources ? videoSourceFilter : {}),
        }
      : { ...(isConstrainedByVideoSources ? videoSourceFilter : {}) },
    {
      enabled: typeFilter === null || typeFilter === 'files',
      keepPreviousData: true,
    }
  );

  const { pageElements, totalElements } = React.useMemo(() => {
    let pageElements = 0;
    let totalElements = 0;

    if (typeFilter === null || typeFilter === 'cameras') {
      if (cameras) {
        pageElements += cameras.page_elements;
        totalElements += cameras.total_elements;
      }
    }

    if (typeFilter === null || typeFilter === 'streams') {
      if (gatewayInputStreams) {
        pageElements += gatewayInputStreams.page_elements;
        totalElements += gatewayInputStreams.total_elements;
      }

      if (globalInputStreams) {
        pageElements += globalInputStreams.page_elements;
        totalElements += globalInputStreams.total_elements;
      }

      if (outputStreams) {
        pageElements += outputStreams.page_elements;
        totalElements += outputStreams.total_elements;
      }
    }

    if (typeFilter === null || typeFilter === 'files') {
      if (fileStreams) {
        pageElements += fileStreams.page_elements;
        totalElements += fileStreams.total_elements;
      }
    }

    return { pageElements, totalElements };
  }, [
    typeFilter,
    cameras,
    gatewayInputStreams,
    globalInputStreams,
    outputStreams,
    fileStreams,
  ]);

  const items = React.useMemo<VideoSource[]>(() => {
    const videoSourceItems: VideoSource[] = [];

    if (typeFilter === null || typeFilter === 'cameras') {
      if (cameras) {
        videoSourceItems.push(...cameras.data);
      }
    }

    if (typeFilter === null || typeFilter === 'streams') {
      if (gatewayInputStreams) {
        videoSourceItems.push(...gatewayInputStreams.data);
      }

      if (globalInputStreams) {
        videoSourceItems.push(...globalInputStreams.data);
      }

      if (outputStreams) {
        videoSourceItems.push(...outputStreams.data);
      }
    }

    if (typeFilter === null || typeFilter === 'files') {
      if (fileStreams) {
        videoSourceItems.push(...fileStreams.data);
      }
    }

    return videoSourceItems;
  }, [
    typeFilter,
    cameras,
    gatewayInputStreams,
    globalInputStreams,
    outputStreams,
    fileStreams,
  ]);

  return {
    items,
    isLoading:
      isLoadingCameras ||
      isLoadingInputStreams ||
      isLoadingGlobalInputStreams ||
      isLoadingOutputStreams ||
      isLoadingFileStreams,
    pageElements,
    totalElements,
  };
}
