import React from 'react';
import classNames from 'classnames/bind';
import { DateTime } from 'luxon';
import { Skeleton } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';

import APIError from 'types/api_error';
import { FilesSearchResult } from 'types/files_search_result';
import { LumeoFile } from 'types/file';
import { OffsetPaginated } from 'types/pagination';

import { FILES_SEARCH_ICONS_MAP } from 'files/services/icons_map';

import { Icon } from 'components/Icon/Icon';
import { Pagination } from 'components/Pagination/Pagination';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Text } from 'components/Text/Text';
import { Thumbnail } from 'components/Thumbnail/Thumbnail';
import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './FilesSearchDrawer.module.scss';

const c = classNames.bind(styles);

const overscan = 20;
const estimateSize = 150;

type FilesSearchDrawerListProps = {
  queryResult: UseQueryResult<
    OffsetPaginated<FilesSearchResult | LumeoFile>,
    APIError
  >;
  page?: number;
  onPageChange: (page: number) => void;
  onFileClick: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedFileId: string | undefined;
};

export function FilesSearchDrawerList({
  queryResult: { data, isFetching },
  page,
  onPageChange,
  onFileClick,
  selectedFileId,
}: FilesSearchDrawerListProps) {
  const hasScrolled = React.useRef(false);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: data ? data.data.length : 0,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => estimateSize,
    overscan,
  });

  const [isDeleted, setIsDeleted] = React.useState(false);

  const selectedFileIndex = data?.data.findIndex((file) =>
    'file_id' in file
      ? file.file_id === selectedFileId
      : file.id === selectedFileId
  );

  React.useEffect(() => {
    if (hasScrolled.current || !selectedFileIndex || selectedFileIndex < 0) {
      return;
    }

    virtualizer.scrollToIndex(selectedFileIndex, {
      align: 'start',
      behavior: 'smooth',
    });
    hasScrolled.current = true;
  }, [selectedFileIndex, virtualizer]);

  if (isFetching) {
    return (
      <div className={c('files')}>
        <SkeletonList component={SkeletonFile} min={3} max={3} />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  if (data.data.length === 0) {
    return <div className={c('files', 'empty')}>No results found.</div>;
  }

  return (
    <div ref={scrollRef} className={c('files')}>
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {virtualizer.getVirtualItems().map(({ key, size, start, index }) => {
          const item = data.data[index];

          const isFileSearchResult = 'file_id' in item;
          const id = isFileSearchResult ? item.file_id : item.id;
          const objects = isFileSearchResult ? item.objects : [];

          const objectsWithIcon = objects
            .filter((object) => FILES_SEARCH_ICONS_MAP[object])
            .slice(0, 3);

          const objectsWithoutIcon = objects.filter(
            (object) => !objectsWithIcon.includes(object)
          );

          return (
            <button
              key={key}
              className={c('file', { selected: selectedFileId === id })}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${size}px`,
                transform: `translateY(${start}px)`,
              }}
              onClick={() => onFileClick(id)}
              disabled={isFetching || isDeleted}
            >
              <Thumbnail
                className={c('thumbnail')}
                thumbnail_url={item.thumbnail_url}
                mime_type={item.mime_type}
                onError={() => setIsDeleted(true)}
              />
              <Text className={c('timestamp')} size="xsmall" align="start">
                {DateTime.fromISO(item.created_at).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </Text>
              <div className={c('objects')}>
                {objectsWithIcon.map((object) => (
                  <Tooltip key={object} content={object}>
                    <Icon name={FILES_SEARCH_ICONS_MAP[object]} />
                  </Tooltip>
                ))}

                {objectsWithoutIcon.length > 0 && (
                  <Tooltip content={objectsWithoutIcon.join(', ')}>
                    <span className={c('icon')}>
                      +{objectsWithoutIcon.length}
                    </span>
                  </Tooltip>
                )}
              </div>
            </button>
          );
        })}
      </div>

      {page && data.total_pages > 1 && (
        <Pagination
          className={c('pagination')}
          total={data.total_pages}
          value={page}
          onChange={onPageChange}
          size="sm"
          gap="xs"
        />
      )}
    </div>
  );
}

const SkeletonFile = (
  <div style={{ height: estimateSize }} className={c('file', 'loading')}>
    <Thumbnail className={c('thumbnail')} isLoading />
    <Skeleton height="2rem" width="70%" className={c('timestamp')} visible />
    <Skeleton height="1.25rem" width="100%" className={c('objects')} visible />
  </div>
);
