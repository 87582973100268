import React from 'react';
import classNames from 'classnames/bind';
import { useToggle } from '@mantine/hooks';

import Camera from 'types/camera';
import Gateway from 'types/gateway';

import { useSet } from 'hooks/useSet';

import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Separator } from 'components/Separator/Separator';
import { Text } from 'components/Text/Text';

import { AuthenticateCameraDialog } from '../../AuthenticateCameraDialog/AuthenticateCameraDialog';
import { BulkLinkGatewayCamerasDialog } from '../../LinkGatewayCameraDialog/BulkLinkGatewayCamerasDialog';
import { DiscoveredCameraCard } from '../../DiscoveredCameraCard/DiscoveredCameraCard';
import { LinkedCameraCard } from '../../LinkedCameraCard/LinkedCameraCard';
import { LinkGatewayCameraDialog } from '../../LinkGatewayCameraDialog/LinkGatewayCameraDialog';
import styles from './DiscoveredAndLinkedGatewayCameras.module.scss';

const c = classNames.bind(styles);

type DiscoveredAndLinkedGatewayCamerasProps = {
  gatewayID: Gateway['id'];
  cameras: Camera[];
  discover: () => Promise<void>;
  isCameraLinked: (camera: Camera) => boolean;
};

function displaySource(camera: Camera) {
  if (camera.ip_local) {
    return `${camera.model || ''} @ ${camera.ip_local}`;
  }

  if (camera.ip_ext) {
    return `${camera.model || ''} @ ${camera.ip_ext}`;
  }

  return camera.uri || '';
}

export function DiscoveredAndLinkedGatewayCameras({
  gatewayID,
  cameras,
  discover,
  isCameraLinked,
}: DiscoveredAndLinkedGatewayCamerasProps) {
  const [selectedCameras, { add, remove, clear }] = useSet<Camera>();
  const [camerasToLink, setCamerasToLink] = React.useState<Camera[]>();
  const [isLinkingOpen, toggleIsLinkingOpen] = useToggle();

  const [cameraToUpdate, setCameraToUpdate] = React.useState<Camera>();
  const [isAuthOpen, toggleIsAuthOpen] = useToggle();

  const linkedCameras = cameras.filter(isCameraLinked);
  const discoveredCameras = cameras.filter(
    ({ id: discoveredID }) =>
      !linkedCameras.some(({ id: linkedID }) => linkedID === discoveredID)
  );

  const hasDiscoveredCameras = discoveredCameras.length > 0;
  const hasLinkedCameras = linkedCameras.length > 0;

  return (
    <>
      {hasDiscoveredCameras && (
        <section className={c('cameras')}>
          <div className={c('cameras-header')}>
            <Heading level="2">Discovered Cameras</Heading>
            <Button
              size="small"
              className={c('button-wrap')}
              onClick={discover}
            >
              Discover
            </Button>
          </div>

          <div className={c('cameras-list')}>
            <ul>
              {discoveredCameras.map((camera) => (
                <li
                  className={c('cameras-list-item')}
                  key={camera.id || displaySource(camera)}
                >
                  <DiscoveredCameraCard
                    key={camera.id || displaySource(camera)}
                    camera={camera}
                    displaySource={displaySource}
                    onLinkButtonClick={() => {
                      setCamerasToLink([camera]);
                      toggleIsLinkingOpen();
                    }}
                    isSelected={selectedCameras.includes(camera)}
                    onSelect={(isSelected) => {
                      if (isSelected) {
                        add(camera);
                      } else {
                        remove(camera);
                      }
                    }}
                  />
                </li>
              ))}
            </ul>

            {selectedCameras.length > 0 && (
              <div className={c('cameras-bulk-link')}>
                <Text>
                  {selectedCameras.length} camera
                  {selectedCameras.length > 1 ? 's' : ''} selected
                </Text>
                <Button size="small" variant="secondary" onClick={clear}>
                  Clear selection
                </Button>
                <Button
                  className={c('cameras-bulk-link-primary')}
                  size="small"
                  variant="primary"
                  onClick={() => {
                    setCamerasToLink([...selectedCameras]);
                    toggleIsLinkingOpen();
                  }}
                >
                  Link selected cameras
                </Button>
              </div>
            )}
          </div>

          <FormMessage intent="info" icon="info">
            If your IP camera cannot be discovered automatically, but it is on
            the same subnet, check to ensure you have enabled ONVIF in your
            camera's settings.
          </FormMessage>
        </section>
      )}

      {hasLinkedCameras && (
        <>
          {hasDiscoveredCameras && <Separator />}
          <section className={c('cameras')}>
            <Heading level="2" className={c('cameras-header')}>
              Linked Cameras
              {!hasDiscoveredCameras && (
                <Button
                  size="small"
                  className={c('button-wrap')}
                  onClick={discover}
                >
                  Discover
                </Button>
              )}
            </Heading>
            <ul className={c('cameras-list')}>
              {linkedCameras.map((camera) => (
                <li
                  className={c('cameras-list-item')}
                  key={camera.id || displaySource(camera)}
                >
                  <LinkedCameraCard
                    camera={camera}
                    displaySource={displaySource}
                    onAuthenticate={() => {
                      setCameraToUpdate(camera);
                      toggleIsAuthOpen();
                    }}
                  />
                </li>
              ))}
            </ul>
          </section>
        </>
      )}

      {!hasDiscoveredCameras && !hasLinkedCameras && (
        <Box className={c('no-cameras-box')}>
          <div>
            <Heading level="4">No cameras</Heading>
            <Text type="paragraph">
              Please make sure your camera is connected and then retry
              discovery.
            </Text>
          </div>
          <div className={c('button-wrap')}>
            <Button size="small" variant="primary" onClick={discover}>
              Discover
            </Button>
          </div>
        </Box>
      )}

      <LinkGatewayCameraDialog
        gatewayID={gatewayID}
        camera={camerasToLink?.[0]}
        onOpenChange={toggleIsLinkingOpen}
        onSuccess={remove}
        open={camerasToLink && camerasToLink.length === 1 && isLinkingOpen}
      />

      <BulkLinkGatewayCamerasDialog
        gatewayID={gatewayID}
        cameras={camerasToLink}
        onSuccess={clear}
        onOpenChange={toggleIsLinkingOpen}
        open={camerasToLink && camerasToLink.length > 1 && isLinkingOpen}
      />

      <AuthenticateCameraDialog
        camera={cameraToUpdate}
        open={Boolean(cameraToUpdate) && isAuthOpen}
        onOpenChange={toggleIsAuthOpen}
      />
    </>
  );
}
