import React from 'react';
import classNames from 'classnames/bind';

import styles from './Meter.module.scss';

const c = classNames.bind(styles);

export type MeterProps = React.MeterHTMLAttributes<HTMLElement> & {
  className?: string;
  size?: 'small' | 'large';
};

export function Meter({ className, size = 'small', ...props }: MeterProps) {
  return <meter className={c('meter', size, className)} {...props} />;
}
