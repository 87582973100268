import React from 'react';
import classnames from 'classnames/bind';
import ReactModal, { Props } from 'react-modal';

import { generateId } from 'services/string';

import { IconButton } from 'components/IconButton/IconButton';

import styles from './Modal.module.scss';

const c = classnames.bind(styles);

ReactModal.setAppElement('#root');

export type ModalProps = React.PropsWithChildren<
  Pick<
    Props,
    'shouldCloseOnOverlayClick' | 'shouldCloseOnEsc' | 'contentElement'
  > & {
    header?: React.ReactNode;
    headerActions?: React.ReactNode;
    actions?: React.ReactNode;
    className?: string;
    component?: any;
    onRequestClose?: () => void;
  }
>;

/**
 * @deprecated Use &lt;Dialog /&gt; instead.
 */
export function Modal({
  header,
  headerActions,
  actions,
  className,
  onRequestClose,
  children,
  component,
  ...props
}: ModalProps) {
  const TagName: any = component || 'div';
  const id = generateId();

  return (
    <ReactModal
      {...props}
      aria={{ labelledby: id }}
      isOpen={true}
      onRequestClose={onRequestClose}
      overlayClassName={c('modalMask')}
      className={c('modal', className)}
      htmlOpenClassName={c('overlay-active')}
    >
      <TagName>
        <header
          className={c('header', {
            plain: !children,
            empty: !header && !headerActions,
          })}
        >
          {header && (
            <div className={c('header-content')} id={id}>
              {header}
            </div>
          )}

          {headerActions}

          {onRequestClose && (
            <IconButton
              className={c('close')}
              onClick={onRequestClose}
              icon="cancel"
              label="Close modal"
              variant="ghost"
            />
          )}
        </header>
        {children && <div className={c('body')}>{children}</div>}
        {actions && <div className={c('footer')}>{actions}</div>}
      </TagName>
    </ReactModal>
  );
}
