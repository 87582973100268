import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { FloatInput } from 'components/NumberInput/NumberInput';

import { FIELD_LABEL_WIDTH, Step3FieldValues } from '../Step3';

export function EpsilonInput() {
  const {
    control,
    formState: { errors },
  } = useFormContext<Step3FieldValues>();

  return (
    <Field
      id="eps"
      label="Epsilon"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      info="Epsilon or EPS value for OpenCV grouprectangles() and DBSCAN algorithm."
      error={errors.inference_config?.class_attributes?.['*']?.eps}
      required
    >
      <Controller
        control={control}
        name="inference_config.class_attributes.*.eps"
        defaultValue={0.2}
        render={({ field: { value, onChange } }) => (
          <FloatInput
            id="eps"
            min={0}
            step={0.01}
            value={value}
            onChange={onChange}
          />
        )}
        rules={{
          required: 'Please enter an epsilon value.',
          min: {
            value: 0,
            message: 'Epsilon value must be at least 0.',
          },
        }}
      />
    </Field>
  );
}
