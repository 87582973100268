import classNames from 'classnames/bind';

import Camera from 'types/camera';

import { useStreams } from 'streams/hooks/useStreams';
import { useMqttStreamsState } from 'streams/hooks/useMqttStreamsState';

import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/Text/Text';

import { CameraStreamListElement } from './CameraStreamListElement';
import styles from './CameraDetailStreams.module.scss';

export const c = classNames.bind(styles);

export type CameraDetailStreamsProps = {
  camera: Camera;
};

export function CameraDetailStreams({ camera }: CameraDetailStreamsProps) {
  const { data: streams, isLoading } = useStreams(
    ['streams', 'camera-detail'],
    {
      sources: ['camera_stream'],
      camera_ids: [camera.id],
    }
  );

  useMqttStreamsState(streams ? streams.data.map(({ id }) => id) : []);

  if (isLoading) {
    return <Loader text="Loading camera streams..." size="small" />;
  }

  if (!streams || streams.total_elements === 0) {
    return <Text>No camera streams configured.</Text>;
  }

  return (
    <ul className={c('list')}>
      {streams &&
        streams.data.map((stream, idx) => (
          <CameraStreamListElement
            key={stream.id}
            streamID={stream.id}
            hideLoader={idx !== 0}
          />
        ))}
    </ul>
  );
}
