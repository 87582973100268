import React from 'react';
import classnames from 'classnames/bind';

import { StreamType } from 'types/stream';

import { useStreams, UseStreamsFilterParams } from 'streams/hooks/useStreams';

import { CameraFileListTable } from './Table';

import styles from './FileList.module.scss';

const c = classnames.bind(styles);

export type FileListProps = {
  cameraID: string;
};

export function FileList({ cameraID }: FileListProps) {
  const [params, setParams] = React.useState<UseStreamsFilterParams>({
    stream_types: [StreamType.FILE],
    camera_ids: cameraID ? [cameraID] : undefined,
    page: 1,
  });

  const queryResult = useStreams(['streams'], params, {
    enabled: Boolean(cameraID),
  });

  return (
    <div className={c('wrap')}>
      <CameraFileListTable
        queryResult={queryResult}
        pageSize={50}
        page={params.page}
        onPageChange={(page) =>
          setParams((previousParams) => ({ ...previousParams, page }))
        }
      />
    </div>
  );
}
