import React from 'react';
import classnames from 'classnames/bind';

import { useForwardedRef } from 'hooks/useForwardedRef';

import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';

import styles from './FileInput.module.scss';

const c = classnames.bind(styles);

type CommonFileInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  id: string;
  innerRef?: React.RefObject<HTMLInputElement>;
  type?: 'image' | 'application' | 'video' | 'text';
};

type SingleFileInputProps = CommonFileInputProps & {
  onChange: (file: File) => void;
  multiple?: never;
};

type MultipleFileInputProps = CommonFileInputProps & {
  onChange: (files: FileList) => void;
  multiple: true;
};

export type FileInputProps = CommonFileInputProps & {
  onChange: ((file: File) => void) | ((files: FileList) => void);
};

export function _FileInput(
  props: MultipleFileInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
): JSX.Element;
export function _FileInput(
  props: SingleFileInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
): JSX.Element;

export function _FileInput(
  { id, onChange, multiple, type, ...props }: FileInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const [files, setFiles] = React.useState<FileList>();
  const [file, setFile] = React.useState<File>();
  const [error, setError] = React.useState<string>();

  const isEmpty = (!files || files.length <= 0) && !file;
  const hasMultipleFiles = multiple && files && files.length > 1;
  const hasSingleFile = (multiple && files && files.length === 1) || file;

  const innerRef = useForwardedRef(ref);

  React.useEffect(() => {
    const ref = innerRef.current;

    if (!ref?.form) {
      return;
    }

    function handleReset() {
      setFiles(undefined);
      setFile(undefined);
    }

    ref.form.addEventListener('reset', handleReset);

    return () => {
      if (!ref?.form) {
        return;
      }

      ref.form.removeEventListener('reset', handleReset);
    };
  }, [innerRef]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setError(undefined);
    let hasError;

    if (!event.target.files || event.target.files.length <= 0) {
      return;
    }

    if (multiple) {
      const func = onChange as (files: FileList) => void;
      hasError =
        type &&
        !Array.from(event.target.files).every(
          (file) => !file.type || file.type.includes(type)
        );
      func(event.target.files);
      setFiles(event.target.files);
    } else {
      const file = event.target.files.item(0);
      const func = onChange as (file: File) => void;
      hasError = type && file && file.type && !file.type.includes(type);

      if (file) {
        func(file);
        setFile(file);
      }
    }

    if (hasError) {
      setError(
        multiple
          ? `Please select only ${type} files.`
          : `Please select a ${type} file.`
      );
    }
  }

  return (
    <div className={c('wrap')}>
      <div
        className={c('input', 'focus-ring-within')}
        title={file?.name || files?.item(0)?.name}
      >
        <input
          {...props}
          id={id}
          type="file"
          className={c('real', 'sr-only')}
          onChange={handleChange}
          multiple={multiple}
          ref={innerRef}
        />
        <Button className={c('button')} variant="tertiary" size="small" asChild>
          <label htmlFor={id}>
            <Icon name="upload" />
            <span>Upload file</span>
          </label>
        </Button>
        <label
          htmlFor={id}
          className={c('info', {
            empty: (!files || files.length <= 0) && !file,
          })}
        >
          {isEmpty && (multiple ? 'No files selected' : 'No file selected')}
          {hasMultipleFiles && `${files!.length} files selected`}
          {hasSingleFile && (multiple ? files?.item(0)?.name : file?.name)}
        </label>
      </div>
      {error && <p className="form-error theme danger">{error}</p>}
    </div>
  );
}

export const FileInput = React.forwardRef(_FileInput);
