import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Dashboard from 'types/dashboard';

import { migrateMetricFilters } from '../components/MetricFiltersInput/migrate';
import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export type UseDashboardOptions = UseQueryOptions<Dashboard, APIError>;

export function useDashboard(
  dashboardId: Dashboard['id'] | undefined,
  options: UseDashboardOptions = {}
) {
  const api = useAPI();

  return useAuthenticatedQuery(
    ['dashboard', dashboardId, api.applicationID],
    () => {
      if (!dashboardId) {
        return Promise.reject('No dashboard id provided.');
      }

      return api.dashboards.read(dashboardId);
    },
    {
      ...options,
      select(dashboard) {
        return dashboard ? migrateMetricFilters(dashboard) : dashboard;
      },
    }
  );
}
