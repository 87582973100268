import React from 'react';

export function usePreventWindowDrop(
  componentRef: React.RefObject<HTMLLabelElement>
) {
  React.useEffect(() => {
    function handleDrag(event: Event) {
      if (event.currentTarget === componentRef.current) {
        return;
      }

      event.preventDefault();
    }

    ['dragenter', 'dragover', 'drop'].forEach((event) =>
      window.addEventListener(event, handleDrag)
    );

    return () => {
      ['dragenter', 'dragover', 'drop'].forEach((event) =>
        window.removeEventListener(event, handleDrag)
      );
    };
  }, [componentRef]);
}
