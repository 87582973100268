import PropelToken from 'types/propel_token';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useHasAccess } from 'hooks/useHasAccess';

const ONE_HOUR = 3600 * 1000;

export function usePropelAuthentication() {
  const [hasAccess] = useHasAccess();
  const { applicationID, dashboards } = useAPI();

  return useAuthenticatedQuery<PropelToken>(
    ['propel-token', applicationID],
    () => dashboards.propel.authenticate(),
    {
      enabled: hasAccess('monitor'),
      // Token expires in 1 hour
      cacheTime: ONE_HOUR,
      staleTime: ONE_HOUR,
      refetchInterval: ONE_HOUR,
    }
  );
}
