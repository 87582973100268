import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import { Radio, RadioOption, RadioProps } from 'components/Radio/Radio';
import { RadioOptionProps } from 'components/Radio/RadioOption';

export type ReteRadioSelectOptionType = Omit<RadioOptionProps, 'children'> & {
  label: string;
  info?: string;
};

type ReteRadioProps = ReteInputProps &
  RadioProps & { options: ReteRadioSelectOptionType[] };

export type ReteRadioSelectOptions = ReteInputParams &
  Pick<ReteRadioProps, 'defaultValue' | 'options'>;

export default class ReteRadioSelect extends ReteInput<
  ReteInputProps & ReteRadioProps
> {
  options: ReteRadioSelectOptionType[];

  constructor(
    editor: NodeEditor,
    key: string,
    { options, initial, ...params }: ReteRadioSelectOptions
  ) {
    super(editor, key, {
      ...params,
      initial,
      inputType: 'radio',
    });

    this.options = options;
    this.props.options = options;
    this.props.defaultValue = initial;
  }

  get component() {
    return ReteRadioSelectComponent;
  }
}

function ReteRadioSelectComponent({
  id,
  options,
  keyrefValue,
  initial,
  defaultValue,
  value,
  ...props
}: ReteRadioProps) {
  const size = options.some(({ info }) => Boolean(info)) ? 'large' : undefined;

  const initialValue = initial ?? defaultValue;

  const isInitialValueJson = initialValue && typeof initialValue === 'object';
  const isValueJson = value && typeof value === 'object';

  return (
    <Radio
      {...props}
      id={id}
      defaultValue={
        isInitialValueJson ? JSON.stringify(initialValue) : initialValue
      }
      value={isValueJson ? JSON.stringify(value) : value}
      size={size}
    >
      {options.map(({ label, info, ...optionProps }, index) => (
        <RadioOption
          {...optionProps}
          keyrefValue={keyrefValue}
          key={`${id}_${index}`}
        >
          <strong>{label}</strong>
          {info && <span>{info}</span>}
        </RadioOption>
      ))}
    </Radio>
  );
}
