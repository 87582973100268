import React from 'react';
import classNames from 'classnames/bind';

import { Icon, IconProps } from 'components/Icon/Icon';

import styles from './List.module.scss';
import { Text } from 'components/Text/Text';

const c = classNames.bind(styles);

export type ItemProps = React.PropsWithChildren<{
  icon?: IconProps['name'];
}>;

export function Item({ icon, children }: ItemProps) {
  return (
    <li className={c('item', { icon })}>
      {icon && (
        <Text className={c('item-icon')} aria-hidden="true" inline>
          <Icon name={icon} />
          &nbsp;
        </Text>
      )}
      <div className={c('item-content')}>{children}</div>
    </li>
  );
}
