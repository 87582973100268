import React from 'react';
import classnames from 'classnames/bind';

import { Button, ButtonProps } from 'components/Button/Button';
import { Heading } from 'components/Heading/Heading';

import styles from './NotFound.module.scss';

const c = classnames.bind(styles);

export type NotFoundProps = React.PropsWithChildren<{
  entity?: string;
  returnTo?: ButtonProps['to'];
  image?: React.FunctionComponent<React.HTMLAttributes<SVGSVGElement>>;
}>;

export function NotFound({
  entity = 'page',
  returnTo,
  children,
  image: ImageComponent,
}: NotFoundProps) {
  return (
    <div className={c('not-found', 'error')}>
      {ImageComponent && <ImageComponent className={c('illustration')} />}
      <Heading level="2" asChild>
        <h1>Sorry, we could not find this {entity}.</h1>
      </Heading>
      {children}
      {returnTo && (
        <div className={c('button')}>
          <Button to={returnTo}>Return to overview</Button>
        </div>
      )}
    </div>
  );
}
