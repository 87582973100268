import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames/bind';

import styles from './ContextMenu.module.scss';

const c = classNames.bind(styles);

export type ContextMenuGroupProps = React.PropsWithChildren<{}>;

export function ContextMenuGroup({ children }: ContextMenuGroupProps) {
  return (
    <DropdownMenu.Group className={c('group')}>{children}</DropdownMenu.Group>
  );
}
