import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import {
  MultiSelectInput,
  MultiSelectInputProps,
} from 'components/MultiSelectInput/MultiSelectInput';

type ReteMultiSelectProps = ReteInputProps & MultiSelectInputProps;

export type ReteMultiSelectOptions = ReteInputParams &
  Pick<ReteMultiSelectProps, 'options'>;

export default class ReteMultiSelect extends ReteInput<ReteMultiSelectProps> {
  constructor(
    editor: NodeEditor,
    key: string,
    { options, initial, ...params }: ReteMultiSelectOptions
  ) {
    super(editor, key, {
      ...params,
      initial,
    });

    this.props.options = options;
    this.props.defaultValue = initial;
  }

  get component() {
    return ReteMultiSelectComponent;
  }
}

function ReteMultiSelectComponent({
  keyrefValue,
  initial,
  defaultValue,
  value,
  options = [],
  ...props
}: ReteMultiSelectProps) {
  return (
    <MultiSelectInput
      {...props}
      options={options}
      sort={(items) => items}
      defaultSelected={initial ?? defaultValue}
      selected={value}
    />
  );
}
