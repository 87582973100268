import React from 'react';
import classNames from 'classnames/bind';
import { Combobox, Kbd, TextInput, useCombobox } from '@mantine/core';
import { matchSorter } from 'match-sorter';

import { isNotNullOrUndefined } from 'services/nullable';
import { useActivePipeline } from 'pipelines/context/active_pipeline';
import { useCurrentNode } from 'deployments/hooks/useCurrentNode';
import { useMarketplaceModels, useModels } from 'hooks/api/useModels';
import { useReadFilesFromURL } from 'hooks/useReadFilesFromURL';

import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './UpstreamModelLabels.module.scss';

const c = classNames.bind(styles);

type UpstreamModelLabelsInputProps = {
  id: string;
  values: string[];
  onSubmitValue: (value: string) => void;
};

export function UpstreamModelLabelsInput({
  id,
  values,
  onSubmitValue,
}: UpstreamModelLabelsInputProps) {
  const combobox = useCombobox();

  const [value, setValue] = React.useState('');
  const [valueLabel, valueAttribute] = value.split('.');

  const { nodeGraph } = useActivePipeline();
  const { nodeID } = useCurrentNode();

  const { data: userModels = [] } = useModels();
  const { data: marketplaceModels = [] } = useMarketplaceModels();

  const appModels = [...userModels, ...marketplaceModels];

  const upstreamNodes = nodeGraph.get(nodeID)?.allUpstreamNodes() || [];
  const modelNodes = [...upstreamNodes].filter(({ data }) => data.model_id);

  const upstreamModels = appModels.filter(({ id }) =>
    modelNodes.find(({ data }) => id === data.model_id)
  );

  // Detector models: Labels file contains model-labels
  const upstreamDetectorModels = upstreamModels.filter(
    (model) => model.capability === 'detection'
  );
  const detectorFileURLs = upstreamDetectorModels
    .map(({ labels_file_url }) => labels_file_url)
    .filter(isNotNullOrUndefined);
  const detectorFileResults = useReadFilesFromURL(detectorFileURLs);
  const labels = detectorFileResults
    .map(({ data }) => data)
    .filter(isNotNullOrUndefined)
    .flatMap((labels) => labels.split('\n'));

  // Classifier models: Labels file contains attributes
  const upstreamClassifierModels = upstreamModels.filter(
    (model) => model.capability === 'classification'
  );
  const classifierFileURLs = upstreamClassifierModels
    .map(({ labels_file_url }) => labels_file_url)
    .filter(isNotNullOrUndefined);
  const classifierFileResults = useReadFilesFromURL(classifierFileURLs);
  const attributes = classifierFileResults
    .map(({ data }) => data)
    .filter(isNotNullOrUndefined)
    .flatMap((labels) => labels.split('\n'));

  // Other models: Labels file values may be used as model-label or attribute
  const otherModels = upstreamModels.filter(
    (model) => model.capability === 'other'
  );
  const otherFileURLs = otherModels
    .map(({ labels_file_url }) => labels_file_url)
    .filter(isNotNullOrUndefined);
  const otherFileResults = useReadFilesFromURL(otherFileURLs);
  const labelsOrAttributes = otherFileResults
    .map(({ data }) => data)
    .filter(isNotNullOrUndefined)
    .flatMap((labels) => labels.split('\n'));

  const labelsOptions = [...labels, ...labelsOrAttributes];
  const attributesOptions = ['*', ...attributes, ...labelsOrAttributes];

  const [cursorPosition, setCursorPosition] = React.useState<number | null>(
    null
  );

  const isEditingAttributes =
    cursorPosition !== null
      ? value.slice(0, cursorPosition).includes('.')
      : value.includes('.');

  let activeSubstring = value;

  if (value.includes('.') && cursorPosition !== null) {
    const index = value.indexOf('.');

    if (isEditingAttributes) {
      activeSubstring = value.slice(index + 1);
    } else {
      activeSubstring = value.slice(0, index);
    }
  }

  const activeSuggestions = !isEditingAttributes
    ? labelsOptions
    : attributesOptions;

  const filteredSuggestions = matchSorter(
    activeSuggestions.filter((suggestion) => !values.includes(suggestion)),
    activeSubstring,
    {
      keys: [(item) => item],
    }
  );

  const canConfirm =
    value.trim().length > 0 &&
    !value.trim().endsWith('.') &&
    !combobox.dropdownOpened;
  const isOnlyLabel = value.trim().length > 0 && !value.includes('.');

  function handleOptionSubmit(optionValue: string) {
    combobox.closeDropdown();

    const index = value.indexOf('.');

    if (isEditingAttributes) {
      const newValue = `${value.slice(0, index + 1)}${optionValue}`;
      setValue(newValue);
      return;
    }

    if (index === -1) {
      setValue(optionValue);
      return;
    }

    const newValue = `${optionValue}${value.slice(index)}`;
    onSubmitValue(newValue);
    setValue('');
  }

  const currentValue = isEditingAttributes ? valueAttribute : valueLabel;

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleOptionSubmit}
      classNames={{
        dropdown: c('dropdown'),
      }}
    >
      <Combobox.Target>
        <TextInput
          id={id}
          value={value}
          rightSection={
            canConfirm && (
              <Tooltip content="Press Enter key to save value.">
                <Icon name="enter" />
              </Tooltip>
            )
          }
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();

              if (canConfirm) {
                onSubmitValue(value);
                setValue('');
                combobox.closeDropdown();
              }
            }
          }}
          onKeyUp={(event) => {
            setCursorPosition(event.currentTarget.selectionStart);
          }}
          onChange={(event) => {
            setValue(event.currentTarget.value.trim());
            setCursorPosition(event.currentTarget.selectionStart);
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
          }}
          onFocus={(event) => {
            setCursorPosition(event.currentTarget.selectionStart);
            combobox.openDropdown();
          }}
          onBlur={() => {
            setCursorPosition(null);
            combobox.closeDropdown();
          }}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {filteredSuggestions.map((attribute) => (
            <Combobox.Option value={attribute} key={attribute}>
              {attribute}
            </Combobox.Option>
          ))}

          {filteredSuggestions.length === 0 &&
            (values.includes(value) ? (
              <Combobox.Empty>"{value}" already exists.</Combobox.Empty>
            ) : (
              <Combobox.Option value={currentValue}>
                Add "{currentValue}"
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </Combobox.Dropdown>

      {canConfirm && (
        <Text type="paragraph">
          Press <Kbd size="sm">↵</Kbd> to confirm current value.
          {isOnlyLabel && (
            <>
              Type <Kbd size="sm">.</Kbd> to select an attribute.
            </>
          )}
        </Text>
      )}
    </Combobox>
  );
}
