import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import { useAuthentication } from 'hooks/api/useAuthentication';

import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { Loader } from 'components/Loader/Loader';
import { LogoMark } from 'components/Logo/LogoMark';
import { Text } from 'components/Text/Text';

import styles from './Auth.module.scss';

const c = classNames.bind(styles);

export function SamlReturn() {
  const { samlLogin, isSamlLoggingIn, samlLoginError } = useAuthentication();

  const isMounted = React.useRef(false);

  React.useEffect(() => {
    if (isMounted.current) {
      return;
    }

    isMounted.current = true;
    samlLogin();
  }, [samlLogin]);

  if (isSamlLoggingIn) {
    return <Loader text="Signing in..." showLogo />;
  }

  return (
    <div className={c('wrap')}>
      <div className={c('card', 'auth')}>
        <div className="card-content">
          <LogoMark className={c('logo')} aria-label="Lumeo logo." />

          <div className={c('auth-text')}>
            <Heading level="1">Single sign-on</Heading>
          </div>

          {samlLoginError && (
            <>
              <Text>An error occurred while signing in.</Text>
              <FormErrorMessage error={samlLoginError} />
              <Text>
                return to{' '}
                <Link to="/login" className="link">
                  Sign in
                </Link>
              </Text>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
