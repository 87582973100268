import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import AIModel from 'types/ai_model';

import { isEmptyObject } from 'services/object';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { MultiInput } from 'components/MultiInput/MultiInput';
import { Separator } from 'components/Separator/Separator';

import { ModelFormData, useModelFormState } from './context/useModelFormState';
import { useModelFormStep } from './hooks/useModelFormStep';
import { FormatConfiguration } from './components/FormatConfiguration';
import { CapabilityConfiguration } from './components/CapabilityConfiguration';
import { NetScaleFactorInput } from './inputs/NetScaleFactorInput';
import { ColorFormatInput } from './inputs/ColorFormatInput';
import { NetworkModeInput } from './inputs/NetworkModeInput';
import { AdvancedConfiguration } from './inputs/AdvancedConfiguration';
import { LeavePrompt } from './components/LeavePrompt';

export const FIELD_LABEL_WIDTH = 140;

export type Step2FieldValues = Pick<AIModel, 'parameters'> & {
  inference_config: Omit<
    AIModel['inference_config'],
    'class_attributes' | 'inference_engine'
  >;
};

function getValuesFromModel({
  inference_config,
  parameters,
}: AIModel): Step2FieldValues {
  if (!inference_config) {
    return { inference_config: {}, parameters };
  }

  return { inference_config, parameters };
}

export function areAllMultiInputValuesValid(
  values: (number[] & { length: 3 }) | undefined
) {
  if (!values) {
    return true;
  }

  const errors = values.map((num, index) => {
    if (!Number.isFinite(num)) {
      return `Channel ${index + 1} must be specified.`;
    }

    return '';
  });

  if (errors.every((error) => error === '')) {
    return true;
  }

  return errors.join(' ');
}

/**
 * Model default parameters.
 */
export function Step2() {
  const navigate = useNavigate();

  const { model, baseUrl, data, error, isSaving, dispatch, save } =
    useModelFormState();
  const {
    formState,
    control,
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    ...formMethods
  } = useForm<Step2FieldValues>({
    mode: 'onBlur',

    defaultValues: model
      ? getValuesFromModel(model)
      : {
          inference_config: data?.inference_config,
          parameters: data?.parameters,
        },
  });

  useModelFormStep({ reset, getValues });

  React.useEffect(() => {
    if (formState.isSubmitted || !baseUrl) {
      return;
    }

    if (!data || isEmptyObject(data) || !data.name) {
      navigate('..', { replace: true });
    }
  }, [data, baseUrl, formState.isSubmitted, navigate]);

  const onSubmit = React.useCallback(
    async (formData: Step2FieldValues) => {
      if (!baseUrl) {
        const res = await save({
          inference_config: {
            ...formData.inference_config,
            class_attributes: {},
          },
          parameters: formData.parameters ?? model?.parameters,
        });

        dispatch({ type: 'update', data: formData });

        if (res) {
          reset(getValuesFromModel(res));
        }

        return;
      }

      if (!data || isEmptyObject(data)) {
        return;
      }

      const modelData: ModelFormData = {
        ...data,
        ...formData,
        inference_config: {
          ...data.inference_config,
          ...formData.inference_config,
          class_attributes: {},
        },
      };

      if (!modelData || isEmptyObject(modelData)) {
        return;
      }

      if (data.capability === 'detection') {
        dispatch({ type: 'update', data: formData });
        navigate('../inference-config');
      } else {
        save(modelData);
      }
    },
    [baseUrl, data, model, dispatch, save, reset, navigate]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider
        {...{
          formState,
          handleSubmit,
          register,
          reset,
          getValues,
          control,
          watch,
          ...formMethods,
        }}
      >
        <Field
          id="normalization_mean"
          label="Mean values"
          info="Per-channel values subtracted from each pixel before being passed to the model."
          labelWidth={FIELD_LABEL_WIDTH}
          labelVerticalAlign="flex-start"
          error={formState.errors.inference_config?.normalization_mean}
        >
          <Controller
            name="inference_config.normalization_mean"
            control={control}
            render={({ field: { value, onChange } }) => (
              <MultiInput
                id="normalization_mean"
                length={3}
                type="number"
                value={value}
                decimalScale={4}
                onChange={onChange}
                step={0.01}
                min={0}
                max={1}
              />
            )}
            rules={{
              validate: areAllMultiInputValuesValid,
            }}
          />
        </Field>

        <NetScaleFactorInput />

        <Separator />

        <ColorFormatInput />
        <NetworkModeInput />
        <FormatConfiguration />
        <CapabilityConfiguration />

        {data?.capability !== 'detection' && (
          <>
            <Separator />
            <AdvancedConfiguration />
          </>
        )}

        <LeavePrompt />

        <FormErrorMessage error={error} />

        <ButtonGroup>
          <Button type="submit" variant="primary" loading={isSaving}>
            {model && 'Save changes'}
            {!model &&
              (data?.capability === 'detection'
                ? 'Continue to inference parameters'
                : 'Finish & Save model')}
          </Button>

          {model &&
            !isSaving &&
            !formState.isDirty &&
            formState.isSubmitSuccessful && (
              <InlineNotification intent="success" icon="check-circle">
                Saved!
              </InlineNotification>
            )}
        </ButtonGroup>
      </FormProvider>
    </form>
  );
}
