import React from 'react';
import { CellProps } from 'react-table';

import Pipeline from 'types/pipeline';

import { useDeployments } from 'hooks/api/useDeployments';

import { Icon } from 'components/Icon/Icon';

export function PipelineOverviewDeploymentsCell({ row }: CellProps<Pipeline>) {
  const pipeline = row.original;
  const [deploymentsCount, setDeploymentsCount] = React.useState<number>(0);

  const { data: deployments } = useDeployments(
    ['pipeline-deployments', pipeline.id],
    {
      pipeline_ids: [pipeline.id],
      limit: 1,
    },
    { enabled: Boolean(pipeline.id) }
  );

  React.useEffect(() => {
    if (!deployments) {
      return;
    }

    setDeploymentsCount(deployments.total_elements);
  }, [pipeline.id, deployments]);

  return (
    <>
      <Icon name="deployment" />
      <span>{deploymentsCount || 0}</span>
    </>
  );
}
