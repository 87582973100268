import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { useAPI } from 'hooks/api/useAPI';

import Application from 'types/application';
import APIError from 'types/api_error';
import Organization from 'types/organization';

import { useAuthenticatedQuery } from './useAuthenticatedQuery';

export function useApplications() {
  const api = useAPI();
  return useAuthenticatedQuery<Application[]>(
    ['applications'],
    api.applications.list,
    {
      staleTime: Infinity,
      select: (apps) =>
        apps.sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
        ),
    }
  );
}

export type UseOrganizationApplicationsFilterParams = {
  org_id?: Organization['id'];
};

export function useOrganizationApplications(
  organizationId: Organization['id'] | undefined
) {
  const api = useAPI();

  return useAuthenticatedQuery<Application[]>(
    ['applications', 'organization', organizationId],
    () => {
      if (!organizationId) {
        return Promise.reject('No organization id provided.');
      }

      return api.organizations.applications.list(organizationId);
    },
    {
      select(apps) {
        return apps.sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
        );
      },
    }
  );
}

export function useCreateApplication({
  onSettled,
  ...options
}: UseMutationOptions<Application, APIError, Application> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation(api.applications.create, {
    ...options,
    onSettled: (...args) => {
      if (onSettled) {
        onSettled(...args);
      }

      queryClient.invalidateQueries(['applications']);
    },
  });
}

export function useDeleteApplication(
  application: Application,
  { onMutate, onSettled, onError, ...options }: UseMutationOptions = {}
) {
  const api = useAPI();

  return useMutation(() => api.applications.delete(application.id), options);
}
