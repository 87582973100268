import React from 'react';
import { Output } from 'rete';
import { Socket } from 'rete-react-render-plugin';
import c from 'classnames';

import { Icon } from 'components/Icon/Icon';
import { Tooltip } from 'components/Tooltip/Tooltip';

export type EditorNodeOutputProps = {
  output: Output;
  outputMetadata?: string[];
};

function EditorNodeOutput(
  { output, outputMetadata }: EditorNodeOutputProps,
  ref: unknown
) {
  const innerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Tooltip
      side="right"
      sideOffset={16}
      content={
        <>
          <strong>{output.socket.name}</strong>
          {outputMetadata &&
            outputMetadata.map((meta) => (
              <p key={meta}>
                <Icon name="plus" /> <span>{meta}</span>
              </p>
            ))}
        </>
      }
    >
      <div
        className={c('output', { active: output.connections.length > 0 })}
        key={output.key}
        ref={innerRef}
      >
        <Socket
          type="output"
          socket={output.socket}
          io={output}
          innerRef={ref}
        />

        <div className={c('socket-mirror')} />
      </div>
    </Tooltip>
  );
}

const forwardRef = React.forwardRef<unknown, EditorNodeOutputProps>(
  EditorNodeOutput
);

export { forwardRef as EditorNodeOutput };
