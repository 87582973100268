import { Controller, useFormContext } from 'react-hook-form';

import { Accordion } from 'components/Accordion/Accordion';
import { Field } from 'components/Field/Field';
import { JsonObjectInput } from 'components/JsonObjectInput/JsonObjectInput';

import { FIELD_LABEL_WIDTH } from '../Step1';

const JSON_PARAMETER_DEFAULT_VALUE = { '': '' };

export function AdvancedConfiguration() {
  const { control } = useFormContext();

  return (
    <Accordion label="Advanced configuration" variant="flat">
      <Field
        id="model-parameters"
        label="Parameters"
        labelVerticalAlign="flex-start"
        labelWidth={FIELD_LABEL_WIDTH}
      >
        <Controller
          name="parameters"
          control={control}
          render={({ field: { value, onChange } }) => (
            <JsonObjectInput value={value} onChange={onChange} />
          )}
          defaultValue={JSON_PARAMETER_DEFAULT_VALUE}
        />
      </Field>
    </Accordion>
  );
}
