import React from 'react';
import classNames from 'classnames/bind';
import { CellProps } from 'react-table';

import LumeodMetric from 'types/metric';

import { Abbr } from 'components/Abbr/Abbr';

import styles from '../Settings.module.scss';

const c = classNames.bind(styles);

export type MetricsConfigTableAttributesProps = CellProps<LumeodMetric>;

export function MetricsConfigTableAttributes({
  row,
}: MetricsConfigTableAttributesProps) {
  return (
    <ul className={c('config-table-attributes')}>
      {row.original.attributes.map(({ name, info }) => (
        <li key={name}>{info ? <Abbr info={info}>{name}</Abbr> : name}</li>
      ))}
    </ul>
  );
}
