import Rete, { Node } from 'rete';

import { RawVideo } from 'pipelines/services/rete/sockets';
import Component from 'pipelines/services/rete/nodes/component';
import {
  FlipVideoInput,
  FrameRateInput,
  ResolutionInput,
  RotationInput,
} from 'pipelines/services/rete/controls/configured_inputs';
import ReteCropInput from 'pipelines/services/rete/controls/crop';

export default class TransformVideo extends Component {
  static key = 'Transform Video';
  static icon = 'transform' as const;
  static exportType = 'transform';
  static category = 'transformations' as const;
  static description =
    'Manipulate video transformations, resolution, and frame rate';

  static input = {
    type: 'raw',
  };
  static output = {
    type: 'raw',
  };

  constructor() {
    super(TransformVideo.key);
  }

  async builder(node: Node) {
    node
      .addControl(FrameRateInput(this.editor!, {}))
      .addControl(ResolutionInput(this.editor!, {}))
      .addControl(RotationInput(this.editor!, { group: 'transformations' }))
      .addControl(FlipVideoInput(this.editor!, { group: 'transformations' }))
      .addControl(
        new ReteCropInput(this.editor!, 'crop', {
          label: 'Crop',
          info: 'Specify the amount of pixels to crop from each side.',
          group: 'transformations',
        })
      )
      .addInput(new Rete.Input('input', 'Input', RawVideo, false))
      .addOutput(new Rete.Output('output', 'Output', RawVideo));
  }
}
