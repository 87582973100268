import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import OrganizationInvitation from 'types/invitation';

import { updateQueriesData } from 'services/update_queries_data';

import { useAPI } from 'hooks/api/useAPI';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';

/** Remove organization invitation from organization */
export function useDeleteOrganizationInvitation({
  onMutate,
  onSettled,
  onError,
  ...options
}: UseMutationOptions<void, APIError, OrganizationInvitation['id']> = {}) {
  const queryClient = useQueryClient();
  const { organizations } = useAPI();

  const { data: organization } = useCurrentOrganization();
  const organizationId = organization?.id;

  return useDangerousMutation(
    'Are you sure you want to cancel and delete this invitation?',
    (organizationInvitationId) => {
      if (!organizationId) {
        return Promise.reject('Missing organization id');
      }

      return organizations.invitations.delete(
        organizationId,
        organizationInvitationId
      );
    },
    {
      ...options,
      action: 'delete',
      entity: 'invitation',
      async onMutate(organizationInvitationId) {
        await queryClient.cancelQueries(['organization-invitations']);

        onMutate?.(organizationInvitationId);

        return updateQueriesData({
          queryClient,
          listQueryKey: ['organization-invitations'],
          ids: [organizationInvitationId],
          updateData: null,
        });
      },
      onError(error, variables, context) {
        onError?.(error, variables, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(...args) {
        onSettled?.(...args);

        queryClient.invalidateQueries(['organization-invitations']);
      },
    }
  );
}
