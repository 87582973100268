import React from 'react';
import classnames from 'classnames/bind';

import { Icon } from 'components/Icon/Icon';

import {
  AdvancedSearchBarInput,
  AdvancedSearchBarInputProps,
} from './AdvancedSearchBarInput';
import { AdvancedSearchBarWrap } from './AdvancedSearchBarWrap';
import styles from './AdvancedSearchBar.module.scss';

const c = classnames.bind(styles);

export type AdvancedSearchBarProps = AdvancedSearchBarInputProps;

export function AdvancedSearchBar({
  label = 'Search',
}: AdvancedSearchBarProps) {
  const filterRef = React.useRef<HTMLFormElement>(null);

  return (
    <AdvancedSearchBarWrap ref={filterRef}>
      <label htmlFor="filterbar_search" className="sr-only">
        {label}
      </label>
      <label htmlFor="filterbar_search">
        <Icon name="search" className={c('search-icon')} />
      </label>

      <div className={c('search-wrap')}>
        <div className={c('search-inner')}>
          <AdvancedSearchBarInput label={label} />
        </div>
      </div>
    </AdvancedSearchBarWrap>
  );
}
