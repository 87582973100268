import React from 'react';

import { FilterInput } from 'graphql/types/filter';

import { formatPretty } from 'services/string';

import { Input } from 'components/Input/Input';
import { OptionalTooltip } from 'components/Tooltip/OptionalTooltip';
import { Select } from 'components/Select/Select';

import { DeploymentFilterInput } from './DeploymentFilterInput';
import { GatewayFilterInput } from './GatewayFilterInput';
import { OPERATORS_WITHOUT_VALUE } from '../OperatorInput';
import { PipelineFilterInput } from './PipelineFilterInput';
import { StreamFilterInput } from './StreamFilterInput';
import { useMetricFiltersState } from '../context';

export const Placeholder = <Select isDisabled isLoading />;

export type SuggestionInputProps = Omit<FilterInput, 'and' | 'or'>;

export function SuggestionInput({
  id,
  column,
  operator,
  value,
}: SuggestionInputProps) {
  const { updateFilter } = useMetricFiltersState();

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    updateFilter(id, 'value', event.target.value.trim());
  }

  const isDisabled = OPERATORS_WITHOUT_VALUE.includes(operator);

  function renderInput() {
    switch (column) {
      case 'deployment_id':
        return <DeploymentFilterInput id={id} value={value} />;
      case 'gateway_id':
        return <GatewayFilterInput id={id} value={value} />;
      case 'pipeline_id':
        return <PipelineFilterInput id={id} value={value} />;
      case 'source_id':
        return <StreamFilterInput id={id} value={value} />;
      default:
        return (
          <Input
            defaultValue={value ? String(value) : undefined}
            onChange={handleInputChange}
          />
        );
    }
  }

  return (
    <OptionalTooltip
      showTooltip={isDisabled}
      content={`Value will be ignored when using "${formatPretty(
        operator
      ).toLowerCase()}" operator`}
    >
      {isDisabled ? <Input disabled /> : renderInput()}
    </OptionalTooltip>
  );
}
