import React from 'react';
import classNames from 'classnames/bind';
import { CellProps, Column } from 'react-table';
import { useToggle } from '@mantine/hooks';

import Camera from 'types/camera';
import Gateway from 'types/gateway';

import { relativeTimeFromNow } from 'services/date';

import { useGatewayCameras } from 'hooks/api/useGatewayCameras';
import { useHasAccess } from 'hooks/useHasAccess';

import { AddCameraDialog } from 'cameras/components/AddCameraDialog';
import { Button } from 'components/Button/Button';
import { NameCell, Table } from 'components/Table/Table';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';

import styles from './CamerasTable.module.scss';

const c = classNames.bind(styles);

export type GatewayCamerasProps = {
  gateway: Gateway;
};

const columns: Column<Camera>[] = [
  {
    Header: 'Name',
    Cell(props: CellProps<Camera>) {
      return (
        <NameCell
          {...props}
          to={`/deploy/cameras/${props.row.original.id}`}
          entity="camera"
        />
      );
    },
  },
  {
    Header: 'Status',
    Cell(camera: CellProps<Camera>) {
      return <StatusIndicator status={camera.row.original.status} />;
    },
  },
  {
    Header: 'Model',
    accessor(camera) {
      return camera.model || '';
    },
  },
  {
    Header: 'Address',
    accessor(camera) {
      return camera.ip_local || '';
    },
  },
  {
    Header: 'Recent activity',
    accessor(camera) {
      return relativeTimeFromNow(camera.updated_at);
    },
  },
];

export function GatewayCameras({ gateway }: GatewayCamerasProps) {
  const { cameras, isLoading, isCameraLinked } = useGatewayCameras(gateway.id);

  const linkedCameras = React.useMemo(() => {
    return cameras.filter(isCameraLinked);
  }, [cameras, isCameraLinked]);

  const [isAddingCamera, toggleIsAddingCamera] = useToggle();

  const [hasAccess] = useHasAccess();

  if (!gateway.id) {
    return null;
  }

  return (
    <div className={c('wrap')}>
      <header className={c('header')}>
        <Text type="paragraph">
          ONVIF and USB cameras accessible by this gateway
        </Text>

        {hasAccess('deploy_edit') && (
          <>
            <Button
              id="up-gateway-cameras_add"
              onClick={() => toggleIsAddingCamera(true)}
              variant="primary"
              size="small"
            >
              Add Camera
            </Button>
            <AddCameraDialog
              open={isAddingCamera}
              onClose={toggleIsAddingCamera}
              gateway={gateway}
            />
          </>
        )}
      </header>

      <Table
        id="gateway-cameras-overview"
        label="gateway cameras"
        columns={columns}
        data={linkedCameras}
        loading={isLoading}
      />
    </div>
  );
}
