import { Connection, Node, Output } from 'rete';
import { Node as DefaultNode } from 'rete-react-render-plugin';
import classnames from 'classnames/bind';

import { getCategoryByNodeName } from 'pipelines/services/rete/nodes/categories';
import { getComponentByName } from 'pipelines/services/rete/nodes';
import Component from 'pipelines/services/rete/nodes/component';
import ReteInput from 'pipelines/services/rete/controls/input';

import { Icon, IconType } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';

import { EditorNodeOutput } from './EditorNodeOutput';
import { EditorNodeInputs } from './EditorNodeInputs';
import { EditorNodeProperty } from './EditorNodeProperty';
import { EditorNodePricingPlanLabel } from './PricingPlanLabel';
import styles from './EditorNode.module.scss';

const c = classnames.bind(styles);

type ConnectionElements = {
  [nodeID: string]: HTMLElement[];
};

type ReteRenderConnectionEvent = {
  connection: Connection;
  el: HTMLElement;
};

export class EditorNode extends DefaultNode {
  id = Math.floor(Math.random() * 10000);
  mounted = false;

  connectionElements: ConnectionElements = {};

  componentDidMount() {
    const { editor } = this.props;
    this.mounted = true;

    if (this.mounted) {
      // Save all incoming and outgoing connections of a node with
      // the theme color of the output end
      editor.on(
        'renderconnection',
        ({ connection, el }: ReteRenderConnectionEvent) => {
          const inputNode = connection.input.node;
          const outputNode = connection.output.node;

          if (!inputNode || !outputNode) {
            return;
          }

          const category = getCategoryByNodeName(inputNode.name);

          const theme = category ? category.theme : null;

          if (!this.connectionElements[inputNode.id]) {
            this.connectionElements[inputNode.id] = [];
          }

          if (!this.connectionElements[outputNode.id]) {
            this.connectionElements[outputNode.id] = [];
          }

          if (theme) {
            el.classList.add('theme', theme);
          }

          this.connectionElements[inputNode.id].push(el);
          this.connectionElements[outputNode.id].push(el);
        }
      );
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { node, bindSocket, bindControl, onDelete } = this.props;
    const { outputs, inputs, selected } = this.state;

    const component = getComponentByName(node.name);

    if (!component) {
      return;
    }

    const { icon, outputMetadata, status } =
      component as unknown as typeof Component;
    const category = getCategoryByNodeName(node.name);

    const theme = category ? category.theme : null;
    const connections = this.connectionElements[node.id] || [];

    const properties = Array.from<ReteInput>(node.controls.values())
      .filter((control) => control.deploymentParam !== 'always')
      .map((control) => ({
        label: control.label!,
        key: control.key,
        type: control.type,
      }));

    return (
      <article
        data-name={(node as Node).name}
        className={c('node', { selected })}
        onMouseOver={(_) => {
          connections.forEach((el: HTMLElement) =>
            el.classList.add('active-connection')
          );
        }}
        onMouseOut={(_) => {
          connections.forEach((el: HTMLElement) =>
            el.classList.remove('active-connection')
          );
        }}
      >
        <div className={c('content', 'card', `theme ${theme}`)}>
          <EditorNodeInputs
            inputs={inputs}
            bindSocket={bindSocket}
            bindControl={bindControl}
          />

          <EditorNodePricingPlanLabel Component={component} />

          {status && status === 'beta' && (
            <div className={c('node-status', status)}>Beta</div>
          )}

          <div className={c('header')}>
            <div className={c('header-icon-wrap')}>
              <Icon name={icon as IconType} className={c('header-icon')} />
            </div>
            <strong className={c('header-title')}>{node.name}</strong>
            <span className={c('header-id')}>{node.id}</span>

            {/* edit node properties button */}
            {properties && properties.length ? (
              <div className={c('header-click-teaser')}>
                <IconButton
                  className="userpilot-node-settings-btn"
                  onClick={() => this.props.onEdit(node)}
                  icon="settings"
                  label={`Edit "${node.id}" properties`}
                  variant="ghost"
                />
              </div>
            ) : null}

            <div className={c('delete-button', 'theme danger')}>
              <IconButton
                onClick={() => onDelete(node)}
                intent="danger"
                icon="delete"
                label={`Delete "${node.id}"`}
                size="small"
              />
            </div>
          </div>

          {properties && properties.length ? (
            <dl className={c('properties', 'theme default')}>
              {properties.map(({ key, label, type }) => (
                <EditorNodeProperty
                  key={key}
                  label={label}
                  node={node}
                  name={key}
                  type={type}
                />
              ))}
            </dl>
          ) : null}

          <div className={c('outputs', { multiOutput: outputs.length > 1 })}>
            {outputs.map((output: Output) => (
              <EditorNodeOutput
                output={output}
                outputMetadata={outputMetadata}
                ref={bindSocket}
                key={output.key}
              />
            ))}
          </div>
        </div>
      </article>
    );
  }
}
