import React from 'react';
import classNames from 'classnames/bind';

import { Icon, IconType } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import styles from './ContextMenu.module.scss';

const c = classNames.bind(styles);

export type ContextMenuLabelProps = React.PropsWithChildren<{
  icon?: IconType;
}>;

export function ContextMenuLabel({ icon, children }: ContextMenuLabelProps) {
  return (
    <Text className={c('group-label')} size="small">
      {icon && <Icon name={icon} />}
      <strong>{children}</strong>
    </Text>
  );
}
