import React from 'react';
import classnames from 'classnames/bind';
import { Controller, useForm } from 'react-hook-form';

import Gateway from 'types/gateway';

import { useUpdateGateway } from 'gateways/hooks/useUpdateGateway';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { NumberInput } from 'components/NumberInput/NumberInput';

import styles from './GatewayDetail.module.scss';

const c = classnames.bind(styles);

export type GatewayDetailSettingsProps = {
  gateway: Gateway;
};

type GatewaySettingsFieldValues = {
  max_running_deployments: number;
};

export function GatewayDetailSettings({ gateway }: GatewayDetailSettingsProps) {
  const { formState, control, handleSubmit, reset } =
    useForm<GatewaySettingsFieldValues>({
      defaultValues: { ...gateway },
    });

  const { mutateAsync: updateGateway, isLoading: isSaving } =
    useUpdateGateway();

  async function onSubmit({
    max_running_deployments,
  }: GatewaySettingsFieldValues) {
    const updatedGateway = gateway.copy();

    updatedGateway.max_running_deployments = max_running_deployments;

    const res = await updateGateway(updatedGateway);
    reset(res);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={c('settings')}>
      <Field
        id="max_deployments"
        label="Max. number of running deployments"
        required
      >
        <Controller
          control={control}
          name="max_running_deployments"
          render={({ field: { value, onChange } }) => (
            <NumberInput
              min={0}
              max={65_535}
              placeholder="unlimited"
              value={value}
              onChange={onChange}
            />
          )}
          defaultValue={10}
          rules={{
            required: 'Please enter a valid number.',
            min: {
              value: 0,
              message: 'Please enter a value above 0.',
            },
          }}
        />
      </Field>

      <ButtonGroup>
        <Button type="submit" variant="primary" loading={isSaving}>
          Save
        </Button>
        {!isSaving && !formState.isDirty && formState.isSubmitSuccessful && (
          <InlineNotification intent="success" icon="check-circle">
            Saved!
          </InlineNotification>
        )}
      </ButtonGroup>
    </form>
  );
}
