import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
} from 'pipelines/services/rete/controls/input';

import { MetadataList } from 'pipelines/components/MetadataList/MetadataList';

export default class ReteMetadataListInput extends ReteInput {
  constructor(
    editor: NodeEditor,
    key: string,
    params: Omit<ReteInputParams, 'type'>
  ) {
    super(editor, key, { ...params, type: 'string', inputType: 'select' });
  }

  get component() {
    return MetadataList;
  }
}
