import { Input, NodeEditor, Output } from 'rete';

type InputSource = 'click' | 'pointer';

type SocketListenersMapType = {
  el: HTMLElement;
  handleClick: (event: MouseEvent) => void;
  handlePointerDown: (event: MouseEvent) => void;
};

function install(editor: NodeEditor) {
  const socketListeners = new Map<string, SocketListenersMapType>();
  let inputSource: InputSource | null = null;
  let disabledHTMLNodes: HTMLElement[] = [];

  function resetDisabledHTMLNodes() {
    disabledHTMLNodes.forEach((node) => {
      node.classList.remove('disabled');
    });
    disabledHTMLNodes = [];
  }

  function disableHTMLNode(nodeElement: HTMLElement) {
    nodeElement.classList.add('disabled');
    disabledHTMLNodes.push(nodeElement);
  }

  async function disablePipelineNodes(io: Input | Output | undefined) {
    if (!io || !io.node) {
      return;
    }

    resetDisabledHTMLNodes();

    // Compatible nodes either have the same type or 'any' type
    const compatibleTypes = [
      io.socket.name,
      ...io.socket.compatible.map((compatible) => compatible.name),
    ];

    editor.view.nodes.forEach(({ el, node }) => {
      // Disable nodes without inputs
      if (node.inputs.size === 0) {
        disableHTMLNode(el);
      }

      // Disable incompatible nodes
      for (const { socket } of node.inputs.values()) {
        if (!compatibleTypes.includes(socket.name)) {
          disableHTMLNode(el);
        }
      }
    });
  }

  let hasActiveClickConnection = false;

  editor.on('rendersocket', ({ el, output, input }) => {
    // type-cast because it's always gonna be either input or output
    const io = (output as Output) || (input as Input);

    if (!io.node || !el) {
      return;
    }

    const key = `${io.node.id}_${io.key}`;
    const previous = socketListeners.get(key);

    if (previous) {
      previous.el.removeEventListener('click', previous.handleClick);
      previous.el.removeEventListener(
        'pointerdown',
        previous.handlePointerDown
      );
    }

    // Visually de-emphasize incompatible nodes when drawing a connection
    el.addEventListener('click', handleClick);
    el.addEventListener('pointerdown', handlePointerDown);

    socketListeners.set(key, { el, handleClick, handlePointerDown });

    function handleClick(event: MouseEvent) {
      event.stopPropagation();

      if (!hasActiveClickConnection) {
        // user is starting to draw a connection
        inputSource = 'click';
        hasActiveClickConnection = true;

        window.setTimeout(() => {
          disablePipelineNodes(io);
          editor.view.container.classList.add('drawing-connection');
        });
      } else {
        // second click - abort or finish connection
        inputSource = null;
        hasActiveClickConnection = false;
        resetDisabledHTMLNodes();
        editor.view.container.classList.remove('drawing-connection');
      }
    }

    function handlePointerDown() {
      if (inputSource) {
        return;
      }

      inputSource = 'pointer';
      hasActiveClickConnection = false;

      window.setTimeout(() => {
        disablePipelineNodes(io);
        editor.view.container.classList.add('drawing-connection');
      });
    }
  });

  editor.view.container.addEventListener('click', (_) => {
    editor.view.container.classList.remove('drawing-connection');
    resetDisabledHTMLNodes();
    hasActiveClickConnection = false;
    inputSource = null;
  });

  editor.view.container.addEventListener('pointerup', (_) => {
    if (inputSource === 'pointer') {
      inputSource = null;

      window.setTimeout(() => {
        if (!hasActiveClickConnection) {
          resetDisabledHTMLNodes();
          editor.view.container.classList.remove('drawing-connection');
        }
      });
    }
  });

  editor.on(['connectioncreated', 'updateconnection'], resetDisabledHTMLNodes);
}

const plugin = { name: 'NodeCompatibilityHighlight', install };

export default plugin;
