import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { SharedStream } from 'types/shared_stream';

import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';

type UseDeleteSharedStreamParams = { id: SharedStream['id'] };

export function useDeleteSharedStream({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<void, APIError, UseDeleteSharedStreamParams> = {}) {
  const queryClient = useQueryClient();
  const { client, applicationID } = useAPI();

  return useDangerousMutation(
    'Are you sure you want to delete this shared stream?',
    ({ id }) => client.delete(`/apps/${applicationID}/shared_streams/${id}`),
    {
      ...options,
      async onMutate({ id }) {
        await queryClient.cancelQueries(['shared-streams']);

        onMutate?.({ id });

        return updateQueriesData<SharedStream>({
          queryClient,
          listQueryKey: ['shared-streams'],
          singleQueryKey: ['shared-stream'],
          ids: [id],
          updateData: null,
        });
      },
      onError(error, params, context) {
        onError?.(error, params, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(...args) {
        onSettled?.(...args);
        queryClient.invalidateQueries(['shared-streams']);
      },
    }
  );
}
