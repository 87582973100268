import Rete, { Node } from 'rete';

import Component from 'pipelines/services/rete/nodes/component';
import { RawVideo } from 'pipelines/services/rete/sockets';

export default class OverlayMetadata extends Component {
  static key = 'Overlay Metadata';
  static icon = 'overlay' as const;
  static exportType = 'overlay';
  static category = 'transformations' as const;
  static description = 'Draw model inference data on a video stream';

  static release = 'alpha1';
  static input = {
    type: 'raw',
  };
  static output = {
    type: 'raw',
  };

  constructor() {
    super(OverlayMetadata.key);
  }

  async builder(node: Node) {
    node
      .addInput(new Rete.Input('input', 'Input', RawVideo, false))
      .addOutput(new Rete.Output('output', 'Output', RawVideo));
  }
}
