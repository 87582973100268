import React from 'react';
import {
  IPublishPacket,
  OnMessageCallback as MqttClientOnMessageCallback,
} from 'mqtt';

import { useMqttClient } from 'hooks/useMqttClient';

export type OnMessagePayload = {
  topic: string;
  message: string;
  buffer: Buffer;
  packet: IPublishPacket;
};
export type OnMessageCallback = (payload: OnMessagePayload) => void;
export type SubscriptionMap = Record<string, { onMessage: OnMessageCallback }>;

export function useMqttSubscribe(subscriptions: SubscriptionMap) {
  const client = useMqttClient();

  const onMessage = React.useCallback<MqttClientOnMessageCallback>(
    (topic, buffer, packet) => {
      const callback = subscriptions[topic];

      if (!callback) return;

      const { onMessage } = callback;
      let message: string;

      try {
        message = new TextDecoder('utf-8').decode(buffer);
        onMessage({ topic, message, buffer, packet });
      } catch (error) {
        console.error('Failed to decode mqtt payload.');
      }
    },
    [subscriptions]
  );

  React.useEffect(() => {
    const topics = Object.keys(subscriptions);
    const hasTopics = topics.length > 0;

    if (client && hasTopics) {
      client.subscribe(topics);
      client.on('message', onMessage);
    }

    return () => {
      if (client && hasTopics) {
        client.unsubscribe(topics);
        client.off('message', onMessage);
      }
    };
  }, [client, onMessage, subscriptions]);
}
