import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function useCamera(
  cameraId?: Camera['id'] | null,
  { enabled, ...options }: UseQueryOptions<Camera, APIError> = {}
) {
  const api = useAPI();

  return useAuthenticatedQuery(
    ['camera', cameraId],
    () => {
      if (!cameraId) {
        return Promise.reject('No camera id provided.');
      }

      return api.cameras.read(cameraId);
    },
    {
      ...options,
      enabled:
        enabled !== undefined
          ? Boolean(cameraId) && enabled
          : Boolean(cameraId),
    }
  );
}
