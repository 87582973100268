import React from 'react';
import classNames from 'classnames/bind';
import {
  DimensionInput,
  FilterInput,
  Leaderboard,
  LeaderboardChartVariant,
  Sort,
} from '@propeldata/ui-kit';

import { useLeaderboard } from 'dashboards/hooks/useLeaderboard';
import { useTimeRangeParams } from 'dashboards/hooks/useTimeRangeParams';

import { ChartError } from './components/ChartError';
import { ChartLoader } from './components/ChartLoader';
import { WIDGET_REFETCH_INTERVAL } from '../services/interval';
import { hslaToString } from '../services/color';
import styles from './Leaderboard.module.scss';
import { useChartColors } from '../hooks/useChartColors';

const c = classNames.bind(styles);

export type PropelLeaderboardProps = {
  query: {
    metric: string;
    dimensions: DimensionInput[];
    rowLimit: number;
    sort: Sort;
    filters?: FilterInput[];
  };
  variant: LeaderboardChartVariant;
};

export function PropelLeaderboard({
  query: { metric, dimensions, rowLimit, sort, filters },
  variant,
}: PropelLeaderboardProps) {
  const { start, stop, timezone } = useTimeRangeParams();

  const {
    data,
    isLoading: isLoadingLeaderboard,
    error,
  } = useLeaderboard(
    {
      metric: { name: metric },
      dimensions,
      rowLimit,
      sort,
      timeRange: { start, stop },
      filters,
      timeZone: timezone,
    },
    { refetchInterval: WIDGET_REFETCH_INTERVAL, keepPreviousData: true }
  );

  // Use first column as labels
  const labels = data?.leaderboard.rows.map(([label]) => label);

  const { data: colors, isLoading: isLoadingColors } = useChartColors(labels);

  const isLoading = isLoadingLeaderboard || isLoadingColors;

  if (error) {
    return (
      <ChartError
        error={{ title: 'No data', body: 'Failed to fetch values.' }}
      />
    );
  }

  if (!data && isLoading) {
    return <ChartLoader />;
  }

  if (!data || !colors) {
    return null;
  }

  return (
    <Leaderboard
      className={c('leaderboard')}
      headers={data.leaderboard.headers}
      rows={data.leaderboard.rows}
      chartConfigProps={(config) => ({
        ...config,
        data: {
          ...config.data,
          datasets: config.data.datasets.map((dataset) => ({
            ...dataset,
            backgroundColor: colors.map(hslaToString),
            borderColor: colors.map(hslaToString),
          })),
        },
        options: {
          ...config.options,
          elements: {
            ...config.options?.elements,
            point: {
              ...config.options?.elements?.point,
              hoverBackgroundColor: undefined,
            },
            bar: {
              ...config.options?.elements?.bar,
              hoverBackgroundColor: undefined,
              hoverBorderColor: undefined,
            },
          },
        },
      })}
      variant={variant}
      timeZone={timezone}
    />
  );
}
