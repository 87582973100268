import { serializeAll } from 'serializr';

import Organization from './organization';
import Application from './application';

export const APPLICATION_ROLES = [
  'designer',
  'deployer',
  'monitor',
  'user',
] as const;
export const ORGANIZATION_ROLES = ['owner', 'billing', 'manager'] as const;
export const LUMEO_INTERNAL_ROLES = ['admin'] as const;

export type ApplicationRole = typeof APPLICATION_ROLES[number];
export type OrganizationRole = typeof ORGANIZATION_ROLES[number];
export type LumeoInternalRole = typeof LUMEO_INTERNAL_ROLES[number];

export type Role = ApplicationRole | OrganizationRole | LumeoInternalRole;

@serializeAll
export default class AccountRole {
  name: Role;
  organization_id?: Organization['id'];
  application_id?: Application['id'];

  constructor(accountRole: AccountRole = { name: 'user' }) {
    this.name = accountRole.name;
    this.application_id = accountRole.application_id;
    this.organization_id = accountRole.organization_id;
  }
}

// Type guard
export function isApplicationRole(role: string): role is ApplicationRole {
  return ([...APPLICATION_ROLES] as string[]).includes(role);
}

// Type guard
export function isOrganizationRole(role: string): role is OrganizationRole {
  return ([...ORGANIZATION_ROLES] as string[]).includes(role);
}

// Type guard
export function isLumeoInternalRole(role: string): role is LumeoInternalRole {
  return ([...LUMEO_INTERNAL_ROLES] as string[]).includes(role);
}
