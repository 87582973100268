import classNames from 'classnames/bind';
import { useQueryClient } from '@tanstack/react-query';
import { useClipboard } from '@mantine/hooks';

import { useServerVersion } from 'hooks/api/useServerVersion';

import { capitalizeFirst } from 'services/string';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Heading } from 'components/Heading/Heading';
import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './LumeoVersion.module.scss';

const c = classNames.bind(styles);

export function LumeoVersion() {
  const queryClient = useQueryClient();
  const consoleVersion = queryClient.getQueryData<string>(['console-version']);

  const { data: serverVersion, isLoading: isServerVersionLoading } =
    useServerVersion();

  const { copied: isCopied, copy } = useClipboard({ timeout: 2000 });

  if (!consoleVersion && !serverVersion) {
    return null;
  }

  function handleCopyClick() {
    let str = '';

    if (consoleVersion) {
      str += `Console: ${consoleVersion}\n`;
    }

    if (serverVersion) {
      Object.entries(serverVersion).forEach(([key, value]) => {
        if (key.trim() && value) {
          str += `${capitalizeFirst(key.replace('_', ' '))}: ${value}\n`;
        }
      });
    }

    copy(str);
  }

  return (
    <div>
      <div className={c('version-heading')}>
        <Heading level="2">Version</Heading>
        {isServerVersionLoading && (
          <span
            className="loading-spinner xsmall"
            aria-label="Loading server version..."
          />
        )}
      </div>
      <dl className={c('version-list')}>
        {consoleVersion && (
          <>
            <dt className={c('version-list-term')}>Console</dt>
            <dd className={c('version-list-data')}>{consoleVersion}</dd>
          </>
        )}

        {serverVersion && (
          <>
            {serverVersion.tag && (
              <>
                <dt className={c('version-list-term')}>API Tag</dt>
                <dd className={c('version-list-data')}>{serverVersion.tag}</dd>
              </>
            )}
            <dt className={c('version-list-term')}>API Commit</dt>
            <dd className={c('version-list-data')}>{serverVersion.commit}</dd>
            <dt className={c('version-list-term')}>Rust Version</dt>
            <dd className={c('version-list-data')}>
              {serverVersion.rust_version}
            </dd>
          </>
        )}
      </dl>

      <ButtonGroup>
        <Tooltip side="right" content="Copied to clipboard!" open={isCopied}>
          <Button size="small" onClick={handleCopyClick}>
            Copy to clipboard
          </Button>
        </Tooltip>
      </ButtonGroup>
    </div>
  );
}
