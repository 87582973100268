import React from 'react';
import classNames from 'classnames/bind';

import { usePageLayout } from '../context/page_layout';
import { Container, ContainerProps } from './Container';
import styles from '../PageLayout.module.scss';

const c = classNames.bind(styles);

export type HeaderProps = React.PropsWithChildren<{
  className?: string;
  size?: ContainerProps['size'];
}>;

export function Header({ className, size, children }: HeaderProps) {
  const { size: layoutSize } = usePageLayout();

  return (
    <div className={c(className, 'header')}>
      <Container
        className={c(className, 'header-container')}
        size={size ?? layoutSize}
      >
        {children}
      </Container>
    </div>
  );
}
