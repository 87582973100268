const REGEX_TRAILING_SEMICOLON = /;+$/;
const EMPTY_SHAPES = Object.freeze({});

export function getROIShapesFromInput(
  labels: string[] | null | undefined,
  coordinates: number[][] | null | undefined
) {
  if (!labels || !coordinates || labels.length !== coordinates.length) {
    return EMPTY_SHAPES;
  }

  return labels.reduce<Record<string, number[]>>(
    (namedShapes, label, index) => {
      namedShapes[label] = coordinates[index];
      return namedShapes;
    },
    {}
  );
}

export function getLabelsFromString(labelString: string): string[] {
  labelString = labelString.replace(REGEX_TRAILING_SEMICOLON, '');
  return labelString.split(';');
}

export function getCoordinatesFromString(coordinateString: string): number[][] {
  coordinateString = coordinateString.replace(REGEX_TRAILING_SEMICOLON, '');
  return coordinateString.split(';').map((str) => {
    if (str === '') {
      return [];
    }

    // Flat coordinates array
    return str
      .split(',')
      .map((coord) => (coord.trim() === '' ? undefined : Number(coord)))
      .filter((coord) => coord !== undefined) as number[];
  });
}

export function getShapesFromString(
  labelString?: string | null,
  coordinateString?: string | null
): Record<string, number[]> {
  if (
    (labelString === '' && coordinateString === '') ||
    !labelString ||
    !coordinateString
  ) {
    return EMPTY_SHAPES;
  }

  const labels = getLabelsFromString(labelString);
  const coordinates = getCoordinatesFromString(coordinateString);

  return getROIShapesFromInput(labels, coordinates);
}
