import React from 'react';

/**
 * Extension of useState that acts like a physical spring.
 * When the value is set, it springs back to its initial value after `revertDelay` ms.
 * Setting the value during the delay resets the delay.
 */
export function useSpringState<T>(initialValue: T, revertDelay: number) {
  const [value, setValue] = React.useState(initialValue);
  const timer = React.useRef<number>();
  const mounted = React.useRef(false);

  const pullSpring: (newValue: T) => void = React.useCallback(
    (newValue: T) => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }

      // Set the "spring" to the new value
      setValue(newValue);

      // Set a timer to release the spring
      timer.current = window.setTimeout(() => {
        if (mounted.current) {
          setValue(initialValue);
        }
      }, revertDelay);
    },
    [timer, initialValue, revertDelay]
  );

  React.useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return [value, pullSpring] as const;
}
