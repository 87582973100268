import React from 'react';
import classNames from 'classnames/bind';
import { Link, useParams } from 'react-router-dom';

import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

import { ApplicationParams } from 'application/types/application_params';

import styles from './PricingPlanLabel.module.scss';

const c = classNames.bind(styles);

export type PricingPlanLabelLinkProps = React.PropsWithChildren<{
  className?: string;
  hasLink?: boolean;
}>;

export function PricingPlanLabelLink({
  className,
  hasLink,
  children,
}: PricingPlanLabelLinkProps) {
  const { data: organization } = useCurrentOrganization();
  const { applicationID } = useParams<ApplicationParams>();

  if (hasLink) {
    return (
      <Link
        className={c(className, 'wrap')}
        to={`/applications/${applicationID}/settings/organization/${organization?.id}/plans`}
      >
        {children}
      </Link>
    );
  }

  return <div className={className}>{children}</div>;
}
