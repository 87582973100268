import React from 'react';
import classnames from 'classnames/bind';
import { useParams } from 'react-router-dom';

import { DeploymentParams } from 'deployments/views/DeploymentDetail/DeploymentDetail';

import { useMqttStreamsState } from 'streams/hooks/useMqttStreamsState';
import { useStreams } from 'streams/hooks/useStreams';

import { EmptyView } from 'components/EmptyView/EmptyView';
import { ReactComponent as EmptyIllustration } from 'streams/images/empty-streams-illustration.svg';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';

import { StreamsListItem, StreamsListItemSkeleton } from './Item';
import styles from './List.module.scss';

const c = classnames.bind(styles);

export function StreamsList() {
  const { deploymentID } = useParams<DeploymentParams>();

  const { data, isLoading, isSuccess } = useStreams(
    ['streams', 'deployment-detail'],
    { deployment_ids: deploymentID ? [deploymentID] : undefined },
    { enabled: Boolean(deploymentID), keepPreviousData: false }
  );

  useMqttStreamsState(data?.data ? data.data.map(({ id }) => id) : []);

  if (isSuccess && data && data.total_elements === 0) {
    return (
      <EmptyView image={EmptyIllustration}>No streams generated yet.</EmptyView>
    );
  }

  return (
    <ul className={c('list')}>
      {data &&
        data.data.map((stream) => (
          <li key={stream.id}>
            <StreamsListItem stream={stream} key={stream.id} />
          </li>
        ))}

      {isLoading && <SkeletonList component={<StreamsListItemSkeleton />} />}
    </ul>
  );
}
