import React from 'react';
import classNames from 'classnames/bind';

import { useForwardedRef } from 'hooks/useForwardedRef';

import styles from './AutosizeInput.module.scss';

const c = classNames.bind(styles);

export type AutosizeInputProps = React.InputHTMLAttributes<HTMLInputElement>;

function AutosizeInput(
  { value, defaultValue, className, placeholder, ...props }: AutosizeInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const sizeRef = React.useRef<HTMLSpanElement>(null);
  const inputRef = useForwardedRef(ref);

  React.useLayoutEffect(() => {
    if (!sizeRef.current || !inputRef.current) {
      return;
    }

    const { width } = sizeRef.current.getBoundingClientRect();
    inputRef.current.style.width = `${Math.ceil(width)}px`;
    // inputRef is a regular RefObject and not needed in dependency array
    // eslint-disable-next-line
  }, [placeholder, defaultValue, value]);

  return (
    <div className={c('wrap')}>
      <span className={c(className, 'mirror')} aria-hidden="true" ref={sizeRef}>
        {value ?? defaultValue ?? placeholder}
      </span>
      <input
        {...props}
        className={c(className, 'target')}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        ref={inputRef}
      />
    </div>
  );
}

const Forward = React.forwardRef<HTMLInputElement, AutosizeInputProps>(
  AutosizeInput
);

export { Forward as AutosizeInput };
