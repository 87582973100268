import { AppEntityObject } from 'hooks/useEntities';

import {
  AdvancedFilterState,
  AdvancedSearchRelatedEntityFilterList,
} from './../AdvancedSearch.types';

type NameOrID = string | null | undefined;

export function findEntitiesWithMatchingProperties<T extends AppEntityObject>(
  baseEntity: T,
  searchTerm: any,
  entityList?: T[]
) {
  if (!entityList || entityList.length <= 0) {
    return [];
  }

  const searchableProperties = Object.keys(baseEntity) as (keyof T)[];

  return entityList.reduce<T[]>((acc, current) => {
    if (
      searchableProperties.some((property) =>
        stringIncludes(current[property as keyof T], searchTerm)
      )
    ) {
      acc.push(current);
    }

    return acc;
  }, []);
}

export function findEntitiesWithNameOrID<T extends AppEntityObject>(
  partialNameOrID: NameOrID,
  entityList?: T[]
): T[] {
  if (!entityList || entityList.length <= 0) {
    return [];
  }

  return entityList.reduce<T[]>((acc, current) => {
    if (
      stringIncludes(current.id, partialNameOrID) ||
      stringIncludes(current.name, partialNameOrID)
    ) {
      acc.push(current);
    }

    return acc;
  }, []);
}

export type FilterListOptions = {
  searchTerm?: string;
  filters?: AdvancedFilterState;
};

export function filterList<T extends AppEntityObject>(
  baseEntity: T,
  list: T[],
  relatedEntityFilters: AdvancedSearchRelatedEntityFilterList<T>,
  { searchTerm, filters }: FilterListOptions = {}
) {
  return list.filter((item) => {
    let isMatch = true;

    for (const {
      property,
      filter: { value },
    } of relatedEntityFilters) {
      isMatch = isMatch && String(item[property as keyof T]) === value;
    }

    const searchableProperties = Object.keys(baseEntity) as (keyof T)[];

    if (searchTerm?.trim()) {
      isMatch =
        isMatch &&
        searchableProperties.some((property) =>
          stringIncludes(item[property as keyof T], searchTerm)
        );
    }

    if (filters && Object.keys(filters).length > 0) {
      isMatch =
        isMatch &&
        Object.keys(filters).some((property) => {
          const value = item[property as keyof T];
          return filters[property][String(value)];
        });
    }

    return isMatch;
  });
}

function stringIncludes(a: any, b: any): boolean {
  if (!a || !b) {
    return false;
  }

  return String(a).toUpperCase().includes(String(b).toUpperCase());
}
