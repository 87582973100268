import { FilterInput, MetricReportDimensionInput } from '@propeldata/ui-kit';
import { gql } from 'graphql-request';

import { CursorVariables } from '../types/pagination';

export type MetricReportQueryVariables = CursorVariables & {
  metrics: Array<{ metric: { name: string } }>;
  dimensions: MetricReportDimensionInput[];
  orderByColumn?: number;
  filters?: FilterInput[];
};

export type MetricReportResponse = {
  metricReport: {
    headers: string[];
    rows: string[][];
  };
};

export const METRIC_REPORT = gql`
  query MetricReport($input: MetricReportInput!) {
    metricReport(input: $input) {
      headers
      rows
    }
  }
`;
