import React from 'react';
import classNames from 'classnames/bind';
import * as RadixDialog from '@radix-ui/react-dialog';
import { DateTime } from 'luxon';
import { Skeleton } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { LumeoFile } from 'types/file';
import { Tag } from 'types/tag';

import downloadFile from 'services/download_file';
import { useAddEntityTags } from 'tags/hooks/useAddEntityTag';
import { useCamera } from 'cameras/hooks/useCamera';
import { useDeleteEntityTags } from 'tags/hooks/useDeleteEntityTags';
import { useDeleteFiles } from 'files/hooks/useDeleteFiles';
import { useDeployment } from 'deployments/hooks/useDeployment';
import { useFile } from 'files/hooks/useFile';
import { useModal } from 'hooks/useModal';
import { usePipeline } from 'hooks/api/usePipeline';
import { useSpringState } from 'hooks/useSpringState';
import { useStream } from 'streams/hooks/useStream';
import { useUpdateFile } from 'files/hooks/useUpdateFile';

import { EntityListItem } from 'components/EntityList';
import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Separator } from 'components/Separator/Separator';
import { TagSelect } from 'tags/components/TagSelect/TagSelect';
import { Text } from 'components/Text/Text';
import { Textarea } from 'components/Textarea/Textarea';

import styles from './Lightbox.module.scss';

const c = classNames.bind(styles);

type FileFieldValues = { description: string };

export function Controls({ id }: { id: LumeoFile['id'] }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const { open, close } = useModal();

  const { data: file, isLoading } = useFile(id);

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<FileFieldValues>();

  const [isSuccess, setIsSuccess] = useSpringState(false, 2000);

  const { data: stream, isInitialLoading: isLoadingStream } = useStream(
    file?.stream_id
  );
  const { data: camera, isInitialLoading: isLoadingCamera } = useCamera(
    stream?.camera_id
  );
  const { data: pipeline, isInitialLoading: isLoadingPipeline } = usePipeline(
    file?.pipeline_id
  );
  const { data: deployment, isInitialLoading: isLoadingDeployment } =
    useDeployment(file?.deployment_id);

  const { mutate: mutateFile, isLoading: isUpdating } = useUpdateFile({
    onSuccess(file) {
      setIsSuccess(true);
      reset({ description: file.description });
    },
  });

  const { mutateAsync: deleteFiles, isLoading: isDeleting } = useDeleteFiles({
    onSuccess() {
      close('file');
      queryClient.invalidateQueries(['files-search']);
    },
  });

  const { mutate: addTags } = useAddEntityTags({
    entity: 'files',
    listQueryKey: ['files'],
    singleQueryKey: ['file'],
  });

  const { mutate: removeTags } = useDeleteEntityTags({
    entity: 'files',
    listQueryKey: ['files'],
    singleQueryKey: ['file'],
  });

  function onSubmit({ description }: FileFieldValues) {
    if (!file) {
      return;
    }

    mutateFile({
      id: file.id,
      description,
    });
  }

  function handleDeleteFile() {
    open('confirm-delete', {
      title: 'Are you sure you want to delete this file?',
      onConfirm: () => deleteFiles({ file_ids: [id] }),
    });
  }

  function handleAddTags(tag: Tag) {
    if (!file) {
      return;
    }

    addTags({ id: file.id, tagIds: [tag.id] });
  }

  function handleRemoveTags(tagId: Tag['id']) {
    if (!file) {
      return;
    }

    removeTags({ id: file.id, tagIds: [tagId] });
  }

  function handleDownload() {
    if (!file || !file.data_url) {
      return;
    }

    downloadFile(file.name, file.data_url);
  }

  function displayDate(file: LumeoFile) {
    const dashboardTimezone = searchParams.get('timezone');

    if (!dashboardTimezone) {
      return DateTime.fromISO(file.created_at).toLocaleString(
        DateTime.DATETIME_MED
      );
    }

    const createdAtWithZone = DateTime.fromISO(file.created_at).setZone(
      dashboardTimezone
    );

    return `${createdAtWithZone.toLocaleString(DateTime.DATETIME_MED)}, ${createdAtWithZone.toFormat('ZZZZ')}`;
  }

  return (
    <div className={c('controls')}>
      <RadixDialog.Close asChild>
        <IconButton
          className={c('close')}
          icon="cancel"
          label="Close"
          onClick={() => close()}
          variant="ghost"
        />
      </RadixDialog.Close>

      <div className={c('info')}>
        {file ? (
          <div>
            <Text size="small" inline>
              {displayDate(file)}
            </Text>
            <Heading className={c('heading')} level="2">
              {file.name}
            </Heading>
          </div>
        ) : (
          <div>
            <Skeleton width="50%" height="var(--size-md)" mb="xs" />
            <Skeleton width="80%" height="var(--size-lg)" />
          </div>
        )}

        <div>
          <EntityListItem
            icon="stream"
            type="streams"
            label="Stream"
            loading={isLoadingStream}
            entity={stream}
            onLinkClick={() => close()}
          />
          <EntityListItem
            icon="camera"
            type="cameras"
            label="Camera"
            loading={isLoadingCamera}
            entity={camera}
            onLinkClick={() => close()}
          />
          <EntityListItem
            icon="pipeline"
            type="pipelines"
            label="Pipeline"
            loading={isLoadingPipeline}
            entity={pipeline}
            onLinkClick={() => close()}
          />
          <EntityListItem
            icon="deployment"
            type="deployments"
            label="Deployment"
            loading={isLoadingDeployment}
            entity={deployment}
            onLinkClick={() => close()}
          />
        </div>

        {file && (
          <TagSelect
            defaultSelection={file.tags}
            className={c('tags')}
            trigger={
              <Button size="xsmall" variant="secondary">
                Edit labels
              </Button>
            }
            onAdd={handleAddTags}
            onRemove={handleRemoveTags}
          />
        )}
      </div>

      <ButtonGroup>
        <IconButton
          icon="download"
          variant="secondary"
          label="Download"
          disabled={isLoading}
          onClick={handleDownload}
          size="small"
          showLabel
        />
        <IconButton
          icon="delete"
          variant="secondary"
          label="Delete"
          disabled={isLoading}
          loading={isDeleting}
          onClick={handleDeleteFile}
          size="small"
          showLabel
        />
      </ButtonGroup>

      <Separator />

      <form onSubmit={handleSubmit(onSubmit)}>
        {file ? (
          <Textarea
            {...register('description')}
            defaultValue={file.description}
          />
        ) : (
          <Skeleton width="100%" height="6.25rem" />
        )}

        <ButtonGroup>
          <Button
            variant="primary"
            type="submit"
            loading={isUpdating}
            disabled={!isDirty || !file}
          >
            Save description
          </Button>

          {isDirty && <InlineNotification>Unsaved changes</InlineNotification>}

          {isSuccess && (
            <InlineNotification intent="success">Saved!</InlineNotification>
          )}
        </ButtonGroup>
      </form>
    </div>
  );
}
