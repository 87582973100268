import {
  getCoordinatesFromString,
  getLabelsFromString,
} from 'pipelines/components/ROICanvas/ROICanvas';

export enum RoiValidationMessage {
  INCOMPLETE_COORDINATE_PAIR = 'Coordinates must be x,y pairs.',
  INCOMPLETE_SHAPE = 'Shapes must have at least 2 coordinate pairs.',
  INVALID_COORDINATES = 'Coordinates must be valid numbers.',
  OUT_OF_BOUNDS = 'Coordinates must lie between 0,0 and 1,1.',
  LENGTH_MISMATCH = 'The number of labels does not match the number of shapes.',
  DUPLICATE_LABELS = 'Labels must be unique.',
}

export function validateStrings(
  labelString: string,
  coordinateString: string
): string | undefined {
  if (!labelString.trim() && !coordinateString.trim()) {
    return;
  }

  const coordinates = getCoordinatesFromString(coordinateString);
  const flatCoords = coordinates.flat(2);

  const hasIncompleteCoords = flatCoords.some((x) => x === undefined);
  const hasUnevenCoords = coordinates.some((shape) => shape.length % 2 !== 0);

  if (hasIncompleteCoords || hasUnevenCoords) {
    return RoiValidationMessage.INCOMPLETE_COORDINATE_PAIR;
  }

  const hasIncompleteShapes = coordinates.some((shape) => shape.length < 4);

  if (hasIncompleteShapes) {
    return RoiValidationMessage.INCOMPLETE_SHAPE;
  }

  const hasInvalidCoords = flatCoords.some(
    (x) => Number.isNaN(Number(x)) || !Number.isFinite(Number(x))
  );

  if (hasInvalidCoords) {
    return RoiValidationMessage.INVALID_COORDINATES;
  }

  const hasCoordsOutOfBounds = flatCoords.some((x) => x < 0 || x > 1);

  if (hasCoordsOutOfBounds) {
    return RoiValidationMessage.OUT_OF_BOUNDS;
  }

  const labels = getLabelsFromString(labelString);
  const isTransformedLengthMismatch = labels.length !== coordinates.length;
  const isStringMismatch = !coordinateString.trim() || !labelString.trim();

  if (isStringMismatch || isTransformedLengthMismatch) {
    return RoiValidationMessage.LENGTH_MISMATCH;
  }

  const hasDuplicateLabels = new Set(labels).size !== labels.length;

  if (hasDuplicateLabels) {
    return RoiValidationMessage.DUPLICATE_LABELS;
  }
}
