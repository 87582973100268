import React from 'react';
import classnames from 'classnames/bind';
import {
  Card,
  Image,
  Title,
  Text,
  Button,
  Group,
  Badge,
  Skeleton,
} from '@mantine/core';
import { Link } from 'react-router-dom';

import AIModel from 'types/ai_model';

import { useAPI } from 'hooks/api/useAPI';
import { useIsLumeoAdmin } from 'hooks/useAuth';

import { Icon } from 'components/Icon/Icon';

import styles from './ModelCard.module.scss';

const c = classnames.bind(styles);

type ModelCardProps = {
  model: AIModel;
};

export function ModelCard({ model }: ModelCardProps) {
  const { applicationID } = useAPI();

  const isAdmin = useIsLumeoAdmin();
  const canEdit = !model.isMarketplaceModel() || isAdmin;

  const [isLoading, setIsLoading] = React.useState(true);

  const detailURL = model.isMarketplaceModel()
    ? `/applications/${applicationID}/admin/marketplace-models/${model.id}`
    : `/applications/${applicationID}/design/models/${model.id}`;

  return (
    <Card className={c('model-card')} withBorder>
      {model.gallery_img_url && (
        <Card.Section>
          <Skeleton radius="0" visible={isLoading}>
            <Image
              className={c('image')}
              src={model.gallery_img_url}
              onLoad={() => setIsLoading(false)}
              alt=""
              fit="cover"
            />
          </Skeleton>
        </Card.Section>
      )}

      <Group gap="xs" mx="-2px" mb="sm" mt={model.gallery_img_url && 'md'}>
        <Badge size="md">{model.architecture}</Badge>
        <Badge size="md">{model.capability}</Badge>
      </Group>

      <Title order={3}>{model.name}</Title>

      <Text
        mt="xs"
        mb="md"
        lineClamp={model.isMarketplaceModel() ? undefined : 3}
      >
        {model.description}
      </Text>

      {canEdit && (
        <Button
          className={c('button')}
          mt="auto"
          leftSection={<Icon name="edit" size="small" />}
          component={Link}
          size="sm"
          to={detailURL}
        >
          Edit
        </Button>
      )}
    </Card>
  );
}
