import React from 'react';
import classnames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Pipeline from 'types/pipeline';

import { ApplicationParams } from 'application/views/App/AppContainer';
import { NotFound } from 'views/NotFound/NotFound';

import { useAppRedirect } from 'hooks/useAppRedirect';
import { useDeletePipeline, usePipeline } from 'hooks/api/usePipeline';
import { useUpdatePipeline } from 'pipelines/hooks/useUpdatePipeline';

import * as PageLayout from 'components/PageLayout';
import { Loader } from 'components/Loader/Loader';

import { ReactComponent as EmptyIllustration } from 'pipelines/images/empty-pipelines.svg';
import { PipelineDetailProvider } from 'pipelines/context/pipeline_detail_view';
import { PipelineEditor } from 'pipelines/components/Editor/Editor';
import { PipelineDetailError } from './Error/Error';

import styles from './PipelineDetail.module.scss';

const c = classnames.bind(styles);

export type PipelineParams = ApplicationParams & {
  pipelineID: string;
};

export function PipelineDetail() {
  const redirect = useAppRedirect();

  const { pipelineID } = useParams<PipelineParams>();
  const { data: initialPipeline, isLoading } = usePipeline(pipelineID);

  const [key, setKey] = React.useState(0);

  const [requestDelete, { isLoading: isDeleting }] = useDeletePipeline(
    initialPipeline,
    {
      onSuccess() {
        redirect('/design/pipelines');
      },
    }
  );

  const { mutate: restorePipeline, isLoading: isRestoring } = useUpdatePipeline(
    {
      onSuccess() {
        setKey((previousKey) => previousKey + 1);
      },
    }
  );

  function handleRestorePipeline() {
    if (!initialPipeline) {
      return;
    }

    const updatedPipeline = Pipeline.withInvalidNodesRemoved(initialPipeline);
    restorePipeline({
      id: updatedPipeline.id,
      definition: updatedPipeline.definition,
    });
  }

  if (!initialPipeline && isLoading) {
    return <Loader text="Loading pipeline..." />;
  }

  if (!initialPipeline) {
    return (
      <NotFound
        entity="pipeline"
        returnTo="/design/pipelines"
        image={EmptyIllustration}
      />
    );
  }

  return (
    <PipelineDetailProvider pipeline={initialPipeline} key={key}>
      <PageLayout.Root size="full">
        <PageLayout.Content>
          <div className={c('pipeline')}>
            <div className={c('rete')}>
              <ErrorBoundary
                fallbackRender={({ resetErrorBoundary }) => (
                  <PipelineDetailError
                    isRestoring={isRestoring}
                    isDeleting={isDeleting}
                    resetErrorBoundary={resetErrorBoundary}
                    onRestore={handleRestorePipeline}
                    onDelete={requestDelete}
                  />
                )}
              >
                <PipelineEditor />
              </ErrorBoundary>
            </div>
          </div>
        </PageLayout.Content>
      </PageLayout.Root>
    </PipelineDetailProvider>
  );
}
