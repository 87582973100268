import React from 'react';

import {
  Condition,
  conditionToString,
  parseCondition,
} from 'pipelines/services/condition_parser';
import { getFirstConditionError } from 'components/ConditionInput/services/get_first_condition_error';
import { getSuggestionsFromNodeMetadata } from 'services/get_node_metadata_suggestions';
import { useActivePipelineOptional } from 'pipelines/context/active_pipeline';
import { useCurrentNode } from 'deployments/hooks/useCurrentNode';

import { ConditionInput, ConditionInputProps } from 'components/ConditionInput';
import { Text } from 'components/Text/Text';

export type PipelineConditionInputProps = Omit<
  ConditionInputProps,
  'suggestions' | 'value' | 'defaultValue' | 'onChange'
> & {
  suggestionTypeFilters?: string[];
  value?: string;
  defaultValue?: string;
  onChange?: (value: string | undefined, errorMessage?: string) => void;
};

export function PipelineConditionInput({
  name,
  suggestionTypeFilters,
  value,
  defaultValue,
  onChange,
}: PipelineConditionInputProps) {
  const didInitialize = React.useRef(false);

  const [error, setError] = React.useState<string>();
  const [errorInput, setErrorInput] = React.useState<string>();

  const [condition, setCondition] = React.useState<Condition>();

  const { nodeID } = useCurrentNode();
  const pipeline = useActivePipelineOptional();

  const suggestions = React.useMemo(() => {
    if (!pipeline) {
      return undefined;
    }

    const nodesById = pipeline.nodeGraph;

    const currentNode = nodesById.get(nodeID);
    const upstreamNodes = currentNode!.allUpstreamNodes();

    return getSuggestionsFromNodeMetadata(upstreamNodes, suggestionTypeFilters);
  }, [nodeID, pipeline, suggestionTypeFilters]);

  React.useEffect(() => {
    const initializerValue = value ?? defaultValue;

    if (!initializerValue) {
      didInitialize.current = true;
      return;
    }

    try {
      const parsedCondition = parseCondition(initializerValue);

      const error = getFirstConditionError(parsedCondition);

      if (error) {
        setError(error);
        throw new Error(error);
      }

      setCondition(parsedCondition);
    } catch (error) {
      // This component only cares about errors from the initial value,
      // not those from subsequent updates.
      if (didInitialize.current) {
        return;
      }

      onChange?.(undefined);
      setErrorInput(initializerValue);
    }

    didInitialize.current = true;
  }, [value, defaultValue, onChange]);

  function handleChange(
    condition: Condition | undefined,
    errorMessage?: string
  ) {
    setCondition(condition);
    onChange?.(
      condition ? conditionToString(condition) : undefined,
      errorMessage
    );
  }

  if (!pipeline) {
    return null;
  }

  return (
    <div>
      {errorInput !== undefined && (
        <Text intent="danger" type="paragraph">
          {error ?? 'Invalid condition format. '}
          The original input was "{errorInput}".
        </Text>
      )}
      <ConditionInput
        name={name}
        value={condition}
        onChange={handleChange}
        suggestions={suggestions}
      />
    </div>
  );
}
