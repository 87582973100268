import React from 'react';
import classNames from 'classnames/bind';
import * as RadixDialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { LumeoFile } from 'types/file';

import { useFile } from 'files/hooks/useFile';
import { useModal } from 'hooks/useModal';

import { Controls } from './Controls';
import { Preview } from './Preview';
import styles from './Lightbox.module.scss';

const c = classNames.bind(styles);

type FileLightboxProps = Pick<RadixDialog.DialogProps, 'open'> &
  Pick<LumeoFile, 'id'>;

/** Lightbox for {@link LumeoFile} */
export function FileLightbox({ id, ...props }: FileLightboxProps) {
  return (
    <RadixDialog.Root {...props}>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className={c('overlay')} />
        <FileLightboxContent id={id} />
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}

function FileLightboxContent({ id }: FileLightboxProps) {
  const { data: file } = useFile(id);

  const { close } = useModal();

  return (
    <RadixDialog.Content
      className={c('content')}
      onEscapeKeyDown={() => close()}
    >
      <VisuallyHidden>
        <RadixDialog.Title>
          {file ? `View file ${file.name}` : ''}
        </RadixDialog.Title>
        <RadixDialog.Description>
          The contents of the file, with controls to delete or edit the file
          description.
        </RadixDialog.Description>
      </VisuallyHidden>
      <Preview id={id} />
      <Controls id={id} />
    </RadixDialog.Content>
  );
}
