import { UseQueryOptions } from '@tanstack/react-query';

import { useAPI } from 'hooks/api/useAPI';

import APIError from 'types/api_error';
import Stream from 'types/stream';

import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function useStream(
  streamID?: Stream['id'] | null,
  { enabled, ...options }: UseQueryOptions<Stream, APIError> = {}
) {
  const api = useAPI();

  return useAuthenticatedQuery<Stream>(
    ['stream', streamID],
    () => {
      if (!streamID) {
        return Promise.reject('No stream id provided.');
      }

      return api.streams.read(streamID);
    },
    {
      enabled:
        enabled !== undefined
          ? Boolean(streamID) && enabled
          : Boolean(streamID),
      ...options,
    }
  );
}
