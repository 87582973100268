import React from 'react';
import { MetricFiltersInputContextProps } from './MetricFiltersInput';

export const MetricFiltersInputContext =
  React.createContext<MetricFiltersInputContextProps>(undefined);

export function useMetricFiltersState() {
  const context = React.useContext(MetricFiltersInputContext);

  if (!context) {
    throw new Error(
      'Hook `useMetricFiltersState` may only be used within <MetricFiltersProvider />.'
    );
  }

  return context;
}
