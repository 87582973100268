export const ICON_NAMES = [
  // Common
  'plus',
  'minus',
  'delete',
  'settings',
  'edit',
  'text',
  'drag-indicator',
  'logout',
  'folder',
  'clipboard',
  'download',
  'export',
  'wifi',
  'file',
  'image',
  'play',
  'puzzle',
  'eye',
  'eye-off',
  'external',
  'link',
  'lock',
  'lock-open',
  'unlock',
  'search',
  'filter',
  'upload',
  'message',
  'help',
  'copy',
  'take-snapshot',
  'global',
  'connection',
  'tag',
  'calendar',
  'key',
  'timer',

  // Arrows and controls
  'enter',
  'arrow-right',
  'arrow-left',
  'chevron-right',
  'chevron-left',
  'chevron-down',
  'unfold-vertical',
  'more-vertical',
  'expand',
  'collapse',

  // Status / Status Change
  'stop',
  'cancel',
  'check',
  'check-circle',
  'danger',
  'error',
  'error-outline',
  'warning',
  'running',
  'info',

  // Entities
  'source',
  'dashboard',
  'event',
  'camera',
  'virtual-camera',
  'deployment',
  'pipeline',
  'stream',
  'gateway',
  'model',
  'face-recognition',

  // Navigation
  'billing',
  'marketplace',
  'metrics',
  'account',
  'account-key',
  'documentation',
  'organization',
  'stream-out',
  'stream-webrtc',
  'plan',

  // Nodes & Pipeline editor
  'cloud',
  'code',
  'convert',
  'decode',
  'encode',
  'grid',
  'flip-horizontal',
  'flip-vertical',
  'kafka',
  'merge',
  'split',
  'meta',
  'motion',
  'overlay',
  'rotate',
  'storage',
  'track-objects',
  'transform',
  'video',
  'webhook',
  'wallpaper',
  'barcode',
  'alarm',

  // Events
  'airplane',
  'bag',
  'baseball-bat',
  'bed',
  'bike',
  'bike',
  'bus',
  'car',
  'clock',
  'cutlery',
  'ferry',
  'food',
  'laptop',
  'motorbike',
  'person',
  'phone',
  'soccer',
  'tennis',
  'traffic-light',
  'train',
  'train',
  'truck',
  'truck',

  // ROI
  'vector-square',
  'vector-line',

  // Logo variants
  'logo',
  'logo-access-denied',
] as const;

export type IconType = (typeof ICON_NAMES)[number];
