import React from 'react';
import {
  unstable_usePrompt,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import {
  AdminRoute,
  DeployRoute,
  DesignRoute,
  MonitorRoute,
  NavRoute,
} from 'application/types/routes';
import Deployment from 'types/deployment';

import { useHasAccess } from 'hooks/useHasAccess';
import { useIsLumeoAdmin } from 'hooks/useAuth';

import { AddCameraDialog } from 'cameras/components/AddCameraDialog';
import { AddGatewayModal } from 'gateways/components/AddGateway';
import { BulkOverrideDeploymentsForm } from 'deployments/components/Form/BulkOverrideForm/BulkOverrideForm';
import { CreateDashboardDialog } from 'dashboards/components/CreateDashboardDialog/CreateDashboardDialog';
import { CreateInputStreamsDialog } from 'streams/components/CreateInputStreamsDialog';
import { CreateNewPipeline } from 'pipelines/components/CreateNewPipeline/CreateNewPipeline';
import { CreatePipelinesFromJsonModal } from 'components/CreatePipelinesFromJsonModal/CreatePipelinesFromJsonModal';
import { CreateSharedStreamDrawer } from 'streams/components/SharedStreams/CreateSharedStreamDrawer';
import { Drawer } from 'components/Drawer/Drawer';
import {
  DropdownButton,
  DropdownButtonOption,
} from 'components/DropdownButton/DropdownButton';
import {
  deploymentFormDrawerClassName,
  NewDeploymentForm,
  UpdateDeploymentsForm,
} from 'deployments/components/Form';
interface PrimaryAction {
  label: string;
  onClick?: () => void;
}

const defaultAction = {
  label: 'Create',
};

export function CreateButton() {
  const navigate = useNavigate();
  const [hasAccess] = useHasAccess();
  const isLumeoAdmin = useIsLumeoAdmin();
  const { applicationID } = useParams();
  const { pathname } = useLocation();
  const [, , , , route, entityId] = pathname.split('/');

  const [primaryAction, setPrimaryAction] =
    React.useState<PrimaryAction>(defaultAction);
  const { label, onClick } = primaryAction;

  const [isCreatingPipeline, toggleIsCreatingPipeline] = useToggle();
  const [isImportingJSONFile, toggleIsImportingJSONFile] = useToggle();
  const [isAddingGateway, toggleIsAddingGateway] = useToggle();
  const [isDeploying, toggleIsDeploying] = useToggle();
  const [isCreatingDashboard, toggleIsCreatingDashboard] = useToggle();
  const [isCreatingInputStream, toggleIsCreatingInputStream] = useToggle();
  const [isCreatingSharedStream, toggleIsCreatingSharedStream] = useToggle();
  const [isAddingCameras, toggleIsAddingCameras] = useToggle();

  const isUpdatingDeploymentsRef = React.useRef(false);
  const [isUpdatingDeployments, toggleIsUpdatingDeployments] = useToggle();
  const [isBulkOverridingDeployments, toggleIsBulkOverridingDeployments] =
    useToggle();

  unstable_usePrompt({
    message:
      'This page contains unsaved changes. Are you sure you want to leave?',
    when({ currentLocation, nextLocation }) {
      return (
        isUpdatingDeploymentsRef.current &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
  });

  React.useEffect(() => {
    switch (route) {
      case DesignRoute.PIPELINES: {
        if (entityId && hasAccess('deploy_edit')) {
          setPrimaryAction({
            label: 'Deploy',
          });
          break;
        }

        setPrimaryAction({
          label: 'Create pipeline',
          onClick: toggleIsCreatingPipeline,
        });
        break;
      }
      case DesignRoute.MODELS:
        setPrimaryAction({
          label: 'Add model',
          onClick: () =>
            navigate(`/applications/${applicationID}/design/models/new`),
        });
        break;
      case DeployRoute.GATEWAYS:
        if (!hasAccess('deploy_edit')) {
          break;
        }

        setPrimaryAction({
          label: 'Add gateway',
          onClick: toggleIsAddingGateway,
        });
        break;
      case DeployRoute.CAMERAS:
        if (!hasAccess('deploy_edit')) {
          break;
        }

        setPrimaryAction({
          label: 'Add camera',
          onClick: toggleIsAddingCameras,
        });
        break;
      case DeployRoute.STREAMS:
        if (!hasAccess('deploy_edit')) {
          break;
        }

        setPrimaryAction({
          label: 'Add input stream',
          onClick: toggleIsCreatingInputStream,
        });
        break;
      case DeployRoute.FILES:
        if (!hasAccess('deploy_edit')) {
          break;
        }

        setPrimaryAction({
          label: 'Add files',
          onClick: () =>
            navigate(`/applications/${applicationID}/deploy/files/new`),
        });
        break;
      case DeployRoute.DEPLOYMENTS:
        if (!hasAccess('deploy_edit')) {
          break;
        }

        setPrimaryAction({
          label: 'Deploy pipeline',
          onClick: toggleIsDeploying,
        });
        break;
      case MonitorRoute.DASHBOARDS:
        setPrimaryAction({
          label: 'Create dashboard',
          onClick: toggleIsCreatingDashboard,
        });
        break;
      case AdminRoute.MARKETPLACE_MODELS:
        isLumeoAdmin
          ? setPrimaryAction({
              label: '(Admin) Add marketplace model',
              onClick: () =>
                navigate(
                  `/applications/${applicationID}/${NavRoute.ADMIN}/${AdminRoute.MARKETPLACE_MODELS}/new`
                ),
            })
          : setPrimaryAction(defaultAction);
        break;
      default:
        setPrimaryAction(defaultAction);
    }
  }, [
    route,
    applicationID,
    entityId,
    isLumeoAdmin,
    hasAccess,
    navigate,
    toggleIsAddingGateway,
    toggleIsCreatingPipeline,
    toggleIsDeploying,
    toggleIsCreatingDashboard,
    toggleIsCreatingInputStream,
    toggleIsAddingCameras,
  ]);

  const handleOnDeploymentSuccess = React.useCallback(
    (deployment: Deployment) => {
      toggleIsDeploying();
      navigate(
        `/applications/${applicationID}/${NavRoute.DEPLOY}/${DeployRoute.DEPLOYMENTS}/${deployment.id}`
      );
    },
    [applicationID, toggleIsDeploying, navigate]
  );

  React.useEffect(() => {
    if (!isUpdatingDeployments) {
      return;
    }

    return () => {
      toggleIsUpdatingDeployments(false);
      isUpdatingDeploymentsRef.current = false;
    };
  }, [pathname, isUpdatingDeployments, toggleIsUpdatingDeployments]);

  React.useEffect(() => {
    if (!isBulkOverridingDeployments) {
      return;
    }

    return () => {
      toggleIsBulkOverridingDeployments(false);
      isUpdatingDeploymentsRef.current = false;
    };
  }, [
    pathname,
    isBulkOverridingDeployments,
    toggleIsBulkOverridingDeployments,
  ]);

  return (
    <>
      <DropdownButton
        id="up-global-create"
        label={label}
        size="small"
        onClick={onClick}
        alwaysTriggerMenu={label === defaultAction.label || label === 'Deploy'}
      >
        {hasAccess('deploy_edit') && (
          <>
            <DropdownButtonOption
              icon="deployment"
              onClick={() =>
                navigate(
                  `/applications/${applicationID}/${NavRoute.DEPLOY}/new`
                )
              }
            >
              Quick deploy
            </DropdownButtonOption>
            <DropdownButtonOption icon="deployment" onClick={toggleIsDeploying}>
              Deploy pipeline
            </DropdownButtonOption>
          </>
        )}

        {route === DesignRoute.PIPELINES &&
          entityId &&
          hasAccess('design') &&
          hasAccess('deploy') && (
            <>
              <DropdownButtonOption
                icon="deployment"
                onClick={() => {
                  toggleIsUpdatingDeployments(true);
                  isUpdatingDeploymentsRef.current = true;
                }}
              >
                Update deployments
              </DropdownButtonOption>
              <DropdownButtonOption
                icon="deployment"
                onClick={() => {
                  toggleIsBulkOverridingDeployments(true);
                }}
              >
                Bulk update deployments
              </DropdownButtonOption>
            </>
          )}

        {hasAccess('design') && (
          <>
            <DropdownButtonOption
              icon="pipeline"
              onClick={toggleIsCreatingPipeline}
            >
              Create pipeline
            </DropdownButtonOption>
            <DropdownButtonOption
              icon="upload"
              onClick={toggleIsImportingJSONFile}
            >
              Import pipeline from JSON
            </DropdownButtonOption>
            <DropdownButtonOption
              icon="model"
              onClick={() =>
                navigate(`/applications/${applicationID}/design/models/new`)
              }
            >
              Add model
            </DropdownButtonOption>
          </>
        )}

        {hasAccess('deploy_edit') && (
          <>
            <DropdownButtonOption
              icon="gateway"
              onClick={toggleIsAddingGateway}
            >
              Add gateway
            </DropdownButtonOption>
            <DropdownButtonOption icon="camera" onClick={toggleIsAddingCameras}>
              Add camera
            </DropdownButtonOption>
            <DropdownButtonOption
              icon="stream"
              onClick={toggleIsCreatingInputStream}
            >
              Add input streams
            </DropdownButtonOption>
            <DropdownButtonOption
              icon="stream-webrtc"
              onClick={toggleIsCreatingSharedStream}
            >
              Create shared stream
            </DropdownButtonOption>
            <DropdownButtonOption
              icon="file"
              onClick={() =>
                navigate(`/applications/${applicationID}/deploy/files/new`)
              }
            >
              Add files
            </DropdownButtonOption>
          </>
        )}

        {isLumeoAdmin && (
          <DropdownButtonOption
            icon="model"
            onClick={() =>
              navigate(
                `/applications/${applicationID}/admin/marketplace-models/new`
              )
            }
          >
            (Admin) Add marketplace model
          </DropdownButtonOption>
        )}
      </DropdownButton>

      {hasAccess('design') && (
        <>
          <CreatePipelinesFromJsonModal
            open={isImportingJSONFile}
            onOpenChange={toggleIsImportingJSONFile}
          />

          <CreateNewPipeline
            open={isCreatingPipeline}
            onClose={toggleIsCreatingPipeline}
          />
        </>
      )}

      {hasAccess('deploy_edit') && (
        <>
          <AddGatewayModal
            open={isAddingGateway}
            onClose={toggleIsAddingGateway}
          />

          <AddCameraDialog
            open={isAddingCameras}
            onClose={() => toggleIsAddingCameras(false)}
            onSuccess={() =>
              navigate(`/applications/${applicationID}/deploy/cameras`)
            }
          />

          <Drawer
            title="Deploy pipeline"
            containerClassName={deploymentFormDrawerClassName}
            onClose={toggleIsDeploying}
            open={isDeploying}
          >
            <NewDeploymentForm
              onSuccess={handleOnDeploymentSuccess}
              pipelineId={
                route === DesignRoute.PIPELINES ? entityId : undefined
              }
              onLink={() => toggleIsDeploying(false)}
            />
          </Drawer>

          <Drawer
            title="Update pipeline deployments"
            containerClassName={deploymentFormDrawerClassName}
            onClose={() => {
              toggleIsUpdatingDeployments(false);
              isUpdatingDeploymentsRef.current = false;
            }}
            open={Boolean(entityId) && isUpdatingDeployments}
          >
            <UpdateDeploymentsForm
              onSuccess={(deployments) => {
                toggleIsUpdatingDeployments(false);
                isUpdatingDeploymentsRef.current = false;

                if (deployments.length === 1) {
                  const [{ id }] = deployments;
                  navigate(
                    `/applications/${applicationID}/${NavRoute.DEPLOY}/${DeployRoute.DEPLOYMENTS}/${id}`
                  );
                  return;
                }

                navigate(
                  `/applications/${applicationID}/${NavRoute.DEPLOY}/${DeployRoute.DEPLOYMENTS}`
                );
              }}
              pipelineId={entityId}
            />
          </Drawer>

          <BulkOverrideDeploymentsForm
            pipelineId={entityId}
            onCancel={() => {
              toggleIsBulkOverridingDeployments(false);
              isUpdatingDeploymentsRef.current = false;
            }}
            onSuccess={() => {
              toggleIsBulkOverridingDeployments(false);
              isUpdatingDeploymentsRef.current = false;
              navigate(
                `/applications/${applicationID}/${NavRoute.DEPLOY}/${DeployRoute.DEPLOYMENTS}`
              );
            }}
            open={isBulkOverridingDeployments}
          />

          <CreateInputStreamsDialog
            open={isCreatingInputStream}
            onSuccess={() =>
              navigate(`/applications/${applicationID}/deploy/streams`)
            }
            onClose={toggleIsCreatingInputStream}
          />

          <CreateSharedStreamDrawer
            open={isCreatingSharedStream}
            onClose={toggleIsCreatingSharedStream}
          />
        </>
      )}

      {hasAccess('monitor') && isCreatingDashboard && (
        <CreateDashboardDialog
          open={isCreatingDashboard}
          onOpenChange={toggleIsCreatingDashboard}
        />
      )}
    </>
  );
}
