import classNames from 'classnames/bind';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import styles from './ContextMenu.module.scss';

const c = classNames.bind(styles);

export type ContextMenuOptionGroupProps = DropdownMenu.DropdownMenuRadioGroupProps;

export function ContextMenuOptionGroup(props: ContextMenuOptionGroupProps) {
  return <DropdownMenu.RadioGroup {...props} className={c('option-group')} />;
}
