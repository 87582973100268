import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import { InputProps } from 'components/Input/Input';
import {
  PipelineConditionInput,
  PipelineConditionInputProps,
} from 'pipelines/components/PipelineConditionInput/PipelineConditionInput';

export default class ReteTriggerConditionInput extends ReteInput<
  ReteInputProps & InputProps & PipelineConditionInputProps
> {
  constructor(
    editor: NodeEditor,
    key: string,
    {
      suggestionTypeFilters,
      ...params
    }: ReteInputParams &
      Pick<PipelineConditionInputProps, 'suggestionTypeFilters'>
  ) {
    super(editor, key, { ...params, type: 'string' });
    this.props.suggestionTypeFilters = suggestionTypeFilters;
    this.props.name = key;
  }

  get component() {
    return RetePipelineConditionInputComponent;
  }
}

function RetePipelineConditionInputComponent({
  keyrefValue,
  ...props
}: ReteInputProps & InputProps) {
  return <PipelineConditionInput {...(props as any)} />;
}
