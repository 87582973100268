import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import Pipeline from 'types/pipeline';
import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';

import { initializeAPIPipeline } from './usePipelines';

export type CreatePipelineVariables = {
  name?: Pipeline['name'];
  definition: Pipeline['definition'];
};

export function useCreatePipeline({
  onSettled,
  onSuccess,
  ...options
}: UseMutationOptions<Pipeline, APIError, CreatePipelineVariables> = {}) {
  const { pipelines } = useAPI();
  const queryClient = useQueryClient();

  return useMutation(pipelines.create, {
    ...options,
    onSuccess(pipeline, variables, context) {
      onSuccess?.(initializeAPIPipeline(pipeline), variables, context);
    },
    onSettled(pipeline, error, variables, context) {
      if (pipeline) {
        onSettled?.(initializeAPIPipeline(pipeline), error, variables, context);
      } else {
        onSettled?.(pipeline, error, variables, context);
      }

      queryClient.invalidateQueries(['pipelines']);
    },
  });
}
