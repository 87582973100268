import classnames from 'classnames/bind';
import { useForm } from 'react-hook-form';

import Stream from 'types/stream';

import { useSpringState } from 'hooks/useSpringState';
import { useUpdateStream } from 'streams/hooks/useUpdateStream';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Input } from 'components/Input/Input';

import styles from './FileDetail.module.scss';

const c = classnames.bind(styles);

export type FileDetailConfigurationProps = {
  fileStream: Stream;
};

type ConfigurationFieldValues = Pick<Stream, 'uri'>;

export function FileDetailConfiguration({
  fileStream,
}: FileDetailConfigurationProps) {
  const [isSuccess, setIsSuccess] = useSpringState(false, 2000);

  const { uri } = fileStream;

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<ConfigurationFieldValues>({
    defaultValues: { uri },
  });

  const { mutate, isLoading } = useUpdateStream(['file-streams'], {
    onSuccess(updatedFileStream) {
      setIsSuccess(true);
      reset({ uri: updatedFileStream.uri });
    },
  });

  function onSubmit({ uri }: ConfigurationFieldValues) {
    const updatedFileStream = fileStream.copy();
    updatedFileStream.uri = uri;
    mutate(updatedFileStream);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={c('configuration')}>
      <Field id="file-uri" label="URL">
        <Input id="file-uri" {...register('uri')} />
      </Field>

      <ButtonGroup>
        <Button
          variant="primary"
          type="submit"
          loading={isLoading}
          disabled={!isDirty}
        >
          Save configuration
        </Button>

        {isDirty && <InlineNotification>Unsaved changes</InlineNotification>}

        {isSuccess && (
          <InlineNotification intent="success">Saved!</InlineNotification>
        )}
      </ButtonGroup>
    </form>
  );
}
