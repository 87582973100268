import classNames from 'classnames/bind';
import * as RadixScrollArea from '@radix-ui/react-scroll-area';

import styles from './ScrollArea.module.scss';

const c = classNames.bind(styles);

export type ScrollAreaProps = RadixScrollArea.ScrollAreaViewportProps &
  Pick<RadixScrollArea.ScrollAreaProps, 'asChild' | 'dir'>;

export function ScrollArea({
  className,
  dir,
  asChild,
  ...viewPortProps
}: ScrollAreaProps) {
  return (
    <RadixScrollArea.Root
      className={c('scroll-area', className)}
      asChild={asChild}
      dir={dir}
    >
      <div className={c('scroll-area-content')}>
        <RadixScrollArea.Viewport
          {...viewPortProps}
          className={c('viewport')}
        />
      </div>
      <RadixScrollArea.Scrollbar orientation="horizontal">
        <RadixScrollArea.Thumb />
      </RadixScrollArea.Scrollbar>
      <RadixScrollArea.Scrollbar
        orientation="vertical"
        className={c('scrollbar')}
      >
        <RadixScrollArea.Thumb className={c('scrollbar-thumb')} />
      </RadixScrollArea.Scrollbar>
      <RadixScrollArea.Corner className={c('corner')} />
    </RadixScrollArea.Root>
  );
}
