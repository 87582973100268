import React from 'react';
import classnames from 'classnames/bind';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { NavRoute } from 'application/types/routes';

import { Icon, IconType } from 'components/Icon/Icon';

import styles from './Nav.module.scss';

const c = classnames.bind(styles);

type PrimaryLinkProps = React.PropsWithChildren<
  Omit<NavLinkProps, 'to' | 'className'>
> & {
  route: NavRoute;
  icon: IconType;
};

export function PrimaryLink({
  route,
  icon,
  children,
  ...props
}: PrimaryLinkProps) {
  return (
    <li>
      <NavLink
        {...props}
        className={({ isActive }) => c('primary-link', { selected: isActive })}
        to={route}
      >
        <Icon name={icon} />
        <span>{children}</span>
      </NavLink>
    </li>
  );
}
