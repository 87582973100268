import React from 'react';
import classnames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';

import Node, { getPropertyValue } from 'types/node';
import ReteInput from 'pipelines/services/rete/controls/input';
import { DeployTimeEditableNode } from 'types/pipeline';

import { useReteNode } from 'pipelines/hooks/useReteNode';
import {
  GroupedControls,
  useGroupedNodeControls,
} from 'deployments/hooks/useGroupedNodeControls';
import {
  CurrentNodeProvider,
  useCurrentNode,
} from 'deployments/hooks/useCurrentNode';

import {
  NodeCategory,
  getCategoryByNodeName,
} from 'pipelines/services/rete/nodes/categories';

import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import { useDeploymentForm } from '../context/deployment_form_context';
import { DeploymentFormControl } from '../Control/Control';
import styles from './Node.module.scss';

const c = classnames.bind(styles);

export type DeploymentFormNodeProps = {
  node: Node;
  deployTimeEditableNodes: DeployTimeEditableNode[];
};

export function DeploymentFormNode({
  node,
  deployTimeEditableNodes,
}: DeploymentFormNodeProps) {
  const reteNode = useReteNode(node);
  const { readonly } = useDeploymentForm();
  const controls = React.useMemo(() => {
    if (!reteNode) {
      return undefined;
    }

    return Array.from(reteNode.controls.values());
  }, [reteNode]);
  const groupedControls = useGroupedNodeControls(controls);

  if (!node || !node.name || !reteNode || (!readonly && !controls?.length)) {
    return null;
  }

  const { component } = node;
  if (!component) {
    return null;
  }
  const category = getCategoryByNodeName(node.name);

  return (
    <CurrentNodeProvider nodeID={node.id!} node={node} reteNode={reteNode}>
      <Inner
        groupedControls={groupedControls}
        category={category}
        deployTimeEditableNodes={deployTimeEditableNodes}
      />
    </CurrentNodeProvider>
  );
}

type InnerProps = {
  groupedControls: GroupedControls;
  category?: NodeCategory;
} & Pick<DeploymentFormNodeProps, 'deployTimeEditableNodes'>;

function Inner({
  groupedControls,
  category,
  deployTimeEditableNodes,
}: InnerProps) {
  const { node, reteNode } = useCurrentNode();
  const { pipeline, deployment, readonly } = useDeploymentForm();
  const { watch } = useFormContext();
  const { unfilledProperties } = node;
  const controls = Object.keys(groupedControls).flatMap(
    (group) => groupedControls[group] as ReteInput[]
  );
  const description =
    pipeline && node.id ? pipeline.nodeDescriptions[node.id] : undefined;

  const isUnfilled = (key: string) => unfilledProperties.includes(key);
  function keyRefDependents(key: string) {
    return controls.filter(({ keyref }) => keyref === key);
  }

  function isDeployParam(control: ReteInput): boolean {
    const deployNode = deployTimeEditableNodes.find(
      ({ nodeID }) => nodeID === node.id
    );

    const userSpecifiedControl = deployNode?.controls.find(
      ({ key }) => key === control.key
    );
    const isUserChecked = userSpecifiedControl
      ? userSpecifiedControl.deploymentParam &&
        userSpecifiedControl.deploymentParam !== 'never'
      : undefined;

    const isDefaultChecked =
      control.deploymentParam && control.deploymentParam !== 'never';

    return (isUserChecked ?? isDefaultChecked) as unknown as boolean;
  }

  function defaultValue(control: ReteInput) {
    const pipelineDefinitionValue = getPropertyValue(node.data, control.key);
    const deploymentConfigValue = getPropertyValue(
      deployment?.configuration[node.id!],
      control.key
    );

    const isPipelineParameter =
      pipelineDefinitionValue !== undefined &&
      pipelineDefinitionValue !== null &&
      (deploymentConfigValue === undefined || deploymentConfigValue === null);

    const controlName = `${node.id}.${control.key}`;

    return isPipelineParameter ? pipelineDefinitionValue : watch(controlName);
  }

  function isActiveKeyRef(control: ReteInput) {
    return keyRefDependents(control.key)
      .filter(
        ({ requiresKeyrefValue }) =>
          requiresKeyrefValue === defaultValue(control)
      )
      .some(
        (control) =>
          (control.props.required && isUnfilled(control.key)) ||
          isDeployParam(control)
      );
  }

  function isUnusedHiddenKeyRef(control: ReteInput) {
    return (
      !readonly &&
      control.props.type === 'hidden' &&
      keyRefDependents(control.key).every(
        (control) =>
          !isDeployParam(control) &&
          (!control.props.required || !isUnfilled(control.key))
      )
    );
  }

  function hasNoDeployParams() {
    // Determine potential keyRef node controls
    const keyRefs = controls.filter(
      ({ requiresKeyrefValue }) => requiresKeyrefValue === undefined
    );

    // Check if a node has any keyRef controls on deployment dialog
    const hasNoKeyRefs = keyRefs.every(
      (control) =>
        !readonly &&
        (!control.props.required || !isUnfilled(control.key)) &&
        (!isDeployParam(control) || control.props.type === 'hidden')
    );

    // Check if node has keyRefDependents that actively use a keyRef value on deployment dialog
    const hasNoKeyRefDependents = controls
      .filter(
        ({ requiresKeyrefValue, keyref }) =>
          requiresKeyrefValue !== undefined &&
          keyref &&
          keyRefs.some(
            (control) =>
              keyref === control.key &&
              defaultValue(control) === requiresKeyrefValue
          )
      )
      .every(
        (control) =>
          !readonly &&
          (!control.props.required || !isUnfilled(control.key)) &&
          !isDeployParam(control)
      );

    return hasNoKeyRefs && hasNoKeyRefDependents;
  }

  if (hasNoDeployParams()) {
    return null;
  }

  return (
    <fieldset key={node.id} className={c('wrap')} name={node.name}>
      <div className={c('node')}>
        <span className={c('node-icon', 'theme', category?.theme)}>
          <Icon name={node.component!.icon} />
        </span>

        <div className={c('node-header')}>
          <Heading level="3" asChild>
            <legend>
              {node.name} <em>{node.id}</em>
            </legend>
          </Heading>

          {description && (
            <Text className={c('node-description')} type="paragraph">
              {description}
            </Text>
          )}
        </div>

        <div className={c('node-properties')}>
          {Object.keys(groupedControls).map((group) => {
            const controls = groupedControls[group] as ReteInput[];
            const groupID = `${node.id}_${group}`;

            if (controls.length <= 0) {
              return null;
            }

            return (
              <fieldset
                className={c('group')}
                aria-labelledby={groupID}
                key={groupID}
              >
                {group && (
                  <strong
                    id={groupID}
                    className={c('group-label', 'label-headline')}
                  >
                    {group}
                  </strong>
                )}

                {controls.map((control) => (
                  <DeploymentFormControl
                    property={control.key}
                    control={control}
                    node={node}
                    reteNode={reteNode}
                    defaultValue={defaultValue(control)}
                    isUnusedHiddenKeyRef={isUnusedHiddenKeyRef(control)}
                    isActiveKeyRef={isActiveKeyRef(control)}
                    isDeployParam={isDeployParam(control)}
                    isUnfilled={isUnfilled(control.key)}
                    key={`${node.id}-${control.key}`}
                  />
                ))}
              </fieldset>
            );
          })}
        </div>
      </div>
    </fieldset>
  );
}

const Memoized = React.memo(Inner);

export { Memoized as Inner };
