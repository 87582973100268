import React from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

import { useAPI } from 'hooks/api/useAPI';

export type AppLinkProps = Partial<NavLinkProps> & {
  'aria-current'?: React.AriaAttributes['aria-current'];
  keepSearchParams?: boolean;
};

/** @deprecated */
function AppLink(
  { to, children, keepSearchParams, ...props }: AppLinkProps,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const { applicationID } = useAPI();
  const { search: previousSearch } = useLocation();

  const rest = typeof to === 'object' ? { ...to } : {};
  const newSearch =
    typeof to === 'object' && 'search' in to ? to.search : undefined;
  let pathname =
    typeof to === 'object' && 'pathname' in to
      ? String(to.pathname)
      : String(to);

  if (!pathname.includes('/applications/')) {
    pathname = `/applications/${applicationID}${pathname}`;
  }

  const search: string[] = [];

  if (previousSearch && keepSearchParams) {
    search.push(previousSearch);
  }

  if (newSearch) {
    search.push(newSearch);
  }

  to = {
    ...rest,
    pathname,
    search: search.length ? [...new Set(search)].join('&') : undefined,
  };

  return (
    <NavLink {...props} to={to} ref={ref}>
      {children}
    </NavLink>
  );
}

const ForwardRef = React.forwardRef<HTMLAnchorElement, AppLinkProps>(AppLink);

export { ForwardRef as AppLink };
