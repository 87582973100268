import React from 'react';
import classnames from 'classnames/bind';
import { Column } from 'react-table';
import { DateTime } from 'luxon';
import { DatesRangeValue } from '@mantine/dates';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mantine/core';

import { LumeoEvent, RELATED_ENTITIES_KEYS } from 'types/event';
import { getPresetDateRange } from 'dashboards/services/time_range';

import { useCameras } from 'cameras/hooks/useCameras';
import { useDeployments } from 'hooks/api/useDeployments';
import { useEvents, UseEventsParams } from 'events/hooks/useEvents';
import { useFiles } from 'files/hooks/useFiles';
import { useGateways } from 'hooks/api/useGateways';
import { useStreams } from 'streams/hooks/useStreams';

import { Code } from 'components/Code/Code';
import { DateRangePickerInput } from 'components/DateRangePickerInput/DateRangePickerInput';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { Icon } from 'components/Icon/Icon';
import { PaginatedTable } from 'components/Table/PaginatedTable';
import { SeveritySelect } from 'components/SeveritySelect/SeveritySelect';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';

import styles from './EventsOverview.module.scss';

const c = classnames.bind(styles);

const defaultDateRange = 'TODAY';

export function EventsOverview() {
  const [params, setParams] = React.useState<
    UseEventsParams & { page: number }
  >(() => {
    const [since, until] = getPresetDateRange(defaultDateRange);

    return {
      page: 1,
      since: since.toISOString(),
      until: until.toISOString(),
    };
  });

  const queryResult = useEvents({ params, keepPreviousData: true });

  const ids: Record<string, Array<string>> = Object.fromEntries(
    RELATED_ENTITIES_KEYS.map((key) => [`${key}s`, []])
  );

  if (queryResult.data) {
    queryResult.data.data.forEach((event) => {
      Object.entries(event.related_entities).forEach(([key, value]) => {
        value && !ids[`${key}s`].includes(value) && ids[`${key}s`].push(value);
      });
    });
  }

  const { camera_ids, deployment_ids, file_ids, gateway_ids, stream_ids } = ids;

  const { data: cameras, isInitialLoading: isLoadingCameras } = useCameras(
    { camera_ids, limit: camera_ids.length },
    { enabled: camera_ids.length > 0 }
  );

  const { data: deployments, isInitialLoading: isLoadingDeployments } =
    useDeployments(
      { deployment_ids, limit: deployment_ids.length },
      { enabled: deployment_ids.length > 0 }
    );

  const { data: files, isInitialLoading: isLoadingFiles } = useFiles({
    params: { file_ids, limit: file_ids.length },
    enabled: file_ids.length > 0,
  });

  const { data: gateways, isInitialLoading: isLoadingGateways } = useGateways(
    {
      gateway_ids,
      limit: gateway_ids.length,
    },
    { enabled: gateway_ids.length > 0 }
  );

  const { data: streams, isInitialLoading: isLoadingStreams } = useStreams(
    ['streams'],
    { stream_ids, limit: stream_ids.length },
    { enabled: stream_ids.length > 0 }
  );

  const isLoadingRelatedEntities =
    isLoadingCameras ||
    isLoadingDeployments ||
    isLoadingFiles ||
    isLoadingGateways ||
    isLoadingStreams;

  const columns = React.useMemo<Column<LumeoEvent>[]>(
    () => [
      {
        Header: 'Timestamp',
        accessor: 'event_ts',
        Cell: ({ value }) => (
          <span>
            {DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)}
          </span>
        ),
      },
      {
        Header: 'Severity',
        accessor: 'severity',
        Cell: ({ value }) => <StatusIndicator status={value} size="small" />,
      },
      {
        Header: 'Event',
        accessor: 'payload',
      },
      {
        Header: 'Entities',
        accessor: 'related_entities',
        Cell: ({ value: relatedEntities }) => {
          const camera =
            relatedEntities.camera_id &&
            cameras?.data.find(({ id }) => id === relatedEntities.camera_id);

          const deployment =
            relatedEntities.deployment_id &&
            deployments?.data.find(
              ({ id }) => id === relatedEntities.deployment_id
            );

          const file =
            relatedEntities.file_id &&
            files?.data.find(({ id }) => id === relatedEntities.file_id);

          const gateway =
            relatedEntities.gateway_id &&
            gateways?.data.find(({ id }) => id === relatedEntities.gateway_id);

          const stream =
            relatedEntities.stream_id &&
            streams?.data.find(({ id }) => id === relatedEntities.stream_id);

          if (isLoadingRelatedEntities) {
            return (
              <div className={c('entities')}>
                <Skeleton height="1rem" width="12rem" />
                <Skeleton height="1rem" width="12rem" />
              </div>
            );
          }

          return (
            <div className={c('entities')}>
              {camera && (
                <Link to={`../cameras/${camera.id}`}>
                  <div className={c('center')}>
                    <Icon name="camera" size="small" />
                    <span className={c('entity-link')}>{camera.name}</span>
                  </div>
                </Link>
              )}
              {deployment && (
                <Link to={`../deployments/${deployment.id}`}>
                  <div className={c('center')}>
                    <Icon name="deployment" size="small" />
                    <span className={c('entity-link')}>{deployment.name}</span>
                  </div>
                </Link>
              )}
              {file && (
                <div className={c('center')}>
                  <Icon name="file" size="small" />
                  <span>{file.name}</span>
                </div>
              )}
              {gateway && (
                <Link to={`../gateways/${gateway.id}`}>
                  <div className={c('center')}>
                    <Icon name="gateway" size="small" />
                    <span className={c('entity-link')}>{gateway.name}</span>
                  </div>
                </Link>
              )}
              {stream && (
                <Link to={`../streams/${stream.id}`}>
                  <div className={c('center')}>
                    <Icon name="stream" size="small" />
                    <span className={c('entity-link')}>{stream.name}</span>
                  </div>
                </Link>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'event_type',
        Cell: ({ value }) => <Code size="small">{value}</Code>,
      },
    ],
    [
      cameras?.data,
      deployments?.data,
      files?.data,
      gateways?.data,
      isLoadingRelatedEntities,
      streams?.data,
    ]
  );

  function handleDateRangeChange(dateRange?: DatesRangeValue) {
    if (!dateRange) {
      return;
    }

    const [since, until] = dateRange;

    if (!since || !until) {
      return;
    }

    setParams((previousState) => ({
      ...previousState,
      page: 1,
      since: since.toISOString(),
      until: until.toISOString(),
    }));
  }

  return (
    <div>
      <div className={c('header')}>
        <DateRangePickerInput
          defaultValue={defaultDateRange}
          onChange={handleDateRangeChange}
        />
        <SeveritySelect
          size="small"
          onChange={(option) =>
            option
              ? setParams((previousState) => ({
                  ...previousState,
                  severities: [option.value],
                  page: 1,
                }))
              : setParams((previousState) => ({
                  ...previousState,
                  severities: undefined,
                  page: 1,
                }))
          }
          isClearable
        />
      </div>
      <PaginatedTable<LumeoEvent>
        id="events-table"
        label="events"
        queryResult={queryResult}
        columns={columns}
        pageSize={50}
        page={params.page}
        onPageChange={(page) =>
          setParams((previousState) => ({ ...previousState, page }))
        }
        emptyMessage={
          <EmptyView>No events for this time range and filters.</EmptyView>
        }
      />
    </div>
  );
}
