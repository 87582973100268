import React from 'react';
import { SingleValue } from 'react-select';
import { useUncontrolled } from '@mantine/hooks';

import Gateway from 'types/gateway';

import { UseGatewaysFilterParams, useGateways } from 'hooks/api/useGateways';

import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';

import { useDeploymentForm } from './context/deployment_form_context';

export type DeploymentFormGatewaySelectProps = {
  onChange?: (value: Gateway | null) => void;
};

export function DeploymentFormGatewaySelect({
  onChange,
}: DeploymentFormGatewaySelectProps) {
  const { allowedGatewayIds, gateway, setGateway } = useDeploymentForm();

  // Manually track gateway selection when available gateway list is cleared
  React.useEffect(() => {
    if (!allowedGatewayIds?.length) {
      onChange?.(null);
      setGateway?.(null);
    }
  }, [onChange, setGateway, allowedGatewayIds]);

  const queryFilters: UseGatewaysFilterParams = {
    gateway_ids: allowedGatewayIds,
  };

  // Automatically select first of available gateways
  const { isInitialLoading } = useGateways(
    {
      ...queryFilters,
      limit: 1,
    },
    {
      enabled: Boolean(allowedGatewayIds?.length),
      onSuccess({ data }) {
        if (!data.length || gateway) {
          return;
        }

        const [firstGateway] = data;
        onChange?.(firstGateway);
        setGateway?.(firstGateway);
      },
    }
  );

  const [value, setValue] = useUncontrolled<SingleValue<Gateway>>({
    value: gateway,
    onChange(gateway) {
      onChange?.(gateway);
      setGateway?.(gateway);
    },
  });

  return (
    <GatewaySelect
      id="up-new-deployment_gateway"
      value={value}
      onChange={setValue}
      isLoading={isInitialLoading}
      queryFilters={queryFilters}
      queryOptions={{
        keepPreviousData: false,
      }}
      isClearable
    />
  );
}
