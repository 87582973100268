import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import { useDashboards } from 'dashboards/hooks/useDashboards';
import { usePropelMetrics } from 'dashboards/hooks/usePropelMetrics';
import { useTimeRangeParams } from 'dashboards/hooks/useTimeRangeParams';

import { CreateDashboardDialog } from 'dashboards/components/CreateDashboardDialog/CreateDashboardDialog';
import { DashboardView } from 'dashboards/views/DashboardView/DashboardView';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { Loader } from 'components/Loader/Loader';

export function Dashboards() {
  const { data: dashboards, isLoading } = useDashboards();
  const { preset, start, stop, timezone } = useTimeRangeParams();

  const [isCreatingDashboard, toggleCreateDashboard] = useToggle();

  // Pre-load
  usePropelMetrics();

  if (!dashboards || isLoading) {
    return <Loader text="Loading dashboards..." />;
  }

  const [firstDashboard] = dashboards.data;

  const search = new URLSearchParams({
    preset,
    start,
    stop,
    timezone,
  }).toString();

  return (
    <>
      <Routes>
        {firstDashboard && (
          <Route
            index
            element={
              <Navigate to={{ pathname: firstDashboard.id, search }} replace />
            }
          />
        )}

        <Route path=":dashboardID" element={<DashboardView />} />

        <Route
          path="*"
          element={
            <EmptyView
              title="You have not added any dashboards yet."
              buttonText="Create Dashboard"
              onButtonClick={toggleCreateDashboard}
            />
          }
        />
      </Routes>

      <CreateDashboardDialog
        open={isCreatingDashboard}
        onOpenChange={toggleCreateDashboard}
      />
    </>
  );
}
