import { Heading } from 'components/Heading/Heading';
import { Modal, ModalProps } from 'components/Modal/Modal';

export type TitledModalProps = Omit<ModalProps, 'header'> & {
  title: JSX.Element | string;
};

/** @deprecated */
export function TitledModal({ title, ...props }: TitledModalProps) {
  if (!props.children) {
    return null;
  }

  let header = title;

  if (typeof title === 'string') {
    header = <Heading level="2">{title}</Heading>;
  }

  return <Modal {...props} header={header} />;
}

export default TitledModal;
