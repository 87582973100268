import React from 'react';
import { NodeEditor } from 'rete';
import { Data as ReteEditorData } from 'rete/types/core/data';

import Pipeline from 'types/pipeline';
import { hasProperty, setPropertyValue } from 'types/node';

export type UseInitializeEditorOptions = {
  onSave: () => void;
  onInit: (editor: NodeEditor) => void;
};

export function useInitializeEditor(
  pipeline: Pipeline,
  { onSave, onInit }: UseInitializeEditorOptions
) {
  const editorRef = React.useRef<NodeEditor>();
  const [isInitialized, setIsInitialized] = React.useState(false);
  const didImportFromJSON = React.useRef(false);

  // Initialize pipeline from known definition
  React.useEffect(() => {
    const editor = editorRef.current;

    if (!editor || !isInitialized) {
      return;
    }

    const json = pipeline.reteDefinition as ReteEditorData;

    editor.nodes.forEach((node) => {
      const { controls } = node;
      const keys = Object.keys(node.data);

      // Make sure node properties include new possible props
      for (const key of controls.keys()) {
        if (!hasProperty(node.data, key)) {
          setPropertyValue(json.nodes[node.id].data, key, null);
        }
      }

      for (const key in keys) {
        if (!controls.has(key)) {
          delete json.nodes[node.id].data[key];
        }
      }
    });

    async function initializeFromJSON() {
      if (!editor) {
        return;
      }

      await editor.fromJSON(json);
      didImportFromJSON.current = true;
    }

    initializeFromJSON();
  }, [pipeline, isInitialized]);

  const initializeEditor = React.useCallback(
    (createdEditor: NodeEditor) => {
      if (!createdEditor || isInitialized) {
        return;
      }

      createdEditor.on(
        [
          'nodetranslated',
          'nodeupdated',
          'nodecreated',
          'noderemoved',
          'connectioncreated',
          'connectionremoved',
        ],
        () => {
          if (!didImportFromJSON.current) {
            return;
          }
          onSave();
        }
      );

      onInit(createdEditor);

      setIsInitialized(true);
      editorRef.current = createdEditor;
    },
    [onInit, onSave, isInitialized]
  );

  return initializeEditor;
}
