import React from 'react';
import classNames from 'classnames/bind';
import * as RadixAlertDialog from '@radix-ui/react-alert-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { isAPIError } from 'types/api_error';
import { useModal } from 'hooks/useModal';

import { Button, ButtonIntent } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';

import styles from './ConfirmDialog.module.scss';

const c = classNames.bind(styles);

export type ConfirmDialogProps = RadixAlertDialog.AlertDialogProps & {
  title: string;
  info?: string;
  confirmButtonText?: string;
  intent?: ButtonIntent;
  onConfirm: (() => void) | (() => Promise<void>);
  onCancel?: () => void;
};

export function ConfirmDialog({
  title,
  info,
  confirmButtonText = 'Confirm',
  intent,
  onConfirm,
  onCancel,
  ...props
}: ConfirmDialogProps) {
  return (
    <RadixAlertDialog.Root {...props}>
      <RadixAlertDialog.Portal>
        <RadixAlertDialog.Overlay className={c('overlay')} />
        <ConfirmModalContent
          title={title}
          info={info}
          confirmButtonText={confirmButtonText}
          intent={intent}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </RadixAlertDialog.Portal>
    </RadixAlertDialog.Root>
  );
}

function ConfirmModalContent({
  title,
  info,
  confirmButtonText,
  intent,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) {
  const { close } = useModal();

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  async function handleConfirm() {
    setError(undefined);

    try {
      const result = onConfirm();

      if (result instanceof Promise) {
        setIsLoading(true);
        await result;
      }

      close();
    } catch (error) {
      isAPIError(error)
        ? setError(error.message)
        : setError('An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  }

  function handleCancel() {
    onCancel?.();
    close();
  }

  return (
    <RadixAlertDialog.Content className={c('content')}>
      <div className={c('dialog')}>
        <div className={c('header')}>
          <RadixAlertDialog.Title className={c('header-title')}>
            <Heading level="2" asChild>
              <span>{title}</span>
            </Heading>
          </RadixAlertDialog.Title>
          <RadixAlertDialog.Cancel className={c('close-button')} asChild>
            <IconButton
              label="Close"
              icon="cancel"
              variant="ghost"
              onClick={handleCancel}
            />
          </RadixAlertDialog.Cancel>
        </div>

        {info && <p className={c('info')}>{info}</p>}
        {error && (
          <FormMessage icon="warning" intent="danger">
            {error}
          </FormMessage>
        )}

        <VisuallyHidden>
          <RadixAlertDialog.Description>
            Select "${confirmButtonText}" to proceed with the action, or
            "Cancel" to discard the action and return to the previous screen.
          </RadixAlertDialog.Description>
        </VisuallyHidden>

        <ButtonGroup align="center">
          <RadixAlertDialog.Cancel asChild>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </RadixAlertDialog.Cancel>
          <RadixAlertDialog.Action asChild>
            <Button
              variant="primary"
              intent={intent}
              onClick={handleConfirm}
              loading={isLoading}
            >
              {confirmButtonText}
            </Button>
          </RadixAlertDialog.Action>
        </ButtonGroup>
      </div>
    </RadixAlertDialog.Content>
  );
}
