import { FilterInput } from 'graphql/types/filter';

export function filterIds(dimension: string, filters: FilterInput[]) {
  return filters.reduce<string[]>((filteredIds, { column, value, and, or }) => {
    if (column === dimension && value) {
      filteredIds.push(value);
    }

    if (and) {
      filteredIds.push(...filterIds(dimension, and));
    }

    if (or) {
      filteredIds.push(...filterIds(dimension, or));
    }

    return filteredIds;
  }, []);
}
