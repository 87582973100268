import React from 'react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import classnames from 'classnames/bind';

import { ButtonTypeButtonProps } from 'components/Button/ButtonTypeButton';

import { Button } from 'components/Button/Button';
import { IconButton } from 'components/IconButton/IconButton';
import { Icon } from 'components/Icon/Icon';

import { DropdownButtonOption } from './DropdownButtonOption';
import styles from './DropdownButton.module.scss';

const c = classnames.bind(styles);

export type DropdownButtonProps = Omit<
  ButtonTypeButtonProps,
  'variant' | 'loading'
> & {
  label: string | React.ReactElement;
  alwaysTriggerMenu?: boolean;
};

export function DropdownButton({
  children,
  label,
  className,
  size,
  disabled,
  alwaysTriggerMenu,
  ...rest
}: DropdownButtonProps) {
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
  }

  return (
    <RadixDropdownMenu.Root>
      <div className={c('wrap', 'theme primary', size)}>
        {!alwaysTriggerMenu && (
          <Button
            {...rest}
            className={c('primary-button', className)}
            disabled={disabled}
            variant="none"
          >
            <span>{label}</span>
          </Button>
        )}
        <RadixDropdownMenu.Trigger disabled={disabled} asChild>
          {alwaysTriggerMenu ? (
            <Button
              {...rest}
              className={c('primary-button', className)}
              disabled={disabled}
              variant="none"
            >
              <span>{label}</span>
              <div className={c('icon-wrap', { disabled })}>
                <Icon name="chevron-down" size="small" />
              </div>
            </Button>
          ) : (
            <IconButton
              className={c('dropdown-trigger', size)}
              onClick={handleClick}
              icon="chevron-down"
              label="View more options"
              variant={size === 'small' ? 'none' : 'ghost'}
              size="small"
            />
          )}
        </RadixDropdownMenu.Trigger>
      </div>

      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content className={c('dropdown')} align="end">
          {children}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
}

export { DropdownButtonOption };
