import React from 'react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import classnames from 'classnames/bind';

import { Icon, IconType } from 'components/Icon/Icon';
import { AppLink } from 'components/AppLink/AppLink';

import styles from './DropdownButton.module.scss';

const c = classnames.bind(styles);

type CommonProps = React.PropsWithChildren<{
  icon?: IconType;
  disabled?: boolean;
}>;

type ButtonProps = CommonProps & {
  onClick: () => void;
  appLink?: never;
};

type LinkProps = CommonProps & {
  appLink: string;
  onClick?: never;
};

export type DropdownButtonOptionProps = ButtonProps | LinkProps;

export function DropdownButtonOption({
  children,
  icon,
  onClick,
  appLink,
  disabled,
}: DropdownButtonOptionProps) {
  let inner = (
    <div className={c('dropdown-link')}>
      {icon && <Icon name={icon} />}
      <span key="label">{children}</span>
    </div>
  );

  if (appLink) {
    inner = <AppLink to={appLink}>{inner}</AppLink>;
  }

  return (
    <RadixDropdownMenu.Item asChild>
      <li className={c('dropdown-item', { disabled })} onClick={onClick}>
        {inner}
      </li>
    </RadixDropdownMenu.Item>
  );
}
