import React from 'react';
import Konva from 'konva';
import classNames from 'classnames/bind';
import { Layer, Line, Stage } from 'react-konva';
import { useClickOutside, useToggle } from '@mantine/hooks';

import { Icon } from 'components/Icon/Icon';

import {
  denormalizeROIShapes,
  normalizeROIShapes,
} from './services/normalize_shapes';
import { getDefaultLabel } from './services/get_default_label';
import { getPointerClickPosition } from './services/get_pointer_position';
import { isValidShape } from './services/is_valid_shape';
import { ROIAnchorPoint } from './components/AnchorPoint';
import { ROICanvasInputs } from './components/Inputs';
import { ROIShape } from './components/Shape';

import { ROICanvasType } from './types';
import { ROIToolbar } from './components/Toolbar';
import { ROI_CONFIG } from './config';

import styles from './ROICanvas.module.scss';

const c = classNames.bind(styles);

export type ROICanvasProps = {
  children?: React.ReactNode;
  type?: ROICanvasType;
  defaultValue?: Record<string, number[]>;
  onChange?: (value: Record<string, number[]>) => void;
};

const DBLCLICK_DELTA = 10;

function ROICanvas({
  children,
  defaultValue,
  type = 'polygon',
  onChange,
}: ROICanvasProps) {
  /**
   * Rerender helper to construct shapes with ref but still rerender on update
   */
  const [, rerender] = React.useState<number>(0);

  const stageRef = React.useRef<Konva.Stage>(null);

  const clickPosition = React.useRef<Konva.Vector2d | null>(null);

  const lineRef = React.useRef<Konva.Line>(null);
  const shapeRef = React.useRef<Konva.Line>(null);
  const [shapeStart, setShapeStart] = React.useState<
    readonly [number, number] | null
  >(null);

  const [selectedShape, setSelectedShape] = React.useState<string | null>(null);
  const [selectedAnchor, setSelectedAnchor] =
    React.useState<Konva.Circle | null>(null);

  const [width, setWidth] = React.useState<number>();
  const [height, setHeight] = React.useState<number>();

  const [shapes, setShapes] = React.useState<Record<string, number[]>>({});

  const isInitialized = React.useRef(false);

  const normalizedShapes = React.useMemo(() => {
    const width = stageRef.current?.width();
    const height = stageRef.current?.height();

    if (!width || !height) {
      return null;
    }

    return normalizeROIShapes(shapes, width, height);
  }, [shapes]);

  const [isFullscreen, toggleFullscreen] = useToggle();
  const [isShowingLabels, toggleLabels] = useToggle([true, false]);

  const closeCurrentShape = React.useCallback(() => {
    const shape = shapeRef.current;
    const line = lineRef.current;
    const points = shape?.points();

    if (
      !line ||
      !shape ||
      !points ||
      !isValidShape(points, type) ||
      !stageRef.current
    ) {
      return;
    }

    const width = stageRef.current.width();
    const height = stageRef.current.height();

    setShapes((shapes) => {
      const newShapes = {
        ...shapes,
        [getDefaultLabel(shapes, type)]: points,
      };
      onChange?.(normalizeROIShapes(newShapes, width, height));
      return newShapes;
    });

    line.points([]);
    shape.points([]);
    setShapeStart(null);
  }, [type, onChange]);

  function handleStageClick() {
    // Reset selection
    setSelectedShape(null);
    setSelectedAnchor(null);

    const stage = stageRef.current;
    const pointerPos = getPointerClickPosition(stage);

    if (!stage || !pointerPos || !shapeRef.current) {
      return;
    }

    const { x, y } = pointerPos;

    if (!shapeRef.current.points().length) {
      setShapeStart([x, y]);
    }

    const points = [...shapeRef.current.points(), x, y];

    if (!clickPosition.current) {
      clickPosition.current = stage.pointerPos;
      shapeRef.current.points(points);
      rerender((num) => num + 1);
      return;
    }

    const { x: ax, y: ay } = clickPosition.current;

    const dx = Math.abs(x - ax);
    const dy = Math.abs(y - ay);

    // Simulated double-click
    if (dx < DBLCLICK_DELTA && dy < DBLCLICK_DELTA) {
      closeCurrentShape();
      clickPosition.current = null;
      return;
    }

    shapeRef.current.points(points);
    clickPosition.current = stage.pointerPos;
    rerender((num) => num + 1);
  }

  function handleStageMouseMove({
    target,
  }: Konva.KonvaEventObject<MouseEvent>) {
    if (
      !stageRef.current?.pointerPos ||
      !shapeRef.current ||
      !lineRef.current
    ) {
      return;
    }

    const targetType = target.getClassName();
    const currentShape = shapeRef.current.points();

    if (currentShape.length >= 2) {
      const [ax, ay] = currentShape.slice(-2);

      // Snap to existing anchors
      if (targetType === 'Circle') {
        lineRef.current.points([ax, ay, target.x(), target.y()]);
        return;
      }

      const pointerPos = getPointerClickPosition(stageRef.current);

      if (pointerPos) {
        lineRef.current.points([ax, ay, pointerPos.x, pointerPos.y]);
      }
    }
  }

  function handleStartPointClick(event: Konva.KonvaEventObject<MouseEvent>) {
    event.cancelBubble = true;
    closeCurrentShape();
  }

  const handleShapeChange = React.useCallback(
    (label: string, shape: number[]) => {
      if (!stageRef.current) {
        return;
      }

      const width = stageRef.current.width();
      const height = stageRef.current.height();

      setShapes((previousShapes) => {
        const updatedShapes = { ...previousShapes, [label]: shape };
        onChange?.(normalizeROIShapes(updatedShapes, width, height));
        return updatedShapes;
      });
    },
    [onChange]
  );

  const handleShapeRename = React.useCallback(
    (oldLabel: string, newLabel: string) => {
      if (!stageRef.current) {
        return;
      }

      const width = stageRef.current.width();
      const height = stageRef.current.height();

      setShapes((previousShapes) => {
        const { [oldLabel]: shape, ...remainingShapes } = previousShapes;
        const updatedShapes = {
          ...remainingShapes,
          [newLabel]: shape,
        };
        onChange?.(normalizeROIShapes(updatedShapes, width, height));
        return updatedShapes;
      });
    },
    [onChange]
  );

  function deleteSelectedShape() {
    if (!selectedShape || !stageRef.current) {
      return;
    }

    const width = stageRef.current.width();
    const height = stageRef.current.height();

    setShapes(({ [selectedShape]: deletedShape, ...remainingShapes }) => {
      onChange?.(normalizeROIShapes(remainingShapes, width, height));
      return remainingShapes;
    });
    setSelectedShape(null);
  }

  function insertThreePointLine() {
    if (!stageRef.current) {
      return;
    }

    const width = stageRef.current.width();
    const height = stageRef.current.height();

    const threePointLine = denormalizeROIShapes(
      {
        [getDefaultLabel(shapes, type)]: [0, 0.5, 0.5, 0.5, 1, 0.5],
      },
      width,
      height
    );

    setShapes((previousShapes) => {
      const updatedShapes = {
        ...previousShapes,
        ...threePointLine,
      };
      onChange?.(normalizeROIShapes(updatedShapes, width, height));
      return updatedShapes;
    });
  }

  function insertEightPointRectangle() {
    if (!stageRef.current) {
      return;
    }

    const width = stageRef.current.width();
    const height = stageRef.current.height();

    const eightPointRectangle = denormalizeROIShapes(
      {
        [getDefaultLabel(shapes, type)]: [
          0, 0, 0.5, 0, 1, 0, 1, 0.5, 1, 1, 0.5, 1, 0, 1, 0, 0.5,
        ],
      },
      width,
      height
    );

    setShapes((previousShapes) => {
      const updatedShapes = {
        ...previousShapes,
        ...eightPointRectangle,
      };
      onChange?.(normalizeROIShapes(updatedShapes, width, height));
      return updatedShapes;
    });
  }

  const handleShapeDelete = React.useCallback(
    (label: string) => {
      if (!stageRef.current) {
        return;
      }

      const width = stageRef.current.width();
      const height = stageRef.current.height();

      setShapes(({ [label]: deletedShape, ...remainingShapes }) => {
        onChange?.(normalizeROIShapes(remainingShapes, width, height));
        return remainingShapes;
      });
      setSelectedShape(null);
    },
    [onChange]
  );

  const handleManualChange = React.useCallback(
    (shapes: Record<string, number[]>) => {
      if (!stageRef.current) {
        return;
      }

      const width = stageRef.current.width();
      const height = stageRef.current.height();

      const denormalized = denormalizeROIShapes(shapes, width, height);
      setShapes(denormalized);
      onChange?.(shapes);
    },
    [onChange]
  );

  // Initialize from defaultValue
  React.useEffect(() => {
    if (
      !defaultValue ||
      Object.keys(defaultValue).length === 0 ||
      !width ||
      !height
    ) {
      return;
    }

    const denormalizedValue = denormalizeROIShapes(defaultValue, width, height);
    setShapes(denormalizedValue);

    // FIXME: emitting on initialization doesn't make any sense but seems to be
    // necessary to not lose the initial ROI shapes when editing any other field
    if (!isInitialized.current) {
      onChange?.(defaultValue);
      isInitialized.current = true;
    }
  }, [defaultValue, width, height, onChange]);

  React.useEffect(() => {
    if (!stageRef.current) {
      return;
    }

    const container = stageRef.current.container();

    function handleKeyDown(event: KeyboardEvent) {
      switch (event.key) {
        // Finish current shape if valid
        case 'Enter': {
          event.preventDefault();
          closeCurrentShape();
          break;
        }

        // While drawing, remove last segment and reset current line
        case 'Backspace': {
          if (!shapeRef.current?.points().length) {
            break;
          }

          event.preventDefault();

          const updatedShape = shapeRef.current.points().slice(0, -2);
          shapeRef.current.points(updatedShape);

          if (!lineRef.current) {
            break;
          }

          const [ax, ay] = updatedShape.slice(-2);
          const [bx, by] = lineRef.current.points().slice(-2);
          lineRef.current.points([ax, ay, bx, by]);
          break;
        }

        // Stop drawing and deselect shapes
        case 'Escape': {
          event.preventDefault();
          lineRef.current?.points([]);
          shapeRef.current?.points([]);
          setShapeStart(null);
          setSelectedShape(null);
          rerender((num) => num + 1);
          break;
        }
      }
    }

    container.addEventListener('keydown', handleKeyDown);

    return () => {
      container.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeCurrentShape]);

  const wrapRef = useClickOutside(() => {
    if (!shapeRef.current) {
      return;
    }

    lineRef.current?.points([]);
    shapeRef.current?.points([]);
    setShapeStart(null);
    setSelectedShape(null);
    rerender((num) => num + 1);
  });

  // Resize stage and shapes with container element
  React.useEffect(() => {
    const stage = stageRef.current;
    const container = stage?.container();

    if (!stage || !container) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const width = entry.target.clientWidth;
        const height = entry.target.clientHeight;

        if (
          width <= ROI_CONFIG.padding * 2 ||
          height <= ROI_CONFIG.padding * 2
        ) {
          return;
        }

        const previousWidth = stage.width();
        const previousHeight = stage.height();

        stage.width(width);
        stage.height(height);

        setWidth(width);
        setHeight(height);
        // Not emitting onChange here because no value change occurred.
        setShapes((shapes) => {
          const normalized = normalizeROIShapes(
            shapes,
            previousWidth,
            previousHeight
          );
          return denormalizeROIShapes(normalized, width, height);
        });
      });
    });

    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, [onChange]);

  function handleFullscreenClick() {
    if (!wrapRef.current) {
      return;
    }

    toggleFullscreen();

    if (!document.fullscreenElement) {
      wrapRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  React.useEffect(() => {
    function handleFullscreenChange() {
      if (!document.fullscreenElement) {
        toggleFullscreen(false);
      }
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [toggleFullscreen]);

  return (
    <div
      className={c('wrap', { fullscreen: isFullscreen })}
      style={
        {
          '--width': width,
          '--height': height,
        } as React.CSSProperties
      }
      ref={wrapRef}
    >
      <div className={c('canvas')}>
        <div className={c('background')}>{children}</div>

        <Stage
          className={c('stage')}
          onClick={handleStageClick}
          onMouseMove={handleStageMouseMove}
          tabIndex={-1}
          ref={stageRef}
        >
          <Layer>
            {Object.entries(shapes).map(([label, shape]) => (
              <ROIShape
                labels={Object.keys(shapes)}
                label={label}
                shape={shape}
                closed={type === 'polygon'}
                isSelected={selectedShape === label}
                selectedAnchor={selectedAnchor}
                isShowingLabels={isShowingLabels}
                onChange={handleShapeChange}
                onRename={handleShapeRename}
                onSelect={setSelectedShape}
                onSelectedAnchorChange={setSelectedAnchor}
                onDelete={handleShapeDelete}
                key={label}
              />
            ))}

            {/* Line from last point to cursor position */}
            <Line {...ROI_CONFIG.line} ref={lineRef} />

            {/* Current shape */}
            <Line {...ROI_CONFIG.line} ref={shapeRef} />

            {/* Start anchor of current shape to easily close */}
            {shapeStart?.length ? (
              <ROIAnchorPoint
                x={shapeStart[0]}
                y={shapeStart[1]}
                onClick={handleStartPointClick}
              />
            ) : null}
          </Layer>
        </Stage>
      </div>

      <ROIToolbar
        type={type}
        isFullscreen={isFullscreen}
        isShowingLabels={isShowingLabels}
        selectedShape={selectedShape}
        onDeleteSelectedShape={deleteSelectedShape}
        onInsertEightPointRectangle={insertEightPointRectangle}
        onInsertThreePointLine={insertThreePointLine}
        onToggleFullscreen={handleFullscreenClick}
        onToggleLabels={toggleLabels}
      />

      <details className={c('advanced')}>
        <summary className={c('advanced-header')}>
          <Icon
            className={c('advanced-indicator')}
            name="chevron-right"
            size="small"
          />
          <span>View values</span>
        </summary>
        <div className={c('advanced-content')}>
          <ROICanvasInputs
            shapes={normalizedShapes}
            onChange={handleManualChange}
          />
        </div>
      </details>
    </div>
  );
}

const memoized = React.memo(ROICanvas);

export { memoized as ROICanvas };

export type { ROICanvasType };

export {
  getCoordinatesFromString,
  getLabelsFromString,
} from './services/get_shapes_from_string';
export { coordinatesToPairs } from './services/coordinates_to_pairs';
