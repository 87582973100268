import {
  Clazz,
  createModelSchema,
  custom,
  deserialize,
  list,
  object,
  primitive,
} from 'serializr';

import Account from 'types/account';
import Camera from 'types/camera';
import Category from 'types/category';
import Dashboard from 'types/dashboard';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import OrganizationInvitation from 'types/invitation';
import Pipeline from 'types/pipeline';
import PipelineTemplate from 'types/pipeline_template';
import Stream from 'types/stream';
import { VideoSource } from 'hooks/api/useVideoSources';

/** @deprecated
 * Use {@link OffsetPaginated} instead.
 */
export class PaginatedAPIResponse<TClass> {
  pagination: 'offset';

  data: TClass[];

  current_page: number;
  total_pages: number;
  total_elements: number;
  page_elements: number;

  previous_page: number | null;
  next_page: number | null;

  constructor(
    pagination: 'offset',
    data: TClass[],
    currentPage: number,
    totalPages: number,
    totalElements: number,
    pageElements: number,
    previousPage: number | null,
    nextPage: number | null
  ) {
    this.pagination = pagination;
    this.data = data;
    this.current_page = currentPage;
    this.total_pages = totalPages;
    this.total_elements = totalElements;
    this.page_elements = pageElements;
    this.previous_page = previousPage;
    this.next_page = nextPage;
  }
}

function createPaginatedModelSchema<TClass>(
  paginatedResponseClass: Clazz<PaginatedAPIResponse<TClass>>,
  modelClass: Clazz<TClass>
) {
  if (!modelClass) {
    return;
  }

  createModelSchema(paginatedResponseClass, {
    pagination: primitive(),
    current_page: primitive(),
    total_pages: primitive(),
    total_elements: primitive(),
    page_elements: primitive(),
    previous_page: primitive(),
    next_page: primitive(),
    data: list(object(modelClass)),
  });
}

/** Typed extensions of `PaginatedAPIResponse` for use with serializr. */

export class StreamPaginatedAPIResponse extends PaginatedAPIResponse<Stream> {}
createPaginatedModelSchema(StreamPaginatedAPIResponse, Stream);

export class DeploymentPaginatedAPIResponse extends PaginatedAPIResponse<Deployment> {}
createPaginatedModelSchema(DeploymentPaginatedAPIResponse, Deployment);

export class GatewayPaginatedAPIResponse extends PaginatedAPIResponse<Gateway> {}
createPaginatedModelSchema(GatewayPaginatedAPIResponse, Gateway);

export class CameraPaginatedAPIResponse extends PaginatedAPIResponse<Camera> {}
createPaginatedModelSchema(CameraPaginatedAPIResponse, Camera);

export class PipelinePaginatedAPIResponse extends PaginatedAPIResponse<Pipeline> {}
createPaginatedModelSchema(PipelinePaginatedAPIResponse, Pipeline);

export class DashboardPaginatedAPIResponse extends PaginatedAPIResponse<Dashboard> {}
createPaginatedModelSchema(DashboardPaginatedAPIResponse, Dashboard);

export class MembersPaginatedAPIResponse extends PaginatedAPIResponse<Account> {}
createPaginatedModelSchema(MembersPaginatedAPIResponse, Account);

// eslint-disable-next-line max-len
export class OrganizationInvitationsPaginatedAPIResponse extends PaginatedAPIResponse<OrganizationInvitation> {}
createPaginatedModelSchema(
  OrganizationInvitationsPaginatedAPIResponse,
  OrganizationInvitation
);

export class CategoryPaginatedAPIResponse extends PaginatedAPIResponse<Category> {}
createPaginatedModelSchema(CategoryPaginatedAPIResponse, Category);

export class PipelineTemplatesPaginatedAPIResponse extends PaginatedAPIResponse<PipelineTemplate> {}
createPaginatedModelSchema(
  PipelineTemplatesPaginatedAPIResponse,
  PipelineTemplate
);

type PaginatedAPIResponseVideoSource = VideoSource & {
  type: 'camera' | 'stream';
};

export class VideoSourcesPaginatedAPIResponse extends PaginatedAPIResponse<VideoSource> {}
createModelSchema(VideoSourcesPaginatedAPIResponse, {
  pagination: primitive(),
  current_page: primitive(),
  total_pages: primitive(),
  page_elements: primitive(),
  previous_page: primitive(),
  next_page: primitive(),
  data: list(
    custom(
      (videoSource: VideoSourcesPaginatedAPIResponse) => videoSource,
      (videoSource: PaginatedAPIResponseVideoSource) => {
        if (!videoSource.type) {
          return null;
        }

        switch (videoSource.type) {
          case 'camera':
            return deserialize(Camera, videoSource);
          case 'stream':
            return deserialize(Stream, videoSource);
          default:
            return null;
        }
      }
    )
  ),
});
