import React from 'react';
import classnames from 'classnames/bind';

import { useAdvancedSearch } from '../../services/AdvancedSearchContext';
import styles from './AdvancedSearchBar.module.scss';

const c = classnames.bind(styles);

export type AdvancedSearchBarInputProps =
  React.HTMLAttributes<HTMLInputElement> & {
    label?: string;
  };

export function AdvancedSearchBarInput({
  label = 'Search',
  ...props
}: AdvancedSearchBarInputProps) {
  const { relatedEntityFilters, searchInput, setSearchInput } =
    useAdvancedSearch();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const isMounted = React.useRef(false);
  const isInputInitialized = React.useRef(false);

  const [value, setValue] = React.useState<string>(searchInput);

  // Whenever adding or removing a filter, add focus back to input field
  React.useEffect(() => {
    if (!isInputInitialized.current) {
      isInputInitialized.current = true;
      return;
    }

    inputRef.current?.focus();
  }, [relatedEntityFilters]);

  // Reset local value up-to-date if context value is reset,
  // and add focus back to input field
  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (searchInput.trim() === '') {
      setValue(searchInput);
      inputRef.current?.focus();
    }
  }, [searchInput]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setValue(value);
    setSearchInput(value);
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        event.preventDefault();
    }
  }

  return (
    <input
      {...props}
      type="search"
      id="filterbar_search"
      className={c('search-input')}
      placeholder={`${label}...`}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      value={value}
      ref={inputRef}
      aria-haspopup="listbox"
    />
  );
}
