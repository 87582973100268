import React from 'react';
import classNames from 'classnames/bind';

import APIError from 'types/api_error';

import { capitalizeFirst } from 'services/string';
import { VideoSource } from 'hooks/api/useDeploymentsVideoSources';

import { Text } from 'components/Text/Text';

import styles from './VideoSourceROI.module.scss';

const c = classNames.bind(styles);

export type VideoSourceROIPreviewProps = {
  source?: VideoSource | null;
  url?: string;
  error?: APIError | null;
  isLoading: boolean;
};

export function VideoSourceROIPreview({
  source,
  url,
  error,
  isLoading,
}: VideoSourceROIPreviewProps) {
  const [isLoaded, setIsLoaded] = React.useState(false);

  if (!source) {
    return <div className={c('placeholder')} />;
  }

  if (source.status === 'offline') {
    return (
      <>
        <div className={c('placeholder')} />
        <Text className={c('roi-info')} type="paragraph" inline>
          {capitalizeFirst(source.source_type)} is unavailable.
        </Text>
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className={c('placeholder')} />
        <Text className={c('roi-info')} type="paragraph" inline>
          {error.message ||
            'An unknown error occurred. Please try again later.'}
        </Text>
      </>
    );
  }

  if (isLoading || !url) {
    return (
      <>
        <div className="loading-spinner-wrap">
          <span className="loading-spinner small" />
        </div>
        <div className={c('placeholder')} />
      </>
    );
  }

  return (
    <>
      {!isLoaded && (
        <div className="loading-spinner-wrap">
          <span className="loading-spinner small" />
        </div>
      )}
      <img
        className={c('image', { placeholder: !isLoaded })}
        src={url}
        alt={`${source.name} snapshot`}
        onLoad={() => setIsLoaded(true)}
      />
    </>
  );
}
