export const PROPEL_GATEWAY_METRICS = [
  'Gateway CPU Utilization Latest',
  'Gateway CPU Memory Usage Percent Latest',
  'Gateway GPU Utilization Latest',
  'Gateway GPU Memory Usage Percent Latest',
  'Gateway Disk Usage Percent Latest',
] as const;

export type PropelGatewayMetric = typeof PROPEL_GATEWAY_METRICS[number];

export function isPropelGatewayMetric(
  metric: string
): metric is PropelGatewayMetric {
  return ([...PROPEL_GATEWAY_METRICS] as string[]).includes(metric);
}

export const PROPEL_GATEWAY_METRICS_LATEST: Array<{
  metric: { name: PropelGatewayMetric };
}> = [
  { metric: { name: 'Gateway CPU Utilization Latest' } },
  { metric: { name: 'Gateway CPU Memory Usage Percent Latest' } },
  { metric: { name: 'Gateway GPU Utilization Latest' } },
  { metric: { name: 'Gateway GPU Memory Usage Percent Latest' } },
  { metric: { name: 'Gateway Disk Usage Percent Latest' } },
];
