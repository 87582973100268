import React from 'react';
import classNames from 'classnames/bind';
import { CellProps } from 'react-table';

import { AppEntityObject } from 'hooks/useEntities';
import { truncateMiddle } from 'services/string';

import { CopyToClipboardButton } from 'components/CopyToClipboardButton/CopyToClipboardButton';
import { Heading } from 'components/Heading/Heading';
import { Icon, IconProps } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import { TextAppLink } from 'components/TextAppLink/TextAppLink';
import { OptionalTooltip } from 'components/Tooltip/Tooltip';

import { RenameDialog } from './RenameDialog';
import styles from './TableNameCell.module.scss';

const c = classNames.bind(styles);

export type NameCellProps<T extends AppEntityObject = AppEntityObject> =
  CellProps<T> & {
    entity: string;
    to?: string;
    icon?: IconProps['name'];
    info?: React.ReactText | React.ReactElement;
    suffix?: JSX.Element | null | false;
    isRenaming?: boolean;
    onNameChange?: (newName: string) => void;
    onCancel?: () => void;
  };

const MAX_NAME_DISPLAY_LENGTH = 75;

function NameCell<T extends AppEntityObject = AppEntityObject>({
  row: { original },
  to,
  icon,
  entity,
  info,
  suffix,
  isRenaming,
  onNameChange,
  onCancel,
}: NameCellProps<T>) {
  const { id, name } = original;

  return (
    <div className={c('name-cell', 'wrap')}>
      <Heading level="4" className={c('name-cell-title')} asChild>
        <div>
          {icon && <Icon name={icon} size="small" />}

          <OptionalTooltip
            content={name}
            showTooltip={name ? name.length > MAX_NAME_DISPLAY_LENGTH : false}
          >
            {to ? (
              <TextAppLink
                key="name"
                to={to}
                className={c('table-link')}
                keepSearchParams
              >
                {truncateMiddle(name ?? 'Unknown', MAX_NAME_DISPLAY_LENGTH)}
              </TextAppLink>
            ) : (
              <span>
                {truncateMiddle(name ?? 'Unknown', MAX_NAME_DISPLAY_LENGTH)}
              </span>
            )}
          </OptionalTooltip>

          {id && (
            <div className={c('clipboard-button')}>
              <CopyToClipboardButton
                message="Copied ID to clipboard!"
                text={String(id)}
                inline
              />
            </div>
          )}

          {suffix && <div className={c('suffix')}>{suffix}</div>}
        </div>
      </Heading>

      {info && <Text>{info}</Text>}

      {onNameChange && (
        <RenameDialog
          defaultValue={name}
          onSave={onNameChange}
          open={isRenaming}
          onCancel={onCancel}
          entity={entity}
        />
      )}
    </div>
  );
}

const Memoized = React.memo(NameCell) as typeof NameCell;

export { Memoized as NameCell };
