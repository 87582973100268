import React from 'react';
import { CellProps } from 'react-table';
import { useClipboard, useToggle } from '@mantine/hooks';

import Deployment from 'types/deployment';
import Pipeline from 'types/pipeline';

import downloadFile from 'services/download_file';
import { useAppRedirect } from 'hooks/useAppRedirect';
import { useCreatePipeline } from 'pipelines/hooks/useCreatePipeline';
import { useDeletePipeline } from 'hooks/api/usePipeline';
import { useHasAccess } from 'hooks/useHasAccess';

import {
  deploymentFormDrawerClassName,
  NewDeploymentForm,
} from 'deployments/components/Form';

import { ContextMenuAction } from 'components/ContextMenu/ContextMenuAction';
import {
  ContextMenu,
  ContextMenuSeparator,
} from 'components/ContextMenu/ContextMenu';
import { Drawer } from 'components/Drawer/Drawer';
import { IconButton } from 'components/IconButton/IconButton';

export type PipelineOverviewActionsCellProps = CellProps<Pipeline> & {
  onRenameClick: (pipelineID: Pipeline['id']) => void;
};

export function PipelineOverviewActionsCell({
  row,
  onRenameClick,
}: PipelineOverviewActionsCellProps) {
  const [hasAccess] = useHasAccess();

  const [isDeploying, toggleIsDeploying] = useToggle();
  const redirect = useAppRedirect();

  const { copy } = useClipboard();

  const { mutate: createPipeline } = useCreatePipeline({
    onSuccess: (pipeline) => {
      redirect(`/design/pipelines/${pipeline.id}`);
    },
  });

  const [requestDelete] = useDeletePipeline(row.original, {
    onSuccess: () => redirect('/design/pipelines'),
  });

  function handleRenameClick() {
    onRenameClick(row.original.id);
  }

  const handleOnDeploymentSuccess = React.useCallback(
    (deployment: Deployment) => {
      toggleIsDeploying();
      redirect(`/deploy/deployments/${deployment.id}`);
    },
    [toggleIsDeploying, redirect]
  );

  function downloadPipeline() {
    if (!row.original) {
      return;
    }

    downloadFile(
      `lumeo-pipeline_${row.original.name.replaceAll(' ', '_')}.json`,
      new Blob([JSON.stringify(row.original.definition)], {
        type: 'application/json',
      })
    );
  }

  function duplicatePipeline() {
    if (!row.original) {
      return null;
    }

    const { name, definition } = row.original;

    createPipeline({
      name: `${name} copy`,
      definition,
    });
  }

  return (
    <>
      <div className="columns halign-end">
        <ContextMenu
          trigger={
            <IconButton
              icon="more-vertical"
              label="Reveal more actions"
              variant="ghost"
            />
          }
        >
          <ContextMenuAction icon="edit" onClick={handleRenameClick}>
            Rename
          </ContextMenuAction>
          <ContextMenuAction icon="export" onClick={() => downloadPipeline()}>
            Export as JSON
          </ContextMenuAction>
          <ContextMenuAction icon="copy" onClick={() => duplicatePipeline()}>
            Duplicate
          </ContextMenuAction>
          {hasAccess('deploy_edit') && (
            <ContextMenuAction icon="export" onClick={toggleIsDeploying}>
              Deploy
            </ContextMenuAction>
          )}
          <ContextMenuAction icon="info" onClick={() => copy(row.original.id)}>
            Copy ID
          </ContextMenuAction>
          <ContextMenuSeparator />
          <ContextMenuAction
            icon="delete"
            intent="danger"
            onClick={() => requestDelete()}
          >
            Delete
          </ContextMenuAction>
        </ContextMenu>
      </div>

      {hasAccess('deploy_edit') && (
        <Drawer
          title="Deploy Pipeline"
          onClose={toggleIsDeploying}
          containerClassName={deploymentFormDrawerClassName}
          open={isDeploying}
        >
          <NewDeploymentForm
            pipelineId={row.original.id}
            onSuccess={handleOnDeploymentSuccess}
          />
        </Drawer>
      )}
    </>
  );
}
