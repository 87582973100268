import { useDeploymentsVideoSources } from 'hooks/api/useDeploymentsVideoSources';

import { AppLink } from 'components/AppLink/AppLink';
import { Icon } from 'components/Icon/Icon';
import { useDeploymentFormOptional } from 'deployments/components/Form/context';

import { DisplayerProps } from '../controls/input';

export const FIXED_ROTATION_MAP = {
  clockwise90: '90°',
  clockwise180: '180°',
  counter_clockwise90: '270°',
};

export function FixedRotationDisplayer({ node, value }: DisplayerProps) {
  const rotation: keyof typeof FIXED_ROTATION_MAP =
    value || node.data.rotate_fixed_angle;

  if (!rotation) {
    return <span>unset</span>;
  }

  return <strong>{FIXED_ROTATION_MAP[rotation]}</strong>;
}

export function VideoSourceIDDisplayer({ node, value }: DisplayerProps) {
  const { deployment } = useDeploymentFormOptional();
  const { cameras, streams, isLoading } = useDeploymentsVideoSources(
    deployment ? [deployment] : []
  );

  if (!deployment) {
    return null;
  }

  if (isLoading) {
    return <span>Loading...</span>;
  }

  const videoSources = [...cameras, ...streams];

  const sourceID = value || node.data.source_id;
  const source = videoSources.find(({ id }) => id === sourceID);

  if (!source) {
    return <span>Unknown video source</span>;
  }

  let slug = 'cameras';

  if ('stream_type' in source) {
    slug = 'input';
    if (source.stream_type === 'file') {
      slug = 'files';
    }
  }

  return (
    <AppLink className="link" to={`/deploy/${slug}/${source.id}`}>
      <span>{source.name}</span>
      <Icon name="arrow-right" size="small" />
    </AppLink>
  );
}
