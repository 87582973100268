import React from 'react';

import { useApplications } from 'hooks/api/useApplications';
import { useOrganizations } from 'hooks/api/useOrganizations';

/**
 * Get a user's applications grouped by organization.
 * Returns an array with organization ID, name, and a list of applications.
 */
export function useGroupedApplications() {
  const { data: organizations } = useOrganizations();
  const { data: applications } = useApplications();

  return React.useMemo(() => {
    if (!organizations || !applications) {
      return [];
    }

    return organizations
      .map((organization) => ({
        id: organization.id,
        name: organization.name,
        applications: applications
          .filter(
            (application) => application.organization_id === organization.id
          )
          .sort((a, b) => a.name.localeCompare(b.name)),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [organizations, applications]);
}
