import classNames from 'classnames/bind';

import AIModel from 'types/ai_model';

import { useDeleteModel, useUpdateModel } from 'hooks/api/useModel';
import { useAppRedirect } from 'hooks/useAppRedirect';

import * as PageLayout from 'components/PageLayout';
import { IconButton } from 'components/IconButton/IconButton';
import {
  DetailView,
  DetailViewHeader,
  DetailViewStatus,
} from 'components/DetailView/DetailView';
import { Tabs, TabsButton, TabsContent, TabsList } from 'components/Tabs/Tabs';

import {
  ModelFormProvider,
  Step1,
  Step2,
  Step3,
} from 'analytics-library/components/MultiStepForm';
import styles from './ModelDetail.module.scss';

const c = classNames.bind(styles);

export type ModelFieldValues = {
  name: string;
  description?: string;
  capability?: string;
  architecture?: string;
  weights_file?: FileList;
  metadata_file?: FileList | '';
};

export type ModelDetailProps = {
  model: AIModel;
};

export function ModelDetail({ model }: ModelDetailProps) {
  const redirect = useAppRedirect();

  const { mutateAsync, isLoading: isUpdating } = useUpdateModel();
  const [requestDelete, { isLoading: isDeleting }] = useDeleteModel({
    onMutate: () => redirect('/design/models'),
  });

  function handleDeleteClick() {
    requestDelete(model);
  }

  return (
    <DetailView className={c('model-detail')}>
      <DetailViewHeader entity={model}>
        <DetailViewStatus entity={model}>
          <IconButton
            icon="delete"
            label="Delete"
            intent="danger"
            size="small"
            variant="secondary"
            onClick={handleDeleteClick}
            loading={isDeleting}
            showLabel
          />
        </DetailViewStatus>
      </DetailViewHeader>

      <ModelFormProvider
        model={model}
        onSave={mutateAsync}
        isSaving={isUpdating}
      >
        <PageLayout.Container size="small">
          <Tabs defaultValue="type">
            <TabsList>
              <TabsButton value="type">Type &amp; Weights</TabsButton>
              <TabsButton value="parameters">Parameters</TabsButton>

              {model.capability === 'detection' && (
                <TabsButton value="inference-config">
                  Inference parameters
                </TabsButton>
              )}
            </TabsList>
            <TabsContent className={c('tabs-content')} value="type">
              <Step1 />
            </TabsContent>

            <TabsContent className={c('tabs-content')} value="parameters">
              <Step2 />
            </TabsContent>

            {model.capability === 'detection' && (
              <TabsContent
                className={c('tabs-content')}
                value="inference-config"
              >
                <Step3 />
              </TabsContent>
            )}
          </Tabs>
        </PageLayout.Container>
      </ModelFormProvider>
    </DetailView>
  );
}
