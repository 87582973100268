import React from 'react';

import { ContainerSize } from '../components/Container';

export type PageLayoutContextProps = {
  size: ContainerSize;
};

export const PageLayoutContext = React.createContext<
  PageLayoutContextProps | undefined
>(undefined);

export function usePageLayout() {
  const context = React.useContext(PageLayoutContext);

  if (context === undefined) {
    throw new Error(
      'Hook `usePageLayout` may only be used within a <PageLayout.Root />.'
    );
  }

  return context;
}

export function usePageLayoutOptional(): Partial<PageLayoutContextProps> {
  return React.useContext(PageLayoutContext) || {};
}
