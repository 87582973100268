import { useFormContext } from 'react-hook-form';

import { MODEL_FORMAT_OPTIONS } from 'services/model_options';

import { Field } from 'components/Field/Field';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import { Separator } from 'components/Separator/Separator';

import { InputBlobNameInput } from '../inputs/InputBlobNameInput';
import { InputLayerDimensionsInput } from '../inputs/InputLayerDimensionsInput';
import { InputLayerOrderInput } from '../inputs/InputLayerOrderInput';
import { OutputBlobNamesInput } from '../inputs/OutputBlobNamesInput';
import { TAOModelKeyInput } from '../inputs/TAOModelKeyInput';
import { useModelFormState } from '../context/useModelFormState';
import { FIELD_LABEL_WIDTH, Step2FieldValues } from '../Step2';

const CONFIGURABLE_FORMATS = ['caffe', 'etlt', 'onnx', 'onnxlumeoyolo', 'uff'];

export function FormatConfiguration() {
  const { data, model } = useModelFormState();
  const {
    register,
    formState: { errors },
  } = useFormContext<Step2FieldValues>();

  if (!data && !model) {
    return null;
  }

  const format = data?.format || model?.format;
  const formatLabel = MODEL_FORMAT_OPTIONS.find(
    ({ value }) => value === format
  )?.label;

  if (!format || !CONFIGURABLE_FORMATS.includes(format) || !formatLabel) {
    return null;
  }

  const inferenceEngine = (data ?? model)?.inference_config?.inference_engine;

  return (
    <>
      <Separator />

      <Heading level="2">{formatLabel} configuration</Heading>

      {(format === 'etlt' || format === 'uff') && (
        <>
          <InputBlobNameInput />
          <InputLayerOrderInput />
        </>
      )}

      {(format === 'onnx' || format === 'onnxlumeoyolo') && inferenceEngine === 'parallelized' && (
        <Field
          id="infer_layer_name"
          label="Input layer name"
          labelWidth={FIELD_LABEL_WIDTH}
          labelVerticalAlign="flex-start"
          error={errors.inference_config?.input_blob_name}
          required
        >
          <Input
            id="infer_layer_name"
            {...register('inference_config.input_blob_name', {
              required: 'Please enter an input layer name.',
            })}
          />
        </Field>
      )}

      {(format === 'etlt' || format === 'onnx' || format === 'onnxlumeoyolo' || format === 'uff') && (
        <InputLayerDimensionsInput format={format} />
      )}

      {(format === 'caffe' || format === 'etlt' || format === 'onnx' || format === 'onnxlumeoyolo') && (
        <OutputBlobNamesInput />
      )}

      {format === 'etlt' && <TAOModelKeyInput />}
    </>
  );
}
