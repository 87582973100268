import React from 'react';
import classNames from 'classnames/bind';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';

import { postSelfServeSignup } from 'services/api_auth';

import { useHashParams } from 'hooks/useHashParams';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Loader } from 'components/Loader/Loader';
import { LogoMark } from 'components/Logo/LogoMark';
import { Text } from 'components/Text/Text';

import styles from './Auth.module.scss';

const c = classNames.bind(styles);

export type VerifyEmailParams = {
  token: string;
};

const AUTO_REDIRECT_TO_LOGIN_TIMEOUT = 6000;

export function VerifyEmail() {
  const hashParams = useHashParams();
  const navigate = useNavigate();

  const { isLoading, isSuccess, isError } = useQuery(
    ['email-verification'],
    () => {
      if (!hashParams.token) {
        return Promise.reject(new Error(''));
      }

      return postSelfServeSignup('/internal/signup/verify_email', {
        token: hashParams.token,
      });
    },
    {
      enabled: Boolean(hashParams.token),
    }
  );

  React.useEffect(() => {
    if (!isSuccess) {
      return;
    }

    window.setTimeout(() => {
      navigate('/login');
    }, AUTO_REDIRECT_TO_LOGIN_TIMEOUT);
  }, [navigate, isSuccess]);

  if (isLoading) {
    return <Loader text="Verifying email..." />;
  }

  return (
    <div className={c('wrap')}>
      <div className={c('card', 'auth')}>
        <div className="card-content">
          <LogoMark className={c('logo')} aria-label="Lumeo logo." />

          <div className={c('auth-text')}>
            <Heading level="1">Email verification</Heading>

            {!hashParams.token && (
              <div className={c('auth-text-notification')}>
                <InlineNotification intent="danger">
                  This link is invalid.
                </InlineNotification>
              </div>
            )}

            {isSuccess && (
              <div className={c('auth-text-notification')}>
                <InlineNotification intent="success">
                  Successfully verified!
                </InlineNotification>
              </div>
            )}
          </div>

          {!hashParams.token && (
            <ButtonGroup align="center">
              <Button to="register" variant="primary">
                Return to signup
              </Button>
            </ButtonGroup>
          )}

          {isSuccess && (
            <ButtonGroup align="center">
              <Button to="/login" variant="primary">
                Continue to login
              </Button>
            </ButtonGroup>
          )}

          {isError && (
            <FormMessage icon={'warning'} intent="danger">
              This confirmation token has expired.
            </FormMessage>
          )}

          <div className={c('auth-text-secondary')}>
            <Text>
              <Link to="/login" className="link">
                Sign in
              </Link>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
