import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Controller,
  FieldValues,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { deserialize } from 'serializr';
import classNames from 'classnames/bind';

import Gateway from 'types/gateway';
import Stream, { StreamType } from 'types/stream';

import { useCreateStream } from 'streams/hooks/useCreateStream';
import { useGateways } from 'hooks/api/useGateways';
import { useAppRedirect } from 'hooks/useAppRedirect';

import { ApplicationParams } from 'application/views/App/AppContainer';

import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormMessage';
import { Icon } from 'components/Icon/Icon';
import { Radio, RadioOption, RadioSelectOption } from 'components/Radio/Radio';

import { getNameFromStreamURI } from 'streams/services/input_streams';
import { CreateInputStreamsRow } from './CreateInputStreamsRow';
import { CreateInputStreamFieldValues } from './types';
import styles from './CreateInputStreamsDialog.module.scss';

const c = classNames.bind(styles);

export type CreateInputStreamsFormProps = {
  gateway?: Gateway;
  onSuccess?: (stream: Stream) => void;
};

function CreateInputStreamsForm({
  gateway,
  onSuccess,
}: CreateInputStreamsFormProps) {
  const redirect = useAppRedirect();
  const { applicationID } = useParams<ApplicationParams>();
  const {
    mutate: createStream,
    isLoading,
    error,
  } = useCreateStream(['streams'], {
    action: 'link',
    entity: 'streams',
    onSuccess(stream) {
      if (onSuccess) {
        onSuccess(stream);
      } else {
        redirect('/deploy/streams');
      }
    },
  });
  const { control, handleSubmit, watch, register, ...methods } =
    useForm<CreateInputStreamFieldValues>({
      mode: 'onBlur',
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'streams',
  });
  const streams = watch('streams');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...streams[index],
    };
  });

  const { data: gateways } = useGateways();
  const gatewayOptions = gateways?.data.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  // Initialize form with two blank rows
  React.useEffect(() => {
    append({ uri: '', name: '' });
  }, [append]);

  function appendRow() {
    append({ uri: '', name: '' });
  }

  function appendDemos() {
    append([
      {
        uri: 'rtsp://media.lumeo.com/static/mall-parking',
        name: 'mall-parking',
      },
      {
        uri: 'rtsp://media.lumeo.com/static/mall-exit',
        name: 'mall-exit',
      },
      {
        uri: 'rtsp://media.lumeo.com/static/mall-guest-svcs',
        name: 'mall-guest-svcs',
      },
    ]);
  }

  async function create({ stream_type, streams }: FieldValues) {
    for (const { uri, name } of streams) {
      if (!uri || uri.length === 0) {
        continue;
      }

      const stream = deserialize(Stream, {
        application_id: applicationID,
        source: 'uri_stream',
        stream_type: StreamType.RTSP,
        gateway_id: stream_type === 'global' ? undefined : String(stream_type),
        name: name.trim() || getNameFromStreamURI(uri),
        uri,
      });

      createStream(stream);
    }
  }

  return (
    <form onSubmit={handleSubmit(create)}>
      <FormErrorMessage error={error} />

      {gateway ? (
        <input type="hidden" {...register('stream_type')} value={gateway.id} />
      ) : (
        <Field
          label="Access"
          info={
            <>
              Global streams are accessible by any gateway in your workspace.
              <br />
              Select a gateway if the streams are accessible only through a
              specific gateway.
            </>
          }
        >
          <Controller
            name="stream_type"
            defaultValue="global"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Radio
                id="new-input-stream_type"
                onChange={(value) => onChange(String(value))}
                value={value}
              >
                <RadioOption value="global" icon="global">
                  Global
                </RadioOption>
                <RadioSelectOption icon="gateway" options={gatewayOptions}>
                  Gateway
                </RadioSelectOption>
              </Radio>
            )}
          />
        </Field>
      )}

      <FormProvider {...{ control, handleSubmit, watch, register, ...methods }}>
        <Card className={c('streams-card')}>
          {controlledFields.map((field, index) => (
            <CreateInputStreamsRow
              index={index}
              streams={streams}
              onRemove={remove}
              key={field.id}
            />
          ))}

          <div className={c('streams-card-buttons')}>
            <Button variant="tertiary" onClick={appendRow} size="small">
              <Icon name="plus" size="small" />
              <span>Add stream</span>
            </Button>
            <Button variant="tertiary" onClick={appendDemos} size="small">
              <Icon name="plus" size="small" />
              <span>Add demo streams</span>
            </Button>
          </div>
        </Card>
      </FormProvider>

      <div className={c('buttons')}>
        <Button type="submit" variant="primary" loading={isLoading}>
          Save all streams
        </Button>
      </div>
    </form>
  );
}

const Memoized = React.memo(CreateInputStreamsForm);

export { Memoized as CreateInputStreamsForm };
