import React from 'react';
import classNames from 'classnames/bind';

import Gateway from 'types/gateway';

import { CameraTypeInfo } from '../CameraTypeInfo/CameraTypeInfo';
import { Drawer, DrawerProps } from 'components/Drawer/Drawer';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { SeparatorWithLabel } from 'components/SeparatorWithLabel/SeparatorWithLabel';
import { Text } from 'components/Text/Text';

import {
  AddCameraManuallyForm,
  AddCameraManuallyFormProps,
} from '../AddCameraManuallyForm/AddCameraManuallyForm';
import { AddCameraManuallyButton } from './components/AddCameraManuallyButton';
import { DiscoverCameras } from '../DiscoverCameras/DiscoverCameras';
import styles from './AddCameraDialog.module.scss';

const c = classNames.bind(styles);

export type AddCameraDialogProps = DrawerProps & {
  gateway?: Gateway;
  onSuccess?: () => void;
};

export function AddCameraDialog({
  gateway,
  open,
  onClose,
  onSuccess,
}: AddCameraDialogProps) {
  const [isAddingManually, setIsAddingManually] = React.useState(false);
  const [defaultType, setDefaultType] =
    React.useState<AddCameraManuallyFormProps['defaultCameraType']>();

  React.useEffect(() => {
    if (!open) {
      setDefaultType(undefined);
      setIsAddingManually(false);
    }
  }, [open]);

  return (
    <Drawer
      title="Add cameras"
      open={open}
      onClose={onClose}
      classNames={{
        content: c('drawer'),
      }}
    >
      <Text type="paragraph">
        Add cameras to Lumeo to use as a video source for pipelines.
      </Text>
      <ExternalLink href="https://docs.lumeo.com/docs/camera">
        Camera setup guide
      </ExternalLink>
      <CameraTypeInfo />

      {!isAddingManually ? (
        <>
          <div className={c('manual-buttons')}>
            <AddCameraManuallyButton
              iconName="camera"
              headerText="Manually add cameras"
              bodyText="Use ONVIF or USB cameras that are not automatically discovered by the gateway."
              onClick={() => setIsAddingManually(true)}
            />
            <AddCameraManuallyButton
              iconName="virtual-camera"
              headerText="Create virtual camera"
              bodyText="Use this with the Universal Bridge."
              onClick={() => {
                setIsAddingManually(true);
                setDefaultType('virtual');
              }}
            />
          </div>
          <SeparatorWithLabel label="or" />

          <DiscoverCameras gateway={gateway} />
        </>
      ) : (
        <AddCameraManuallyForm
          gateway={gateway}
          defaultCameraType={defaultType}
          onSuccess={() => {
            onClose?.();
            onSuccess?.();
          }}
        />
      )}
    </Drawer>
  );
}
