import React from 'react';
import { UseQueryOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Stream from 'types/stream';
import { LumeoFile } from 'types/file';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useFile } from 'files/hooks/useFile';

import { useTakeSnapshot } from './api/useTakeSnapshot';
import { VideoSource } from './api/useDeploymentsVideoSources';

export type UseSnapshotResponse = {
  file_id: string;
};

export type VideoSourceType = 'camera' | 'stream';

export interface SourcePollingData {
  update_timestamp: number;
  old_snapshot_file_id: string;
}

const pollingInterval = 2000;
const maxPollingTimeout = 30 * 1000;

/**
 * Hook to fetch the cached snapshot for a given video source
 * or take a new snapshot if none is cached.
 *
 * @param source Video source
 * @param gatewayID Optional gateway ID to take the snapshot with
 *
 * @deprecated
 */
export function useSnapshot(
  originalSource?: VideoSource | null,
  gatewayID?: string
) {
  const queryClient = useQueryClient();
  const { applicationID } = useAPI();
  const { data: source } = useSnapshotVideoSource(originalSource);

  const isTakingSnapshot = React.useRef(false);
  const {
    data: snapshot,
    mutate: takeNewSnapshot,
    error: takeSnapshotError,
    reset,
  } = useTakeSnapshot(source, gatewayID, {
    onError() {
      setIsLoading(false);
    },
    onSettled() {
      isTakingSnapshot.current = false;
    },
  });

  const sourceId = source?.id;
  const fileId =
    snapshot?.file_id ?? (source?.snapshot_file_id as LumeoFile['id']);
  const isWebRTC =
    source?.source_type === 'stream'
      ? (source as Stream).isWebRTCStream()
      : false;

  const [isLoading, setIsLoading] = React.useState(Boolean(source));

  // NOTE(dustin) the polling functionality of `useFile` is not needed here anymore
  // this is done by `useVideoSourceForSnapshot` now.
  const { data: snapshotFile, error: snapshotError } = useFile(fileId, {
    refetchInterval: false,
    onSettled() {
      setIsLoading(false);
    },
  });

  const url =
    fileId && !isLoading && snapshotFile?.cloud_status === 'uploaded'
      ? snapshotFile.data_url
      : undefined;
  // URL of previous image to display while new snapshot is not uploaded yet
  const previousUrl = React.useRef(url);

  React.useEffect(() => {
    return () => {
      if (!url) {
        return;
      }

      previousUrl.current = url;
    };
  }, [url]);

  React.useEffect(() => {
    if (
      !source ||
      isWebRTC ||
      source.isOffline ||
      fileId ||
      isTakingSnapshot.current ||
      (takeSnapshotError && takeSnapshotError.status === 400) ||
      (snapshotError && snapshotError.status === 400)
    ) {
      return;
    }

    // only take a snapshot if no polling is currently happening on that source
    const queryKey = [
      `${source.source_type}_polling`,
      source.id,
      applicationID,
    ];
    const pollingData = queryClient.getQueryData<SourcePollingData>(queryKey);

    if (pollingData) {
      return;
    }

    isTakingSnapshot.current = true;
    takeNewSnapshot();
  }, [
    takeSnapshotError,
    snapshotError,
    source,
    fileId,
    isWebRTC,
    takeNewSnapshot,
    queryClient,
    applicationID,
  ]);

  React.useEffect(() => {
    return () => {
      isTakingSnapshot.current = false;
      setIsLoading(true);
      reset();
    };
  }, [sourceId, reset]);

  return {
    file: snapshotFile,
    previousUrl: previousUrl.current,
    url: snapshotFile?.cloud_status === 'uploaded' ? url : previousUrl.current,
    error: snapshotError || takeSnapshotError,
    isLoading,
    isError: Boolean(takeSnapshotError || snapshotError),
    takeNewSnapshot,
  };
}

/** @deprecated */
function useSnapshotVideoSource(
  source?: VideoSource | null,
  { enabled, ...options }: UseQueryOptions<VideoSource, APIError> = {}
) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useAuthenticatedQuery<VideoSource>(
    [source?.source_type, source?.id],
    () => {
      if (!source) {
        return Promise.reject(new Error(`Invalid VideoSource: ${source}`));
      }

      if (source.source_type === 'stream') {
        return api.streams.read(source.id);
      }

      if (source.source_type === 'camera') {
        return api.cameras.read(source.id);
      }

      return Promise.reject(
        new Error(
          `Unsupported VideoSource Type: ${(source as any).source_type}`
        )
      );
    },
    {
      ...options,
      enabled:
        enabled !== undefined ? enabled && Boolean(source) : Boolean(source),
      refetchInterval(source) {
        if (!source) {
          return false;
        }

        // read polling data that is set by useTakeSnapshot
        const queryKey = [
          `${source.source_type}_polling`,
          source.id,
          api.applicationID,
        ];
        const pollingData =
          queryClient.getQueryData<SourcePollingData>(queryKey);
        const now = Date.now();

        if (pollingData === undefined) {
          return false;
        }

        const passedTime = now - pollingData.update_timestamp;

        if (
          passedTime > maxPollingTimeout ||
          source.snapshot_file_id !== pollingData.old_snapshot_file_id
        ) {
          queryClient.removeQueries(queryKey);
          return false;
        }

        return pollingInterval;
      },
    }
  );
}
