import React from 'react';
import classNames from 'classnames/bind';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import styles from './Radio.module.scss';

const c = classNames.bind(styles);

export type RadioOptionProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'id' | 'name'
> & {
  id: string;
  name: string;
};

/**
 * Styled wrapper of `<input type="radio" />`.
 */
export function RadioButton({ id, ...props }: RadioOptionProps) {
  return (
    <div className={c('button')}>
      <VisuallyHidden asChild>
        <input {...props} id={id} type="radio" />
      </VisuallyHidden>
      <label htmlFor={id} className={c('faux-button')} />
    </div>
  );
}
