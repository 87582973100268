import { Button } from 'components/Button/Button';
import { Heading } from 'components/Heading/Heading';
import { Logo } from 'components/Logo/Logo';

export function AppNotFound() {
  return (
    <div className="error-screen error-message">
      <div className="error-message-content">
        <Logo width="160" />
        <Heading level="1">Workspace not found</Heading>
        <p>
          We could not find an workspace with this ID linked to your account.
        </p>
        <Button to="/">Back to overview</Button>
      </div>
    </div>
  );
}
