import React from 'react';
import classNames from 'classnames/bind';
import { RenderParams, NodeModel } from '@minoru/react-dnd-treeview';
import { useNavigate, useLocation } from 'react-router-dom';

import { Tag } from 'types/tag';

import styles from './TagsTree.module.scss';

const c = classNames.bind(styles);

export type TagsTreeStaticItemProps = RenderParams & {
  className?: string;
  node: NodeModel<Tag>;
};

function isStaticLinkActive(id: string | number, search: string) {
  const searchParams = new URLSearchParams(search);

  if (id === 'none') {
    return searchParams.get('unlabeled');
  }

  if (id === 'all') {
    return !searchParams.has('tag') && !searchParams.has('unlabeled');
  }

  return false;
}

export function TagsTreeStaticItem({
  className,
  node: { id, text },
}: TagsTreeStaticItemProps) {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  // Override click handler for ?tag=id link to prevent page reload
  // and keep all other functionalities of the link (copy link, open in new tab)
  function handleLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();

    switch (id) {
      case 'all':
        navigate({ search: undefined });
        break;
      case 'none':
        navigate({ search: '?unlabeled=true' });
    }
  }

  return (
    <div
      className={c(className, 'item', 'interactive', {
        active: isStaticLinkActive(id, search),
      })}
    >
      <a
        href={id === 'all' ? pathname : `?unlabeled=true`}
        className={c('item-link')}
        onClick={handleLinkClick}
      >
        {text}
      </a>
    </div>
  );
}
