import { useNavigate } from 'react-router-dom';

import Camera from 'types/camera';

import { useDeleteCamera } from 'cameras/hooks/useDeleteCamera';
import { useHasAccess } from 'hooks/useHasAccess';
import { useMqttCamerasState } from 'cameras/hooks/useMqttCamerasState';

import { IconButton } from 'components/IconButton/IconButton';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';

export type CameraDetailStatusProps = {
  camera: Camera;
};

export function CameraDetailStatus({ camera }: CameraDetailStatusProps) {
  const navigate = useNavigate();
  const [hasAccess] = useHasAccess();

  useMqttCamerasState([camera.id]);

  const [requestDelete, { isLoading }] = useDeleteCamera(camera, {
    onSuccess() {
      navigate('./..');
    },
  });

  function handleDeleteClick() {
    requestDelete();
  }

  return (
    <>
      <StatusIndicator status={camera.status} />
      {hasAccess('deploy_edit') && (
        <IconButton
          icon="delete"
          label="Delete"
          onClick={handleDeleteClick}
          intent="danger"
          loading={isLoading}
          size="small"
          variant="secondary"
          showLabel
        />
      )}
    </>
  );
}
