import { serializable } from 'serializr';

import { Timestamped } from './timestamp';

export default class Organization extends Timestamped {
  @serializable id: string;
  @serializable name: string;
  @serializable account_id: string;
  @serializable is_suspended: boolean;

  constructor(
    id: string,
    name: string,
    accountId: string,
    isSuspended: boolean,
    createdAt?: string,
    updatedAt?: string
  ) {
    super(createdAt, updatedAt);
    this.id = id;
    this.name = name;
    this.account_id = accountId;
    this.is_suspended = isSuspended;
  }

  copy() {
    return new Organization(
      this.id,
      this.name,
      this.account_id,
      this.is_suspended,
      this.created_at,
      this.updated_at
    );
  }
}
