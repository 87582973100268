import { UseQueryOptions } from '@tanstack/react-query';

import AIModel from 'types/ai_model';
import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';

import { useAuthenticatedQuery } from './useAuthenticatedQuery';


export function useModels(options?: UseQueryOptions<AIModel[], APIError>) {
  const api = useAPI();

  return useAuthenticatedQuery<AIModel[]>(
    ['models', api.applicationID],
    api.models.list,
    { ...options, staleTime: Infinity }
  );
}

export function useMarketplaceModels(
  options?: Omit<UseQueryOptions<AIModel[], APIError>, 'select'>
) {
  const api = useAPI();

  return useAuthenticatedQuery<AIModel[]>(
    ['marketplace-models'],
    api.models.marketplace.list,
    {
      ...options,
      select: (marketplaceModels) =>
        marketplaceModels.map((model) => {
          model.source = 'marketplace';
          return model;
        }),
      staleTime: Infinity,
    }
  );
}
