import { NodeEditor } from 'rete';
import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import {
  NumberInput,
  NumberInputProps,
} from 'components/NumberInput/NumberInput';

export default class ReteOutputControl extends ReteInput<
  ReteInputProps & Partial<NumberInputProps>
> {
  constructor(
    editor: NodeEditor,
    key: string,
    params: Omit<ReteInputParams, 'type'>
  ) {
    super(editor, key, {
      type: 'number',
      ...params,
    });
  }

  get component() {
    return ReteOutputControlComponent as any;
  }
}

function ReteOutputControlComponent({ onChange, ...props }: NumberInputProps) {
  return (
    <NumberInput
      {...props}
      min={1}
      onChange={(number) => {
        if (number) {
          onChange?.(number);
        } else {
          onChange?.(1);
        }
      }}
    />
  );
}
