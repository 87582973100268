import { createModelSchema, primitive } from 'serializr';

import { EntityID } from 'types/entity';

export interface IPipelineLock {
  /** ID of the user who currently possesses the lock */
  account_id: EntityID;
  /** ISO date string */
  ts: string;
}

export default class PipelineLock implements IPipelineLock {
  account_id;
  ts;

  constructor(accountID: EntityID) {
    this.account_id = accountID;
    this.ts = new Date().toISOString();
  }
}

createModelSchema(PipelineLock, {
  account_id: primitive(),
  ts: primitive(),
});
