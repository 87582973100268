import { useMemo } from 'react';
import { Node } from 'rete';

import { getPropertyValue } from 'types/node';

type NodeEditorValues = {
  [key: string]: any;
};

export function useDefaultValues(
  node: Node,
  controls: any[]
): NodeEditorValues | undefined {
  return useMemo<NodeEditorValues>(() => {
    const values: NodeEditorValues = {};

    // First get the initial value
    controls.forEach((control) => {
      const definitionValue = getPropertyValue(node.data, control.key);
      values[control.key] =
        definitionValue === undefined ? control.initial : definitionValue;
    });

    // Then fix the ROI values
    controls.forEach((control) => {
      if (control.type === 'line' || control.type === 'polygon') {
        const merged = {
          coordinates: values[control.key],
          labels: values[control.keyref],
        };

        values[control.key] = merged;
      }

      if (control.type === 'multiline') {
        const labels = control.keyref ? values[control.keyref] : undefined;
        const coordinates = values[control.key];
        values[control.key] = { coordinates, labels };
      }
    });

    return values;
  }, [node.data, controls]);
}
