import React from 'react';
import classnames from 'classnames/bind';

import { AttributeListItem } from './AttributeListItem';
import styles from './AttributeList.module.scss';

const c = classnames.bind(styles);

export type AttributeListProps = React.PropsWithChildren<{
  className?: string;
}>;

export function AttributeList({ className, children }: AttributeListProps) {
  const content = Array.isArray(children)
    ? React.Children.map(
        children,
        (child) =>
          child &&
          React.cloneElement(child as React.ReactElement, {
            style: { maxWidth: `${100 / (children?.length || 1)}%` },
          })
      )
    : children;

  if (!children) {
    return null;
  }

  return <dl className={c(className, 'attribute-list')}>{content}</dl>;
}

export { AttributeListItem };
