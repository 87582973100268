import { useQueryClient } from '@tanstack/react-query';

import Gateway, { GatewayState, isGatewayState } from 'types/gateway';
import { OffsetPaginated } from 'types/pagination';

import {
  OnMessagePayload,
  SubscriptionMap,
  useMqttSubscribe,
} from 'hooks/useMqttSubscribe';
import { isNotNullOrUndefined } from 'services/nullable';
import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';

type MqttGatewayState = {
  status: GatewayState;
  version?: string;
};

export function useMqttGatewaysState(ids: Gateway['id'][]) {
  const queryClient = useQueryClient();
  const { applicationID } = useAPI();

  const invalidate = useDebouncedCallback(
    (gatewayId: Gateway['id']) => {
      queryClient.invalidateQueries(['gateways']);
      queryClient.invalidateQueries(['gateway', gatewayId]);
    },
    [queryClient]
  );

  async function onMessage({ topic, message }: OnMessagePayload) {
    const [, , , gatewayId] = topic.split('/');
    let mqttState;

    try {
      mqttState = JSON.parse(message);
    } catch (error) {
      console.error('Failed to parse mqtt message.');
    }

    if (!isMqttGatewayState(mqttState)) {
      return;
    }

    let isStaleListQuery = false;
    let isStaleSingleQuery = false;

    const listQueries = queryClient.getQueriesData<OffsetPaginated<Gateway>>([
      'gateways',
    ]);

    const singleQueries = queryClient.getQueriesData<Gateway>([
      'gateway',
      gatewayId,
    ]);

    if (listQueries.length) {
      const listQueryStates = listQueries
        .map(([queryKey]) =>
          queryClient.getQueryState<OffsetPaginated<Gateway>>(queryKey)
        )
        .filter(isNotNullOrUndefined);

      if (listQueryStates.length) {
        const { data: latestGateways } = listQueryStates.reduce(
          (latest, current) =>
            latest.dataUpdatedAt > current.dataUpdatedAt ? latest : current
        );

        if (!latestGateways || !latestGateways.data) return;

        const latestGateway = latestGateways.data.find(
          ({ id }) => id === gatewayId
        );

        if (!latestGateway) return;

        isStaleListQuery =
          latestGateway.status !== mqttState.status &&
          shouldUpdateGatewayState(latestGateway.status, mqttState.status);
      }
    }

    if (singleQueries.length) {
      const singleQueryStates = singleQueries
        .map(([queryKey]) => queryClient.getQueryState<Gateway>(queryKey))
        .filter(isNotNullOrUndefined);

      if (singleQueryStates.length) {
        const { data: latestGateway } = singleQueryStates.reduce(
          (latest, current) =>
            latest.dataUpdatedAt > current.dataUpdatedAt ? latest : current
        );

        if (!latestGateway) return;

        isStaleSingleQuery =
          latestGateway.status !== mqttState.status &&
          shouldUpdateGatewayState(latestGateway.status, mqttState.status);
      }
    }

    if (isStaleListQuery || isStaleSingleQuery) {
      await queryClient.cancelQueries(['gateways']);
      await queryClient.cancelQueries(['gateway', gatewayId]);

      updateQueriesData<Gateway>({
        queryClient,
        listQueryKey: ['gateways'],
        singleQueryKey: ['gateway'],
        ids: [gatewayId],
        updateData(gateway) {
          const updatedGateway = gateway.copy();
          updatedGateway.status = mqttState.status;

          if (mqttState.version) {
            updatedGateway.version = mqttState.version;
          }

          return updatedGateway;
        },
      });

      invalidate(gatewayId);
    }
  }

  const subscriptions: SubscriptionMap = {};

  if (applicationID) {
    for (const id of ids) {
      const topic = `apps/${applicationID}/gateways/${id}/internal/status`;
      subscriptions[topic] = { onMessage };
    }
  }

  useMqttSubscribe(subscriptions);
}

function isMqttGatewayState(state: unknown): state is MqttGatewayState {
  return (
    state !== null &&
    typeof state === 'object' &&
    'status' in state &&
    typeof state.status === 'string' &&
    isGatewayState(state.status)
  );
}

// The gateway topic .../internal/status has retain option enabled and only reports 'online' or 'offline' state.
// If the topic is subscribed during 'pending', 'starting' or 'stopping' state we still receive the retained 'online' or 'offline' state.
//
// Workaround: We only accept 'online' state during 'pending' & 'starting' and only accept 'offline' during 'stopping'
// to prevent incorrectly overriding the cloud gateway states.
function shouldUpdateGatewayState(
  currentGatewayState: GatewayState,
  mqttState: GatewayState
) {
  switch (currentGatewayState) {
    case 'offline':
      return mqttState === 'online';
    case 'online':
      return mqttState === 'offline';
    case 'pending':
      return mqttState === 'online';
    case 'starting':
      return mqttState === 'online';
    case 'stopping':
      return mqttState === 'offline';
  }
}
