import React from 'react';

import Deployment from 'types/deployment';

import { useGateways } from 'hooks/api/useGateways';

export function useDeploymentsGateways(deployments?: Deployment[]) {
  const gatewayIds = React.useMemo(
    () => [
      ...new Set(
        deployments?.map(({ gateway_id }) => gateway_id).filter(Boolean)
      ),
    ],
    [deployments]
  );

  return useGateways(
    {
      gateway_ids: gatewayIds,
      limit: gatewayIds?.length,
    },
    { enabled: Boolean(gatewayIds && gatewayIds.length > 0) }
  );
}
