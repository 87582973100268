import React from 'react';
import classnames from 'classnames/bind';

import { Button, ButtonProps } from 'components/Button/Button';
import { Icon, IconType } from 'components/Icon/Icon';

import { OptionalTooltip } from 'components/Tooltip/Tooltip';

import styles from './IconButton.module.scss';

const c = classnames.bind(styles);

export type IconButtonProps = Omit<ButtonProps, 'size'> & {
  icon: IconType;
  label: string;
  showLabel?: boolean;
  size?: 'small' | 'xsmall';
};

function IconButton(
  {
    icon,
    label,
    showLabel,
    variant = 'ghost',
    intent,
    loading,
    size,
    className,
    disabled,
    disabledTooltip,
    ...buttonProps
  }: IconButtonProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <OptionalTooltip
      content={disabled ? disabledTooltip : label}
      showTooltip={(disabled && Boolean(disabledTooltip)) || !showLabel}
      delayDuration={700}
      className={c({ [className || '']: !showLabel })}
    >
      <div
        className={c('icon-button-wrap', {
          [className || '']: showLabel,
          'has-label': showLabel,
          loading,
          disabled,
        })}
      >
        <Button
          {...(buttonProps as ButtonProps)}
          className={c('icon-button', variant, intent, size)}
          variant={variant}
          intent={intent}
          disabled={disabled}
          ref={ref}
          aria-label={label}
        >
          <span className={c('icon-button-content')}>
            <Icon name={icon} size={size} />
          </span>
        </Button>

        {showLabel && (
          <span className={c('icon-button-label')} aria-hidden="true">
            {label}
          </span>
        )}

        {loading && (
          <span className={c('loader')}>
            <span className="loading-spinner" />
          </span>
        )}
      </div>
    </OptionalTooltip>
  );
}

const ForwardRef = React.forwardRef<HTMLElement, IconButtonProps>(IconButton);

export { ForwardRef as IconButton };
