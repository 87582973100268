import classnames from 'classnames/bind';
import { ClearIndicatorProps } from 'react-select';

import { IconButton } from 'components/IconButton/IconButton';

import styles from '../Select.module.scss';

const c = classnames.bind(styles);

export function ClearIndicator<
  OptionType,
  MultiSelect extends boolean = false
>({ innerProps, clearValue }: ClearIndicatorProps<OptionType, MultiSelect>) {
  return (
    <div {...innerProps}>
      <IconButton
        icon="cancel"
        label="Clear"
        size="small"
        className={c('indicator')}
        onClick={clearValue}
      />
    </div>
  );
}
