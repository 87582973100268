import React from 'react';
import classnames from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { capitalizeFirst, truncateMiddle } from 'services/string';

import Camera from 'types/camera';
import { NavRoute } from 'application/types/routes';

import { VideoSource } from 'hooks/api/useDeploymentsVideoSources';
import { useModal } from 'hooks/useModal';
import { useSnapshot } from 'hooks/useSnapshot';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { IconButton } from 'components/IconButton/IconButton';
import { OptionalTooltip } from 'components/Tooltip/Tooltip';
import { Text } from 'components/Text/Text';

import { MediaPreview } from 'components/MediaPreview/MediaPreview';
import Stream from 'types/stream';
import styles from './VideoSourceSnapshot.module.scss';

const c = classnames.bind(styles);

export type VideoSourceSnapshotProps = {
  className?: string;
  source?: VideoSource | null;
  /** this is only used to determine if this source is from a virtual camera */
  camera?: Camera | null;
  compact?: boolean;
  hideTitle?: boolean;
  autoRefresh?: boolean;
};

/** @deprecated */
export function VideoSourceSnapshot({
  className,
  source,
  camera,
  compact,
  hideTitle,
  autoRefresh,
}: VideoSourceSnapshotProps) {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const queryClient = useQueryClient();
  const timeoutRef = React.useRef<number>();

  const { pathname } = useLocation();
  const { open } = useModal();

  const { url, previousUrl, file, takeNewSnapshot, isLoading, error } =
    useSnapshot(source);
  const date = file
    ? Intl.DateTimeFormat('en-us', {
        dateStyle: 'medium',
        timeStyle: 'medium',
      }).format(new Date(file.created_at))
    : undefined;

  const isVirtualCamera = camera?.conn_type === 'virtual';

  const isStreamSource = source instanceof Stream;

  const previousImage = React.useMemo(
    () => <img className={c('image')} src={previousUrl} />,
    [previousUrl]
  );

  function handleTakeNewSnapshotClick() {
    if (isVirtualCamera) {
      // Creating a new snapshot from the same source (file stream) does not
      // make sense for virtual cameras as the file never changes its content.
      // For virtual cameras new file streams are added that needs to be refetched
      queryClient.invalidateQueries(['streams']);
    } else {
      setIsLoaded(false);
      takeNewSnapshot();
    }
  }

  React.useEffect(() => {
    if (autoRefresh && source && source.isOnline && source.canTakeSnapshot) {
      // then setup a refresh every 30 seconds
      timeoutRef.current = window.setInterval(() => {
        takeNewSnapshot();
      }, 30000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [autoRefresh, source, takeNewSnapshot]);

  function renderPreview() {
    if (!source) {
      return (
        <>
          <div className={c('image', 'placeholder')} />
          <div className={c('image-info', 'info')}>
            <Text>No files or streams available to create a snapshot.</Text>
            <Button size="small" onClick={handleTakeNewSnapshotClick}>
              Try again
            </Button>
          </div>
        </>
      );
    }

    if (source.isOffline) {
      return (
        <>
          <div className={c('image', 'placeholder')} />
          <Text className={c('image-info', 'info')}>
            {capitalizeFirst(source.source_type)} is unavailable.
          </Text>
        </>
      );
    }

    if (error) {
      return (
        <>
          <div className={c('image', 'placeholder')} />
          <Text className={c('image-info', 'info')}>
            {error.message ||
              'An unknown error occurred. Please try again later.'}
          </Text>
        </>
      );
    }

    if (!url || file?.cloud_status !== 'uploaded') {
      return (
        <>
          {!compact && (
            <div className="loading-spinner-wrap">
              <span className="loading-spinner small" />
            </div>
          )}
          <div className={c('image-container')}>
            {isStreamSource && source.isStreamable && (
              <MediaPreview className={c('snapshot-overlay')} stream={source} />
            )}
            <div className={c('image-wrap', 'placeholder')}>
              {previousUrl ? previousImage : <div className={c('image')} />}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        {!isLoaded && !compact && (
          <div className="loading-spinner-wrap">
            <span className="loading-spinner small" />
          </div>
        )}
        <div className={c('image-container')}>
          {isStreamSource && source.isStreamable && (
            <MediaPreview className={c('snapshot-overlay')} stream={source} />
          )}
          <div
            className={c('image-wrap', {
              placeholder: !isLoaded || !file || !url || !!true,
            })}
          >
            <img
              className={c('image')}
              src={url}
              alt={`${source.name} snapshot from ${date}`}
              onLoad={() => setIsLoaded(true)}
            />
          </div>
        </div>
      </>
    );
  }

  const isFileSearchEnabled =
    pathname.includes(NavRoute.MONITOR) &&
    source &&
    isStreamSource &&
    // We don't offer file search drawer for pipeline output streams because files are associated with the input stream
    source.source !== 'pipeline_stream';

  return (
    <figure className={c('wrap', className)}>
      <div className={c('image-wrap')}>{renderPreview()}</div>

      {!compact && (
        <div className={c('snapshot-info')}>
          <div className={c('snapshot-text')}>
            {!hideTitle && source && (
              <OptionalTooltip
                content={source.name}
                showTooltip={source.name ? source.name.length > 50 : false}
              >
                <span>{truncateMiddle(source.name ?? 'Unknown', 50)}</span>
              </OptionalTooltip>
            )}
            <Text>{file && `Snapshot from ${date}`}</Text>
          </div>
          <div className={c('snapshot-buttons')}>
            <ButtonGroup align="end">
              <>
                {isFileSearchEnabled && (
                  <IconButton
                    onClick={() =>
                      open('files-search', {
                        defaultStreamId: source.id,
                      })
                    }
                    label="Search recordings"
                    icon="search"
                    variant="tertiary"
                    size="small"
                  />
                )}
                {((source?.isOnline && source?.canTakeSnapshot) ||
                  isVirtualCamera) && (
                  <IconButton
                    onClick={handleTakeNewSnapshotClick}
                    label="Take a new snapshot"
                    icon="take-snapshot"
                    variant="tertiary"
                    disabled={isLoading || !source}
                    size="small"
                  />
                )}
                {file && url && (
                  <IconButton
                    href={url}
                    label="Download file"
                    icon="download"
                    variant="tertiary"
                    size="small"
                  />
                )}
              </>
            </ButtonGroup>
          </div>
        </div>
      )}
    </figure>
  );
}
