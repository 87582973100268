import type { Condition } from 'pipelines/services/condition_parser';
import { isNotNullOrUndefined } from 'services/nullable';

export function updateNestedCondition(
  condition: Condition,
  path: number[],
  updateFn: (condition: Condition) => Condition | undefined
): Condition | undefined {
  if (path.length === 0) {
    return updateFn(condition);
  }

  const [index, ...restPath] = path;
  const logicalOperator = condition.and ? 'and' : 'or';
  const updatedChildren = condition[logicalOperator]
    ?.map((child, i) =>
      i === index ? updateNestedCondition(child, restPath, updateFn) : child
    )
    .filter(isNotNullOrUndefined);

  if (!updatedChildren || updatedChildren.length <= 0) {
    const { [logicalOperator]: removedChildren, ...restCondition } = condition;
    return restCondition;
  }

  return {
    ...condition,
    [logicalOperator]: updatedChildren,
  };
}
