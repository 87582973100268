import classNames from 'classnames/bind';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useToggle } from '@mantine/hooks';

import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

import * as PageLayout from 'components/PageLayout';
import { AppLink } from 'components/AppLink/AppLink';
import { Card } from 'components/Card/Card';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { LinkFilesDialog } from 'files/components/LinkFilesDialog';
import { Text } from 'components/Text/Text';

import { UploadFiles } from './UploadFiles';
import styles from './CreateFilesView.module.scss';

const c = classNames.bind(styles);

export function CreateFilesView() {
  const { data: organization } = useCurrentOrganization();

  const [isLinkingFiles, toggleIsLinkingFiles] = useToggle();

  return (
    <PageLayout.Root size="small">
      <PageLayout.Header>
        <div>
          <Text>
            <AppLink to="/deploy/files">
              <Icon name="file" size="small" />
              <span>Files</span>
            </AppLink>
          </Text>
          <PageLayout.Title>Add files to Lumeo</PageLayout.Title>
          <Text>
            Bring your own files to Lumeo to use as a video source in your
            deployments.
          </Text>
          <Text>
            When using files as a deployment source, they are processed as fast
            as possible and are not restarted when processing is complete.
          </Text>

          {organization?.is_suspended && (
            <FormMessage intent="warning" icon="warning">
              <Text>
                <em>{organization?.name} is suspended.</em>
                <br />
                Please contact support to upload files.
              </Text>
            </FormMessage>
          )}
        </div>
      </PageLayout.Header>
      <PageLayout.Content>
        <Card onClick={toggleIsLinkingFiles} size="small">
          <div className={c('link-files-button')}>
            <Icon name="link" size="medium" />
            <div>
              <Heading level="2">Link externally hosted files</Heading>
              <Text>
                Use files hosted on your webserver or cloud storage as a video
                source.
              </Text>
            </div>
          </div>
        </Card>
        <LinkFilesDialog
          open={isLinkingFiles}
          onClose={() => toggleIsLinkingFiles(false)}
        />

        <Text align="center" className={c('or')}>
          or
        </Text>

        <VisuallyHidden>
          <Heading level="2">Upload files</Heading>
        </VisuallyHidden>
        <UploadFiles />
      </PageLayout.Content>
    </PageLayout.Root>
  );
}
