import React from 'react';

import Gateway from 'types/gateway';
import { VideoSource } from 'hooks/api/useVideoSources';
import { RapidDeployVideoSourcesReducerParams } from '../RapidDeploy';

export type RapidDeployContextProps = {
  gateway: Gateway | undefined;
  setGateway: (gateway: Gateway) => void;

  videoSources: VideoSource[];
  dispatchVideoSources: React.Dispatch<RapidDeployVideoSourcesReducerParams>;
};

export const RapidDeployContext = React.createContext<
  RapidDeployContextProps | undefined
>(undefined);

export function useRapidDeployState() {
  const context = React.useContext(RapidDeployContext);

  if (context === undefined) {
    throw new Error(
      'Hook `useRapidDeployState` may only be used within <RapidDeploy />.'
    );
  }

  return context;
}
