import React from 'react';
import classNames from 'classnames/bind';
import { Column } from 'react-table';

import LumeodMetric from 'types/metric';

import { Table } from 'components/Table/Table';

import { MetricsConfigTableSummary } from './ConfigTableSummary';
import { MetricsConfigTableAttributes } from './ConfigTableAttributes';
import styles from '../Settings.module.scss';

const c = classNames.bind(styles);

// TODO: prop via query hook
const data: LumeodMetric[] = [
  new LumeodMetric(
    'lumeo.deployment.run_time',
    'Total run time from restart',
    'ms',
    [
      { name: 'gateway_id' },
      { name: 'deployment_id' },
      { name: 'pipeline_id' },
    ],
    'Counter'
  ),
  new LumeodMetric(
    'lumeo.deployment.input_frames',
    'Frames produced by all input nodes',
    'frames',
    [
      { name: 'gateway_id' },
      { name: 'deployment_id' },
      { name: 'pipeline_id' },
    ],
    'Counter'
  ),
  new LumeodMetric(
    'lumeo.deployment.process.cpu.utilization',
    'CPU utilization by deployment process',
    'number',
    [
      { name: 'gateway_id' },
      { name: 'deployment_id' },
      { name: 'pipeline_id' },
      { name: 'state', info: 'system | user | wait' },
    ],
    'Counter'
  ),
];

export function MetricsConfigTable() {
  const columns = React.useMemo<Column<LumeodMetric>[]>(
    () => [
      {
        Header: 'Metrics',
        Cell: MetricsConfigTableSummary,
      },
      {
        Header: 'Unit',
        accessor: ({ unit }) => unit,
      },
      {
        Header: 'Attributes',
        Cell: MetricsConfigTableAttributes,
      },
      {
        Header: 'Type',
        accessor: ({ type }) => type,
      },
    ],
    []
  );

  return (
    <div className={c('config-table')}>
      <Table
        id="metrics-configuration"
        label="Metrics configuration"
        columns={columns}
        data={data}
      />
    </div>
  );
}
