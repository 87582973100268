import { AppEntityObject } from 'hooks/useEntities';
import { useMarketplaceModels, useModels } from 'hooks/api/useModels';

import { Select } from 'components/Select/Select';

function modelToOption({ id, name }: AppEntityObject) {
  return { value: id, label: name };
}

export type ModelDropdownProps = {
  id?: string;
  value?: string | null;
  disabled?: boolean;
  onChange?: (value: string | null | undefined) => void;
};

export default function ModelDropdown({
  id,
  value,
  disabled,
  onChange,
}: ModelDropdownProps) {
  const { data: models } = useModels();
  const { data: marketplaceModels } = useMarketplaceModels();

  const groups = [
    {
      options: [{ value: '', label: 'None' }],
    },
    {
      label: 'Your AI models',
      options: models?.map(modelToOption) ?? [],
    },
    {
      label: 'Ready-to-use models',
      options: marketplaceModels?.map(modelToOption) ?? [],
    },
  ];

  // Compute the selected label (needed for react-select)
  const options = groups.map((group) => group.options).flat();
  const selectedOption = options.find((option) => option.value === value);
  const selectedLabel = selectedOption ? selectedOption.label : '';

  return (
    <Select
      id={id}
      options={groups}
      defaultValue={{ value, label: selectedLabel }}
      onChange={(selection) => {
        onChange?.(selection?.value);
      }}
      menuPosition="absolute"
      isDisabled={disabled}
      maxMenuHeight={250}
    />
  );
}
