import React from 'react';
import classNames from 'classnames/bind';

import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './Abbr.module.scss';

const c = classNames.bind(styles);

export type AbbrProps = React.PropsWithChildren<{
  info: string;
}>;

export function Abbr({ children, info }: AbbrProps) {
  return (
    <Tooltip content={info}>
      <span className={c('abbr')}>{children}</span>
    </Tooltip>
  );
}
