import React from 'react';
import classNames from 'classnames/bind';
import { Link, Location, Navigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import APIError from 'types/api_error';

import { API_BASE_URL } from 'services/api';
import {
  AuthenticationRedirect,
  getAuthenticationRedirect,
  postSelfServeSignup,
} from 'services/api_auth';
import { useAuthentication } from 'hooks/api/useAuthentication';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Input } from 'components/Input/Input';
import { Logo } from 'components/Logo/Logo';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { Text } from 'components/Text/Text';
import * as List from 'components/List';

import styles from './Register.module.scss';

const c = classNames.bind(styles);

type EmailFieldValues = {
  email: string;
};

type PasswordFieldValues = {
  password: string;
  hasAcceptedTermsOfService: boolean;
};

export type RegisterParams = {
  email: string;
  password: String;
  name: string;
  organization_name: string;
};

export type ResendEmailParams = {
  email: string;
};

export type RegisterLocation = {
  unverifiedEmailFromLogin?: string;
};

export function Register() {
  const { isAuthenticated } = useAuthentication();
  const { state } = useLocation() as Location<RegisterLocation>;

  const {
    formState: emailFormState,
    getValues: getEmailForm,
    handleSubmit: submitEmailForm,
    register: registerEmailForm,
    reset: resetEmailForm,
    watch: watchEmailForm,
  } = useForm<EmailFieldValues>();

  const {
    formState: passwordFormState,
    handleSubmit: submitPasswordForm,
    register: registerPasswordForm,
    reset: resetPasswordForm,
  } = useForm<PasswordFieldValues>();

  const [email, setEmail] = React.useState(state?.unverifiedEmailFromLogin);

  const [authenticationRedirect, setAuthenticationRedirect] =
    React.useState<AuthenticationRedirect>();
  const [isLoadingAuthenticationRedirect, setIsLoadingAuthenticationRedirect] =
    React.useState(false);
  const [authenticationRedirectError, setAuthenticationRedirectError] =
    React.useState<APIError>();

  const {
    isLoading: isRegistering,
    isSuccess: didRegister,
    error: registerError,
    mutate: registerUser,
    reset,
  } = useMutation(
    (params: RegisterParams) => postSelfServeSignup('/internal/signup', params),
    {
      onError: () => {
        setEmail(undefined);
      },
    }
  );

  const {
    isLoading: isResending,
    isSuccess: didResend,
    error: resendError,
    mutate: resendEmail,
  } = useMutation((params: ResendEmailParams) =>
    postSelfServeSignup('/internal/signup/resend_verification', params)
  );

  function requestAuthenticationRedirect({ email }: EmailFieldValues) {
    setIsLoadingAuthenticationRedirect(true);

    getAuthenticationRedirect(email)
      .then((res) => setAuthenticationRedirect(res))
      .catch(() =>
        setAuthenticationRedirectError({
          code: 'server-error',
          message: 'We are unable to process your request right now.',
          status: 500,
        })
      )
      .finally(() => setIsLoadingAuthenticationRedirect(false));
  }

  const termsOfServiceId = `terms-of-service`;

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  function signUp({ password }: PasswordFieldValues) {
    const email = getEmailForm('email');

    registerUser({
      email,
      password,
      name: email,
      organization_name: `${email}'s organization`,
    });
    setEmail(email);
  }

  function resendVerificationEmail() {
    if (!email) {
      return;
    }

    resendEmail({ email });
  }

  function changeRegisterEmail() {
    reset();
    // Reset isSubmitted state but keep values
    resetPasswordForm((values) => ({ ...values }));
    setAuthenticationRedirect(undefined);
    setAuthenticationRedirectError(undefined);
  }

  function resetSignUp() {
    reset();
    resetEmailForm();
    resetPasswordForm();
    setEmail(undefined);
    setAuthenticationRedirect(undefined);
    setAuthenticationRedirectError(undefined);
  }

  if (didRegister || (email && !isRegistering)) {
    return (
      <main className={c('wrap', 'content')}>
        <div className={c('container', 'register-success')}>
          <Logo className={c('logo')} />
          <Heading level="1">Please check your inbox.</Heading>
          <Text size="large">We've sent a confirmation email to {email}.</Text>

          <FormErrorMessage error={resendError as APIError} />

          <ButtonGroup>
            <Button variant="primary" to="/login">
              Continue to login
            </Button>
            <Button onClick={resendVerificationEmail} loading={isResending}>
              Resend Email
            </Button>
            {didResend && (
              <InlineNotification intent="success">Sent!</InlineNotification>
            )}
          </ButtonGroup>

          <br />

          <p className={c('link-group')}>
            <Button variant="link" onClick={resetSignUp}>
              Back to registration
            </Button>
          </p>
        </div>
      </main>
    );
  }

  if (authenticationRedirect?.type === 'saml_sso_redirect') {
    window.location.href = `${API_BASE_URL}/internal/saml/redirect?identity_provider=${authenticationRedirect.identity_provider}&redirect_url=${window.location.origin}/saml_return`;
  }

  return (
    <div className={c('page')}>
      <header className={c('wrap', 'header', 'theme dark')}>
        <div className={c('container')}>
          <Logo className={c('logo')} />
          <Heading level="1">
            Create &amp; deliver custom video analytics solutions{' '}
            <span className={c('color-primary')}>in minutes</span>.
          </Heading>

          <List.Root className={c('header-list')}>
            <List.Item icon="check">
              <Text size="large">
                Effortlessly analyze, transform, and act on video using drag
                &amp; drop tools
              </Text>
            </List.Item>
            <List.Item icon="check">
              <Text size="large">
                Create tailored solutions with ready-to-use AI models, video
                utilities, and integrations
              </Text>
            </List.Item>
            <List.Item icon="check">
              <Text size="large">
                Plug &amp; play with existing IP or USB cameras and video
                management systems
              </Text>
            </List.Item>
            <List.Item icon="check">
              <Text size="large">
                Customizable with REST APIs, OpenCV, and Python
              </Text>
            </List.Item>
            <List.Item icon="check">
              <Text size="large">Process on the edge or cloud</Text>
            </List.Item>
          </List.Root>
        </div>
      </header>

      <main className={c('wrap', 'content')}>
        <div className={c('container')}>
          <Heading level="1" asChild>
            <h2>Sign up for free</h2>
          </Heading>
          <Text size="large">
            Take Lumeo for a test drive&mdash;no credit card required
          </Text>

          {authenticationRedirect?.type === 'password' ? (
            <form className={c('form')} onSubmit={submitPasswordForm(signUp)}>
              <button
                className={c('link', 'text-button')}
                type="button"
                onClick={changeRegisterEmail}
              >
                <Icon name="arrow-left" />
                <span>{watchEmailForm('email')}</span>
              </button>
              <Field
                id="password"
                label="Password"
                error={passwordFormState.errors.password}
              >
                <PasswordInput
                  id="password"
                  size="large"
                  {...registerPasswordForm('password', {
                    required: 'Please choose a password.',
                  })}
                />
              </Field>

              <FormErrorMessage error={registerError as APIError} />

              <Field error={passwordFormState.errors.hasAcceptedTermsOfService}>
                <div className={c('toc-wrapper')}>
                  <Checkbox
                    id={termsOfServiceId}
                    {...registerPasswordForm('hasAcceptedTermsOfService', {
                      required: 'Please accept the terms of service.',
                    })}
                  />

                  <label htmlFor={termsOfServiceId}>
                    I accept the Lumeo{' '}
                    <a
                      className="link"
                      href="https://lumeo.com/legal/terms/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      className="link"
                      href="https://lumeo.com/legal/aup/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      Acceptable Use Policy
                    </a>
                  </label>
                </div>
              </Field>

              <ButtonGroup>
                <Button
                  type="submit"
                  variant="primary"
                  size="large"
                  loading={isRegistering}
                >
                  Sign up
                </Button>
              </ButtonGroup>
            </form>
          ) : (
            <form
              className={c('form')}
              onSubmit={submitEmailForm(requestAuthenticationRedirect)}
            >
              <Field
                id="email"
                label="Email"
                error={emailFormState.errors.email}
              >
                <Input
                  id="email"
                  type="email"
                  size="large"
                  {...registerEmailForm('email', {
                    required: 'Please provide an email address.',
                    // Check that email contains dot in domain
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Please provide a valid email address.',
                    },
                  })}
                />
              </Field>

              <FormErrorMessage error={authenticationRedirectError} />

              <ButtonGroup>
                <Button
                  type="submit"
                  variant="primary"
                  size="large"
                  loading={isLoadingAuthenticationRedirect}
                >
                  Continue
                </Button>
              </ButtonGroup>
            </form>
          )}
          <br />
          <Text>
            Already have an account?{' '}
            <Link to="/login" className="link">
              Sign in
            </Link>
          </Text>
        </div>
      </main>
    </div>
  );
}
