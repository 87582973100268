import { createModelSchema, raw } from 'serializr';

import { DashboardWidget } from './dashboard_widget';

export default class DashboardConfiguration {
  widgets: DashboardWidget[];

  constructor(widgets: DashboardWidget[]) {
    this.widgets = widgets;
  }
}

createModelSchema(DashboardConfiguration, {
  widgets: raw(),
});
