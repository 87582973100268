import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';
import Gateway from 'types/gateway';
import Stream, { StreamSource, StreamType } from 'types/stream';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';

import { getOverloadedQueryParams } from 'services/get_overloaded_query_params';

import { useAPI } from './useAPI';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';

export type VideoSource = Camera | Stream;

export type UseVideoSourcesFilterParams = OffsetPaginationParams & {
  /** Limit response to camera or stream. Defaults to 'all'. */
  fetch_type?: 'all' | 'cameras' | 'streams';

  names?: string[];
  gateway_ids?: Gateway['id'][];

  // Stream filters
  stream_types?: StreamType[];
  stream_sources?: StreamSource[];

  // Camera filters
  conn_types?: Camera['conn_type'][];
};

export type UseVideoSourcesOptions<T extends VideoSource> = UseQueryOptions<
  PaginatedAPIResponse<T>,
  APIError
>;

export function useVideoSources<T extends VideoSource = VideoSource>(
  filters?: UseVideoSourcesFilterParams,
  options?: UseVideoSourcesOptions<T>
): UseQueryResult<PaginatedAPIResponse<T>, APIError>;

export function useVideoSources<T extends VideoSource = VideoSource>(
  queryKey?: QueryKey,
  filters?: UseVideoSourcesFilterParams,
  options?: UseVideoSourcesOptions<T>
): UseQueryResult<PaginatedAPIResponse<T>, APIError>;

export function useVideoSources<T extends VideoSource = VideoSource>(
  queryKeyOrFilters?: QueryKey | UseVideoSourcesFilterParams,
  filtersOrOptions?: UseVideoSourcesFilterParams | UseVideoSourcesOptions<T>,
  queryOptions?: UseVideoSourcesOptions<T>
): UseQueryResult<PaginatedAPIResponse<T>, APIError> {
  const api = useAPI();

  const {
    queryKey = ['video-sources'],
    filters: { pagination = 'offset', page = 1, limit = 50, ...filters } = {},
    options,
  } = getOverloadedQueryParams(
    queryKeyOrFilters,
    filtersOrOptions,
    queryOptions
  );

  const params = {
    ...filters,
    limit,
    page,
    pagination,
  };

  return useAuthenticatedQuery(
    [...queryKey, api.applicationID, params],
    () => api.videoSources.list<T>(params),
    options
  );
}
