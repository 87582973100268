import React from 'react';
import classNames from 'classnames/bind';

import PricingPlan from 'types/pricing_plan';

import { useSpringState } from 'hooks/useSpringState';
import { useSwitchPricingPlan } from 'organizations/hooks/useSwitchPricingPlan';

import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Button } from 'components/Button/Button';
import { FormMessage } from 'components/FormMessage/FormMessage';
import * as Dialog from 'components/Dialog';

import styles from './UpgradePlanDialog.module.scss';

const c = classNames.bind(styles);

export type UpgradePlanFormProps = {
  planID: PricingPlan['id'];
  onSuccess: () => void;
};

export function UpgradePlanForm({ planID, onSuccess }: UpgradePlanFormProps) {
  const [isSuccess, setIsSuccess] = useSpringState(false, 3500);

  const {
    error,
    isLoading,
    mutate: switchPricingPlan,
  } = useSwitchPricingPlan({
    onSuccess() {
      setIsSuccess(true);
    },
  });

  // Fire `onSuccess` after `isSuccess` reverts to `false`
  React.useEffect(() => {
    if (!isSuccess || !onSuccess) {
      return;
    }

    return onSuccess;
  }, [isSuccess, onSuccess]);

  async function handleUpgradeClick() {
    switchPricingPlan(planID);
  }

  return (
    <>
      {isSuccess && <Dialog.Message intent="success">Success!</Dialog.Message>}

      {error && (
        <FormMessage icon="warning" intent="danger">
          {error.message}
        </FormMessage>
      )}

      <div className={c('upgrade-buttons')}>
        <ButtonGroup>
          <Button
            type="submit"
            variant="primary"
            onClick={handleUpgradeClick}
            loading={isLoading}
          >
            Upgrade and pay
          </Button>

          <Dialog.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
        </ButtonGroup>
      </div>
    </>
  );
}
