import React from 'react';
import classnames from 'classnames/bind';
import { DateTime } from 'luxon';
import { ScrollArea, Skeleton } from '@mantine/core';

import { FilesWidget } from 'types/dashboard_widget';
import { WIDGET_REFETCH_INTERVAL } from 'dashboards/services/interval';

import { EmptyView } from 'components/EmptyView/EmptyView';
import { isNotNullOrUndefined } from 'services/nullable';
import { useCameras } from 'cameras/hooks/useCameras';
import { useFiles } from 'files/hooks/useFiles';
import { useModal } from 'hooks/useModal';
import { usePipelines } from 'pipelines/hooks/usePipelines';
import { useStreams } from 'streams/hooks/useStreams';
import { useTimeRangeParams } from 'dashboards/hooks/useTimeRangeParams';
import { useWidgetModal } from 'dashboards/components/WidgetModal';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { Pagination } from 'components/Pagination/Pagination';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { TagSelect } from 'tags/components/TagSelect/TagSelect';
import { Text } from 'components/Text/Text';
import { Thumbnail } from 'components/Thumbnail/Thumbnail';

import { DashboardWidgetProps } from '../../types/widget_props';
import styles from './Files.module.scss';

const c = classnames.bind(styles);

export function DashboardFiles({
  id,
  options = {},
  name,
  onDelete,
  onDuplicate,
}: DashboardWidgetProps<FilesWidget>) {
  const { editWidget } = useWidgetModal();
  const { start, stop, timezone } = useTimeRangeParams();

  const { open } = useModal();

  const [page, setPage] = React.useState(1);

  const {
    data: files,
    isFetching,
    isPreviousData,
    isLoading: isLoadingFiles,
    error,
  } = useFiles({
    params: {
      created_ts_since: start,
      created_ts_until: stop,
      with_thumbnail: true,
      include_snapshots: false,
      page,
      ...options,
    },
    refetchInterval: WIDGET_REFETCH_INTERVAL,
    keepPreviousData: true,
  });

  React.useEffect(() => {
    if (page !== 1 && error && error.code === 'invalid-pagination-parameters') {
      setPage(1);
    }
  }, [error, page]);

  const pipelineIds = [
    ...new Set(
      files?.data.map((file) => file.pipeline_id).filter(isNotNullOrUndefined)
    ),
  ];

  const streamIds = [
    ...new Set(
      files?.data.map((file) => file.stream_id).filter(isNotNullOrUndefined)
    ),
  ];

  const { data: pipelines, isInitialLoading: isLoadingPipelines } =
    usePipelines(
      { pipeline_ids: pipelineIds, limit: pipelineIds.length },
      { enabled: pipelineIds.length > 0 }
    );

  const { data: streams, isInitialLoading: isLoadingStreams } = useStreams(
    ['streams'],
    {
      stream_ids: streamIds,
      limit: streamIds.length,
    },
    { enabled: streamIds.length > 0 }
  );

  const streamsCamerasMap = streams
    ? Object.fromEntries(
        streams?.data
          .filter(({ camera_id }) => camera_id)
          .map(({ id, camera_id }) => [id, camera_id])
      )
    : {};

  const cameraIds = [
    ...new Set(Object.values(streamsCamerasMap).filter(isNotNullOrUndefined)),
  ];

  const { data: cameras, isInitialLoading: isLoadingCameras } = useCameras(
    { camera_ids: cameraIds, limit: cameraIds.length },
    { enabled: cameraIds.length > 0 }
  );

  function handleEditClick() {
    editWidget(id);
  }

  function handleDeleteClick() {
    onDelete(id);
  }

  function handleDuplicateClick() {
    onDuplicate(id);
  }

  const isLoading =
    isLoadingFiles ||
    isLoadingPipelines ||
    isLoadingStreams ||
    isLoadingCameras;
  const hasFiles = files && files.data.length > 0;

  return (
    <div className={c('wrap')}>
      <div className={c('header')}>
        <Heading level={3}>{name}</Heading>
        <ContextMenu
          trigger={
            <IconButton
              icon="more-vertical"
              label="Reveal more actions"
              variant="ghost"
              size="small"
            />
          }
        >
          <ContextMenuAction icon="edit" onClick={handleEditClick}>
            Edit
          </ContextMenuAction>
          <ContextMenuAction icon="copy" onClick={handleDuplicateClick}>
            Duplicate
          </ContextMenuAction>
          <ContextMenuAction
            icon="delete"
            intent="danger"
            onClick={handleDeleteClick}
          >
            Delete
          </ContextMenuAction>
        </ContextMenu>
      </div>
      <ul className={c('files')}>
        {!hasFiles && !isLoading && (
          <EmptyView>No files for this time period and filters.</EmptyView>
        )}

        {/* TODO Adjust mantine scrollbar style to existing ScrollArea */}
        <ScrollArea h="100%" scrollbars="y">
          {isLoading || (isFetching && isPreviousData) ? (
            <SkeletonList
              min={hasFiles ? files?.data.length : 5}
              max={hasFiles ? files?.data.length : 5}
              component={<SkeletonItem />}
            />
          ) : (
            <>
              {files?.data.map((file) => {
                const cameraId =
                  file.stream_id && streamsCamerasMap[file.stream_id];
                const isFromCameraStream = Boolean(file.stream_id && cameraId);
                const source = isFromCameraStream
                  ? cameras?.data.find(({ id }) => id === cameraId)
                  : streams?.data.find(({ id }) => id === file.stream_id);

                const pipeline = file.pipeline_id
                  ? pipelines?.data.find(({ id }) => id === file.pipeline_id)
                  : undefined;

                return (
                  <li key={file.id}>
                    <button
                      className={c('file', 'border')}
                      onClick={() =>
                        open('files-search', {
                          defaultFile: {
                            id: file.id,
                            created_at: file.created_at,
                          },
                          defaultStreamId: file.stream_id,
                        })
                      }
                    >
                      <Thumbnail
                        thumbnail_url={file.thumbnail_url}
                        mime_type={file.mime_type}
                      />
                      <div className={c('info')}>
                        <Text size="small" inline>
                          {DateTime.fromISO(file.created_at)
                            .setZone(timezone)
                            .toLocaleString(DateTime.DATETIME_MED)}
                        </Text>

                        <div className={c('entities')}>
                          {source && (
                            <Text className={c('text')} inline>
                              <Icon
                                name={isFromCameraStream ? 'camera' : 'stream'}
                              />
                              <span className={c('text-inner')}>
                                {source.name}
                              </span>
                            </Text>
                          )}
                          {pipeline && (
                            <Text className={c('text')} inline>
                              <Icon name="pipeline" />
                              <span className={c('text-inner')}>
                                {pipeline.name}
                              </span>
                            </Text>
                          )}
                          {file.description && (
                            <Text className={c('text')} inline>
                              <Icon name="meta" />
                              <span className={c('text-inner')}>
                                {file.description}
                              </span>
                            </Text>
                          )}
                        </div>
                        <TagSelect defaultSelection={file.tags} readOnly />
                      </div>
                    </button>
                  </li>
                );
              })}
            </>
          )}

          {hasFiles && files.total_pages > 1 && (
            <Pagination
              total={files.total_pages}
              value={page}
              onChange={setPage}
              siblings={0}
            />
          )}
        </ScrollArea>
      </ul>
    </div>
  );
}

function SkeletonItem() {
  return (
    <li>
      <div className={c('file')}>
        <Thumbnail isLoading />
        <div className={c('info')}>
          <div>
            <Skeleton height="1rem" width="40%" />
            <Skeleton height="1rem" width="90%" mt="xs" />
          </div>

          <div className={c('entities', 'entities-skeleton')}>
            <Skeleton height="1rem" width="80%" />
            <Skeleton height="1rem" width="80%" />
          </div>
        </div>
      </div>
    </li>
  );
}
