import { useIntercom } from 'react-use-intercom';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';

import { useModal } from 'hooks/useModal';

import { useCurrentOrganization } from './useCurrentOrganization';

/**
 * @deprecated
 */
export type UseBillingMutationOptions<
  TData = unknown,
  TError = APIError,
  TVariables = void,
  TContext = unknown,
> = UseMutationOptions<TData, TError, TVariables, TContext> & {
  action: string;
  entity: string;
};

/**
 * Wrapper around react-query `useMutation`.
 * * Prevent user from executing billing mutations while suspended
 *
 * @deprecated
 */
export function useBillingMutation<
  TData = unknown,
  TError = APIError,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  {
    action,
    entity,
    onError,
    ...options
  }: UseBillingMutationOptions<TData, TError, TVariables, TContext>
) {
  const { data: organization } = useCurrentOrganization();
  const isSuspended = organization?.is_suspended;

  const { open } = useModal();
  const { show } = useIntercom();

  return useMutation<TData, TError, TVariables, TContext>(
    (variables: TVariables) => {
      if (isSuspended) {
        open('confirm', {
          title: `Organization ${organization.name} is suspended.`,
          info: `Please contact support to ${action} ${entity}.`,
          confirmButtonText: 'Contact support',
          onConfirm: show,
        });

        return Promise.reject(
          new Error(`Organization ${organization.name} is suspended.`)
        );
      }

      return mutationFn(variables);
    },
    {
      ...options,
      onError(error, variables, context) {
        if (isSuspended) {
          return;
        }

        onError?.(error, variables, context);
      },
    }
  );
}
