import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Category from 'types/category';
import PipelineTemplate from 'types/pipeline_template';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

import { getOverloadedQueryParams } from 'services/get_overloaded_query_params';

export type UsePipelineTemplatesFilterParams = OffsetPaginationParams & {
  category_labels?: Category['label'][];
};

export type UsePipelineTemplatesOptions = UseQueryOptions<
  PaginatedAPIResponse<PipelineTemplate>,
  APIError
>;

export function usePipelineTemplates(
  filters?: UsePipelineTemplatesFilterParams,
  options?: UsePipelineTemplatesOptions
): UseQueryResult<PaginatedAPIResponse<PipelineTemplate>, APIError>;

export function usePipelineTemplates(
  queryKey?: QueryKey,
  filters?: UsePipelineTemplatesFilterParams,
  options?: UsePipelineTemplatesOptions
): UseQueryResult<PaginatedAPIResponse<PipelineTemplate>, APIError>;

export function usePipelineTemplates(
  queryKeyOrFilters?: QueryKey | UsePipelineTemplatesFilterParams,
  filtersOrOptions?:
    | UsePipelineTemplatesFilterParams
    | UsePipelineTemplatesOptions,
  queryOptions?: UsePipelineTemplatesOptions
) {
  const api = useAPI();

  const {
    queryKey = ['pipeline-templates'],
    filters: { pagination = 'offset', page = 1, limit = 50, ...filters } = {},
    options,
  } = getOverloadedQueryParams(
    queryKeyOrFilters,
    filtersOrOptions,
    queryOptions
  );

  const params = { ...filters, limit, page, pagination };

  return useAuthenticatedQuery(
    [...queryKey, params],
    () => api.pipelines.templates.list(params),
    options
  );
}
