import { QueryKey } from '@tanstack/react-query';

export type OverloadedQueryParamsResult<
  TFilterParams extends Record<string, any>,
  TOptions extends Record<string, any>,
> = {
  queryKey?: QueryKey;
  filters?: TFilterParams;
  options?: TOptions;
};

export function getOverloadedQueryParams<
  TFilterParams extends Record<string, any>,
  TOptions extends Record<string, any>,
>(
  queryKeyOrFilters?: QueryKey | TFilterParams,
  filtersOrOptions?: TFilterParams | TOptions,
  queryOptions?: TOptions
): OverloadedQueryParamsResult<TFilterParams, TOptions> {
  const hasQueryKey = Array.isArray(queryKeyOrFilters);

  const queryKey: QueryKey | undefined = hasQueryKey
    ? queryKeyOrFilters
    : undefined;

  const filters = ((hasQueryKey ? filtersOrOptions : queryKeyOrFilters) ??
    undefined) as TFilterParams | undefined;

  const options =
    queryOptions ??
    (!hasQueryKey && filtersOrOptions
      ? (filtersOrOptions as TOptions)
      : undefined);

  return {
    queryKey,
    filters,
    options,
  };
}
