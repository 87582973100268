import { MODEL_CLUSTERING_ALGORITHM_OPTIONS } from 'services/model_options';

import { Heading } from 'components/Heading/Heading';
import { Separator } from 'components/Separator/Separator';

import { useModelFormState } from '../context/useModelFormState';
import { DbScanConfidenceScoreInput } from '../inputs/DbScanConfidenceScoreInput';
import { EpsilonInput } from '../inputs/EpsilonInput';
import { MinBoundingBoxesInput } from '../inputs/MinBoundingBoxesInput';
import { NmsClusteringIoUInput } from '../inputs/NmsClusteringIoUInput';

const CONFIGURABLE_CLUSTER_MODES = ['dbscan', 'dbscan_nms_hybrid', 'nms'];

export function ClusteringConfiguration() {
  const { model, data } = useModelFormState();

  if (!data && !model) {
    return null;
  }

  const clusterMode =
    data?.inference_config?.cluster_mode ||
    model?.inference_config?.cluster_mode;
  const clusterModeLabel = MODEL_CLUSTERING_ALGORITHM_OPTIONS.find(
    ({ value }) => value === clusterMode
  )?.label;

  if (
    !clusterMode ||
    !CONFIGURABLE_CLUSTER_MODES.includes(clusterMode) ||
    !clusterMode
  ) {
    return null;
  }

  return (
    <>
      <Separator />

      <Heading level="2">{clusterModeLabel} Configuration</Heading>

      {(clusterMode === 'dbscan' ||
        clusterMode === 'dbscan_nms_hybrid' ||
        clusterMode === 'open_cv_group_rectangles') && <EpsilonInput />}

      {(clusterMode === 'dbscan' || clusterMode === 'dbscan_nms_hybrid') && (
        <>
          <MinBoundingBoxesInput />
          <DbScanConfidenceScoreInput />
        </>
      )}

      {(clusterMode === 'nms' || clusterMode === 'dbscan_nms_hybrid') && (
        <NmsClusteringIoUInput />
      )}
    </>
  );
}
