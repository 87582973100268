import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import Camera, { CameraCapability, Framerate } from 'types/camera';

import { useUpdateCamera } from 'cameras/hooks/useUpdateCamera';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Select } from 'components/Select/Select';
import { Text } from 'components/Text/Text';

import { CameraOrStreamConfig } from '../CameraOrStreamSettings/CameraOrStreamSettings';

export type CameraDetailSettingsProps = {
  camera: Camera;
};

type Resolution = {
  width: number;
  height: number;
  framerates: string[];
};

type ResolutionOption = {
  label: string;
  value: Resolution | null;
};

type FramerateOption = {
  label: string | number;
  value: Framerate | null;
};

type MemoResult = {
  resolutionOptions: ResolutionOption[];
  framerateOptions: FramerateOption[];
};

type FieldValues = {
  framerate: FramerateOption | null;
  resolution: ResolutionOption | null;
};

const UNSET_OPTION = {
  value: null,
  label: 'Default',
};

export function CapabilitySettings({ camera }: CameraDetailSettingsProps) {
  const {
    mutate: updateCamera,
    error,
    status,
    data: updatedCamera,
  } = useUpdateCamera();

  const [framerate, setFramerate] = React.useState<Framerate | null>();
  const [resolution, setResolution] = React.useState<Resolution | null>();

  const { resolutionOptions, framerateOptions } =
    React.useMemo<MemoResult>(() => {
      const framerates = new Map<string, Framerate>();
      const resolutions = new Map<string, Resolution>();

      camera.capabilities?.forEach((c: CameraCapability) => {
        if (!c.width || !c.height) {
          return;
        }

        resolutions.set(`${c.width}x${c.height}`, {
          width: c.width,
          height: c.height,
          framerates: c.framerates.map((fps: Framerate) => {
            framerates.set(`${fps.numer}/${fps.denom}`, fps);
            return `${fps.numer}/${fps.denom}` as string;
          }),
        });
      });

      return {
        resolutionOptions: [
          UNSET_OPTION,
          ...Array.from(resolutions.values())
            .sort((a, b) => b.width - a.width)
            .map(({ width, height, framerates }) => ({
              label: `${width}x${height}`,
              value: { width, height, framerates },
            })),
        ],
        framerateOptions: [
          UNSET_OPTION,
          ...Array.from(framerates.values())
            // We currently don't support fractional frame rates
            .filter((fps) => fps.denom === 1)
            .sort((a, b) => b.numer - a.numer)
            .map((fps) => ({
              label: `${fps.numer}/${fps.denom}`,
              value: fps,
            })),
        ],
      };
    }, [camera.capabilities]);

  const [defaultValues] = React.useState(() => {
    const defaultValues: FieldValues = {
      framerate: null,
      resolution: null,
    };

    try {
      const config = updatedCamera?.config || camera.config;
      if (config.framerate) {
        const numer = (config.framerate as Framerate).numer || config.framerate;
        const denom = (config.framerate as Framerate).denom || 1;
        const framerate =
          framerateOptions.find(
            ({ value: fps }) => fps?.denom === denom && fps?.numer === numer
          ) || null;
        defaultValues.framerate = framerate;
        setFramerate(framerate?.value);
      } else {
        [defaultValues.framerate] = framerateOptions;
        setFramerate(null);
      }

      if (config.width && config.height) {
        const resolution =
          resolutionOptions.find(
            (res) =>
              res.value?.width === config.width &&
              res.value?.height === config.height
          ) || null;
        defaultValues.resolution = resolution;
        setResolution(resolution?.value);
      } else {
        [defaultValues.resolution] = resolutionOptions;
        setResolution(null);
      }
    } catch (error) {
      // do not set default values from invalid configuration
    } finally {
      return defaultValues;
    }
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({ defaultValues });

  function save(values: FieldValues) {
    const config = Object.entries(values).reduce((config, [key, option]) => {
      switch (key) {
        case 'framerate': {
          config.framerate = (option?.value as Framerate) || null;
          break;
        }

        case 'resolution': {
          const resolution = option?.value
            ? (option?.value as Resolution)
            : null;
          config.width = resolution?.width || null;
          config.height = resolution?.height || null;
          break;
        }
        default:
        // do nothing
      }

      return config;
    }, {} as Partial<CameraOrStreamConfig>);

    const newCamera = camera.withConfig(config);
    updateCamera(newCamera, {
      onSuccess: () => reset(values),
    });
  }

  function handleFormReset() {
    reset(defaultValues);
  }

  return (
    <section>
      <Text>
        This camera only allows specific combinations of format, frame rate and
        resolution. Depending on your selection, some options may become
        unavailable.
      </Text>

      <form className="form" onSubmit={handleSubmit(save)}>
        <Field label="Resolution">
          <Controller
            control={control}
            name="resolution"
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={(resolution) => {
                  setResolution(resolution?.value ?? null);
                  onChange(resolution);
                }}
                options={resolutionOptions}
                isOptionDisabled={({ value }) => {
                  if (!framerate || !value) {
                    return false;
                  }

                  return !value.framerates.includes(
                    `${framerate.numer}/${framerate.denom}`
                  );
                }}
                defaultValue={UNSET_OPTION}
              />
            )}
          />
        </Field>

        <Field label="Frame rate">
          <Controller
            control={control}
            name="framerate"
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={(framerate) => {
                  setFramerate(framerate?.value);
                  onChange(framerate);
                }}
                options={framerateOptions}
                isOptionDisabled={({ value }) => {
                  if (!resolution || !value) {
                    return false;
                  }

                  return !resolution.framerates.includes(
                    `${value.numer}/${value.denom}`
                  );
                }}
                defaultValue={UNSET_OPTION}
              />
            )}
          />
        </Field>

        <FormErrorMessage error={error} />

        <ButtonGroup>
          <Button
            variant="primary"
            type="submit"
            size="small"
            loading={status === 'loading'}
          >
            Save configuration
          </Button>

          {status === 'success' && !isDirty && (
            <InlineNotification intent="success" icon="check-circle">
              Saved!
            </InlineNotification>
          )}

          {isDirty && (
            <Button
              type="reset"
              variant="ghost"
              size="small"
              onClick={handleFormReset}
            >
              Discard changes
            </Button>
          )}
        </ButtonGroup>
      </form>
    </section>
  );
}
