import classNames from 'classnames/bind';

import { truncateMiddle } from 'services/string';

import { Button } from 'components/Button/Button';
import { OptionalTooltip } from 'components/Tooltip/OptionalTooltip';
import { Popover } from 'components/Popover/Popover';
import { Text } from 'components/Text/Text';
import * as Stepper from 'components/Stepper';

import { useRapidDeployState } from './hooks/useRapidDeployState';
import { RapidDeployRoute } from './routes';
import styles from './RapidDeploy.module.scss';

const c = classNames.bind(styles);

const LABEL_MAX_LENGTH = 30;

function renderName(name: string) {
  return (
    <OptionalTooltip
      showTooltip={name.length > LABEL_MAX_LENGTH}
      content={name}
    >
      {truncateMiddle(name, LABEL_MAX_LENGTH)}
    </OptionalTooltip>
  );
}

export function RapidDeployStepper() {
  const { gateway, videoSources } = useRapidDeployState();

  const [firstVideoSource, ...moreVideoSources] = videoSources;

  return (
    <Stepper.Root className={c('stepper')}>
      <Stepper.Step
        label={gateway ? renderName(gateway.name) : 'Gateway'}
        to={RapidDeployRoute.GATEWAY}
        icon="gateway"
        index={1}
        state={Boolean(gateway) ? 'valid' : undefined}
      />
      <Stepper.Step
        label={
          firstVideoSource ? (
            <>
              {renderName(firstVideoSource.name)}
              {moreVideoSources.length > 0 && (
                <Popover
                  trigger={
                    <Button
                      size="xsmall"
                      variant="tertiary"
                      className={c('stepper-popover-button')}
                    >
                      +{moreVideoSources.length}
                    </Button>
                  }
                  sideOffset={8}
                >
                  <ul>
                    {moreVideoSources.map((videoSource) => (
                      <li key={videoSource.id}>
                        <Text type="paragraph">{videoSource.name}</Text>
                      </li>
                    ))}
                  </ul>
                </Popover>
              )}
            </>
          ) : (
            'Video sources'
          )
        }
        icon="camera"
        to={RapidDeployRoute.VIDEO_SOURCES}
        index={2}
        state={videoSources.length > 0 ? 'valid' : undefined}
      />
      <Stepper.Step label="Configure" to={RapidDeployRoute.DEPLOY} index={3} />
    </Stepper.Root>
  );
}
