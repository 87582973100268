import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import LumeoJWT from 'types/jwt';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function useFilesSearchJWT(
  options?: Omit<UseQueryOptions<LumeoJWT, APIError>, 'staleTime'>
) {
  const api = useAPI();
  return useAuthenticatedQuery(
    ['files-search-jwt', api.applicationID],
    api.jwt.createSearchToken,
    {
      ...options,
      // 1 day
      staleTime: 24 * 60 * 60 * 1000,
    }
  );
}
