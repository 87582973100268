import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
} from '@tanstack/react-query';

import AIModel from 'types/ai_model';
import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';
import { useAppQueryClient } from 'hooks/useAppQueryClient';
import { useEntity } from 'hooks/useEntities';

import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { UseCreateModelVariables, UseUpdateModelVariables } from './useModel';
import { useDangerousMutation } from './useDangerousMutation';

export function useMarketplaceModel(
  modelID?: AIModel['id'],
  options?: Omit<UseQueryOptions<AIModel, APIError>, 'select'>
) {
  const api = useAPI();
  const placeholderData = useEntity<AIModel>('marketplace-models', modelID);

  return useAuthenticatedQuery<AIModel>(
    ['marketplace-model', modelID],
    () => {
      if (!modelID) {
        return Promise.reject('No model id provided.');
      }

      return api.models.marketplace.read(modelID);
    },
    {
      ...options,
      select: (marketplaceModel) => {
        marketplaceModel.source = 'marketplace';
        return marketplaceModel;
      },
      enabled: !!modelID,
      placeholderData,
    }
  );
}
export function useUpdateMarketplaceModel({
  onMutate,
  onSettled,
  ...options
}: UseMutationOptions<AIModel, APIError, UseUpdateModelVariables> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useMutation(
    ({ model, files = {} }) => api.models.marketplace.update(model, files),
    {
      ...options,
      onMutate: ({ model }) => {
        const previousModels =
          queryClient.getQueryData<AIModel[]>(['marketplace-models']) || [];

        if (previousModels) {
          queryClient.setQueryData(
            ['marketplace-models'],
            previousModels.map((previousModel) => {
              if (previousModel.id === model.id) {
                return model;
              }

              return previousModel;
            })
          );
        }

        return { previousModels };
      },
      onSettled: (model, ...args) => {
        if (onSettled) {
          onSettled(model, ...args);
        }

        queryClient.invalidateQueries(['marketplace-models']);

        if (model) {
          queryClient.invalidateQueries(['marketplace-model', model.id]);
        }
      },
    }
  );
}

export function useCreateMarketplaceModel({
  onMutate,
  onSuccess,
  onSettled,
  ...options
}: UseMutationOptions<AIModel, APIError, UseCreateModelVariables> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useMutation(
    ({ model, files = {} }) => api.models.marketplace.create(model, files),
    {
      ...options,
      onSuccess: async (model, variables, context) => {
        await queryClient.invalidateQueries(['marketplace-models']);

        if (onSuccess) {
          onSuccess(model, variables, context);
        }
      },
      onSettled: (...args) => {
        if (onSettled) {
          onSettled(...args);
        }

        queryClient.invalidateQueries(['marketplace-models']);
      },
    }
  );
}

type UseDeleteModelContext = {
  previousModels: AIModel[] | undefined;
} | void;

export function useDeleteMarketplaceModel({
  onMutate,
  onError,
  onSettled,
}: UseMutationOptions<void, APIError, AIModel, UseDeleteModelContext> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useDangerousMutation<void, UseDeleteModelContext, AIModel>(
    'Are you sure you want to delete this AI model?',
    (model) => api.models.marketplace.delete(model.id),
    {
      onMutate: async (model) => {
        await queryClient.cancelQueries(['marketplace-models']);
        const models =
          queryClient.getQueryData<AIModel[]>(['marketplace-models']) || [];
        const updatedModels = models.filter(({ id }) => id !== model.id);

        queryClient.setQueryData(['marketplace-models'], updatedModels);

        onMutate?.(model);

        return { previousModels: models };
      },
      onError: (err, deletedModel, context) => {
        if (context?.previousModels) {
          queryClient.setQueryData(
            ['marketplace-models'],
            context.previousModels
          );
        }

        onError?.(err, deletedModel, context);
      },
      onSettled: (...args) => {
        queryClient.invalidateQueries(['marketplace-models']);
        onSettled?.(...args);
      },
    }
  );
}
