import React from 'react';
import { MutationStatus } from '@tanstack/react-query';
import { NodeEditor } from 'rete';

import Pipeline from 'types/pipeline';
import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';
import { useAppRedirect } from 'hooks/useAppRedirect';
import { useInitializeEditor } from 'pipelines/hooks/useInitializeEditor';
import { useIsMounted } from 'hooks/useIsMounted';
import { usePipelineLock } from 'pipelines/hooks/usePipelineLock';
import { useUpdatePipeline } from 'pipelines/hooks/useUpdatePipeline';

export type PipelineDetailProviderProps = React.PropsWithChildren<{
  pipeline: Pipeline;
}>;

export type PipelineDetailState = {
  pipeline: Pipeline;
  transientPipeline: React.MutableRefObject<Pipeline>;

  isLocked: boolean;
  isLoadingLock: boolean;
  save: () => void;
  saveStatus: MutationStatus;
  saveError: APIError | null;
  changeName: (newName: string) => void;

  initializeEditor: (editor: NodeEditor) => void;
};

export const PipelineDetailContext = React.createContext<
  PipelineDetailState | undefined
>(undefined);

export function usePipelineDetailStateOptional() {
  return React.useContext(PipelineDetailContext);
}

export function usePipelineDetailState() {
  const context = React.useContext(PipelineDetailContext);

  if (context === undefined) {
    throw new Error(
      'Hook `usePipelineDetailState` can only be used within a `PipelineDetailProvider`'
    );
  }

  return context;
}

const INACTIVITY_TIMEOUT = 1000 * 60 * 15;

export function PipelineDetailProvider({
  children,
  pipeline: initialPipeline,
}: PipelineDetailProviderProps) {
  const redirect = useAppRedirect();

  const saveTimeout = React.useRef<number>();
  const inactivityTimeout = React.useRef<number>();

  const [pipeline, setPipeline] = React.useState(initialPipeline);
  const transientPipeline = React.useRef(initialPipeline);

  const editorRef = React.useRef<NodeEditor>();

  const { mutate: savePipeline, status, error } = useUpdatePipeline();

  const isMounted = useIsMounted();

  const { sendBeacon } = useAPI();
  const { lock, isLoading, isLocked, lockHeld } = usePipelineLock(pipeline.id!);

  React.useEffect(() => {
    inactivityTimeout.current = window.setTimeout(
      () => redirect('/design/pipelines'),
      INACTIVITY_TIMEOUT
    );

    // Clear inactivity timeout when leaving editor
    return () => {
      window.clearTimeout(inactivityTimeout.current);
    };
  }, [redirect]);

  // Unlock pipeline when closing Console
  React.useEffect(() => {
    function beforeUnloadHandler() {
      if (!isLocked) {
        sendBeacon(`/pipelines/${pipeline.id}/unlock_form`);
      }
    }

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [sendBeacon, pipeline.id, isLocked]);

  const save = React.useCallback(() => {
    if (!editorRef.current || !transientPipeline.current || !lockHeld.current) {
      return;
    }

    window.clearTimeout(saveTimeout.current);
    window.clearTimeout(inactivityTimeout.current);

    saveTimeout.current = window.setTimeout(async () => {
      if (
        !isMounted() ||
        !editorRef.current ||
        !transientPipeline.current ||
        !lockHeld.current
      ) {
        return;
      }

      await transientPipeline.current.updateFromReteDefinition(
        editorRef.current.toJSON()
      );

      setPipeline(transientPipeline.current);
      savePipeline({
        id: transientPipeline.current.id,
        definition: transientPipeline.current.definition,
      });
      // refresh lock
      lock();
    }, 500);

    inactivityTimeout.current = window.setTimeout(
      () => redirect('/design/pipelines'),
      INACTIVITY_TIMEOUT
    );
  }, [savePipeline, isMounted, redirect, lockHeld, lock]);

  const initializeEditor = useInitializeEditor(pipeline, {
    onSave: save,
    onInit: (editor) => {
      editorRef.current = editor;
    },
  });

  function changeName(newName: string) {
    if (!transientPipeline.current) {
      return;
    }

    transientPipeline.current.name = newName;
    setPipeline((pipeline) => pipeline?.withName(newName));
    savePipeline({ id: transientPipeline.current.id, name: newName });
  }

  const context = {
    pipeline,
    transientPipeline,

    initializeEditor,

    isLoadingLock: isLoading,
    isLocked,
    save,
    saveStatus: status,
    saveError: error,
    changeName,
  };

  return (
    <PipelineDetailContext.Provider value={context}>
      {children}
    </PipelineDetailContext.Provider>
  );
}
