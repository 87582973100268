import React from 'react';

import { NodeEditor } from 'rete';

import { IconType } from 'components/Icon/Icon';
import { Input, InputProps } from 'components/Input/Input';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

export type ReteTextInputParams = ReteInputParams & {
  autoComplete?: InputProps['autoComplete'];
  icon?: IconType;
  spellCheck?: InputProps['spellCheck'];
};

type ReteTextInputProps = Omit<InputProps, 'onChange' | 'onValueChange'> & {
  onChange: InputProps['onValueChange'];
};

export default class ReteTextInput extends ReteInput<
  ReteInputProps & ReteTextInputProps
> {
  constructor(
    editor: NodeEditor,
    key: string,
    {
      inputType,
      autoComplete,
      icon,
      spellCheck,
      ...params
    }: ReteTextInputParams
  ) {
    super(editor, key, { ...params, inputType });

    this.props.autoComplete = autoComplete;
    this.props.type = inputType;
    this.props.icon = icon;
    this.props.spellCheck = spellCheck;
  }

  get component() {
    return ReteTextInputComponent;
  }
}

function ReteTextInputComponent({
  onChange,
  ...props
}: ReteInputProps & ReteTextInputProps) {
  return <Input {...props} onValueChange={onChange} />;
}
