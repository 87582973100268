import React from 'react';
import classNames from 'classnames/bind';

import { AccessorSegment, DisplayRange } from 'types/trigger_condition';

import { OptionalTooltip } from 'components/Tooltip/Tooltip';

import styles from './MetadataListInput.module.scss';

const c = classNames.bind(styles);

export type MetadataListChunkBaseProps = {
  showTooltips?: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  cursorPosition: number | null;
};

export type MetadataListChunkProps = DisplayRange & MetadataListChunkBaseProps;

export function MetadataListChunk({
  range,
  elements,
  errors,
  showTooltips,
  cursorPosition,
  inputRef,
}: MetadataListChunkProps) {
  const ref = React.useRef<HTMLSpanElement>(null);

  const hasError = errors.length > 0;
  const timeoutRef = React.useRef<number>();
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  const isActive =
    cursorPosition !== null &&
    cursorPosition >= range.begin &&
    cursorPosition < range.end;

  /**
   * Unless the current chunk is a [variable], show relevant error messages.
   * If it is a [variable], show neutral tooltip to prompt user to manually
   * fill out the variable.
   */
  const variable = elements.find(
    (element) => element instanceof AccessorSegment && element.isVariable
  );
  const isVariable = Boolean(variable);

  const tooltipContent = errors.map((error, index) => (
    <p
      className={c('chunk-error')}
      key={`${index}_${error.range.begin}_${error.range.end}`}
    >
      {error.message}
    </p>
  ));

  React.useEffect(() => {
    if (!inputRef.current || !isVariable || !isActive) {
      return;
    }

    inputRef.current.setSelectionRange(range.begin, range.end);

    // inputRef is a React ref
    // eslint-disable-next-line
  }, [isActive, isVariable, range.begin, range.end]);

  React.useEffect(() => {
    window.clearTimeout(timeoutRef.current);

    if (!isActive) {
      setIsTooltipOpen(false);
      return;
    }

    if (isVariable) {
      setIsTooltipOpen(true);
      return;
    }

    timeoutRef.current = window.setTimeout(() => {
      setIsTooltipOpen(isActive);
    }, 500);
  }, [isActive, isVariable]);

  return (
    <OptionalTooltip
      className={c('chunk', {
        error: hasError,
        active: showTooltips && isActive,
        'theme danger': hasError,
        variable: isVariable,
      })}
      intent="danger"
      showTooltip={hasError || isVariable}
      content={tooltipContent}
      open={showTooltips && isActive && isTooltipOpen}
    >
      <span ref={ref}>{range.text === ' ' ? <>&nbsp;</> : range.text}</span>
    </OptionalTooltip>
  );
}
