import classNames from 'classnames/bind';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Icon, IconType } from 'components/Icon/Icon';

import styles from './ContextMenu.module.scss';

const c = classNames.bind(styles);

export type ContextMenuActionProps = React.PropsWithChildren<
  {
    icon?: IconType;
    onClick: () => void;
    intent?: 'danger';
  } & Pick<DropdownMenu.DropdownMenuItemProps, 'disabled'>
>;

export function ContextMenuAction({
  icon,
  children,
  onClick,
  intent,
  disabled,
}: ContextMenuActionProps) {
  return (
    <DropdownMenu.Item
      className={c('option', 'type-action', `theme ${intent}`, { disabled })}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <Icon name={icon} size="small" />}
      <div>{children}</div>
    </DropdownMenu.Item>
  );
}
