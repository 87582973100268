import React from 'react';
import { useQueries, UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { OffsetPaginated, OffsetPaginationParams } from 'types/pagination';
import { Tag } from 'types/tag';

import { useAPI } from 'hooks/api/useAPI';

import { UseTagsParams } from './useTags';

const TAGS_ANCESTORS_MAX_LIMIT = 1;

export function useTagsAncestors(
  data: OffsetPaginated<Tag> | undefined,
  options?: UseQueryOptions<OffsetPaginated<Tag>, APIError>
) {
  const { applicationID, client } = useAPI();

  const ancestorIDs = React.useMemo(() => {
    if (!data) {
      return [];
    }

    const uniqueIDs = [...new Set(data.data.flatMap(({ path }) => path))];
    const chunks = Math.ceil(uniqueIDs.length / TAGS_ANCESTORS_MAX_LIMIT);
    const chunkSize = Math.ceil(uniqueIDs.length / chunks);

    const ids = [];

    while (uniqueIDs.length) {
      ids.push(uniqueIDs.splice(0, chunkSize));
    }

    return ids;
  }, [data]);

  const queries = useQueries({
    queries: ancestorIDs.map((ids) => {
      const params: UseTagsParams & OffsetPaginationParams = {
        pagination: 'offset',
        page: 1,
        limit: ids.length,
        tag_ids: ids,
      };

      return {
        queryKey: ['tags', applicationID, params],
        async queryFn() {
          const { data } = await client.get(`/apps/${applicationID}/tags`, {
            params,
          });

          return data;
        },
        ...options,
      };
    }),
  });

  return React.useMemo(
    () => ({
      data: queries.map(({ data }) => data),
      isLoading: queries.some(({ isLoading }) => isLoading),
      isSuccess: queries.every(({ isSuccess }) => isSuccess),
      isError: queries.some(({ isError }) => isError),
      error: queries.find(({ error }) => error),
    }),
    [queries]
  );
}
