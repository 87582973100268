import React from 'react';
import classNames from 'classnames/bind';
import { CellProps, Column } from 'react-table';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useTagsFilterParams } from 'tags/hooks/useTagsFilterParams';
import {
  UseVideoSourcesFilterParams,
  VideoSource,
  useVideoSources,
} from 'hooks/api/useVideoSources';

import { Checkbox } from 'components/Checkbox/Checkbox';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { PaginatedTable } from 'components/Table/PaginatedTable';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { TableLabelsCell } from 'components/Table/Table';
import { Text } from 'components/Text/Text';
import { VideoSourceSnapshot } from 'components/VideoSourceSnapshot/VideoSourceSnapshot';

import { useRapidDeployState } from '../hooks/useRapidDeployState';
import styles from '../RapidDeploy.module.scss';

const c = classNames.bind(styles);

export const RAPID_DEPLOY_VIDEO_SOURCES_PAGE_SIZE = 20;

export type RapidDeployVideoSourcesListProps = {
  filters: UseVideoSourcesFilterParams;
};

export function RapidDeployVideoSourcesList({
  filters: baseFilters,
}: RapidDeployVideoSourcesListProps) {
  const { data: currentPlan, isSuccess } = useCurrentPlan();
  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);
  const tagFilters = useTagsFilterParams();

  React.useEffect(() => {
    setFilters((filters) => ({
      ...filters,
      ...baseFilters,
      ...tagFilters,
      page: 1,
    }));
  }, [tagFilters, baseFilters]);

  const [filters, setFilters] = React.useState<UseVideoSourcesFilterParams>({
    ...tagFilters,
    ...baseFilters,
    page: 1,
    limit: RAPID_DEPLOY_VIDEO_SOURCES_PAGE_SIZE,
  });
  const queryResult = useVideoSources(filters, { keepPreviousData: true });

  const [searchFilters, setSearchFilters] =
    React.useState<UseVideoSourcesFilterParams>({});
  const searchQueryResult = useVideoSources(
    ['video-sources-suggestions'],
    { ...baseFilters, ...searchFilters, limit: 20 },
    { enabled: Object.keys(searchFilters).length > 0, keepPreviousData: false }
  );

  function handleSearchInput(value?: string) {
    if (!value) {
      setSearchFilters({});
      setFilters(({ names, ...filters }) => ({ ...filters, page: 1 }));
      return;
    }

    setSearchFilters({
      names: [value],
    });
  }

  const { isLoading } = queryResult;

  const { videoSources, dispatchVideoSources } = useRapidDeployState();

  const columns = React.useMemo(() => {
    const columns: Column<VideoSource>[] = [
      {
        id: 'select',
        Cell({ row: { original } }) {
          return (
            <Checkbox
              id={`${original.source_type}_${original.id}`}
              value={original.id}
              name="rapid-deploy-sources"
              checked={videoSources.some(({ id }) => id === original.id)}
              onChange={(event) => {
                if (event.target.checked) {
                  dispatchVideoSources({ type: 'add', data: original });
                  return;
                }

                dispatchVideoSources({ type: 'remove', data: original });
              }}
            />
          );
        },
        width: 0,
      },
      {
        id: 'snapshot',
        Cell: ThumbnailCell,
        width: 0,
      },
      {
        id: 'status',
        Cell({ row: { original } }: CellProps<VideoSource>) {
          return <StatusIndicator status={original.status} size="small" />;
        },
        width: 0,
      },
      {
        id: 'name',
        Cell({ row: { original } }: CellProps<VideoSource>) {
          return (
            <label
              className={c('table-cell')}
              htmlFor={`${original.source_type}_${original.id}`}
            >
              <Heading level="4" asChild>
                <strong>
                  <Icon name={original.source_type} />
                  <span>{original.name}</span>
                </strong>
              </Heading>

              {original.source_type === 'camera' && (
                <>
                  <Text className={c('table-cell-properties')} inline>
                    {original.model && <span>{original.model}</span>}
                    {(original.ip_ext || original.ip_local) && (
                      <span>
                        <Icon name="global" />
                        <span>{original.ip_ext ?? original.ip_local}</span>
                      </span>
                    )}
                  </Text>
                </>
              )}
            </label>
          );
        },
      },
    ];

    if (canCreateTags) {
      columns.push({
        id: 'tags',
        Cell(props: CellProps<VideoSource>) {
          return (
            <TableLabelsCell
              {...props}
              entity={`${props.row.original.source_type}s`}
              singleQueryKey={[props.row.original.source_type]}
              readOnly
            />
          );
        },
      });
    }

    return columns;
  }, [videoSources, canCreateTags, dispatchVideoSources]);

  function handleApplyFilters() {
    setFilters((filters) => ({ ...filters, ...searchFilters, page: 1 }));
  }

  return (
    <>
      <QuerySearch
        entityName="video source"
        searchLabel="Search video sources..."
        searchResultField="name"
        searchQueryResult={searchQueryResult}
        onApplyFilters={handleApplyFilters}
        onSuggestionSelect={(data) =>
          dispatchVideoSources({ type: 'add', data })
        }
        onValueChange={handleSearchInput}
        totalElements={queryResult.data?.total_elements}
      />

      {isLoading ? (
        <div className={c('loader-table')}>
          <SkeletonList min={8} max={15} component={<LoaderRow />} />
        </div>
      ) : (
        <PaginatedTable<VideoSource>
          className={c('table')}
          id="rapid-deploy-video-sources"
          label="Video sources"
          queryResult={queryResult}
          columns={columns}
          pageSize={RAPID_DEPLOY_VIDEO_SOURCES_PAGE_SIZE}
          page={filters.page}
          onPageChange={(page) =>
            setFilters((filters) => ({ ...filters, page }))
          }
          onRowClick={(data) => dispatchVideoSources({ type: 'toggle', data })}
        />
      )}
    </>
  );
}

function ThumbnailCell({ row: { original } }: CellProps<VideoSource>) {
  return (
    <VideoSourceSnapshot
      className={c('snapshot')}
      source={original}
      compact
      hideTitle
    />
  );
}

function LoaderRow() {
  return (
    <div className={c('loader-table-row')}>
      <div className={c('loader-table-cell', 'auto')}>
        <span className={c('loader-radio')} />
      </div>

      <div className={c('loader-table-cell', 'auto')}>
        <span className={c('loader-snapshot', 'snapshot', 'skeleton-image')} />
      </div>

      <div className={c('loader-table-cell', 'auto')}>
        <span className={c('skeleton-bar', 'loader-status-indicator')} />
      </div>

      <div className={c('loader-table-cell')}>
        <Heading level="4" asChild>
          <span className="skeleton-text" />
        </Heading>
      </div>
    </div>
  );
}
