const has = Object.prototype.hasOwnProperty;

export function objectHas(object: object, property: string) {
  return has.call(object, property);
}

export function isObject(object: unknown) {
  return object !== null && typeof object === 'object';
}

/**
 * Flatten nested object properties to top-level properties.
 */
export function flattenObject(object: object): Record<string, unknown> {
  if (!isObject(object)) {
    throw new Error(`Tried to flatten a ${typeof object}`);
  }

  return Object.keys(object).reduce((flattened, key) => {
    const value = object[key as keyof typeof object];
    if (!value || typeof value !== 'object') {
      flattened[key] = object[key as keyof typeof object];
      return flattened;
    }

    const flatObject = flattenObject(value as Record<string, unknown>);
    Object.keys(flatObject).forEach((innerKey) => {
      flattened[`${key}.${innerKey}`] = flatObject[innerKey];
    });

    return flattened;
  }, {} as Record<string, unknown>);
}

/**
 * Remove `null` and `undefined` values from an object
 * and return a copy.
 */
export function sanitizeObject(object: unknown) {
  return JSON.parse(JSON.stringify(object), (_key, value) => {
    if (
      value !== null &&
      value !== undefined &&
      (typeof value !== 'object' || Object.keys(value).length)
    ) {
      return value;
    }
  });
}

export function isEmptyObject(object: object | null): boolean {
  if (object === null || typeof object !== 'object') {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  for (const _ in object) {
    return false;
  }

  return true;
}
