import { AppLink } from 'components/AppLink/AppLink';
import { Box } from 'components/Box/Box';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import * as List from 'components/List';

export function CameraTypeInfo() {
  return (
    <Box>
      <Heading level="4">
        <Icon name="camera" size="small" />
        <span>Supported camera types</span>
      </Heading>

      <List.Root>
        <List.Item>
          <Text type="paragraph">
            ONVIF cameras are IP cameras with ONVIF protocol enabled and must be
            accessible from the gateway network.
          </Text>
        </List.Item>
        <List.Item>
          <Text type="paragraph">
            USB cameras need to be connected to the gateway before linking it to
            Lumeo.
            <br />
            To recognize cameras plugged in later, delete and recreate the
            gateway.
          </Text>
        </List.Item>
        <List.Item>
          <Text type="paragraph">
            Create virtual cameras to work with IP cameras that send clips to
            Lumeo Cloud using Universal Bridge and SMTP.{' '}
            <ExternalLink href="https://docs.lumeo.com/docs/universal-bridge">
              Universal Bridge guide
            </ExternalLink>
          </Text>
        </List.Item>
      </List.Root>

      <Text type="paragraph">
        If using a Lumeo Cloud gateway or if your camera does not support ONVIF,{' '}
        <AppLink to="/deploy/streams/new" className="link">
          add RTSP or HTTP streams as live streams
        </AppLink>
        .
      </Text>
    </Box>
  );
}
