import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { GatewayConfiguration } from 'types/gateway_configuration';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function useGatewayConfiguration(
  gatewayConfigurationId: GatewayConfiguration['id'] | null | undefined,
  options?: UseQueryOptions<GatewayConfiguration, APIError>
) {
  const api = useAPI();

  return useAuthenticatedQuery(
    ['gateway-configuration', gatewayConfigurationId, api.applicationID],
    () => {
      if (!gatewayConfigurationId) {
        return Promise.reject('No gateway configuration id provided.');
      }

      return api.applications.gatewayConfiguration.read(gatewayConfigurationId);
    },
    {
      ...options,
      enabled: options?.enabled
        ? options.enabled && Boolean(gatewayConfigurationId)
        : Boolean(gatewayConfigurationId),
    }
  );
}
