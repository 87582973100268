import { DateTime } from 'luxon';
import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { LumeoFile } from 'types/file';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

// 4 seconds
const pollingInterval = 4000;

export function useFile(
  id?: LumeoFile['id'] | null,
  { enabled, ...options }: UseQueryOptions<LumeoFile, APIError, LumeoFile> = {}
) {
  const { client, applicationID } = useAPI();

  const isEnabled =
    enabled !== undefined ? enabled && Boolean(id) : Boolean(id);

  return useAuthenticatedQuery<LumeoFile>(
    ['file', id, applicationID],
    async () => {
      if (!id) {
        return Promise.reject(new Error('No file ID provided.'));
      }

      const { data } = await client.get<LumeoFile>(
        `/apps/${applicationID}/files/${id}`
      );

      return data;
    },
    {
      refetchInterval(file) {
        if (file && file.cloud_status === 'uploading') {
          const createdAt = DateTime.fromISO(file.created_at);
          const now = DateTime.now();
          // Poll if file has been created within the last 30 seconds and is in uploading state
          if (now.diff(createdAt, 'milliseconds').milliseconds <= 30000) {
            return pollingInterval;
          }
        }

        return false;
      },
      ...options,
      enabled: isEnabled,
    }
  );
}
