const releases = ['alpha1', 'alpha2-wip', 'alpha2', 'beta'];

const CURRENT_RELEASE_INDEX = 1;

/**
 * Make unreleased features unavailable for users,
 * but keep them visible for administrators
 */
export function isFeatureEnabled(
  targetRelease: typeof releases[number],
  admin = false
) {
  const idx = releases.findIndex((release) => release === targetRelease);
  const isReleaseActive = idx <= CURRENT_RELEASE_INDEX;
  return admin || isReleaseActive;
}
