const formats = {
  ogg: 'video/ogg; codecs="theora"',
  h264: 'video/mp4; codecs="avc1.42E01E"',
  webm: 'video/webm; codecs="vp8, vorbis"',
  vp9: 'video/webm; codecs="vp9"',
  hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
};

export function browserSupportsVideoType(type: string) {
  const video = document.createElement('video');
  const canPlayType = video.canPlayType(
    type in formats ? formats[type as keyof typeof formats] : type
  );
  return canPlayType === 'probably' || canPlayType === 'maybe';
}
