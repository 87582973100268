import React from 'react';
import classnames from 'classnames/bind';

import { useAPI } from 'hooks/api/useAPI';
import { useCameras } from 'cameras/hooks/useCameras';
import { useFileStreams } from 'files/hooks/useFileStreams';
import { useGateways } from 'hooks/api/useGateways';
import { useInputStreams } from 'streams/hooks/useInputStreams';
import { usePipelines } from 'pipelines/hooks/usePipelines';

import { Button } from 'components/Button/Button';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { Text } from 'components/Text/Text';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';

import { ReactComponent as EmptyIllustration } from 'deployments/images/empty-pipeline-deployments.svg';
import styles from './DeploymentOverview.module.scss';

const c = classnames.bind(styles);

export type DeploymentOverviewEmptyViewProps = {
  onDeployClick: () => void;
};

export function DeploymentOverviewEmptyView({
  onDeployClick,
}: DeploymentOverviewEmptyViewProps) {
  const { applicationID } = useAPI();

  const { data: pipelines } = usePipelines();
  const { data: gateways } = useGateways();
  const { data: cameras } = useCameras();
  const { data: inputStreams } = useInputStreams();
  const { data: fileStreams } = useFileStreams();

  const hasPipelines = Boolean(pipelines?.total_elements);
  const hasGateways = Boolean(gateways?.total_elements);
  const hasCameras = Boolean(cameras?.total_elements);
  const hasInputStreams = Boolean(inputStreams?.total_elements);
  const hasFileStreams = Boolean(fileStreams?.total_elements);
  const hasInputSources = hasCameras || hasInputStreams || hasFileStreams;

  const canDeploy = hasPipelines && hasGateways && hasInputSources;

  return (
    <EmptyView
      image={EmptyIllustration}
      title="Create your first deployment"
      buttonText={canDeploy ? 'Deploy your first pipeline' : undefined}
      onButtonClick={onDeployClick}
    >
      <Text type="paragraph">
        Complete the following the steps to create your first deployment with
        Lumeo.
      </Text>

      <ul className={c('walkthrough')}>
        <li>
          <StatusIndicator
            status={hasPipelines ? 'success' : 'progress'}
            size="small"
            className={c('walkthrough-icon')}
          />
          <Button
            variant={hasPipelines ? 'none' : 'link'}
            to={`/applications/${applicationID}/design/pipelines`}
          >
            Create a pipeline
          </Button>
        </li>

        <li>
          <StatusIndicator
            status={hasGateways ? 'success' : 'progress'}
            size="small"
            className={c('walkthrough-icon')}
          />
          <Button
            variant={hasGateways ? 'none' : 'link'}
            to={`/applications/${applicationID}/deploy/gateways`}
          >
            Link a gateway
          </Button>
        </li>

        <li>
          <StatusIndicator
            status={hasInputSources ? 'success' : 'progress'}
            size="small"
            className={c('walkthrough-icon')}
          />
          <Button
            variant={hasInputSources ? 'none' : 'link'}
            to={`/applications/${applicationID}/deploy/cameras`}
          >
            Add a camera
          </Button>
          ,{' '}
          <Button
            variant={hasInputSources ? 'none' : 'link'}
            to={`/applications/${applicationID}/deploy/files`}
          >
            file
          </Button>
          ,<span className={c('walkthrough-text')}>or</span>
          <Button
            variant={hasInputSources ? 'none' : 'link'}
            to={`/applications/${applicationID}/deploy/streams`}
          >
            stream
          </Button>
        </li>
      </ul>
    </EmptyView>
  );
}
