import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import NodeDropdown, {
  NodeDropdownProps,
} from 'pipelines/components/NodeDropdown/NodeDropdown';

export type NodeDropdownInputParams = ReteInputParams & {
  /**
   * Specifies the type of `node` to be included
   * in the dropdown
   */
  nodeType: string;
};

export default class NodeDropdownInput extends ReteInput<
  ReteInputProps & NodeDropdownProps
> {
  constructor(
    editor: NodeEditor,
    key: string,
    { nodeType, ...params }: NodeDropdownInputParams
  ) {
    super(editor, key, { ...params, type: 'string', inputType: 'select' });
    this.props.nodeType = nodeType;
  }

  get component() {
    return NodeDropdown;
  }
}
