import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Account from 'types/account';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useHasAccess } from 'hooks/useHasAccess';

export type UseMembersFilterParams = OffsetPaginationParams & {
  account_ids?: string[];
  emails?: string[];
  names?: string[];
};

export type UseMembersOptions = UseQueryOptions<
  PaginatedAPIResponse<Account>,
  APIError
>;

/** List members of an organization */
export function useMembers(
  queryKey: QueryKey = ['members'],
  {
    pagination = 'offset',
    page = 1,
    limit = 50,
    ...filters
  }: UseMembersFilterParams = {},
  { enabled, ...options }: UseMembersOptions = {}
) {
  const { applicationID, organizations } = useAPI();
  const { data: organization } = useCurrentOrganization();

  const organizationId = organization?.id;

  const [hasAccess] = useHasAccess();
  const params = { pagination, page, limit, ...filters };

  return useAuthenticatedQuery(
    [...queryKey, applicationID, params],
    () => {
      return organizationId
        ? organizations.members.list(organizationId, params)
        : Promise.reject('No organizationId provided');
    },
    {
      enabled:
        enabled !== undefined
          ? enabled &&
            Boolean(organizationId) &&
            hasAccess('organization_settings')
          : Boolean(organizationId) && hasAccess('organization_settings'),
      ...options,
    }
  );
}
