import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Organization from 'types/organization';

import { useAPI } from './api/useAPI';
import { useAuthenticatedQuery } from './api/useAuthenticatedQuery';
import { useOrganizations } from './api/useOrganizations';

export function useOrganization(
  organizationID?: string,
  options?: Omit<
    UseQueryOptions<Organization | undefined, APIError>,
    'placeholderData'
  >
) {
  const api = useAPI();
  const { data: organizations } = useOrganizations();

  return useAuthenticatedQuery(
    ['organization', organizationID],
    () => {
      if (!organizationID) {
        return Promise.reject(new Error('No organization ID provided.'));
      }

      return api.organizations.read(organizationID);
    },
    {
      ...options,
      enabled:
        options && options?.enabled !== undefined
          ? Boolean(organizationID) && options.enabled
          : Boolean(organizationID),
      staleTime: options?.staleTime || Infinity,
      placeholderData() {
        return organizations?.find(
          (organization) => organization.id === organizationID
        );
      },
    }
  );
}
