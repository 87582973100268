import APIError from 'types/api_error';

import { IconType } from 'components/Icon/Icon';
import { FormMessage } from './FormMessage';

export type FormErrorMessageProps = {
  icon?: IconType;
  error?: APIError | null;
};

export function FormErrorMessage({ icon, error }: FormErrorMessageProps) {
  if (!error) {
    return null;
  }

  return (
    <FormMessage icon={icon || 'warning'} intent="danger">
      {error.message}
    </FormMessage>
  );
}
