import { QueryKey, UseMutationOptions } from '@tanstack/react-query';

import { useAPI } from 'hooks/api/useAPI';

import Stream from 'types/stream';
import APIError from 'types/api_error';

import { useAppQueryClient } from 'hooks/useAppQueryClient';

import { useDangerousMutation } from 'hooks/api/useDangerousMutation';

type UseDeleteStreamContext = {
  previousStreams: Stream[] | undefined;
} | void;

export function useDeleteStream(
  queryKey: QueryKey,
  {
    onMutate,
    onError,
    onSettled,
    ...options
  }: UseMutationOptions<
    Stream | void,
    APIError,
    Stream['id'],
    UseDeleteStreamContext
  > = {}
) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete this stream?',
    (streamID) => {
      if (!streamID) {
        return Promise.resolve();
      }
      return api.streams.delete(streamID);
    },
    {
      ...options,
      onMutate: async (streamID) => {
        await queryClient.cancelQueries(queryKey);
        const previousStreams = queryClient.getQueryData<Stream[]>(queryKey);

        if (previousStreams) {
          const updatedStreams = previousStreams.filter(
            ({ id }) => id !== streamID
          );
          queryClient.setQueryData(queryKey, updatedStreams);
        }

        if (onMutate) {
          onMutate(streamID);
        }

        return { previousStreams };
      },
      onError: (err, deletedStream, context) => {
        if (context?.previousStreams) {
          queryClient.setQueryData(queryKey, context.previousStreams);
        }

        if (onError) {
          onError(err, deletedStream, context);
        }
      },
      onSettled: async (...args) => {
        await queryClient.cancelQueries(queryKey);
        queryClient.invalidateQueries(queryKey);

        if (onSettled) {
          onSettled(...args);
        }
      },
    }
  );
}
