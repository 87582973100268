import { AxiosError } from 'axios';
import { QueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';

export type ResponseInterceptorOptions = {
  redirectToLogin?: () => void;
};

// Reject promise with custom error containing status-based message
export default function errorResponseInterceptor(
  axiosError: AxiosError<APIError>,
  queryClient?: QueryClient,
  { redirectToLogin }: ResponseInterceptorOptions = {}
) {
  if (axiosError.response) {
    const { status, data } = axiosError.response;

    switch (status) {
      case 401: {
        if (data.code === 'expired-access-token') {
          redirectToLogin?.();
          break;
        }

        if (data.code === 'route-not-allowed') {
          queryClient?.invalidateQueries(['account']);
          break;
        }
      }
    }
  }

  return Promise.reject(axiosError.response?.data ?? axiosError);
}
