import React from 'react';
import classnames from 'classnames/bind';
import { DateTime } from 'luxon';
import { useToggle } from '@mantine/hooks';
import { Skeleton } from '@mantine/core';

import Stream from 'types/stream';

import { useDeleteSharedStream } from 'streams/hooks/useDeleteSharedStream';
import {
  SharedStreamsParams,
  useSharedStreams,
  UseSharedStreamsParams,
} from 'streams/hooks/useSharedStreams';

import { Button } from 'components/Button/Button';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { Pagination } from 'components/Pagination/Pagination';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Text } from 'components/Text/Text';

import { CreateSharedStreamDrawer } from './CreateSharedStreamDrawer';
import styles from './SharedStreamsList.module.scss';

const c = classnames.bind(styles);

type SharedStreamsListProps = {
  params: SharedStreamsParams;
  defaultStream?: Stream;
};

export function SharedStreamsList({
  params: sharedStreamParams,
  defaultStream,
}: SharedStreamsListProps) {
  const [params, setParams] = React.useState<UseSharedStreamsParams>({
    ...sharedStreamParams,
  });

  const { data, isPreviousData, isLoading, isFetching } = useSharedStreams({
    params,
    keepPreviousData: true,
  });

  const [requestDelete] = useDeleteSharedStream();

  const [isCreatingSharedStream, toggleIsCreatingSharedStream] = useToggle();

  const hasSharedStreams = data && data.data.length > 0;

  return (
    <div className={c('wrap')}>
      <div className={c('header', 'border')}>
        <Button
          variant="primary"
          size="small"
          onClick={() => toggleIsCreatingSharedStream()}
        >
          Create shared stream
        </Button>
      </div>

      <ul className={c('list')}>
        {isLoading || (isFetching && isPreviousData) ? (
          <SkeletonList
            min={hasSharedStreams ? data.data.length : 5}
            max={hasSharedStreams ? data.data.length : 5}
            component={
              <li className={c('item')}>
                <Skeleton height="1rem" width="33rem" />
                <Skeleton height="1rem" width="12.5rem" />
                <Skeleton height="1rem" width="1rem" radius="1rem" />
              </li>
            }
          />
        ) : (
          <>
            {!hasSharedStreams && <EmptyView>No shared streams.</EmptyView>}

            {hasSharedStreams && (
              <>
                {data.data.map(({ id, name, expires_at }) => {
                  return (
                    <li key={id} className={c('item', 'border')}>
                      <Heading className={c('name')} level="4">
                        {name}
                      </Heading>
                      <div>
                        {expires_at && (
                          <Text>
                            Expires{' '}
                            {DateTime.fromISO(expires_at).toLocaleString(
                              DateTime.DATETIME_MED
                            )}
                          </Text>
                        )}
                      </div>
                      <IconButton
                        icon="delete"
                        label="Delete shared stream"
                        onClick={() => requestDelete({ id })}
                      />
                    </li>
                  );
                })}
              </>
            )}
          </>
        )}

        {data && data.total_pages > 1 && (
          <Pagination
            className={c('pagination')}
            total={data.total_pages}
            value={params.page}
            onChange={(page) => {
              setParams((previousParams) => ({ ...previousParams, page }));
            }}
          />
        )}
      </ul>

      <CreateSharedStreamDrawer
        open={isCreatingSharedStream}
        onClose={toggleIsCreatingSharedStream}
        params={params}
        defaultStream={defaultStream}
      />
    </div>
  );
}
