import { Token } from 'leac';

// Separates a trigger conditions input text into adjacent ranges that all have a list of all elements and errors
// that exist in that range.
export function calculateDisplayRanges(
  triggerCondition: TriggerCondition,
  filter: (
    element: TriggerConditionElement | ValidationError
  ) => boolean = errorsAndVariableFilter
): DisplayRange[] {
  const elements = triggerCondition.allElements().filter(filter);
  const errors = triggerCondition.validationErrorsOfEntireTree().filter(filter);

  const nonOverlappingRanges = createNonoverlappingDisplayRanges([
    ...[...errors, ...elements].filter(filter),
    { range: InputRange.fromText(triggerCondition.range.input) },
  ]);
  for (const nonOverlappingRange of nonOverlappingRanges) {
    const { range } = nonOverlappingRange;
    // TODO: Reduce algorithmic complexity if this gets too slow
    for (const error of errors) {
      if (error.range.containsRange(range)) {
        nonOverlappingRange.errors.push(error);
      }
    }

    for (const element of elements) {
      if (element.range.containsRange(range)) {
        nonOverlappingRange.elements.push(element);
      }
    }
  }

  return nonOverlappingRanges;
}

function errorsAndVariableFilter(
  element: TriggerConditionElement | ValidationError
): boolean {
  if (!(element instanceof TriggerConditionElement)) {
    // ValidationError
    return true;
  }

  return element instanceof AccessorSegment && element.isVariable;
}

function createNonoverlappingDisplayRanges(
  elements: { range: InputRange }[]
): DisplayRange[] {
  const input = elements[0]?.range?.input;
  if (input === undefined) {
    return [];
  }

  const allPositions = new Set<number>();
  for (const element of elements) {
    allPositions.add(element.range.begin);
    allPositions.add(element.range.end);
  }

  const sortedPositions = [...allPositions.values()].sort((a, b) => a - b);

  const displayRanges: DisplayRange[] = [];

  let previousPosition: undefined | number;
  for (const position of sortedPositions) {
    if (previousPosition === undefined) {
      previousPosition = position;
      continue;
    }

    displayRanges.push({
      range: new InputRange(input, previousPosition, position),
      errors: [],
      elements: [],
    });

    previousPosition = position;
  }

  return displayRanges;
}

export type DisplayRange = {
  range: InputRange;
  errors: ValidationError[];
  elements: TriggerConditionElement[];
};

export abstract class TriggerConditionElement {
  readonly range: InputRange;
  readonly children: TriggerConditionElement[];

  private parentElement?: TriggerConditionElement;
  private parentheses = 0;
  private validationErrorList: ValidationError[] = [];
  private isValidated = false;

  protected constructor(
    range: InputRange,
    children: TriggerConditionElement[]
  ) {
    this.range = range;
    this.children = children;

    let lastEndPosition: undefined | number;
    for (const child of children) {
      if (child.parentElement !== undefined) {
        throw new Error('Child already has a parent.');
      }

      if (
        lastEndPosition !== undefined &&
        child.range.begin < lastEndPosition
      ) {
        throw new Error(
          "Children's ranges must not overlap and must be in order from left to right"
        );
      }
      lastEndPosition = child.range.end;

      child.parentElement = this;
    }
  }

  protected abstract performValidation(): void;

  validationErrorsOfSingleElement(): ValidationError[] {
    // NOTE: It's necessary to validate the entire tree because validation logic of parent elements
    // can result in validation errors in this element.
    this.validateEntireTree();

    return this.validationErrorList;
  }

  validationErrorsOfEntireTree(): ValidationError[] {
    const root = this.findRoot();
    root.validateEntireTree();
    return root.recursiveValidationErrors();
  }

  private recursiveValidationErrors(): ValidationError[] {
    return [
      ...this.validationErrorList,
      ...this.children.flatMap((child) => child.recursiveValidationErrors()),
    ];
  }

  private validateEntireTree() {
    const root = this.findRoot();
    root.validateAllChildren();
    root.validateSingleElement();
  }

  private validateAllChildren() {
    for (const child of this.children) {
      child.validateAllChildren();
      child.validateSingleElement();
    }
  }

  private validateSingleElement() {
    if (this.isValidated) {
      return;
    }

    this.performValidation();

    if (this.parentheses !== 0) {
      this.addValidationError(
        'Parentheses are not supported yet.',
        this.range.with(this.range.begin, this.range.begin + this.parentheses)
      );

      this.addValidationError(
        'Parentheses are not supported yet.',
        this.range.with(this.range.end - this.parentheses)
      );
    }

    this.isValidated = true;
  }

  // add a validation error and uphold the invariant that validation errors
  // are always at the deepest tree node that they can be.
  addValidationError(message: string, range?: InputRange) {
    const errorRange = range ?? this.range;
    if (!this.range.containsRange(errorRange)) {
      throw new Error(
        'Tried to add validation error with a range outside of the current element.'
      );
    }

    for (const child of this.children) {
      if (child.range.containsRange(errorRange)) {
        child.addValidationError(message, range);
        return;
      }
    }

    this.validationErrorList.push({
      message,
      range: errorRange,
      element: this,
    });
  }

  findRoot(): TriggerConditionElement {
    if (this.parentElement === undefined) {
      return this;
    }

    return this.parentElement.findRoot();
  }

  addParentheses(count: number) {
    this.parentheses += count;
    this.range.begin = Math.max(this.range.begin - count, 0);
    this.range.end += count;
  }

  get parent(): TriggerConditionElement | undefined {
    return this.parentElement;
  }

  get isRoot(): boolean {
    return this.parentElement === undefined;
  }

  get isLeaf(): boolean {
    return this.children.length === 0;
  }

  allElements(): TriggerConditionElement[] {
    if (this.isLeaf) {
      return [this];
    }

    return [this, ...this.children.flatMap((child) => child.allElements())];
  }

  get neighbors(): Neighbors {
    return {
      left: this.findNeighbor('left'),
      right: this.findNeighbor('right'),
    };
  }

  private findNeighbor(
    direction: 'left' | 'right'
  ): TriggerConditionElement | undefined {
    let currentElement: TriggerConditionElement = this;
    while (true) {
      const parent = currentElement.parentElement;
      if (parent === undefined) {
        return undefined;
      }

      let index = -1;
      for (let i = 0; i < parent.children.length; i++) {
        if (Object.is(currentElement, parent.children[i])) {
          index = i;
        }
      }

      const neighbor = parent.children[index + (direction === 'left' ? -1 : 1)];
      if (neighbor === undefined) {
        currentElement = parent;
        continue;
      }

      return neighbor;
    }
  }
}

export type Neighbors = {
  left?: TriggerConditionElement;
  right?: TriggerConditionElement;
};

export class InputRange {
  input: string;
  // index of the first character of the range
  begin: number;
  // index after the last character of the range (same as the arguments to String.slice)
  end: number;

  constructor(input: string, begin: number, end: number) {
    this.input = input;
    this.begin = begin;
    this.end = end;
  }

  static fromToken(input: string, token: Token): InputRange {
    return new InputRange(input, token.offset, token.offset + token.len);
  }

  static fromText(input: string) {
    return new InputRange(input, 0, input.length);
  }

  with(begin: number, end?: number): InputRange {
    return new InputRange(this.input, begin, end ?? this.end);
  }

  containsRange(range: InputRange): boolean {
    return this.begin <= range.begin && this.end >= range.end;
  }

  relationshipToCursor(position: number): RelationShipToCursor {
    if (position === this.begin && position === this.end) {
      // special case for ranges with length of zero.
      return {
        distance: 0,
        side: 'equal',
      };
    }

    if (position <= this.begin) {
      return {
        distance: this.begin - position,
        side: 'right',
      };
    }

    if (position >= this.end) {
      return {
        distance: position - this.end,
        side: 'left',
      };
    }

    return {
      distance: 0,
      side: 'surrounding',
    };
  }

  get text(): string {
    return this.input.slice(this.begin, this.end);
  }
}

export type RelationShipToCursor = {
  distance: number;
  side:
    | 'left' // the element is to the left of the cursor
    | 'right' // the element is to the right of the cursor
    | 'surrounding' // the element is surrounding the cursor (the cursor is inside the element)
    | 'equal'; // the element is equal to the cursor (element has 0 length and is at the same position as the cursor)
};

export class TriggerCondition extends TriggerConditionElement {
  condition: Condition | EmptyElement | InvalidInput;

  constructor(condition: Condition | InvalidInput) {
    super(condition.range, [condition]);
    this.condition = condition;
  }

  protected performValidation() {}
}

export class InvalidInput extends TriggerConditionElement {
  // not useless because it makes the constructor publicly accessible
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(range: InputRange) {
    super(range, []);
  }

  protected performValidation() {
    this.addValidationError(
      "Parsing failed. Trigger conditions follow the pattern 'a > 1 and b < 1'",
      this.range
    );
  }
}

export abstract class Condition extends TriggerConditionElement {}

export class ChainedCondition extends Condition {
  leftHandSide: Condition | EmptyElement;
  operator: LogicalOperator;
  rightHandSide: Condition | EmptyElement;

  constructor(
    leftHandSide: Condition,
    operator: LogicalOperator,
    rightHandSide: Condition
  ) {
    const range = rightHandSide.range.with(leftHandSide.range.begin);
    super(range, [leftHandSide, operator, rightHandSide]);

    this.leftHandSide = leftHandSide;
    this.operator = operator;
    this.rightHandSide = rightHandSide;
  }

  protected performValidation() {}
}

export class SingleCondition extends Condition {
  condition: Comparison | Accessor | Value | EmptyElement;

  constructor(condition: Comparison | Accessor | Value | EmptyElement) {
    super(condition.range, [condition]);

    this.condition = condition;
  }

  protected performValidation() {
    if (this.condition instanceof Value) {
      this.condition.addValidationError(
        'Values are not allowed as conditions. Try a variable or a comparison instead.'
      );
    }
  }
}

export class Comparison extends TriggerConditionElement {
  leftHandSide: Accessor | Value | EmptyElement;
  comparator: ComparisonOperator;
  rightHandSide: Accessor | Value | EmptyElement;

  constructor(
    leftHandSide: Accessor | Value | EmptyElement,
    comparator: ComparisonOperator,
    rightHandSide: Accessor | Value | EmptyElement
  ) {
    const range = rightHandSide.range.with(leftHandSide.range.begin);
    super(range, [leftHandSide, comparator, rightHandSide]);

    this.leftHandSide = leftHandSide;
    this.comparator = comparator;
    this.rightHandSide = rightHandSide;
  }

  protected performValidation() {
    if (!(this.leftHandSide instanceof Accessor)) {
      this.leftHandSide.addValidationError(
        'The left-hand side of a comparison must be a variable.'
      );
    }

    if (
      !(this.rightHandSide instanceof Value) ||
      typeof this.rightHandSide.value === 'boolean'
    ) {
      this.rightHandSide.addValidationError(
        'The right-hand side of a comparison must be a number or string.'
      );
    }
  }
}

export class Accessor extends TriggerConditionElement {
  segments: AccessorSegment[];

  constructor(segments: AccessorSegment[], range: InputRange) {
    super(range, segments);
    this.segments = segments;
  }

  protected performValidation() {
    const { text } = this.range;
    for (const match of text.matchAll(/\s+/g)) {
      const index = match.index!;
      this.addValidationError(
        'Variables must not contain whitespace.',
        new InputRange(this.range.input, index, index + 2)
      );
    }

    if (text.startsWith('.')) {
      this.addValidationError(
        'Variables must not start with a period character.',
        new InputRange(this.range.input, this.range.begin, this.range.begin + 1)
      );
    }

    if (text.endsWith('.')) {
      this.addValidationError(
        'Variables must not end with a period character.',
        new InputRange(this.range.input, this.range.end - 1, this.range.end)
      );
    }
  }
}

export class AccessorSegment extends TriggerConditionElement {
  openingSquareBracket: boolean;
  value: string;
  closingSquareBracket: boolean;

  constructor(
    value: string,
    leftSquareBracket: boolean,
    rightSquareBracket: boolean,
    range: InputRange
  ) {
    super(range, []);

    this.openingSquareBracket = leftSquareBracket;
    this.value = value;
    this.closingSquareBracket = rightSquareBracket;
  }

  get isVariable(): boolean {
    return this.openingSquareBracket || this.closingSquareBracket;
  }

  protected performValidation() {
    if (this.isVariable) {
      this.addValidationError('The placeholder still needs to be filled in.');
    }
  }
}

export class Value extends TriggerConditionElement {
  value: string | number | boolean;

  constructor(value: string | number | boolean, range: InputRange) {
    super(range, []);
    this.value = value;
  }

  protected performValidation() {
    if (typeof this.value === 'string') {
      if (this.value.startsWith("'")) {
        this.addValidationError(
          'Single quoted strings are not supported. Try double quotes instead.'
        );
      }

      for (const match of this.value.matchAll(/\\"/g)) {
        const index = match.index!;
        this.addValidationError(
          'Strings do not yet support escaped double quotes.',
          new InputRange(this.range.input, index, index + 2)
        );
      }
    }
  }
}

export class ComparisonOperator extends TriggerConditionElement {
  operator: ComparisonOperatorType;

  constructor(comparator: ComparisonOperatorType, range: InputRange) {
    super(range, []);
    this.operator = comparator;
  }

  protected performValidation() {
    if (this.operator === '==') {
      this.addValidationError("'==' is not allowed. Try '=' instead.");
    }
  }
}

export type ComparisonOperatorType =
  | '>'
  | '<'
  | '>='
  | '<='
  | '='
  | '=='
  | '!=';

export class LogicalOperator extends TriggerConditionElement {
  operator: LogicalOperatorType;

  constructor(operator: LogicalOperatorType, range: InputRange) {
    super(range, []);
    this.operator = operator;
  }

  protected performValidation() {
    if (this.operator === '&&') {
      this.addValidationError("'&&' is not supported. Try 'and' instead");
    }

    if (this.operator === '||') {
      this.addValidationError("'||' is not supported. Try 'or' instead");
    }
  }
}

export type LogicalOperatorType = '&&' | '||' | 'and' | 'or';

export class EmptyElement extends TriggerConditionElement {
  constructor(range: InputRange) {
    super(range, []);
  }

  protected performValidation() {
    this.addValidationError('Incomplete trigger condition.');
  }
}

export type ValidationError = {
  // human readable error message
  message: string;
  // back reference to the element that has the error
  element: TriggerConditionElement;
  // NOTE: This isn't necessarily the same as the elements range. Can be a subrange.
  range: InputRange;
};
