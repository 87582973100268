import React from 'react';
import classnames from 'classnames/bind';
import { captureMessage } from '@sentry/react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import Camera, { CameraConnectionType } from 'types/camera';
import Gateway from 'types/gateway';
import { ApplicationParams } from 'application/types/application_params';
import { DeployRoute, NavRoute } from 'application/types/routes';

import { useCreateCamera } from 'cameras/hooks/useCreateCamera';
import { useGateways } from 'hooks/api/useGateways';
import { useSpringState } from 'hooks/useSpringState';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import { Radio, RadioOption } from 'components/Radio/Radio';
import { Separator } from 'components/Separator/Separator';
import { Text } from 'components/Text/Text';

import styles from './AddCameraManuallyForm.module.scss';

const c = classnames.bind(styles);

// https://digitalfortress.tech/tricks/top-15-commonly-used-regex/
// also see https://www.regexpal.com/?fam=104038
const IP_REGEX =
  /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

type AddIPCameraFieldValues = {
  ip: string;
  name?: string;

  gateway_id?: string;

  username?: string;
  password?: string;

  external_id?: string;

  uri?: never;
};

type AddUSBCameraFieldValues = {
  uri: string;

  gateway_id?: string;

  external_id?: string;

  ip?: never;
  name?: never;
  username?: never;
  password?: never;
};

type AddVirtualCameraFieldValues = {
  name?: string;
  uri: string;

  gateway_id?: string;

  external_id?: string;

  ip?: never;
  username?: never;
  password?: never;
};

type AddCameraFieldValues =
  | AddIPCameraFieldValues
  | AddUSBCameraFieldValues
  | AddVirtualCameraFieldValues;

export type AddCameraManuallyFormProps = {
  gateway?: Gateway;
  defaultCameraType?: CameraConnectionType;
  onSuccess?: () => void;
};

export function AddCameraManuallyForm({
  gateway,
  defaultCameraType,
  onSuccess,
}: AddCameraManuallyFormProps) {
  const { applicationID } = useParams<ApplicationParams>();
  const { data: gateways } = useGateways();

  const [type, setType] = React.useState<string>(defaultCameraType || 'remote');
  const [didCreate, setDidCreate] = useSpringState(false, 2000);
  const navigate = useNavigate();

  const {
    formState: { errors: formErrors },
    register,
    handleSubmit,
    reset: resetForm,
    control,
  } = useForm<AddCameraFieldValues>();

  const {
    isLoading,
    error: cameraCreationError,
    mutate: createCamera,
    reset,
  } = useCreateCamera({
    onSuccess() {
      setDidCreate(true);
      resetForm();
    },
  });

  function handleCreateCamera({
    ip,
    name,
    gateway_id,
    username,
    password,
    uri,
    external_id,
  }: AddCameraFieldValues) {
    if (!applicationID) {
      return;
    }

    const cameraGateway =
      gateway ?? gateways?.data.find((gateway) => gateway.id === gateway_id);

    if (!cameraGateway && type !== 'virtual') {
      captureMessage(
        'A gateway must be selected in cases where the camera is not a virtual camera.'
      );
      return;
    }

    const camera = new Camera('', name || '', applicationID);
    camera.gateway_id = gateway_id;
    camera.conn_type = type as CameraConnectionType;
    camera.username = username;
    camera.password = password;
    // external_id's are unique across an application. If the empty string would be a valid id, we could
    // only have one camera with that id. An undefined (null) value (in the database) is usable many times.
    // Also trimming is a good idea i guess.
    camera.external_id =
      external_id?.trim()?.length === 0 ? undefined : external_id?.trim();

    if (type === 'local') {
      if (typeof uri === 'string') {
        if (uri.startsWith('/')) {
          camera.uri = `file://${uri}`;
        } else {
          camera.uri = `file:///${uri}`;
        }
      }

      // gateway is required for local cameras but not for virtual cameras
      if (!name && cameraGateway) {
        camera.name = `${cameraGateway.name} - ${uri}`;
      }
    } else if (type === 'remote') {
      const target = '/onvif/device_service';

      if (camera.uri && IP_REGEX.test(camera.uri)) {
        camera.uri = ip + target;
      } else {
        camera.uri = `http://${ip}${target}`;
      }

      if (!name && typeof ip === 'string') {
        camera.name = ip;
      }
    } else if (type === 'virtual') {
      camera.status = 'unknown';
      camera.model = 'Virtual';
    }

    createCamera(camera);
  }

  React.useEffect(() => {
    if (didCreate) {
      reset();
      resetForm();

      if (onSuccess) {
        onSuccess();
      } else {
        navigate(
          `/applications/${applicationID}/${NavRoute.DEPLOY}/${DeployRoute.CAMERAS}`
        );
      }
    }
  }, [didCreate, reset, resetForm, onSuccess, navigate, applicationID]);

  return (
    <form className={c('form')} onSubmit={handleSubmit(handleCreateCamera)}>
      <FormErrorMessage error={cameraCreationError} />

      <Field label="Camera type">
        <Radio
          id="add-camera-type"
          onChange={(type) => setType(String(type))}
          value={type}
        >
          <RadioOption value="remote">ONVIF camera</RadioOption>
          <RadioOption value="local">USB camera</RadioOption>
          <RadioOption value="virtual">Virtual</RadioOption>
        </Radio>
      </Field>

      {!gateway && (
        <Field
          label="Gateway"
          error={formErrors.gateway_id}
          key="gateway_id"
          required={type === 'local' || type === 'remote'}
        >
          <Controller
            control={control}
            name="gateway_id"
            rules={{
              required:
                type === 'local' || type === 'remote'
                  ? 'Please select a gateway.'
                  : false,
            }}
            render={({ field: { onChange } }) => (
              <GatewaySelect
                onChange={(gateway) => {
                  onChange(gateway?.id);
                }}
                isSearchable
                required={type === 'local' || type === 'remote'}
              />
            )}
          />
        </Field>
      )}

      {gateway && (
        <input type="hidden" {...register('gateway_id')} value={gateway.id} />
      )}

      {type === 'remote' && (
        <>
          <Field label="IP address" error={formErrors.ip} key="ip" required>
            <Input
              id="ac_ip"
              {...register('ip', { required: 'Please enter an IP address.' })}
              error={Boolean(formErrors.ip)}
              autoFocus
            />
          </Field>
          <Field label="Camera name">
            <Input id="ac_name" {...register('name')} />
          </Field>

          <Separator />

          <Heading level="2" className="name">
            Credentials
          </Heading>
          <Field label="Username" key="username">
            <Input id="ac_username" {...register('username')} />
          </Field>

          <Field label="Password" key="password">
            <Input id="ac_password" type="password" {...register('password')} />
          </Field>
        </>
      )}

      {type === 'local' && (
        <div key="fileDescriptor">
          <Field label="Device file path" error={formErrors.uri} required>
            <Input
              id="ac_uri"
              placeholder="/dev/video0"
              {...register('uri', {
                required: 'Please enter a valid device file path.',
              })}
              prefix="file://"
              autoFocus
            />
          </Field>
        </div>
      )}

      {type === 'virtual' && (
        <Field label="Camera name" error={formErrors.name} required>
          <Input
            id="ac_name"
            {...register('name', {
              required: 'Please enter a camera name.',
            })}
            autoFocus
          />
        </Field>
      )}

      <Separator />

      <Heading level="2" className="name">
        Universal bridge
      </Heading>

      <Field label="External source identifier" key="name">
        <Input id="ac_external_id" {...register('external_id')} />
        <Text type="paragraph">
          Universal Bridge will associate incoming clips with this camera using
          this identifier.
        </Text>
      </Field>

      <ButtonGroup>
        <Button type="submit" variant="primary" loading={isLoading}>
          Add camera
        </Button>
      </ButtonGroup>
    </form>
  );
}
