import React from 'react';
import classNames from 'classnames/bind';
import {
  DatePickerInput as Cmp,
  DatePickerInputProps,
  DatePickerType,
} from '@mantine/dates';

import { Icon } from 'components/Icon/Icon';

import styles from './DatePickerInput.module.scss';

const c = classNames.bind(styles);

export function DatePickerInput<Type extends DatePickerType = 'default'>({
  className,
  ...props
}: Omit<DatePickerInputProps<Type>, 'classNames'>) {
  return (
    <Cmp
      {...props}
      classNames={{
        input: c('input', className),
        placeholder: c('input-placeholder'),
        day: c('calendar-day', 'interactive'),
        calendarHeaderLevel: c('interactive'),
        calendarHeaderControl: c('interactive'),
      }}
      leftSection={<Icon name="calendar" />}
    />
  );
}

export type { DatePickerInputProps };
