import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { DeploymentQueue } from 'types/deployment_queue';
import { OffsetPaginated, OffsetPaginationParams } from 'types/pagination';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export type UseDeploymentsQueuesParams = OffsetPaginationParams & {
  queue_ids?: DeploymentQueue['id'][];
  queue_names?: DeploymentQueue['name'][];
};

type UseDeploymentsQueuesOptions = UseQueryOptions<
  OffsetPaginated<DeploymentQueue>,
  APIError
> & {
  params?: UseDeploymentsQueuesParams;
};

export function useDeploymentsQueues({
  params,
  ...options
}: UseDeploymentsQueuesOptions = {}) {
  const { applicationID, client } = useAPI();

  const queryParams = {
    pagination: 'offset',
    page: 1,
    ...params,
  };

  return useAuthenticatedQuery(
    ['deployment-queues', queryParams, applicationID],
    async () => {
      const { data } = await client.get<OffsetPaginated<DeploymentQueue>>(
        `/apps/${applicationID}/deployment_queues`,
        {
          params: queryParams,
        }
      );

      return data;
    },
    options
  );
}
