import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { LumeoEvent, LumeoEventSeverity } from 'types/event';
import { OffsetPaginated, OffsetPaginationParams } from 'types/pagination';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export type UseEventsParams = {
  categories?: string[];
  event_types?: string[];
  severities?: LumeoEventSeverity[];
  since?: string;
  until?: string;
  object_ids?: string[];
  object_types?: string[];
};

type UseEventsOptions = UseQueryOptions<
  OffsetPaginated<LumeoEvent>,
  APIError
> & {
  params?: UseEventsParams & OffsetPaginationParams;
};

export function useEvents({
  queryKey = ['events'],
  params,
  ...options
}: UseEventsOptions = {}) {
  const { applicationID, client } = useAPI();
  const queryParams = {
    pagination: 'offset',
    page: 1,
    limit: 50,
    ...params,
  };

  return useAuthenticatedQuery(
    [...queryKey, applicationID, params],
    async () => {
      const { data } = await client.get<OffsetPaginated<LumeoEvent>>(
        `/apps/${applicationID}/events`,
        {
          params: queryParams,
        }
      );

      return data;
    },
    options
  );
}
