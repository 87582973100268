import Konva from 'konva';

import { ROI_CONFIG } from '../config';

function getValueInBounds(length: number, num: number): number {
  if (num < ROI_CONFIG.padding) {
    return ROI_CONFIG.padding;
  }

  if (num > length - ROI_CONFIG.padding) {
    return length - ROI_CONFIG.padding;
  }

  return num;
}
function getValueInClickableBounds(length: number, num: number): number | null {
  if (
    num < ROI_CONFIG.padding - ROI_CONFIG.clickablePadding ||
    num > length - (ROI_CONFIG.padding - ROI_CONFIG.clickablePadding)
  ) {
    return null;
  }

  return getValueInBounds(length, num);
}

/**
 * Shift pointer position to be within clickable bounds.
 * The canvas has a non-interactive border to fully display lines and labels.
 */
export function getPointerClickPosition(
  stage: Konva.Stage | null
): Konva.Vector2d | null {
  if (!stage?.pointerPos) {
    return null;
  }

  const x = getValueInClickableBounds(stage.width(), stage.pointerPos.x);
  const y = getValueInClickableBounds(stage.height(), stage.pointerPos.y);

  if (x === null || y === null) {
    return null;
  }

  return { x, y };
}

/**
 * Shift pointer position to be within visible bounds.
 * The canvas has a non-interactive border to fully display lines and labels.
 */
export function getPointerPosition(
  stage: Konva.Stage | null
): Konva.Vector2d | null {
  if (!stage?.pointerPos) {
    return null;
  }

  const x = getValueInBounds(stage.width(), stage.pointerPos.x);
  const y = getValueInBounds(stage.height(), stage.pointerPos.y);

  return { x, y };
}
