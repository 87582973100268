import React, { ReactNode } from 'react';

export type SkeletonListProps = {
  component: ReactNode;
  min?: number;
  max?: number;
};

export function SkeletonList({
  min = 2,
  max = 7,
  component,
}: SkeletonListProps) {
  const helper = new Array(Math.max(min, Math.floor(Math.random() * max))).fill(
    1
  );

  return (
    <>
      {helper.map((_, index) => (
        <React.Fragment key={index}>{component}</React.Fragment>
      ))}
    </>
  );
}
