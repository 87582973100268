import Node from 'types/node';

function nodeIDIndex(nodeID: string): number {
  const chars = nodeID.slice(0, nodeID.search(/\d/));
  return Number(nodeID.replace(chars, ''));
}

export function sortByNodeIDAndVideoSourcesFirst(nodeA: Node, nodeB: Node) {
  const isVideoSource = (id: string) => id.includes('video');
  const a = nodeA.id!;
  const b = nodeB.id!;

  if (isVideoSource(a) && !isVideoSource(b)) {
    return -1;
  }

  if (!isVideoSource(a) && isVideoSource(b)) {
    return 1;
  }

  return nodeIDIndex(a) - nodeIDIndex(b);
}
