import React from 'react';
import classnames from 'classnames/bind';

import styles from './Textarea.module.scss';

const c = classnames.bind(styles);

export type TextareaVariant = 'default' | 'code';

export type TextareaProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    className?: string;
    height?: string;
    variant?: TextareaVariant;
    size?: 'small';
  };

function Textarea(
  {
    className,
    height = '100px',
    variant = 'default',
    size,
    ...props
  }: TextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) {
  return (
    <div className={c('wrap', variant, size, className)}>
      <textarea
        {...props}
        className={c('field')}
        style={{ height }}
        ref={ref}
      />
    </div>
  );
}

const forwardRef = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  Textarea
);

export { forwardRef as Textarea };
