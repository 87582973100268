import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Userpilot } from 'userpilot';

import Account from 'types/account';
import APIError from 'types/api_error';

import { getAuthAPI, postAuthAPI } from 'services/api_auth';

export type LogoutOptionsType = {
  returnTo?: string;
};

export type LoginParams = {
  email: string;
  password: string;
};

export type ResetPasswordParams = {
  password: string;
  token: string;
};

export type AuthenticationContextProps =
  | {
      user?: Account;
      userID?: string;
      setUser: (user: Account) => void;
      logout: () => void;
      isLoading: boolean;
      isAuthenticated: boolean;
      loginReset: () => void;
      login: (params: LoginParams) => void;
      samlLogin: () => void;
      loginError?: APIError;
      samlLoginError?: APIError;
      isLoggingIn?: boolean;
      isSamlLoggingIn?: boolean;
      authToken?: string;

      // reset password flow
      setPassword: (params: ResetPasswordParams) => void;
      setPasswordError?: APIError;
      isSettingPassword?: boolean;
    }
  | undefined;

export const AuthenticationContext =
  React.createContext<AuthenticationContextProps>(undefined);

export function useAuthenticationOptional() {
  return React.useContext(AuthenticationContext);
}

export function useAuthentication() {
  const context = React.useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error(
      '`useAuth` can only be used within an `AuthenticationProvider`.'
    );
  }

  return context;
}

type TokenResponse = {
  token: string;
};

const CONSOLE_TOKEN = 'console.token';

export type AuthenticationProviderProps = React.PropsWithChildren<{
  token?: string;
}>;

export function AuthenticationProvider({
  token: initialToken,
  children,
}: AuthenticationProviderProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const returnTarget = React.useRef<string>();

  const queryClient = useQueryClient();

  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);

  const [token, setToken] = React.useState<string | undefined>(initialToken);
  const [userID, setUserID] = React.useState<string>();
  const [user, setUser] = React.useState<Account>();

  const logout = React.useCallback(() => {
    setToken(undefined);
    setIsAuthenticated(false);
    setUser(undefined);
    setUserID(undefined);
    queryClient.setQueryData(['account'], undefined);
    queryClient.clear();
    localStorage.removeItem(CONSOLE_TOKEN);
  }, [queryClient]);

  const loginMutation = useMutation(
    (params: LoginParams) =>
      postAuthAPI('/internal/auth/login', params).then((res) => res.json()),
    {
      onSuccess: handleTokenResponse,
      onError: () => setIsAuthenticated(false),
    }
  );

  const samlLoginMutation = useMutation(
    () =>
      getAuthAPI('/internal/auth/saml_login', undefined).then((res) =>
        res.json()
      ),
    {
      onSuccess: handleTokenResponse,
      onError: () => setIsAuthenticated(false),
    }
  );

  const setPasswordMutation = useMutation(
    (params: ResetPasswordParams) =>
      postAuthAPI('/internal/auth/set_password', params).then((res) =>
        res.json()
      ),
    {
      onSuccess: handleTokenResponse,
    }
  );

  function handleTokenResponse(data: TokenResponse) {
    try {
      const { account_id } = JSON.parse(atob(data.token.split('.')[1]));

      localStorage.setItem(CONSOLE_TOKEN, data.token);
      setToken(data.token);

      queryClient.invalidateQueries(['account']);
      setUserID(account_id);
      setIsAuthenticated(true);

      navigate(location.state?.from ?? '/', { replace: true });

      Userpilot.identify(account_id);
    } catch (error) {
      setToken(undefined);
      setIsAuthenticated(false);
      localStorage.removeItem(CONSOLE_TOKEN);
    }

    queryClient.invalidateQueries(['account']);
  }

  // To be used when the JWT gotten from /login or a previous /refresh_session call expires.
  const { mutate: refreshSession } = useMutation<TokenResponse>(
    () => {
      const token = localStorage.getItem(CONSOLE_TOKEN);

      if (token) {
        return postAuthAPI('/internal/auth/refresh_session', undefined, {
          token,
        }).then((res) => res.json());
      }

      return Promise.reject('Not authenticated');
    },
    {
      onSuccess: (data) => {
        setIsAuthenticated(true);
        setToken(data.token);

        const { account_id } = JSON.parse(atob(data.token.split('.')[1]));
        queryClient.invalidateQueries(['account']);
        setUserID(account_id);

        if (returnTarget.current) {
          navigate(returnTarget.current);
          returnTarget.current = undefined;
        }
      },
      onError: logout,
      onSettled: () => {
        setIsAuthenticating(false);
      },
    }
  );

  // Check if user has an active session.
  // Keep authenticated if session is active, otherwise mark as signed out.
  React.useEffect(() => {
    if (isAuthenticating && !isAuthenticated) {
      refreshSession();
    }
  }, [isAuthenticating, isAuthenticated, refreshSession]);

  const login = React.useCallback(
    async (params: LoginParams) => {
      await loginMutation.mutateAsync(params);

      if (location.state?.from) {
        navigate(location.state.from);
      }
    },
    [location.state, loginMutation, navigate]
  );

  return (
    <AuthenticationContext.Provider
      value={{
        userID,
        user,
        setUser,
        login,
        samlLogin: samlLoginMutation.mutate,
        loginError: loginMutation.error as APIError | undefined,
        samlLoginError: samlLoginMutation.error as APIError | undefined,
        isLoggingIn: loginMutation.isLoading,
        isSamlLoggingIn: samlLoginMutation.isLoading,
        loginReset: loginMutation.reset,
        logout,
        authToken: token,
        isLoading: isAuthenticating,
        isAuthenticated,

        // reset password flow
        setPassword: setPasswordMutation.mutate,
        setPasswordError: setPasswordMutation.error as APIError,
        isSettingPassword: setPasswordMutation.isLoading,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
