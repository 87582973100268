import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Gateway from 'types/gateway';

import { useAPI } from 'hooks/api/useAPI';
import { useMutationWithError } from 'hooks/api/useMutationWithError';

export function useRetryCloudGateway({
  onMutate,
  onSettled,
  ...options
}: UseMutationOptions<void, APIError, Gateway['id']> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutationWithError(api.gateways.cloud.retry, {
    ...options,
    action: 'retry',
    entity: 'cloud gateway',
    async onMutate(gatewayId) {
      await queryClient.cancelQueries(['gateways']);
      await queryClient.cancelQueries(['gateway', gatewayId]);

      onMutate?.(gatewayId);
    },
    onSettled(data, error, gatewayId, ...args) {
      onSettled?.(data, error, gatewayId, ...args);

      queryClient.invalidateQueries(['gateways']);
      queryClient.invalidateQueries(['gateway', gatewayId]);
    },
  });
}
