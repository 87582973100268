import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Dashboard from 'types/dashboard';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';

import { migrateMetricFilters } from '../components/MetricFiltersInput/migrate';
import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useHasAccess } from 'hooks/useHasAccess';

export type UseDashboardsFilterParams = OffsetPaginationParams & {
  dashboard_ids?: Dashboard['id'][];
  dashboard_names?: Dashboard['name'][];
};

export type UseDashboardsOptions = UseQueryOptions<
  PaginatedAPIResponse<Dashboard>,
  APIError
>;

export function useDashboards(
  {
    pagination = 'offset',
    limit = 50,
    page = 1,
    ...filters
  }: UseDashboardsFilterParams = {},
  { enabled, ...options }: UseDashboardsOptions = {}
) {
  const api = useAPI();
  const params = {
    pagination,
    limit,
    page,
    ...filters,
  };
  const [hasAccess] = useHasAccess();

  return useAuthenticatedQuery(
    ['dashboards', api.applicationID, params],
    () => api.dashboards.list(params),
    {
      ...options,
      enabled:
        enabled !== undefined
          ? enabled && hasAccess('monitor')
          : hasAccess('monitor'),
      select(response) {
        const migratedDashboards = [...response.data].map((dashboard) =>
          migrateMetricFilters(dashboard)
        );

        return { ...response, data: migratedDashboards };
      },
    }
  );
}
