import { Node as ReteNode } from 'rete';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { IconButton } from 'components/IconButton/IconButton';

export type NodePropertiesEditorActionsProps = {
  node?: ReteNode;
  onCancel: () => void;
  onDelete: (node: ReteNode) => void;
  hasError?: boolean;
};

export function NodePropertiesEditorActions({
  node,
  onCancel,
  onDelete,
  hasError,
}: NodePropertiesEditorActionsProps) {
  function handleDeleteClick() {
    if (!node) {
      return;
    }

    onDelete(node);
  }

  return (
    <ButtonGroup align="space-between">
      <IconButton
        icon="delete"
        intent="danger"
        variant="secondary"
        label="Delete node"
        onClick={handleDeleteClick}
        size="small"
        showLabel
      />

      <ButtonGroup>
        <Button variant="ghost" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          form="node-properties-form"
          disabled={hasError}
          aria-label={
            hasError ? 'Please re-check all form values for errors.' : undefined
          }
          variant="primary"
        >
          Save
        </Button>
      </ButtonGroup>
    </ButtonGroup>
  );
}
