import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';

import { Icon, IconType } from 'components/Icon/Icon';

import styles from './Stepper.module.scss';

const c = classNames.bind(styles);

type StepWithLink = {
  to: string;
  onClick?: never;
  isActive?: never;
};

type StepWithButton = {
  to?: never;
  onClick: () => void;
  isActive: boolean;
};

export type StepProps = (StepWithLink | StepWithButton) & {
  index?: number;
  icon?: IconType;
  label: string | React.ReactNode;
  disabled?: boolean;
  state?: 'valid';
};

function Step({
  index,
  icon,
  label,
  to,
  isActive,
  disabled,
  state,
  onClick,
}: StepProps) {
  function renderContent(active: boolean) {
    return (
      <>
        <span
          className={c(
            'icon',
            'theme',
            state === 'valid' && !active ? 'success' : 'primary'
          )}
          aria-label={`Step ${index}`}
        >
          {state === 'valid' && (
            <Icon name="check" className={c('state-icon')} />
          )}
          {index}
        </span>
        <span className={c('label')}>
          {icon && <Icon name={icon} />}
          <span className={c('label-content')}>{label}</span>
        </span>
      </>
    );
  }

  return (
    <li className={c('step-wrap', { disabled })}>
      {to && (
        <NavLink
          to={to}
          className={({ isActive }) => c('step', state, { active: isActive })}
          end
        >
          {({ isActive }) => renderContent(isActive)}
        </NavLink>
      )}

      {onClick && (
        <button
          onClick={onClick}
          className={c('step', state, { active: isActive })}
          disabled={disabled}
        >
          {renderContent(isActive)}
        </button>
      )}
    </li>
  );
}

const Memoized = React.memo(Step);

export { Memoized as Step };
