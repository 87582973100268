import React from 'react';
import {
  Row as ReactTableRow,
  TableRowProps as ReactTableRowProps,
} from 'react-table';
import classnames from 'classnames/bind';
import { useParams } from 'react-router-dom';

import { ApplicationParams } from 'application/types/application_params';
import { useAppRedirect } from 'hooks/useAppRedirect';

import { Text } from 'components/Text/Text';

import styles from './Table.module.scss';

const c = classnames.bind(styles);

export type TableRowProps<T extends {}> = ReactTableRowProps & {
  row: ReactTableRow<T>;
  to?: string;
  onClick?: (element: T) => void;
};

export function TableRow<T extends {}>({
  row,
  to,
  onClick,
  ...props
}: TableRowProps<T>) {
  const redirect = useAppRedirect();
  const { applicationID } = useParams<ApplicationParams>();

  function handleRowClick(
    event: React.MouseEvent<HTMLTableCellElement | HTMLTableRowElement>
  ) {
    if (!to && !onClick) {
      return;
    }

    event.stopPropagation();

    if (
      event.target !== event.currentTarget &&
      // Prioritize interactive elements in table
      (['input', 'label', 'a', 'button'].includes(
        (event.target as Element).localName
      ) ||
        // Do not pass through clicks from elements outside of table (e.g. popovers)
        !event.currentTarget.contains(event.target as HTMLElement))
    ) {
      return;
    }

    if (onClick) {
      onClick(row.original);
      return;
    }

    if (!to) {
      return;
    }

    if (event.metaKey) {
      if (!to.startsWith('/')) {
        to = `/${to}`;
      }

      window.open(`/applications/${applicationID}${to}`, '_blank');
    } else {
      redirect(to);
    }
  }

  return (
    <tr
      {...props}
      className={c('row', { interactive: Boolean(to || onClick) })}
      onClick={handleRowClick}
    >
      {row.cells.map((cell) => {
        const { key, ...props } = cell.getCellProps();
        return (
          <Text key={key} asChild>
            <td
              {...props}
              className={c('cell', { autoSize: cell.column.width === 0 })}
              onClick={handleRowClick}
            >
              {cell.render('Cell')}
            </td>
          </Text>
        );
      })}
    </tr>
  );
}
