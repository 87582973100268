import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import { useDeleteApplication } from 'hooks/api/useApplications';

import Application from 'types/application';

import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { Input } from 'components/Input/Input';
import { TitledModal } from 'components/TitledModal/TitledModal';

import styles from './DeleteApplicationModal.module.scss';

const c = classnames.bind(styles);

export type DeleteApplicationModalProps = {
  application: Application;
  onRequestClose: () => void;
};

export function DeleteApplicationModal({
  application,
  onRequestClose,
}: DeleteApplicationModalProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteApplication } = useDeleteApplication(application, {
    onError: () => {
      setIsDeleting(false);
    },
    onSuccess: () => {
      // Artificially slow down the process to make the action feel more secure
      window.setTimeout(() => {
        setIsDeleting(false);
        queryClient.invalidateQueries(['applications']).then(() => {
          navigate('/');
        });
      }, 400);
    },
  });

  const [confirmation, setConfirmation] = React.useState<string>('');
  const isDisabled = confirmation.trim() !== application.name;

  const [isDeleting, setIsDeleting] = React.useState(false);

  function handleDeleteClick() {
    setIsDeleting(true);
    deleteApplication();
  }

  return (
    <TitledModal
      className={c('delete-app-modal')}
      title="Are you sure you want to delete this workspace?"
      onRequestClose={onRequestClose}
      actions={
        <div className="columns halign-end">
          <Button variant="ghost" onClick={onRequestClose}>
            Cancel
          </Button>
          <Button
            intent="danger"
            onClick={handleDeleteClick}
            disabled={isDisabled}
            loading={isDeleting}
          >
            Permanently delete workspace
          </Button>
        </div>
      }
    >
      <p>
        Deleting this workspace will remove and unlink all associated gateways,
        cameras, and streams. All pipelines and deployments will be deleted.
      </p>
      <p>Are you sure you want to delete this workspace?</p>
      <Field
        id="delete-application-name"
        label={
          <>
            Please type <em>{application.name}</em> to confirm.
          </>
        }
      >
        <Input
          id="delete-application-name"
          value={confirmation}
          onValueChange={setConfirmation}
          required
        />
      </Field>
    </TitledModal>
  );
}
