import React from 'react';
import classnames from 'classnames/bind';

import { Button } from 'components/Button/Button';
import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';

import styles from './EmptyView.module.scss';

const c = classnames.bind(styles);

export type EmptyViewProps = React.PropsWithChildren<{
  image?: React.FC<React.SVGProps<SVGSVGElement>>;
  title?: string;
  buttonText?: string;
  onButtonClick?: () => void;
}>;

export function EmptyView({
  image: Image,
  title,
  buttonText,
  onButtonClick,
  children,
}: EmptyViewProps) {
  return (
    <section className={c('empty')}>
      <div className={c('empty-content')}>
        {Image && <Image className={c('empty-image')} />}

        {title && <Heading level="2">{title}</Heading>}

        {typeof children === 'string' ? (
          <Text className={c('empty-text')}>{children}</Text>
        ) : (
          <div className={c('empty-text')}>{children}</div>
        )}

        {buttonText && onButtonClick && (
          <Button onClick={onButtonClick}>{buttonText}</Button>
        )}
      </div>
    </section>
  );
}
