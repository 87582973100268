import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import Camera, { CAMERA_STATES, CameraState } from 'types/camera';
import Gateway from 'types/gateway';

import { useCameras, UseCamerasFilterParams } from 'cameras/hooks/useCameras';
import { useMqttCamerasState } from 'cameras/hooks/useMqttCamerasState';
import { useTagsFilterParams } from 'tags/hooks/useTagsFilterParams';

import * as PageLayout from 'components/PageLayout';
import { AddCameraDialog } from 'cameras/components/AddCameraDialog';
import { CamerasTable } from 'cameras/components/Table/Table';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { StateSelect } from 'components/StateSelect/StateSelect';

export function CamerasOverview() {
  const navigate = useNavigate();
  const [isAddingCamera, toggleIsAddingCamera] = useToggle();

  const tagFilters = useTagsFilterParams();
  const [queryFilters, setQueryFilters] =
    React.useState<UseCamerasFilterParams>({ page: 1 });

  const queryResult = useCameras(
    {
      ...tagFilters,
      ...queryFilters,
    },
    { keepPreviousData: true }
  );

  useMqttCamerasState(
    queryResult.data?.data ? queryResult.data.data.map(({ id }) => id) : []
  );

  const [searchFilters, setSearchFilters] =
    React.useState<UseCamerasFilterParams>({});

  const searchQueryResult = useCameras(
    ['cameras-suggestions'],
    {
      ...tagFilters,
      ...searchFilters,
      limit: 20,
    },
    { enabled: Object.keys(searchFilters).length > 0, keepPreviousData: false }
  );

  const handleSearchInput = React.useCallback((value?: string) => {
    if (!value) {
      setSearchFilters({});
      setQueryFilters({ page: 1 });
      return;
    }

    setSearchFilters({
      camera_names: [value],
      page: 1,
    });
  }, []);

  function handleApplyFilters() {
    setQueryFilters({ ...searchFilters, page: 1 });
  }

  function handleSuggestionSelect({ id }: Camera) {
    navigate(id);
  }

  function handleGatewayFilterChange(gateway: Gateway | null) {
    setQueryFilters((previousFilters) => ({
      ...previousFilters,
      gateway_ids: gateway ? [gateway.id] : undefined,
      page: 1,
    }));

    setSearchFilters((previousFilters) => ({
      ...previousFilters,
      gateway_ids: gateway ? [gateway.id] : undefined,
    }));
  }

  function handleStateFilterChange(state: string | null) {
    setQueryFilters((previousFilters) => ({
      ...previousFilters,
      statuses: state ? [state as CameraState] : undefined,
      page: 1,
    }));

    setSearchFilters((previousFilters) => ({
      ...previousFilters,
      statuses: state ? [state as CameraState] : undefined,
    }));
  }

  const hasFilters =
    Object.keys(queryFilters).length > 0 ||
    Object.values(tagFilters).some(Boolean);

  return (
    <PageLayout.Root>
      <QuerySearch
        entityName="camera"
        searchLabel="Search cameras..."
        searchResultField="name"
        searchQueryResult={searchQueryResult}
        onApplyFilters={handleApplyFilters}
        onSuggestionSelect={handleSuggestionSelect}
        onValueChange={handleSearchInput}
        totalElements={queryResult.data?.total_elements}
        filters={
          <>
            <StateSelect
              onChange={handleStateFilterChange}
              options={CAMERA_STATES}
              size="small"
            />
            <GatewaySelect
              onChange={handleGatewayFilterChange}
              size="small"
              isClearable
            />
          </>
        }
      />

      <CamerasTable
        queryResult={queryResult}
        pageSize={50}
        page={queryFilters.page}
        onPageChange={(page) =>
          setQueryFilters((previous) => ({ ...previous, page }))
        }
        hasFilters={hasFilters}
        onCreate={toggleIsAddingCamera}
      />

      <AddCameraDialog open={isAddingCamera} onClose={toggleIsAddingCamera} />
    </PageLayout.Root>
  );
}
