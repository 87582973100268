import { UseQueryOptions } from '@tanstack/react-query';

import {
  METRICS,
  MetricsResponse,
  MetricsQueryVariables,
} from 'graphql/queries/metrics';

import { usePropelQuery } from 'graphql/hooks/usePropelQuery';

export function usePropelMetrics(
  variables?: MetricsQueryVariables,
  options?: UseQueryOptions<MetricsResponse>
) {
  return usePropelQuery<MetricsResponse, MetricsQueryVariables>(
    METRICS,
    {
      first: 50,
      ...variables,
    },
    ['metrics'],
    // Metrics only change when new metrics get set up in Propel
    { staleTime: Infinity, ...options }
  );
}
