import React from 'react';
import classnames from 'classnames/bind';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import { useDeleteGateway } from 'gateways/hooks/useDeleteGateway';
import { useGateway } from 'gateways/hooks/useGateway';
import { useGatewaySpecification } from 'gateways/hooks/useGatewaySpecification';
import { useHasAccess } from 'hooks/useHasAccess';
import { useMqttGatewaysState } from 'gateways/hooks/useMqttGatewaysState';
import { useRetryCloudGateway } from 'gateways/hooks/useRetryCloudGateway';
import { useStartCloudGateway } from 'gateways/hooks/useStartCloudGateway';
import { useStopCloudGateway } from 'gateways/hooks/useStopCloudGateway';
import { useUpdateGateway } from 'gateways/hooks/useUpdateGateway';

import { ApplicationParams } from 'application/views/App/AppContainer';
import { NotFound } from 'views/NotFound/NotFound';
import {
  DetailView,
  DetailViewHeader,
  DetailViewStatus,
} from 'components/DetailView/DetailView';
import { DeploymentListSmall } from 'components/DeploymentListSmall/DeploymentListSmall';
import { EventsList } from 'events/components/List/List';
import { GatewayCameras } from 'gateways/components/CamerasTable/CamerasTable';
import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { Loader } from 'components/Loader/Loader';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Tabs, TabsButton, TabsContent, TabsList } from 'components/Tabs/Tabs';
import { Text } from 'components/Text/Text';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { GatewayDetailEntities } from './Entities';
import { GatewayDetailHardwareUtilization } from './Utilization';
import { GatewayDetailHardwareUtilizationIndicators } from './UtilizationIndicators';
import { GatewayDetailSettings } from './Settings';
import { GatewayDetailSpecification } from './Specification';
import { useRestartGateway } from 'gateways/hooks/useRestartGateway';

import styles from './GatewayDetail.module.scss';

const c = classnames.bind(styles);

export type GatewayParams = ApplicationParams & {
  gatewayID: string;
};

export function GatewayDetail() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const defaultTab = state?.tab ?? 'cameras';

  const { applicationID, gatewayID } = useParams<GatewayParams>();
  const { data: gateway, isLoading } = useGateway(gatewayID);
  const { mutate: updateGateway } = useUpdateGateway();
  const [requestDelete, { isLoading: isDeleting }] = useDeleteGateway({
    onSuccess() {
      navigate('./..');
    },
  });

  useMqttGatewaysState(gatewayID ? [gatewayID] : []);
  useGatewaySpecification(gatewayID);

  const { mutate: stopTrial, isLoading: isStopping } = useStopCloudGateway();
  const { mutate: restartTrial, isLoading: isRestartingTrial } =
    useStartCloudGateway();
  const { mutate: restartGateway, isLoading: isRestartingGateway } =
    useRestartGateway();
  const { mutate: retryCreate, isLoading: isRetrying } = useRetryCloudGateway();

  const [hasAccess] = useHasAccess();

  const [isRenaming, toggleIsRenaming] = useToggle();

  if (isLoading) {
    return <Loader text="Loading gateway..." />;
  }

  if (!gateway) {
    return (
      <NotFound
        entity="gateway"
        returnTo={`/applications/${applicationID}/deploy/gateways`}
      />
    );
  }

  function handleRename(name: string) {
    if (!gateway) {
      return;
    }

    updateGateway(gateway.withName(name));
  }

  function handleStopClick() {
    if (!gateway) {
      return;
    }

    stopTrial(gateway.id);
  }

  function handleTrialOfflineRestartClick() {
    if (!gateway) {
      return;
    }

    restartTrial(gateway.id);
  }

  function handleRestartClick() {
    if (!gateway) {
      return;
    }

    restartGateway(gateway.id);
  }

  function handleRetryClick() {
    if (!gateway) {
      return;
    }

    retryCreate(gateway.id);
  }

  function handleDeleteClick() {
    if (!gateway) {
      return;
    }

    requestDelete(gateway.id);
  }

  return (
    <DetailView
      key={gateway.id} // Key is required to correctly re-initialize detail view
      size="default"
    >
      <DetailViewHeader
        entity={gateway}
        entityType="gateways"
        singleQueryKey={['gateway']}
        isRenaming={isRenaming}
        onRename={hasAccess('deploy_edit') ? handleRename : undefined}
        titleSuffix={
          (gateway.is_managed || gateway.isTrial) && (
            <Text className="theme primary">
              <strong>
                {gateway.is_managed && (
                  <Tooltip content="Lumeo Cloud Gateway">
                    <Icon name="cloud" size="large" />
                  </Tooltip>
                )}
                {gateway.isTrial && <span>Trial</span>}
              </strong>
            </Text>
          )
        }
      >
        <GatewayDetailEntities gateway={gateway} />

        <DetailViewStatus entity={gateway} onRename={toggleIsRenaming}>
          <StatusIndicator status={gateway.status} />
          {gateway.stops_automatically_at && gateway.status === 'online' && (
            <Text>
              <Icon name="timer" />
              <span>
                {dayjs(gateway.stops_automatically_at).fromNow(true)} remaining
              </span>
            </Text>
          )}

          {hasAccess('deploy_edit') && (
            <>
              {gateway.isTrial &&
                (gateway.status === 'online' ||
                  gateway.status === 'starting') && (
                  <IconButton
                    icon="stop"
                    label="Stop"
                    onClick={handleStopClick}
                    loading={isStopping}
                    size="small"
                    variant="secondary"
                    showLabel
                  />
                )}

              {gateway.isTrial &&
              (gateway.status === 'stopping' ||
                gateway.status === 'offline') ? (
                <IconButton
                  icon="rotate"
                  label="Restart"
                  onClick={handleTrialOfflineRestartClick}
                  loading={isRestartingTrial}
                  size="small"
                  variant="secondary"
                  showLabel
                />
              ) : (
                <IconButton
                  icon="rotate"
                  label="Restart"
                  onClick={handleRestartClick}
                  loading={isRestartingGateway}
                  size="small"
                  variant="secondary"
                  showLabel
                />
              )}

              {gateway.isTrial && gateway.status === 'pending' && (
                <IconButton
                  icon="rotate"
                  label="Retry"
                  onClick={handleRetryClick}
                  loading={isRetrying}
                  size="small"
                  variant="secondary"
                  showLabel
                />
              )}

              <IconButton
                icon="delete"
                label="Delete"
                onClick={handleDeleteClick}
                intent="danger"
                loading={isDeleting}
                size="small"
                variant="secondary"
                showLabel
              />
            </>
          )}
        </DetailViewStatus>

        <GatewayDetailHardwareUtilizationIndicators />
      </DetailViewHeader>

      <Tabs className={c('tabs')} defaultValue={defaultTab}>
        <TabsList className={c('tabs-list')}>
          <TabsButton value="cameras">Cameras</TabsButton>
          <TabsButton value="deployments">Deployments</TabsButton>
          <TabsButton value="events">Events</TabsButton>
          {hasAccess('deploy_edit') && (
            <TabsButton value="specification">Hardware</TabsButton>
          )}
          {hasAccess('deploy_edit') && (
            <TabsButton value="settings">Settings</TabsButton>
          )}
        </TabsList>

        <TabsContent className={c('tab-content')} value="cameras">
          <GatewayCameras gateway={gateway} />
        </TabsContent>

        <TabsContent className={c('tab-content')} value="deployments">
          <DeploymentListSmall gatewayId={gateway.id} />
        </TabsContent>

        <TabsContent className={c('tab-content')} value="events">
          <EventsList params={{ object_ids: [gateway.id] }} />
        </TabsContent>

        {hasAccess('deploy_edit') && (
          <TabsContent className={c('tab-content')} value="specification">
            <div className={c('specification-wrap')}>
              <GatewayDetailHardwareUtilization />
              <GatewayDetailSpecification gateway={gateway} />
            </div>
          </TabsContent>
        )}

        {hasAccess('deploy_edit') && (
          <TabsContent className={c('tab-content')} value="settings">
            <GatewayDetailSettings gateway={gateway} />
          </TabsContent>
        )}
      </Tabs>
    </DetailView>
  );
}
