import {
  createModelSchema,
  deserialize,
  identifier,
  list,
  primitive,
  raw,
  serialize,
} from 'serializr';

import { objectOrNull } from 'services/serializr';

import Application from 'types/application';
import Gateway from 'types/gateway';
import { LumeoFile } from 'types/file';
import { RTSPConfig } from 'types/rtsp_config';
import { Tag } from 'types/tag';

export const CAMERA_FORMAT_OPTIONS = [
  { label: 'Raw', value: 'raw' },
  { label: 'H.264', value: 'h264' },
  { label: 'H.265', value: 'h265' },
  { label: 'VP8', value: 'vp8' },
];

export type Framerate = {
  denom: number;
  numer: number;
};

export type CameraConnectionType = 'local' | 'remote' | 'virtual';

export const CAMERA_STATES = [
  'online',
  'offline',
  'unknown',
  'unauthorized',
] as const;

export type CameraState = (typeof CAMERA_STATES)[number];

export function isCameraState(state: string): state is CameraState {
  return ([...CAMERA_STATES] as string[]).includes(state);
}

export type CameraConfiguration = {
  aperture?: string;
  format?: string;
  resolution?: string;
  framerate?: Framerate | number | null;
  width?: number | null;
  height?: number | null;
  capacity?: string;
};

export type CameraCapability = {
  name: string | null;
  width: number | null;
  format: string | null;
  height: number | null;
  framerates: Framerate[];
};

export default class Camera {
  source_type: 'camera' = 'camera';
  id: string;
  application_id: Application['id'];
  name: string;
  model?: string;
  conn_type?: CameraConnectionType;
  capabilities?: CameraCapability[];

  ip_local?: string;
  ip_ext?: string;
  mac_address?: string;
  gateway_id?: Gateway['id'];
  uri?: string;
  file?: string;

  status: CameraState;
  username?: string;
  password?: string;
  /**
   * JSON string
   */
  private configuration: string;
  created_at: string;
  updated_at: string;

  snapshot_file_id?: LumeoFile['id'];

  tags: Tag['id'][];

  reference_pipeline_ids: string[];
  reference_deployment_ids: string[];
  /** ID  to identify a camera externally. Unique across application. */
  external_id?: string | null;

  rtsp_config: RTSPConfig | null = null;

  constructor(id: string, name: string, applicationID: Application['id']) {
    const now = new Date().toISOString();

    this.id = id;
    this.name = name;
    this.application_id = applicationID;
    this.configuration = '{}';
    this.status = 'offline';

    this.created_at = now;
    this.updated_at = now;

    this.tags = [];

    this.reference_pipeline_ids = [];
    this.reference_deployment_ids = [];
  }

  isUSB() {
    return this.conn_type === 'local';
  }

  isIP() {
    return this.conn_type === 'remote';
  }

  get isOnline(): boolean {
    return this.status === 'online';
  }

  get isOffline() {
    return this.status === 'offline';
  }

  get canTakeSnapshot() {
    return this.isOnline;
  }

  copy() {
    return deserialize(Camera, serialize(this)) as unknown as Camera;
  }

  get capacity() {
    return this.config.capacity;
  }

  get config() {
    if (!this.configuration) {
      return {};
    }

    return JSON.parse(this.configuration) as CameraConfiguration;
  }

  set config(newConfiguration: CameraConfiguration) {
    this.configuration = JSON.stringify(newConfiguration);
  }

  withConfig(newConfiguration: object) {
    const newCamera = this.copy();
    const configuration = newCamera.config || {};
    Object.assign(configuration, newConfiguration);
    newCamera.config = configuration;
    return newCamera;
  }

  withName(name: string) {
    const newCamera = this.copy();
    newCamera.name = name;
    return newCamera;
  }

  equals(otherCamera: Camera) {
    return this.id === otherCamera.id;
  }
}

createModelSchema(Camera, {
  id: identifier(),
  application_id: primitive(),
  name: primitive(),
  model: primitive(),
  conn_type: primitive(),
  capabilities: raw(),
  gateway_id: primitive(),
  uri: primitive(),
  ip_local: primitive(),
  ip_ext: primitive(),
  mac_address: primitive(),
  username: primitive(),
  password: primitive(),
  configuration: primitive(),
  status: primitive(),
  created_at: primitive(),
  updated_at: primitive(),
  snapshot_file_id: primitive(),
  tags: list(primitive()),
  reference_pipeline_ids: list(primitive()),
  reference_deployment_ids: list(primitive()),
  external_id: primitive(),
  rtsp_config: objectOrNull(RTSPConfig),
});
