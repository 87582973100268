import { useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { Tag } from 'types/tag';

import { useAPI } from 'hooks/api/useAPI';
import {
  useBillingMutation,
  UseBillingMutationOptions,
} from 'hooks/api/useBillingMutation';
import { updateQueriesData } from 'services/update_queries_data';

type UseUpdateTagParams = {
  id: string;
} & (
  | { name: Tag['name'] }
  | { parent: Tag['id'] }
  | { name: Tag['name']; parent: Tag['id'] }
);

type UseUpdateTagOptions = Omit<
  UseBillingMutationOptions<Tag, APIError, UseUpdateTagParams>,
  'action' | 'entity'
>;

export function useUpdateTag({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseUpdateTagOptions = {}) {
  const { applicationID, client } = useAPI();
  const queryClient = useQueryClient();

  return useBillingMutation(
    async ({ id, ...params }) => {
      const { data } = await client.put(`/apps/${applicationID}/tags/${id}`, {
        ...params,
      });

      return data;
    },
    {
      ...options,
      action: 'update',
      entity: 'tag',
      async onMutate({ id, ...params }) {
        await queryClient.cancelQueries(['tags']);
        await queryClient.cancelQueries(['tag']);

        onMutate?.({ id, ...params });

        return updateQueriesData<Tag>({
          queryClient,
          listQueryKey: ['tags'],
          singleQueryKey: ['tag'],
          ids: [id],
          updateData: (tag) => ({ ...tag, ...params }),
        });
      },
      async onError(error, tag, context) {
        onError?.(error, tag, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      async onSettled(...args) {
        onSettled?.(...args);

        queryClient.invalidateQueries(['tags']);
        queryClient.invalidateQueries(['tag']);
      },
    }
  );
}
