import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';
import { useToggle } from '@mantine/hooks';

import { useUpdateApplication } from 'application/hooks/useUpdateApplication';
import { useCurrentApplication } from 'application/hooks/useCurrentApplication';

import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { DeleteApplicationModal } from 'application/components/DeleteApplicationModal/DeleteApplicationModal';
import { Field } from 'components/Field/Field';
import { Heading } from 'components/Heading/Heading';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Input } from 'components/Input/Input';
import { Text } from 'components/Text/Text';
import * as PageLayout from 'components/PageLayout';

import styles from '../Settings.module.scss';

const c = classNames.bind(styles);

type AppNameFieldValues = {
  name: string;
};

export function Workspace() {
  const { data: application } = useCurrentApplication();

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<AppNameFieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      name: application?.name,
    },
  });

  const {
    isLoading,
    isSuccess,
    error,
    mutate: renameApplication,
  } = useUpdateApplication({
    onSuccess(data) {
      reset({ name: data.name });
    },
  });

  const [isDeleting, toggleIsDeleting] = useToggle();

  if (!application) {
    return null;
  }

  function onSubmit({ name }: AppNameFieldValues) {
    if (!application) {
      return;
    }

    const newApplication = application.copy();
    newApplication.name = name;

    renameApplication(newApplication);
  }

  return (
    <PageLayout.Root size="small">
      <PageLayout.Container>
        <div className={c('wrap')}>
          <form aria-label="Update workspace" onSubmit={handleSubmit(onSubmit)}>
            <Box variant="secondary">
              <Field
                id="app-name"
                label="Workspace name"
                error={errors.name ?? error}
              >
                <Input
                  id="app-name"
                  placeholder={application.name}
                  {...register('name', { required: true })}
                />
              </Field>
              <ButtonGroup>
                <Button variant="primary" type="submit" loading={isLoading}>
                  Update workspace name
                </Button>
                {isSuccess && !isDirty && (
                  <InlineNotification intent="success">
                    Successfully updated workspace name.
                  </InlineNotification>
                )}
              </ButtonGroup>
            </Box>
          </form>

          <section>
            <Heading level="3">Delete workspace</Heading>
            <Text type="paragraph">
              Deleting this workspace is permanent and removes all associated
              gateways and deployments.
            </Text>
            <br />
            <Button intent="danger" onClick={() => toggleIsDeleting()}>
              Delete workspace
            </Button>

            {isDeleting && (
              <DeleteApplicationModal
                application={application}
                onRequestClose={toggleIsDeleting}
              />
            )}
          </section>
        </div>{' '}
      </PageLayout.Container>
    </PageLayout.Root>
  );
}
