import React from 'react';
import * as RadixPortal from '@radix-ui/react-portal';
import classNames from 'classnames/bind';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react-dom';
import { useCombobox, useMultipleSelection } from 'downshift';
import { MatchSorterOptions, matchSorter } from 'match-sorter';

import { IconButton } from 'components/IconButton/IconButton';
import { Input } from 'components/Input/Input';

import styles from './MultiSelectInput.module.scss';

const c = classNames.bind(styles);

export type MultiSelectInputOption = {
  label?: string;
  value: string;
  isCustom?: boolean;
};

type OptionType = MultiSelectInputOption | string;

type CommonProps = {
  id: string;
  selected?: string[] | string;
  defaultSelected?: string[] | string;
  allowCreate?: boolean;
  onChange?: (selected: string[] | null) => void;
  sort?: MatchSorterOptions<MultiSelectInputOption>['sorter'];
};

type PropsWithOptions = CommonProps & {
  options: MultiSelectInputOption[];
};

type PropsWithoutOptions = CommonProps & {
  options?: never;
};

export type MultiSelectInputProps = PropsWithOptions | PropsWithoutOptions;

export function MultiSelectInput(props: PropsWithOptions): JSX.Element;
export function MultiSelectInput(props: PropsWithoutOptions): JSX.Element;
export function MultiSelectInput({
  id,
  options,
  selected,
  defaultSelected,
  allowCreate,
  onChange,
  sort,
}: MultiSelectInputProps) {
  const [inputValue, setInputValue] = React.useState('');
  const [customOption, setCustomOption] =
    React.useState<MultiSelectInputOption>();

  const items =
    Boolean(selected) &&
    (Array.isArray(selected)
      ? (selected as OptionType[])
      : ([selected] as OptionType[]));
  const defaultItems =
    Boolean(defaultSelected) &&
    (Array.isArray(defaultSelected)
      ? (defaultSelected as OptionType[])
      : ([defaultSelected] as OptionType[]));
  const initialSelectedItems = items || defaultItems || [];
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection<OptionType>({
    initialSelectedItems,
  });

  function allOptions() {
    if (options && customOption) {
      return [...options, customOption];
    }

    if (!options && customOption) {
      return [customOption];
    }

    if (options && !customOption) {
      return options;
    }

    return [];
  }

  React.useEffect(() => {
    if (allowCreate) {
      const label = `Add "${inputValue}"`;

      const isDuplicate =
        options?.some(({ value }) => value === inputValue) ||
        selectedItems.some((item) => inputValue === getValue(item));

      const customOption =
        inputValue && !isDuplicate
          ? { label, value: inputValue, isCustom: true }
          : undefined;

      setCustomOption(customOption);
    }
  }, [allowCreate, inputValue, options, selectedItems]);

  function getFilteredItems(items?: MultiSelectInputOption[]) {
    if (!items) {
      return [];
    }

    const matches = matchSorter(items, inputValue, {
      keys: ['label', 'value'],
      sorter: sort,
    });

    return matches.filter(
      ({ value }) => !selectedItems.some((item) => value === getValue(item))
    );
  }

  const {
    isOpen,
    highlightedIndex,
    getMenuProps,
    getInputProps,
    getItemProps,
    openMenu,
  } = useCombobox({
    id,
    inputValue,
    items: getFilteredItems(allOptions()),
    defaultHighlightedIndex: 0,
    itemToString: (item) => item?.value ?? '',
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || '');
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('');
            addSelectedItem(selectedItem);
          }

          break;
        default:
          break;
      }
    },
  });

  const [menuStyles, setMenuStyles] = React.useState<React.CSSProperties>({});

  // Menu overlay positioning configuration
  const floatingConfig = React.useMemo(
    () => ({
      placement: 'bottom-start' as const,
      middleware: [
        offset(8),
        shift({ padding: 8 }),
        flip(),
        size({
          padding: 8,
          apply({ width, height, reference }) {
            setMenuStyles((styles) => ({
              ...styles,
              maxHeight: `${Math.max(100, height)}px`,
              maxWidth: `${width}px`,
              width: `${reference.width}px`,
            }));
          },
        }),
      ],
    }),
    []
  );

  const { x, y, strategy, refs, reference, floating, update } =
    useFloating(floatingConfig);

  const menuProps = React.useMemo(
    () => getMenuProps({ ref: floating }),
    [getMenuProps, floating]
  );

  React.useEffect(() => {
    if (!onChange) {
      return;
    }

    if (selectedItems.length === 0) {
      onChange(null);
      return;
    }

    onChange(
      selectedItems.map((item) => {
        if (typeof item === 'string') {
          return item;
        }
        return item.value;
      })
    );
  }, [selectedItems, onChange]);

  React.useEffect(() => {
    if (isOpen && refs.reference.current && refs.floating.current) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [isOpen, refs.floating, refs.reference, update]);

  return (
    <div className={c('wrap')}>
      <div className={c('combobox')} ref={reference}>
        <Input
          {...getInputProps({
            ...getDropdownProps({
              preventKeyAction: isOpen,
              onClick: openMenu,
            }),
            // NOTE: onKeyDown event is typed as `any` by downshift
            onKeyDown(event: any) {
              if (event.key === 'Enter' && !allOptions()) {
                event.preventDefault();
                addSelectedItem(inputValue);
                setInputValue('');
              }
            },
          })}
          onValueChange={setInputValue}
        />
      </div>

      <RadixPortal.Root>
        <ul
          {...menuProps}
          className={c('combobox-menu')}
          style={{
            ...menuStyles,
            position: strategy,
            top: 0,
            left: 0,
            transform: `translate3d(${x}px, ${y}px, 0)`,
          }}
        >
          {(isOpen || inputValue) &&
            getFilteredItems(allOptions()).map((item, index) => (
              <li
                {...getItemProps({ item, index })}
                className={c('combobox-option', {
                  active: highlightedIndex === index,
                })}
                key={`${item}${index}`}
              >
                {item.label || item.value}
              </li>
            ))}
        </ul>
      </RadixPortal.Root>

      {selectedItems.length > 0 && (
        <div className={c('selection')}>
          {selectedItems.map((selectedItem, index) => {
            const label = getLabel(selectedItem);
            return (
              <span
                className={c('selected-item')}
                key={`selected-item-${index}`}
                {...getSelectedItemProps({ selectedItem, index })}
              >
                {label}
                <IconButton
                  className={c('selected-item-remove')}
                  icon="cancel"
                  label={`Remove ${label}`}
                  onClick={() => removeSelectedItem(selectedItem)}
                  size="xsmall"
                  variant="ghost"
                />
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
}

function getLabel(item: OptionType): string {
  if (typeof item === 'string') {
    return item;
  }

  if (item.isCustom) {
    return item.value;
  }

  return item.label || item.value;
}

function getValue(item: OptionType): string {
  if (typeof item === 'string') {
    return item;
  }

  return item.value;
}
