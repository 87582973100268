import classnames from 'classnames/bind';

import { Logo } from 'components/Logo/Logo';
import { Text } from 'components/Text/Text';

import styles from './Loader.module.scss';

const c = classnames.bind(styles);

export type LoaderProps = {
  text: string;
  size?: 'small' | 'xsmall';
  showLogo?: boolean;
};

export function Loader({ text, size, showLogo = false }: LoaderProps) {
  const isSmall = !showLogo && (size === 'small' || size === 'xsmall');

  return (
    <div className={c('loader', size)} aria-label={isSmall ? text : undefined}>
      {showLogo && <Logo className={c('logo')} />}

      <div className={c('spinner', 'loading-spinner', size)} />
      {!isSmall && <Text className="loading-spinner-message">{text}</Text>}
    </div>
  );
}

export { PercentageLoader } from './PercentageLoader';
