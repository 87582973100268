import Camera from 'types/camera';

import { useGateway } from 'gateways/hooks/useGateway';

import { EntityList, EntityListItem } from 'components/EntityList';

export type CameraDetailEntitiesProps = {
  camera: Camera;
};

export function CameraDetailEntities({ camera }: CameraDetailEntitiesProps) {
  const { data: gateway, isInitialLoading } = useGateway(camera.gateway_id);

  const ip = camera.ip_ext || camera.ip_local;
  const { model } = camera;

  return (
    <EntityList>
      {camera.isIP() && (
        <EntityListItem icon="global" label="IP address" value={ip} />
      )}
      {camera.isUSB() && (
        <EntityListItem icon="connection" label="Connection" value="USB" />
      )}
      <EntityListItem icon="camera" label="Camera model" value={model} />
      <EntityListItem
        icon="gateway"
        label="Gateway"
        type="gateways"
        loading={isInitialLoading}
        entity={gateway}
      />
    </EntityList>
  );
}
