import React from 'react';
import classNames from 'classnames/bind';
import { DatePickerInputProps, DatesRangeValue } from '@mantine/dates';
import { SingleValue } from 'react-select';
import { useUncontrolled, useInterval } from '@mantine/hooks';

import { formatPretty } from 'services/string';
import {
  DATE_RANGE_PRESETS,
  RelativeDateRange,
  getPresetDateRange,
} from 'dashboards/services/time_range';

import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput';
import { Select } from 'components/Select/Select';

import styles from './DateRangePickerInput.module.scss';

const c = classNames.bind(styles);

const DATE_RANGE_PRESET_OPTIONS = DATE_RANGE_PRESETS.map(({ preset }) => ({
  value: preset,
  label: formatPretty(preset),
}));

export type DateRange = DatesRangeValue | RelativeDateRange;

export type DateRangePickerInputProps = Omit<
  DatePickerInputProps<'range'>,
  'icon' | 'type' | 'classNames' | 'defaultValue' | 'value' | 'onChange'
> & {
  defaultValue?: DateRange;
  value?: DateRange;
  /** Update date range every few seconds to allow displaying live data */
  liveUpdate?: boolean;
  onChange?: (dateRange?: DatesRangeValue, preset?: string) => void;
};

export function DateRangePickerInput({
  className,
  value,
  defaultValue = 'TODAY',
  liveUpdate,
  onChange,
  disabled,
  ...props
}: DateRangePickerInputProps) {
  const [dateRange, setDateRange] = useUncontrolled<DateRange>({
    value,
    defaultValue,
    onChange: handleValueChange,
  });

  const isDateRangeArray = Array.isArray(dateRange);
  const dates = isDateRangeArray ? dateRange : getPresetDateRange(dateRange);
  const preset = isDateRangeArray ? 'CUSTOM' : dateRange;

  // Update date range over time if preset is selected and different from current value
  const interval = useInterval(() => {
    if (!preset || preset === 'CUSTOM' || !liveUpdate) {
      return;
    }

    const newDates = getPresetDateRange(preset);

    if (!newDates) {
      return;
    }

    const [newStart, newEnd] = newDates;

    if (
      !dates ||
      newStart?.toISOString() !== dates[0]?.toISOString() ||
      newEnd?.toISOString() !== dates[1]?.toISOString()
    ) {
      setDateRange(preset as RelativeDateRange);
    }
  }, 6000);

  React.useEffect(() => {
    // Interval is inactive (-1) if no preset is selected
    if (!preset || preset === 'CUSTOM') {
      return;
    }

    interval.start();
    return interval.stop;
  }, [preset, interval]);

  function handleValueChange(value: DateRange) {
    if (Array.isArray(value)) {
      onChange?.(value, 'CUSTOM');
    } else {
      onChange?.(getPresetDateRange(value), value);
    }
  }

  function handlePresetChange(
    option: SingleValue<{ value: string; label: string }>
  ) {
    if (!option || !option.value) {
      return;
    }

    setDateRange(option.value as RelativeDateRange);
  }

  function handleDateChange(value: DatesRangeValue) {
    setDateRange(value);
  }

  return (
    <div className={c('wrap', className)}>
      <Select
        className={c('presets')}
        options={DATE_RANGE_PRESET_OPTIONS}
        onChange={handlePresetChange}
        value={{ value: preset, label: formatPretty(preset) }}
        size="small"
        isDisabled={disabled}
      />

      <DatePickerInput
        {...props}
        className={c('input')}
        onChange={handleDateChange}
        value={dates}
        type="range"
        disabled={disabled}
      />
    </div>
  );
}
