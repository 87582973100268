import React from 'react';
import Konva from 'konva';
import { createRoot } from 'react-dom/client';
import { Rect, Text } from 'react-konva';

import { ROI_CONFIG } from '../config';
import { getLabelPosition } from '../services/get_label_position';
import { getLineRotation } from '../services/get_line_rotation';
import { DirectionIndicators } from './DirectionIndicators';
import { ROILabelInput } from './LabelInput';

export type ROILabelProps = {
  labels: string[];
  text: string;
  shape: number[];
  closed?: boolean;
  onChange: (text: string) => void;
};

export const PADDING = 3;
const OFFSET_X = 4;

/**
 * ROI canvas shape label.
 * Positions itself at the start of a shape.
 */
export function ROILabel({
  labels,
  text,
  shape,
  closed,
  onChange,
}: ROILabelProps) {
  const elementRef = React.useRef<Konva.Rect>(null);
  const wrapperRef = React.useRef<HTMLDivElement>();

  // Text element ref affects rendering for rect, so we need to store in state
  const [textRef, setTextRef] = React.useState<Konva.Text | null>(null);

  const {
    rotation: labelRotation,
    position: { x, y },
    offsetModifier,
  } = React.useMemo(() => getLabelPosition(shape), [shape]);

  const rotation = labelRotation % 180;
  const width = (textRef?.getWidth() ?? 0) + PADDING * 2;
  const height = (textRef?.getHeight() ?? 0) + PADDING * 2;

  const offsetX =
    offsetModifier * width + (OFFSET_X + PADDING) * offsetModifier - OFFSET_X;
  const offsetY = (offsetModifier - 1) * height * -1;

  const lineRotation = getLineRotation(shape);

  function handleClick(event: Konva.KonvaEventObject<MouseEvent>) {
    event.cancelBubble = true;
  }

  function handleDblClick(event: Konva.KonvaEventObject<MouseEvent>) {
    event.cancelBubble = true;

    const container = elementRef.current?.getStage()?.container();

    if (wrapperRef.current || !container) {
      return;
    }

    wrapperRef.current = document.createElement('div');
    container.appendChild(wrapperRef.current);

    function validate(value: string): boolean {
      return (
        Boolean(value.trim()) && !(labels.includes(value) && value !== text)
      );
    }

    createRoot(wrapperRef.current).render(
      <ROILabelInput
        x={x}
        y={y}
        offsetY={offsetY}
        offsetX={offsetX}
        rotation={rotation}
        defaultValue={text}
        onValidate={validate}
        onSave={handleSave}
        onCancel={handleCancelEditing}
      />
    );
  }

  function handleSave(label: string) {
    onChange(label);
    handleCancelEditing();
  }

  function handleCancelEditing() {
    if (!wrapperRef.current) {
      return;
    }

    wrapperRef.current.remove();
    wrapperRef.current = undefined;
  }

  return (
    <>
      {!closed && (
        <DirectionIndicators
          x={x}
          y={y}
          height={height}
          rotation={lineRotation}
        />
      )}

      <Rect
        {...ROI_CONFIG.labelBg}
        x={x}
        y={y}
        rotation={rotation}
        offsetY={offsetY}
        offsetX={offsetX}
        height={height}
        width={width}
        ref={elementRef}
      />

      <Text
        {...ROI_CONFIG.labelText}
        text={text}
        align="bottom"
        x={x}
        y={y}
        rotation={rotation}
        offsetY={offsetY - PADDING}
        offsetX={PADDING * -1 + offsetX}
        onClick={handleClick}
        onDblClick={handleDblClick}
        ref={setTextRef}
      />
    </>
  );
}
