import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { OffsetPaginated, OffsetPaginationParams } from 'types/pagination';
import { Tag } from 'types/tag';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export type UseTagsParams = {
  tag_ids?: Tag['id'][];
  tag_names?: Tag['name'][];

  /** Filter by parents */
  parents?: Tag['id'][];

  /** Filter by parents or grandparents */
  ancestors?: Tag['id'][];

  /** Filter for only top-level tags */
  only_roots?: boolean;
};

type UseTagsOptions = UseQueryOptions<OffsetPaginated<Tag>, APIError> & {
  params?: UseTagsParams & OffsetPaginationParams;
};

export function useTags({
  queryKey = ['tags'],
  params,
  ...options
}: UseTagsOptions = {}) {
  const { applicationID, client } = useAPI();
  const queryParams = {
    pagination: 'offset',
    page: 1,
    limit: 50,
    ...params,
  };

  return useAuthenticatedQuery(
    [...queryKey, queryParams],
    async () => {
      const { data } = await client.get(`/apps/${applicationID}/tags`, {
        params: queryParams,
      });

      return data;
    },
    options
  );
}
