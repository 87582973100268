import { ActionType, IdType, TableState } from 'react-table';

// Fixes toggleAllRowsSelected() only toggling visible rows
// https://github.com/TanStack/table/issues/3142
export function deselectReducer<T extends object>(
  newState: TableState,
  action: ActionType
) {
  return action.type === 'deselectAllRows'
    ? { ...newState, selectedRowIds: {} as Record<IdType<T>, boolean> }
    : newState;
}
