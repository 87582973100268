import { serializable } from 'serializr';

export abstract class Timestamped {
  /** ISO 8601 string */
  @serializable created_at: string;
  /** ISO 8601 string */
  @serializable updated_at: string;

  protected constructor(createAt?: string, updatedAt?: string) {
    // Fallback value
    const timestamp = new Date();
    this.created_at = createAt || timestamp.toISOString();
    this.updated_at = updatedAt || timestamp.toISOString();
  }
}
