import React from 'react';
import { useLocation } from 'react-router-dom';

import Deployment, { DeploymentConfiguration } from 'types/deployment';
import Node, { getPropertyValue, setPropertyValue } from 'types/node';
import Pipeline from 'types/pipeline';

import { isEmptyObject } from 'services/object';
import { VideoSource } from 'hooks/api/useVideoSources';

import { type DeploymentVideoSource } from 'deployments/components/Form/context';

export type UnfilledParameterNode = {
  node: Node;
  properties: string[];
};

export function useDeploymentNodes(
  deployment?: Deployment,
  pipeline?: Pipeline
) {
  return React.useMemo<Partial<Node>[]>(() => {
    if (!deployment) {
      return [];
    }

    if (pipeline && isEmptyObject(deployment.configuration)) {
      return pipeline?.nodes;
    }

    // Transform { id: properties } type into [{ id, data }] array
    return Object.entries(deployment.configuration).map(([id, properties]) => ({
      id,
      data: properties,
    }));
  }, [deployment, pipeline]);
}

export function useDeploymentParameters(
  deployment: Deployment | undefined,
  pipeline?: Pipeline,
  initialSource?: VideoSource
): DeploymentConfiguration {
  const location = useLocation();
  const deploymentNodes = useDeploymentNodes(deployment, pipeline);

  return React.useMemo<DeploymentConfiguration>(() => {
    if (!pipeline) {
      return {};
    }

    const { nodes } = pipeline;

    const videoSourceNodeIDs = pipeline.definition
      .filter(({ properties: { type } }) => type === 'video')
      .map(({ id }) => id)
      .sort();
    const videoSources: DeploymentVideoSource = {};

    if (initialSource) {
      const [firstVideoSourceNodeID] = videoSourceNodeIDs;

      if (firstVideoSourceNodeID) {
        videoSources[firstVideoSourceNodeID] = {
          source_id: initialSource.id,
          source_type: initialSource.source_type,
        };
      }
    } else {
      // Initialize video sources from search params
      // ?source_typeN=TYPE&source_idN=ID
      const searchParams = new URLSearchParams(location.search);
      searchParams.sort();

      if (searchParams.has('pipeline_id')) {
        searchParams.delete('pipeline_id');
      }

      let nodeIndex = 0;

      // Find video source ID+type pairs
      searchParams.forEach((value, idKey) => {
        if (!idKey.includes('source_id')) {
          return;
        }

        const num = idKey.match(/\d/)?.[0];
        const typeKey = `source_type${num}`;

        if (!num || !value || !searchParams.has(typeKey)) {
          return;
        }

        const nodeID = videoSourceNodeIDs[nodeIndex];

        if (!nodeID) {
          return;
        }

        videoSources[nodeID] = {
          source_id: value ?? undefined,
          source_type: searchParams.get(typeKey) ?? undefined,
        };
        nodeIndex++;
      });
    }

    return nodes.reduce<DeploymentConfiguration>(
      (deploymentParameters, node) => {
        if (!node.id || !pipeline) {
          return deploymentParameters;
        }

        const parameterNode = deploymentNodes.find(({ id }) => id === node.id);

        /**
         * FIXME: Workaround for Dynamic Node Properties.
         * Dynamic nodes store their properties under `props` INSIDE the `properties` object.
         * If the node has a `props` object in `properties`, we should use that as a drop-in
         * replacement for the `properties`.
         */
        const data = parameterNode?.data?.props || parameterNode?.data;

        // Node has been deleted in pipeline and deployment doesn't yet reflect it
        // or deployment is fresh
        if (!parameterNode || !data) {
          const lumeoNode = pipeline?.definition.find(
            ({ id }) => id === node.id
          );
          const isVideoSourceNode = lumeoNode?.properties.type === 'video';

          if (isVideoSourceNode) {
            deploymentParameters[node.id] = videoSources[node.id];
          }

          return deploymentParameters;
        }

        deploymentParameters[node.id] = Object.keys(node.data).reduce<
          DeploymentConfiguration[string]
        >((deploymentValues, property) => {
          const value = getPropertyValue(data, property) ?? null;
          return setPropertyValue(deploymentValues, property, value);
        }, {});

        return deploymentParameters;
      },
      {}
    );
  }, [pipeline, initialSource, location, deploymentNodes]);
}
