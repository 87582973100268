import React from 'react';

export function Logo({
  alt,
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      {...props}
      src={`${process.env.PUBLIC_URL}/brand/logo-wordmark-primary.svg`}
      alt={alt || 'Lumeo logo.'}
    />
  );
}
