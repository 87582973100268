import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Application from 'types/application';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useApplications } from 'hooks/api/useApplications';

export type UseApplicationOptions = Omit<
  UseQueryOptions<Application | undefined, APIError>,
  'placeholderData'
>;

export function useApplication(
  applicationID: Application['id'] | undefined,
  { enabled, ...options }: UseApplicationOptions = {}
) {
  const api = useAPI();
  const { data: applications } = useApplications();

  return useAuthenticatedQuery(
    ['application', applicationID],
    () => {
      if (!applicationID) {
        return Promise.reject('No application id provided.');
      }

      return api.applications.read(applicationID);
    },
    {
      ...options,
      enabled:
        enabled !== undefined
          ? enabled && Boolean(applicationID)
          : Boolean(applicationID),
      placeholderData() {
        return applications?.find(({ id }) => id === applicationID);
      },
    }
  );
}
