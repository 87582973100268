import { useQuery } from '@tanstack/react-query';

import { mapLabelToChartColor } from '../services/color';

export function useChartColors(labels: string[] = []) {
  return useQuery(
    ['dashboard-colors', ...labels],
    () => Promise.all(labels.map(mapLabelToChartColor)),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
}
