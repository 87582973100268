import React from 'react';
import classnames from 'classnames/bind';
import { useClickOutside } from '@mantine/hooks';

import { Icon, IconType } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';
import { Popover } from 'components/Popover/Popover';
import { Status } from 'components/StatusIndicator/StatusIndicator';

import { EntityListEntities, EntityListObject } from './types';
import { EntityListLink } from './Link';
import styles from './EntityList.module.scss';

const c = classnames.bind(styles);

type CommonProps = {
  icon: IconType;
  label: string;
  status?: Status;
  loading?: boolean;
  onLinkClick?: () => void;
};

type EntityTypeProps<T> = CommonProps & {
  entity: T | T[] | undefined;
  value?: never;
  type: EntityListEntities;
};

type StaticTypeProps = CommonProps & {
  value?: React.ReactText;
  entity?: never;
  type?: never;
};

export type EntityListItemProps<T> = EntityTypeProps<T> | StaticTypeProps;

export const ENTITY_LABELS: {
  [property in EntityListEntities]: string;
} = {
  pipelines: 'Pipeline',
  gateways: 'Gateway',
  deployments: 'Deployment',
  cameras: 'Camera',
  streams: 'Stream',
  sources: 'Video Source',
  models: 'AI Model',
  files: 'File',
};

export function EntityListItem<T extends EntityListObject>(
  props: EntityTypeProps<T>
): JSX.Element;
export function EntityListItem(props: StaticTypeProps): JSX.Element;
export function EntityListItem<T extends EntityListObject>({
  icon,
  label,
  type,
  entity,
  value,
  status,
  loading,
  onLinkClick,
}: EntityListItemProps<T>) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const elementRef = useClickOutside(() => setIsExpanded(false));

  if (value !== undefined) {
    return (
      <div className={c('item')}>
        <div className={c('item-icon')}>
          <Icon name={icon} />
        </div>

        <dd className={c('item-label')}>{label}</dd>
        <dt className={c('item-value', { 'item-value-unknown': !value })}>
          {value || 'unknown'}
        </dt>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={c('item', { loading })}>
        <div className={c('item-icon')}>
          <Icon name={icon} />
          <Loader size="xsmall" text="Loading..." />
        </div>
        <dd className={c('item-label')}>{label}</dd>
        <dt className={c('item-value')}>Loading...</dt>
      </div>
    );
  }

  if (!entity || (Array.isArray(entity) && !entity.length)) {
    return null;
  }

  const firstEntity = Array.isArray(entity) ? entity[0] : entity;
  let itemLabel = label;

  if (type) {
    itemLabel = ENTITY_LABELS[type];
  }

  return (
    <div className={c('item')}>
      <div className={c('item-icon')}>
        <Icon name={icon} />
      </div>
      <dd className={c('item-label')}>
        {itemLabel}
        {Array.isArray(entity) && entity.length > 1 ? 's' : ''}
      </dd>
      <dt className={c('item-value')}>
        <EntityListLink
          entity={firstEntity}
          type={type}
          status={status}
          onLinkClick={onLinkClick}
        />

        {Array.isArray(entity) && entity.length > 1 && (
          <div
            className={c('all-sources', { expanded: isExpanded })}
            ref={elementRef}
          >
            <Popover
              trigger={
                <button
                  className={c('all-sources-toggle')}
                  onClick={() => setIsExpanded(!isExpanded)}
                  aria-label={`Show all associated ${type}s`}
                  aria-haspopup="listbox"
                  aria-expanded={isExpanded}
                >
                  {entity.length}
                </button>
              }
            >
              <strong className="name" aria-hidden="true">
                <Icon name="camera" size="small" />
                <span>All associated {type}</span>
              </strong>

              <ul>
                {entity.map((item, index) => (
                  <li key={item.id || index}>
                    <EntityListLink
                      entity={item}
                      type={type}
                      onLinkClick={onLinkClick}
                    />
                  </li>
                ))}
              </ul>
            </Popover>
          </div>
        )}
      </dt>
    </div>
  );
}
