import React from 'react';
import ReactTimezoneSelect, {
  Props as ReactTimezoneSelectProps,
  ITimezone,
  ITimezoneOption,
} from 'react-timezone-select';
import { GroupBase, SingleValueProps, components } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { useUncontrolled } from '@mantine/hooks';

import {
  c,
  getDefaultSelectComponents,
  getSelectStyles,
} from 'components/Select/Select';

export type TimezoneSelectProps = Omit<
  ReactTimezoneSelectProps,
  'components' | 'styles' | 'value' | 'defaultValue' | 'onChange'
> & {
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
};

const styles = getSelectStyles<ITimezone>();
const componentsOverride: Partial<
  SelectComponents<ITimezone, false, GroupBase<ITimezone>>
> = {
  ...getDefaultSelectComponents<ITimezone>(),
  SingleValue(props: SingleValueProps<ITimezone, false>) {
    const { data } = props;

    if (!data) {
      return <components.SingleValue {...props} />;
    }

    const { label, abbrev } = data as ITimezoneOption;
    const gmtString = label.slice(label.indexOf('('), label.indexOf(')') + 1);

    return (
      <components.SingleValue {...props}>
        {gmtString} {abbrev}
      </components.SingleValue>
    );
  },
};

export function TimezoneSelect({
  className,
  value,
  defaultValue,
  onChange,
  ...props
}: TimezoneSelectProps) {
  const [timezone, setTimezone] = useUncontrolled<ITimezone>({
    value,
    defaultValue,
  });

  function handleChange(timezone: ITimezone) {
    const tz = typeof timezone === 'object' ? timezone.value : timezone;

    setTimezone(tz);
    onChange?.(tz);
  }

  return (
    <ReactTimezoneSelect
      {...props}
      className={c(className, 'small')}
      components={componentsOverride}
      styles={styles}
      value={timezone}
      onChange={handleChange}
    />
  );
}
