import classNames from 'classnames/bind';

import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';

import { ReactComponent as PropelAlertIcon } from 'dashboards/images/propel_alert_icon.svg';

import styles from './ChartError.module.scss';

const c = classNames.bind(styles);

type ChartErrorProps = {
  error: { title: string; body: string };
};

/**
 * Error message replicating Propel components error
 */
export function ChartError({ error }: ChartErrorProps) {
  return (
    <div className={c('chart-error')}>
      <PropelAlertIcon />
      <Heading level={4}>{error.title}</Heading>
      <Text type="paragraph">{error.body}</Text>
    </div>
  );
}
