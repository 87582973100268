import AceEditor, { IAceEditorProps } from 'react-ace';
import classNames from 'classnames/bind';

import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-dracula';
import 'ace-builds/src-noconflict/ext-language_tools';

import styles from './CodeEditor.module.scss';

const c = classNames.bind(styles);

export type CodeEditorProps = Pick<IAceEditorProps, 'value' | 'onChange'> & {
  disabled?: boolean;
};

export function CodeEditor({ value, onChange, disabled }: CodeEditorProps) {
  return (
    <div className={c('wrap')}>
      <AceEditor
        mode="python"
        theme="dracula"
        onChange={onChange}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={value || ''}
        width="100%"
        height="60vh"
        enableBasicAutocompletion={false}
        enableLiveAutocompletion={true}
        enableSnippets={false}
        tabSize={2}
        readOnly={disabled}
      />
    </div>
  );
}
