import {
  UseMutationOptions,
  useMutation,
  MutationKey,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import LumeoJWT from 'types/jwt';

import { useAPI } from 'hooks/api/useAPI';

export type JwtType = 'mqtt' | 'gateway_provisioning';

export type JwtParams<Type extends JwtType> = {
  type: Type;
} & (Type extends 'mqtt'
  ? { requested_application_id_for_user: string }
  : Type extends 'gateway_provisioning'
    ? {
        application_id: string;
        pending_gateway_id?: string;
        gateway_type?: 'trial';
      }
    : never);

/** Create JWT tokens for gateway provisioning and mqtt topics */
export function useJWT<Type extends JwtType>(
  mutationKey: MutationKey,
  options: UseMutationOptions<LumeoJWT, APIError, JwtParams<Type>> = {}
) {
  const api = useAPI();

  return useMutation(mutationKey, api.jwt.create, options);
}
