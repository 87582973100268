import React from 'react';
import classNames from 'classnames/bind';

import { ButtonProps } from 'components/Button/Button';

import styles from './ButtonGroup.module.scss';

const c = classNames.bind(styles);

type Children = React.ReactElement<ButtonProps> | false | undefined;

export type ButtonGroupProps = {
  children: Children | Children[];
  align?: 'start' | 'center' | 'space-between' | 'end';
};

export function ButtonGroup({ children, align }: ButtonGroupProps) {
  return (
    <div className={c('button-group', align)} role="group">
      {children}
    </div>
  );
}
