import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import { CropInput, CropInputProps } from 'components/CropInput/CropInput';

export default class ReteCropInput extends ReteInput<
  ReteInputProps & CropInputProps
> {
  constructor(
    editor: NodeEditor,
    key: string,
    params: Omit<ReteInputParams, 'type'>
  ) {
    super(editor, key, { ...params, type: 'string' });
  }

  get component() {
    return CropInput;
  }
}
