import React from 'react';
import { QueryClient } from '@tanstack/react-query';

import { useAppQueryClient } from 'hooks/useAppQueryClient';
import { AppEntityObject } from 'hooks/useEntities';
import { objectHas } from 'services/object';

import {
  AdvancedSearchMatches,
  AdvancedSearchRelatedEntityFilterList,
} from '../AdvancedSearch.types';
import {
  findEntitiesWithMatchingProperties,
  findEntitiesWithNameOrID,
} from '../services/filter';

type EntityMap = {
  [entity: string]: AppEntityObject[];
};

type EntityResolver = {
  [property: string]: string;
};

export const ENTITY_RESOLVER: EntityResolver = {
  gateway_id: 'gateways',
  pipeline_id: 'pipelines',
};

export function getPropertyFromEntity(entity: string | undefined) {
  return Object.keys(ENTITY_RESOLVER).find(
    (property) => ENTITY_RESOLVER[property] === entity
  );
}

export function useSuggestions<T extends AppEntityObject>(
  baseEntity: T,
  filters: AdvancedSearchRelatedEntityFilterList<T>,
  data: T[]
) {
  const queryClient = useAppQueryClient();
  const queryClientRef = React.useRef<QueryClient>(queryClient);

  const [suggestions, setSuggestions] = React.useState<AdvancedSearchMatches>(
    {}
  );

  const suggestionsCount = React.useMemo(() => {
    if (!suggestions) {
      return 0;
    }

    return Object.entries(suggestions).reduce((len, [category, results]) => {
      if (category === 'text') {
        return len + 1;
      }

      return len + results.length;
    }, 0);
  }, [suggestions]);

  React.useEffect(() => {
    queryClientRef.current = queryClient;
  }, [queryClient]);

  const findSuggestions = React.useCallback(
    (searchTerm?: string) => {
      if (!searchTerm?.trim()) {
        setSuggestions({});
        return;
      }

      const newMatches = Object.keys(baseEntity).reduce<EntityMap>(
        (matches, property) => {
          if (
            !objectHas(ENTITY_RESOLVER, property) ||
            filters.some((filter) => filter.property === property)
          ) {
            return matches;
          }

          const entity = ENTITY_RESOLVER[property];

          const list =
            queryClientRef.current.getQueryData<AppEntityObject[]>([entity]) ||
            [];
          matches[entity] = findEntitiesWithNameOrID(searchTerm, list);

          return matches;
        },
        {}
      );

      // plain-text search for items of original list
      newMatches.text = findEntitiesWithMatchingProperties(
        baseEntity,
        searchTerm,
        data
      );

      setSuggestions(newMatches);
    },
    [baseEntity, filters, data]
  );

  return { findSuggestions, suggestions, suggestionsCount };
}
