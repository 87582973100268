import React from 'react';
import classnames from 'classnames/bind';

import {
  NumberInput as MantineNumberInput,
  NumberInputProps as MantineNumberInputProps,
  NumberInputStylesNames,
} from '@mantine/core';

import styles from './NumberInput.module.scss';

const c = classnames.bind(styles);

export type NumberInputProps = Omit<
  MantineNumberInputProps,
  'size' | 'classNames'
> & {
  classNames?: Partial<Record<NumberInputStylesNames, string>>;

  keyrefValue?: unknown;
  unit?: string;
  size?: 'small';
};

function NumberInput(
  {
    id,
    classNames,
    unit,
    size,
    keyrefValue,
    disabled,
    /**
     * Unused if `unit` is specified.
     */
    rightSection,
    ...props
  }: NumberInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <MantineNumberInput
      {...props}
      id={id}
      classNames={{
        ...classNames,
        wrapper: c(classNames?.wrapper, 'wrapper'),
        input: c(classNames?.input, 'input'),
        section: c(classNames?.section, 'section'),
        root: c(classNames?.root, 'root', size, { disabled }),
        control: c(classNames?.control, 'control'),
      }}
      disabled={disabled}
      rightSection={
        unit ? (
          <label htmlFor={id} className={c('unit')}>
            {unit}
          </label>
        ) : (
          rightSection
        )
      }
      unstyled
      ref={ref}
    />
  );
}

const ForwardNumberInputRef = React.forwardRef<
  HTMLInputElement,
  NumberInputProps
>(NumberInput);
export { ForwardNumberInputRef as NumberInput };

function FloatInput(
  { decimalScale = 2, ...props }: NumberInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <ForwardNumberInputRef
      {...props}
      step={0.01}
      decimalScale={decimalScale}
      fixedDecimalScale={Boolean(decimalScale)}
      ref={ref}
    />
  );
}

const ForwardFloatInputRef = React.forwardRef<
  HTMLInputElement,
  NumberInputProps
>(FloatInput);
export { ForwardFloatInputRef as FloatInput };

export default ForwardNumberInputRef;
