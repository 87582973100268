import classNames from 'classnames/bind';

import { Tooltip, TooltipProps } from './Tooltip';

import styles from './Tooltip.module.scss';

const c = classNames.bind(styles);

export type OptionalTooltipProps = {
  showTooltip?: boolean;
  content?: TooltipProps['content'];
} & Omit<TooltipProps, 'content'>;

export function OptionalTooltip({
  content,
  showTooltip,
  children,
  side = 'top',
  className,
  ...rest
}: OptionalTooltipProps) {
  if (!content || !showTooltip) {
    return <>{children}</>;
  }

  return (
    <Tooltip content={content} side={side} {...rest}>
      <span className={c('wrap', className)}>{children}</span>
    </Tooltip>
  );
}
