import React from 'react';
import { Column, CellProps } from 'react-table';
import { useLocation } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import Stream from 'types/stream';

import { isNotNullOrUndefined } from 'services/nullable';
import { relativeTimeFromNow } from 'services/date';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useDeleteStream } from 'streams/hooks/useDeleteStream';
import { useDeleteStreams } from 'streams/hooks/useDeleteStreams';
import { useGateways } from 'hooks/api/useGateways';
import { useHasAccess } from 'hooks/useHasAccess';
import { useUpdateStream } from 'streams/hooks/useUpdateStream';

import { Button } from 'components/Button/Button';
import { CreateInputStreamsDialog } from 'streams/components/CreateInputStreamsDialog';
import { EmptyView } from 'components/EmptyView/EmptyView';
import {
  PaginatedTable,
  PaginatedTableProps,
} from 'components/Table/PaginatedTable';
import { StreamsTableActionsCell } from 'streams/components/Table/ActionsCell';
import { StreamsTableInfoCell } from 'streams/components/Table/InfoCell';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { TableLabelsCell } from 'components/Table/Table';
import { TableBulkActionType } from 'components/Table/components/BulkActions/BulkActions';
import { TextAppLink } from 'components/TextAppLink/TextAppLink';

export type InputStreamsTableProps = Pick<
  PaginatedTableProps<Stream>,
  'queryResult' | 'pageSize' | 'page' | 'onPageChange'
> & {
  hasFilters: boolean;
};

export function InputStreamsTable({
  hasFilters,
  ...props
}: InputStreamsTableProps) {
  const { mutate } = useUpdateStream(['streams']);
  const { search } = useLocation();

  const [hasAccess] = useHasAccess();

  const [isCreatingInputStream, toggleIsCreatingInputStream] = useToggle();
  const [requestDeleteStream] = useDeleteStream(['streams']);
  const [requestDeleteStreams, { isLoading: isDeletingStreams }] =
    useDeleteStreams(['streams']);

  const gatewayIDs = [
    ...new Set(
      props.queryResult?.data?.data
        .map(({ gateway_id }) => gateway_id)
        .filter(isNotNullOrUndefined)
    ),
  ];

  const { data: gateways } = useGateways(
    { gateway_ids: gatewayIDs },
    { keepPreviousData: false }
  );

  const bulkActions = React.useMemo(() => {
    if (hasAccess('deploy_edit')) {
      return [
        {
          action: 'Delete',
          intent: 'danger',
          onClick: requestDeleteStreams!,
          loading: isDeletingStreams,
        },
      ] as TableBulkActionType[];
    }
  }, [isDeletingStreams, requestDeleteStreams, hasAccess]);

  const { data: currentPlan, isSuccess } = useCurrentPlan();
  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);

  const [isRenamingStream, setIsRenamingStream] = React.useState<string>();

  const columns = React.useMemo(() => {
    function getStreamGateway(gatewayID: string | undefined) {
      return gatewayID
        ? gateways?.data.find(({ id }) => id === gatewayID)
        : undefined;
    }

    function handleRenameInputStream(stream: Stream) {
      mutate(stream);
      setIsRenamingStream(undefined);
    }

    function handleCancelRenameInputStream() {
      setIsRenamingStream(undefined);
    }

    function InfoCell(props: CellProps<Stream>) {
      return (
        <StreamsTableInfoCell
          {...props}
          isRenaming={isRenamingStream === props.row.original.id}
          gateway={getStreamGateway(props.row.original.gateway_id)}
          onCancelRename={handleCancelRenameInputStream}
          onRename={handleRenameInputStream}
          to={`/deploy/streams/${props.row.original.id}`}
          key={`stream-info_${props.row.original.id}`}
        />
      );
    }

    const columns: Column<Stream>[] = [
      {
        Header: 'Stream',
        Cell: InfoCell,
      },
      {
        Header: 'State',
        Cell({ row }: CellProps<Stream>) {
          return <StatusIndicator status={row.original.status} />;
        },
      },
      {
        Header: 'Gateway',
        Cell(props: CellProps<Stream>) {
          const { gateway_id } = props.row.original;

          const gateway = gateway_id
            ? gateways?.data.find(({ id }) => id === gateway_id)
            : undefined;

          if (!gateway) {
            return null;
          }

          return (
            <TextAppLink to={`/deploy/gateways/${gateway.id}`}>
              {gateway.name}
            </TextAppLink>
          );
        },
      },
    ];

    if (canCreateTags) {
      columns.push({
        Header: 'Labels',
        Cell(props: CellProps<Stream>) {
          return (
            <TableLabelsCell
              {...props}
              entity="streams"
              listQueryKey={['streams']}
              singleQueryKey={['stream']}
              readOnly={!hasAccess('deploy_edit', 'cameras')}
            />
          );
        },
      });
    }

    columns.push({
      id: 'updated_at',
      Header: 'Last Activity',
      accessor(stream: Stream) {
        return relativeTimeFromNow(stream.updated_at);
      },
      sortType: 'date',
    });

    if (hasAccess('deploy')) {
      function ActionsCell(props: CellProps<Stream>) {
        return (
          <StreamsTableActionsCell
            {...props}
            onRenameClick={setIsRenamingStream}
            onDeleteClick={requestDeleteStream}
            key={`stream-actions_${props.row.original.id}`}
          />
        );
      }

      columns.push({
        Header: ' ',
        Cell: ActionsCell,
      });
    }

    return columns;
  }, [
    canCreateTags,
    gateways?.data,
    isRenamingStream,
    mutate,
    requestDeleteStream,
    hasAccess,
  ]);

  return (
    <PaginatedTable
      {...props}
      id="streams-table"
      label="streams"
      columns={columns}
      bulkActions={bulkActions}
      onRowNavigate={(stream) => `/deploy/streams/${stream.id + search}`}
      emptyMessage={
        hasFilters ? (
          <EmptyView title="No streams matching the filter criteria found.">
            Please try another filter combination or clear filters.
          </EmptyView>
        ) : (
          <EmptyView title="You have not added any streams yet.">
            <Button onClick={() => toggleIsCreatingInputStream()}>
              Create input stream
            </Button>
            <CreateInputStreamsDialog
              open={isCreatingInputStream}
              onClose={toggleIsCreatingInputStream}
            />
          </EmptyView>
        )
      }
    />
  );
}
