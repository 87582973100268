import { list, object, serializable } from 'serializr';
import AccountRole from './account_role';

export type OrganizationInvitationStatus =
  | 'accepted'
  | 'declined'
  | 'expired'
  | 'pending';

export default class OrganizationInvitation {
  @serializable
  id: string;

  @serializable
  email: string;

  @serializable
  status: OrganizationInvitationStatus;

  @serializable(list(object(AccountRole)))
  roles: AccountRole[];

  @serializable
  organization_id: string;

  @serializable
  inviter_account_id: string | null;

  /** ISO 8601 string */
  @serializable
  valid_until: string;

  /** ISO 8601 string */
  @serializable
  created_at: string;

  constructor(
    id: string,
    email: string,
    status: OrganizationInvitationStatus,
    roles: AccountRole[],
    organizationId: string,
    inviterAccountId: string | null,
    validUntil: string,
    createdAt: string
  ) {
    this.id = id;
    this.email = email;
    this.status = status;
    this.roles = roles;
    this.organization_id = organizationId;
    this.inviter_account_id = inviterAccountId;
    this.valid_until = validUntil;
    this.created_at = createdAt;
  }
}
