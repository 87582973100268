import { lastElement } from 'services/array';

export function getNameFromStreamURI(uri?: string): string {
  if (!uri?.trim()) {
    return '';
  }

  try {
    const url = new URL(uri);
    const lastPathSegment = lastElement(url.pathname.split('/')) ?? '';
    const fragment = url.hash.replace(/[#/]/, '-');

    return lastPathSegment + fragment;
  } catch (error) {
    return '';
  }
}
