import React from 'react';
import classnames from 'classnames/bind';

import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';

import { isExceedingOrganizationUsageLimit } from 'organizations/services/usage_limits';
import { useCanDeployPipeline } from 'deployments/hooks/useCanDeployPipeline';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useGateways } from 'hooks/api/useGateways';
import { useUsageStatistics } from 'organizations/hooks/useUsageStatistics';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { Loader } from 'components/Loader/Loader';

import { DeploymentFormGatewaySelect } from './GatewaySelect';
import styles from './Form.module.scss';

const c = classnames.bind(styles);

export type DeploymentFormActionsProps = {
  className?: string;

  pipeline?: Pipeline | null;
  gateway?: Gateway | null;
  deployments?: Deployment[];
  isDeploying?: boolean;
  isUpdateDeploymentsForm?: boolean;
  borderless?: boolean;

  gatewayError?: string;
  onGatewayChange?: (gateway: Gateway | null) => void;

  startImmediately?: boolean;
  onToggleStartImmediately: React.Dispatch<React.SetStateAction<boolean>>;

  onCancel?: () => void;

  buttonText?: string;
  onSubmit?: () => void;
};

export function DeploymentFormActions({
  className,

  pipeline,
  deployments,
  isDeploying,
  isUpdateDeploymentsForm,
  borderless,

  gateway,
  gatewayError,
  onGatewayChange,

  startImmediately,
  onToggleStartImmediately,

  onCancel,

  buttonText = 'Deploy',
  onSubmit,
}: DeploymentFormActionsProps) {
  const deploymentsCount = deployments ? deployments.length : 1;
  const { data: deploymentGateways } = useGateways(
    {
      gateway_ids: deployments?.map(({ gateway_id }) => gateway_id),
    },
    { enabled: Boolean(deployments) }
  );

  const { isLoading: isLoadingStatistics, data: usageStatistics } =
    useUsageStatistics();
  const { isLoading: isLoadingPlan, data: currentPlan } = useCurrentPlan();

  const canDeploy = useCanDeployPipeline(pipeline);

  let onlineState = 'all';

  if (gateway) {
    onlineState = gateway.status !== 'online' ? 'none' : 'all';
  } else if (
    deploymentGateways?.data &&
    Boolean(deploymentsCount) &&
    isUpdateDeploymentsForm
  ) {
    const offlineGatewayCount = deploymentGateways.data.filter(
      ({ status }) => status !== 'online'
    ).length;

    if (offlineGatewayCount === 0) {
      onlineState = 'all';
    } else if (offlineGatewayCount === deploymentGateways.data.length) {
      onlineState = 'none';
    } else {
      onlineState = 'indeterminate';
    }
  }

  const canStartAll = React.useMemo(() => {
    if (!usageStatistics || !currentPlan || !deployments) {
      return true;
    }

    // Number of deployments being updated that were running or deploying
    const previouslyActiveDeploymentCount = deployments.filter(
      ({ isActive }) => isActive
    ).length;

    // Number of running deployments excl. deployments being updated
    const activeDeploymentCount =
      usageStatistics.deployment_count - previouslyActiveDeploymentCount;

    const willExceedDeploymentsLimit = isExceedingOrganizationUsageLimit({
      planLimit: currentPlan.running_deployment_limit,
      usage: activeDeploymentCount + deployments.length,
    });

    return !willExceedDeploymentsLimit;
  }, [deployments, usageStatistics, currentPlan]);

  React.useEffect(() => {
    if (gateway) {
      onToggleStartImmediately(gateway.status === 'online');
    }
  }, [gateway, onToggleStartImmediately]);

  if (isLoadingStatistics || isLoadingPlan) {
    return <Loader size="small" text="Loading..." />;
  }

  return (
    <section className={c('actions', className, { borderless })}>
      {onGatewayChange && (
        <div className={c('actions-gateway', { error: gatewayError })}>
          <DeploymentFormGatewaySelect onChange={onGatewayChange} />
        </div>
      )}

      <div className={c('actions-deploy')}>
        <div className={c('actions-checkbox')}>
          <LightSwitch
            id="nd_always-on"
            onValueChange={onToggleStartImmediately}
            checked={
              canStartAll && (onlineState !== 'none' ? startImmediately : false)
            }
            disabled={!canStartAll || onlineState === 'none'}
            indeterminate={onlineState === 'indeterminate'}
          />
          <label
            className={c('actions-checkbox__label', 'text-label')}
            htmlFor="nd_always-on"
          >
            Start immediately
          </label>
        </div>

        <ButtonGroup>
          {onCancel && (
            <Button onClick={onCancel} variant="secondary">
              Cancel
            </Button>
          )}

          <Button
            id="up-deployment-form_submit"
            onClick={onSubmit}
            loading={isDeploying}
            disabled={!canDeploy}
            variant="primary"
          >
            {buttonText}
          </Button>
        </ButtonGroup>
      </div>

      <div className={c('actions-errors')}>
        {!canStartAll && currentPlan?.running_deployment_limit ? (
          <LimitWarning
            maxDeploymentCount={currentPlan.running_deployment_limit}
          />
        ) : (
          <>
            {onlineState === 'indeterminate' && <IndeterminateWarning />}
            {onlineState === 'none' && (
              <OfflineWarning count={deploymentsCount} />
            )}
          </>
        )}

        {gatewayError && (
          <FormMessage intent="danger">{gatewayError}</FormMessage>
        )}
      </div>
    </section>
  );
}

function IndeterminateWarning() {
  return (
    <FormMessage intent="warning" icon="warning">
      <strong>Some gateways are offline.</strong>
      <br />
      <span>Cannot start all deployments immediately.</span>
    </FormMessage>
  );
}

type OfflineWarningProps = {
  count: number;
};

function OfflineWarning({ count }: OfflineWarningProps) {
  return (
    <FormMessage intent="warning" icon="warning">
      <strong>
        {count === 1 ? 'Gateway is offline.' : 'Gateways are offline.'}
      </strong>
      <br />
      <span>Cannot start deployment{count > 1 ? 's' : ''} immediately.</span>
    </FormMessage>
  );
}

type LimitWarningProps = {
  maxDeploymentCount?: number | null;
};

function LimitWarning({ maxDeploymentCount }: LimitWarningProps) {
  return (
    <FormMessage intent="warning" icon="warning">
      <strong>Cannot start immediately.</strong>
      <br />
      <span>
        Your plan only allows {maxDeploymentCount} concurrent&nbsp;deployments.
      </span>
    </FormMessage>
  );
}
