import React from 'react';
import classNames from 'classnames/bind';

import Pipeline from 'types/pipeline';

import { Radio, RadioOption, RadioValue } from 'components/Radio/Radio';

import { RapidDeployPipelinesList } from './PipelinesList';
import { RapidDeployPipelineTemplatesList } from './PipelineTemplatesList';
import styles from '../RapidDeploy.module.scss';

const c = classNames.bind(styles);

export type RapidDeployAnalyticsListProps = {
  hasPipelines: boolean;
  onPipelineSelect: (pipeline: Pipeline) => void;
};

export function RapidDeployAnalyticsList({
  hasPipelines,
  onPipelineSelect,
}: RapidDeployAnalyticsListProps) {
  const [activeView, setActiveView] = React.useState<RadioValue>(
    hasPipelines ? 'pipelines' : 'templates'
  );

  return (
    <>
      <div className={c('view-switch-controls')}>
        <Radio
          id="rapid-deploy-video-source-type"
          value={activeView}
          onChange={setActiveView}
        >
          <RadioOption value="pipelines" icon="camera">
            Your analytic pipelines
          </RadioOption>
          <RadioOption value="templates" icon="stream">
            Analytic templates
          </RadioOption>
        </Radio>
      </div>

      {activeView === 'pipelines' ? (
        <RapidDeployPipelinesList onSelect={onPipelineSelect} />
      ) : (
        <RapidDeployPipelineTemplatesList onSelect={onPipelineSelect} />
      )}
    </>
  );
}
