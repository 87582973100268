import Deployment from 'types/deployment';

import { useGateway } from 'gateways/hooks/useGateway';
import { usePipeline } from 'hooks/api/usePipeline';
import { useDeploymentsVideoSources } from 'hooks/api/useDeploymentsVideoSources';

import { EntityList, EntityListItem } from 'components/EntityList';

export type DeploymentDetailEntitiesProps = {
  deployment: Deployment;
};

export function DeploymentEntities({
  deployment,
}: DeploymentDetailEntitiesProps) {
  const { data: pipeline } = usePipeline(deployment.pipeline_id);
  const { data: gateway } = useGateway(deployment.gateway_id);
  const { cameras, streams, isLoading } = useDeploymentsVideoSources([
    deployment,
  ]);

  return (
    <EntityList>
      <EntityListItem
        icon="pipeline"
        type="pipelines"
        label="Pipeline"
        entity={pipeline}
      />
      <EntityListItem
        icon="gateway"
        type="gateways"
        label="Gateway"
        entity={gateway}
        status={gateway?.status}
      />
      <EntityListItem
        icon="source"
        type="sources"
        label="Video Source"
        loading={isLoading}
        entity={[...cameras, ...streams]}
      />
    </EntityList>
  );
}
