import Row from './JsonObjectInputRow';

export function validateAreKeysUnique(values: Row[]): number[] {
  const keys = values.map(({ key }) => key);

  // reduceRight to keep the first instance of a duplicate key
  const duplicateKeyIndices = keys.reduceRight<number[]>(
    (indices, key, index, list) => {
      if (list.slice(0, index).includes(key)) {
        indices.push(index);
      }

      return indices;
    },
    []
  );

  return duplicateKeyIndices;
}
