/** Only parses the token. Does not mean the token is valid. */
export function parseJwtWithoutVerification<T>(token: string) {
  try {
    return JSON.parse(
      Buffer.from(token.split('.')[1], 'base64').toString()
    ) as T;
  } catch {
    console.error('Failed to parse jwt token.');
  }
}
