import { ConfirmDeleteDialog } from 'components/ConfirmDialog/ConfirmDeleteDialog';
import { ConfirmDialog } from 'components/ConfirmDialog/ConfirmDialog';
import { FileLightbox } from 'files/components/Lightbox/Lightbox';
import { FilesSearchDrawer } from 'files/components/FilesSearchDrawer/FilesSearchDrawer';
import { StreamLightbox } from 'streams/components/Lightbox/Lightbox';

export const MODAL_REGISTRY = {
  'confirm-delete': ConfirmDeleteDialog,
  'files-search': FilesSearchDrawer,
  confirm: ConfirmDialog,
  file: FileLightbox,
  stream: StreamLightbox,
} as const;
