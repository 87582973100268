import classnames from 'classnames/bind';

import { Text } from 'components/Text/Text';

import styles from './Overview.module.scss';

const c = classnames.bind(styles);

const year = new Date().getFullYear();

export function OverviewFooter() {
  return (
    <Text asChild>
      <footer className={c('footer')}>
        <Text type="paragraph">
          &copy; {year} Lumeo, Inc. All rights reserved.
        </Text>

        <ul className={c('footer-links')}>
          <li className={c('footer-list-item')}>
            <a
              href="https://lumeo.com/legal/terms/"
              className={c('footer-link')}
            >
              Terms of Services
            </a>
          </li>

          <li className={c('footer-list-item')}>
            <a href="https://lumeo.com/legal/aup/" className={c('footer-link')}>
              Acceptable Use Policy
            </a>
          </li>
        </ul>
      </footer>
    </Text>
  );
}
