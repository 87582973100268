import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import AccountRole from 'types/account_role';
import APIError from 'types/api_error';
import OrganizationInvitation from 'types/invitation';

import { useAPI } from 'hooks/api/useAPI';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

export type CreateOrganizationInvitationVariables = {
  email: string;
  roles: AccountRole[];
};

/** Invite user to an organization */
export function useCreateOrganizationInvitation({
  onMutate,
  onSettled,
  ...options
}: UseMutationOptions<
  OrganizationInvitation,
  APIError,
  CreateOrganizationInvitationVariables
> = {}) {
  const queryClient = useQueryClient();
  const { organizations } = useAPI();

  const { data: organization } = useCurrentOrganization();
  const organizationId = organization?.id;

  return useMutation(
    (variables) => {
      if (!organizationId) {
        return Promise.reject('Missing organization id');
      }

      return organizations.invitations.create(organizationId, variables);
    },
    {
      ...options,
      onSettled(...args) {
        onSettled?.(...args);

        queryClient.invalidateQueries(['organization-invitations']);
      },
    }
  );
}
