import React from 'react';
import { MutationFunction, UseMutationOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';

import { useModal } from 'hooks/useModal';

import { useMutationWithError } from './useMutationWithError';

export type UseDangerousMutationOptions<TData, TContext, TVariables> =
  UseMutationOptions<TData, APIError, TVariables, TContext> & {
    info?: string;
    entity?: string;
    action?: string;
    onCancel?: () => void;
  };

/**
 * Extension of react-query `useMutation` that adds an error notification.
 *
 * @deprecated Use {@link useModal} instead.
 */
export function useDangerousMutation<
  TData = undefined,
  TContext = unknown,
  TVariables = void,
>(
  message: string,
  mutationFn: MutationFunction<TData, TVariables>,
  {
    info,
    onCancel,
    ...options
  }: UseDangerousMutationOptions<TData, TContext, TVariables>
) {
  const mutation = useMutationWithError<TData, TVariables, TContext>(
    mutationFn,
    options
  );

  const { open } = useModal();
  const { mutateAsync } = mutation;

  const requestDangerousMutation = React.useCallback(
    (variables: TVariables) => {
      open('confirm-delete', {
        title: message,
        info,
        onConfirm: () => mutateAsync(variables),
        onCancel,
      });
    },
    [info, message, mutateAsync, onCancel, open]
  );

  return [requestDangerousMutation, mutation] as const;
}
