import Rete, { Node } from 'rete';

import Component from 'pipelines/services/rete/nodes/component';
import { EncodedVideo } from 'pipelines/services/rete/sockets';

export default class RTSPStreamOut extends Component {
  static key = 'Stream RTSP';
  static icon = 'stream-out' as const;
  static exportType = 'stream_rtsp_out';
  static category = 'media' as const;
  static description =
    'Create an RTSP output stream for use in other pipelines or systems';

  static input = {
    type: 'encoded',
  };

  constructor() {
    super(RTSPStreamOut.key);
  }

  async builder(node: Node) {
    node.addInput(new Rete.Input('input', 'Input', EncodedVideo, false));
  }
}
