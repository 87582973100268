import React from 'react';

import Pipeline from 'types/pipeline';

import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import {
  usePipelines,
  UsePipelinesFilterParams,
} from 'pipelines/hooks/usePipelines';

import { Select, SelectProps } from 'components/Select/Select';

export type PipelineSelectProps<IsMulti extends boolean = false> = SelectProps<
  Pipeline,
  IsMulti
> & {
  queryFilters?: UsePipelinesFilterParams;
};

export function PipelineSelect<IsMulti extends boolean = false>({
  placeholder = 'Pipeline',
  isLoading,
  queryFilters = {},
  ...props
}: PipelineSelectProps<IsMulti>) {
  const [filters, setFilters] = React.useState<UsePipelinesFilterParams>({});

  const { data, isLoading: isLoadingOptions } = usePipelines(
    ['pipeline-select-pipelines'],
    { ...queryFilters, ...filters },
    { keepPreviousData: true }
  );

  const handleInputChange = useDebouncedCallback(
    (value: string) => {
      setFilters((previousFilters) => ({
        ...previousFilters,
        pipeline_names: [value],
      }));
    },
    [],
    { wait: 100 }
  );

  return (
    <Select
      {...props}
      options={data?.data}
      totalElements={data?.total_elements}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      getOptionLabel={({ name }) => name}
      getOptionValue={({ id }) => id}
      isLoading={isLoading || isLoadingOptions}
    />
  );
}
