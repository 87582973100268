import classNames from 'classnames/bind';

import { useDeploymentsQueues } from 'deployments/hooks/useDeploymentsQueues';
import { useDeploymentsQueueEntries } from 'deployments/hooks/useDeploymentsQueueEntries';

import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import styles from './DeploymentOverview.module.scss';

const c = classNames.bind(styles);

export function QueuedDeploymentsBanner() {
  const { data: deploymentsQueues } = useDeploymentsQueues({
    params: { limit: 1 },
  });

  const defaultQueue = deploymentsQueues?.data.at(0);

  const { data: queueEntries } = useDeploymentsQueueEntries(defaultQueue?.id, {
    params: { limit: 1 },
  });

  const queuedDeploymentsCount = queueEntries?.total_elements;

  if (!queuedDeploymentsCount) {
    return null;
  }

  return (
    <aside className={c('deployment-queue-banner', 'theme primary')}>
      <Icon name="rotate" className={c('deployment-queue-banner-icon')} />
      <Text>{queuedDeploymentsCount} deployments pending</Text>
    </aside>
  );
}
