import { Condition, LogicalOperator } from './types';

export class NestedConditionBuilder {
  private root: Condition;

  private lastTarget: Condition;
  private lastCondition: Condition | null = null;

  constructor(root: Pick<Condition, 'accessor' | 'comparator' | 'value'>) {
    this.root = { ...root };
    this.lastTarget = this.root;
  }

  addCondition(operator: LogicalOperator, condition: Condition) {
    if (!this.root.and && !this.root.or) {
      this.pushCondition(this.root, operator, condition);
      return;
    }

    if (operator in this.lastTarget) {
      this.pushCondition(this.lastTarget, operator, condition);
      return;
    }

    if (!this.lastCondition) {
      return;
    }

    this.pushCondition(this.lastCondition, operator, condition);
  }

  private pushCondition(
    root: Condition,
    operator: LogicalOperator,
    condition: Condition
  ) {
    if (!(operator in root)) {
      root[operator] = [];
    }

    root[operator]!.push(condition);

    this.lastTarget = root;
    this.lastCondition = condition;
  }

  get condition(): Condition {
    return this.root;
  }
}
