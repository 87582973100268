import classnames from 'classnames/bind';
import { useLocation } from 'react-router-dom';

import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';

import { relativeTimeFromNow } from 'services/date';
import { truncateMiddle } from 'services/string';
import { useDeleteDeployment } from 'hooks/api/useDeployment';
import { useHasAccess } from 'hooks/useHasAccess';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToggleDeploymentState } from 'deployments/hooks/useDeployment';

import { AppLink } from 'components/AppLink/AppLink';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuAction } from 'components/ContextMenu/ContextMenuAction';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';
import { OptionalTooltip } from 'components/Tooltip/OptionalTooltip';

import { MediaPreview } from 'components/MediaPreview/MediaPreview';
import { useOutputStreams } from 'streams/hooks/useOutputStreams';
import styles from './ItemCompact.module.scss';

const c = classnames.bind(styles);

type DeploymentListItemCompactProps = {
  deployment: Deployment;
  gateway?: Gateway;
  pipeline?: Pipeline;
  isReferenceDeployment?: boolean;
};

export function DeploymentListItemCompact({
  deployment,
  gateway,
  pipeline,
  isReferenceDeployment,
}: DeploymentListItemCompactProps) {
  const { pathname } = useLocation();
  const timeFromNow = relativeTimeFromNow(deployment.updated_at);

  const [hasAccess] = useHasAccess();

  const isMounted = useIsMounted();
  const { toggleState, isLoading } = useToggleDeploymentState();
  const { action } = deployment;

  const [requestDelete] = useDeleteDeployment();

  const { data: outputStreams } = useOutputStreams({
    deployment_ids: [deployment.id],
  });

  function handleDeleteDeployment() {
    requestDelete(deployment.id);
  }

  function handleUpdateState() {
    if (!deployment || !isMounted()) {
      return;
    }

    toggleState(deployment);
  }

  return (
    <div className={c('item')}>
      <div className={c('item-stream')}>
        <MediaPreview
          className={c('stream-thumbnail')}
          stream={outputStreams?.data[0]}
        />
      </div>
      <div className={c('item-info')}>
        <OptionalTooltip
          showTooltip={deployment.name ? deployment.name.length > 80 : false}
          content={deployment.name}
        >
          <Heading level="4" asChild>
            <AppLink
              className={c('item-link')}
              to={`${pathname}/ref/${deployment.id}`}
              state={
                isReferenceDeployment && {
                  defaultTab: 'configuration',
                }
              }
              keepSearchParams
            >
              {truncateMiddle(deployment.name || 'Unknown', 80)}
            </AppLink>
          </Heading>
        </OptionalTooltip>
        <div className={c('status')}>
          <div>
            <StatusIndicator status={deployment.state} />
          </div>
          {hasAccess('deploy_edit') && (
            <div className={c('icon')}>
              <IconButton
                className={c('control')}
                icon={action === 'start' ? 'play' : action}
                label={action}
                onClick={handleUpdateState}
                loading={isLoading}
                size="small"
                variant="secondary"
                showLabel
              />
            </div>
          )}
        </div>
      </div>
      <div className={c('entities')}>
        <div>
          <Icon name="pipeline" size="small" />
          {pipeline ? (
            <OptionalTooltip
              showTooltip={pipeline.name.length > 32}
              content={pipeline.name}
            >
              <AppLink
                className={c('link')}
                to={`/design/pipelines/${deployment.pipeline_id}`}
              >
                {truncateMiddle(pipeline.name || 'Unknown', 32)}
              </AppLink>
            </OptionalTooltip>
          ) : (
            <Text className={c('skeleton-text')}>Loading pipeline...</Text>
          )}
        </div>

        <div>
          <Icon name="gateway" size="small" />
          {gateway ? (
            <OptionalTooltip
              showTooltip={gateway.name.length > 32}
              content={gateway.name}
            >
              <AppLink
                className={c('link')}
                to={`/deploy/gateways/${gateway.id}`}
              >
                {truncateMiddle(gateway.name || 'Unknown', 32)}
              </AppLink>
            </OptionalTooltip>
          ) : (
            <Text className={c('skeleton-text')}>Loading gateway...</Text>
          )}
        </div>
      </div>
      <div className={c('time')}>
        <Text>{timeFromNow}</Text>
      </div>
      {hasAccess('deploy_edit') && (
        <ContextMenu
          trigger={
            <IconButton
              className={c('control')}
              icon="more-vertical"
              label="Reveal more actions"
              variant="ghost"
            />
          }
        >
          <ContextMenuAction
            onClick={handleDeleteDeployment}
            icon="delete"
            intent="danger"
          >
            Delete
          </ContextMenuAction>
        </ContextMenu>
      )}
    </div>
  );
}
