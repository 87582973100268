import React from 'react';
import classnames from 'classnames/bind';
import { Column } from 'react-table';
import { DateTime } from 'luxon';
import { ActionIcon } from '@mantine/core';

import AccessToken, { AccessTokenType } from 'types/token';
import { IconType } from 'components/Icon/types/Icon';

import { useDeleteAccessToken } from 'hooks/api/useAccessTokens';

import { Box } from 'components/Box/Box';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';
import { Table } from 'components/Table/Table';
import { Text } from 'components/Text/Text';

import styles from './TokenTable.module.scss';

const c = classnames.bind(styles);

const AccessTokenTypeMap: Partial<
  Record<AccessTokenType, { icon: IconType; description: string }>
> = {
  application: { icon: 'dashboard', description: 'Application' },
  gateway_provisioning: {
    icon: 'gateway',
    description: 'Gateway provisioning',
  },
  mintable: { icon: 'camera', description: 'Universal bridge' },
  user: { icon: 'account', description: 'User' },
};

type TokenTableProps = {
  tokens?: AccessToken[];
  isLoading: boolean;
};

export function TokenTable({ tokens, isLoading }: TokenTableProps) {
  const [requestDelete] = useDeleteAccessToken();

  const columns = React.useMemo<Column<AccessToken>[]>(
    () => [
      {
        Header: 'Name',
        Cell({ row: { original } }) {
          return (
            <Heading className={c('name')} level="4">
              {original.name}
            </Heading>
          );
        },
      },
      {
        Header: 'Type',
        Cell({ row: { original } }) {
          const { icon, description } = AccessTokenTypeMap[original.type] || {
            icon: 'dashboard',
            description: 'Application',
          };

          return (
            <div className={c('type')}>
              <Icon name={icon} />
              <span>{description}</span>
            </div>
          );
        },
      },
      {
        Header: 'Expiration',
        Cell({ row: { original } }) {
          if (!original.expires_at) {
            return null;
          }

          return (
            <Text>
              {DateTime.fromISO(original.expires_at).toLocaleString(
                DateTime.DATETIME_MED
              )}
            </Text>
          );
        },
      },
      {
        Header: 'Created',
        id: 'created_at',
        Cell({ row: { original } }) {
          return (
            <Text>
              {DateTime.fromISO(original.created_at).toLocaleString(
                DateTime.DATETIME_MED
              )}
            </Text>
          );
        },
      },
      {
        id: 'controls',
        Cell({ row: { original } }) {
          return (
            <ActionIcon
              aria-label="Delete access token"
              color="red"
              variant="subtle"
              onClick={() => requestDelete(original)}
            >
              <Icon name="delete" unstyled />
            </ActionIcon>
          );
        },
      },
    ],
    [requestDelete]
  );

  if (!tokens && isLoading) {
    return <Loader text="Loading tokens..." />;
  }

  if (!tokens?.length) {
    return (
      <Box>
        <Text type="paragraph" align="center">
          No tokens generated yet.
        </Text>
      </Box>
    );
  }

  return (
    <Table<AccessToken>
      className={c('token-table')}
      id="token-table"
      label="Tokens"
      columns={columns}
      data={tokens}
      sortBy={[
        {
          id: 'created_at',
        },
      ]}
    />
  );
}
