import React from 'react';
import { Node as ReteNode } from 'rete';
import classnames from 'classnames/bind';

import ReteInput from 'pipelines/services/rete/controls/input';

import Node, { getPropertyValue } from 'types/node';

import { NodePropertyValueType } from 'pipelines/services/rete/nodes/types';

import { Text } from 'components/Text/Text';

import styles from './EditorNode.module.scss';

const c = classnames.bind(styles);

export type EditorNodePropertyProps = {
  node: ReteNode;
  label: string;
  name: string;
  type?: NodePropertyValueType;
};

export function EditorNodeProperty({
  node,
  label,
  name,
  type,
}: EditorNodePropertyProps) {
  if (label.length === 0) {
    return null;
  }

  const { component } = Node.fromReteNode(node);
  const Displayer = component?.getEditorDisplayer(name);
  if (!Displayer) {
    return null;
  }

  const control = node.controls?.get(name) as ReteInput;

  if (control.keyref && control.requiresKeyrefValue !== undefined) {
    const keyrefValue = getPropertyValue(node.data, control.keyref);

    if (keyrefValue !== control.requiresKeyrefValue) {
      return null;
    }
  }

  if (control?.inputType === 'textarea') {
    return null;
  }

  return (
    <div className={c('property')}>
      <dt className={c('property-label')}>{label}</dt>
      <Text size="small" asChild>
        <dd className={c('property-value')}>
          <Displayer node={node} property={name} type={type} />
        </dd>
      </Text>
    </div>
  );
}
