import { Select, SelectProps } from '../Select/Select';
import { DateTime } from 'luxon';

export type Expiration =
  | '2 hours'
  | '4 hours'
  | '8 hours'
  | '1 day'
  | '7 days'
  | '30 days'
  | '60 days'
  | '90 days'
  | '180 days'
  | '1 year'
  | 'No expiration';

export type ExpirationOption = {
  value: () => string | undefined;
  label: Expiration;
};

export const EXPIRATION_OPTIONS: Record<Expiration, ExpirationOption> = {
  '2 hours': {
    value: () => DateTime.now().plus({ hours: 2 }).toISO(),
    label: '2 hours',
  },
  '4 hours': {
    value: () => DateTime.now().plus({ hours: 4 }).toISO(),
    label: '4 hours',
  },
  '8 hours': {
    value: () => DateTime.now().plus({ hours: 8 }).toISO(),
    label: '8 hours',
  },
  '1 day': {
    value: () => DateTime.now().plus({ days: 1 }).toISO(),
    label: '1 day',
  },
  '7 days': {
    value: () => DateTime.now().plus({ days: 7 }).toISO(),
    label: '7 days',
  },
  '30 days': {
    value: () => DateTime.now().plus({ days: 30 }).toISO(),
    label: '30 days',
  },
  '60 days': {
    value: () => DateTime.now().plus({ days: 60 }).toISO(),
    label: '60 days',
  },
  '90 days': {
    value: () => DateTime.now().plus({ days: 90 }).toISO(),
    label: '90 days',
  },
  '180 days': {
    value: () => DateTime.now().plus({ days: 180 }).toISO(),
    label: '180 days',
  },
  '1 year': {
    value: () => DateTime.now().plus({ years: 1 }).toISO(),
    label: '1 year',
  },
  'No expiration': { value: () => undefined, label: 'No expiration' },
};

const OPTIONS = Object.values(EXPIRATION_OPTIONS);

/** Token expiration presets */
export function ExpirationSelect(props: SelectProps<ExpirationOption>) {
  return <Select {...props} options={OPTIONS} />;
}
