import React from 'react';
import classNames from 'classnames/bind';
import { CellProps, Column } from 'react-table';
import { useToggle } from '@mantine/hooks';

import Gateway, { GATEWAY_STATES, GatewayState } from 'types/gateway';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { UseGatewaysFilterParams, useGateways } from 'hooks/api/useGateways';
import { useTagsFilterParams } from 'tags/hooks/useTagsFilterParams';

import { AddGateway, AddGatewayModal } from 'gateways/components/AddGateway';
import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { PaginatedTable } from 'components/Table/PaginatedTable';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { RadioButton } from 'components/Radio/Radio';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { StateSelect } from 'components/StateSelect/StateSelect';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { TableLabelsCell } from 'components/Table/Table';
import { Text } from 'components/Text/Text';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { RapidDeployLayout } from './Layout';
import { RapidDeployRoute } from '../routes';
import { useRapidDeployState } from '../hooks/useRapidDeployState';
import styles from '../RapidDeploy.module.scss';

const c = classNames.bind(styles);

const DEFAULT_STATE_FILTER = {
  value: 'online' as const,
  label: 'online' as const,
};

const PAGE_SIZE = 20;

export function RapidDeployGatewaysList() {
  const tagFilters = useTagsFilterParams();

  const isInitialized = React.useRef(false);

  const { data: currentPlan, isSuccess } = useCurrentPlan();
  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);

  React.useEffect(() => {
    setFilters((filters) => ({ ...filters, ...tagFilters, page: 1 }));
  }, [tagFilters]);

  const [filters, setFilters] = React.useState<UseGatewaysFilterParams>({
    ...tagFilters,
    page: 1,
    statuses: [DEFAULT_STATE_FILTER.value],
    limit: PAGE_SIZE,
  });
  const queryResult = useGateways(filters, { keepPreviousData: true });

  const [searchFilters, setSearchFilters] =
    React.useState<UseGatewaysFilterParams>({});
  const searchQueryResult = useGateways(
    ['gateways-suggestion'],
    { ...searchFilters, limit: 20 },
    { enabled: Object.keys(searchFilters).length > 0, keepPreviousData: false }
  );

  useGateways(undefined, {
    onSettled(data) {
      if (!data || isInitialized.current) {
        return;
      }

      setHasViewedEmptyView(data.total_elements > 0);
      isInitialized.current = true;
    },
  });

  function handleSearchInput(value?: string) {
    if (!value) {
      setSearchFilters({});
      setFilters(({ gateway_names, ...filters }) => ({ ...filters, page: 1 }));
      return;
    }

    setSearchFilters({
      gateway_names: [value],
    });
  }

  function handleStateFilterChange(state: string | null) {
    setFilters((previousFilters) => ({
      ...previousFilters,
      statuses: state ? [state as GatewayState] : undefined,
      page: 1,
    }));

    setSearchFilters((previousFilters) => ({
      ...previousFilters,
      statuses: state ? [state as GatewayState] : undefined,
    }));
  }

  const [isAddingGateway, toggleIsAddingGateway] = useToggle();
  const [hasViewedEmptyView, setHasViewedEmptyView] = React.useState(false);

  const { data, isLoading } = queryResult;

  const { gateway, setGateway } = useRapidDeployState();

  const columns = React.useMemo(() => {
    function handleSelectGateway(gateway: Gateway) {
      setGateway(gateway);
    }

    const columns: Column<Gateway>[] = [
      {
        Cell: ({ row: { original } }: CellProps<Gateway>) => (
          <RadioButton
            id={`gateway_${original.id}`}
            value={original.id}
            name="rapid-deploy-gateways"
            checked={gateway?.id === original.id}
            onChange={(event) => {
              if (event.target.checked) {
                handleSelectGateway(original);
              }
            }}
          />
        ),
        accessor: 'id',
        width: 0,
      },
      {
        Cell: ({ row: { original } }: CellProps<Gateway>) => (
          <StatusIndicator status={original.status} size="small" />
        ),
        accessor: 'status',
        width: 0,
      },
      {
        Cell: ({ row: { original } }: CellProps<Gateway>) => (
          <label className={c('table-cell')} htmlFor={`gateway_${original.id}`}>
            <Heading level="4" asChild>
              <strong>{original.name}</strong>
            </Heading>
            {original.model && <Text>{original.model}</Text>}
            <Text className={c('table-cell-properties')}>
              {(original.ip_ext || original.ip_local) && (
                <span>
                  <Icon name="global" />
                  <span>{original.ip_ext ?? original.ip_local}</span>
                </span>
              )}

              <Tooltip content="Lumeo version">
                <span>
                  <Icon name="logo" />
                  <span>v{original.version}</span>
                </span>
              </Tooltip>
            </Text>
          </label>
        ),
        accessor: 'name',
      },
    ];

    if (canCreateTags) {
      columns.push({
        Cell: (props: CellProps<Gateway>) => (
          <TableLabelsCell<Gateway>
            {...props}
            entity="gateways"
            listQueryKey={['gateways']}
            singleQueryKey={['gateway']}
            readOnly
          />
        ),
        accessor: 'tags',
      });
    }

    return columns;
  }, [gateway, canCreateTags, setGateway]);

  function Buttons() {
    return (
      <ButtonGroup>
        <Button
          variant="secondary"
          size="small"
          onClick={() => toggleIsAddingGateway()}
        >
          <Icon name="plus" size="small" />
          <span>Add new gateway</span>
        </Button>
      </ButtonGroup>
    );
  }

  function Search() {
    const { statuses } = filters;
    const [status] = statuses ?? [];

    return (
      <QuerySearch
        entityName="gateway"
        searchLabel="Search gateways..."
        searchResultField="name"
        searchQueryResult={searchQueryResult}
        onApplyFilters={handleApplyFilters}
        onSuggestionSelect={setGateway}
        onValueChange={handleSearchInput}
        totalElements={queryResult.data?.total_elements}
        filters={
          <StateSelect
            onChange={handleStateFilterChange}
            options={GATEWAY_STATES}
            value={status}
            size="small"
          />
        }
        actions={
          <Button
            size="small"
            variant="secondary"
            onClick={() => queryResult.refetch()}
            loading={queryResult.isRefetching}
          >
            <Icon name="rotate" />
            <span>Refresh</span>
          </Button>
        }
      />
    );
  }

  if (isLoading) {
    return (
      <RapidDeployLayout>
        <Buttons />
        <Search />
        <div className={c('loader-table')}>
          <SkeletonList min={4} component={<LoaderRow />} />
        </div>
      </RapidDeployLayout>
    );
  }

  if (!hasViewedEmptyView && !data?.total_elements) {
    return (
      <RapidDeployLayout onContinue={() => setHasViewedEmptyView(true)}>
        <AddGateway />
      </RapidDeployLayout>
    );
  }

  function handleApplyFilters() {
    setFilters((filters) => ({ ...filters, ...searchFilters, page: 1 }));
  }

  return (
    <RapidDeployLayout
      canContinue={Boolean(gateway)}
      onContinue={`../${RapidDeployRoute.VIDEO_SOURCES}`}
    >
      <Buttons />

      <Search />

      <PaginatedTable<Gateway>
        className={c('table')}
        id="rapid-deploy-gateways"
        label="Gateways"
        queryResult={queryResult}
        columns={columns}
        pageSize={PAGE_SIZE}
        page={filters.page}
        onPageChange={(page) => setFilters((filters) => ({ ...filters, page }))}
        onRowClick={setGateway}
      />

      <AddGatewayModal open={isAddingGateway} onClose={toggleIsAddingGateway} />
    </RapidDeployLayout>
  );
}

function LoaderRow() {
  return (
    <div className={c('loader-table-row')}>
      <div className={c('loader-table-cell', 'auto')}>
        <span className={c('loader-radio')} />
      </div>

      <div className={c('loader-table-cell', 'auto')}>
        <span className={c('skeleton-bar', 'loader-status-indicator')} />
      </div>

      <div className={c('loader-table-cell')}>
        <Heading level="4" asChild>
          <span className="skeleton-text" />
        </Heading>
        <Text asChild>
          <span className="skeleton-text" />
        </Text>
        <Text asChild>
          <span className="skeleton-text" />
        </Text>
      </div>
    </div>
  );
}
