import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import PricingPlan from 'types/pricing_plan';

import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function usePricingPlans(
  options?: UseQueryOptions<PricingPlan[], APIError>
) {
  const api = useAPI();
  const { data: organization } = useCurrentOrganization();

  return useAuthenticatedQuery(
    ['pricing_plans', 'org', organization?.id],
    () => api.organizations.plans.list(organization!.id),
    {
      ...options,
      staleTime: options?.staleTime ?? Infinity,
      enabled: Boolean(organization),
    }
  );
}
