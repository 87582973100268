export enum NavRoute {
  OVERVIEW = '',
  DESIGN = 'design',
  DEPLOY = 'deploy',
  MONITOR = 'monitor',
  SETTINGS = 'settings',
  ADMIN = 'admin',
}

export type SidebarRoute =
  | DesignRoute
  | DeployRoute
  | MonitorRoute
  | SettingsRoute
  | AdminRoute;

export enum DesignRoute {
  PIPELINES = 'pipelines',
  MODELS = 'models',
  SOLUTIONS = 'solutions',
  MARKETPLACE = 'marketplace',
}

export enum DeployRoute {
  GATEWAYS = 'gateways',
  CAMERAS = 'cameras',
  STREAMS = 'streams',
  FILES = 'files',
  DEPLOYMENTS = 'deployments',
  EVENTS = 'events',
  RAPID_DEPLOY = 'new',
}

export enum MonitorRoute {
  SEARCH = 'search',
  DASHBOARDS = 'dashboards',
}

export enum SettingsRoute {
  ACCESS_TOKENS = 'access-tokens',
  UNIVERSAL_BRIDGE = 'universal-bridge',
  METRICS = 'metrics',
  WORKSPACE = 'workspace',
  ORGANIZATION = 'organization',
  PLAN = 'plans',
  MEMBERS = 'members',
  ACCOUNT = 'account',
  PASSWORD = 'password',
}

export enum AdminRoute {
  MARKETPLACE_MODELS = 'marketplace-models',
}
