import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames/bind';

import { CommonButtonProps } from './Button';
import styles from './Button.module.scss';

const c = classNames.bind(styles);

export type LinkTypeButtonProps = CommonButtonProps & {
  type?: never;
  onClick?: never;
  href?: string;
  to?: LinkProps['to'];
} & Pick<LinkProps, 'rel' | 'target' | 'download'>;

function LinkTypeButton(
  {
    children,
    className: customClassName,
    asChild,
    variant,
    intent,
    size,
    autoFocus,
    disabled,
    loading,
    to,
    href,
    ...props
  }: LinkTypeButtonProps,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const themeClassName =
    intent === 'default' && variant === 'primary' ? 'primary' : intent;

  const className = c(
    customClassName,
    variant,
    size,
    `theme ${themeClassName}`,
    {
      'focus-ring': variant !== 'primary',
      'focus-ring-soft': variant === 'primary',
      [`intent-${intent}`]: intent,

      button: variant !== 'none' && variant !== 'link',
    }
  );

  if (asChild && React.isValidElement(children)) {
    const cloneProps = {
      ...props,
      'aria-disabled': disabled,
      className: c(children.props.className, className),
      autoFocus,
      disabled,
      ref,
    };

    return React.cloneElement(children, cloneProps);
  }

  if (to) {
    return (
      <Link
        {...props}
        className={className}
        to={to}
        aria-disabled={disabled}
        ref={ref}
      >
        {children}
      </Link>
    );
  }

  return (
    <a
      {...props}
      className={className}
      href={href}
      aria-disabled={disabled}
      ref={ref}
    >
      {children}
    </a>
  );
}

const ForwardRef = React.forwardRef<HTMLAnchorElement, LinkTypeButtonProps>(
  LinkTypeButton
);

export { ForwardRef as LinkTypeButton };
