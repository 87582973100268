import React from 'react';
import classnames from 'classnames/bind';
import { FilterOperator } from '@propeldata/ui-kit';
import { Controller, useForm } from 'react-hook-form';

import { FilterInput } from 'graphql/types/filter';

import { generateId } from 'services/string';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Icon } from 'components/Icon/Icon';

import {
  LogicalOperator,
  LogicalOperatorSelect,
} from './LogicalOperatorSelect';
import { MetricFilter } from './MetricFilter';
import { MetricFilterGroup } from './MetricFilterGroup';
import { MetricFiltersInputContext } from './context';
import styles from './MetricFiltersInput.module.scss';

const c = classnames.bind(styles);

const EMPTY: FilterInput[] = [];

export type MetricFiltersInputContextProps =
  | {
      filters: FilterInput[];
      metric: { name: string };
      addFilter: (logical: LogicalOperator, parent?: string) => void;
      updateFilter: (
        id: string,
        property: keyof FilterInput,
        value?: string
      ) => void;
      updateLogicalOperator: (logical: LogicalOperator, id: string) => void;
      removeFilter: (id: string) => void;
    }
  | undefined;

export type MetricFiltersInputProps = {
  metric: { name: string };
  value?: FilterInput[];
  onChange?: (newValue: FilterInput[]) => void;
};

export function MetricFiltersInput({
  metric,
  value,
  onChange,
}: MetricFiltersInputProps) {
  const [filters, setFilters] = React.useState<FilterInput[]>(value || EMPTY);

  const [rootFilter] = filters;

  const { control, getValues } = useForm<{ logical: LogicalOperator }>({
    defaultValues: { logical: rootFilter?.or ? 'OR' : 'AND' },
  });

  React.useEffect(() => {
    onChange?.(filters);
  }, [filters, onChange]);

  function insertFilter(
    logical: LogicalOperator,
    parent: string,
    newFilter: FilterInput,
    filters: FilterInput[]
  ): FilterInput[] {
    return filters.map((filter) => {
      if (filter.id === parent) {
        if (logical === 'AND') {
          return {
            ...filter,
            and: filter?.and ? [...filter.and, newFilter] : [newFilter],
          };
        }

        if (logical === 'OR') {
          return {
            ...filter,
            or: filter?.or ? [...filter.or, newFilter] : [newFilter],
          };
        }
      }

      if (filter?.and) {
        return {
          ...filter,
          and: insertFilter(logical, parent, newFilter, filter.and),
        };
      }

      if (filter?.or) {
        return {
          ...filter,
          or: insertFilter(logical, parent, newFilter, filter.or),
        };
      }

      return filter;
    });
  }

  /**
   * Adds a new filter.
   * @param logical - The logical operator used to connect the new filter with the parent filter.
   * @param parent - Id of the parent filter.
   */
  function addFilter(logical: LogicalOperator, parent?: string) {
    const newFilter = {
      id: generateId(),
      column: '',
      operator: FilterOperator.Equals,
      value: '',
    };

    if (!parent) {
      setFilters([newFilter]);
      return;
    }

    setFilters((previousFilters) =>
      insertFilter(logical, parent, newFilter, previousFilters)
    );
  }

  function handleAddFilter() {
    addFilter(getValues('logical'), rootFilter?.id);
  }

  function handleAddFilterGroup() {
    const logical = getValues('logical');

    const newFilterGroup = {
      id: generateId(),
      column: '',
      operator: FilterOperator.Equals,
      value: '',
      and: [],
    };

    if (filters.length === 0) {
      setFilters([newFilterGroup]);
      return;
    }

    setFilters((previousFilters) =>
      insertFilter(logical, rootFilter.id, newFilterGroup, previousFilters)
    );
  }

  const updateFilter = React.useCallback(
    (id: string, property: keyof FilterInput, value?: string) => {
      setFilters((previousFilters) => {
        const updateProperty = (filter: FilterInput): FilterInput => {
          if (filter.id === id) {
            return { ...filter, [property]: value };
          }

          if (filter?.and || filter?.or) {
            return {
              ...filter,
              and: filter.and?.map(updateProperty),
              or: filter.or?.map(updateProperty),
            };
          }

          return filter;
        };

        return previousFilters.map(updateProperty);
      });
    },
    []
  );

  function switchLogicalOperator(
    logical: LogicalOperator,
    id: string,
    filters: FilterInput[]
  ): FilterInput[] {
    return filters.map((filter) => {
      if (filter.id === id) {
        if (logical === 'AND' && filter?.or) {
          const { and, or, ...rest } = filter;

          return {
            ...rest,
            and: or,
          };
        }

        if (logical === 'OR' && filter?.and) {
          const { and, or, ...rest } = filter;

          return {
            ...rest,
            or: and,
          };
        }
      }

      if (filter?.and) {
        return {
          ...filter,
          and: switchLogicalOperator(logical, id, filter.and),
        };
      }

      if (filter?.or) {
        return {
          ...filter,
          or: switchLogicalOperator(logical, id, filter.or),
        };
      }

      return filter;
    });
  }

  /**
   * Changes how filters are logically connected.
   * @param logical - Logical operator to switch to.
   * @param id - Id of the root filter.
   */
  function updateLogicalOperator(logical: LogicalOperator, id: string) {
    setFilters((previousFilters) =>
      switchLogicalOperator(logical, id, previousFilters)
    );
  }

  /**
   * Removes a filter by Id.
   * If a root filter is removed the first filter inside `and` or `or` will be promoted to become the new root filter.
   */
  function deleteFilter(id: string, filters: FilterInput[]) {
    return filters
      .map((filter) => {
        if (filter.id === id && !filter?.and && !filter?.or) {
          return null;
        }

        if (filter.id === id && filter?.and) {
          const promotedFilter = filter.and.shift();

          return promotedFilter
            ? { ...promotedFilter, and: [...filter.and] }
            : null;
        }

        if (filter.id === id && filter?.or) {
          const promotedFilter = filter.or.shift();

          return promotedFilter
            ? { ...promotedFilter, or: [...filter.or] }
            : null;
        }

        if (filter?.and) {
          filter.and = deleteFilter(id, filter.and);
        }

        if (filter?.or) {
          filter.or = deleteFilter(id, filter.or);
        }

        return filter;
      })
      .filter(Boolean) as FilterInput[];
  }

  function removeFilter(id: string) {
    setFilters((previousFilters) => deleteFilter(id, previousFilters));
  }

  return (
    <MetricFiltersInputContext.Provider
      value={{
        filters,
        metric,
        addFilter,
        updateFilter,
        updateLogicalOperator,
        removeFilter,
      }}
    >
      <div className={c('input')}>
        {filters.map((filter) => (
          <div className={c('group')} key={filter.id}>
            <div className={c('group-item')}>
              <Controller
                name="logical"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <LogicalOperatorSelect
                    id={`logical-operator-switch-${filter.id}`}
                    value={value}
                    onChange={(logical) => {
                      updateLogicalOperator(
                        logical as LogicalOperator,
                        rootFilter.id
                      );
                      onChange(logical);
                    }}
                  />
                )}
              />
            </div>

            <MetricFilter {...filter} />

            {filter?.and?.map((andFilter) => {
              if (andFilter?.and || andFilter?.or) {
                return <MetricFilterGroup key={andFilter.id} {...andFilter} />;
              }

              return <MetricFilter key={andFilter.id} {...andFilter} />;
            })}

            {filter?.or?.map((orFilter) => {
              if (orFilter?.and || orFilter?.or) {
                return <MetricFilterGroup key={orFilter.id} {...orFilter} />;
              }

              return <MetricFilter key={orFilter.id} {...orFilter} />;
            })}
          </div>
        ))}

        <ButtonGroup>
          <Button variant="secondary" size="xsmall" onClick={handleAddFilter}>
            <Icon name="plus" size="xsmall" />
            <span>Add filter</span>
          </Button>
          <Button
            variant="secondary"
            size="xsmall"
            onClick={handleAddFilterGroup}
            disabled={!filters.length}
          >
            <Icon name="plus" size="xsmall" />
            <span>Add filter group</span>
          </Button>
        </ButtonGroup>
      </div>
    </MetricFiltersInputContext.Provider>
  );
}
