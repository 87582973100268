import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import Camera from 'types/camera';
import Gateway from 'types/gateway';

import { useGateways } from 'hooks/api/useGateways';
import { useUpdateCamera } from 'cameras/hooks/useUpdateCamera';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { Heading } from 'components/Heading/Heading';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Text } from 'components/Text/Text';

type GatewaySettingsProps = {
  camera: Camera;
};

type GatewaySettingsFieldValues = {
  gateway: Gateway;
};

export function GatewaySettings({ camera }: GatewaySettingsProps) {
  const [initialGatewayId, setInitialGatewayId] = React.useState(
    camera.gateway_id
  );

  const { data: initialGateways, isFetching: isGatewaysLoading } = useGateways(
    [`camera-settings-gateways`],
    {
      gateway_ids: initialGatewayId ? [initialGatewayId] : undefined,
    },
    {
      enabled: initialGatewayId !== null && initialGatewayId !== undefined,
    }
  );

  const {
    formState: { isDirty },
    handleSubmit,
    control,
    reset,
  } = useForm<GatewaySettingsFieldValues>({
    defaultValues: { gateway: initialGateways?.data[0] },
  });

  React.useEffect(() => {
    reset({ gateway: initialGateways?.data[0] });
  }, [initialGateways, reset]);

  const {
    mutateAsync: updateCamera,
    isLoading,
    isSuccess,
    error,
  } = useUpdateCamera({
    onSuccess: (camera) => {
      setInitialGatewayId(camera.gateway_id);
    },
  });

  function onSubmit({ gateway }: GatewaySettingsFieldValues) {
    if (!gateway) {
      return;
    }

    const updatedCamera = camera.copy();
    updatedCamera.gateway_id = gateway.id;
    updateCamera(updatedCamera);
  }

  if (
    (!initialGateways || initialGateways?.data.length === 0) &&
    !isGatewaysLoading
  ) {
    return (
      <section>
        <Heading level="3">Gateway</Heading>
        <FormMessage icon={'warning'} intent="danger">
          <Text>Invalid gateway</Text>
        </FormMessage>
      </section>
    );
  }

  return (
    <section>
      <Heading level="3">Gateway</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="gateway"
          control={control}
          render={({ field: { value, onChange } }) => (
            <GatewaySelect
              value={value}
              onChange={onChange}
              isLoading={isGatewaysLoading}
              isClearable={false}
            />
          )}
        />

        <FormErrorMessage error={error} />

        <ButtonGroup>
          <Button
            variant="primary"
            type="submit"
            size="small"
            loading={isLoading}
          >
            Save Gateway
          </Button>

          {isDirty && <InlineNotification>Unsaved changes</InlineNotification>}

          {isSuccess && !isDirty && (
            <InlineNotification intent="success">Saved!</InlineNotification>
          )}
        </ButtonGroup>
      </form>
    </section>
  );
}
