import React from 'react';
import { matchSorter } from 'match-sorter';
import { useCombobox } from 'downshift';
import * as RadixPortal from '@radix-ui/react-portal';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react-dom';

import { AutosizeInput } from 'components/AutosizeInput/AutosizeInput';
import { IconButton } from 'components/IconButton/IconButton';
import { Input } from 'components/Input/Input';
import {
  AccessorSegment,
  DisplayRange,
  InputRange,
} from 'types/trigger_condition';

import classNames from 'classnames/bind';

import { MetadataListChunk } from './MetadataListChunk';
import styles from './MetadataListInput.module.scss';

const c = classNames.bind(styles);

const ADD_CUSTOM = 'Add custom pattern';

export type MetadataListInputProps = {
  options: string[];
  selected: string[];
  onChange?: (selected: string[] | null) => void;
};

type OptionWrapper = {
  option: string;
  editCache: string;
  isEditing: boolean;
  isCommitted: boolean;
};

export function MetadataListInput({
  options,
  onChange,
  selected,
}: MetadataListInputProps) {
  const [inputValue, setInputValue] = React.useState('');

  const initialSelection = selected.map((item) => {
    return {
      isEditing: false,
      isCommitted: true,
      editCache: '',
      option: item,
    } as OptionWrapper;
  });

  const [filteredSuggestions, setFilteredSuggestions] = React.useState(options);

  function filteredSuggestionsWithCustom() {
    return [ADD_CUSTOM, ...filteredSuggestions];
  }

  const [selectedItems, setSelectedItems] =
    React.useState<OptionWrapper[]>(initialSelection);

  const {
    isOpen,
    highlightedIndex,
    getMenuProps,
    getInputProps,
    getItemProps,
    openMenu,
    reset,
  } = useCombobox({
    inputValue,
    onInputValueChange({ inputValue }) {
      const matches = matchSorter(options, inputValue || '');
      setFilteredSuggestions(matches);
    },
    items: filteredSuggestionsWithCustom(),
    itemToString(item) {
      return item ?? '';
    },
    onStateChange({ inputValue, type, selectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || '');
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            selectedItem = selectedItem === ADD_CUSTOM ? '' : selectedItem;
            setInputValue('');
            setSelectedItems((oldItems) => [
              {
                option: selectedItem,
                editCache: selectedItem,
                isEditing: true,
                isCommitted: false,
              } as OptionWrapper,
              ...oldItems,
            ]);
            setFilteredSuggestions(options);
            reset();
          }
          break;
        default:
          break;
      }
    },
  });

  const [menuStyles, setMenuStyles] = React.useState<React.CSSProperties>({});

  // Menu overlay positioning configuration
  const floatingConfig = React.useMemo(
    () => ({
      placement: 'bottom-start' as const,
      middleware: [
        offset(8),
        shift({ padding: 8 }),
        flip(),
        size({
          padding: 8,
          apply({ width, height, reference }) {
            setMenuStyles((styles) => ({
              ...styles,
              maxHeight: `${Math.max(100, height)}px`,
              maxWidth: `${width}px`,
              width: `${reference.width}px`,
            }));
          },
        }),
      ],
    }),
    []
  );

  const { x, y, strategy, refs, reference, floating, update } =
    useFloating(floatingConfig);

  const menuProps = React.useMemo(
    () => getMenuProps({ ref: floating }),
    [getMenuProps, floating]
  );

  React.useEffect(() => {
    if (isOpen && refs.reference.current && refs.floating.current) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [isOpen, refs.floating, refs.reference, update]);

  // repost back the changes
  React.useEffect(() => {
    if (!onChange) {
      return;
    }

    if (selectedItems.length === 0) {
      onChange(null);
      return;
    }

    onChange(
      selectedItems.map((item) => {
        return item.option;
      })
    );
  }, [selectedItems, onChange]);

  return (
    <div className={c('wrap')}>
      <div className={c('combobox')} ref={reference}>
        <Input
          {...getInputProps({ onClick: () => openMenu() })}
          onValueChange={setInputValue}
        />
      </div>
      <RadixPortal.Root>
        <ul
          {...menuProps}
          className={c('combobox-menu')}
          style={{
            ...menuStyles,
            position: strategy,
            top: 0,
            left: 0,
            transform: `translate3d(${x}px, ${y}px, 0)`,
          }}
        >
          {isOpen &&
            filteredSuggestionsWithCustom().map((item, index) => (
              <li
                {...getItemProps({ item, index })}
                className={c('combobox-option', {
                  active: highlightedIndex === index,
                })}
                key={`${item}${index}`}
              >
                {item}
              </li>
            ))}
        </ul>
      </RadixPortal.Root>

      {selectedItems.length > 0 && (
        <div className={c('selection')}>
          {selectedItems.map((selectedItem, index) => {
            return (
              <span
                className={c('selected-item')}
                key={`selected-item-${index}`}
              >
                <div className={c('selected-item-display')}>
                  {selectedItem.isEditing && (
                    <InlineEdit
                      initialValue={selectedItem.option}
                      setSelectedItems={setSelectedItems}
                      index={index}
                    />
                  )}
                  {!selectedItem.isEditing && selectedItem.option}
                </div>
                <IconButton
                  icon={selectedItem.isEditing ? 'check' : 'edit'}
                  label={`Edit ${selectedItem.option}`}
                  onClick={() => {
                    setSelectedItems((old) => {
                      const update = [...old];
                      const item = update[index];
                      update[index] = {
                        editCache: item.option,
                        option: !item.isEditing ? item.option : item.editCache,
                        isEditing: !item.isEditing,
                        isCommitted: true,
                      };
                      return update;
                    });
                  }}
                  size="xsmall"
                  variant="ghost"
                />
                {selectedItem.isEditing && selectedItem.isCommitted && (
                  <IconButton
                    icon="cancel"
                    label={`cancel edit of ${selectedItem.option}`}
                    onClick={() => {
                      setSelectedItems((old) => {
                        const update = [...old];
                        const item = update[index];
                        update[index] = {
                          ...item,
                          editCache: '',
                          isEditing: !item.isEditing,
                        };
                        return update;
                      });
                    }}
                    size="xsmall"
                    variant="ghost"
                  />
                )}
                {(!selectedItem.isEditing ||
                  (selectedItem.isEditing && !selectedItem.isCommitted)) && (
                  <IconButton
                    icon="delete" // cancel
                    label={`Remove ${selectedItem.option}`}
                    onClick={() => {
                      setSelectedItems((old) => {
                        const update = [...old];
                        update.splice(index, 1);
                        return update;
                      });
                    }}
                    size="xsmall"
                    variant="ghost"
                  />
                )}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
}

type InlineEditProps = {
  initialValue?: string;
  setSelectedItems: React.Dispatch<React.SetStateAction<OptionWrapper[]>>;
  index: number;
};

function InlineEdit({
  initialValue,
  setSelectedItems,
  index,
}: InlineEditProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const scrollTargetRef = React.useRef<HTMLSpanElement>(null);

  const [value, setValue] = React.useState(initialValue || '');

  const hasInitiallyShownError = React.useRef(false);

  const [cursorPosition, setCursorPosition] = React.useState<number | null>(
    null
  );

  const [ranges, setRanges] = React.useState<DisplayRange[]>([]);

  // require focus on the input after it is created
  React.useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.focus();
  }, []);

  // Parse input value into trigger condition
  React.useEffect(() => {
    const ranges = parseInputString(value);
    setRanges(ranges);

    // move cursor to the first error to highlight after creation
    if (!hasInitiallyShownError.current) {
      hasInitiallyShownError.current = true;

      ranges.every((range) => {
        if (range.errors.length > 0) {
          setCursorPosition(range.errors[0].range.begin);

          if (scrollTargetRef.current) {
            scrollTargetRef.current.innerText = value.substring(
              0,
              range.errors[0].range.end
            );

            window.setTimeout(() => {
              if (!scrollTargetRef.current || !scrollRef.current) {
                return;
              }

              scrollRef.current.scrollLeft =
                scrollTargetRef.current.scrollWidth -
                scrollRef.current.clientWidth / 2.0;
            });
          }

          return false;
        }
        return true;
      });
      return;
    }
  }, [value]);

  const [hasFocus, setHasFocus] = React.useState(false);

  // Track cursor position
  const handleInputSelectionChange = React.useCallback(() => {
    if (inputRef.current && document.activeElement === inputRef.current) {
      setCursorPosition(inputRef.current.selectionStart);
      return;
    }

    setCursorPosition(null);
  }, []);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedItems((old) => {
      const update = [...old];
      const item = update[index];
      update[index] = {
        ...item,
        editCache: event.target.value,
      };
      setValue(event.target.value);
      return update;
    });
  }

  function handleInputFocus() {
    setHasFocus(true);
    handleInputSelectionChange();
  }

  function handleInputBlur() {
    setHasFocus(false);
  }

  function handleInputClick() {
    handleInputSelectionChange();
  }

  const inputProps = {
    value,
    ref: inputRef,
    onChange: handleInputChange,
    onFocus: handleInputFocus,
    onBlur: handleInputBlur,
    onKeyUp: handleInputSelectionChange,
    onClick: handleInputClick,
  };

  return (
    <div className={c('edit-wrap')}>
      {/* Simulate scroll behavior of a native input component */}
      <ScrollArea.Root className={c('input')}>
        <ScrollArea.ScrollAreaViewport ref={scrollRef}>
          {/* Mirrored (partial) value to determine scroll position 
          Not sure why this needs to be outside of the `input-content` div that 
          provides `position: relative` that should theoretically necessary
          but breaks it somehow by changing the width to the full width of
          of the other children – the exact opposite of what it is supposed to do*/}
          <span
            ref={scrollTargetRef}
            className={c('input-mirror')}
            aria-hidden="true"
          />
          <div className={c('input-content')}>
            {/* Mirrored value for error highlighting */}
            <div className={c('input-mirror')} aria-hidden="true">
              {ranges.map((props, index) => {
                return (
                  <MetadataListChunk
                    {...props}
                    cursorPosition={cursorPosition}
                    inputRef={inputRef}
                    showTooltips={hasFocus}
                    key={`${index}_${props.range.begin}-${props.range.end}`}
                  />
                );
              })}
            </div>

            {/* User-controlled input */}
            <AutosizeInput
              {...inputProps}
              onKeyUp={handleInputSelectionChange}
              className={c('input-field')}
              onChange={handleInputChange}
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </ScrollArea.ScrollAreaViewport>

        <ScrollArea.Scrollbar orientation="horizontal">
          <ScrollArea.ScrollAreaThumb />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </div>
  );
}

// This is a very simple way to create DisplayRanges used by the ConditionInputChunk.
// MetadataListInputs don't exactly replicate the parsing behaviour of Trigger Conditions
// and most of the Errors would not apply here anyway. So the only Errors we're
// replicating here is for variables. (e.g. test.[variable].value)
function parseInputString(inputString: string): DisplayRange[] {
  const regex = /(\[[^\]]+\])|([^[\]]+)/g;
  const matches = inputString.matchAll(regex);
  const result = [];

  for (const match of matches) {
    if (match.index === undefined) {
      continue;
    }

    const [matchText, bracketed] = match;
    const range = {
      input: inputString,
      text: matchText,
      begin: match.index,
      end: match.index + matchText.length,
    };
    const errors = bracketed
      ? [
          {
            message: 'The placeholder still needs to be filled in.',
            range,
          },
        ]
      : [];

    // this makes the ConditionInputChunk believe its a variable
    // in order to mark the error for immidate replacement
    const elements = bracketed
      ? [new AccessorSegment(bracketed, true, true, range as InputRange)]
      : [];

    result.push({ range, errors, elements });
  }

  return result as unknown as DisplayRange[];
}
