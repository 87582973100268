import classNames from 'classnames/bind';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import styles from './ContextMenu.module.scss';

const c = classNames.bind(styles);

export type ContextMenuSeparatorProps = {};

export function ContextMenuSeparator(_: ContextMenuSeparatorProps) {
  return <DropdownMenu.Separator className={c('separator')} />;
}
