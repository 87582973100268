import RGL from 'react-grid-layout';
import {
  DimensionInput,
  Sort,
  TimeSeriesGranularity,
} from '@propeldata/ui-kit';

import Stream from 'types/stream';
import { UseFilesParams } from 'files/hooks/useFiles';
import { UseStreamsFilterParams } from 'streams/hooks/useStreams';
import { FilterInput } from 'graphql/types/filter';

import { generateId } from 'services/string';

export type WidgetType = 'data' | 'iframe' | 'stream' | 'multistream' | 'files';
export type ChartType =
  | 'timeseries-bar'
  | 'timeseries-line'
  | 'timeseries-bar-stacked'
  | 'timeseries-bar-grouped'
  | 'timeseries-heatmap'
  | 'pie'
  | 'leaderboard-bar'
  | 'leaderboard-table'
  | 'counter';

type DataWidgetQueryBase = {
  /** metricName */
  metric: string;
  filters?: FilterInput[];
};

// Selected chart props that are persisted with api-server
type DataWidgetQuery<T extends ChartType> = T extends
  | 'timeseries-bar'
  | 'timeseries-line'
  ? DataWidgetQueryBase & {
      granularity: TimeSeriesGranularity;
    }
  : T extends
        | 'timeseries-bar-stacked'
        | 'timeseries-bar-grouped'
        | 'timeseries-heatmap'
    ? DataWidgetQueryBase & {
        dimensions: DimensionInput[];
        granularity: TimeSeriesGranularity;
      }
    : T extends 'pie' | 'leaderboard-bar' | 'leaderboard-table'
      ? DataWidgetQueryBase & {
          dimensions: DimensionInput[];
          rowLimit: number;
          sort: Sort;
        }
      : T extends 'counter'
        ? DataWidgetQueryBase
        : never;

export abstract class DashboardWidget<Type extends WidgetType = WidgetType> {
  id: string;

  name: string;

  type: Type;

  layout: RGL.Layout;

  protected constructor(
    id: string,
    name: string,
    type: Type,
    layout: Omit<RGL.Layout, 'i'>
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.layout = {
      i: id,
      ...layout,
    };
  }

  static duplicate(
    originalWidget: DashboardWidget,
    newLayout: Omit<RGL.Layout, 'i'> | undefined
  ) {
    const newId = generateId();
    const duplicatedWidget = {
      ...originalWidget,
      id: newId,
      name: `Copy of ${originalWidget.name}`,
      layout: newLayout
        ? { ...newLayout, i: newId }
        : { ...originalWidget.layout, i: newId },
    } as DashboardWidget;

    return duplicatedWidget;
  }
}

export class DataWidget<T extends ChartType> extends DashboardWidget<'data'> {
  chartType: ChartType;
  metricId: string;
  query: DataWidgetQuery<T>;

  constructor(
    id: string,
    name: string,
    chartType: ChartType,
    metricId: string,
    query: DataWidgetQuery<T>,
    layout: Omit<RGL.Layout, 'i'>
  ) {
    super(id, name, 'data', layout);
    this.chartType = chartType;
    this.metricId = metricId;
    this.query = query;
  }
}

export class IframeWidget extends DashboardWidget<'iframe'> {
  /** Iframe URL */
  url: string;

  constructor(
    id: string,
    name: string,
    url: string,
    layout: Omit<RGL.Layout, 'i'>
  ) {
    super(id, name, 'iframe', layout);
    this.url = url;
  }
}

export class StreamWidget extends DashboardWidget<'stream'> {
  streamId: Stream['id'];
  autoPlay: boolean;

  constructor(
    id: string,
    name: string,
    streamId: string,
    autoPlay: boolean,
    layout: Omit<RGL.Layout, 'i'>
  ) {
    super(id, name, 'stream', layout);
    this.streamId = streamId;
    this.autoPlay = autoPlay;
  }
}

export class MultiStreamWidget extends DashboardWidget<'multistream'> {
  options: UseStreamsFilterParams;
  autoPlay: boolean;

  constructor(
    id: string,
    name: string,
    options: UseStreamsFilterParams,
    autoPlay: boolean,
    layout: Omit<RGL.Layout, 'i'>
  ) {
    super(id, name, 'multistream', layout);
    this.options = options;
    this.autoPlay = autoPlay;
  }
}

export class FilesWidget extends DashboardWidget<'files'> {
  options: UseFilesParams;

  constructor(
    id: string,
    name: string,
    options: UseFilesParams,
    layout: Omit<RGL.Layout, 'i'>
  ) {
    super(id, name, 'files', layout);
    this.options = options;
  }
}
