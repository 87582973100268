import React from 'react';
import classnames from 'classnames/bind';

import { AppEntity, AppEntityObject } from 'hooks/useEntities';

import { useAdvancedSearch } from '../../services/AdvancedSearchContext';
import { AdvancedSearchBarInputProps } from './AdvancedSearchBarInput';
import styles from './AdvancedSearchBar.module.scss';

const c = classnames.bind(styles);

export type AdvancedSearchBarWrapProps = AdvancedSearchBarInputProps;

function AdvancedSearchBarWrap(
  { children }: AdvancedSearchBarWrapProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const {
    searchInput,
    handleSearch,
    suggestions,
    suggestionsCount,
    setRelatedEntityFilter,
    removeRelatedEntityFilter,
    getPropertyFromEntityName,
  } = useAdvancedSearch();

  const isMounted = React.useRef(false);

  const [cursor, setCursor] = React.useState(0);
  const [didConfirmRemoveTag, setDidConfirmRemoveTag] = React.useState(false);

  // Reset local value up-to-date if context value is reset,
  // and add focus back to input field
  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (searchInput.trim() === '') {
      handleSearch();
    }
  }, [searchInput, handleSearch]);

  function handleCursorChange(cursor: React.SetStateAction<number>) {
    setCursor(cursor);
  }

  function handleConfirmRemoveTagChange(tag: React.SetStateAction<boolean>) {
    setDidConfirmRemoveTag(tag);
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (cursor <= 0) {
      handleSearch();
    } else {
      const { text, ...results } = suggestions;

      const { entity, ...match } = Object.entries(results)
        .map(([entity, matches]) =>
          matches.map((match) => ({
            label: match.name || 'Unknown',
            value: String(match.id),
            entity,
          }))
        )
        .flat()[cursor - 1];

      const property = getPropertyFromEntityName(entity as AppEntity);

      if (!property || !match) {
        return;
      }

      setRelatedEntityFilter(property as keyof AppEntityObject, match);
    }

    handleCursorChange(0);
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    switch (event.key) {
      case 'ArrowDown':
        handleCursorChange((cursor) => {
          if (cursor < suggestionsCount - 1) {
            return cursor + 1;
          }
          return 0;
        });
        break;
      case 'ArrowUp':
        handleCursorChange((cursor) => {
          if (cursor > 0) {
            return cursor - 1;
          }
          return suggestionsCount - 1;
        });
        break;
      case 'Backspace':
        if (searchInput) {
          break;
        }

        if (didConfirmRemoveTag) {
          removeRelatedEntityFilter();
          handleConfirmRemoveTagChange(false);
          return;
        }

        handleConfirmRemoveTagChange(true);
    }
  }

  return (
    <form
      className={c('filter-bar')}
      onSubmit={handleSubmit}
      onKeyDown={handleKeyDown}
      autoComplete="off"
      ref={ref}
    >
      <div className={c('search')}>{children}</div>
    </form>
  );
}

const ForwardRef = React.forwardRef<
  HTMLFormElement,
  AdvancedSearchBarWrapProps
>(AdvancedSearchBarWrap);

export { ForwardRef as AdvancedSearchBarWrap };
