import React from 'react';

import Camera from 'types/camera';

import { UseCamerasFilterParams, useCameras } from 'cameras/hooks/useCameras';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';

import { Select, SelectProps } from 'components/Select/Select';

export type CameraSelectProps<IsMulti extends boolean = false> = SelectProps<
  Camera,
  IsMulti
> & {
  queryFilters?: UseCamerasFilterParams;
};

export function CameraSelect<IsMulti extends boolean = false>({
  placeholder = 'Camera',
  isLoading,
  queryFilters = {},
  ...props
}: CameraSelectProps<IsMulti>) {
  const [filters, setFilters] = React.useState<UseCamerasFilterParams>({});

  const { data, isLoading: isLoadingOptions } = useCameras(
    ['camera-select-cameras'],
    { ...queryFilters, ...filters },
    {
      keepPreviousData: true,
    }
  );

  const handleInputChange = useDebouncedCallback(
    (value: string) => {
      setFilters((previousFilters) => ({
        ...previousFilters,
        camera_names: [value],
      }));
    },
    [],
    { wait: 100 }
  );

  return (
    <Select
      {...props}
      options={data?.data}
      totalElements={data?.total_elements}
      onInputChange={handleInputChange}
      getOptionLabel={({ name }) => name}
      getOptionValue={({ id }) => id}
      isLoading={isLoading || isLoadingOptions}
      placeholder={placeholder}
    />
  );
}
