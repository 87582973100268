import React from 'react';
import classnames from 'classnames/bind';
import { useUncontrolled } from '@mantine/hooks';

import { ChartType } from 'types/dashboard_widget';

import { Icon } from 'components/Icon/Icon';

import { ReactComponent as CounterSVG } from 'images/charts/counter.svg';
import { ReactComponent as LeaderboardSVG } from 'images/charts/leaderboard.svg';
import { ReactComponent as PieSVG } from 'images/charts/pie.svg';
import { ReactComponent as TableSVG } from 'images/charts/table.svg';
import { ReactComponent as TimeSeriesBarGroupedSVG } from 'images/charts/timeseries-bar-grouped.svg';
import { ReactComponent as TimeSeriesBarSVG } from 'images/charts/timeseries-bar.svg';
import { ReactComponent as TimeSeriesBarStackedSVG } from 'images/charts/timeseries-bar-stacked.svg';
import { ReactComponent as TimeSeriesHeatmapSVG } from 'images/charts/timeseries-heatmap.svg';
import { ReactComponent as TimeSeriesLineSVG } from 'images/charts/timeseries-line.svg';

import styles from './ChartSelect.module.scss';

const c = classnames.bind(styles);

type ChartSelectProps = {
  value?: ChartType;
  onChange?: (value: ChartType) => void;
};

export function ChartSelect({ value, onChange }: ChartSelectProps) {
  const [chartType, setChartType] = useUncontrolled({ value, onChange });

  function handleChartSelect({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement> & { target: { value: ChartType } }) {
    if (!checked) {
      return;
    }

    setChartType(value);
  }

  return (
    <div role="radiogroup" className={c('group')}>
      <div>
        <input
          type="radio"
          value="timeseries-bar"
          id="timeseries-bar-radio"
          className={c('input')}
          checked={chartType === 'timeseries-bar'}
          onChange={handleChartSelect}
        />
        <label htmlFor="timeseries-bar-radio" className={c('option')}>
          <strong className={c('name')}>Bar</strong>
          <Icon className={c('icon')} name="check" />
          <TimeSeriesBarSVG className={c('chart')} />
        </label>
      </div>
      <div>
        <input
          type="radio"
          value="timeseries-line"
          id="timeseries-line-radio"
          className={c('input')}
          checked={chartType === 'timeseries-line'}
          onChange={handleChartSelect}
        />
        <label htmlFor="timeseries-line-radio" className={c('option')}>
          <strong className={c('name')}>Line</strong>
          <Icon className={c('icon')} name="check" />
          <TimeSeriesLineSVG className={c('chart')} />
        </label>
      </div>
      <div>
        <input
          type="radio"
          value="timeseries-bar-stacked"
          id="timeseries-bar-stacked-radio"
          className={c('input')}
          checked={chartType === 'timeseries-bar-stacked'}
          onChange={handleChartSelect}
        />
        <label htmlFor="timeseries-bar-stacked-radio" className={c('option')}>
          <strong className={c('name')}>Stacked</strong>
          <Icon className={c('icon')} name="check" />
          <TimeSeriesBarStackedSVG className={c('chart')} />
        </label>
      </div>
      <div>
        <input
          type="radio"
          value="timeseries-bar-grouped"
          id="timeseries-bar-grouped-radio"
          className={c('input')}
          checked={chartType === 'timeseries-bar-grouped'}
          onChange={handleChartSelect}
        />
        <label htmlFor="timeseries-bar-grouped-radio" className={c('option')}>
          <strong className={c('name')}>Grouped</strong>
          <Icon className={c('icon')} name="check" />
          <TimeSeriesBarGroupedSVG className={c('chart')} />
        </label>
      </div>
      <div>
        <input
          type="radio"
          value="timeseries-heatmap"
          id="timeseries-heatmap-radio"
          className={c('input')}
          checked={chartType === 'timeseries-heatmap'}
          onChange={handleChartSelect}
        />
        <label htmlFor="timeseries-heatmap-radio" className={c('option')}>
          <strong className={c('name')}>Heatmap</strong>
          <Icon className={c('icon')} name="check" />
          <TimeSeriesHeatmapSVG className={c('chart')} />
        </label>
      </div>
      <div>
        <input
          type="radio"
          value="leaderboard-bar"
          id="leaderboard-bar-radio"
          className={c('input')}
          checked={chartType === 'leaderboard-bar'}
          onChange={handleChartSelect}
        />
        <label htmlFor="leaderboard-bar-radio" className={c('option')}>
          <strong className={c('name')}>Leaderboard</strong>
          <Icon className={c('icon')} name="check" />
          <LeaderboardSVG className={c('chart')} />
        </label>
      </div>
      <div>
        <input
          type="radio"
          value="leaderboard-table"
          id="leaderboard-table-radio"
          className={c('input')}
          checked={chartType === 'leaderboard-table'}
          onChange={handleChartSelect}
        />
        <label htmlFor="leaderboard-table-radio" className={c('option')}>
          <strong className={c('name')}>Table</strong>
          <Icon className={c('icon')} name="check" />
          <TableSVG className={c('chart')} />
        </label>
      </div>
      <div>
        <input
          type="radio"
          value="pie"
          id="pie-radio"
          className={c('input')}
          checked={chartType === 'pie'}
          onChange={handleChartSelect}
        />
        <label htmlFor="pie-radio" className={c('option')}>
          <strong className={c('name')}>Pie</strong>
          <Icon className={c('icon')} name="check" />
          <PieSVG className={c('chart')} />
        </label>
      </div>
      <div>
        <input
          type="radio"
          value="counter"
          id="counter-radio"
          className={c('input')}
          checked={chartType === 'counter'}
          onChange={handleChartSelect}
        />
        <label htmlFor="counter-radio" className={c('option')}>
          <strong className={c('name')}>Number</strong>
          <Icon className={c('icon')} name="check" />
          <CounterSVG className={c('chart')} />
        </label>
      </div>
    </div>
  );
}
