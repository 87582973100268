import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { DeploymentDetail } from 'deployments/views/DeploymentDetail/DeploymentDetail';
import { SplitView } from 'components/SplitView/SplitView';

export type DetailRouteWithDeploymentRefProps = {
  children: React.ReactNode;
};

export function DetailRouteWithDeploymentRef({
  children,
}: DetailRouteWithDeploymentRefProps) {
  const { search } = useLocation();
  const navigate = useNavigate();

  function closeDetail(isOpen?: boolean) {
    if (!isOpen) {
      navigate({ pathname: '..', search });
    }
  }

  function closeDeploymentDetail(isOpen?: boolean) {
    if (!isOpen) {
      navigate({ pathname: '.', search });
    }
  }

  return (
    <Routes>
      <Route
        path="*"
        element={
          <>
            <SplitView onOpenChange={closeDetail} easeOut open>
              {children}
            </SplitView>
            <Outlet />
          </>
        }
      >
        <Route
          path="ref/:deploymentID"
          element={
            <SplitView
              onOpenChange={closeDeploymentDetail}
              easeOut
              isStacked
              open
            >
              <DeploymentDetail />
            </SplitView>
          }
        />
      </Route>
    </Routes>
  );
}
