import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { FloatInput } from 'components/NumberInput/NumberInput';

import { FIELD_LABEL_WIDTH, Step3FieldValues } from '../Step3';

export function DbScanConfidenceScoreInput() {
  const {
    control,
    formState: { errors },
  } = useFormContext<Step3FieldValues>();

  return (
    <Field
      id="dbscan_min_score"
      label="Confidence score"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      info="Required minimum sum of confidence scores of all neighbors in a cluster."
      error={errors.inference_config?.class_attributes?.dbscan_min_score}
      required
    >
      <Controller
        control={control}
        name="inference_config.class_attributes.*.dbscan_min_score"
        defaultValue={0.1}
        render={({ field: { value, onChange } }) => (
          <FloatInput
            id="dbscan_min_score"
            min={0}
            value={value}
            onChange={onChange}
          />
        )}
        rules={{
          required: 'Please enter a confidence score.',
          min: {
            value: 0,
            message: 'Confidence score must be at least 0.',
          },
        }}
      />
    </Field>
  );
}
