import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { Tag } from 'types/tag';

import { useAPI } from 'hooks/api/useAPI';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';
import { updateQueriesData } from 'services/update_queries_data';

export function useDeleteTag({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<void, APIError, Tag['id']> = {}) {
  const queryClient = useQueryClient();
  const { applicationID, client } = useAPI();

  return useDangerousMutation(
    `Are you sure you want to delete this label?`,
    (tagId) => client.delete(`/apps/${applicationID}/tags/${tagId}`),
    {
      ...options,
      info: 'All sublabels will also be deleted.',
      entity: 'tag',
      action: 'delete',
      async onMutate(tagId) {
        await queryClient.cancelQueries(['tags']);
        await queryClient.cancelQueries(['tag']);

        onMutate?.(tagId);

        return updateQueriesData<Tag>({
          queryClient,
          listQueryKey: ['tags'],
          singleQueryKey: ['tag'],
          ids: [tagId],
          updateData: null,
        });
      },
      onError(error, tagId, context) {
        onError?.(error, tagId, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(...args) {
        onSettled?.(...args);
        queryClient.invalidateQueries(['tags']);
        queryClient.invalidateQueries(['tag']);
      },
    }
  );
}
