import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { deserialize } from 'serializr';

import { Option } from 'types/context_menu';
import Application from 'types/application';

import { useCreateApplication } from 'hooks/api/useApplications';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useOrganizations } from 'hooks/api/useOrganizations';
import { useUsageStatistics } from 'organizations/hooks/useUsageStatistics';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';

import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Input } from 'components/Input/Input';
import { Loader } from 'components/Loader/Loader';
import { Select } from 'components/Select/Select';

import styles from './CreateApplication.module.scss';

export type NewApplicationProps = {
  onFinishCreating?: () => void;
  onClose?: () => void;
};

type NewApplicationFieldValues = {
  organization: any;
  name: string;
};

export function CreateApplication({
  onFinishCreating,
  onClose,
}: NewApplicationProps) {
  const { data: organizations } = useOrganizations();
  const { data: currentOrganization } = useCurrentOrganization();
  const { data: usageStatistics, isLoading: isLoadingUsageStatistics } =
    useUsageStatistics();

  const { isLoading, mutate: createApplication } = useCreateApplication();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const organizationOptions: Option[] = React.useMemo(
    () =>
      organizations?.map(({ id, name }) => ({
        value: id,
        label: name,
      })) ?? [],
    [organizations]
  );

  const {
    control,
    formState: { errors },
    register,
    watch,
    handleSubmit,
  } = useForm<NewApplicationFieldValues>({
    defaultValues: {
      organization:
        organizationOptions.find(
          ({ label }) => label === currentOrganization?.name
        ) || organizationOptions[0],
    },
  });

  const selectedOrganization = watch('organization');

  const { data: currentPlan, isLoading: isLoadingCurrentPlan } = useCurrentPlan(
    selectedOrganization?.value
  );

  function create({ organization, name }: NewApplicationFieldValues) {
    if (!organization || !name) {
      return;
    }

    const application = deserialize(Application, {
      name,
      organization_id: organization.value,
    });

    createApplication(application, {
      onSuccess: async (application) => {
        await queryClient.cancelQueries(['applications']);
        const applications =
          queryClient.getQueryData<Application[]>(['applications']) || [];

        // insert the newly created application manually for speed
        queryClient.setQueryData(
          ['applications'],
          [...applications, application]
        );
        navigate(`/applications/${application.id}`);

        onFinishCreating?.();
      },
    });
  }

  if (
    isLoadingCurrentPlan ||
    isLoadingUsageStatistics ||
    currentPlan === undefined ||
    usageStatistics === undefined
  ) {
    return <Loader text="Loading..." size="small" />;
  }

  const canCreateApplication =
    !currentPlan?.application_limit ||
    usageStatistics.application_count < currentPlan.application_limit;

  return (
    <form className={styles.container} onSubmit={handleSubmit(create)}>
      <section className={styles.body}>
        <Field label="Organization" error={errors?.organization} required>
          <Controller
            name="organization"
            render={({ field: { onChange, value, name } }) => (
              <Select
                id="na_organization"
                name={name}
                options={organizationOptions}
                value={value}
                onChange={onChange}
              />
            )}
            control={control}
            rules={{
              required: 'Please select an organization',
            }}
          />
        </Field>

        {currentPlan?.application_limit && !canCreateApplication ? (
          <FormMessage intent="warning" icon="warning">
            <strong>
              Your plan is limited to {currentPlan.application_limit} workspace
              {currentPlan.application_limit > 1 ? 's' : ''}.
            </strong>
            {selectedOrganization && (
              <>
                <br />
                Please{' '}
                <Link
                  className={'link'}
                  to={`/organizations/${selectedOrganization.value}/plans`}
                >
                  upgrade your organization
                </Link>{' '}
                to add another workspace.
              </>
            )}
          </FormMessage>
        ) : (
          <Field label="Workspace name" error={errors?.name} required>
            <Input
              id="na_name"
              {...register('name', {
                required: 'Please choose a name for your new workspace.',
              })}
            />
          </Field>
        )}

        <div className={styles.actions}>
          <div className="columns halign-end">
            {onClose && (
              <Button onClick={onClose} variant="ghost">
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              loading={isLoading}
              variant="primary"
              disabled={!canCreateApplication}
            >
              Create
            </Button>
          </div>
        </div>
      </section>
    </form>
  );
}
