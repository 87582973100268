import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { NumberInput } from 'components/NumberInput/NumberInput';

import { FIELD_LABEL_WIDTH, Step3FieldValues } from '../Step3';

export function MinBoundingBoxesInput() {
  const {
    control,
    formState: { errors },
  } = useFormContext<Step3FieldValues>();

  return (
    <Field
      id="min_boxes"
      label="# of bounding boxes"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      info="Minimum number of boxes to form a dense region."
      error={errors.inference_config?.class_attributes?.min_boxes}
      required
    >
      <Controller
        control={control}
        name="inference_config.class_attributes.*.min_boxes"
        render={({ field: { value, onChange } }) => (
          <NumberInput
            id="min_boxes"
            min={0}
            value={value}
            onChange={onChange}
          />
        )}
        defaultValue={1}
        rules={{
          required: 'Please enter a minimum number of boxes.',
          min: {
            value: 0,
            message: 'Minimum number of boxes must be at least 0.',
          },
        }}
      />
    </Field>
  );
}
