import React from 'react';
import classnames from 'classnames/bind';
import { Controller, useForm } from 'react-hook-form';

import { FilterInput } from 'graphql/types/filter';

import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';

import {
  LogicalOperator,
  LogicalOperatorSelect,
} from './LogicalOperatorSelect';
import { MetricFilter } from './MetricFilter';
import { useMetricFiltersState } from './context';
import styles from './MetricFiltersInput.module.scss';

const c = classnames.bind(styles);

export function MetricFilterGroup({
  id,
  column,
  operator,
  value,
  and,
  or,
}: FilterInput) {
  const { addFilter, updateLogicalOperator } = useMetricFiltersState();

  const { control, getValues } = useForm<{ logical: LogicalOperator }>({
    defaultValues: { logical: or ? 'OR' : 'AND' },
  });

  function handleAddFilterClick() {
    addFilter(getValues('logical'), id);
  }

  return (
    <div className={c('group-item')}>
      <div className={c('group')}>
        <div className={c('group-item')}>
          <Controller
            name="logical"
            control={control}
            render={({ field: { value, onChange } }) => (
              <LogicalOperatorSelect
                id={`logical-operator-switch-${id}`}
                value={value}
                onChange={(logical) => {
                  updateLogicalOperator(logical as LogicalOperator, id);
                  onChange(logical);
                }}
              />
            )}
          />
        </div>

        <MetricFilter
          key={id}
          id={id}
          column={column}
          operator={operator}
          value={value}
        />

        {and?.map((filter) => (
          <MetricFilter key={filter.id} {...filter} />
        ))}
        {or?.map((filter) => (
          <MetricFilter key={filter.id} {...filter} />
        ))}

        <div className={c('group-item')}>
          <Button
            variant="secondary"
            size="xsmall"
            onClick={handleAddFilterClick}
          >
            <Icon name="plus" size="xsmall" />
            <span>Add filter</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
