import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';

import APIError from 'types/api_error';
import { FileCloudStatus, LumeoFile } from 'types/file';

export type UseUpdateFileParams = {
  id: LumeoFile['id'];
  name?: string;
  cloud_status?: FileCloudStatus;
  description?: string;
};

export function useUpdateFile({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<LumeoFile, APIError, UseUpdateFileParams> = {}) {
  const queryClient = useQueryClient();
  const { applicationID, client } = useAPI();

  return useMutation(
    async ({ id, ...params }) => {
      const { data } = await client.put(`/apps/${applicationID}/files/${id}`, {
        ...params,
      });

      return data;
    },
    {
      ...options,
      async onMutate({ id, ...params }) {
        await queryClient.cancelQueries(['files']);
        await queryClient.cancelQueries(['file']);

        onMutate?.({ id, ...params });

        return updateQueriesData<LumeoFile>({
          queryClient,
          listQueryKey: ['files'],
          singleQueryKey: ['file'],
          ids: [id],
          updateData: (file) => ({ ...file, ...params }),
        });
      },
      onError(error, params, context) {
        onError?.(error, params, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(...args) {
        onSettled?.(...args);

        queryClient.invalidateQueries(['files']);
        queryClient.invalidateQueries(['file']);
      },
    }
  );
}
