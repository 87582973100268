import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import classnames from 'classnames/bind';

import { AppEntityObject } from 'hooks/useEntities';

import { Separator } from 'components/Separator/Separator';
import { Text } from 'components/Text/Text';

import { AdvancedSearchBar } from './components/AdvancedSearchBar/AdvancedSearchBar';
import {
  AdvancedSearchProvider,
  AdvancedSearchProviderProps,
} from './AdvancedSearchProvider';
import { AdvancedFilterOptions } from './AdvancedSearch.types';
import styles from './AdvancedSearch.module.scss';

const c = classnames.bind(styles);

export type AdvancedSearchProps<T> = React.PropsWithChildren<
  Omit<AdvancedSearchProviderProps<T>, 'data'> & {
    className?: string;
    searchLabel?: string;
    data?: T[];
  }
>;

export type AdvancedSearchLocationState = {
  filters: {
    [property: string]: string;
  };
};

/**
 * Wrapper component that receives & filters data,
 * and populates the context.
 *
 * @deprecated
 */
export function AdvancedSearch<T extends AppEntityObject>({
  entity,
  data = [],
  isLoading,
  filterOptions,
  searchLabel,
  className,
  children,
}: AdvancedSearchProps<T>) {
  const [searchResultCount, setSearchResultCount] = React.useState(data.length);
  const [updatedData, setUpdatedData] = React.useState<T[]>(data);

  // Display entity name in plural or singular depending on amount of results shown
  const type =
    searchResultCount === 1 ? entity.substring(0, entity.length - 1) : entity;

  useDeepCompareEffect(() => setUpdatedData(data), [data]);

  return (
    <AdvancedSearchProvider
      data={updatedData}
      isLoading={isLoading}
      entity={entity}
      filterOptions={filterOptions}
      onFilteredDataChange={setSearchResultCount}
    >
      <div className={c('wrap', className)}>
        <div className={c('search-header')}>
          <AdvancedSearchBar label={searchLabel} />
          {Boolean(searchResultCount) && (
            <>
              <Separator orientation="vertical" />
              <Text>
                {searchResultCount} {type}
              </Text>
            </>
          )}
        </div>
        {children}
      </div>
    </AdvancedSearchProvider>
  );
}

export type { AdvancedFilterOptions };
