import React from 'react';
import classnames from 'classnames/bind';
import { DateTime } from 'luxon';
import { useVirtualizer } from '@tanstack/react-virtual';

import { DeploymentLog } from 'deployments/hooks/useMqttDeploymentLogs';

import { IconButton } from 'components/IconButton/IconButton';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';
import { EmptyView } from 'components/EmptyView/EmptyView';

import { LogInspector } from './components/LogInspector';
import styles from './Logs.module.scss';

const c = classnames.bind(styles);

export type LogsProps = {
  logs: DeploymentLog[];
  emptyMessage: string;
};

export function Logs({ logs, emptyMessage }: LogsProps) {
  const previousLogsLength = React.useRef(logs.length);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: logs.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => 40,
  });

  const [shouldAutoScroll, setShouldAutoScroll] = React.useState(true);
  const [selectedLog, setSelectedLog] = React.useState<string>();

  const items = virtualizer.getVirtualItems();

  // Autoscroll
  React.useEffect(() => {
    if (
      !shouldAutoScroll ||
      previousLogsLength.current === items.length ||
      !scrollRef.current
    ) {
      return;
    }

    scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
  }, [shouldAutoScroll, items.length]);

  if (!logs.length) {
    return <EmptyView>{emptyMessage}</EmptyView>;
  }

  return (
    <div className={c('wrap')}>
      <div className={c('controls')}>
        <LightSwitch
          id="auto-scroll"
          onValueChange={setShouldAutoScroll}
          checked={shouldAutoScroll}
        />
        <Text asChild>
          <label htmlFor="auto-scroll">
            <strong>Auto-scroll with output</strong>
          </label>
        </Text>
      </div>

      <div
        ref={scrollRef}
        style={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {items.map((item) => {
            const { ts, msg: log } = logs[item.index];
            const timestamp = DateTime.fromISO(ts);

            return (
              <div
                className={c('row')}
                key={item.key}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${item.size}px`,
                  transform: `translateY(${item.start}px)`,
                }}
              >
                <IconButton
                  icon="eye"
                  label="Inspect log"
                  onClick={() => setSelectedLog(log)}
                />
                <StatusIndicator status="info" size="small" />
                <Tooltip
                  content={timestamp.toLocaleString(DateTime.DATETIME_FULL)}
                  side="bottom"
                >
                  <span className={c('time')}>
                    {timestamp.toLocaleString(DateTime.TIME_WITH_SECONDS)}
                  </span>
                </Tooltip>
                <span className={c('log')}>{log}</span>
              </div>
            );
          })}
        </div>
      </div>

      <LogInspector
        log={selectedLog}
        open={Boolean(selectedLog)}
        onOpenChange={() => setSelectedLog(undefined)}
      />
    </div>
  );
}
