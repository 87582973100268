import {
  createModelSchema,
  deserialize,
  identifier,
  object,
  primitive,
  serialize,
} from 'serializr';

import { generateId } from 'services/string';

import DashboardConfiguration from './dashboard_configuration';
import { DashboardWidget } from './dashboard_widget';

export const DASHBOARD_COLUMNS = 12;
export default class Dashboard {
  id: string;

  application_id: string;

  name: string;

  configuration: DashboardConfiguration;

  /** ISO date string */
  created_at: string;

  /** ISO date string */
  updated_at: string;

  constructor(
    id: string,
    applicationID: string,
    name: string,
    configuration: DashboardConfiguration
  ) {
    this.id = id;
    this.application_id = applicationID;
    this.name = name;
    this.configuration = configuration;

    // usually the dates come from the deserialized API response,
    // so these values are just to satisfy the model
    this.created_at = new Date().toISOString();
    this.updated_at = new Date().toISOString();
  }

  copy() {
    return deserialize(Dashboard, serialize(this)) as unknown as Dashboard;
  }

  duplicate() {
    const duplicatedDashboard = this.copy();
    duplicatedDashboard.id = generateId();
    duplicatedDashboard.name = `Copy of ${duplicatedDashboard.name}`;
    return duplicatedDashboard;
  }

  get widgets() {
    return this.configuration.widgets;
  }

  getWidget(id: string) {
    return this.widgets.find((widget) => widget.id === id);
  }

  /** Update or insert widget */
  upsertWidget(widget: DashboardWidget) {
    const updatedDashboard = this.copy();
    const { widgets } = updatedDashboard;

    const index = widgets.findIndex(({ id }) => id === widget.id);

    if (index !== -1) {
      // Update existing widget
      widgets[index] = widget;
    } else {
      // Add new widget
      widgets.push(widget);
    }

    updatedDashboard.configuration = new DashboardConfiguration(widgets);

    return updatedDashboard;
  }

  getNewWidgetLayout(w: number, h: number) {
    const layout = { x: 0, y: 0, w, h };

    // Find the maximum x and y values among the existing widgets
    const maxX = this.widgets.reduce(
      (max, widget) => Math.max(max, widget.layout.x + widget.layout.w),
      0
    );
    const maxY = this.widgets.reduce(
      (max, widget) => Math.max(max, widget.layout.y + widget.layout.h),
      0
    );

    // If maxX + widget width exceeds the column count, put the widget at the start of a new row
    if (maxX + w > DASHBOARD_COLUMNS) {
      layout.x = 0;
      layout.y = maxY;
    } else {
      layout.x = maxX;
      layout.y = Math.floor(maxY / layout.h) * layout.h;
    }

    return layout;
  }
}

createModelSchema(Dashboard, {
  id: identifier(),
  application_id: primitive(),
  name: primitive(),
  created_at: primitive(),
  updated_at: primitive(),
  configuration: object(DashboardConfiguration),
});
