import classNames from 'classnames/bind';

import Gateway from 'types/gateway';

import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

import { Drawer, DrawerProps } from 'components/Drawer/Drawer';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Text } from 'components/Text/Text';

import { LinkFilesForm } from './Form';
import { Info } from './Info';
import styles from './LinkFiles.module.scss';

const c = classNames.bind(styles);

export type LinkFilesDialogProps = Pick<DrawerProps, 'open' | 'onClose'> & {
  gateway?: Gateway;
  onSuccess?: () => void;
};

export function LinkFilesDialog({
  gateway,
  onSuccess,
  ...props
}: LinkFilesDialogProps) {
  const { data: organization } = useCurrentOrganization();

  return (
    <Drawer
      {...props}
      title="Link externally hosted files"
      classNames={{
        content: c('wrap'),
      }}
    >
      <div>
        <Text type="paragraph">
          Use files hosted on your webserver or cloud storage as a video source.
        </Text>
        <Text type="paragraph">
          When using files as a deployment source, they are processed as fast as
          possible and are not restarted when processing is complete.
        </Text>

        {organization?.is_suspended && (
          <FormMessage intent="warning" icon="warning">
            <Text>
              <em>{organization?.name} is suspended.</em>
              <br />
              Please contact support to link externally hosted files.
            </Text>
          </FormMessage>
        )}
      </div>

      <Info />

      <LinkFilesForm gateway={gateway} onSuccess={onSuccess} />
    </Drawer>
  );
}
