import { createModelSchema, primitive } from 'serializr';

export default class OrganizationUsageStatistics {
  file_processing_minutes: number;

  cloud_storage_bytes: number;

  gateway_count: number;

  cloud_gateway_count: number;

  application_count: number;

  realtime_stream_count: number;

  deployment_count: number;

  updated_at: string;

  constructor(
    applicationCount: number,
    gatewayCount: number,
    cloudGatewayCount: number,
    deploymentCount: number,
    cloudStorageBytes: number,
    realtimeStreamCount: number,
    fileProcessingMinutes: number,
    updatedAt?: string
  ) {
    this.application_count = applicationCount;
    this.gateway_count = gatewayCount;
    this.cloud_gateway_count = cloudGatewayCount;
    this.deployment_count = deploymentCount;
    this.cloud_storage_bytes = cloudStorageBytes;
    this.realtime_stream_count = realtimeStreamCount;
    this.file_processing_minutes = fileProcessingMinutes;
    this.updated_at = updatedAt ?? new Date().toISOString();
  }
}

createModelSchema(OrganizationUsageStatistics, {
  file_processing_minutes: primitive(),
  cloud_storage_bytes: primitive(),
  gateway_count: primitive(),
  cloud_gateway_count: primitive(),
  application_count: primitive(),
  realtime_stream_count: primitive(),
  deployment_count: primitive(),
  updated_at: primitive(),
});
