import React from 'react';
import { useToggle } from '@mantine/hooks';

import { useDashboards } from 'dashboards/hooks/useDashboards';

import { Button } from 'components/Button/Button';
import { CreateDashboardDialog } from 'dashboards/components/CreateDashboardDialog/CreateDashboardDialog';
import { Icon } from 'components/Icon/Icon';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Text } from 'components/Text/Text';

import { DashboardLink, DashboardLinkSkeleton } from './DashboardLink';

export function MonitorDashboardLinks() {
  const { data: dashboards, isLoading, error } = useDashboards();

  const [isCreatingDashboard, toggleCreateDashboard] = useToggle();

  if (isLoading) {
    return (
      <div>
        <SkeletonList min={3} max={6} component={<DashboardLinkSkeleton />} />
      </div>
    );
  }

  if (error) {
    return (
      <Text intent="danger" align="center">
        <Icon name="warning" /> <br />
        <span>
          {error.message ??
            'Unable to load dashboards. Please try again later.'}
        </span>
      </Text>
    );
  }

  if (!dashboards?.total_elements || !dashboards.data.length) {
    return (
      <>
        <Text>No dashboards yet.</Text>

        <Button size="small" onClick={() => toggleCreateDashboard()}>
          Create dashboard
        </Button>
        <CreateDashboardDialog
          open={isCreatingDashboard}
          onOpenChange={toggleCreateDashboard}
        />
      </>
    );
  }

  return (
    <ul>
      {dashboards.data.map((dashboard) => (
        <DashboardLink dashboard={dashboard} key={dashboard.id} />
      ))}
    </ul>
  );
}
