import { createModelSchema, list, primitive } from 'serializr';

export type RTSPConfigProtocol = 'tcp' | 'udp' | 'udp-mcast';

export class RTSPConfig {
  protocols?: RTSPConfigProtocol[];
}

createModelSchema(RTSPConfig, {
  protocols: list(primitive()),
});
