export const GATEWAY_STATES = [
  'online',
  'offline',
  'starting',
  'stopping',
  'pending',
] as const;

export type GatewayState = (typeof GATEWAY_STATES)[number];

export function isGatewayState(state: string): state is GatewayState {
  return ([...GATEWAY_STATES] as string[]).includes(state);
}

export default class Gateway {
  id: string;
  name: string;
  status: GatewayState;
  application_id: string;
  configuration_id?: string;
  ip_ext?: string;
  ip_local?: string;
  mac_address?: string;
  model?: string;
  version?: string;
  is_managed: boolean;
  /** ISO date string */
  stops_automatically_at: string | null;
  max_running_deployments: number;
  tags: string[];
  created_at: string;
  updated_at: string;

  constructor({
    id,
    name,
    status,
    application_id,
    configuration_id,
    ip_ext,
    ip_local,
    mac_address,
    model,
    version,
    is_managed,
    stops_automatically_at,
    max_running_deployments,
    tags,
    created_at = new Date().toISOString(),
    updated_at = new Date().toISOString(),
  }: Gateway) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.application_id = application_id;
    this.configuration_id = configuration_id;
    this.ip_ext = ip_ext;
    this.ip_local = ip_local;
    this.mac_address = mac_address;
    this.model = model;
    this.version = version;
    this.is_managed = is_managed;
    this.stops_automatically_at = stops_automatically_at;
    this.max_running_deployments = max_running_deployments;
    this.tags = tags;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }

  copy() {
    return new Gateway(this);
  }

  isOnline() {
    return this.status === 'online';
  }

  isOffline() {
    return this.status === 'offline';
  }

  get isTrial() {
    return this.model === 'Lumeo Cloud Trial';
  }

  withName(name: string) {
    const newGateway = this.copy();
    newGateway.name = name;
    return newGateway;
  }
}
