import React from 'react';
import { Slot } from '@radix-ui/react-slot';

import classNames from 'classnames/bind';

import { Icon, IconType } from 'components/Icon/Icon';

import styles from './Pill.module.scss';

const c = classNames.bind(styles);

export type PillIntent =
  | 'info'
  | 'warning'
  | 'pending'
  | 'danger'
  | 'success'
  | 'default';

export type PillProps = React.HTMLAttributes<HTMLSpanElement> &
  React.PropsWithChildren<{
    /** @default 'default' */
    intent?: PillIntent;
    /** @default 'secondary' */
    variant?: 'primary' | 'secondary';
    icon?: IconType;
    className?: string;
    iconClassName?: string;
    size?: 'small';
    asChild?: boolean;
    interactive?: boolean;
  }>;

export function Pill({
  icon,
  intent = 'default',
  variant = 'secondary',
  children,
  className,
  iconClassName,
  size,
  asChild,
  interactive,
  ...rest
}: PillProps) {
  const Element = asChild ? Slot : 'span';

  return (
    <Element
      {...rest}
      className={c('pill', className, size, {
        [`theme ${intent}`]: intent,
        [variant]: variant === 'primary',
        interactive,
      })}
    >
      {icon && <Icon name={icon} className={c('pill-icon', iconClassName)} />}
      {children && <span className={c('pill-content')}>{children}</span>}
    </Element>
  );
}
