import React from 'react';
import classnames from 'classnames/bind';
import { Component, NodeEditor } from 'rete';

import { isFeatureEnabled } from 'services/feature_flags';
import { nodeCategories } from 'pipelines/services/rete/nodes/categories';
import { useIsLumeoAdmin } from 'hooks/useAuth';

import { Input } from 'components/Input/Input';
import { Kbd } from 'components/Kbd/Kbd';

import { PipelineEditorDocksItem } from './Item';
import styles from './EditorDocks.module.scss';

const c = classnames.bind(styles);

export type PipelineEditorDocksProps = {
  editor: NodeEditor | undefined;
};

export default function PipelineEditorDocks({
  editor,
}: PipelineEditorDocksProps) {
  const isAdmin = useIsLumeoAdmin();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const container = React.useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleReset = React.useCallback(() => {
    inputRef.current?.focus();
    container.current?.removeAttribute('data-disabled');
    setSearchTerm('');
  }, []);

  React.useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'f' && event.metaKey) {
        event.preventDefault();
        inputRef.current?.focus();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    if (!editor) {
      return;
    }

    function handleDragOver(event: DragEvent) {
      event.preventDefault();
    }

    async function handleDrop(event: DragEvent) {
      if (!event.dataTransfer || !editor) {
        return;
      }

      const name = event.dataTransfer.getData('componentName');
      const component = editor.components.get(name);

      if (!component) {
        throw new Error(`Component ${name} not found.`);
      }

      // force update the mouse position
      editor.view.area.pointermove(event as any as PointerEvent);

      const node = await (component as Component).createNode({});
      const { x, y } = editor.view.area.mouse;
      node.position = [x, y];
      editor.addNode(node);
    }

    editor.view.container.addEventListener('dragover', handleDragOver);
    editor.view.container.addEventListener('drop', handleDrop);

    return () => {
      editor.view.container.removeEventListener('dragover', handleDragOver);
      editor.view.container.removeEventListener('drop', handleDrop);
    };
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <>
      <div className={c('search')}>
        <label htmlFor="nodes-library-search" className="sr-only">
          Search node library
        </label>
        <Input
          className={c('search-field')}
          id="nodes-library-search"
          icon="search"
          placeholder="Search node library"
          value={searchTerm}
          button={<Kbd metaKey>F</Kbd>}
          onValueChange={setSearchTerm}
          ref={inputRef}
        />
      </div>

      <div className={c('list')} ref={container}>
        {Object.values(nodeCategories).map((category, index) => {
          // Filter out unreleased components
          category.components = category.components.filter(
            ({ release }) => !release || isFeatureEnabled(release, isAdmin)
          );

          return (
            <PipelineEditorDocksItem
              editor={editor}
              category={category}
              index={index}
              isEmpty={category.components.length === 0}
              searchTerm={searchTerm}
              onReset={handleReset}
              key={category.key}
            />
          );
        })}
      </div>
    </>
  );
}
