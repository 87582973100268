import { ROICanvasType } from 'pipelines/components/ROICanvas/types';
import { VideoSourceROIInputValue } from '../VideoSourceROI';

export function getLabelsFromInput(
  labels: string[] | string | null | undefined
): string[] {
  if (!labels) {
    return [];
  }

  if (typeof labels === 'string') {
    return labels.split(';');
  }

  if (Array.isArray(labels)) {
    return labels;
  }

  return [];
}

export function getCoordinatesFromInput(
  coordinates: (readonly [number, number])[][] | string | null | undefined,
  type?: ROICanvasType
): number[][] {
  if (!coordinates) {
    return [];
  }

  let coords: number[][] = [];

  if (Array.isArray(coordinates)) {
    coords = coordinates.map((shape) => {
      if (shape.every((value) => Array.isArray(value))) {
        return shape.flat(2);
      }
      return shape.flat();
    });
  } else if (typeof coordinates === 'string') {
    coords = coordinates
      .split(';')
      .map((coordinates) => coordinates.split(',').map(Number));
  }

  return coords.map((shape) => {
    const [ax, ay] = shape;
    const [bx, by] = shape.slice(-2);

    // Omit duplicate start point
    if (type === 'polygon' && ax === bx && ay === by) {
      return shape.slice(0, -2);
    }

    return shape;
  });
}

const EMPTY_SHAPES = {};

export function getROIShapesFromInput(
  input: VideoSourceROIInputValue | undefined,
  type?: ROICanvasType
): Record<string, number[]> {
  if (!input) {
    return EMPTY_SHAPES;
  }

  const labels = getLabelsFromInput(input.labels);
  const coordinates = getCoordinatesFromInput(input.coordinates, type);

  return labels.reduce<Record<string, number[]>>((defaultValue, label, i) => {
    defaultValue[label] = coordinates[i];

    return defaultValue;
  }, {});
}
