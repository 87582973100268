import { CellProps } from 'react-table';

import Camera from 'types/camera';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { IconButton } from 'components/IconButton/IconButton';

export type CamerasTableActionsCellProps = CellProps<Camera> & {
  onRenameClick: (cameraID: Camera['id']) => void;
  onDeleteClick: (cameraID: Camera['id']) => void;
};

export function CamerasTableActionsCell({
  row,
  onRenameClick,
  onDeleteClick,
}: CamerasTableActionsCellProps) {
  function handleRenameClick() {
    onRenameClick(row.original.id);
  }

  function handleDeleteClick() {
    onDeleteClick(row.original.id);
  }

  return (
    <div className="columns halign-end">
      <ContextMenu
        trigger={
          <IconButton
            icon="more-vertical"
            label="Reveal more actions"
            variant="ghost"
          />
        }
      >
        <ContextMenuAction icon="text" onClick={handleRenameClick}>
          Rename
        </ContextMenuAction>
        <ContextMenuAction
          icon="delete"
          onClick={handleDeleteClick}
          intent="danger"
        >
          Delete
        </ContextMenuAction>
      </ContextMenu>
    </div>
  );
}
