import React from 'react';
import classnames from 'classnames/bind';
import { useDisclosure } from '@mantine/hooks';

import Account from 'types/account';
import OrganizationInvitation from 'types/invitation';

import { useMembers, UseMembersFilterParams } from 'settings/hooks/useMembers';
import {
  useOrganizationInvitations,
  UseOrganizationInvitationsParams,
} from 'settings/hooks/useOrganizationInvitations';

import * as PageLayout from 'components/PageLayout';
import { Button } from 'components/Button/Button';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { Tabs, TabsButton, TabsContent, TabsList } from 'components/Tabs/Tabs';

import { InviteMemberModal } from './components/InviteMemberModal/InviteMemberModal';
import { MembersTable } from './components/Table/MembersTable/MembersTable';
import { OrganizationInvitationsTable } from './components/Table/OrganizationInvitationsTable/OrganizationInvitationsTable';
import styles from './Members.module.scss';

const c = classnames.bind(styles);

export function Members() {
  const [membersParams, setMembersParams] = React.useState<
    UseMembersFilterParams & { page: number }
  >({ page: 1 });

  const [organizationInvitationsParams, setOrganizationInvitationsParams] =
    React.useState<UseOrganizationInvitationsParams & { page: number }>({
      page: 1,
    });

  const membersQuery = useMembers(
    ['members'],
    { limit: 10, ...membersParams },
    { keepPreviousData: true }
  );

  const organizationInvitationsQuery = useOrganizationInvitations(
    ['organization-invitations'],
    { limit: 10, statuses: ['pending'], ...organizationInvitationsParams },
    { keepPreviousData: true }
  );

  const [membersSearchParams, setMembersSearchParams] = React.useState<
    UseMembersFilterParams & { page: number }
  >({ page: 1 });

  const [
    organizationInvitationsSearchParams,
    setOrganizationInvitationsSearchParams,
  ] = React.useState<UseOrganizationInvitationsParams & { page: number }>({
    page: 1,
  });

  const membersSearchQuery = useMembers(
    ['members-suggestions'],
    { limit: 10, ...membersSearchParams },
    { enabled: 'emails' in membersSearchParams, keepPreviousData: false }
  );

  const organizationInvitationsSearchQuery = useOrganizationInvitations(
    ['organization-invitations-suggestions'],
    {
      limit: 10,
      statuses: ['pending'],
      ...organizationInvitationsSearchParams,
    },
    {
      enabled: 'emails' in organizationInvitationsSearchParams,
      keepPreviousData: false,
    }
  );

  const [tab, setTab] = React.useState('active');
  const [isInvitingMember, { open, close }] = useDisclosure();

  function handleMembersSearchInput(value?: string) {
    if (!value) {
      setMembersParams({ page: 1 });
      setMembersSearchParams({ page: 1 });
      return;
    }

    setMembersSearchParams({
      page: 1,
      emails: [value],
    });
  }

  function handleOrganizationInvitationsSearchInput(value?: string) {
    if (!value) {
      setOrganizationInvitationsParams({ page: 1 });
      setOrganizationInvitationsSearchParams({ page: 1 });
      return;
    }

    setOrganizationInvitationsSearchParams({
      page: 1,
      emails: [value],
    });
  }

  function handleMembersFiltersChange() {
    setMembersParams({ ...membersSearchParams, page: 1 });
  }

  function handleOrganizationInvitationsFiltersChange() {
    setOrganizationInvitationsParams({
      ...organizationInvitationsSearchParams,
      page: 1,
    });
  }

  function handleMembersPageChange(page: number) {
    setMembersParams((previous) => ({ ...previous, page }));
  }

  function handleOrganizationInvitationsPageChange(page: number) {
    setOrganizationInvitationsParams((previous) => ({ ...previous, page }));
  }

  return (
    <PageLayout.Root size="medium">
      <PageLayout.Container>
        <div className={c('wrap')}>
          <div className={c('header')}>
            <div>
              <Heading level="2">Organization members</Heading>
              <ExternalLink href="https://docs.lumeo.com/docs/roles">
                Member and roles docs
              </ExternalLink>
            </div>

            <Button variant="secondary" size="small" onClick={open}>
              <Icon name="account" size="small" />
              <span>Invite new member</span>
            </Button>
          </div>

          <Tabs value={tab} onValueChange={setTab}>
            <TabsList>
              <TabsButton value="active">Active</TabsButton>
              <TabsButton value="pending">Pending invitations</TabsButton>
            </TabsList>

            <TabsContent value="active">
              <QuerySearch<Account>
                entityName="member"
                searchLabel="Search by email..."
                searchResultField="email"
                className={c('search')}
                searchQueryResult={membersSearchQuery}
                onApplyFilters={handleMembersFiltersChange}
                onValueChange={handleMembersSearchInput}
                totalElements={membersQuery.data?.total_elements}
              />
              <MembersTable
                pageSize={10}
                page={membersParams.page}
                onPageChange={handleMembersPageChange}
                queryResult={membersQuery}
              />
            </TabsContent>
            <TabsContent value="pending">
              <QuerySearch<OrganizationInvitation>
                entityName="invite"
                searchLabel="Search by email..."
                searchResultField="email"
                className={c('search')}
                searchQueryResult={organizationInvitationsSearchQuery}
                onApplyFilters={handleOrganizationInvitationsFiltersChange}
                onValueChange={handleOrganizationInvitationsSearchInput}
                totalElements={
                  organizationInvitationsQuery.data?.total_elements
                }
              />
              <OrganizationInvitationsTable
                pageSize={10}
                page={organizationInvitationsParams.page}
                onPageChange={handleOrganizationInvitationsPageChange}
                queryResult={organizationInvitationsQuery}
              />
            </TabsContent>
          </Tabs>
        </div>

        <InviteMemberModal
          open={isInvitingMember}
          onClose={close}
          onSuccess={() => setTab('pending')}
        />
      </PageLayout.Container>
    </PageLayout.Root>
  );
}
