import { createModelSchema, primitive } from 'serializr';

export default class LumeoJWT {
  token: string;

  constructor(token: string) {
    this.token = token;
  }
}

createModelSchema(LumeoJWT, {
  token: primitive(),
});

export type GatewayJwtPayload = {
  iss: string;
  exp: number;
  nbf: number;
  type: 'gateway_provisioning';
  application_id: string;
  gateway_type: string | null;
  gateway_id: string;
};

export type MqttJwtPayload = {
  iss: string;
  exp: number;
  nbf: number;
  type: 'mqtt';
  topics: string[];
};
