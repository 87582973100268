export function getDefaultLabel(
  shapes: Record<string, number[]>,
  type = 'shape'
) {
  let base = type;

  if (base === 'multiline') {
    base = 'line';
  }

  const [highestNumber] = Object.keys(shapes)
    .filter((label) => label.toUpperCase().includes(base.toUpperCase()))
    // replace all leading non-digits with nothing
    .map((label) => Number(label.replace(/^\D+/g, '')))
    .filter((num) => !Number.isNaN(num))
    .sort((a, b) => b - a);

  return `${base}${(highestNumber || 0) + 1}`;
}
