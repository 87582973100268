import React from 'react';
import classnames from 'classnames/bind';
import { BrowserRouter } from 'react-router-dom';
import { Node, NodeEditor as ReteNodeEditor } from 'rete';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { ZoomSource as ReteZoomSource } from 'rete/types/view/area';

import { ActivePipelineContext } from 'pipelines/context/active_pipeline';
import { APIProvider } from 'hooks/api/useAPI';
import {
  AuthenticationContext,
  useAuthentication,
} from 'hooks/api/useAuthentication';
import { injectRete } from 'pipelines/services/inject_rete';
import { useMarketplaceModels, useModels } from 'hooks/api/useModels';
import { useModal } from 'hooks/useModal';
import { useNodeIDs } from 'pipelines/hooks/useNodeIDs';
import { usePipelineComponents } from 'pipelines/hooks/usePipelineComponents';
import { usePipelineDetailState } from 'pipelines/context/pipeline_detail_view';

import { Button } from 'components/Button/Button';
import { IconButton } from 'components/IconButton/IconButton';
import { Icon } from 'components/Icon/Icon';
import { EditorNode } from 'pipelines/components/EditorNode/EditorNode';
import { NodePropertiesEditor } from 'pipelines/components/NodePropertiesEditor/NodePropertiesEditor';
import { Text } from 'components/Text/Text';

import PipelineEditorDocks from 'pipelines/components/EditorDocks/EditorDocks';
import { PipelineActions } from 'pipelines/components/Actions/Actions';
import { PipelineDetailHeader } from 'pipelines/views/PipelineDetail/Header/Header';
import styles from './Editor.module.scss';

const c = classnames.bind(styles);

export const ZOOM_MAX = 1.5;
export const ZOOM_MIN = 0.5;

const EditorNodeCmp: any = EditorNode;

export function PipelineEditor() {
  const {
    pipeline,
    transientPipeline,
    isLocked,
    isLoadingLock,
    initializeEditor,
  } = usePipelineDetailState();
  const getNodeID = useNodeIDs(transientPipeline.current);
  const authContext = useAuthentication();

  const queryClient = useQueryClient();
  const { open } = useModal();

  const isInitialized = React.useRef(false);

  const rete = React.useRef<HTMLDivElement>(null);
  const editor = React.useRef<ReteNodeEditor>();

  const zoom = React.useRef<number>(1);
  const [zoomLevel, setZoomLevel] = React.useState(zoom.current);

  const [propEditorNode, setPropEditorNode] = React.useState<any>();

  const [connectionError, setConnectionError] = React.useState<Error>();
  const notificationTimeout = React.useRef<number>();

  const { isLoading: isLoadingModels } = useModels();
  const { isLoading: isLoadingMarketplaceModels } = useMarketplaceModels();
  const [components, { isLoading: isLoadingComponents }] =
    usePipelineComponents();

  const handleDeleteNode = React.useCallback(
    (node: Node) => {
      if (!node || !editor.current) {
        return;
      }

      open('confirm-delete', {
        title: `Are you sure you want to delete ${node.name} node?`,
        onConfirm: () => {
          if (!editor.current) {
            return;
          }

          editor.current.removeNode(node);
          setPropEditorNode(null);
        },
      });
    },
    [open]
  );

  React.useEffect(() => {
    if (!rete.current || !components) {
      return;
    }

    if (!isInitialized.current) {
      function NodeComponent(props: any) {
        return (
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <AuthenticationContext.Provider value={authContext}>
                <APIProvider>
                  <TooltipProvider>
                    <ActivePipelineContext.Provider value={pipeline}>
                      <EditorNodeCmp
                        {...props}
                        editor={editor.current}
                        onEdit={setPropEditorNode}
                        onDelete={handleDeleteNode}
                      />
                    </ActivePipelineContext.Provider>
                  </TooltipProvider>
                </APIProvider>
              </AuthenticationContext.Provider>
            </QueryClientProvider>
          </BrowserRouter>
        );
      }

      editor.current = injectRete(rete.current, {
        component: NodeComponent,
        components,
        getNodeID,
        readonly: isLocked,
      });
      initializeEditor(editor.current);
      initConnectionErrorNotification(editor.current);

      editor.current.on('zoom', (event) => {
        // Reduce zoom scroll wheel sensitivity
        if (event.source === 'wheel') {
          // eslint-disable-next-line id-length
          const { k } = event.transform;
          if (event.zoom > k) {
            event.zoom = k + 0.05;
          } else if (event.zoom < k) {
            event.zoom = k - 0.05;
          }
          event.zoom = +event.zoom.toFixed(2);
        }

        zoom.current = event.zoom;
        setZoomLevel(event.zoom);

        // disable zoom by double click
        return event.source !== 'dblclick';
      });

      isInitialized.current = true;
    }
  }, [
    authContext,
    components,
    initializeEditor,
    getNodeID,
    queryClient,
    pipeline,
    isLocked,
    handleDeleteNode,
  ]);

  React.useEffect(() => {
    if (connectionError) {
      notificationTimeout.current = window.setTimeout(() => {
        setConnectionError(undefined);
      }, 10000);
    }

    return () => {
      clearTimeout(notificationTimeout.current);
    };
  }, [connectionError]);

  function initConnectionErrorNotification(editor: ReteNodeEditor) {
    editor.on('warn', (error) => {
      const isReadonly = editor.trigger('readonly');

      if (!isReadonly) {
        if (error && typeof error !== 'string') {
          if (error.message === 'Sockets not compatible') {
            error.message =
              'Target input format is incompatible with origin output format.';
          }
          setConnectionError(error);
        }
      }
    });
  }

  function zoomAt(factorK: number) {
    if (!editor.current) {
      return;
    }

    const { area, container } = editor.current.view;

    const rect = area.el.getBoundingClientRect();
    const ox = (rect.left - container.clientWidth / 2) * factorK;
    const oy = (rect.top - container.clientHeight / 2) * factorK;

    let newZoom = area.transform.k + factorK;

    if (newZoom > ZOOM_MAX) {
      newZoom = ZOOM_MAX;
    } else if (newZoom < ZOOM_MIN) {
      newZoom = ZOOM_MIN;
    }

    zoom.current = newZoom;
    // send as custom zoom source 'button' to avoid source clashes
    area.zoom(zoom.current, ox, oy, 'button' as unknown as ReteZoomSource);
    area.update();
    setZoomLevel(zoom.current);
  }

  function zoomIn() {
    zoomAt(0.25);
  }
  function zoomOut() {
    zoomAt(-0.25);
  }

  function resetZoom() {
    const reset = +((zoom.current - 1) * -1).toFixed(2);
    zoomAt(reset);
  }

  const isLoading =
    isLoadingComponents ||
    isLoadingModels ||
    isLoadingMarketplaceModels ||
    isLoadingLock;

  function handleNodePropertiesEditorClose() {
    setPropEditorNode(null);
  }

  return (
    <>
      <div className={c('sidebar')}>
        <header className={c('sidebar-header')}>
          <PipelineDetailHeader />
          <PipelineActions pipeline={pipeline} />
        </header>

        <section className={c('sidebar-docks')}>
          <PipelineEditorDocks editor={editor.current} />
        </section>
      </div>

      <div className={c('editor', { readonly: isLocked })}>
        {isLoading ? (
          <div
            className={c('editor-loader', 'loading-spinner-wrap is-fullscreen')}
          >
            <div className="loading-spinner"></div>
            <Text className="loading-spinner-message">
              Initializing pipeline editor...
            </Text>
          </div>
        ) : (
          <section className={c('rete')} ref={rete} />
        )}

        <section className={c('controls')}>
          <div className="columns halign-end spaced-small">
            <IconButton
              className={c('controls-button')}
              icon="minus"
              label="Zoom out"
              variant="tertiary"
              onClick={zoomOut}
            />
            <Button
              className={c('controls-button')}
              variant="tertiary"
              aria-label="Reset zoom to 100%"
              onClick={resetZoom}
              size="small"
            >
              <Icon name="rotate" />
              <span>{Math.round(zoomLevel * 100)}%</span>
            </Button>
            <IconButton
              className={c('controls-button')}
              icon="plus"
              label="Zoom in"
              variant="tertiary"
              onClick={zoomIn}
            />
          </div>
        </section>

        <ActivePipelineContext.Provider value={pipeline}>
          <NodePropertiesEditor
            editor={editor.current}
            node={propEditorNode}
            onDeleteNode={handleDeleteNode}
            onClose={handleNodePropertiesEditorClose}
          />
        </ActivePipelineContext.Provider>

        {connectionError && (
          <div className={c('notification', 'inverted')}>
            <Icon className={c('notification-icon')} name="warning" />
            <p>{connectionError.message}</p>
            <button
              className={c('notification-close')}
              onClick={() => setConnectionError(undefined)}
              aria-label="Close"
            >
              <Icon name="cancel" />
            </button>
          </div>
        )}
      </div>
    </>
  );
}
