import Rete, { Node } from 'rete';

import Component from 'pipelines/services/rete/nodes/component';
import { MultiplexedVideo, RawVideo } from 'pipelines/services/rete/sockets';
import ReteSocketControl from 'pipelines/services/rete/controls/socket_control';

export default class DemultiplexVideo extends Component {
  static key = 'Demultiplex Video';
  static icon = 'split' as const;
  static exportType = 'demultiplex';
  static category = 'transformations' as const;
  static input = {
    type: 'multiplexed',
  };
  static output = {
    type: 'raw',
  };
  static outputParams = [RawVideo, false] as const;
  static dynamicSocketKeys = {
    output: 'num_streams',
  };

  static description =
    'Separate a multiplexed stream into its constituent streams';

  constructor() {
    super(DemultiplexVideo.key);
  }

  async builder(node: Node) {
    node
      .addControl(
        new ReteSocketControl(this.editor!, 'num_streams', {
          label: 'Number of output streams',
          initial: 2,
        })
      )
      .addInput(new Rete.Input('input', 'Input', MultiplexedVideo, false));
    // outputs added by DynamicSockets plugin
  }

  worker() {}
}
