import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import {
  FloatInput,
  NumberInputProps,
} from 'components/NumberInput/NumberInput';

export type ReteFloatInputParams = ReteInputParams &
  Pick<NumberInputProps, 'min' | 'max' | 'step' | 'decimalScale'>;

export default class ReteFloatInput extends ReteInput<
  ReteInputProps & NumberInputProps
> {
  constructor(
    editor: NodeEditor,
    key: string,
    { min, max, step, decimalScale, ...params }: ReteFloatInputParams
  ) {
    super(editor, key, { ...params, type: 'number' });

    this.props.min = min;
    this.props.max = max;
    this.props.step = step;
    this.props.decimalScale = decimalScale;
  }

  get component() {
    return ReteFloatInputComponent;
  }
}

function ReteFloatInputComponent(props: NumberInputProps) {
  return <FloatInput {...props} />;
}
