import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import Gateway from 'types/gateway';
import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';
import {
  updateQueriesData,
  UpdateQueriesDataContext,
} from 'services/update_queries_data';

export function useUpdateGateway({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<
  Gateway,
  APIError,
  Gateway,
  UpdateQueriesDataContext<Gateway>
> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation(api.gateways.update, {
    ...options,
    async onMutate(updateData) {
      await queryClient.cancelQueries(['gateways']);
      onMutate?.(updateData);

      return updateQueriesData({
        queryClient,
        listQueryKey: ['gateways'],
        singleQueryKey: ['gateway'],
        ids: [updateData.id],
        updateData,
      });
    },
    onError(error, updatedStream, context) {
      onError?.(error, updatedStream, context);

      context?.previousLists?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSettled(...args) {
      onSettled?.(...args);
      queryClient.invalidateQueries(['gateways']);
    },
  });
}
