import { DisplayerProps } from '../controls/input';

export const PROFILE_OPTIONS = [
  { label: 'Default', value: 'default' },
  { label: 'Stationary', value: 'stationary' },
  {
    label: 'Custom',
    value: 'custom',
    disabled: (keyrefValue: string | object) => {
      let selectedTracker = keyrefValue;

      if (typeof selectedTracker !== 'string') {
        selectedTracker = JSON.stringify(selectedTracker);
      }

      return (
        selectedTracker !== getTrackerTypeValue('dcf') &&
        selectedTracker !== getTrackerTypeValue('deepsort')
      );
    },
    disabledTooltip:
      'Custom profile options can only be enabled for high accuracy and Re-Id trackers.',
  },
];

export const TRACKER_OPTIONS = [
  { label: 'High', value: getTrackerTypeValue('dcf') },
  { label: 'Re-Id (High)', value: getTrackerTypeValue('deepsort') },
  { label: 'Medium', value: getTrackerTypeValue('klt') },
  { label: 'Low', value: getTrackerTypeValue('iou') },
];

export function getTrackerTypeValue(type: string) {
  return JSON.stringify({ type });
}

export function TrackerTypeDisplayer({ node }: DisplayerProps) {
  const { tracker } = node.data;

  const trackerType =
    typeof tracker === 'string' ? tracker : JSON.stringify(tracker);

  const option = TRACKER_OPTIONS.find(({ value }) => value === trackerType);

  if (!option) {
    return <span>unset</span>;
  }

  return <strong>{option.label}</strong>;
}
