import React from 'react';

import { Text } from 'components/Text/Text';
import * as List from 'components/List';

export type ComparePlansListItemProps = List.ItemProps & {};

export function ComparePlansListItem({ children }: ComparePlansListItemProps) {
  return (
    <List.Item icon="check">
      <Text>{children}</Text>
    </List.Item>
  );
}
