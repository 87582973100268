import { ChartData } from 'chart.js';

import { CustomTimeSeriesQueryVariables } from 'graphql/queries/timeseries';

import { useChartColors } from './useChartColors';
import { useCustomTimeSeries } from './useCustomTimeSeries';
import { hslaToString, MAIN_CHART_COLOR } from '../services/color';

export type GroupedBarChartResult = {
  data?: ChartData<'bar', number[], string>;
  isLoading: boolean;
  error?: string;
};

export function useGroupedBarChart(
  variables: CustomTimeSeriesQueryVariables,
  variant: 'stacked' | 'grouped'
): GroupedBarChartResult {
  const {
    data,
    isLoading: isLoadingTimeSeries,
    error,
  } = useCustomTimeSeries(variables);
  const { data: colors, isLoading: isLoadingColors } = useChartColors(
    data?.seriesLabels
  );

  const isLoading = isLoadingTimeSeries && isLoadingColors;

  if (!data || !colors) {
    return { isLoading, error };
  }

  const { seriesLabels, seriesData } = data;

  const [{ labels }] = Object.values(seriesData);
  const datasets = Object.entries(seriesData).map(([_, timeseries], index) => ({
    label: seriesLabels[index],
    backgroundColor: hslaToString(colors[index] ?? MAIN_CHART_COLOR),
    borderRadius: 2,
    data: timeseries.values,
    stack: variant === 'stacked' ? 'stack0' : seriesLabels[index],
  }));

  return {
    data: { labels, datasets },
    isLoading,
    error,
  };
}
