import React from 'react';
import Konva from 'konva';
import { Circle } from 'react-konva';

import { ROI_CONFIG } from '../config';

export type ROIAnchorPointProps = {
  x: number;
  y: number;
  draggable?: boolean;
  isSelected?: boolean;
  onDragMove?: (event: Konva.KonvaEventObject<DragEvent>) => void;
  onMouseDown?: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  onClick?: (event: Konva.KonvaEventObject<MouseEvent>) => void;
};

const HOVER_STROKE_WIDTH = ROI_CONFIG.anchor.strokeWidth * 1.5;

/**
 * Draggable anchor point of a ROI canvas shape to allow manipulating shapes.
 */
function ROIAnchorPoint(
  { x, y, isSelected, onClick, ...props }: ROIAnchorPointProps,
  ref: React.ForwardedRef<Konva.Circle>
) {
  const [isHovering, setIsHovering] = React.useState(false);

  function handleMouseEnter() {
    setIsHovering(true);
  }

  function handleMouseLeave() {
    setIsHovering(false);
  }

  function handleClick(event: Konva.KonvaEventObject<MouseEvent>) {
    event.cancelBubble = true;
    onClick?.(event);
  }

  return (
    <Circle
      {...props}
      {...ROI_CONFIG.anchor}
      {...(isHovering && { strokeWidth: HOVER_STROKE_WIDTH })}
      {...(isSelected && {
        stroke: ROI_CONFIG.anchor.fill,
        fill: ROI_CONFIG.anchor.stroke,
      })}
      x={x}
      y={y}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      hitStrokeWidth={10}
      ref={ref}
    />
  );
}

const forwardedRef = React.forwardRef(ROIAnchorPoint);

export { forwardedRef as ROIAnchorPoint };
