import React from 'react';

import { useHasAccess } from 'hooks/useHasAccess';

import { DeploymentFormUtilizationLimitWarning } from './warnings/UtilizationLimitWarning';
import { DeploymentFormDisconnectedNodesWarning } from './warnings/DisconnectedNodesWarning';
import { DeploymentFormNoSourceNodeWarning } from './warnings/NoSourceNodeWarning';
import { DeploymentFormNodesNotIncludedInPlanWarning } from './warnings/NodesNotIncludedInPlanWarning';
import Pipeline from 'types/pipeline';

export type DeploymentFormContentInnerProps = {
  pipeline: Pipeline | undefined;
  /**
   * Callback function to clean up dialog when navigating.
   */
  onLinkClick?: () => void;
};

export function DeploymentFormWarnings({
  pipeline,
  onLinkClick,
}: DeploymentFormContentInnerProps) {
  const [hasAccess] = useHasAccess();

  if (!pipeline || !hasAccess('deploy_edit')) {
    return null;
  }

  return (
    <>
      <DeploymentFormUtilizationLimitWarning onLink={onLinkClick} />
      <DeploymentFormNoSourceNodeWarning pipeline={pipeline} />
      <DeploymentFormDisconnectedNodesWarning pipeline={pipeline} />
      <DeploymentFormNodesNotIncludedInPlanWarning
        pipeline={pipeline}
        onLink={onLinkClick}
      />
    </>
  );
}
