import React from 'react';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';

import errorResponseInterceptor from 'services/api_error_handler';
import { API_BASE_URL } from 'services/api';

import { useFilesSearchJWT } from './useFilesSearchJWT';
import { USER_AGENT } from '../../services/constants';

const client = axios.create({
  baseURL: API_BASE_URL,
  responseType: 'json',
  headers: {
    'User-Agent': USER_AGENT,
  },
});

export function useFilesSearchClient() {
  const queryClient = useQueryClient();

  const { data: jwt } = useFilesSearchJWT();

  React.useEffect(() => {
    if (jwt) {
      client.defaults.headers.common.Authorization = `Bearer ${jwt.token}`;
    }

    const errorInterceptor = client.interceptors.response.use(
      (res) => res,
      (error) => errorResponseInterceptor(error, queryClient)
    );

    return () => {
      client.interceptors.response.eject(errorInterceptor);
    };
  }, [jwt, queryClient]);

  return client;
}
