import Rete, { Node } from 'rete';

import ReteCodeEditor from 'pipelines/services/rete/controls/code_editor';
import ReteTextInput from 'pipelines/services/rete/controls/text_input';
import Component from 'pipelines/services/rete/nodes/component';
import { RawVideo } from 'pipelines/services/rete/sockets';

const defaultTemplate = `# Documentation reference: 
# How to access metadata using this node: https://docs.lumeo.com/docs/custom-function-node#accessing-metadata 
# Examples, e.g. how to send webhooks: https://docs.lumeo.com/docs/custom-function-node#examples

from lumeopipeline import VideoFrame  # Lumeo lib to access frame and metadata
import cv2                            # OpenCV for image manipulations
import numpy                          # numpy for image manipulations

# Global variables that persist across frames go here.
# Onetime initialization code can also live here.
frame_count = 0

def process_frame(frame: VideoFrame, **kwargs) -> bool:

    # Insert your code here.
    global frame_count
    frame_count = frame_count + 1

    with frame.data() as mat:

        # Here's an example that uses OpenCV to put arbitrary text on the frame
        cv2.putText(mat, "Frame " + str(frame_count), (50,50), cv2.FONT_HERSHEY_DUPLEX, 0.7, (0, 0, 255), 1)

        # Here's an example of how to access the frame level metadata an print on the frame
        meta = frame.meta()
        yidx = 100
        for (key, value) in meta.get_all().items(): 
            cv2.putText(mat, key + " : " + str(value), (50,yidx), cv2.FONT_HERSHEY_DUPLEX, 0.7, (255, 255, 255), 1, cv2.LINE_AA)
            yidx = yidx + 50

        # Add your own metadata to the frame
        meta.set_field("frame_count", frame_count)
        meta.save()


    # Return False to drop this frame, True to continue processing.
    return True`;

export default class CustomFunction extends Component {
  static key = 'Custom Function';
  static icon = 'code' as const;
  static exportType = 'function';
  static category = 'rules' as const;
  static description = 'Manually process or modify frames and metadata';

  static editorDisplayers = {
    code: null,
  };
  static input = {
    type: 'raw',
  };
  static output = {
    type: 'raw',
  };

  static outputMetadata = ['Injected metadata'];

  constructor() {
    super(CustomFunction.key);
  }

  async builder(node: Node) {
    node
      .addControl(
        new ReteTextInput(this.editor!, 'name', { label: 'Name', initial: '' })
      )
      .addControl(
        new ReteCodeEditor(this.editor!, 'code', {
          label: 'Code',
          initial: defaultTemplate,
          deploymentParam: 'never',
        })
      )
      .addInput(new Rete.Input('input', 'Input', RawVideo, false))
      .addOutput(new Rete.Output('output', 'Output', RawVideo));
  }
}
