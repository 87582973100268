import React from 'react';
import classnames from 'classnames/bind';
import { useToggle } from '@mantine/hooks';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Deployment, {
  DEPLOYMENT_ACTIVE_STATES,
  DEPLOYMENT_ERROR_STATES,
  DEPLOYMENT_INACTIVE_STATES,
  DeploymentState,
} from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';

import {
  useDeployments,
  UseDeploymentsFilterParams,
} from 'hooks/api/useDeployments';
import { useTagsFilterParams } from 'tags/hooks/useTagsFilterParams';

import { DeploymentList } from 'deployments/components/List/List';
import { Drawer } from 'components/Drawer/Drawer';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { Loader } from 'components/Loader/Loader';
import {
  deploymentFormDrawerClassName,
  NewDeploymentForm,
} from 'deployments/components/Form';
import { PipelineSelect } from 'pipelines/components/PipelineSelect/PipelineSelect';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { ScrollArea } from 'components/ScrollArea/ScrollArea';
import { StateSelect } from 'components/StateSelect/StateSelect';

import { DeploymentOverviewEmptyView } from './EmptyView';
import { QueuedDeploymentsBanner } from './QueuedDeploymentsBanner';
import styles from './DeploymentOverview.module.scss';

const c = classnames.bind(styles);

const DEPLOYMENT_STATES = [
  ...DEPLOYMENT_ACTIVE_STATES,
  ...DEPLOYMENT_INACTIVE_STATES,
  ...DEPLOYMENT_ERROR_STATES,
];

export function DeploymentOverview() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const defaultStateFilter = state?.filters?.state;

  const paginationContainerRef = React.useRef<HTMLDivElement>(null);

  const [isDeploying, toggleIsDeploying] = useToggle();

  const tagFilters = useTagsFilterParams();
  const [queryFilters, setQueryFilters] =
    React.useState<UseDeploymentsFilterParams>({
      page: 1,
      states: defaultStateFilter ? [defaultStateFilter] : undefined,
    });
  const queryResult = useDeployments({
    ...tagFilters,
    ...queryFilters,
  });

  const [searchFilters, setSearchFilters] =
    React.useState<UseDeploymentsFilterParams>({});
  const searchQueryResult = useDeployments(
    ['deployments-suggestions'],
    {
      ...tagFilters,
      ...searchFilters,
      limit: 20,
    },
    { enabled: Object.keys(searchFilters).length > 0, keepPreviousData: false }
  );

  const { data: deployments, isLoading } = queryResult;

  const handleSearchInput = React.useCallback((value?: string) => {
    if (!value) {
      setSearchFilters({});
      setQueryFilters({ page: 1 });
      return;
    }

    setSearchFilters({
      deployment_names: [value],
    });
  }, []);

  const handleGatewayFilterChange = React.useCallback(
    (gateway: Gateway | null) => {
      setQueryFilters((previousFilters) => ({
        ...previousFilters,
        gateway_ids: gateway ? [gateway.id] : undefined,
        page: 1,
      }));

      setSearchFilters((previousFilters) => ({
        ...previousFilters,
        gateway_ids: gateway ? [gateway.id] : undefined,
      }));
    },
    []
  );

  const handlePipelineFilterChange = React.useCallback(
    (pipeline: Pipeline | null) => {
      setQueryFilters((previousFilters) => ({
        ...previousFilters,
        pipeline_ids: pipeline ? [pipeline.id] : undefined,
        page: 1,
      }));

      setSearchFilters((previousFilters) => ({
        ...previousFilters,
        pipeline_ids: pipeline ? [pipeline.id] : undefined,
      }));
    },
    []
  );

  const handleStateFilterChange = React.useCallback((state: string | null) => {
    setQueryFilters((previousFilters) => ({
      ...previousFilters,
      states: state ? [state as DeploymentState] : undefined,
      page: 1,
    }));

    setSearchFilters((previousFilters) => ({
      ...previousFilters,
      states: state ? [state as DeploymentState] : undefined,
    }));
  }, []);

  const handleOnDeploymentSuccess = React.useCallback(
    (deployment: Deployment) => {
      toggleIsDeploying();
      navigate(deployment.id);
    },
    [toggleIsDeploying, navigate]
  );

  if (isLoading) {
    return <Loader text="Loading deployments..." />;
  }

  if (!deployments) {
    return null;
  }

  const hasDeployments = deployments?.total_elements > 0;
  const hasFilters =
    Object.keys(queryFilters).length > 0 ||
    Object.values(tagFilters).some(Boolean);

  function handleApplyFilters() {
    setQueryFilters({ ...searchFilters, page: 1 });
  }

  function handleSuggestionSelect({ id }: Deployment) {
    navigate(id);
  }

  return (
    <>
      {!hasDeployments && !hasFilters ? (
        <DeploymentOverviewEmptyView onDeployClick={toggleIsDeploying} />
      ) : (
        <div className={c('wrap')}>
          <div className={c('top')}>
            <QuerySearch
              entityName="deployment"
              searchLabel="Search deployments..."
              searchResultField="name"
              searchQueryResult={searchQueryResult}
              onApplyFilters={handleApplyFilters}
              onSuggestionSelect={handleSuggestionSelect}
              onValueChange={handleSearchInput}
              totalElements={queryResult.data?.total_elements}
              filters={
                <>
                  <GatewaySelect
                    onChange={handleGatewayFilterChange}
                    size="small"
                    isClearable
                  />
                  <PipelineSelect
                    onChange={handlePipelineFilterChange}
                    size="small"
                    isClearable
                  />
                  <StateSelect
                    onChange={handleStateFilterChange}
                    options={DEPLOYMENT_STATES}
                    defaultValue={defaultStateFilter}
                    size="small"
                  />
                </>
              }
            />
          </div>

          <nav className={c('list')}>
            <QueuedDeploymentsBanner />
            <ScrollArea className={c('list-scroll-area')}>
              <DeploymentList
                queryResult={queryResult}
                page={queryFilters.page}
                onPageChange={(page) =>
                  setQueryFilters((previous) => ({ ...previous, page }))
                }
                portalTarget={paginationContainerRef}
              />
            </ScrollArea>
          </nav>

          <section className={c('detail')}>
            <Outlet />
          </section>

          <div className={c('pagination')} ref={paginationContainerRef} />
        </div>
      )}

      <Drawer
        title="Deploy pipeline"
        containerClassName={deploymentFormDrawerClassName}
        onClose={toggleIsDeploying}
        open={isDeploying}
      >
        <NewDeploymentForm onSuccess={handleOnDeploymentSuccess} />
      </Drawer>
    </>
  );
}
