import React from 'react';
import { RegularPolygon } from 'react-konva';

import { ROI_CONFIG, STROKE_COLOR } from '../config';

type DirectionIndicatorsProps = {
  x: number;
  y: number;
  height: number;
  rotation: number;
};

const RIGHT_COLOR = STROKE_COLOR;
const LEFT_COLOR = '#E54D4D';

export function DirectionIndicators({
  x,
  y,
  height,
  rotation,
}: DirectionIndicatorsProps) {
  const radius = height / 4 - 1;

  return (
    <>
      <RegularPolygon
        {...ROI_CONFIG.directionIndicator}
        x={x}
        y={y}
        offsetY={13}
        radius={radius}
        rotation={rotation}
        stroke={LEFT_COLOR}
      />
      <RegularPolygon
        {...ROI_CONFIG.directionIndicator}
        x={x}
        y={y}
        fill={RIGHT_COLOR}
        offsetY={-5}
        radius={radius}
        rotation={rotation}
        scaleY={-1}
        stroke={RIGHT_COLOR}
      />
    </>
  );
}
