import classnames from 'classnames/bind';
import { components, ControlProps } from 'react-select';

import styles from '../Select.module.scss';

const c = classnames.bind(styles);

export function Control<OptionType, MultiSelect extends boolean = false>(
  props: React.PropsWithChildren<ControlProps<OptionType, MultiSelect>>
) {
  return (
    <components.Control
      {...props}
      className={c('input', 'control', { focused: props.isFocused })}
      aria-disabled={props.isDisabled}
    />
  );
}
