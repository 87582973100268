import React from 'react';
import classnames from 'classnames/bind';
import { useToggle } from '@mantine/hooks';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';

import { usePreventWindowDrop } from './hooks/usePreventWindowDrop';
import styles from './DragDropFileUploadArea.module.scss';

const c = classnames.bind(styles);

export type DragDropFileUploadAreaProps = {
  id: string;
  className?: string;
  info?: string;
  imageSrc?: string;
  accept?: React.InputHTMLAttributes<HTMLInputElement>['accept'];
  onChange: (files: File[]) => void;
};

export function DragDropFileUploadArea({
  id,
  className,
  info,
  imageSrc,
  accept,
  onChange,
  ...props
}: DragDropFileUploadAreaProps) {
  const formRef = React.useRef<HTMLLabelElement>(null);

  const [isDragging, toggleIsDragging] = useToggle();
  usePreventWindowDrop(formRef);

  function handleDrop(event: React.DragEvent) {
    toggleIsDragging(false);

    const files = Array.from(event.dataTransfer.files);

    if (!files) {
      return;
    }

    if (accept) {
      const regex = new RegExp(accept);
      onChange(files.filter((file) => regex.test(file.type)));
      return;
    }

    onChange(files);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      onChange([...event.target.files]);
    }
  }

  return (
    <label
      htmlFor={id}
      className={c(className, 'upload-wrapper', { active: isDragging })}
      onDragEnter={() => toggleIsDragging()}
      onDragLeave={() => toggleIsDragging()}
      onDrop={handleDrop}
      ref={formRef}
    >
      {imageSrc ? <img src={imageSrc} alt="" className={c('image')} /> : null}
      <VisuallyHidden>
        <input
          {...props}
          id={id}
          type="file"
          accept={accept}
          multiple={true}
          onChange={handleChange}
        />
      </VisuallyHidden>

      <div className={c('theme info')}>
        <Heading level="4" className={c('header')}>
          Drag and drop or <span className={c('link')}>browse</span>
        </Heading>
        <Text>{info}</Text>
      </div>
    </label>
  );
}
