import { serializable } from 'serializr';

import { Timestamped } from './timestamp';

export default class Application extends Timestamped {
  @serializable id: string;
  @serializable name: string;
  @serializable organization_id: string;
  @serializable default_gateway_configuration_id: string | null;

  constructor(
    id: string,
    name: string,
    organizationId: string,
    defaultGatewayConfigurationId: string | null,
    createdAt?: string,
    updatedAt?: string
  ) {
    super(createdAt, updatedAt);
    this.id = id;
    this.name = name;
    this.organization_id = organizationId;
    this.default_gateway_configuration_id = defaultGatewayConfigurationId;
  }

  copy() {
    return new Application(
      this.id,
      this.name,
      this.organization_id,
      this.default_gateway_configuration_id,
      this.created_at,
      this.updated_at
    );
  }
}
