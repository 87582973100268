import React from 'react';
import Select, {
  ControlProps,
  MenuProps,
  OnChangeValue,
  Options,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps,
  components,
} from 'react-select';
import { useToggle } from '@mantine/hooks';
import classNames from 'classnames/bind';

import { RadioContext, RadioContextProps, RadioValue } from './Radio';

import { Icon } from 'components/Icon/Icon';
import {
  SelectClearIndicator,
  SelectDropdownIndicator,
  SelectIndicatorSeparator,
  SelectOption,
} from 'components/Select/Select';

import { RadioOptionProps } from './RadioOption';
import styles from './Radio.module.scss';

const c = classNames.bind(styles);

export type RadioSelectOptionProps<T> = Omit<RadioOptionProps, 'value'> & {
  options?: Options<T>;
};

type RadioSelectOptionType = {
  label: string;
  value: RadioValue;
};

type RadioSelectValueType = OnChangeValue<RadioSelectOptionType, false>;

export function RadioSelectOption(
  props: RadioSelectOptionProps<RadioSelectOptionType>
) {
  const context = React.useContext(RadioContext);

  if (!context) {
    throw new Error(
      "Can't use component `RadioSelectOption` outside of a `Radio` component."
    );
  }

  return <RadioSelectOptionInner {...props} />;
}

function RadioSelectOptionInner({
  children: label,
  disabled,
  icon,
  options,
  keyrefValue,
}: RadioSelectOptionProps<RadioSelectOptionType>) {
  const { value, onChange, ...context } = React.useContext(
    RadioContext
  ) as RadioContextProps;
  const [selected, setSelected] = React.useState<RadioSelectValueType>(null);
  const [isOpen, toggleIsOpen] = useToggle();

  const isOptionDisabled =
    context.disabled ||
    (typeof disabled === 'function' ? disabled(keyrefValue) : disabled);

  React.useEffect(() => {
    if (selected && value !== selected?.value) {
      setSelected(null);
    } else if (value) {
      setSelected(options?.find((option) => option.value === value) ?? null);
    }
  }, [options, value, selected]);

  function handleChange(selected: RadioSelectValueType) {
    setSelected(selected);

    if (!selected) {
      return;
    }

    onChange(selected.value);
  }

  function ValueContainer({
    children,
    className,
    ...props
  }: ValueContainerProps<RadioSelectOptionType, false>) {
    return (
      <components.ValueContainer
        {...props}
        className={c(className, 'select-value')}
      >
        <span className={c('select-value-content')}>{label}</span>
        {children}
      </components.ValueContainer>
    );
  }

  function Control({
    children,
    className,
    ...props
  }: ControlProps<RadioSelectOptionType, false>) {
    return (
      <components.Control
        {...props}
        className={c(className, 'select-control', 'faux', {
          focused: props.isFocused,
        })}
        aria-disabled={props.isDisabled}
      >
        {icon && <Icon name={icon} className={c('icon')} size="small" />}
        <div className={c('select-control-content')}>{children}</div>
      </components.Control>
    );
  }

  return (
    <fieldset
      className={c('select-wrap', {
        selected: Boolean(selected),
        open: isOpen,
      })}
    >
      <div className={c('select-button')}>
        <Select<RadioSelectOptionType>
          options={options}
          onChange={handleChange}
          placeholder={label}
          value={selected}
          isDisabled={isOptionDisabled}
          components={{
            ClearIndicator: SelectClearIndicator,
            DropdownIndicator: SelectDropdownIndicator,
            IndicatorSeparator: SelectIndicatorSeparator,
            Option: SelectOption,
            Placeholder,
            Control,
            ValueContainer,
            SingleValue,
            Menu,
          }}
          onMenuOpen={() => toggleIsOpen(true)}
          onMenuClose={() => toggleIsOpen(false)}
          menuPortalTarget={document.body}
          isMulti={false}
          styles={{
            menuPortal: (css) => ({
              ...css,
              zIndex: 999,
            }),
            menu: (css) => ({
              ...css,
              backgroundColor: 'hsl(var(--ui-fg))',
              padding: 8,
              width: '400px',
            }),
            input: (css) => ({
              ...css,
              margin: 0,
              padding: 0,
            }),
            placeholder: (css) => ({
              ...css,
              margin: 0,
              padding: 0,
              color: 'hsl(var(--control-placeholder))',
            }),
            singleValue: (css) => ({
              ...css,
              color: 'hsl(var(--primary-text))',
            }),
          }}
          menuIsOpen={isOpen}
        />
      </div>
    </fieldset>
  );
}

function Placeholder({
  cx,
  className,
  ...props
}: PlaceholderProps<RadioSelectOptionType, false>) {
  return (
    <components.Placeholder
      {...props}
      className={cx({}, c('select-placeholder'))}
      cx={cx}
    />
  );
}

function SingleValue({
  cx,
  className,
  ...props
}: SingleValueProps<RadioSelectOptionType, false>) {
  return (
    <components.SingleValue
      {...props}
      className={cx({}, c('select-single-value', className))}
      cx={cx}
    />
  );
}

function Menu({
  cx,
  className,
  ...props
}: MenuProps<RadioSelectOptionType, false>) {
  return (
    <components.Menu
      {...props}
      className={cx({}, c('select-menu', className))}
      cx={cx}
    />
  );
}
