import { useCallback } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

import { useAPI } from './api/useAPI';

/** @deprecated Use react router hooks directly */
export function useAppRedirect() {
  const { applicationID } = useAPI();
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(
    (to: To, keepSearchParams?: boolean) => {
      const rest = typeof to === 'object' ? { ...to } : {};
      let pathname =
        typeof to === 'object' && 'pathname' in to
          ? String(to.pathname)
          : String(to);

      if (!pathname.includes('/applications/')) {
        pathname = `/applications/${applicationID + pathname}`;
      }

      const previousSearch = location.search;

      const params: string[] = [];

      if (previousSearch && keepSearchParams) {
        params.push(previousSearch);
      }

      if (typeof to === 'object' && to?.search) {
        params.push(to.search);
      }

      const search = params.length ? [...new Set(params)].join('&') : undefined;

      const newLocation = {
        ...rest,
        pathname,
        search,
      };

      navigate(newLocation);
    },
    [applicationID, location, navigate]
  );
}
