import React from 'react';
import classNames from 'classnames/bind';

import { IconButton } from 'components/IconButton/IconButton';

import styles from './CookieNotice.module.scss';

const c = classNames.bind(styles);

export function CookieNotice() {
  const [didClose, setDidClose] = React.useState<boolean>(() => {
    try {
      const storage = localStorage.getItem('ignore-cookie-notice');

      if (storage) {
        return JSON.parse(storage);
      }

      return false;
    } catch (error) {
      return false;
    }
  });

  function handleCloseClick() {
    localStorage.setItem('ignore-cookie-notice', 'true');
    setDidClose(true);
  }

  if (didClose) {
    return null;
  }

  return (
    <div className={c('theme dark', 'wrap')}>
      <div>
        <p>We use cookies to analyse how you use the Lumeo console.</p>
        <p>By using our site, you agree to their use.</p>
      </div>
      <IconButton icon="cancel" label="Close" onClick={handleCloseClick} />
    </div>
  );
}
