import React from 'react';
import classnames from 'classnames/bind';
import { Controller, useForm } from 'react-hook-form';

import Application from 'types/application';
import AccountRole, {
  ApplicationRole,
  OrganizationRole,
} from 'types/account_role';

import { useCreateOrganizationInvitation } from 'settings/hooks/useCreateOrganizationInvitation';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Drawer, DrawerProps } from 'components/Drawer/Drawer';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Field } from 'components/Field/Field';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Input } from 'components/Input/Input';
import { Text } from 'components/Text/Text';

import { ApplicationRolesInput } from '../ApplicationRolesInput/ApplicationRolesInput';
import { OrganizationRolesInput } from '../OrganizationRolesInput/OrganizationRolesInput';
import styles from './InviteMemberModal.module.scss';

const c = classnames.bind(styles);

type InviteMemberFieldValues = {
  email: string;
  applicationRoles?: Record<Application['id'], ApplicationRole[]>;
  organizationRoles?: OrganizationRole[];
};

type InviteMemberModalProps = DrawerProps & { onSuccess: () => void };

export function InviteMemberModal({
  onClose,
  onSuccess,
  ...props
}: InviteMemberModalProps) {
  const { data: organization } = useCurrentOrganization();
  const organizationName = organization?.name;

  const scrollOnError = React.useRef<HTMLDivElement>(null);

  const {
    mutate: createInvitation,
    isLoading,
    error,
    reset,
  } = useCreateOrganizationInvitation({
    onError() {
      scrollOnError.current?.scrollIntoView();
    },
    onSuccess: handleSuccess,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<InviteMemberFieldValues>({ shouldUnregister: true });

  function inviteMember({
    email,
    applicationRoles = {},
    organizationRoles = [],
  }: InviteMemberFieldValues) {
    if (!organization) {
      return;
    }

    const applicationAccountRoles = Object.keys(applicationRoles).flatMap(
      (key) =>
        applicationRoles[key].map(
          (name) => new AccountRole({ name, application_id: key })
        )
    );
    const organizationAccountRoles = organizationRoles.map(
      (name) => new AccountRole({ name, organization_id: organization.id })
    );

    createInvitation({
      email,
      roles: [...applicationAccountRoles, ...organizationAccountRoles],
    });
  }

  function closeDrawer() {
    reset();
    onClose?.();
  }

  function handleSuccess() {
    onSuccess();
    closeDrawer();
  }

  const hasSelectedManagerRole = Boolean(
    watch('organizationRoles')?.some((role) => role === 'manager')
  );

  return (
    <Drawer
      {...props}
      title="Invite new member to organization"
      onClose={closeDrawer}
      header={
        <div className={c('header')}>
          {organizationName ? (
            <Text>{organizationName}</Text>
          ) : (
            <span className={c('skeleton-text')}>
              Loading organization name...
            </span>
          )}
          <ExternalLink href="https://docs.lumeo.com/docs/roles">
            Member and roles docs
          </ExternalLink>
        </div>
      }
      footer={
        <ButtonGroup align="end">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="invite-new-member-form"
            loading={isLoading}
          >
            Invite
          </Button>
        </ButtonGroup>
      }
    >
      <form id="invite-new-member-form" onSubmit={handleSubmit(inviteMember)}>
        {error && (
          <FormMessage className={c('error')} icon="warning" intent="danger">
            {error.message}
          </FormMessage>
        )}

        <Field
          className={c('email')}
          label="Email address"
          error={errors.email}
        >
          <Input
            {...register('email', {
              required: 'Please provide an email address.',
            })}
            type="email"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
          />
        </Field>
        <div className={c('scroll-on-error')} ref={scrollOnError} />
        <Field label="Organization roles" error={errors.organizationRoles}>
          <Controller
            name="organizationRoles"
            control={control}
            render={({ field: { value, onChange } }) => (
              <OrganizationRolesInput value={value} onChange={onChange} />
            )}
          />
        </Field>

        {hasSelectedManagerRole && (
          <FormMessage className={c('info')} intent="info" icon="info">
            Users with the <strong>Manager</strong> role automatically have{' '}
            <strong>
              Design, Deploy, and Monitor access to all workspaces
            </strong>{' '}
            under your organization.
          </FormMessage>
        )}

        <Field error={errors.applicationRoles}>
          <Controller
            name="applicationRoles"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ApplicationRolesInput value={value} onChange={onChange} />
            )}
          />
        </Field>
      </form>
    </Drawer>
  );
}
