import React from 'react';
import classNames from 'classnames/bind';
import { Navigate, useNavigate } from 'react-router-dom';

import Deployment from 'types/deployment';

import { isNotNullOrUndefined } from 'services/nullable';
import { useAPI } from 'hooks/api/useAPI';
import { useCreateDeployment } from 'hooks/api/useDeployment';

import { DeploymentFormContentRef } from 'deployments/components/Form/Content';
import { DeploymentFormProvider } from 'deployments/components/Form/context';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { OptionalTooltip } from 'components/Tooltip/OptionalTooltip';

import { useRapidDeployState } from '../hooks/useRapidDeployState';
import { RapidDeployRoute } from '../routes';
import { RapidDeployConfigurationForm } from './ConfigurationForm';
import { RapidDeployLayout } from './Layout';

import styles from '../RapidDeploy.module.scss';

const c = classNames.bind(styles);

export function RapidDeployDeployments() {
  const navigate = useNavigate();
  const { applicationID } = useAPI();
  const { gateway, videoSources } = useRapidDeployState();

  const isGatewayOnline = gateway?.isOnline() ?? false;
  const [shouldStartImmediately, setShouldStartImmediately] =
    React.useState(isGatewayOnline);
  const [, setError] = React.useState<string>();

  const [
    videoSourceIdsWithPipelineSelected,
    setVideoSourceIdsWithPipelineSelected,
  ] = React.useState(new Set<string>());

  const formRefs = React.useRef<Array<DeploymentFormContentRef | null>>([]);

  const { mutate: createDeployment, isLoading } = useCreateDeployment({
    onSuccess() {
      if (!gateway) {
        return;
      }

      navigate(`../../gateways/${gateway.id}`, {
        state: {
          tab: 'deployments',
        },
      });
    },
  });

  if (videoSources.length === 0) {
    return <Navigate to={`../${RapidDeployRoute.VIDEO_SOURCES}`} replace />;
  }

  async function handleContinueClick() {
    if (!formRefs.current || !gateway || !applicationID) {
      return;
    }

    const nonNullForms = formRefs.current.filter(isNotNullOrUndefined);
    const isValid = (
      await Promise.all(nonNullForms.map((form) => form.validate()))
    ).every(Boolean);

    // Only continue if all forms are valid
    if (isValid) {
      const formSubmissions = nonNullForms.map((form) => form.onSubmit());

      try {
        const configurations = await Promise.all(formSubmissions);

        configurations.forEach(({ configuration, pipeline, videoSource }) => {
          let { name } = gateway;

          if (videoSource) {
            name = `${name}_${videoSource.name}`;
          }

          if (pipeline) {
            name = `${name}_${pipeline.name}`;
          }

          const deployment = new Deployment(
            '',
            applicationID,
            pipeline.id,
            gateway.id,
            name,
            configuration
          );

          deployment.state = shouldStartImmediately ? 'running' : 'stopped';
          createDeployment(deployment);
          setError(undefined);
        });
      } catch (error) {
        setError(String(error));
      }
    }
  }

  return (
    <RapidDeployLayout
      backTo={RapidDeployRoute.VIDEO_SOURCES}
      continueText="Deploy"
      onContinue={handleContinueClick}
      isContinueLoading={isLoading}
      canContinue={videoSources.every(({ id }) =>
        videoSourceIdsWithPipelineSelected.has(id)
      )}
      slot={
        <OptionalTooltip
          showTooltip={!isGatewayOnline}
          content="Gateway is offline. Cannot start immediately."
        >
          <div className={c('footer-actions')}>
            <LightSwitch
              id="rd_always-on"
              onValueChange={setShouldStartImmediately}
              defaultChecked={isGatewayOnline}
              disabled={!isGatewayOnline}
            />
            <label
              className={c('footer-actions-label', 'text-label')}
              htmlFor="rd_always-on"
            >
              Start immediately
            </label>
          </div>
        </OptionalTooltip>
      }
    >
      {videoSources.map((videoSource, index) => (
        <DeploymentFormProvider
          gateway={gateway}
          initialSource={videoSource}
          key={videoSource.id}
        >
          <RapidDeployConfigurationForm
            videoSource={videoSource}
            onPipelineSelect={() => {
              setVideoSourceIdsWithPipelineSelected((previous) =>
                new Set(previous).add(videoSource.id)
              );
            }}
            defaultOpen={videoSources.length === 1}
            ref={(el) => (formRefs.current[index] = el)}
          />
        </DeploymentFormProvider>
      ))}
    </RapidDeployLayout>
  );
}
