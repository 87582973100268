import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Pipeline from 'types/pipeline';

import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';

import { initializeAPIPipeline } from './usePipelines';

export type UpdatePipelineVariables =
  | { id: Pipeline['id']; name: Pipeline['name'] }
  | { id: Pipeline['id']; definition: Pipeline['definition'] };

export function useUpdatePipeline({
  onMutate,
  onError,
  onSuccess,
  onSettled,
  ...options
}: UseMutationOptions<Pipeline, APIError, UpdatePipelineVariables> = {}) {
  const { pipelines, applicationID } = useAPI();
  const queryClient = useQueryClient();

  return useMutation(pipelines.update, {
    ...options,
    async onMutate({ id, ...variables }) {
      await queryClient.cancelQueries(['pipelines']);
      await queryClient.cancelQueries(['pipeline', id, applicationID]);

      onMutate?.({ id, ...variables });

      return updateQueriesData<Pipeline>({
        queryClient,
        listQueryKey: ['pipelines'],
        singleQueryKey: ['pipeline'],
        ids: [id],
        updateData(pipeline) {
          const updatedPipeline = pipeline.copy();

          if ('name' in variables) {
            updatedPipeline.name = variables.name;
          }

          if ('definition' in variables) {
            updatedPipeline.definition = variables.definition;
          }

          return updatedPipeline;
        },
      });
    },
    onError(error, variables, context) {
      onError?.(error, variables, context);

      context?.previousLists?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSuccess(data, variables, context) {
      onSuccess?.(initializeAPIPipeline(data), variables, context);
    },
    onSettled(data, error, variables, context) {
      if (data) {
        onSettled?.(initializeAPIPipeline(data), error, variables, context);
      } else {
        onSettled?.(data, error, variables, context);
      }

      const { id } = variables;
      queryClient.invalidateQueries(['pipeline', id, applicationID]);
      queryClient.invalidateQueries(['pipelines']);
    },
  });
}
