import React from 'react';
import classnames from 'classnames/bind';

import Pipeline from 'types/pipeline';

import { useAppRedirect } from 'hooks/useAppRedirect';
import { useCreatePipeline } from 'pipelines/hooks/useCreatePipeline';

import { readFile } from 'services/files';

import * as Dialog from 'components/Dialog';
import { Heading } from 'components/Heading/Heading';
import { DragDropFileUploadArea } from 'components/DragDropFileUploadArea/DragDropFileUploadArea';
import { FileDisplay } from 'components/FileDisplay/FileDisplay';
import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import {
  FormErrorMessage,
  FormMessage,
} from 'components/FormMessage/FormMessage';

import LumeoCloudJsonImage from './images/lumeo-cloud-json.svg';
import styles from './CreatePipelinesFromJsonModal.module.scss';

const c = classnames.bind(styles);

export type CreatePipelinesFromJsonModalProps = Pick<
  Dialog.RootProps,
  'open' | 'onOpenChange'
>;

export function CreatePipelinesFromJsonModal({
  open,
  onOpenChange,
}: CreatePipelinesFromJsonModalProps) {
  const [files, setFiles] = React.useState<File[]>([]);
  const [pipelines, setPipelines] = React.useState<Pipeline[]>([]);
  const [errorIndices, setErrorIndices] = React.useState<number[]>([]);

  const redirect = useAppRedirect();
  const { mutateAsync: createPipeline, isLoading, error } = useCreatePipeline();

  React.useEffect(() => {
    async function handleFileProcessing(newFiles: File[]) {
      const res = await Promise.allSettled(
        newFiles.map(async (file) => {
          const res = await readFile(file);
          const pipeline = Pipeline.fromDefinition(JSON.parse(res));
          return pipeline;
        })
      );

      const errIndex: number[] = [];
      const createdPipelines = res.reduce<Pipeline[]>(
        (pipelines, current, index) => {
          if (current.status === 'fulfilled') {
            pipelines.push(current.value);
          } else {
            errIndex.push(index);
          }

          return pipelines;
        },
        []
      );

      setErrorIndices(errIndex);

      setPipelines(createdPipelines);
    }

    handleFileProcessing(files);
  }, [files]);

  function handleFileChange(newFiles: File[]) {
    setFiles([...files, ...newFiles]);
  }

  function handleDelete(index: number) {
    setErrorIndices(errorIndices.filter((errorIndex, i) => index !== i));
    setFiles(files.filter((file, i) => index !== i));
  }

  function closeModal() {
    onOpenChange?.(false);
  }

  async function handleCreatePipelineClick() {
    const newPipelines = await Promise.allSettled(
      pipelines.map(async (pipeline) =>
        createPipeline({
          name: pipeline.name,
          definition: pipeline.definition,
        })
      )
    );

    if (newPipelines.length === 0) {
      return;
    }

    const createdPipelines = newPipelines.reduce<Pipeline[]>(
      (pipelines, current) => {
        if (current.status === 'fulfilled') {
          pipelines.push(current.value);
        }
        return pipelines;
      },
      []
    );

    if (createdPipelines.length === 1) {
      redirect(`/design/pipelines/${createdPipelines[0].id}`);
      closeModal();
    } else {
      redirect('/design/pipelines/');
      closeModal();
    }
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <div className={c('dialog')}>
        <Dialog.Title asChild>
          <Heading level="2">
            <span>Import Pipeline from JSON</span>
          </Heading>
        </Dialog.Title>

        <DragDropFileUploadArea
          id="json-uploader"
          onChange={handleFileChange}
          accept="application/json"
          info=".json files"
          imageSrc={LumeoCloudJsonImage}
        />

        {error && <FormErrorMessage error={error} />}

        {errorIndices.length ? (
          <FormMessage className={c('form-message')} intent="warning">
            Some uploaded files are not valid JSON and will not be imported.
          </FormMessage>
        ) : undefined}

        {files.length ? (
          <div>
            {files.map((file, index) => (
              <FileDisplay
                file={file}
                onDelete={() => handleDelete(index)}
                key={`${file.name}_${index}`}
                isError={errorIndices.includes(index)}
              />
            ))}
          </div>
        ) : undefined}

        <ButtonGroup>
          <Button
            variant="primary"
            disabled={!files.length}
            onClick={handleCreatePipelineClick}
            loading={isLoading}
          >
            {files.length > 1 ? 'Create Pipelines' : 'Create Pipeline'}
          </Button>
        </ButtonGroup>
      </div>
    </Dialog.Root>
  );
}
