import React from 'react';
import { SingleValue } from 'react-select';

import Deployment from 'types/deployment';
import { FilterInput } from 'graphql/types/filter';

import { useDeployments } from 'hooks/api/useDeployments';

import { DeploymentSelect } from 'deployments/components/DeploymentSelect/DeploymentSelect';

import { Placeholder } from './SuggestionInput';
import { filterIds } from '../service';
import { useMetricFiltersState } from '../context';

export type DeploymentFilterInputProps = Pick<FilterInput, 'id' | 'value'>;

export function DeploymentFilterInput({
  id,
  value,
}: DeploymentFilterInputProps) {
  const { filters, updateFilter } = useMetricFiltersState();

  const deploymentIds = filterIds('deployment_id', filters);

  const { data: deployments, isInitialLoading } = useDeployments(
    ['metric-filter-deployments'],
    { deployment_ids: deploymentIds },
    { enabled: Boolean(deploymentIds.length), keepPreviousData: true }
  );

  if (!deployments && isInitialLoading) {
    return Placeholder;
  }

  function handleDeploymentChange(deployment: SingleValue<Deployment>) {
    updateFilter(id, 'value', deployment?.id);
  }

  return (
    <DeploymentSelect
      defaultValue={deployments?.data.find(({ id }) => id === value)}
      onChange={handleDeploymentChange}
      styles={{
        menu: (provided) => ({
          ...provided,
          width: 'auto',
        }),
      }}
    />
  );
}
