import { UseQueryOptions } from '@tanstack/react-query';

import {
  COUNTER,
  CounterQueryVariables,
  CounterResponse,
} from 'graphql/queries/counter';

import { usePropelQuery } from 'graphql/hooks/usePropelQuery';

export function useCounter(
  variables: CounterQueryVariables,
  options?: UseQueryOptions<CounterResponse>
) {
  return usePropelQuery<CounterResponse, { input: CounterQueryVariables }>(
    COUNTER,
    { input: variables },
    ['counter'],
    options
  );
}
