import * as RadixDialog from '@radix-ui/react-dialog';
import classNames from 'classnames/bind';

import { IconButton } from 'components/IconButton/IconButton';

import styles from './Dialog.module.scss';

const c = classNames.bind(styles);

type CommonProps = Pick<
  RadixDialog.DialogContentProps,
  'children' | 'className'
> &
  Pick<
    RadixDialog.DialogProps,
    'open' | 'onOpenChange' | 'defaultOpen' | 'modal'
  > & {
    trigger?: React.ReactElement;
  };

type DialogWithoutImage = CommonProps & {
  imageSrc?: never;
  imageAlt?: never;
};

type DialogWithImage = CommonProps & {
  imageSrc: string;
  imageAlt: string;
};

export type RootProps = DialogWithoutImage | DialogWithImage;

const containerElement = document.getElementById('modal-area');

export function Root(props: DialogWithoutImage): JSX.Element;
export function Root(props: DialogWithImage): JSX.Element;
export function Root({
  children,
  className,
  trigger,
  open,
  defaultOpen,
  modal,
  imageAlt,
  imageSrc,
  onOpenChange,
}: RootProps) {
  const hasImage = imageSrc !== undefined && imageAlt !== undefined;

  function preventAccidentalClose(event: Event) {
    if (!modal) {
      return;
    }

    event.preventDefault();
  }

  return (
    <RadixDialog.Root
      open={open}
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
      modal={modal ?? true}
    >
      {trigger && <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>}
      <RadixDialog.Portal container={containerElement}>
        <RadixDialog.Overlay className={c('overlay')} />
        <RadixDialog.Content
          className={c('wrap')}
          onEscapeKeyDown={preventAccidentalClose}
          onInteractOutside={preventAccidentalClose}
        >
          <div className={c(className, 'content', { 'has-image': hasImage })}>
            <RadixDialog.Close className={c('close')} asChild>
              <IconButton icon="cancel" label="Close" />
            </RadixDialog.Close>

            {hasImage && (
              <img src={imageSrc} alt={imageAlt} className={c('image')} />
            )}

            {children}
          </div>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}
