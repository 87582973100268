export type FileSizeUnit = 'TiB' | 'GiB' | 'MiB' | 'KiB';

export function getDisplayValue(
  bytes: number | string | null,
  unit: FileSizeUnit
): number | string {
  if (Number.isNaN(Number(bytes)) || !Number.isFinite(bytes)) {
    return bytes ?? '';
  }

  let targetValue = Number(bytes);

  switch (unit) {
    case 'TiB':
      targetValue /= 1024;
    case 'GiB':
      targetValue /= 1024;
    case 'MiB':
      targetValue /= 1024;
    case 'KiB':
      targetValue /= 1024;
  }

  return targetValue;
}

export function getBytes(
  value: number | string | null,
  unit: FileSizeUnit
): number | string {
  if (Number.isNaN(Number(value)) || !Number.isFinite(value)) {
    return value ?? '';
  }

  let valueInBytes = Number(value);

  switch (unit) {
    case 'TiB':
      valueInBytes *= 1024;
    case 'GiB':
      valueInBytes *= 1024;
    case 'MiB':
      valueInBytes *= 1024;
    case 'KiB':
      valueInBytes *= 1024;
  }

  return valueInBytes;
}
