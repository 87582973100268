import React from 'react';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { LumeoFile } from 'types/file';

import { FileUploadOptions, handleFileUpload } from 'services/file_upload';

import { addErrorNotification } from 'services/notification';
import { useAPI } from 'hooks/api/useAPI';
import { useBillingMutation } from 'hooks/api/useBillingMutation';

export type LumeoUploadFile = Pick<LumeoFile, 'name' | 'size'>;

function useCreateFile(
  options?: UseMutationOptions<LumeoFile, APIError, LumeoUploadFile>
) {
  const api = useAPI();
  return useBillingMutation(api.files.create, {
    ...options,
    action: 'upload',
    entity: 'files',
    onError(error) {
      addErrorNotification({ title: 'Unable to upload file.', error });
    },
  });
}

type FileUploadParams = { file: File; apiFile: LumeoFile } & FileUploadOptions;

export function useUploadFile() {
  const api = useAPI();
  const queryClient = useQueryClient();

  const { mutateAsync: createFile, isLoading: isCreating } = useCreateFile();

  const { mutateAsync, mutate, ...queryResult } = useBillingMutation<
    Response | void,
    APIError,
    FileUploadParams
  >(
    ({ file, apiFile, ...options }) => {
      if (!apiFile) {
        return Promise.reject(new Error('No API file object or upload URL.'));
      }

      return handleFileUpload(apiFile.data_url, file, options);
    },
    {
      action: 'upload',
      entity: 'files',
      onError(error) {
        addErrorNotification({ title: 'Unable to upload file.', error });
      },
    }
  );

  const uploadFile = React.useCallback(
    async (file: File, options?: FileUploadOptions) => {
      try {
        const apiFile = await createFile({
          name: file.name,
          size: file.size,
        });

        await mutateAsync({ file, apiFile, ...options });
        await api.files.updateStatus(apiFile.id, 'uploaded');
        queryClient.invalidateQueries(['files']);
        return apiFile;
      } catch (error) {
        console.error(error);
      }
    },
    [mutateAsync, createFile, api.files, queryClient]
  );

  return {
    ...queryResult,
    uploadFile,
    isLoading: isCreating || queryResult.isLoading,
  };
}
