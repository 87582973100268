import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';
import { VideoSource } from 'hooks/api/useDeploymentsVideoSources';

import {
  VideoSourceSelectLegacy,
  VideoSourceSelectLegacyProps,
} from 'components/VideoSourceSelect/VideoSourceSelectLegacy';

export type VideoSourceSelection = {
  source_id?: string;
  source_type?: string;
};

type ReteVideoSourceSelectProps = Omit<
  VideoSourceSelectLegacyProps,
  'onChange'
> & {
  onChange: (source: VideoSourceSelection | null) => void;
};

export default class ReteVideoSourceSelector extends ReteInput<
  ReteInputProps & ReteVideoSourceSelectProps
> {
  constructor(editor: NodeEditor, key: string, params: ReteInputParams) {
    super(editor, key, { ...params, inputType: 'select' });
  }

  get component() {
    return ReteVideoSourceSelectorComponent;
  }
}

export function ReteVideoSourceSelectorComponent({
  onChange,
  ...props
}: ReteVideoSourceSelectProps) {
  function handleChange(source: VideoSource | null) {
    if (!source) {
      onChange(null);
      return;
    }

    const { source_type, id } = source;
    onChange({ source_type, source_id: String(id) });
  }

  return (
    <VideoSourceSelectLegacy {...props} onChange={handleChange} isClearable />
  );
}
