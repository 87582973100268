import React from 'react';
import classNames from 'classnames/bind';
import { Route, Routes } from 'react-router-dom';

import { Logo } from 'components/Logo/Logo';

import { Accept } from './Accept';
import { Decline } from './Decline';

import styles from '../Auth.module.scss';

const c = classNames.bind(styles);

export function Invite() {
  return (
    <Routes>
      <Route path="accept" element={<Accept />} />
      <Route path="accept_existing" element={<Accept />} />
      <Route path="decline" element={<Decline />} />
    </Routes>
  );
}

export function InviteWrapper({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className={c('wrap')}>
      <div className={c('card', 'auth')}>
        <div className="card-content">
          <Logo className={c('logo')} aria-label="Lumeo logo." />
          {children}
        </div>
      </div>
    </div>
  );
}
