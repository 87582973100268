import React from 'react';

import { ReteInputProps } from 'pipelines/services/rete/controls/input';

import { MetadataListInput } from 'components/MetadataListInput/MetadataListInput';
import { useActivePipelineOptional } from 'pipelines/context/active_pipeline';
import { useCurrentNode } from 'deployments/hooks/useCurrentNode';
import { getSuggestionsFromNodeMetadata } from 'services/get_node_metadata_suggestions';

type MetadataListProps = ReteInputProps;

export function MetadataList({ onChange, value }: MetadataListProps) {
  const { nodeID } = useCurrentNode();
  const pipeline = useActivePipelineOptional();

  const nodesById = pipeline?.nodeGraph;
  const currentNode = nodesById?.get(nodeID);
  const upstreamNodes = currentNode!.allUpstreamNodes();

  const suggestions = getSuggestionsFromNodeMetadata(upstreamNodes);
  const options = Object.values(suggestions).map(({ value }) => value);

  function selected() {
    if (!value) {
      return [];
    }

    return Array.isArray(value) ? value : String(value).split(',');
  }

  function handleChange(value: string[] | null) {
    if (!onChange) {
      return;
    }

    if (!value) {
      onChange(value);
      return;
    }

    onChange(value.join(','));
  }

  return (
    <MetadataListInput
      options={options}
      onChange={handleChange}
      selected={selected()}
    />
  );
}
