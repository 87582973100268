import { unstable_usePrompt } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { useModelFormState } from '../context/useModelFormState';

export function LeavePrompt() {
  const { formState } = useFormContext();
  const { baseUrl } = useModelFormState();

  // FIXME: use useBlocker
  unstable_usePrompt({
    message:
      'This page contains unsaved changes. Are you sure you want to leave?',
    when({ currentLocation, nextLocation }) {
      return (
        formState.isDirty &&
        !formState.isSubmitSuccessful &&
        !(baseUrl && location.pathname.includes(baseUrl)) &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
  });

  return null;
}
