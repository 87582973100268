import Rete, { Node } from 'rete';

import Component from 'pipelines/services/rete/nodes/component';
import { RawVideo } from 'pipelines/services/rete/sockets';

export default class DetectMotion extends Component {
  static key = 'Detect Motion';
  static icon = 'motion' as const;
  static exportType = 'motion_detect';
  static category = 'recognition' as const;
  static description =
    'Detect motion in the input frames and add confidence levels as metadata';

  static release = 'beta';
  static input = {
    type: 'raw',
  };
  static output = {
    type: 'raw',
  };

  static outputMetadata = ['Probability of detected motion'];

  constructor() {
    super(DetectMotion.key);
  }

  async builder(node: Node) {
    node
      .addInput(new Rete.Input('input', 'Input', RawVideo, false))
      .addOutput(new Rete.Output('output', 'Output', RawVideo));
  }
}
