import React from 'react';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';
import Stream from 'types/stream';

import { useAPI } from './useAPI';

export type UseSnapshotResponse = {
  file_id: string;
};

export function useTakeSnapshot(
  source?: Camera | Stream,
  gatewayID?: string,
  options: UseMutationOptions<UseSnapshotResponse, APIError> = {}
) {
  const api = useAPI();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ['snapshot', source?.id],
    () => {
      if (!source) {
        return Promise.reject(
          new Error('No source to take snapshot from was provided.')
        );
      }

      if (gatewayID && !source.gateway_id) {
        source.gateway_id = gatewayID;
      }

      return api.snapshot(source);
    },
    {
      ...options,
      onSettled() {
        if (!source) {
          return;
        }

        const queryKey = [`${source.source_type}`, source.id];
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  // NOTE(dustin) this cannot live inside the `useMutation` because it will
  // be invoked to late as it is called by reactQuery on a later lifecycle.
  // This needs to be invoked immidiately when `takeSnapshot` is called to
  // prevent it from beeing called again multiple times by having the
  // `*_polling` data available now and not later when reactQuery decides to
  // actually invoke the mutation requested by the callee.
  const { mutate } = mutation;
  const mutateWrapper = React.useCallback(() => {
    if (!source?.id) {
      return;
    }

    const queryKey = [`${source.source_type}_polling`, source.id];
    queryClient.setQueryData(queryKey, {
      update_timestamp: Date.now(),
      old_snapshot_file_id: source.snapshot_file_id,
    });

    mutate();
  }, [queryClient, source, mutate]);
  mutation.mutate = mutateWrapper;

  return mutation;
}
