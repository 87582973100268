import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';

import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';

/**
 * Create reference pipelines for a universal bridge.
 */
export function useCreateReferencePipelines({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<
  Camera['reference_pipeline_ids'],
  APIError,
  Camera
> = {}) {
  const queryClient = useQueryClient();
  const { cameras } = useAPI();

  return useMutation(cameras.createReferencePipelines, {
    ...options,
    async onMutate(camera) {
      await queryClient.cancelQueries(['cameras']);
      await queryClient.cancelQueries(['camera', camera.id]);

      onMutate?.(camera);

      return updateQueriesData({
        queryClient,
        listQueryKey: ['cameras'],
        singleQueryKey: ['camera'],
        ids: [camera.id],
        updateData(updatedCamera: Camera) {
          const newReferencePipelines = updatedCamera.reference_pipeline_ids;
          newReferencePipelines.push(...camera.reference_pipeline_ids);
          updatedCamera.reference_pipeline_ids = [
            ...new Set(newReferencePipelines),
          ];
          return updatedCamera;
        },
      });
    },
    async onError(error, camera, context) {
      onError?.(error, camera, context);

      context?.previousLists?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSettled(response, error, camera, ...args) {
      onSettled?.(response, error, camera, ...args);

      queryClient.invalidateQueries(['camera', camera.id]);
      queryClient.invalidateQueries(['cameras']);
    },
  });
}
