import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { updateQueriesData } from 'services/update_queries_data';

import { useAPI } from 'hooks/api/useAPI';
import { useAccount } from 'hooks/api/useAccount';

import APIError from 'types/api_error';
import Account from 'types/account';
import AccountRole, { ApplicationRole } from 'types/account_role';

type CreateApplicationRolesVariables = {
  account: Account;
  newRoles: ApplicationRole[];
};

/** Add application roles to a member */
export function useAddApplicationRoles({
  onMutate,
  onSettled,
  onError,
  ...options
}: UseMutationOptions<void, APIError, CreateApplicationRolesVariables> = {}) {
  const queryClient = useQueryClient();
  const { applicationID, organizations } = useAPI();
  const { data: currentAccount } = useAccount();

  return useMutation(
    ({ account, newRoles }) => {
      if (!account.id) {
        return Promise.reject('Missing account id');
      }

      return organizations.members.addApplicationRoles(account.id, {
        roles: newRoles,
      });
    },
    {
      ...options,
      onMutate({ account, newRoles }) {
        onMutate?.({ account, newRoles });

        if (!account.id) {
          return;
        }

        if (currentAccount && currentAccount.id === account.id) {
          queryClient.cancelQueries(['account']);
        }

        queryClient.cancelQueries(['members']);
        queryClient.cancelQueries(['member', account.id, applicationID]);

        const updatedAccount = account.copy();

        const newAccountRoles: AccountRole[] = newRoles.map((role) => ({
          name: role,
          application_id: applicationID,
        }));

        updatedAccount.roles = [...updatedAccount.roles, ...newAccountRoles];

        return updateQueriesData({
          queryClient,
          listQueryKey: ['members'],
          singleQueryKey: ['member'],
          ids: [account.id],
          updateData: updatedAccount,
        });
      },
      onError(error, variables, context) {
        onError?.(error, variables, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(data, error, { account, newRoles }, context) {
        onSettled?.(data, error, { account, newRoles }, context);

        if (currentAccount && currentAccount.id === account.id) {
          queryClient.invalidateQueries(['account']);
        }

        queryClient.invalidateQueries(['member', account.id, applicationID]);
        queryClient.invalidateQueries(['members']);
      },
    }
  );
}
