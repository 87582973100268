import React from 'react';
import classNames from 'classnames/bind';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';

import { Pill } from 'components/Pill/Pill';

import { PricingPlanLabelLink } from './Link';
import styles from './PricingPlanLabel.module.scss';

const c = classNames.bind(styles);

export type PricingPlanLabelProps = {
  className?: string;
  plan: 'starter' | 'business' | 'custom';
  hasLink?: boolean;
};

export function PricingPlanLabel({ plan, ...props }: PricingPlanLabelProps) {
  const { data: currentPlan, isStarterPlan, isBusinessPlan } = useCurrentPlan();

  if (
    // Starter nodes are available for everyone
    plan === 'starter' ||
    // Custom plans include all nodes
    currentPlan === null
  ) {
    return null;
  }

  if (isStarterPlan) {
    return (
      <PricingPlanLabelLink {...props}>
        <Pill className={c('pricing-plan-label')} intent="warning" size="small">
          Business+
        </Pill>
      </PricingPlanLabelLink>
    );
  }

  if (isBusinessPlan && plan === 'custom') {
    return (
      <PricingPlanLabelLink {...props}>
        <Pill className={c('pricing-plan-label')} intent="warning" size="small">
          Custom+
        </Pill>
      </PricingPlanLabelLink>
    );
  }

  return null;
}
