import { Node as ReteNode } from 'rete';
import { useFormContext } from 'react-hook-form';

import { getPropertyValue } from 'types/node';

export function useKeyRefValue(
  key?: string,
  node?: ReteNode,
  defaultValue?: unknown
): unknown;

export function useKeyRefValue(key?: string, defaultValue?: unknown): unknown;

export function useKeyRefValue(
  key?: string,
  nodeOrDefaultValue?: ReteNode | unknown,
  defaultValue?: unknown
) {
  const { watch } = useFormContext();

  if (!key) {
    return undefined;
  }

  if (nodeOrDefaultValue && 'data' in (nodeOrDefaultValue as ReteNode)) {
    defaultValue =
      getPropertyValue((nodeOrDefaultValue as ReteNode).data, key) ??
      defaultValue;
  }

  return watch(key, defaultValue);
}
