export function getFileExtension(filename: string) {
  // NOTE: String.substr() is only deprecated in node environments.
  return filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
}

export async function readFile(file: File | undefined): Promise<string> {
  if (!file) {
    return Promise.resolve('');
  }

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file, 'UTF-8');

    fileReader.addEventListener('load', (event) => {
      if (!event.target || !event.target.result) {
        reject('Unable to read file.');
        return;
      }

      resolve(event.target.result.toString().trim());
    });

    fileReader.addEventListener('error', () =>
      reject(`Unable to read file ${file.name}.`)
    );
  });
}
