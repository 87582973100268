import React from 'react';
import classNames from 'classnames/bind';

import styles from '../PageLayout.module.scss';

const c = classNames.bind(styles);

export type ActionsProps = React.PropsWithChildren<{
  className?: string;
}>;

export function Actions({ className, children }: ActionsProps) {
  return <div className={c(className, 'actions')}>{children}</div>;
}
