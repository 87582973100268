import React from 'react';
import { useList } from 'react-use';

import { NullModel } from 'types/ai_model';

import { AppEntity, AppEntityObject } from 'hooks/useEntities';
import { objectHas } from 'services/object';

import { AdvancedSearchContext } from './services/AdvancedSearchContext';
import { filterList } from './services/filter';
import { ENTITY_RESOLVER, useSuggestions } from './hooks/useSuggestions';
import {
  AdvancedFilterDispatchAction,
  AdvancedFilterOptions,
  AdvancedFilterState,
  AdvancedSearchRelatedEntityFilterItem,
  AdvancedSearchRelatedEntityFilterList,
} from './AdvancedSearch.types';
import {
  AdvancedSearchFilter as AdvancedSearchFilterCmp,
  AdvancedSearchFilterPublicProps,
} from './components/AdvancedSearchFilter/AdvancedSearchFilter';

const NULL_ENTITY_RESOLVER: EntityMap = {
  models: new NullModel(),
};

type EntityMap = {
  [entity in AppEntity]?: any;
};

export type AdvancedSearchProviderProps<T> = React.PropsWithChildren<{
  data: T[];
  isLoading?: boolean;
  entity: AppEntity;
  filterOptions?: AdvancedFilterOptions<T>;
  onFilteredDataChange?: React.Dispatch<React.SetStateAction<number>>;
}>;

/** @deprecated */
export function AdvancedSearchProvider<T extends AppEntityObject>({
  data,
  isLoading,
  entity,
  filterOptions,
  onFilteredDataChange,
  children,
}: AdvancedSearchProviderProps<T>) {
  const baseEntity = NULL_ENTITY_RESOLVER[entity] as T;

  const [isSearching, setIsSearching] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState<T[]>(data);

  /** User input in search field to control suggestions */
  const [searchInput, setSearchInput] = React.useState<string>('');

  /** Plain-text search directly in top-level list */
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  /** Entity filters applied to data */
  const [relatedEntityFilters, { push, removeAt }] = useList<
    AdvancedSearchRelatedEntityFilterItem<T>
  >([]);

  const filterRef = React.useRef<AdvancedFilterState>({});
  const [filters, dispatchFilters] = React.useReducer(filterReducer, {});
  const [filterTracker, forceFilter] = React.useReducer(() => {
    filterRef.current = filters;
    return {};
  }, {});

  const { suggestions, findSuggestions, suggestionsCount } = useSuggestions<T>(
    baseEntity,
    relatedEntityFilters,
    data
  );
  const suggestionTimeout = React.useRef<number>();

  React.useEffect(() => setIsSearching(true), [searchInput]);

  React.useEffect(() => {
    window.clearTimeout(suggestionTimeout.current);

    suggestionTimeout.current = window.setTimeout(() => {
      if (searchInput && searchInput.trim().length >= 2) {
        findSuggestions(searchInput);
        setSearchInput(searchInput);
        setIsSearching(false);
      } else {
        findSuggestions();
        setIsSearching(false);
      }
    }, 200);

    return () => {
      window.clearTimeout(suggestionTimeout.current);
    };
  }, [searchInput, findSuggestions]);

  React.useEffect(() => {
    const filterResults = filterList(baseEntity, data, relatedEntityFilters, {
      searchTerm,
      filters: filterRef.current,
    }) as T[];
    setFilteredData(filterResults);
  }, [baseEntity, data, relatedEntityFilters, searchTerm, filterTracker]);

  React.useEffect(() => {
    onFilteredDataChange?.(filteredData.length);
  }, [filteredData, onFilteredDataChange]);

  const handleSearch = React.useCallback(() => {
    setSearchTerm(searchInput.trim());
  }, [searchInput]);

  const handleFilter = React.useCallback(
    (
      property: keyof T,
      filter: AdvancedSearchRelatedEntityFilterItem<T>['filter']
    ) => {
      push({ property, filter });
      setSearchInput('');
    },
    [push]
  );

  const handleRemoveFilter = React.useCallback(
    (property?: keyof T) => {
      if (!property) {
        removeAt(relatedEntityFilters.length - 1);
      } else {
        removeAt(
          relatedEntityFilters.findIndex(
            (filter) => filter.property === property
          )
        );
      }
    },
    [removeAt, relatedEntityFilters]
  );

  const getPropertyFromEntityName = React.useCallback(
    (entity: AppEntity) => {
      return Object.keys(ENTITY_RESOLVER).find(
        (property) =>
          ENTITY_RESOLVER[property] === entity &&
          objectHas(baseEntity, property)
      ) as keyof AppEntityObject;
    },
    [baseEntity]
  );

  const AdvancedSearchFilter = React.useCallback(
    (props: AdvancedSearchFilterPublicProps) => {
      return <AdvancedSearchFilterCmp {...props} />;
    },
    []
  );

  return (
    <AdvancedSearchContext.Provider
      value={{
        data,
        filteredData,
        isLoading,
        isSearching,
        isEmpty: !data?.length,
        suggestions,
        suggestionsCount,
        searchInput,
        setSearchInput,
        handleSearch,

        relatedEntityFilters:
          relatedEntityFilters as AdvancedSearchRelatedEntityFilterList<AppEntityObject>,
        setRelatedEntityFilter: handleFilter,
        removeRelatedEntityFilter: handleRemoveFilter,
        getPropertyFromEntityName,

        filters,
        dispatchFilters,
        handleApplyFilters: forceFilter,
        filterOptions: filterOptions as AdvancedFilterOptions<AppEntityObject>,
        AdvancedSearchFilter,
      }}
    >
      {children}
    </AdvancedSearchContext.Provider>
  );
}

function filterReducer(
  state: AdvancedFilterState,
  action: AdvancedFilterDispatchAction
) {
  const value = action.value === undefined ? true : action.value;

  return {
    ...state,
    [action.property]: {
      ...state[action.property],
      [action.key]: value,
    },
  };
}
