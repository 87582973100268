import React from 'react';

import { ExternalLink } from 'components/ExternalLink/ExternalLink';

export function customMarkdownAnchor({
  children,
  ...props
}: React.PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>>) {
  return <ExternalLink {...props}>{children}</ExternalLink>;
}
