import React from 'react';
import classnames from 'classnames/bind';
import { FormProvider } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';

import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';

import { VideoSource } from 'hooks/api/useVideoSources';
import { useAPI } from 'hooks/api/useAPI';
import { useCameras } from 'cameras/hooks/useCameras';
import { useCreateDeployment } from 'hooks/api/useDeployment';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useFileStreams } from 'files/hooks/useFileStreams';
import { useGateways } from 'hooks/api/useGateways';
import { useInputStreams } from 'streams/hooks/useInputStreams';
import { usePipeline } from 'hooks/api/usePipeline';
import { usePipelines } from 'pipelines/hooks/usePipelines';
import { useUsageStatistics } from 'organizations/hooks/useUsageStatistics';

import { ActivePipelineContext } from 'pipelines/context/active_pipeline';

import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { PipelineSelect } from 'pipelines/components/PipelineSelect/PipelineSelect';
import { Separator } from 'components/Separator/Separator';
import { Text } from 'components/Text/Text';

import { CannotDeployInfo } from '../DeployabilityModal/CannotDeployInfo';
import { DeploymentFormActions } from './Actions';
import { DeploymentFormContent, DeploymentFormContentRef } from './Content';
import { DeploymentFormErrorMessage } from './DeploymentFormErrorMessage';
import { DeploymentFormProvider } from './context/deployment_form_provider';
import { useDeploymentFormContent } from './useDeploymentFormContent';
import styles from './Form.module.scss';

const c = classnames.bind(styles);

export type NewDeploymentFormProps = {
  className?: string;
  pipelineId?: Pipeline['id'];
  allowedStreamIds?: string[];
  allowedCameraIds?: string[];
  initialDeployment?: Deployment;
  onSuccess?: (deployment: Deployment) => void;
  onLink?: () => void;
};

function NewDeploymentForm({
  className,
  pipelineId: initialPipelineId,
  allowedStreamIds,
  allowedCameraIds,
  initialDeployment,
  onSuccess,
  onLink,
}: NewDeploymentFormProps) {
  const location = useLocation();

  const { applicationID } = useAPI();
  const { data: organization } = useCurrentOrganization();

  const { data: pipelines, isLoading: isLoadingPipelines } = usePipelines();
  const { data: gateways, isLoading: isLoadingGateways } = useGateways();
  const { data: cameras, isLoading: isLoadingCameras } = useCameras();
  const { data: inputStreams, isLoading: isLoadingInputStreams } =
    useInputStreams();
  const { data: fileStreams, isLoading: isLoadingFileStreams } =
    useFileStreams();

  const isLoadingCanDeploy =
    isLoadingPipelines ||
    isLoadingGateways ||
    isLoadingCameras ||
    isLoadingInputStreams ||
    isLoadingFileStreams;

  const hasPipelines = Boolean(pipelines?.total_elements);
  const hasGateways = Boolean(gateways?.total_elements);
  const hasCameras = Boolean(cameras?.total_elements);
  const hasInputStreams = Boolean(inputStreams?.total_elements);
  const hasFileStreams = Boolean(fileStreams?.total_elements);
  const hasInputSources = hasCameras || hasInputStreams || hasFileStreams;
  const canDeploy = hasPipelines && hasGateways && hasInputSources;

  const { isLoading: isLoadingStatistics, data: usageStatistics } =
    useUsageStatistics();
  const { isLoading: isLoadingPlan, data: currentPlan } = useCurrentPlan();

  const [pipelineId, setPipelineId] = React.useState(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('pipeline_id') ?? initialPipelineId;
  });

  const { data: pipeline, isLoading: isLoadingPipeline } =
    usePipeline(pipelineId);

  const [pipelineError, setPipelineError] = React.useState<string>();
  const [gateway, setGateway] = React.useState<Gateway | null>(null);
  const [gatewayError, setGatewayError] = React.useState<string>();

  const [shouldStartImmediately, toggleShouldStartImmediately] =
    React.useState(true);

  const formContentRef = React.useRef<DeploymentFormContentRef>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const {
    mutate: createDeployment,
    error,
    isLoading,
  } = useCreateDeployment({
    onError: () => containerRef.current?.scrollIntoView(),
    onSuccess,
  });

  async function handleSubmit() {
    setGatewayError(undefined);
    setPipelineError(undefined);

    if (!formContentRef.current || !applicationID) {
      return;
    }

    if (!gateway) {
      setGatewayError('Please select a gateway.');
      return;
    }

    if (!pipeline) {
      setPipelineError('Please select a pipeline.');
      return;
    }

    try {
      const { configuration } = await formContentRef.current.onSubmit();
      const deployment = new Deployment(
        '',
        applicationID,
        pipeline.id,
        gateway.id,
        undefined,
        configuration
      );
      deployment.state = shouldStartImmediately ? 'running' : 'stopped';

      createDeployment(deployment);
    } catch (error) {
      // Do nothing. Errors are displayed inline.
    }
  }

  function handlePipelineSelect(pipeline: Pipeline | null) {
    setPipelineId(pipeline?.id);
    setPipelineError(undefined);
  }

  if (isLoadingCanDeploy || isLoadingPlan || isLoadingStatistics) {
    return <>Loading...</>;
  }

  if (!canDeploy) {
    return (
      <CannotDeployInfo
        hasGateways={hasGateways}
        hasInputSources={hasInputSources}
        hasPipelines={hasPipelines}
      />
    );
  }

  const isExceedingDeploymentCount =
    usageStatistics &&
    currentPlan?.running_deployment_limit &&
    usageStatistics.deployment_count >= currentPlan.running_deployment_limit;

  if (isExceedingDeploymentCount) {
    return (
      <div className={c('form-warning')}>
        <Icon
          className={c('form-warning-icon', 'theme warning')}
          size="large"
          name="warning"
        />
        <Heading level="3">
          Please stop a deployment or{' '}
          <Link
            className="link"
            to={`/applications/${applicationID}/settings/organization/${organization?.id}/plans`}
            onClick={() => onLink?.()}
          >
            upgrade your plan
          </Link>{' '}
          before starting another deployment.
        </Heading>
        <Text>
          Your plan is limited to {currentPlan.running_deployment_limit}{' '}
          concurrent deployments.
        </Text>
      </div>
    );
  }

  return (
    <DeploymentFormProvider
      pipeline={pipeline}
      allowedStreamIds={allowedStreamIds}
      allowedCameraIds={allowedCameraIds}
    >
      <div
        id="up-new-deployment"
        className={c('container', className)}
        ref={containerRef}
      >
        {!initialPipelineId && (
          <section className={c('header')}>
            {pipelineId && isLoadingPipeline && !pipeline ? (
              <span className="skeleton-bar" />
            ) : (
              <div className={c({ error: pipelineError })}>
                <PipelineSelect
                  id="up-new-deployment_pipeline"
                  onChange={handlePipelineSelect}
                  isLoading={isLoadingPipeline}
                  value={pipeline}
                />
              </div>
            )}

            {pipelineError && <Text intent="danger">{pipelineError}</Text>}

            <Separator />
          </section>
        )}

        <section id="up-new-deployment_content" className={c('body')}>
          {!pipeline ? (
            <Text>Select a pipeline you want to deploy.</Text>
          ) : (
            <div
              className={c('field')}
              aria-label="Configure deployment parameters"
            >
              <ActivePipelineContext.Provider value={pipeline}>
                {error && <DeploymentFormErrorMessage error={error} />}

                <FormContent deployment={initialDeployment}>
                  <DeploymentFormContent
                    key={`${pipeline.id}_content`}
                    ref={formContentRef}
                    onLink={onLink}
                  />
                </FormContent>
              </ActivePipelineContext.Provider>
            </div>
          )}
        </section>
        <DeploymentFormActions
          pipeline={pipeline}
          startImmediately={shouldStartImmediately}
          onToggleStartImmediately={toggleShouldStartImmediately}
          gateway={gateway}
          gatewayError={gatewayError}
          onGatewayChange={setGateway}
          isDeploying={isLoading}
          onSubmit={handleSubmit}
        />
      </div>
    </DeploymentFormProvider>
  );
}

function areEqual(prev: NewDeploymentFormProps, next: NewDeploymentFormProps) {
  return prev.pipelineId === next.pipelineId;
}

const Memoized = React.memo(NewDeploymentForm, areEqual);

export { Memoized as NewDeploymentForm };

type FormContentProps = React.PropsWithChildren<{
  deployment?: Deployment;
  videoSource?: VideoSource;
}>;

function FormContent({ deployment, videoSource, children }: FormContentProps) {
  const formMethods = useDeploymentFormContent(deployment, videoSource);
  return <FormProvider {...formMethods}>{children}</FormProvider>;
}
