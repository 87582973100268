import classnames from 'classnames/bind';

import Stream from 'types/stream';

import { useModal } from 'hooks/useModal';

import { IconButton } from 'components/IconButton/IconButton';

import styles from './MediaPreview.module.scss';

const c = classnames.bind(styles);

type MediaPreviewProps = {
  className?: string;
  stream?: Stream;
};

/** @deprecated */
export function MediaPreview({ className, stream }: MediaPreviewProps) {
  const { open } = useModal();

  function handlePlayClick() {
    if (!stream) {
      return;
    }

    open('stream', { id: stream.id });
  }

  let streamStatusText = '';
  if (stream) {
    if (stream.isStreamable) {
      if (stream.status !== 'online') {
        streamStatusText = 'Offline';
      }
    } else {
      streamStatusText = 'Streaming unavailable';
    }
  } else {
    streamStatusText = 'No stream available';
  }

  return (
    <div
      className={c(
        className,
        'thumbnail',
        'theme dark',
        stream ? stream.status : 'offline'
      )}
    >
      {stream && stream.isStreamable && stream.status === 'online' ? (
        <>
          <div className={c('image')} />
          <div className={c('play')} onClick={handlePlayClick}>
            <IconButton icon="play" label="View stream" variant="tertiary" />
          </div>
        </>
      ) : (
        <span className={c('offline-info')}>{streamStatusText}</span>
      )}
    </div>
  );
}

export type MediaPreviewSkeletonProps = {
  className?: string;
};

export function MediaPreviewSkeleton({ className }: MediaPreviewSkeletonProps) {
  return <div className={c(className, 'thumbnail', 'offline')} />;
}
