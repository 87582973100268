import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import OrganizationUsageLimits from 'types/organization_usage_limits';

import { useMutationWithError } from 'hooks/api/useMutationWithError';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useAPI } from 'hooks/api/useAPI';

import { useUsageLimits } from './useUsageLimits';

export function useUpdateUsageLimits({
  onMutate,
  onSettled,
  onError,
  ...options
}: UseMutationOptions<
  OrganizationUsageLimits | null,
  APIError,
  Partial<OrganizationUsageLimits>
> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  const { data: organization } = useCurrentOrganization();
  const { fileProcessingMinutes, monthlyActiveLiveStreams, cloudStorageBytes } =
    useUsageLimits();

  const queryKey = ['usage_limits', 'org', organization?.id];

  return useMutationWithError<
    OrganizationUsageLimits | null,
    Partial<OrganizationUsageLimits>,
    { previousUsageLimits: OrganizationUsageLimits | undefined }
  >(
    (partialUsageLimits: Partial<OrganizationUsageLimits>) => {
      if (!organization) {
        return Promise.reject(new Error('No organization.'));
      }

      return api.organizations.updateUsageLimits(organization.id, {
        file_processing_minutes_limit: fileProcessingMinutes.userLimit ?? null,
        monthly_active_live_stream_limit:
          monthlyActiveLiveStreams.userLimit ?? null,
        cloud_storage_bytes_limit: cloudStorageBytes.userLimit ?? null,
        ...partialUsageLimits,
      });
    },
    {
      ...options,
      action: 'update',
      entity: 'utilization limits',
      async onMutate(partialUsageLimits) {
        await queryClient.cancelQueries(queryKey);
        const previousUsageLimits =
          queryClient.getQueryData<OrganizationUsageLimits>(queryKey);

        if (previousUsageLimits) {
          const {
            file_processing_minutes_limit,
            cloud_storage_bytes_limit,
            monthly_active_live_stream_limit,
          } = previousUsageLimits;

          queryClient.setQueryData<OrganizationUsageLimits>(queryKey, {
            file_processing_minutes_limit,
            monthly_active_live_stream_limit,
            cloud_storage_bytes_limit,
            ...partialUsageLimits,
          });
        }

        if (onMutate) {
          onMutate(partialUsageLimits);
        }

        return { previousUsageLimits };
      },
      async onError(error, partialUsageLimits, context) {
        if (context) {
          queryClient.setQueryData(queryKey, context.previousUsageLimits);
        }

        if (onError) {
          onError(error, partialUsageLimits, context);
        }
      },
      onSettled(...args) {
        if (onSettled) {
          onSettled(...args);
        }

        queryClient.invalidateQueries(queryKey);
      },
    }
  );
}
