import React from 'react';
import classNames from 'classnames/bind';
import { CellProps, Column } from 'react-table';

import Pipeline from 'types/pipeline';

import { relativeTimeFromNow } from 'services/date';
import {
  usePipelines,
  UsePipelinesFilterParams,
} from 'pipelines/hooks/usePipelines';

import { Heading } from 'components/Heading/Heading';
import { PaginatedTable } from 'components/Table/PaginatedTable';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Text } from 'components/Text/Text';

import styles from '../RapidDeploy.module.scss';

const c = classNames.bind(styles);

export type RapidDeployPipelinesListProps = {
  onSelect: (pipeline: Pipeline) => void;
};

const PAGE_SIZE = 20;

const columns: Column<Pipeline>[] = [
  {
    Cell({ row: { original } }: CellProps<Pipeline>) {
      return (
        <Heading level="4" asChild>
          <strong>{original.name}</strong>
        </Heading>
      );
    },
    accessor: 'name',
  },
  {
    Cell({ row: { original } }: CellProps<Pipeline>) {
      return <Text>{relativeTimeFromNow(original.updated_at)}</Text>;
    },
    width: 0,
    accessor: 'updated_at',
  },
];

export function RapidDeployPipelinesList({
  onSelect,
}: RapidDeployPipelinesListProps) {
  const [filters, setFilters] = React.useState<UsePipelinesFilterParams>({
    page: 1,
  });
  const queryResult = usePipelines(
    { ...filters, limit: PAGE_SIZE },
    { keepPreviousData: true }
  );

  const [searchFilters, setSearchFilters] =
    React.useState<UsePipelinesFilterParams>({});
  const searchQueryResult = usePipelines(
    ['pipelines-suggestions'],
    { ...searchFilters, limit: 20 },
    { enabled: Object.keys(searchFilters).length > 0, keepPreviousData: false }
  );

  function handleSearchInput(value?: string) {
    if (!value) {
      setSearchFilters({});
      setFilters({ page: 1 });
      return;
    }

    setSearchFilters({
      pipeline_names: [value],
    });
  }

  const { isLoading } = queryResult;

  function handleApplyFilters() {
    setFilters({ ...searchFilters, page: 1 });
  }

  function handlePageChange(page: number) {
    setFilters((previousFilters) => ({
      ...previousFilters,
      page,
    }));
  }

  return (
    <div className={c('rapid-deploy')}>
      <QuerySearch
        entityName="pipeline"
        searchLabel="Search pipelines..."
        searchResultField="name"
        searchQueryResult={searchQueryResult}
        onApplyFilters={handleApplyFilters}
        onSuggestionSelect={onSelect}
        onValueChange={handleSearchInput}
        totalElements={queryResult.data?.total_elements}
      />

      {isLoading ? (
        <div className={c('table', 'full-width')}>
          <SkeletonList component={<LoaderRow />} />
        </div>
      ) : (
        <PaginatedTable<Pipeline>
          className={c('table', 'full-width')}
          id="rapid-deploy-pipelines"
          label="Pipelines"
          queryResult={queryResult}
          columns={columns}
          pageSize={PAGE_SIZE}
          onRowClick={onSelect}
          page={filters.page}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
}

function LoaderRow() {
  return (
    <div className={c('loader-table-row')}>
      <div className={c('loader-table-cell')}>
        <Heading level="4" asChild>
          <span className="skeleton-text" />
        </Heading>
      </div>

      <div className={c('loader-table-cell', 'auto')}>
        <Text asChild>
          <span className="skeleton-text" aria-hidden="true">
            21 hours ago
          </span>
        </Text>
      </div>
    </div>
  );
}
