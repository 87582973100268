import React from 'react';
import ReactSelect, {
  components,
  GroupBase,
  MenuProps,
  MultiValue,
  Props,
  SingleValue,
  StylesConfig,
} from 'react-select';
import classNames from 'classnames/bind';

import {
  ClearIndicator,
  Control,
  DropdownIndicator,
  IndicatorsContainer,
  IndicatorSeparator,
  LoadingIndicator,
  Option,
} from './components';
import styles from './Select.module.scss';

export const c = classNames.bind(styles);

export function getDefaultSelectComponents<
  OptionType,
  MultiSelect extends boolean = false,
>(): Props<OptionType, MultiSelect>['components'] {
  return {
    ClearIndicator,
    Control,
    DropdownIndicator,
    IndicatorsContainer,
    IndicatorSeparator,
    LoadingIndicator,
    Option,
  };
}

export function getSelectStyles<
  OptionType,
  IsMulti extends boolean = false,
>(): StylesConfig<OptionType, IsMulti, GroupBase<OptionType>> {
  return {
    container: (provided) => ({
      ...provided,
      maxWidth: '100%',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingRight: 0,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'hsl(var(--ui-text-placeholder))',
    }),
    input: (provided) => ({
      ...provided,
      color: 'hsl(var(--ui-text-highContrast))',
      margin: 0,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'hsl(var(--ui-fg))',
      width: 'max-content',
      maxWidth: 400,
      minWidth: '100%',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 4,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 999,
      pointerEvents: 'auto',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'hsl(var(--ui-text-highContrast))',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'hsl(var(--ui-control-bg))',
      borderRadius: 'var(--radius-lg)',
      maxWidth: '15rem',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      borderRadius: 'var(--radius-lg)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'hsl(var(--ui-text-highContrast))',
    }),
  };
}

export type SelectProps<
  OptionType,
  MultiSelect extends boolean = false,
> = Props<OptionType, MultiSelect> & {
  totalElements?: number;
  size?: 'small';
};

export function Select<OptionType, MultiSelect extends boolean = false>({
  className,
  menuPortalTarget = document.body,
  components: overrideComponents,
  styles,
  size,
  isDisabled,
  totalElements,
  options,
  placeholder,
  ...props
}: SelectProps<OptionType, MultiSelect>) {
  function Menu<OptionType, MultiSelect extends boolean = false>({
    children,
    ...props
  }: React.PropsWithChildren<MenuProps<OptionType, MultiSelect>>) {
    return (
      <components.Menu {...props} className={c('menu')}>
        {Number.isFinite(totalElements) && options && (
          <div className={c('menu-header')}>
            Showing {options.length} of {totalElements} results
          </div>
        )}
        {children}
      </components.Menu>
    );
  }

  return (
    <ReactSelect
      {...props}
      className={c(className, size, { disabled: isDisabled })}
      placeholder={placeholder}
      options={options}
      menuPortalTarget={menuPortalTarget}
      menuPlacement="auto"
      isDisabled={isDisabled}
      noOptionsMessage={({ inputValue }) =>
        inputValue
          ? `No options found for "${inputValue.trim()}".`
          : 'No options.'
      }
      styles={{
        ...getSelectStyles<OptionType, MultiSelect>(),
        ...styles,
      }}
      components={{
        ...getDefaultSelectComponents<OptionType, MultiSelect>(),
        Menu,
        ...overrideComponents,
      }}
    />
  );
}

export {
  ClearIndicator as SelectClearIndicator,
  Control as SelectControl,
  DropdownIndicator as SelectDropdownIndicator,
  IndicatorSeparator as SelectIndicatorSeparator,
  LoadingIndicator as SelectLoadingIndicator,
  Option as SelectOption,
};

export function isMultiOption<Option>(
  option: MultiValue<Option> | SingleValue<Option>
): option is MultiValue<Option> {
  return Array.isArray(option);
}
