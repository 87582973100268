import React from 'react';

import Pipeline from 'types/pipeline';
import PipelineContext from 'services/pipeline_context';
import { PipelineDetailContext } from './pipeline_detail_view';

export const ActivePipelineContext = React.createContext<Pipeline | undefined>(
  undefined
);

export function useActivePipelineOptional() {
  const context = React.useContext(PipelineContext);
  const pipeline = React.useContext(ActivePipelineContext);
  const detailContext = React.useContext(PipelineDetailContext);

  return context.pipeline || detailContext?.pipeline || pipeline;
}

export function useActivePipeline() {
  const pipeline = useActivePipelineOptional();

  if (pipeline === undefined) {
    throw new Error(
      'Hook `useActivePipeline` can only be used within Rete nodes, the `PipelineNodeEditor` component, within an `ActivePipelineContext.Provider`, or `PipelineContext.Provider`.'
    );
  }

  return pipeline;
}
