import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Application from 'types/application';

import { useAPI } from 'hooks/api/useAPI';

export function useUpdateApplication({
  onSettled,
  ...options
}: UseMutationOptions<Application, APIError, Application> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation(api.applications.update, {
    ...options,
    onSettled(data, error, application, context) {
      queryClient.invalidateQueries(['applications']);
      queryClient.invalidateQueries(['application', application.id]);
      onSettled?.(data, error, application, context);
    },
  });
}
