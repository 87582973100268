import React from 'react';
import classNames from 'classnames/bind';
import { DatesRangeValue } from '@mantine/dates';
import { MultiValue } from 'react-select';
import { useDebouncedValue } from '@mantine/hooks';

import Camera from 'types/camera';
import Gateway from 'types/gateway';
import Stream, { StreamType } from 'types/stream';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import {
  FilesSearchEntity,
  UseFilesSearchParams,
  useFilesSearch,
} from 'files/hooks/useFilesSearch';
import { useFiles } from 'files/hooks/useFiles';

import {
  DateRangePreset,
  getPresetDateRange,
} from 'dashboards/services/time_range';

import * as PageLayout from 'components/PageLayout';
import { CameraSelect } from 'cameras/components/CameraSelect/CameraSelect';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { DateRangePickerInput } from 'components/DateRangePickerInput/DateRangePickerInput';
import { FilesSearchConfidenceSlider } from 'files/components/FilesSearchConfidenceSlider/FilesSearchConfidenceSlider';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { OptionalTooltip } from 'components/Tooltip/OptionalTooltip';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { Radio, RadioOption } from 'components/Radio/Radio';
import { StreamSelect } from 'streams/components/StreamSelect/StreamSelect';

import { FilesBulkManager } from './BulkManager';
import { FilesSearchProvider } from './Provider';
import { FilesSearchResults } from './Results';
import { FilesSearchUpgradeNotice } from './UpgradeNotice';
import styles from './Search.module.scss';

const c = classNames.bind(styles);

const DEFAULT_DATE_PRESET: DateRangePreset['preset'] = 'THIS_WEEK';

export function FilesSearch() {
  const { data: currentPlan } = useCurrentPlan();

  const [filters, setFilters] = React.useState<
    UseFilesSearchParams & {
      search_entities: FilesSearchEntity[];
    }
  >(() => {
    const [since, until] = getPresetDateRange(DEFAULT_DATE_PRESET);

    return {
      page: 1,
      created_ts_since: since.toISOString(),
      created_ts_until: until.toISOString(),
      search_entities: ['events', 'objects', 'texts', 'vectors'],
      confidence: 0.5,
      sort_by: 'created_at',
      // Custom limit to allow for neat grids on common screen sizes
      limit: 48,
    };
  });
  const [debouncedFilters] = useDebouncedValue(filters, 200);

  const isSearching = Boolean(filters.query);

  const filesSearchQueryResult = useFilesSearch(debouncedFilters, {
    keepPreviousData: true,
    enabled: isSearching && currentPlan?.can_search,
  });

  const filesQueryResult = useFiles({
    params: {
      ...debouncedFilters,
      with_thumbnail: true,
      include_snapshots: false,
    },
    keepPreviousData: true,
    enabled: !isSearching,
  });

  function handleApplySearchText(value?: string) {
    setFilters(({ query, ...previousFilters }) => {
      return value
        ? { ...previousFilters, query: value, sort_by: 'relevance', page: 1 }
        : { ...previousFilters, sort_by: 'created_at', page: 1 };
    });
  }

  function handleDateRangeChange(dateRange?: DatesRangeValue) {
    if (!dateRange) {
      return;
    }

    const [since, until] = dateRange;

    if (!since || !until) {
      return;
    }

    setFilters((previousFilters) => ({
      ...previousFilters,
      page: 1,
      created_ts_since: since.toISOString(),
      created_ts_until: until.toISOString(),
    }));
  }

  function handleCamerasChange(cameras: MultiValue<Camera>) {
    setFilters((previousFilters) => ({
      ...previousFilters,
      camera_ids: cameras.map(({ id }) => id),
      page: 1,
    }));
  }

  function handleStreamsChange(streams: MultiValue<Stream>) {
    setFilters((previousFilters) => ({
      ...previousFilters,
      stream_ids: streams.map(({ id }) => id),
      page: 1,
    }));
  }

  function handleGatewaysChange(gateways: MultiValue<Gateway>) {
    setFilters((previousFilters) => ({
      ...previousFilters,
      gateway_ids: gateways.map(({ id }) => id),
      page: 1,
    }));
  }

  function handleSearchEntitiesChange({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement> & {
    target: { value: FilesSearchEntity };
  }) {
    if (checked) {
      setFilters(({ confidence, search_entities, ...previousFilters }) => ({
        ...previousFilters,
        page: 1,
        search_entities: Array.from(new Set([...search_entities, value])),
      }));
    } else {
      setFilters(({ confidence, search_entities, ...previousFilters }) => {
        const newSearchEntities = [...search_entities].filter(
          (entity) => entity !== value
        );

        return {
          ...previousFilters,
          page: 1,
          search_entities: newSearchEntities,
          // Remove confidence if vectors filter has been removed
          confidence: newSearchEntities.includes('vectors')
            ? confidence
            : undefined,
        };
      });
    }
  }

  function handleConfidenceChange(confidence: number) {
    setFilters((previousFilters) => ({
      ...previousFilters,
      page: 1,
      confidence,
    }));
  }

  function handleSortChange(sort: string) {
    if (sort === 'created_at' || sort === 'relevance') {
      setFilters((previousFilters) => ({
        ...previousFilters,
        page: 1,
        sort_by: sort,
      }));
    }
  }

  function handlePageChange(page: number) {
    setFilters((previousFilters) => ({ ...previousFilters, page }));
  }

  function getAllIdsOnPage() {
    if (isSearching && currentPlan?.can_search) {
      return filesSearchQueryResult.data?.data.map(({ file_id }) => file_id);
    }

    return filesQueryResult.data?.data.map(({ id }) => id);
  }

  const searchEntities = filters.search_entities;
  const isConfidenceSliderDisabled = !Boolean(
    searchEntities.length && searchEntities.includes('vectors')
  );

  const canSearch = currentPlan && currentPlan.can_search;
  const shouldShowUpgradeNotice = !canSearch && isSearching;

  const queryResult =
    canSearch && isSearching ? filesSearchQueryResult : filesQueryResult;

  return (
    <PageLayout.Root className={c('wrap')}>
      <QuerySearch
        value={filters.query ?? ''}
        orientation="vertical"
        entityName="file"
        onValueChange={handleApplySearchText}
        filters={
          <div className={c('filters')}>
            <DateRangePickerInput
              defaultValue={DEFAULT_DATE_PRESET}
              onChange={handleDateRangeChange}
              allowSingleDateInRange
              disabled={shouldShowUpgradeNotice}
            />

            <div className={c('filters-group')}>
              <CameraSelect
                size="small"
                onChange={handleCamerasChange}
                isMulti
                isDisabled={shouldShowUpgradeNotice}
              />

              <StreamSelect
                size="small"
                onChange={handleStreamsChange}
                queryFilters={{
                  sources: ['camera_stream', 'uri_stream'],
                  stream_types: [StreamType.FILE, StreamType.RTSP],
                }}
                isMulti
                isDisabled={shouldShowUpgradeNotice}
              />

              <GatewaySelect
                size="small"
                onChange={handleGatewaysChange}
                isMulti
                isDisabled={shouldShowUpgradeNotice}
              />
            </div>

            <div className={c('filters-group')}>
              <strong>Search in</strong>
              <Checkbox
                id="search_entities_objects"
                name="search_entities"
                value="objects"
                label="Objects"
                checked={searchEntities.includes('objects')}
                onChange={handleSearchEntitiesChange}
                disabled={!isSearching || shouldShowUpgradeNotice}
              />
              <Checkbox
                id="search_entities_events"
                name="search_entities"
                value="events"
                label="Events"
                checked={searchEntities.includes('events')}
                onChange={handleSearchEntitiesChange}
                disabled={!isSearching || shouldShowUpgradeNotice}
              />
              <Checkbox
                id="search_entities_texts"
                name="search_entities"
                value="texts"
                label="Text"
                checked={searchEntities.includes('texts')}
                onChange={handleSearchEntitiesChange}
                disabled={!isSearching || shouldShowUpgradeNotice}
              />
              <Checkbox
                id="search_entities_vectors"
                name="search_entities"
                value="vectors"
                label="Scenes"
                checked={searchEntities.includes('vectors')}
                onChange={handleSearchEntitiesChange}
                disabled={!isSearching || shouldShowUpgradeNotice}
              />
            </div>

            <OptionalTooltip
              className={c('filters-confidence-wrap')}
              showTooltip={isConfidenceSliderDisabled}
              content="Enable scenes search to set confidence"
            >
              <FilesSearchConfidenceSlider
                onChangeEnd={handleConfidenceChange}
                disabled={
                  isConfidenceSliderDisabled ||
                  !isSearching ||
                  shouldShowUpgradeNotice
                }
              />
            </OptionalTooltip>

            <div className={c('filters-group')}>
              <label htmlFor="search_sort">
                <strong>Sort by</strong>
              </label>
              <Radio
                id="search_sort"
                value={filters.sort_by}
                onChange={(value) =>
                  value && typeof value === 'string' && handleSortChange(value)
                }
                disabled={!isSearching || shouldShowUpgradeNotice}
              >
                <RadioOption value="relevance">Relevance</RadioOption>
                <RadioOption value="created_at">Date</RadioOption>
              </Radio>
            </div>
          </div>
        }
      />
      <FilesSearchProvider>
        <PageLayout.Content>
          {shouldShowUpgradeNotice ? (
            <div className={c('info')}>
              <FilesSearchUpgradeNotice
                onCancel={() => {
                  setFilters(({ ...previousFilters }) => ({
                    ...previousFilters,
                    query: '',
                    sort_by: 'created_at',
                    page: 1,
                  }));
                }}
              />
            </div>
          ) : (
            <FilesSearchResults queryResult={queryResult} />
          )}
        </PageLayout.Content>

        {queryResult.data && (
          <FilesBulkManager
            total={queryResult.data.total_pages}
            page={filters.page}
            onPageChange={handlePageChange}
            onSelectAllOnPage={getAllIdsOnPage}
          />
        )}
      </FilesSearchProvider>
    </PageLayout.Root>
  );
}
