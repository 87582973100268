import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import AIModel from 'types/ai_model';

import { useActivePipelineOptional } from 'pipelines/context/active_pipeline';

import { DisplayerProps } from '../controls/input';

export function ModelIDDisplayer({ node, value }: DisplayerProps): JSX.Element {
  const queryClient = useQueryClient();
  const pipeline = useActivePipelineOptional();
  const applicationID = pipeline?.application_id;

  const modelID = value || node.data.model_id;

  const userModels = React.useMemo(
    () => queryClient.getQueryData<AIModel[]>(['models', applicationID]),
    [queryClient, applicationID]
  );
  const marketplaceModels = React.useMemo(
    () => queryClient.getQueryData<AIModel[]>(['marketplace-models']),
    [queryClient]
  );

  const models = userModels?.concat(marketplaceModels || []);
  const model = models?.find(({ id }) => id === modelID);

  return model || modelID ? (
    <strong>{model?.name || modelID}</strong>
  ) : (
    <span>none</span>
  );
}

export function InferenceDisplayer({
  node,
  value,
}: DisplayerProps): JSX.Element {
  value = value || node.data.infer_on_node;
  return value ? <strong>{value}</strong> : <span>none</span>;
}

export function IntervalDisplayer({
  node,
  value,
}: DisplayerProps): JSX.Element {
  const interval = value || node.data.inference_interval;
  return (
    <strong>
      every {interval} frame{interval !== 1 && 's'}
    </strong>
  );
}
