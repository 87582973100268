import React from 'react';
import { useLocation } from 'react-router-dom';

import { TagParams } from 'tags/types/tagged';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';

export function useTagsFilterParams(): TagParams {
  const { search } = useLocation();

  const { data: currentPlan, isSuccess } = useCurrentPlan();

  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);

  return React.useMemo(() => {
    if (!canCreateTags) {
      return {};
    }

    const urlSearchParams = new URLSearchParams(search);
    const tagID = urlSearchParams.get('tag');
    let isUntagged: boolean | undefined = undefined;

    if (!tagID) {
      try {
        isUntagged = Boolean(
          JSON.parse(urlSearchParams.get('unlabeled') ?? 'false')
        );
      } catch (error) {
        isUntagged = false;
      }
    }

    return {
      tagged_with: tagID ? [tagID] : undefined,
      include_tagged_with_descendants: true,
      only_untagged: isUntagged,
    };
  }, [search, canCreateTags]);
}
