import React from 'react';
import classnames from 'classnames/bind';
import {
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
} from 'react-select';

import Deployment from 'types/deployment';

import { relativeTimeFromNow } from 'services/date';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import {
  UseDeploymentsFilterParams,
  useDeployments,
} from 'hooks/api/useDeployments';
import { useGateways } from 'hooks/api/useGateways';
import { useDeploymentsVideoSources } from 'hooks/api/useDeploymentsVideoSources';

import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { Pill } from 'components/Pill/Pill';
import { Select, SelectOption, SelectProps } from 'components/Select/Select';
import { Text } from 'components/Text/Text';

import styles from './DeploymentSelect.module.scss';

const EMPTY: Deployment[] = [];

const c = classnames.bind(styles);

export type DeploymentSelectProps<IsMulti extends boolean = false> =
  SelectProps<Deployment, IsMulti> & {
    queryFilters?: UseDeploymentsFilterParams;
  };

export function DeploymentSelect<IsMulti extends boolean = false>({
  placeholder = 'Deployment',
  isLoading,
  queryFilters = {},
  ...props
}: DeploymentSelectProps<IsMulti>) {
  const [filters, setFilters] = React.useState<UseDeploymentsFilterParams>({});

  const {
    data,
    isLoading: isLoadingOptions,
    isSuccess,
  } = useDeployments(
    ['deployment-select-deployments'],
    {
      ...queryFilters,
      ...filters,
      limit: 25,
      exclude_configuration: false,
    },
    { keepPreviousData: true }
  );

  const { cameras, streams } = useDeploymentsVideoSources(data?.data ?? EMPTY, {
    keepPreviousData: true,
  });

  const { data: gateways, isLoading: isLoadingGateways } = useGateways(
    {
      gateway_ids: data?.data
        .map(({ gateway_id }) => gateway_id)
        .filter(Boolean),
    },
    { keepPreviousData: true, enabled: isSuccess }
  );

  const handleInputChange = useDebouncedCallback(
    (value: string) => {
      setFilters((previousFilters) => ({
        ...previousFilters,
        deployment_names: [value],
      }));
    },
    [],
    { wait: 100 }
  );

  function Option({
    data,
    children,
    ...rest
  }: OptionProps<Deployment, IsMulti>) {
    const { name, configuration, updated_at, gateway_id } = data;

    const gateway = gateways?.data.find(({ id }) => gateway_id === id);
    const cameraNodes = Object.values(configuration).filter(
      ({ source_type }) => source_type === 'camera'
    );
    const streamNodes = Object.values(configuration).filter(
      ({ source_type }) => source_type === 'stream'
    );
    const deploymentCameras = cameras.filter(({ id }) =>
      cameraNodes.some(({ source_id }) => source_id === id)
    );
    const deploymentStreams =
      streams?.filter(({ id }) =>
        streamNodes.some(({ source_id }) => source_id === id)
      ) ?? [];
    const videoSources = [...deploymentCameras, ...deploymentStreams];

    return (
      <SelectOption {...rest} data={data}>
        <div className={c('select-option')}>
          <p className={c('label')}>{name}</p>
          <Text className={c('updated-at')} size="small">
            Last deployed {relativeTimeFromNow(updated_at)}
          </Text>

          <Text className={c('gateway')} size="small">
            <Icon name="gateway" />
            {isLoadingGateways ? (
              <span>Loading...</span>
            ) : (
              <span>{gateway ? gateway.name : 'Unknown gateway'}</span>
            )}
          </Text>
          <Text className={c('source')} size="small">
            <Icon name="camera" />
            <span>{videoSources[0]?.name || 'No sources'}</span>
            {videoSources.length > 1 && (
              <span className={c('tag')}>+{videoSources.length - 1}</span>
            )}
          </Text>
        </div>
      </SelectOption>
    );
  }

  return (
    <Select
      {...props}
      options={data?.data}
      totalElements={data?.total_elements}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      getOptionLabel={({ id, name }) => name ?? id}
      getOptionValue={({ id }) => id}
      isLoading={isLoading || isLoadingOptions}
      components={{
        Option,
        MultiValueLabel,
        MultiValueContainer,
        MultiValueRemove,
      }}
    />
  );
}

function MultiValueLabel<OptionType, MultiSelect extends boolean = false>({
  data,
}: MultiValueGenericProps<OptionType, MultiSelect>) {
  return <span>{data.name}</span>;
}

function MultiValueContainer<OptionType, MultiSelect extends boolean = false>({
  children,
}: MultiValueGenericProps<OptionType, MultiSelect>) {
  return <Pill className={c('value-container')}>{children}</Pill>;
}

function MultiValueRemove<OptionType, MultiSelect extends boolean = false>({
  innerProps,
}: MultiValueRemoveProps<OptionType, MultiSelect>) {
  return (
    <div onClick={innerProps.onClick}>
      <IconButton
        icon="cancel"
        label="Remove item"
        size="xsmall"
        intent="danger"
      />
    </div>
  );
}
