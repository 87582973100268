import { useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { Input } from 'components/Input/Input';

import { FIELD_LABEL_WIDTH, Step2FieldValues } from '../Step2';

export function InputBlobNameInput() {
  const {
    register,
    formState: { errors },
  } = useFormContext<Step2FieldValues>();

  return (
    <Field
      id="input_blob_name"
      label="Input blob name"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      error={errors.inference_config?.input_blob_name}
      required
    >
      <Input
        id="input_blob_name"
        {...register('inference_config.input_blob_name', {
          required: 'Please enter a value.',
        })}
      />
    </Field>
  );
}
