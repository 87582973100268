import { FormMessage } from 'components/FormMessage/FormMessage';
import { Text } from 'components/Text/Text';
import InterceptedAPIError from 'types/api_error';

export type DeploymentFormErrorMessageProps = {
  error: InterceptedAPIError;
};

export function DeploymentFormErrorMessage({
  error: { code, message, context },
}: DeploymentFormErrorMessageProps) {
  if (context?.node_id) {
  }

  return (
    <FormMessage intent="danger" icon="warning">
      {message ? (
        <Text type="paragraph">{message}</Text>
      ) : (
        <Text type="paragraph">
          Please try again later or{' '}
          <a href={`mailto:support@lumeo.com?subject=${code}`} className="link">
            contact our support
          </a>{' '}
          if the error persists.
        </Text>
      )}

      {context?.node_id ? (
        <Text type="paragraph">
          <strong>{String(context.node_id)}</strong>
        </Text>
      ) : null}
    </FormMessage>
  );
}
