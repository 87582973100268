import React from 'react';
import classNames from 'classnames/bind';

import { useSnapshot } from 'hooks/useSnapshot';

import { IconButton } from 'components/IconButton/IconButton';

import { VideoSourceSelectLegacy } from 'components/VideoSourceSelect/VideoSourceSelectLegacy';
import { useDeploymentFormOptional } from 'deployments/components/Form/context';
import {
  ROICanvas,
  ROICanvasProps,
  coordinatesToPairs,
} from 'pipelines/components/ROICanvas/ROICanvas';
import { VideoSource } from 'hooks/api/useDeploymentsVideoSources';
import { VideoSourceSelection } from 'pipelines/services/rete/controls/video_source_selector';

import { getROIShapesFromInput } from './services/get_shapes_from_input';
import { VideoSourceROIPreview } from './Preview';
import styles from './VideoSourceROI.module.scss';

const c = classNames.bind(styles);

export type VideoSourceROIValue = {
  labels: string[] | null;
  coordinates: (readonly [number, number])[][] | null;
};

// FIXME: support for polygon and line strings
export type VideoSourceROIInputValue =
  | VideoSourceROIValue
  | {
      labels: string;
      coordinates: string;
    };

export type VideoSourceROIProps = Pick<ROICanvasProps, 'type'> & {
  value?: VideoSourceROIInputValue;
  onChange: (value: VideoSourceROIValue) => void;
};

export function VideoSourceROI({ type, value, onChange }: VideoSourceROIProps) {
  const { gateway, sources } = useDeploymentFormOptional();

  const [deploymentSource, setDeploymentSource] =
    React.useState<VideoSourceSelection | null>(null);
  const [source, setSource] = React.useState<VideoSource | null>(null);

  const { url, error, isLoading, takeNewSnapshot } = useSnapshot(
    source,
    gateway?.id
  );

  const internalValue = React.useMemo(
    () => getROIShapesFromInput(value, type),
    [value, type]
  );

  React.useEffect(() => {
    if (!sources || JSON.stringify(sources) === '{}') {
      return;
    }

    const [{ source_id, source_type }] = Object.values(sources);
    setDeploymentSource({ source_id, source_type });
    setSource(null);
  }, [sources]);

  function handleSelectionChange(source: VideoSource | null) {
    setSource(source);
  }

  const handleROIChange = React.useCallback(
    (shapes: Record<string, number[]>) => {
      const labels = Object.keys(shapes);
      let coordinates = Object.values(shapes).map(coordinatesToPairs);

      // Close polygon
      if (type === 'polygon') {
        coordinates = coordinates.map((shape) => {
          const [start] = shape;
          return [...shape, start];
        });
      }

      onChange({ labels, coordinates });
    },
    [type, onChange]
  );

  return (
    <div className={c('wrap')}>
      <div className={c('source-select')}>
        <VideoSourceSelectLegacy
          onChange={handleSelectionChange}
          value={source ?? deploymentSource}
          size="small"
          isClearable
        />
      </div>

      <IconButton
        className={c('update-snapshot')}
        icon="take-snapshot"
        label="Take a new snapshot"
        onClick={() => takeNewSnapshot()}
        disabled={!source || isLoading}
      />

      <div className={c('roi')}>
        <ROICanvas
          type={type}
          onChange={handleROIChange}
          defaultValue={internalValue}
        >
          <VideoSourceROIPreview
            source={source}
            url={url}
            error={error}
            isLoading={isLoading}
          />
        </ROICanvas>
      </div>
    </div>
  );
}
