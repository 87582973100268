import React from 'react';
import classNames from 'classnames/bind';

import { ComponentType } from 'pipelines/services/rete/nodes/component';

import { usePlanIncludedNodes } from 'hooks/usePlanIncludedNodes';

import { PricingPlanLabel } from 'pipelines/components/PricingPlanLabel/PricingPlanLabel';

import styles from './EditorNode.module.scss';

const c = classNames.bind(styles);

export type EditorNodePricingPlanLabelProps = {
  Component: ComponentType;
};

export function EditorNodePricingPlanLabel({
  Component,
}: EditorNodePricingPlanLabelProps) {
  const [{ starterNodes, businessNodes }] = usePlanIncludedNodes();

  if (!Component || Component.status === 'deprecated') {
    return null;
  }

  if (!starterNodes || !businessNodes) {
    return null;
  }

  const isStarterPlanNode = starterNodes.includes(Component.exportType);
  const isBusinessPlanNode = businessNodes.includes(Component.exportType);

  let plan: 'starter' | 'business' | 'custom' = 'custom';

  if (isStarterPlanNode) {
    plan = 'starter';
  } else if (isBusinessPlanNode) {
    plan = 'business';
  }

  return <PricingPlanLabel className={c('plan-label')} plan={plan} />;
}
