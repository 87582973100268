import React from 'react';
import classNames from 'classnames/bind';
import {
  CardElement,
  CardElementProps,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';

import PricingPlan from 'types/pricing_plan';

import { useSetupPayment } from 'organizations/hooks/useSetupPayment';
import { useSpringState } from 'hooks/useSpringState';
import { useSwitchPricingPlan } from 'organizations/hooks/useSwitchPricingPlan';

import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Button } from 'components/Button/Button';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Loader } from 'components/Loader/Loader';
import * as Dialog from 'components/Dialog';

import styles from './UpgradePlanDialog.module.scss';

const c = classNames.bind(styles);

export type PaymentInfoFormProps = {
  planID: PricingPlan['id'];
  onSuccess: () => void;
};

export function PaymentInfoForm({ planID, onSuccess }: PaymentInfoFormProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [isDarkMode, setIsDarkMode] = React.useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  const {
    data: setupSecret,
    isLoading: isLoadingClientKey,
    error: keyError,
  } = useSetupPayment();

  const isLoading = isLoadingClientKey || !stripe || !elements;

  const [isUpgrading, setIsUpgrading] = React.useState(false);
  const [isSuccess, setIsSuccess] = useSpringState(false, 3500);
  const [stripeError, setStripeError] = React.useState<StripeError>();

  const { error, mutate: switchPricingPlan } = useSwitchPricingPlan({
    onSuccess() {
      setIsSuccess(true);
    },
    onSettled() {
      setIsUpgrading(false);
    },
  });

  const CARD_ELEMENT_OPTIONS = React.useMemo<CardElementProps['options']>(
    () => ({
      classes: {
        base: c('stripe-input-base'),
        complete: c('stripe-input-complete'),
        empty: c('stripe-input-empty'),
        focus: c('stripe-input-focus'),
        invalid: c('stripe-input-invalid'),
      },
      style: {
        base: {
          fontFamily: 'Roboto, sans-serif',
          fontSize: '14px',
          lineHeight: '1.375',
          color: isDarkMode ? '#FFFFFF' : '#11181C',
          '::placeholder': {
            color: isDarkMode ? '#75818A' : '#7D858C',
          },
        },
      },

      hidePostalCode: false,
    }),
    [isDarkMode]
  );

  // Fire `onSuccess` after `isSuccess` reverts to `false`
  React.useEffect(() => {
    if (!isSuccess || !onSuccess) {
      return;
    }

    return onSuccess;
  }, [isSuccess, onSuccess]);

  React.useEffect(() => {
    function toggleDarkMode(event: MediaQueryListEvent) {
      setIsDarkMode(event.matches);
    }

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', toggleDarkMode);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', toggleDarkMode);
    };
  }, []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const cardElement = elements?.getElement('card');
    if (!stripe || !cardElement || !setupSecret) {
      return;
    }

    setStripeError(undefined);
    setIsUpgrading(true);

    const { setupIntent, error } = await stripe.confirmCardSetup(setupSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      setStripeError(error);
      setIsUpgrading(false);
    } else if (setupIntent?.status === 'succeeded') {
      switchPricingPlan(planID);
    }
  }

  return (
    <form className={c('payment-info-form')} onSubmit={handleSubmit}>
      {isSuccess && <Dialog.Message intent="success">Success!</Dialog.Message>}

      <Heading level="3">Add payment information</Heading>

      {keyError && (
        <FormMessage icon="warning" intent="danger">
          {keyError.message}
        </FormMessage>
      )}

      {stripeError && (
        <FormMessage icon="warning" intent="danger">
          {stripeError.message}
        </FormMessage>
      )}

      {error && (
        <FormMessage icon="warning" intent="danger">
          {error.message}
        </FormMessage>
      )}

      {isLoading ? (
        <Loader size="small" text="Initializing payment..." />
      ) : (
        <div className="form-row">
          <div className="form-item">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>
      )}

      <ButtonGroup>
        <Button
          type="submit"
          variant="primary"
          loading={isUpgrading}
          disabled={isLoading}
        >
          Upgrade and pay
        </Button>

        <Dialog.Close asChild>
          <Button variant="secondary">Cancel</Button>
        </Dialog.Close>
      </ButtonGroup>
    </form>
  );
}
