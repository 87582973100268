import {
  createModelSchema,
  custom,
  deserialize,
  identifier,
  primitive,
  serialize,
} from 'serializr';

import {
  MODEL_ARCHITECTURE_OPTIONS,
  MODEL_CAPABILITY_OPTIONS,
  MODEL_CLUSTERING_ALGORITHM_OPTIONS,
  MODEL_FORMAT_OPTIONS,
} from 'services/model_options';

type ModelRequiredParams = Pick<
  AIModel,
  'name' | 'format' | 'architecture' | 'capability'
> & {
  marketplace?: boolean;
};

export type ModelInferenceProperties = {
  normalization_mean?: number[] & { length: 3 };
  normalization_std?: number[] & { length: 3 };
  net_scale_factor?: number;

  color_format?: 'rgb' | 'bgr' | 'grayscale';
  network_mode?: 'float16' | 'float32' | 'int8';

  inference_engine?: 'default' | 'parallelized';
  infer_dims?: string;
  input_order?: 'nc' | 'nchw' | 'nhwc';
  input_blob_name?: string;
  output_blob_names?: string[];
  tlt_model_key?: string;

  // Detection
  cluster_mode?: (typeof MODEL_CLUSTERING_ALGORITHM_OPTIONS)[number]['value'];
  filter_out_class_ids?: string[];
  class_attributes: ModelClassProperties;

  // Classification
  classifier_threshold?: number;
};

export type ModelClassProperties = {
  [key: string]: {
    min_inference_threshold?: number;
    post_cluster_threshold?: number;

    // DbScan or DbScanNmsHybrid
    eps?: number;
    min_boxes?: number;
    dbscan_min_score?: number;

    // Nms or DbScanNmsHybrid
    nms_iou_threshold?: number;

    object_min_size?: string | null;
    object_max_size?: string | null;
    top_k?: number | null;
  };
};

export default class AIModel {
  readonly id: string;
  application_id?: string;
  gallery_img_url?: string;
  created_at?: string;
  updated_at?: string;
  source?: 'marketplace';

  name: string;
  description?: string;

  format: (typeof MODEL_FORMAT_OPTIONS)[number]['value'] | null;
  architecture: (typeof MODEL_ARCHITECTURE_OPTIONS)[number]['value'] | null;
  capability: (typeof MODEL_CAPABILITY_OPTIONS)[number]['value'] | null;

  weights_file_url?: string;
  weights_file_name?: string | null;
  metadata_file_url?: string;
  metadata_file_name?: string | null;
  labels_file_url?: string;
  labels_file_name?: string | null;
  labels?: string;

  parameters: Record<string, string> = {};
  inference_config: ModelInferenceProperties = {
    inference_engine: 'default',
    class_attributes: {},
  };

  constructor(
    id: string,
    name: string,
    format: AIModel['format'],
    capability: AIModel['capability'],
    architecture: AIModel['architecture'],
    marketplace = false
  ) {
    this.name = name;
    this.id = id;
    this.source = marketplace ? 'marketplace' : undefined;
    this.format = format;
    this.capability = capability;
    this.architecture = architecture;
  }

  static from({
    name,
    marketplace,
    format,
    capability,
    architecture,
    parameters,
    ...properties
  }: ModelRequiredParams & Partial<AIModel>) {
    const model = new AIModel(
      '',
      name,
      format,
      capability,
      architecture,
      marketplace
    );
    Object.assign(model, properties);

    if (parameters) {
      model.parameters = parameters;
    }

    return model;
  }

  copy() {
    return deserialize(AIModel, serialize(this)) as unknown as AIModel;
  }

  isMarketplaceModel() {
    return this.source === 'marketplace';
  }

  isNull() {
    return !this.id;
  }

  withName(name: string) {
    const newModel = this.copy();
    newModel.name = name;
    return newModel;
  }
}

/** @deprecated */
export class NullModel extends AIModel {
  constructor() {
    super('', null as any, null as any, null as any, null as any);
  }
}

export type ModelFiles = {
  weights_file?: Blob | null;
  metadata_file?: Blob | null;
  labels_file?: Blob | null;
};

export type UpdateModelParams = Pick<
  AIModel,
  | 'id'
  | 'application_id'
  | 'name'
  | 'description'
  | 'architecture'
  | 'capability'
  | 'format'
  | 'gallery_img_url'
  | 'metadata_file_name'
  | 'labels_file_name'
  | 'weights_file_name'
  | 'parameters'
  | 'inference_config'
>;

function serializeParameters(parameters: string | object) {
  if (!parameters) {
    return null;
  }

  if (typeof parameters === 'string') {
    return parameters;
  }

  return JSON.stringify(parameters);
}

function deserializeParameters(parameters: string | object) {
  if (!parameters) {
    return null;
  }

  if (typeof parameters !== 'string') {
    return parameters;
  }

  return JSON.parse(parameters);
}

createModelSchema(AIModel, {
  id: identifier(),
  application_id: primitive(),
  gallery_img_url: primitive(),
  created_at: primitive(),
  updated_at: primitive(),

  name: primitive(),
  description: primitive(),

  format: primitive(),
  architecture: primitive(),
  capability: primitive(),

  weights_file_url: primitive(),
  metadata_file_url: primitive(),
  labels_file_url: primitive(),

  inference_config: custom(serializeParameters, deserializeParameters),

  parameters: custom(serializeParameters, deserializeParameters),
});
