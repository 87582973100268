import React from 'react';
import classnames from 'classnames/bind';
import { Link, LinkProps } from 'react-router-dom';

import {
  StatusIndicator,
  Status,
} from 'components/StatusIndicator/StatusIndicator';

import { Icon } from 'components/Icon/Icon';
import { Pill } from 'components/Pill/Pill';

import styles from './Number.module.scss';

const c = classnames.bind(styles);

type CompactDashboardNumberProps = DashboardNumberProps & {
  offlineNumber?: number;
};

type InDepthDashboardNumberProps = DashboardNumberProps & {
  status: Status;
  offlineNumber?: never;
};

export type DashboardNumberProps = Pick<LinkProps, 'to' | 'state'> & {
  number: number;
  status?: Status;
  offlineNumber?: number;
  offlineState?: LinkProps['state'];
};

export function DashboardNumber(
  props: CompactDashboardNumberProps
): JSX.Element;
export function DashboardNumber(
  props: InDepthDashboardNumberProps
): JSX.Element;

export function DashboardNumber({
  number,
  to,
  state,
  status,
  offlineNumber,
  offlineState,
}: DashboardNumberProps) {
  return (
    <div className={c('dashboard-number')}>
      <div className={c(status === 'error' ? number && status : status)}>
        <div className={c('row', 'number')}>
          {status && (
            <StatusIndicator
              status={status}
              className={c('icon')}
              size="small"
            />
          )}

          <span className={c('number-content')}>
            <LinkWrap to={to} state={state}>
              {number}
            </LinkWrap>
          </span>
        </div>
      </div>
      {offlineNumber && offlineNumber > 0 ? (
        <Pill className={c('row')} intent="danger" interactive>
          <Icon className={c('offline-status')} name="cancel" />
          <span className={c('offline-number')}>{offlineNumber}</span>&nbsp;
          <LinkWrap to={to} state={offlineState}>
            offline
          </LinkWrap>
        </Pill>
      ) : null}
    </div>
  );
}

type LinkWrapProps = Pick<
  DashboardNumberProps,
  'to' | 'state' | 'offlineState'
> & {
  className?: string;
};

function LinkWrap({
  to,
  children,
  ...rest
}: React.PropsWithChildren<LinkWrapProps>) {
  if (!to) {
    return <>{children}</>;
  }

  return (
    <Link to={to} {...rest} className={c('link')}>
      {children}
    </Link>
  );
}
