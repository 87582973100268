import classNames from 'classnames/bind';
import {
  Pagination as MantinePagination,
  PaginationProps as MantinePaginationProps,
} from '@mantine/core';

import styles from './Pagination.module.scss';

const c = classNames.bind(styles);

export type PaginationProps = MantinePaginationProps;

export function Pagination({ className, ...props }: PaginationProps) {
  return (
    <div className={c('pagination', className)}>
      <MantinePagination {...props} />
    </div>
  );
}
