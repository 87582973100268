import React from 'react';

import {
  NumberInput,
  NumberInputProps,
} from 'components/NumberInput/NumberInput';

import { FileSizeUnit, getBytes, getDisplayValue } from './convert_file_size';

export type FileSizeInputProps = Pick<
  NumberInputProps,
  'value' | 'defaultValue' | 'onChange' | 'max' | 'min' | 'step'
> & {
  id?: string;
  displayUnit: FileSizeUnit;
};

export function FileSizeInput({
  value,
  defaultValue,
  displayUnit,
  onChange,
  ...props
}: FileSizeInputProps) {
  return (
    <NumberInput
      {...props}
      unit={displayUnit}
      defaultValue={getDisplayValue(Number(defaultValue), displayUnit)}
      value={getDisplayValue(Number(value), displayUnit)}
      onChange={(value) => onChange?.(getBytes(value, displayUnit))}
    />
  );
}
