import { NodeEditor } from 'rete';

import RadioSelect from 'pipelines/services/rete/controls/radio_select';
import TextInput from 'pipelines/services/rete/controls/text_input';
import ResolutionInputField from 'pipelines/services/rete/controls/resolution_input';
import NumberInput from 'pipelines/services/rete/controls/number_input';
import ReteTriggerConditionInput from 'pipelines/services/rete/controls/trigger_condition_input';

import { ReteInputParams } from './input';
import { ResolutionInputProps } from 'components/ResolutionInput/ResolutionInput';
import { PipelineConditionInputProps } from 'pipelines/components/PipelineConditionInput/PipelineConditionInput';

type ConfiguredReteInputParams = Partial<ReteInputParams> & {
  key?: string;
};

export function RotationInput(
  editor: NodeEditor,
  { key, label, initial, group, ...rest }: ConfiguredReteInputParams
) {
  return new RadioSelect(editor, key || 'rotation', {
    label: label || 'Rotation angle',
    options: [
      { label: '0°', value: null },
      { label: '90°', value: 90 },
      // { label: '180°', value: 180 },
      // { label: '270°', value: 270 },
    ],
    initial: initial || '',
    type: 'number',
    unit: 'degrees',
    info: 'The output video will be rotated by this angle.',
    group,
    ...rest,
  });
}

export function FlipVideoInput(
  editor: NodeEditor,
  {
    key,
    initial,
    group,
    ...rest
  }: Omit<ConfiguredReteInputParams, 'label' | 'info'>
) {
  return new RadioSelect(editor, key || 'flip_direction', {
    label: 'Flip direction',
    options: [
      { label: 'None', value: null },
      {
        label: 'Horizontal',
        value: 'horizontal',
        icon: 'flip-horizontal',
      },
      { label: 'Vertical', value: 'vertical', icon: 'flip-vertical' },
    ],
    initial: initial || null,
    type: 'string',
    group,
    ...rest,
  });
}

export function FrameRateInput(
  editor: NodeEditor,
  { key, label, info, initial, group, ...rest }: ConfiguredReteInputParams
) {
  return new NumberInput(editor, key || 'framerate', {
    label: label || 'Output frame rate',
    initial: initial || null,
    unit: 'fps',
    info,
    placeholder: 'auto',
    step: 1,
    group,
    ...rest,
  });
}

type ReteResolutionInputProps = ConfiguredReteInputParams & {
  presets?: ResolutionInputProps['presets'];
};

export function ResolutionInput(
  editor: NodeEditor,
  { key, label, info, initial, presets, ...rest }: ReteResolutionInputProps
) {
  const defaultInfo = 'Width × height in pixels';

  return new ResolutionInputField(editor, key || 'resolution', {
    label: label || 'Output resolution',
    info: info ? `${info}\n${defaultInfo}` : defaultInfo,
    initial: initial || null,
    placeholder: 'auto',
    presets: presets || false,
    ...rest,
  });
}

export const SaveLocationInput = (
  editor: NodeEditor,
  { key, initial, ...rest }: ConfiguredReteInputParams
) =>
  new RadioSelect(editor, key || 'location', {
    type: 'string',
    label: 'Save location',
    options: [
      {
        label: 'Lumeo cloud',
        value: 'lumeo_cloud',
        icon: 'cloud',
        info: 'Access files via our API or the Output Files tab of the deployment.',
      },
      {
        label: 'S3 bucket',
        value: 's3_bucket',
        icon: 'cloud',
        info: 'Access files from your S3 storage.',
      },
      {
        label: 'Local disk',
        value: 'local',
        info: 'Access files from the specified folder of your gateway device.',
      },
    ],
    initial: initial || 'lumeo_cloud',
    ...rest,
  });

export function MaxEdgeFilesInput(
  editor: NodeEditor,
  { initial, ...rest }: ConfiguredReteInputParams
) {
  return new TextInput(editor, 'max_edge_files', {
    label: 'Maximum number of files',
    initial: initial || null,
    type: 'number',
    ...rest,
  });
}

export function WebhookUrlInput(
  editor: NodeEditor,
  { key, label, info, initial, ...rest }: ConfiguredReteInputParams
) {
  return new TextInput(editor, key || 'webhook_url', {
    type: 'string',
    label: label || 'Webhook URL',
    initial: initial || null,
    icon: 'webhook',
    placeholder: 'https://',
    info: info || 'Webhook to this URL when the clip capture finishes',
    ...rest,
  });
}

type TriggerInputProps = ConfiguredReteInputParams &
  Pick<PipelineConditionInputProps, 'suggestionTypeFilters'>;

export function TriggerInput(
  editor: NodeEditor,
  { initial, ...rest }: TriggerInputProps
) {
  return new ReteTriggerConditionInput(editor, 'trigger', {
    label: 'Trigger condition',
    initial: initial || null,
    ...rest,
  });
}

export const TargetLocationInput = (
  editor: NodeEditor,
  { key, label, initial, ...rest }: ConfiguredReteInputParams
) =>
  new TextInput(editor, key || 'path', {
    type: 'string',
    label: label || 'File path',
    initial: initial || '/var/lib/lumeo/media/',
    placeholder: '/path/to/file/',
    ...rest,
  });

export function RetentionDurationInput(
  editor: NodeEditor,
  { key, label, initial, ...rest }: ConfiguredReteInputParams
) {
  return new TextInput(editor, key || 'retention_duration', {
    label: label || 'Retention duration',
    initial: initial || null,
    type: 'number',
    unit: 'seconds',
    info: 'Delete files after this time. Leave blank to never delete files automatically.',
    ...rest,
  });
}

export const CodecInput = (
  editor: NodeEditor,
  { key, initial, ...rest }: ConfiguredReteInputParams
) =>
  new RadioSelect(editor, key || 'codec', {
    type: 'string',
    label: 'Codec',
    options: [
      { label: 'H.264', value: 'h264' },
      { label: 'H.265', value: 'h265', disabled: true },
      { label: 'VP8', value: 'VP8', disabled: true },
    ],
    initial: initial || 'h264',
    // info:
    //   'Use H.264 for the widest compatibility with other systems, H.265 or VP8 for improved efficiency.',
    ...rest,
  });
