import { AxiosError } from 'axios';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
} from '@tanstack/react-query';

import Pipeline from 'types/pipeline';
import APIError from 'types/api_error';

import { initializeAPIPipeline } from 'pipelines/hooks/usePipelines';
import { useAPI } from 'hooks/api/useAPI';
import { useAppQueryClient } from 'hooks/useAppQueryClient';

import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { useDangerousMutation } from './useDangerousMutation';

export function usePipeline(
  id?: string,
  { enabled, ...options }: UseQueryOptions<Pipeline | undefined, APIError> = {}
) {
  const api = useAPI();

  return useAuthenticatedQuery(
    // include appID in queryKey since pipelines are app-specific
    // and need to be refetched when the user switches applications
    ['pipeline', id, api.applicationID],
    () => {
      if (!id) {
        return Promise.reject(new Error('No pipeline ID provided.'));
      }

      return api.pipelines.read(id);
    },
    {
      ...options,
      enabled: enabled !== undefined ? enabled && Boolean(id) : Boolean(id),
      refetchOnWindowFocus: false,
      select(pipeline) {
        if (pipeline) {
          return initializeAPIPipeline(pipeline);
        }

        return pipeline;
      },
    }
  );
}

export function useDeletePipeline(
  pipeline?: Pipeline,
  { onMutate, onError, onSettled, ...options }: UseMutationOptions = {}
) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete this pipeline?',
    () => {
      if (!pipeline) {
        return Promise.resolve();
      }
      return api.pipelines.delete(pipeline.id);
    },
    {
      ...options,
      action: 'delete',
      entity: 'pipeline',
      onMutate: async () => {
        await queryClient.cancelQueries(['pipelines']);
        const previousPipelines = queryClient.getQueryData<Pipeline[]>([
          'pipelines',
        ]);

        if (pipeline && previousPipelines) {
          queryClient.setQueryData(
            ['pipelines'],
            previousPipelines.filter(({ id }) => id !== pipeline.id)
          );
        }

        if (onMutate) {
          onMutate();
        }

        return { previousPipelines };
      },
      onError: (err, deletedPipeline, context) => {
        if (context?.previousPipelines) {
          queryClient.setQueryData(['pipelines'], context.previousPipelines);
        }

        if (onError) {
          onError(err, deletedPipeline, context);
        }
      },
      onSettled: (...args) => {
        queryClient.invalidateQueries(['pipelines']);

        if (onSettled) {
          onSettled(...args);
        }
      },
    }
  );
}

type UseDeletePipelinesContext = {
  previousPipelines: Pipeline[] | undefined;
} | void;

export function useDeletePipelines({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<
  void[],
  APIError,
  Pipeline['id'][],
  UseDeletePipelinesContext
> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete these pipelines?',
    (pipelineIDs) => {
      if (!pipelineIDs.length) {
        return Promise.all([]);
      }

      return Promise.all(pipelineIDs.map(api.pipelines.delete));
    },
    {
      ...options,
      onMutate: async (pipelineIDs) => {
        await queryClient.cancelQueries(['pipelines']);
        const previousPipelines = queryClient.getQueryData<Pipeline[]>([
          'pipelines',
        ]);

        if (pipelineIDs && previousPipelines) {
          queryClient.setQueryData(
            ['pipelines'],
            previousPipelines.filter(({ id }) =>
              pipelineIDs.find((pipelineID) => pipelineID !== id)
            )
          );
        }

        if (onMutate) {
          onMutate(pipelineIDs);
        }

        return { previousPipelines };
      },
      onError: (err, deletedPipeline, context) => {
        if (context?.previousPipelines) {
          queryClient.setQueryData(['pipelines'], context.previousPipelines);
        }

        if (onError) {
          onError(err, deletedPipeline, context);
        }
      },
      onSettled: (...args) => {
        queryClient.invalidateQueries(['pipelines']);

        if (onSettled) {
          onSettled(...args);
        }
      },
    }
  );
}

export function useLockPipeline(
  pipelineID: string,
  options: UseMutationOptions<void, AxiosError, void> = {}
) {
  const api = useAPI();
  return useMutation(() => api.pipelines.lock(pipelineID), options);
}

export function useUnlockPipeline(
  pipelineID: string,
  options: UseMutationOptions<void, AxiosError, void> = {}
) {
  const api = useAPI();
  return useMutation(() => api.pipelines.unlock(pipelineID), options);
}
