import { useHasAccess } from 'hooks/useHasAccess';
import { useMarketplaceModels, useModels } from 'hooks/api/useModels';
import { usePipelineComponents } from 'pipelines/hooks/usePipelineComponents';
import { useUsageLimits } from 'organizations/hooks/useUsageLimits';

export function usePreloadDeploymentForm() {
  const [hasAccess] = useHasAccess();

  const { isLoading: isLoadingUsageLimits, error: usageLimitsError } =
    useUsageLimits({
      enabled: hasAccess('deploy_edit'),
    });

  const [, { isLoading: isLoadingPipelineComponents }] =
    usePipelineComponents();

  const { isInitialLoading: isLoadingModels } = useModels();
  const { isInitialLoading: isLoadingMarketplaceModels } =
    useMarketplaceModels();

  return {
    isLoading:
      isLoadingUsageLimits ||
      isLoadingPipelineComponents ||
      isLoadingModels ||
      isLoadingMarketplaceModels,
    error: usageLimitsError,
  };
}
