import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import {
  FileSizeInput,
  FileSizeInputProps,
} from 'components/FileSizeInput/FileSizeInput';

export type ReteFileSizeInputParams = ReteInputParams & FileSizeInputProps;

export default class ReteFileSizeInput extends ReteInput<
  ReteInputProps & FileSizeInputProps
> {
  constructor(
    editor: NodeEditor,
    key: string,
    { min, max, step, displayUnit, ...params }: ReteFileSizeInputParams
  ) {
    super(editor, key, { ...params, type: 'number' });

    this.props.min = min;
    this.props.max = max;
    this.props.step = step;
    this.props.displayUnit = displayUnit;
  }

  get component() {
    return ReteFileSizeInputComponent;
  }
}

function ReteFileSizeInputComponent(props: FileSizeInputProps) {
  return <FileSizeInput {...props} />;
}
