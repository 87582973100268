import React from 'react';
import classNames from 'classnames/bind';
import { Link, LinkProps } from 'react-router-dom';

import styles from './Card.module.scss';

const c = classNames.bind(styles);

type CommonProps = React.PropsWithChildren<{
  className?: string;
  size?: 'small' | 'xsmall';
}>;

type StaticCardProps = CommonProps & {
  onClick?: never;
  to?: never;
};

type LinkCardProps = CommonProps & {
  to: LinkProps['to'];
  onClick?: never;
};

type ButtonCardProps = CommonProps & {
  to?: never;
  onClick: () => void;
};

export type CardProps = StaticCardProps | LinkCardProps | ButtonCardProps;

export function Card(props: StaticCardProps): JSX.Element;
export function Card(props: LinkCardProps): JSX.Element;
export function Card(props: ButtonCardProps): JSX.Element;

export function Card({ size, to, className, children, onClick }: CardProps) {
  let Element:
    | React.FunctionComponent<LinkProps>
    | keyof JSX.IntrinsicElements = 'div';

  if (to) {
    Element = Link;
  } else if (onClick) {
    Element = 'button';
  }

  return (
    <Element
      className={c(className, 'card', size, {
        interactive: Boolean(to || onClick),
      })}
      to={to as LinkProps['to']}
      onClick={onClick}
    >
      {children}
    </Element>
  );
}
