import React from 'react';
import classnames from 'classnames/bind';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';

import { ApplicationParams } from 'application/types/application_params';
import { DeployRoute, MonitorRoute, NavRoute } from 'application/types/routes';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';

import { Dashboards } from 'dashboards/views';

import { FilesSearch } from 'files/views/Search/Search';
import { Heading } from 'components/Heading/Heading';
import { NotFound } from 'views/NotFound/NotFound';
import { Separator } from 'components/Separator/Separator';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { SidebarLink } from 'components/Sidebar/SidebarLink';

import { MonitorDashboardLinks } from './DashboardLinks';
import styles from './Monitor.module.scss';

const c = classnames.bind(styles);

export function Monitor() {
  const { applicationID } = useParams<ApplicationParams>();

  const { data: currentPlan } = useCurrentPlan();

  return (
    <div className={c('monitor')}>
      <Sidebar>
        <SidebarLink
          to={`/${NavRoute.MONITOR}/${MonitorRoute.SEARCH}`}
          icon="search"
        >
          Search recordings
        </SidebarLink>

        <Separator />

        {currentPlan?.can_create_dashboards && (
          <li className={c('sidebar-scroll')}>
            <Heading level="4">Dashboards</Heading>

            {/* TODO: Search */}

            <MonitorDashboardLinks />
          </li>
        )}
      </Sidebar>

      <Routes>
        <Route index element={<Navigate to="dashboards" replace />} />

        <Route
          path="events"
          element={
            <Navigate
              to={`/applications/${applicationID}/${NavRoute.DEPLOY}/${DeployRoute.EVENTS}`}
            />
          }
        />

        {currentPlan?.can_create_dashboards && (
          <Route path="dashboards/*" element={<Dashboards />} />
        )}

        <Route path="search" element={<FilesSearch />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
