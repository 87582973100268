import React from 'react';
import classNames from 'classnames/bind';
import * as RadixDialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import Stream from 'types/stream';

import WebRTCPlayer from 'components/WebRTCPlayer/WebRTCPlayer';
import { useModal } from 'hooks/useModal';
import { useStream } from 'streams/hooks/useStream';

import { IconButton } from 'components/IconButton/IconButton';

import styles from './Lightbox.module.scss';

const c = classNames.bind(styles);

type StreamLightboxProps = Pick<RadixDialog.DialogProps, 'open'> &
  Pick<Stream, 'id'>;

export function StreamLightbox({ id, ...props }: StreamLightboxProps) {
  return (
    <RadixDialog.Root {...props}>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className={c('overlay')} />
        <StreamLightboxContent id={id} />
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}

function StreamLightboxContent({ id }: StreamLightboxProps) {
  const { data: stream } = useStream(id);

  const { close } = useModal();

  return (
    <RadixDialog.Content
      className={c('content')}
      onEscapeKeyDown={() => close()}
    >
      <VisuallyHidden>
        <RadixDialog.Title>
          {stream ? `View stream ${stream.name}` : ''}
        </RadixDialog.Title>
        <RadixDialog.Description>
          The stream will start playing automatically.
        </RadixDialog.Description>
      </VisuallyHidden>

      <RadixDialog.Close asChild>
        <IconButton
          className={c('close')}
          icon="cancel"
          label="Close"
          onClick={() => close()}
          variant="ghost"
        />
      </RadixDialog.Close>

      <WebRTCPlayer streamID={id} auto />
    </RadixDialog.Content>
  );
}
