import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import OrganizationUsageStatistics from 'types/organization_usage_statistics';

import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function useUsageStatistics(
  options?: UseQueryOptions<OrganizationUsageStatistics, APIError>
) {
  const api = useAPI();
  const { data: organization } = useCurrentOrganization();

  return useAuthenticatedQuery(
    ['usage_statistics', 'org', organization?.id],
    () => {
      if (!organization) {
        return Promise.reject(new Error('No organization.'));
      }

      return api.organizations.readUsageStatistics(organization.id);
    },
    options
  );
}
