import React from 'react';
import classnames from 'classnames/bind';
import { Pagination } from '@mantine/core';

import Deployment, {
  DEPLOYMENT_ACTIVE_STATES,
  DEPLOYMENT_ERROR_STATES,
  DEPLOYMENT_INACTIVE_STATES,
  DeploymentState,
} from 'types/deployment';
import Pipeline from 'types/pipeline';

import { pluralize } from 'services/string';
import {
  useDeployments,
  UseDeploymentsFilterParams,
} from 'hooks/api/useDeployments';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { StateSelect } from 'components/StateSelect/StateSelect';
import { TableRow } from 'components/Table/TableRow';

import { useDeploymentsFormSelectTable } from './useDeploymentsSelectTable';
import styles from '../Form.module.scss';

const c = classnames.bind(styles);

export type DeploymentsFormSelectProps = {
  pipeline?: Pipeline;
  defaultValue: Record<Deployment['id'], boolean>;
  onConfirmSelection: (deployments: Record<Deployment['id'], boolean>) => void;
};

const PAGE_SIZE = 20;

const DEPLOYMENT_STATES = [
  ...DEPLOYMENT_ACTIVE_STATES,
  ...DEPLOYMENT_INACTIVE_STATES,
  ...DEPLOYMENT_ERROR_STATES,
];

export function DeploymentsFormSelect({
  pipeline,
  defaultValue,
  onConfirmSelection,
}: DeploymentsFormSelectProps) {
  const [filters, setFilters] = React.useState<UseDeploymentsFilterParams>({
    page: 1,
  });
  const queryResult = useDeployments(
    {
      ...filters,
      pipeline_ids: pipeline ? [pipeline.id] : undefined,
      limit: PAGE_SIZE,
    },
    {
      keepPreviousData: true,
      enabled: Boolean(pipeline),
    }
  );

  const [searchFilters, setSearchFilters] =
    React.useState<UseDeploymentsFilterParams>({});
  const searchQueryResult = useDeployments(
    ['deployments-suggestions'],
    {
      ...searchFilters,
      pipeline_ids: pipeline ? [pipeline.id] : undefined,
      limit: 20,
    },
    { enabled: Object.keys(searchFilters).length > 0, keepPreviousData: false }
  );

  const handleSelectAll = React.useCallback(() => {
    onConfirmSelection({});
  }, [onConfirmSelection]);

  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,

    page,
    gotoPage,

    toggleRowSelected,

    state: { selectedRowIds },
  } = useDeploymentsFormSelectTable(
    queryResult,
    PAGE_SIZE,
    defaultValue,
    handleSelectAll
  );

  function handleSearchInput(value?: string) {
    if (!value) {
      setSearchFilters({});
      setFilters({ page: 1 });
      return;
    }

    setSearchFilters({
      deployment_names: [value],
    });
  }

  function handleApplyFilters() {
    setFilters({ ...searchFilters, page: 1 });
  }

  const hasPagination = queryResult.data && queryResult.data.total_pages > 1;

  const selectionCount = selectedRowIds
    ? Object.keys(selectedRowIds).length
    : 0;

  return (
    <>
      <section className={c('body')}>
        <QuerySearch<Deployment>
          entityName="deployment"
          searchLabel="Search deployments..."
          searchResultField="name"
          searchQueryResult={searchQueryResult}
          onApplyFilters={handleApplyFilters}
          onSuggestionSelect={(deployment) => {
            toggleRowSelected(deployment.id, true);
            setSearchFilters({});
          }}
          onValueChange={handleSearchInput}
          totalElements={queryResult.data?.total_elements}
          filters={
            <>
              <GatewaySelect
                onChange={(gateway) =>
                  setFilters((previousFilters) => ({
                    ...previousFilters,
                    gateway_ids: gateway ? [gateway.id] : undefined,
                  }))
                }
                size="small"
                isClearable
              />
              <StateSelect
                onChange={(state) =>
                  setFilters((previousFilters) => ({
                    ...previousFilters,
                    states: state ? [state as DeploymentState] : undefined,
                  }))
                }
                options={DEPLOYMENT_STATES}
                size="small"
              />
            </>
          }
        />

        <table
          className={c('override-form-table')}
          {...getTableProps()}
          cellSpacing={0}
        >
          <thead>
            {headerGroups.map((headerGroup) => {
              const { key, ...groupProps } = headerGroup.getHeaderGroupProps();
              return (
                <tr {...groupProps} key={key}>
                  {headerGroup.headers.map((column) => {
                    const { key: colKey, ...headerProps } =
                      column.getHeaderProps();
                    return (
                      <th
                        {...headerProps}
                        className={c('override-form-table-header', 'label', {
                          autoSize: column.width === 0,
                        })}
                        key={colKey}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody
            className={c('override-form-table-body')}
            {...getTableBodyProps()}
          >
            {page.map((row) => {
              prepareRow(row);
              return <TableRow {...row.getRowProps()} row={row} key={row.id} />;
            })}
          </tbody>
        </table>
      </section>

      <section className={c('actions')}>
        <div className={c('actions-slot')}>
          {hasPagination && (
            <Pagination
              total={queryResult.data.total_pages}
              value={filters.page}
              onChange={(page) => {
                setFilters((previousFilters) => ({ ...previousFilters, page }));
                gotoPage(page);
              }}
            />
          )}
        </div>

        <div className={c('actions-deploy')}>
          <ButtonGroup>
            <Button
              onClick={() => onConfirmSelection(selectedRowIds)}
              disabled={selectionCount === 0}
              variant="primary"
            >
              {selectionCount > 0
                ? `Continue with ${selectionCount} ${pluralize(selectionCount, 'deployment')}`
                : 'Continue'}
            </Button>
          </ButtonGroup>
        </div>
      </section>
    </>
  );
}
