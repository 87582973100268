import React from 'react';

import Pipeline from 'types/pipeline';

export function useNodeIDs(pipeline: Pipeline) {
  const isInitialized = React.useRef(false);
  const highestNodeTypeIDs = React.useRef<Map<string, number>>(new Map());

  // Initialize node ID map
  React.useEffect(() => {
    if (isInitialized.current || !pipeline || highestNodeTypeIDs.current.size) {
      return;
    }

    pipeline.definition.forEach(({ id, properties: { type } }) => {
      const numberInID = Number(id.match(/\d+/)![0]);
      const currentHighestID = highestNodeTypeIDs.current.get(type);

      if (!currentHighestID || numberInID > currentHighestID) {
        highestNodeTypeIDs.current.set(type, numberInID);
      }
    });
    isInitialized.current = true;
  }, [pipeline]);

  function getNodeID(type: string) {
    const currentHighestID = highestNodeTypeIDs.current.get(type);
    const number = currentHighestID ? currentHighestID + 1 : 1;
    highestNodeTypeIDs.current.set(type, number);
    return type + number;
  }

  return getNodeID;
}
