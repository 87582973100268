import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';

import { PageLayoutContext } from '../context/page_layout';
import { ContainerSize } from './Container';
import styles from '../PageLayout.module.scss';

const c = classNames.bind(styles);

export type PageLayoutProps = {
  className?: string;
  children: ReactNode;
  /** @default default */
  size?: ContainerSize;
};

function Root(
  { className, children, size = 'default' }: PageLayoutProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <PageLayoutContext.Provider value={{ size }}>
      <div className={c(className, 'root')} ref={ref}>
        {children}
      </div>
    </PageLayoutContext.Provider>
  );
}

const forwardedRef = React.forwardRef(Root);
const memoized = React.memo(forwardedRef);

export { memoized as Root };
