import React from 'react';
import Konva from 'konva';
import { Line as LineElement } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';

import { getPointerPosition } from '../services/get_pointer_position';
import { coordinatesToPairs } from '../services/coordinates_to_pairs';
import { ROI_CONFIG } from '../config';
import { ROIAnchorPoint } from './AnchorPoint';
import { ROILabel } from './Label';

export type ROIShapeProps = {
  labels: string[];
  label: string;
  shape: number[];
  closed?: boolean;
  selectedAnchor: Konva.Circle | null;
  isSelected?: boolean;
  isShowingLabels?: boolean;
  onChange: (label: string, shape: number[]) => void;
  onRename: (oldLabel: string, newLabel: string) => void;
  onSelect: (label: string) => void;
  onSelectedAnchorChange: (anchor: Konva.Circle | null) => void;
  onDelete: (label: string) => void;
};

export function ROIShape({
  labels,
  label,
  shape,
  closed,
  selectedAnchor,
  isSelected,
  isShowingLabels,
  onChange,
  onRename,
  onSelect,
  onSelectedAnchorChange,
  onDelete,
}: ROIShapeProps) {
  const shapeRef = React.useRef<Konva.Line>(null);
  const mirrorRef = React.useRef<Konva.Line>(null);
  const anchorRefs = React.useRef<(Konva.Circle | null)[]>([]);

  const [isHovering, setIsHovering] = React.useState(false);

  const [anchorPoints, setAnchorPoints] = React.useState(
    coordinatesToPairs(shape)
  );

  React.useLayoutEffect(() => {
    setAnchorPoints(coordinatesToPairs(shape));
  }, [shape]);

  function handleMouseEnter() {
    setIsHovering(true);
  }

  function handleMouseLeave() {
    setIsHovering(false);
  }

  function handleAnchorMove(event: KonvaEventObject<DragEvent>) {
    const shape = shapeRef.current;
    const anchors = anchorRefs.current;

    if (!shape || !anchors) {
      return;
    }

    const anchorPos = getPointerPosition(shape.getStage());

    if (anchorPos) {
      event.target.x(anchorPos.x);
      event.target.y(anchorPos.y);
    }

    const newShape = shape
      .points(
        anchors.flatMap((anchor) => {
          if (!anchor) {
            return [];
          }

          return [anchor.x(), anchor.y()];
        })
      )
      .points();

    onChange(label, newShape);
  }

  function handleAnchorSelect(
    event: KonvaEventObject<MouseEvent>,
    index: number
  ) {
    event.cancelBubble = true;

    const anchor = anchorRefs.current[index];

    if (!anchor) {
      return;
    }

    onSelectedAnchorChange(anchor);
  }

  function handleLabelChange(newLabel: string) {
    onRename(label, newLabel);
  }

  function handleClick(event: KonvaEventObject<MouseEvent>) {
    event.cancelBubble = true;
    onSelect(label);
  }

  // Stage event listeners to delete shapes or anchors
  React.useEffect(() => {
    const shape = shapeRef.current;
    const stage = shape?.getStage();
    const container = stage?.container();

    if (!shape || !container) {
      return;
    }

    function handleStageKeyDown(event: KeyboardEvent) {
      if (!shape) {
        return;
      }

      switch (event.key) {
        case 'Backspace':
        case 'Delete': {
          // Delete shape
          if (isSelected) {
            onDelete(label);
            shape.destroy();
            return;
          }

          // Delete selected anchor
          if (selectedAnchor) {
            const ax = selectedAnchor.x();
            const ay = selectedAnchor.y();

            const updatedShape = shape
              .points(
                anchorPoints.filter(([bx, by]) => ax !== bx || ay !== by).flat()
              )
              .points();

            onChange(label, updatedShape);
            onSelectedAnchorChange(null);
          }
        }
      }
    }

    container.addEventListener('keydown', handleStageKeyDown);

    return () => {
      container.removeEventListener('keydown', handleStageKeyDown);
    };
  }, [
    anchorPoints,
    label,
    isSelected,
    selectedAnchor,
    onDelete,
    onChange,
    onSelectedAnchorChange,
  ]);

  const mirrorPoints = closed ? [...shape, ...shape.slice(0, 2)] : shape;
  let mirrorStrokeWidth = isHovering ? 1 : 0;

  if (isSelected) {
    mirrorStrokeWidth = 1.5;
  }

  return (
    <>
      <LineElement
        {...ROI_CONFIG.line}
        points={shape}
        closed={closed}
        ref={shapeRef}
      />

      {/* Mirror to handle events for closed shapes & mouseover indicator.
          Closed shapes also have a hit area for their fill, which may be
          confusing. The hit area for open shapes is limited to the stroke. */}
      <LineElement
        points={mirrorPoints}
        hitStrokeWidth={ROI_CONFIG.line.hitStrokeWidth}
        strokeWidth={mirrorStrokeWidth}
        stroke="magenta"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        ref={mirrorRef}
      />

      {anchorPoints.map(([x, y], i) => (
        <ROIAnchorPoint
          x={x}
          y={y}
          key={i}
          onDragMove={handleAnchorMove}
          onClick={(event) => handleAnchorSelect(event, i)}
          ref={(el) => (anchorRefs.current[i] = el)}
          isSelected={selectedAnchor === anchorRefs.current[i]}
          draggable
        />
      ))}

      {isShowingLabels && (
        <ROILabel
          labels={labels}
          text={label}
          shape={shape}
          closed={closed}
          onChange={handleLabelChange}
        />
      )}
    </>
  );
}
