import React from 'react';
import classNames from 'classnames/bind';

import { PipelineTemplateGrid } from 'analytics-library/components/PipelineTemplateGrid/PipelineTemplateGrid';
import * as PageLayout from 'components/PageLayout';

import styles from './Solutions.module.scss';

const c = classNames.bind(styles);

export function Solutions() {
  return (
    <PageLayout.Root className={c('solutions')}>
      <PipelineTemplateGrid id="library-templates" />
    </PageLayout.Root>
  );
}
