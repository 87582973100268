import React from 'react';
import RGL from 'react-grid-layout';
import { Controller, useForm } from 'react-hook-form';

import Dashboard from 'types/dashboard';
import { StreamWidget } from 'types/dashboard_widget';
import Stream, { StreamType } from 'types/stream';

import { useUpdateDashboard } from 'dashboards/hooks/useUpdateDashboard';
import { generateId } from 'services/string';
import { useStream } from 'streams/hooks/useStream';

import { StreamSelect } from 'streams/components/StreamSelect/StreamSelect';

import { Field } from 'components/Field/Field';
import { Input } from 'components/Input/Input';

import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { useWidgetModal } from '../WidgetModal';
import { WidgetFieldValues, WidgetFormProps } from './DataForm';

type StreamWidgetFieldValues = WidgetFieldValues & {
  stream: Stream;
  autoPlay: boolean;
};

type StreamFormProps = WidgetFormProps<StreamWidget>;

export function StreamForm({
  widget,
  dashboard,
  onIsDirtyChange,
  onUpdate,
}: StreamFormProps) {
  const { onClose } = useWidgetModal();

  const { mutate, isLoading: isUpdating } = useUpdateDashboard({
    onSuccess(data) {
      resetForm(data);
      onClose();
    },
  });

  const { data: stream, isInitialLoading: isLoadingStream } = useStream(
    widget?.streamId,
    { enabled: !!widget }
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<StreamWidgetFieldValues>({
    defaultValues: {
      name: widget?.name,
      autoPlay: widget?.autoPlay ?? true,
    },
  });

  React.useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  React.useEffect(() => {
    onUpdate(isUpdating || isLoadingStream);
  }, [isLoadingStream, isUpdating, onUpdate]);

  React.useEffect(() => {
    reset({ stream });
  }, [reset, stream]);

  function resetForm(updatedDashboard: Dashboard) {
    if (!widget?.id) {
      return;
    }

    const updatedWidget = updatedDashboard.getWidget(widget.id) as StreamWidget;

    if (!updatedWidget) {
      return;
    }

    reset({ name: updatedWidget.name, autoPlay: updatedWidget.autoPlay });
  }

  function onSubmit({ name, stream, autoPlay }: StreamWidgetFieldValues) {
    if (!dashboard || !stream || !stream.id) {
      return;
    }

    const id = widget?.id || generateId();
    // FIXME Replace with proper layout calculation
    const layout: Omit<RGL.Layout, 'i'> =
      widget?.layout || dashboard.getNewWidgetLayout(4, 2);

    mutate(
      dashboard.upsertWidget(
        new StreamWidget(id, name, stream.id, autoPlay, layout)
      )
    );
  }

  return (
    <form id="stream-widget-form" onSubmit={handleSubmit(onSubmit)}>
      <Field label="Name" error={errors.name}>
        <Input
          {...register('name', {
            required: 'Please choose a name for this widget.',
          })}
          type="text"
          autoComplete="off"
          spellCheck="false"
        />
      </Field>
      <Field label="Start playing automatically">
        <LightSwitch id="autplay" {...register('autoPlay')} />
      </Field>
      <Field label="Stream" error={errors.stream}>
        <Controller
          name="stream"
          control={control}
          render={({ field: { onChange, value } }) => (
            <StreamSelect
              value={value}
              onChange={onChange}
              queryFilters={{
                stream_types: [StreamType.WEBRTC, StreamType.RTSP],
              }}
              isLoading={isLoadingStream}
            />
          )}
          rules={{ required: true }}
        />
      </Field>
    </form>
  );
}
