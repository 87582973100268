import { useCamera } from 'cameras/hooks/useCamera';
import { useStream } from 'streams/hooks/useStream';
import { VideoSource } from 'hooks/api/useDeploymentsVideoSources';
import { VideoSourceSelection } from 'pipelines/services/rete/controls/video_source_selector';

export function useVideoSource(
  videoSource?: VideoSource | VideoSourceSelection | null
) {
  const id =
    videoSource &&
    typeof videoSource === 'object' &&
    ('id' in videoSource
      ? videoSource.id
      : (videoSource as VideoSourceSelection).source_id);
  const type = videoSource && videoSource.source_type;

  const cameraQueryResult = useCamera(id || undefined, {
    enabled: type === 'camera',
  });
  const streamQueryResult = useStream(id || undefined, {
    enabled: type === 'stream',
  });

  if (type === 'camera') {
    return cameraQueryResult;
  }

  return streamQueryResult;
}
