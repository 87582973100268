import Rete, { Node } from 'rete';

import Component from 'pipelines/services/rete/nodes/component';
import ReteRadioSelect from 'pipelines/services/rete/controls/radio_select';
import ReteNumberInput from 'pipelines/services/rete/controls/number_input';
import ReteOptionalRangeSlider from '../controls/range_slider_optional';
import { UnencodedVideo } from 'pipelines/services/rete/sockets';
import {
  PROFILE_OPTIONS,
  TRACKER_OPTIONS,
  TrackerTypeDisplayer,
  getTrackerTypeValue,
} from 'pipelines/services/rete/displayers/track_objects';

export default class TrackObjects extends Component {
  static key = 'Track Objects';
  static icon = 'track-objects' as const;
  static exportType = 'track';
  static category = 'recognition' as const;
  static description =
    'Track detected objects and assign a unique identifier to each new object';

  static editorDisplayers = {
    tracker: TrackerTypeDisplayer,
  };
  static input = {
    type: 'raw',
  };
  static output = {
    type: 'raw',
  };
  static inheritOutputFromInput = true;

  static outputMetadata = ['Object ID'];

  constructor() {
    super(TrackObjects.key);
  }

  async builder(node: Node) {
    node
      .addControl(
        new ReteRadioSelect(this.editor!, 'tracker', {
          label: 'Accuracy',
          type: 'json',
          initial: getTrackerTypeValue('dcf'),
          options: TRACKER_OPTIONS,
        })
      )
      .addControl(
        new ReteRadioSelect(this.editor!, 'profile', {
          label: 'Profile',
          initial: 'default',
          options: PROFILE_OPTIONS,
          keyref: 'tracker',
        })
      )
      .addControl(
        new ReteOptionalRangeSlider(
          this.editor!,
          'custom_properties.min_iou_diff',
          {
            label: 'Duplicate merging',
            info: 'Enable to adjust threshold for reducing potential duplicates.',
            input: true,
            scale: 0.05,
            step: 0.01,
            defaultInitial: 0.9,
            min: 0.01,
            minLabel: 'Low overlap',
            max: 0.99,
            maxLabel: 'High overlap',
            keyref: 'profile',
            requiresKeyrefValue: 'custom',
          }
        )
      )
      .addControl(
        new ReteNumberInput(this.editor!, 'custom_properties.activation_age', {
          label: 'Minimum detection period',
          info: 'Number of consecutive frames a new object must be detected for before being tracked.',
          unit: 'frames',
          initial: 3,
          min: 1,
          step: 1,
          keyref: 'profile',
          requiresKeyrefValue: 'custom',
        })
      )
      .addControl(
        new ReteOptionalRangeSlider(
          this.editor!,
          'custom_properties.min_iou_score',
          {
            label: 'Minimum IoU Threshold',
            info: 'Minimum Intersection over Union (IoU) score to match objects across consecutive frames.',
            input: true,
            scale: 0.05,
            step: 0.01,
            defaultInitial: 0.3,
            min: 0.01,
            minLabel: 'Low',
            max: 0.99,
            maxLabel: 'High',
            keyref: 'profile',
            requiresKeyrefValue: 'custom',
          }
        )
      )
      .addControl(
        new ReteOptionalRangeSlider(
          this.editor!,
          'custom_properties.min_visual_similarity_score',
          {
            label: 'Visual Similarity Threshold',
            info: 'Minimum similarity score of visual features to match objects across consecutive frames.',
            input: true,
            scale: 0.05,
            step: 0.01,
            defaultInitial: 0.5,
            min: 0.01,
            minLabel: 'Low',
            max: 0.99,
            maxLabel: 'High',
            keyref: 'profile',
            requiresKeyrefValue: 'custom',
          }
        )
      )
      .addControl(
        new ReteOptionalRangeSlider(
          this.editor!,
          'custom_properties.min_tracker_confidence',
          {
            label: 'Shadow Track Threshold',
            info: 'If the confidence of a tracked object is lower than this, then it will be tracked in shadow mode: i.e. kept in memory in case it reappears later.',
            input: true,
            scale: 0.05,
            step: 0.01,
            defaultInitial: 0.2,
            min: 0.01,
            minLabel: 'Low',
            max: 0.99,
            maxLabel: 'High',
            keyref: 'profile',
            requiresKeyrefValue: 'custom',
          }
        )
      )
      .addControl(
        new ReteNumberInput(
          this.editor!,
          'custom_properties.max_shadow_tracking_age',
          {
            label: 'Maximum Shadow Duration',
            info: 'If tracking for an object is lost and the shadow tracking age exceeds this limit (in number of frames), it will no longer be tracked.',
            unit: 'frames',
            initial: 150,
            min: 1,
            step: 1,
            keyref: 'profile',
            requiresKeyrefValue: 'custom',
          }
        )
      )
      .addControl(
        new ReteNumberInput(
          this.editor!,
          'custom_properties.max_targets_per_stream',
          {
            label: 'Maximum Objects Tracked',
            info: 'Maximum number of tracked objects per each stream.',
            unit: 'objects',
            initial: 150,
            min: 1,
            step: 1,
            keyref: 'profile',
            requiresKeyrefValue: 'custom',
          }
        )
      )
      .addInput(new Rete.Input('input', 'Input', UnencodedVideo, false))
      .addOutput(new Rete.Output('output', 'Output', UnencodedVideo));
  }
}
