import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import OrganizationInvitation, {
  OrganizationInvitationStatus,
} from 'types/invitation';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useHasAccess } from 'hooks/useHasAccess';

export type UseOrganizationInvitationsParams = OffsetPaginationParams & {
  emails?: string[];
  invitation_ids?: string[];
  inviter_ids?: string[];
  statuses?: OrganizationInvitationStatus[];
};

export type OrganizationInvitationsOptions = UseQueryOptions<
  PaginatedAPIResponse<OrganizationInvitation>,
  APIError
>;

/** List invitations in an organization */
export function useOrganizationInvitations(
  queryKey: QueryKey = ['organization-invitations'],
  {
    pagination = 'offset',
    page = 1,
    limit = 50,
    ...filters
  }: UseOrganizationInvitationsParams = {},
  { enabled, ...options }: OrganizationInvitationsOptions = {}
) {
  const { applicationID, organizations } = useAPI();
  const { data: organization } = useCurrentOrganization();

  const organizationId = organization?.id;

  const [hasAccess] = useHasAccess();
  const params = { pagination, page, limit, ...filters };

  return useAuthenticatedQuery(
    [...queryKey, applicationID, params],
    () => {
      return organizationId
        ? organizations.invitations.list(organizationId, params)
        : Promise.reject('No organizationId provided');
    },
    {
      enabled:
        enabled !== undefined
          ? enabled &&
            Boolean(organizationId) &&
            hasAccess('organization_settings')
          : Boolean(organizationId) && hasAccess('organization_settings'),
      ...options,
    }
  );
}
