import React from 'react';
import classNames from 'classnames/bind';
import {
  useDragOver,
  RenderParams,
  NodeModel,
} from '@minoru/react-dnd-treeview';
import { useNavigate, useLocation } from 'react-router-dom';

import { Tag } from 'types/tag';

import { useTags } from 'tags/hooks/useTags';

import { Checkbox } from 'components/Checkbox/Checkbox';
import { Icon } from 'components/Icon/Icon';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';

import { TagsTreeItemContextMenu } from './ItemContextMenu';
import { TagsTreeSkeletonItem } from './SkeletonItem';
import { TagsTreeStaticItem } from './StaticItem';
import styles from './TagsTree.module.scss';

const c = classNames.bind(styles);

export type TagsTreeItemProps = RenderParams & {
  className?: string;
  tag: Tag | undefined;
  node: NodeModel<Tag>;
  isSelected?: boolean;
  isIndeterminate?: boolean;
  onSelectionChange?: (tag: Tag, checked: boolean) => void;
  onLoad: (tagID: Tag['id'], tags: Tag[]) => void;
};

export function TagsTreeItem({
  className,
  tag,
  isSelected,
  isIndeterminate,
  onSelectionChange,
  onLoad,
  ...props
}: TagsTreeItemProps) {
  const { isOpen, depth, hasChild, isDragging, onToggle } = props;

  const { data, isLoading } = useTags({
    params: { parents: tag ? [tag.id] : undefined },
    enabled: Boolean(tag) && isOpen,
  });

  React.useEffect(() => {
    if (!tag?.id || !data) {
      return;
    }

    onLoad(tag.id, data.data);
  }, [data, onLoad, tag?.id]);

  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const dragOverProps = useDragOver(tag?.id ?? 0, isOpen, onToggle);
  const canSelect = Boolean(onSelectionChange);

  if (!tag) {
    if (canSelect) {
      return null;
    }

    return <TagsTreeStaticItem {...props} />;
  }

  const searchParams = new URLSearchParams(search);
  const isActive = searchParams.get('tag') === tag.id;

  const isExpandable = hasChild || tag.children.length > 0;

  // Override click handler for ?tag=id link to prevent page reload
  // and keep all other functionalities of the link (copy link, open in new tab)
  function handleLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
    if (!tag) {
      return;
    }

    event.preventDefault();
    navigate({ pathname, search: `?tag=${tag.id}` });
  }

  function handleSelectionChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!tag) {
      return;
    }

    onSelectionChange?.(tag, event.target.checked);
  }

  return (
    <>
      <div
        {...dragOverProps}
        className={c(className, 'item', {
          expandable: isExpandable,
          expanded: isOpen,
          dragging: isDragging,
          active: !canSelect && isActive,
          interactive: !canSelect,
        })}
        style={{ '--depth': depth } as React.CSSProperties}
      >
        {isExpandable && (
          <Icon
            name="chevron-right"
            size="xsmall"
            className={c('item-expand')}
            onClick={onToggle}
          />
        )}

        {canSelect ? (
          <>
            <Checkbox
              id={`tag_checkbox_${tag.id}`}
              className={c('item-check')}
              onChange={handleSelectionChange}
              checked={isSelected}
              indeterminate={!isSelected && isIndeterminate}
            />
            <label
              htmlFor={`tag_checkbox_${tag.id}`}
              className={c('item-label')}
            >
              {tag.name}
            </label>
          </>
        ) : (
          <>
            <a
              className={c('item-link')}
              href={`${pathname}?tag=${tag.id}`}
              onClick={handleLinkClick}
            >
              {tag.name}
            </a>

            <TagsTreeItemContextMenu tag={tag} />
          </>
        )}
      </div>

      {isLoading && isOpen && (
        <div style={{ '--depth': (depth ?? 0) + 1 } as React.CSSProperties}>
          <SkeletonList
            min={tag.children.length}
            max={tag.children.length}
            component={<TagsTreeSkeletonItem />}
          />
        </div>
      )}
    </>
  );
}
