export type Hsla = {
  h: number;
  s: number;
  l: number;
  a: number;
};

export const MAIN_CHART_COLOR: Hsla = { h: 198, s: 90, l: 60, a: 1 };

export const CHART_COLORS: Hsla[] = [
  MAIN_CHART_COLOR,
  // Pipeline node category colors, slightly desaturated
  { h: 175, s: 70, l: 60, a: 1 },
  { h: 25, s: 90, l: 65, a: 1 },
  { h: 250, s: 50, l: 53, a: 1 },
  { h: 100, s: 70, l: 44, a: 1 },
  { h: 325, s: 50, l: 50, a: 1 },
  // Randomly generated
  { h: 0, s: 84, l: 69, a: 1 },
  { h: 138, s: 78, l: 63, a: 1 },
  { h: 266, s: 75, l: 69, a: 1 },
  { h: 34, s: 49, l: 42, a: 1 },
  { h: 203, s: 78, l: 65, a: 1 },
  { h: 172, s: 46, l: 50, a: 1 },
  { h: 59, s: 48, l: 42, a: 1 },
  { h: 57, s: 73, l: 87, a: 1 },
  { h: 248, s: 54, l: 64, a: 1 },
  { h: 187, s: 48, l: 66, a: 1 },
  { h: 347, s: 75, l: 70, a: 1 },
  { h: 118, s: 64, l: 54, a: 1 },
  { h: 163, s: 96, l: 55, a: 1 },
  { h: 72, s: 79, l: 77, a: 1 },
  { h: 212, s: 97, l: 48, a: 1 },
  { h: 168, s: 52, l: 46, a: 1 },
  { h: 314, s: 84, l: 40, a: 1 },
  { h: 162, s: 71, l: 42, a: 1 },
  { h: 269, s: 42, l: 67, a: 1 },
  { h: 137, s: 85, l: 50, a: 1 },
  { h: 208, s: 48, l: 74, a: 1 },
  { h: 17, s: 47, l: 45, a: 1 },
  { h: 358, s: 88, l: 55, a: 1 },
  { h: 102, s: 86, l: 54, a: 1 },
  { h: 199, s: 76, l: 77, a: 1 },
  { h: 186, s: 50, l: 52, a: 1 },
  { h: 332, s: 62, l: 83, a: 1 },
  { h: 198, s: 55, l: 89, a: 1 },
  { h: 23, s: 75, l: 87, a: 1 },
  { h: 344, s: 53, l: 70, a: 1 },
  { h: 265, s: 68, l: 86, a: 1 },
  { h: 223, s: 59, l: 89, a: 1 },
  { h: 53, s: 82, l: 40, a: 1 },
  { h: 182, s: 42, l: 88, a: 1 },
  { h: 348, s: 93, l: 47, a: 1 },
  { h: 293, s: 45, l: 71, a: 1 },
  { h: 229, s: 85, l: 87, a: 1 },
  { h: 204, s: 46, l: 60, a: 1 },
  { h: 17, s: 91, l: 50, a: 1 },
  { h: 149, s: 74, l: 52, a: 1 },
  { h: 19, s: 55, l: 67, a: 1 },
  { h: 188, s: 98, l: 63, a: 1 },
  { h: 300, s: 69, l: 88, a: 1 },
  { h: 74, s: 54, l: 89, a: 1 },
  { h: 307, s: 89, l: 43, a: 1 },
  { h: 135, s: 91, l: 45, a: 1 },
  { h: 119, s: 79, l: 44, a: 1 },
  { h: 29, s: 84, l: 50, a: 1 },
  { h: 203, s: 86, l: 66, a: 1 },
  { h: 87, s: 47, l: 75, a: 1 },
  { h: 38, s: 96, l: 59, a: 1 },
  { h: 216, s: 50, l: 72, a: 1 },
  { h: 152, s: 86, l: 70, a: 1 },
  { h: 39, s: 51, l: 42, a: 1 },
  { h: 120, s: 53, l: 75, a: 1 },
  { h: 296, s: 91, l: 82, a: 1 },
  { h: 175, s: 73, l: 47, a: 1 },
  { h: 306, s: 62, l: 87, a: 1 },
  { h: 192, s: 50, l: 60, a: 1 },
  { h: 236, s: 50, l: 70, a: 1 },
  { h: 145, s: 81, l: 75, a: 1 },
  { h: 21, s: 54, l: 85, a: 1 },
  { h: 219, s: 75, l: 80, a: 1 },
  { h: 26, s: 53, l: 70, a: 1 },
  { h: 168, s: 66, l: 54, a: 1 },
  { h: 158, s: 53, l: 60, a: 1 },
  { h: 243, s: 61, l: 49, a: 1 },
  { h: 195, s: 89, l: 69, a: 1 },
  { h: 95, s: 73, l: 74, a: 1 },
  { h: 357, s: 73, l: 53, a: 1 },
  { h: 286, s: 87, l: 47, a: 1 },
  { h: 253, s: 46, l: 52, a: 1 },
  { h: 13, s: 44, l: 83, a: 1 },
  { h: 110, s: 43, l: 55, a: 1 },
  { h: 74, s: 72, l: 81, a: 1 },
  { h: 5, s: 44, l: 59, a: 1 },
  { h: 118, s: 86, l: 82, a: 1 },
  { h: 243, s: 87, l: 61, a: 1 },
  { h: 47, s: 94, l: 47, a: 1 },
  { h: 99, s: 66, l: 75, a: 1 },
  { h: 348, s: 88, l: 88, a: 1 },
  { h: 302, s: 93, l: 47, a: 1 },
  { h: 37, s: 94, l: 84, a: 1 },
  { h: 245, s: 83, l: 52, a: 1 },
  { h: 134, s: 73, l: 80, a: 1 },
  { h: 116, s: 50, l: 85, a: 1 },
  { h: 184, s: 84, l: 58, a: 1 },
  { h: 220, s: 51, l: 64, a: 1 },
  { h: 334, s: 64, l: 56, a: 1 },
  { h: 38, s: 48, l: 62, a: 1 },
  { h: 240, s: 64, l: 63, a: 1 },
  { h: 347, s: 72, l: 73, a: 1 },
  { h: 61, s: 81, l: 60, a: 1 },
  { h: 339, s: 70, l: 73, a: 1 },
  { h: 331, s: 65, l: 48, a: 1 },
  { h: 291, s: 83, l: 62, a: 1 },
  { h: 43, s: 75, l: 69, a: 1 },
  { h: 112, s: 64, l: 69, a: 1 },
  { h: 11, s: 46, l: 90, a: 1 },
];

export const CSS_CHART_COLORS = CHART_COLORS.map((color) =>
  hslaToString(color)
);

export function hslaToString({ h, s, l, a }: Hsla) {
  return `hsla(${h}, ${s}%, ${l}%, ${a})`;
}

export async function mapLabelToChartColor(string: string) {
  const data = new TextEncoder().encode(string);
  const buffer = await window.crypto.subtle.digest('SHA-256', data);
  const hash = Array.from(new Uint8Array(buffer))
    .map((number) => number.toString(16).padStart(2, '0'))
    .join('');

  const hashParts = [
    parseInt(hash.slice(0, 8), 16),
    parseInt(hash.slice(8, 16), 16),
    parseInt(hash.slice(16, 24), 16),
    parseInt(hash.slice(24, 32), 16),
  ];

  const range = CHART_COLORS.length + 1;
  const sum = hashParts.reduce((total, part) => total + part, 0);

  return CHART_COLORS[sum % range];
}
