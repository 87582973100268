import React from 'react';
import classnames from 'classnames/bind';

import { Icon } from 'components/Icon/Icon';

import { CropInputField } from './CropInputField';
import styles from './CropInput.module.scss';

const c = classnames.bind(styles);

export type CropInputValues = {
  top: number;
  left: number;
  right: number;
  bottom: number;
};

export type CropInputProps = {
  id: string;
  value?: string;
  onChange: (values: string) => void;
};

const defaultValue = '0:0:0:0';

function parseValue(value: string | number) {
  return Number.isFinite(Number(value)) ? Number(value) : 0;
}

function getValuesFromString(value: string = defaultValue): CropInputValues {
  const [left, right, top, bottom] = (value || defaultValue).split(':');
  return {
    left: parseValue(left),
    right: parseValue(right),
    top: parseValue(top),
    bottom: parseValue(bottom),
  };
}

function getStringFromValues({ left, right, top, bottom }: CropInputValues) {
  return `${left || 0}:${right || 0}:${top || 0}:${bottom || 0}`;
}

export function CropInput({
  id,
  value = defaultValue,
  onChange,
}: CropInputProps) {
  const crop = getValuesFromString(value);

  const { top, left, right, bottom } = crop;

  function handleChange(field: keyof CropInputValues) {
    return function (newValue: string | number) {
      const updatedCrop = { ...crop, [field]: parseValue(newValue) };
      onChange(getStringFromValues(updatedCrop));
    };
  }

  return (
    <div className={c('wrap')}>
      <div className={c('grid')}>
        <CropInputField
          parentID={id}
          id="top"
          value={top}
          onChange={handleChange('top')}
        />
        <CropInputField
          parentID={id}
          id="right"
          value={right}
          onChange={handleChange('right')}
        />
        <div className={c('visual')} aria-hidden="true">
          <Icon name="camera" />
        </div>
        <CropInputField
          parentID={id}
          id="bottom"
          value={bottom}
          onChange={handleChange('bottom')}
        />
        <CropInputField
          parentID={id}
          id="left"
          value={left}
          onChange={handleChange('left')}
        />
      </div>
    </div>
  );
}
