import { serializeAll } from 'serializr';

import { IconType } from 'components/Icon/Icon';

@serializeAll
export default class Category {
  label: string;

  icon_name: IconType;

  description: string;

  is_highlighted = false;

  /**
   * ISO date string
   */
  created_at: string;

  /**
   * ISO date string
   */
  updated_at: string;

  constructor(
    label: string,
    iconName: IconType,
    description: string,
    isHighlighted: boolean = false
  ) {
    this.label = label;
    this.icon_name = iconName;
    this.description = description;
    this.is_highlighted = isHighlighted;

    const now = new Date().toISOString();
    this.created_at = now;
    this.updated_at = now;
  }
}
