import React from 'react';
import classnames from 'classnames/bind';
import { useForm } from 'react-hook-form';

import { useAccount, useUpdateAccount } from 'hooks/api/useAccount';
import { useAuthentication } from 'hooks/api/useAuthentication';

import { NotFound } from 'views/NotFound/NotFound';

import * as PageLayout from 'components/PageLayout';
import { AccountRoles } from 'account/components/Roles/Roles';
import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import { Loader } from 'components/Loader/Loader';

import { LumeoVersion } from 'account/components/LumeoVersion/LumeoVersion';

import styles from '../Settings.module.scss';

const c = classnames.bind(styles);

export type UpdateAccountParams = {
  name?: string;
};

export function AccountSettings() {
  const { data: account, isLoading } = useAccount();
  const { isLoading: isAuthenticating } = useAuthentication();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<UpdateAccountParams>();

  const { mutate, isLoading: isSaving } = useUpdateAccount();

  React.useEffect(() => {
    if (!account) {
      return;
    }

    reset({
      name: account.name,
    });
  }, [reset, account]);

  if (isLoading || isAuthenticating) {
    return <Loader text="Loading your account settings..." />;
  }

  if (!account) {
    return <NotFound />;
  }

  function onSubmit(values: UpdateAccountParams) {
    mutate(values);
  }

  return (
    <PageLayout.Root size="small">
      <PageLayout.Container>
        <div className={c('wrap', 'settings')}>
          <Box variant="secondary">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Heading level="3">Account</Heading>
              <Field id="display-name" label="Display name" error={errors.name}>
                <Input {...register('name')} placeholder={account.email} />
              </Field>

              <Field id="email" label="Email address">
                <Input
                  type="email"
                  name="email"
                  value={account.email}
                  readOnly
                />
              </Field>

              <Button type="submit" variant="primary" loading={isSaving}>
                Save
              </Button>
            </form>
          </Box>

          <section className={c('section')}>
            <Heading level="3">Organizations and workspaces</Heading>
            <AccountRoles account={account} />
          </section>

          <section className={c('section')}>
            <LumeoVersion />
          </section>

          <section className={c('section')}>
            <Heading level="2">Delete Account</Heading>
            <p>
              Please{' '}
              <a
                className="link"
                href="mailto:support@lumeo.com?subject=Delete my Lumeo account"
              >
                contact us
              </a>{' '}
              if you want to remove your account.
            </p>
          </section>
        </div>
      </PageLayout.Container>
    </PageLayout.Root>
  );
}
