import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';

import { useCreateMarketplaceModel } from 'hooks/api/useMarketplaceModel';
import { useAppRedirect } from 'hooks/useAppRedirect';

import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import * as PageLayout from 'components/PageLayout';

import { ModelFormProvider } from 'analytics-library/components/MultiStepForm';
import { CreateModelViewStepper } from './CreateModelViewStepper';
import styles from './CreateModelView.module.scss';

const c = classNames.bind(styles);

export function CreateMarketplaceModelView() {
  const redirect = useAppRedirect();

  const {
    mutateAsync,
    isLoading: isCreating,
    error,
  } = useCreateMarketplaceModel({
    onSuccess: () => redirect(`/admin/marketplace-models`),
  });

  return (
    <ModelFormProvider
      baseUrl="/admin/marketplace-models/new"
      onSave={mutateAsync}
      isSaving={isCreating}
      error={error}
      isMarketplace
    >
      <PageLayout.Root size="small">
        <PageLayout.Header className={c('header')}>
          <div>
            <Text>
              <Icon name="marketplace" size="small" />
              <span>Analytics Library</span>
            </Text>
            <PageLayout.Title>Create new AI model</PageLayout.Title>

            <CreateModelViewStepper />
          </div>
        </PageLayout.Header>

        <PageLayout.Content>
          <Outlet />
        </PageLayout.Content>
      </PageLayout.Root>
    </ModelFormProvider>
  );
}
