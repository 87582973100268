import React from 'react';

import { RequiredKeys } from 'types/utility_types';

import { MODAL_REGISTRY } from 'context/modal/registry';

type HasRequiredProps<T> = RequiredKeys<T> extends never ? false : true;

type ModalKey = keyof typeof MODAL_REGISTRY;
type ModalState = Record<
  string,
  { open: boolean; props: React.ComponentProps<any> }
>;

type OpenFunction = <K extends ModalKey>(
  key: K,
  ...props: HasRequiredProps<
    React.ComponentProps<(typeof MODAL_REGISTRY)[K]>
  > extends true
    ? [props: React.ComponentProps<(typeof MODAL_REGISTRY)[K]>]
    : [props?: React.ComponentProps<(typeof MODAL_REGISTRY)[K]>]
) => void;

export type CloseFunction = <K extends ModalKey>(key?: K) => void;

type ModalContextType = {
  open: OpenFunction;
  close: CloseFunction;
};

export const ModalKeyContext = React.createContext<ModalKey | undefined>(
  undefined
);

export const ModalContext = React.createContext<ModalContextType | undefined>(
  undefined
);

export function ModalProvider({ children }: React.PropsWithChildren) {
  const [modals, setModals] = React.useState<ModalState>({});

  const open: OpenFunction = React.useCallback((key, ...[props = {}]) => {
    setModals((previousState) =>
      sanitize(key, {
        ...previousState,
        [key]: { props, open: true },
      })
    );
  }, []);

  const close: CloseFunction = React.useCallback((key) => {
    if (!key) {
      return;
    }

    setModals((previousState) => {
      const newState = { ...previousState };

      if (key in newState) {
        newState[key] = { ...newState[key], open: false };
      }

      return sanitize(key, newState);
    });
  }, []);

  return (
    <ModalContext.Provider value={{ open, close }}>
      {children}
      {Object.entries(modals).map(([key, { open, props }]) => {
        const modalKey = key as ModalKey;
        const Modal = MODAL_REGISTRY[modalKey] as any;
        return (
          <ModalKeyContext.Provider key={key} value={modalKey}>
            <Modal {...props} key={key} open={open} />
          </ModalKeyContext.Provider>
        );
      })}
    </ModalContext.Provider>
  );
}

function sanitize(key: ModalKey, state: ModalState) {
  const modals = { ...state };

  for (const modalKey in modals) {
    const modal = modals[modalKey];
    if (modalKey !== key && modal && !modal.open) {
      delete modals[modalKey];
    }
  }

  return modals;
}
