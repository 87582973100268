import { UseQueryOptions } from '@tanstack/react-query';

import { usePropelQuery } from 'graphql/hooks/usePropelQuery';

import {
  METRIC_REPORT,
  MetricReportQueryVariables,
  MetricReportResponse,
} from '../queries/metric_report';

export function useMetricReport(
  variables: MetricReportQueryVariables,
  options?: UseQueryOptions<MetricReportResponse>
) {
  return usePropelQuery<
    MetricReportResponse,
    { input: MetricReportQueryVariables }
  >(METRIC_REPORT, { input: variables }, ['metric-report'], options);
}
