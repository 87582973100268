import React from 'react';
import classNames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';

import { getNameFromStreamURI } from 'streams/services/input_streams';

import { Field } from 'components/Field/Field';
import { IconButton } from 'components/IconButton/IconButton';
import { Input } from 'components/Input/Input';

import { LinkFilesFieldValues, LinkFilesFormRowValue } from './types';
import styles from './LinkFiles.module.scss';

const c = classNames.bind(styles);

export type LinkFilesFormRowProps = {
  files: LinkFilesFormRowValue[];
  index: number;
  onRemove: (index: number) => void;
};

export function LinkFilesFormRow({
  files,
  index,
  onRemove,
}: LinkFilesFormRowProps) {
  const {
    formState: { errors },
    watch,
    register,
    getValues,
  } = useFormContext<LinkFilesFieldValues>();

  const uriId = `files.${index}.uri` as const;
  const nameId = `files.${index}.name` as const;

  const uri = watch(uriId, '');
  const uriError = errors.files?.[index]?.uri?.message;

  const isNameRequired =
    Boolean(uri.trim()) && !getNameFromStreamURI(uri).trim();
  const nameError = errors.files?.[index]?.name?.message;

  const isOnlyRow = files.length === 1;

  return (
    <fieldset>
      <div className={c('row')}>
        <Field id={uriId} label="URI" className={c('row-field')}>
          <Input
            id={uriId}
            placeholder="http://user:pass@0.0.0.0/video/1"
            error={Boolean(uriError)}
            {...register(uriId, {
              validate: {
                required: (value) => {
                  const nameValue = getValues(nameId);
                  const hasName =
                    typeof nameValue === 'string' && nameValue.trim();

                  if (!value?.trim() && hasName) {
                    return 'Please enter a file URI.';
                  }

                  return true;
                },
                isValidURI: (value) => {
                  if (!value.trim()) {
                    return true;
                  }

                  try {
                    new URL(value);
                    return true;
                  } catch (error) {
                    return 'Please enter a valid file URI.';
                  }
                },
              },
            })}
          />
        </Field>
        <Field
          id={nameId}
          label="Name (optional)"
          className={c('row-field')}
          required={isNameRequired}
        >
          <Input
            id={nameId}
            placeholder={getNameFromStreamURI(uri)}
            {...register(nameId, {
              validate: {
                required(name) {
                  if (isNameRequired && !name?.trim()) {
                    return 'Please enter a stream name.';
                  }

                  return true;
                },
              },
            })}
          />
        </Field>
        <IconButton
          icon="delete"
          label="Remove row"
          className={c('row-button')}
          onClick={() => onRemove(index)}
          disabled={isOnlyRow}
        />
      </div>

      {uriError && <p className="form-error theme danger">{uriError}</p>}
      {nameError && <p className="form-error theme danger">{nameError}</p>}
    </fieldset>
  );
}
