import { Condition } from 'pipelines/services/condition_parser';

export function getFirstConditionError({
  accessor,
  comparator,
  value,
  and,
  or,
}: Condition): string | undefined {
  const topLevelError = accessor.error ?? value.error ?? comparator.error;

  if (topLevelError) {
    return topLevelError;
  }

  if (and) {
    for (const chainedCondition of and) {
      const chainedError = getFirstConditionError(chainedCondition);

      if (chainedError) {
        return chainedError;
      }
    }
  }

  if (or) {
    for (const chainedCondition of or) {
      const chainedError = getFirstConditionError(chainedCondition);

      if (chainedError) {
        return chainedError;
      }
    }
  }

  return undefined;
}
