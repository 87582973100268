import Rete, { Node } from 'rete';

import Component from 'pipelines/services/rete/nodes/component';
import { EncodedVideo } from 'pipelines/services/rete/sockets';
import { VideoSourceIDDisplayer } from 'pipelines/services/rete/displayers/video_source';

export default class WebRTCStreamOut extends Component {
  static key = 'Stream WebRTC';
  static icon = 'stream-out' as const;
  static exportType = 'stream_webrtc_out';
  static category = 'media' as const;
  static description =
    'Create a WebRTC output stream to use as input stream in other pipelines or view in a web browser';

  protected static editorDisplayers = {
    stream_id: VideoSourceIDDisplayer,
  };
  static input = {
    type: 'encoded',
  };

  constructor() {
    super(WebRTCStreamOut.key);
  }

  async builder(node: Node) {
    node.addInput(new Rete.Input('input', 'Input', EncodedVideo, false));
  }
}
