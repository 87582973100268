import React from 'react';
import classnames from 'classnames/bind';

import styles from './CodeBlock.module.scss';

const c = classnames.bind(styles);

export type CodeBlockProps = React.PropsWithChildren<{
  className?: string;
  component?: 'pre' | 'div';
  theme?: string | null;
}>;

export function CodeBlock({
  className,
  component,
  theme = 'dark',
  children,
  ...props
}: CodeBlockProps) {
  const TagName = component || 'pre';

  return (
    <TagName className={c('code', `theme ${theme}`, className)} {...props}>
      {children}
    </TagName>
  );
}
