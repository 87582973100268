import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import { useHasAccess } from 'hooks/useHasAccess';

import { AccessTokens } from 'settings/views/AccessTokens/AccessTokens';
import { AccountSettings } from 'settings/views/Account/Account';
import { Metrics } from 'settings/views/Metrics/Metrics';
import { Organizations } from 'organizations/views';
import { PasswordSettings } from 'settings/views/Password/Password';
import { RequireAuth } from 'components/RequireAuth/RequireAuth';
import { UniversalBridge } from 'settings/views/UniversalBridge/UniversalBridge';
import { Workspace } from 'settings/views/Workspace/Workspace';

import { SettingsSidebar } from './Sidebar';
import styles from './Settings.module.scss';

const c = classnames.bind(styles);

export function Settings() {
  const redirectPath = useRedirectPath();

  return (
    <div className={c('settings')}>
      <SettingsSidebar />

      <Routes>
        <Route path="/" element={<Navigate to={redirectPath} replace />} />

        <Route
          path="access-tokens"
          element={
            <RequireAuth section="access_tokens">
              <AccessTokens />
            </RequireAuth>
          }
        />
        <Route
          path="universal-bridge"
          element={
            <RequireAuth section="universal_bridge">
              <UniversalBridge />
            </RequireAuth>
          }
        />
        <Route
          path="metrics"
          element={
            <RequireAuth section="metrics">
              <Metrics />
            </RequireAuth>
          }
        />
        <Route
          path="workspace"
          element={
            <RequireAuth section="application_settings">
              <Workspace />
            </RequireAuth>
          }
        />
        <Route path="organization/*" element={<Organizations />} />
        <Route path="account" element={<AccountSettings />} />
        <Route path="password" element={<PasswordSettings />} />
      </Routes>
    </div>
  );
}

function useRedirectPath() {
  const [hasAccess] = useHasAccess();

  if (hasAccess('access_tokens')) {
    return 'access-tokens';
  }

  if (hasAccess('universal_bridge')) {
    return 'universal-bridge';
  }

  if (hasAccess('application_settings')) {
    return 'workspace';
  }

  if (hasAccess('organization_settings') || hasAccess('billing')) {
    return 'organization';
  }

  return 'account';
}
