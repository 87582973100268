import React from 'react';
import classNames from 'classnames/bind';

import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { deserialize } from 'serializr';

import Gateway from 'types/gateway';
import Stream, { StreamType } from 'types/stream';
import { ApplicationParams } from 'application/types/application_params';

import { useAppRedirect } from 'hooks/useAppRedirect';
import { useCreateStream } from 'streams/hooks/useCreateStream';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useGateways } from 'hooks/api/useGateways';
import { getNameFromStreamURI } from 'streams/services/input_streams';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Card } from 'components/Card/Card';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Icon } from 'components/Icon/Icon';
import { Radio, RadioOption, RadioSelectOption } from 'components/Radio/Radio';

import { LinkFilesFormRow } from './Row';
import { LinkFilesFieldValues } from './types';
import styles from './LinkFiles.module.scss';

const c = classNames.bind(styles);

export type LinkFilesFormProps = {
  gateway?: Gateway;
  onSuccess?: () => void;
};

export function LinkFilesForm({ gateway, onSuccess }: LinkFilesFormProps) {
  const { applicationID } = useParams<ApplicationParams>();
  const { data: organization } = useCurrentOrganization();
  const redirect = useAppRedirect();

  const {
    mutate: createFileStream,
    isLoading,
    error,
  } = useCreateStream(['streams'], {
    action: 'upload',
    entity: 'files',
    onSuccess() {
      if (onSuccess) {
        onSuccess();
        return;
      }

      redirect('/deploy/files');
    },
  });
  const { control, handleSubmit, watch, register, ...methods } =
    useForm<LinkFilesFieldValues>({
      mode: 'onBlur',
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'files',
  });
  const files = watch('files');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...files[index],
    };
  });

  const { data: gateways } = useGateways();
  const gatewayOptions = gateways?.data.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  // Initialize form with two blank rows
  React.useEffect(() => {
    append({ uri: '', name: '' });
  }, [append]);

  function appendRow() {
    append({ uri: '', name: '' });
  }

  async function create({ access, files }: LinkFilesFieldValues) {
    for (const { uri, name } of files) {
      if (!uri) {
        continue;
      }

      const stream = deserialize(Stream, {
        application_id: applicationID,
        source: 'uri_stream',
        stream_type: StreamType.FILE,
        gateway_id: access === 'global' ? undefined : String(access),
        name: name?.trim() || getNameFromStreamURI(uri),
        uri,
      });

      createFileStream(stream);
    }
  }

  return (
    <form onSubmit={handleSubmit(create)}>
      <FormErrorMessage error={error} />

      {gateway ? (
        <input type="hidden" value={gateway.id} {...register('access')} />
      ) : (
        <Field
          label="Access"
          info={
            <>
              Global files are accessible by any gateway in your workspace.
              <br />
              Select a gateway if the files are accessible only through a
              specific gateway.
            </>
          }
        >
          <Controller
            name="access"
            defaultValue="global"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Radio
                id="new-input-file_type"
                onChange={(value) => onChange(String(value))}
                value={value}
              >
                <RadioOption value="global" icon="global">
                  Global
                </RadioOption>
                <RadioSelectOption icon="gateway" options={gatewayOptions}>
                  Gateway
                </RadioSelectOption>
              </Radio>
            )}
          />
        </Field>
      )}

      <FormProvider {...{ control, handleSubmit, watch, register, ...methods }}>
        <Card className={c('row-wrap')}>
          {controlledFields.map((field, index) => (
            <LinkFilesFormRow
              index={index}
              files={files}
              onRemove={remove}
              key={field.id}
            />
          ))}

          <div className={c('add-row-button')}>
            <Button variant="tertiary" onClick={appendRow} size="small">
              <Icon name="plus" size="small" />
              <span>Add file</span>
            </Button>
          </div>
        </Card>
      </FormProvider>

      <ButtonGroup>
        <Button
          type="submit"
          variant="primary"
          loading={isLoading}
          disabled={organization?.is_suspended}
        >
          Link all files
        </Button>
      </ButtonGroup>
    </form>
  );
}
