import classnames from 'classnames/bind';
import React from 'react';

import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';
import { objectHas } from 'services/object';

import { Button } from 'components/Button/Button';
import { Text } from 'components/Text/Text';

import styles from './WebRTCPlayer.module.scss';

import 'webrtc-adapter';

const c = classnames.bind(styles);

export type WebRTCPlayerProps = {
  className?: string;
  streamID: string;
  auto?: boolean;
};

export function WebRTCPlayer({ className, streamID, auto }: WebRTCPlayerProps) {
  const api = useAPI();
  const [mode, setMode] = React.useState<'loading' | 'error' | 'idle'>(
    'loading'
  );
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [attempts, setAttempts] = React.useState(1);

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const connection = React.useRef<RTCPeerConnection>();
  const peerID = React.useRef<string>();
  const shouldUseFallbackProfileLevel = React.useRef(false);

  React.useEffect(() => {
    setMode('loading');

    function showErrorState(error: APIError | any) {
      setMode('error');

      if (
        error.name === 'OperationError' ||
        error.name === 'InvalidAccessError' ||
        error.name === 'RTCError'
      ) {
        shouldUseFallbackProfileLevel.current = true;
      }

      if (objectHas(error, 'message')) {
        setErrorMessage(`${error.message}. Retrying in 2 seconds..`);
      } else {
        // default error, make it human-readable
        setErrorMessage(`${error.toLocaleString()}. Retrying in 2 seconds..`);
      }

      // Retry in 2 seconds.
      window.setTimeout(() => {
        setAttempts(attempts + 1);
      }, 2000);
    }

    function onRemoteDescriptionSet() {
      if (!connection.current) {
        return;
      }

      const options: RTCOfferOptions = { offerToReceiveVideo: true };

      connection.current
        .createAnswer(options)
        .then(onAnswerGenerated)
        .catch(showErrorState);
    }

    function onAnswerGenerated(value: RTCSessionDescriptionInit) {
      if (!connection.current) {
        return;
      }

      connection.current
        .setLocalDescription(value)
        .then(() => onLocalDescriptionSet(value))
        .catch(showErrorState);
    }

    function onLocalDescriptionSet(event: RTCSessionDescriptionInit) {
      if (!peerID.current) {
        return;
      }

      const { type, sdp } = event;

      api.webrtc
        .setAnswer(streamID, peerID.current, { type, sdp })
        .catch(showErrorState);

      pollCandidates();
    }

    function pollCandidates() {
      if (!peerID.current) {
        return;
      }

      api.webrtc
        .getCandidates(streamID, peerID.current)
        .then((res) => {
          if (res) {
            res.candidates.forEach(setRemoteCandidate);
            if (!res.completed) {
              setTimeout(pollCandidates, 1500);
            }
          }
        })
        .catch(showErrorState);
    }

    function setRemoteCandidate(candidate: RTCIceCandidateInit) {
      if (!connection.current) {
        return;
      }

      connection.current.addIceCandidate(candidate).catch(showErrorState);
    }

    api.webrtc
      .getOffer(streamID)
      .then((res) => {
        if (res) {
          const config = { iceServers: res.iceServers };

          peerID.current = res.peer_id;

          connection.current = new RTCPeerConnection(config);

          connection.current.onicegatheringstatechange = () => {};

          connection.current.onicecandidate = ({ candidate }) => {
            if (candidate && peerID.current) {
              api.webrtc.addCandidate(streamID, peerID.current, candidate);
            }
          };

          connection.current.ontrack = ({ streams }) => {
            if (videoRef.current) {
              [videoRef.current.srcObject] = streams;
            }
          };

          if (shouldUseFallbackProfileLevel.current) {
            // Replace profile-level-id in SDP to work around Safari and Firefox's refusal to
            // play other streams if the profile level id is not set to 42e01f.
            res.sdp = res.sdp.replace(
              /profile-level-id=(?!42)[0-9a-f]{6}/,
              'profile-level-id=42e01f'
            );
          }

          connection.current
            .setRemoteDescription(res)
            .then(onRemoteDescriptionSet)
            .catch(showErrorState);
        }
      })
      .catch(showErrorState);

    // Stop active connection on dismount or connection retry
    return () => {
      if (connection.current && peerID.current) {
        connection.current.onicegatheringstatechange = null;
        connection.current.onicecandidate = null;
        connection.current.ontrack = null;
        connection.current.oniceconnectionstatechange = null;
        connection.current.close();
        connection.current = undefined;
        api.webrtc.close(streamID, peerID.current).catch(showErrorState);
      }
    };
  }, [
    attempts, // retry on user input
    streamID,
    api.webrtc,
  ]);

  React.useEffect(() => {
    const loadedCheck = window.setInterval(() => {
      if (videoRef.current?.readyState === 4) {
        setMode('idle');
      }
    }, 100);

    return () => {
      window.clearInterval(loadedCheck);
    };
  }, [attempts]);

  return (
    <div id={streamID} className={c('wrap', className, mode, { auto })}>
      {mode === 'loading' && (
        <div className={c('loader')}>
          <div className="loading-spinner" />
        </div>
      )}

      <video ref={videoRef} className={c('video')} muted controls autoPlay />

      {mode === 'error' && (
        <div className={c('error-message')}>
          <p>
            Stream could not be loaded due to network issues or incompatible
            format.
            {errorMessage && (
              <Text inline>
                <br />
                {errorMessage}
              </Text>
            )}
          </p>
          <Button onClick={() => setAttempts(attempts + 1)} size="small">
            Try again
          </Button>
        </div>
      )}
    </div>
  );
}

export default WebRTCPlayer;
