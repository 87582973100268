import React from 'react';
import classnames from 'classnames/bind';
import { Node as ReteNode } from 'rete';

import { hyphenate } from 'services/string';
import { usePipelineDetailState } from 'pipelines/context/pipeline_detail_view';

import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Field } from 'components/Field/Field';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import { Textarea } from 'components/Textarea/Textarea';

import styles from '../NodePropertiesEditor.module.scss';

const c = classnames.bind(styles);

export type NodePropertiesEditorHeaderProps = {
  node?: ReteNode;
  nodeDescriptionsRef: React.MutableRefObject<Record<string, string>>;
};

export function NodePropertiesEditorHeader({
  node,
  nodeDescriptionsRef,
}: NodePropertiesEditorHeaderProps) {
  const { pipeline } = usePipelineDetailState();

  if (!node) {
    return null;
  }

  const nodeId = String(node.id);
  const description = pipeline.nodeDescriptions[nodeId];
  const docsUrl = `https://docs.lumeo.com/docs/${hyphenate(node.name)}-node`;

  function handleDescriptionChange({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) {
    nodeDescriptionsRef.current = {
      ...nodeDescriptionsRef.current,
      [nodeId]: value,
    };
  }

  return (
    <>
      <div className={c('header-controls')}>
        <ExternalLink href={docsUrl}>
          Visit <span className="sr-only">{node.name}</span> node documentation
        </ExternalLink>
      </div>

      <div className={c('deploy-time-header')}>
        <div className={c('deploy-time-info')}>
          <Field className={c('description')} label="Deploy instructions">
            <Textarea
              defaultValue={description}
              height="4rem"
              onChange={handleDescriptionChange}
            />
          </Field>
          <Text>
            <Icon name="deployment" size="small" />
            <span>Toggle to allow overriding property at deploy-time</span>
          </Text>
        </div>
        <div className={c('arrow-down')}></div>
      </div>
    </>
  );
}
