import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import { LumeoFile } from 'types/file';
import { RequireAtLeastOne } from 'types/utility_types';
import { UseFilesParams } from 'files/hooks/useFiles';

import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';

export type UseDeleteFilesParams = RequireAtLeastOne<UseFilesParams>;

export function useDeleteFiles({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<void, APIError, UseDeleteFilesParams> = {}) {
  const queryClient = useQueryClient();
  const { applicationID, client } = useAPI();

  return useMutation({
    mutationFn: (params) =>
      client.delete(`/apps/${applicationID}/files`, { params }),
    ...options,
    async onMutate(params) {
      await queryClient.cancelQueries(['files']);
      await queryClient.cancelQueries(['file']);

      onMutate?.(params);

      if ('file_ids' in params && params.file_ids) {
        return updateQueriesData<LumeoFile>({
          queryClient,
          listQueryKey: ['files'],
          singleQueryKey: ['file'],
          ids: params.file_ids,
          updateData: null,
        });
      }
    },
    onError(error, params, context) {
      onError?.(error, params, context);

      context?.previousLists?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSettled(...args) {
      onSettled?.(...args);
      queryClient.invalidateQueries(['files']);
      queryClient.invalidateQueries(['file']);
    },
  });
}
