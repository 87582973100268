import React from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import classNames from 'classnames/bind';

import styles from './Tabs.module.scss';

const c = classNames.bind(styles);

function TabsList(
  { children, className, ...props }: RadixTabs.TabsListProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <RadixTabs.List {...props} className={c(className, 'tabs-bar')} ref={ref}>
      {children}
    </RadixTabs.List>
  );
}

const forwardRef = React.forwardRef<HTMLDivElement, RadixTabs.TabsListProps>(
  TabsList
);

export { forwardRef as TabsList };
