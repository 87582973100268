import { useParams } from 'react-router-dom';

import { useModels } from 'hooks/api/useModels';
import { useModel } from 'hooks/api/useModel';

import { NotFound } from 'views/NotFound/NotFound';

import { ReactComponent as EmptyIllustration } from 'analytics-library/images/empty-models-illustration.svg';
import { ModelParams } from 'analytics-library/types/model_params';
import { ModelDetail } from './ModelDetail';

export function ModelDetailContainer() {
  const { modelID } = useParams<ModelParams>();
  const { isLoading } = useModels();
  const { data: model } = useModel(modelID);

  if (isLoading) {
    return (
      <div className="loading-spinner-wrap is-fullscreen">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (!model) {
    return (
      <NotFound
        entity="model"
        returnTo={{ pathname: '/design/models' }}
        image={EmptyIllustration}
      />
    );
  }

  return <ModelDetail model={model} />;
}
