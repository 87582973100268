import React from 'react';
import classnames from 'classnames/bind';

import { CommonButtonProps } from './Button';
import styles from './Button.module.scss';

const c = classnames.bind(styles);

type ButtonTypeCommonProps = CommonButtonProps & {
  to?: never;
  href?: never;
  download?: never;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonTypeAsChildProps = ButtonTypeCommonProps & {
  asChild: boolean;
};

type ButtonTypeRegularProps = ButtonTypeCommonProps & {
  asChild?: never;
};

export type ButtonTypeButtonProps =
  | ButtonTypeAsChildProps
  | ButtonTypeRegularProps;

function ButtonTypeButton(
  {
    children,
    className: customClassName,
    size,
    variant,
    intent,
    autoFocus,
    loading,
    asChild,
    disabled,
    type = 'button',
    ...props
  }: ButtonTypeButtonProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const themeClassName =
    intent === 'default' && variant === 'primary' ? 'primary' : intent;

  const className = c(
    customClassName,
    variant,
    size,
    `theme ${themeClassName}`,
    {
      'focus-ring': variant !== 'primary',
      'focus-ring-soft': variant === 'primary',
      [`intent-${intent}`]: intent,

      button: variant !== 'none' && variant !== 'link',
      loading,
      disabled,
    }
  );

  if (asChild && React.isValidElement(children)) {
    const cloneProps = {
      ...props,
      className: c(children.props.className, className),
      role: 'button',
      autoFocus,
      disabled,
      ref,
    };

    return React.cloneElement(children, cloneProps);
  }

  return (
    <button
      {...props}
      className={className}
      disabled={disabled}
      type={type}
      ref={ref as React.Ref<HTMLButtonElement>}
    >
      <span className={c('content')}>{children}</span>
      {loading && (
        <span className={c('loader')}>
          <span className="loading-spinner" />
        </span>
      )}
    </button>
  );
}

const ForwardRef = React.forwardRef<HTMLElement, ButtonTypeButtonProps>(
  ButtonTypeButton
);

export { ForwardRef as ButtonTypeButton };
