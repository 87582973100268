import React from 'react';
import classNames from 'classnames/bind';

import { Tooltip, TooltipProps } from 'components/Tooltip/Tooltip';

import styles from './HelpTooltip.module.scss';

const c = classNames.bind(styles);

export type HelpTooltipProps = React.PropsWithChildren<{
  content: React.ReactText | React.ReactElement;
}> &
  Omit<TooltipProps, 'content'>;

export function HelpTooltip({
  children,
  side = 'right',
  ...props
}: HelpTooltipProps) {
  return (
    <>
      {children}

      <Tooltip {...props} side={side}>
        <span className={c('hint-icon')}>?</span>
      </Tooltip>
    </>
  );
}
