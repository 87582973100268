import React from 'react';
import classNames from 'classnames/bind';
import * as RadixPopover from '@radix-ui/react-popover';

import styles from './Popover.module.scss';

const c = classNames.bind(styles);

export type PopoverProps = React.PropsWithChildren<{
  trigger: React.ReactElement;
}> &
  Pick<RadixPopover.PopoverProps, 'open' | 'defaultOpen' | 'onOpenChange'> &
  Pick<RadixPopover.PopoverContentProps, 'sideOffset' | 'side'>;

export function Popover({
  trigger,
  children,
  side,
  sideOffset,
  ...props
}: PopoverProps) {
  return (
    <RadixPopover.Root {...props}>
      <RadixPopover.Anchor>
        <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      </RadixPopover.Anchor>

      <RadixPopover.Portal>
        <RadixPopover.Content
          className={c('card', 'overlay')}
          side={side}
          sideOffset={sideOffset}
          hideWhenDetached
        >
          <div className="card-content">{children}</div>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
