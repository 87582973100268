import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AIModel from 'types/ai_model';

import { isEmptyObject } from 'services/object';

import { Field } from 'components/Field/Field';
import { FloatInput } from 'components/NumberInput/NumberInput';

import ResolutionInput, {
  getWidthAndHeightFromResolutionString,
} from 'components/ResolutionInput/ResolutionInput';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Button } from 'components/Button/Button';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Separator } from 'components/Separator/Separator';

import { useModelFormState } from './context/useModelFormState';
import { useModelFormStep } from './hooks/useModelFormStep';
import { ClusteringConfiguration } from './components/ClusteringConfiguration';
import { AdvancedConfiguration } from './inputs/AdvancedConfiguration';
import { LeavePrompt } from './components/LeavePrompt';

export const FIELD_LABEL_WIDTH = 164;

export type Step3FieldValues = Pick<AIModel, 'parameters'> & {
  inference_config: {
    class_attributes: AIModel['inference_config']['class_attributes'];
  };
};

/**
 * Inference parameters.
 * Only to be displayed when model capability is detection.
 *
 * TODO: This step should support setting all fields per-class.
 * Right now, this will set values for all classes.
 * Note the inference_config.class_attributes.*.name in control names.
 */
export function Step3() {
  const navigate = useNavigate();

  const { model, baseUrl, data, error, isSaving, save, dispatch } =
    useModelFormState();
  const {
    control,
    formState,
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    ...formMethods
  } = useForm<Step3FieldValues>({
    mode: 'onBlur',
    defaultValues: {
      inference_config: {
        class_attributes: {
          '*': model?.inference_config?.class_attributes?.['*'],
        },
      },
      parameters: model?.parameters,
    },
  });

  const watchMinSize = watch(
    'inference_config.class_attributes.*.object_min_size'
  );

  useModelFormStep({ reset, getValues });

  React.useEffect(() => {
    if (formState.isSubmitted || !baseUrl) {
      return;
    }

    if (!data || isEmptyObject(data) || data.capability !== 'detection') {
      navigate('..', { replace: true });
    }
  }, [data, baseUrl, navigate, formState.isSubmitted]);

  const onSubmit = React.useCallback(
    async (formData: Step3FieldValues) => {
      if (!baseUrl) {
        const res = await save(formData);

        dispatch({ type: 'update', data: formData });

        if (res) {
          reset(res);
        }

        return;
      }

      if (!data || isEmptyObject(data)) {
        return;
      }

      const modelData = {
        ...data,
        ...formData,
        inference_config: {
          ...data.inference_config,
          ...formData.inference_config,
        },
      };

      if (!modelData || isEmptyObject(modelData)) {
        return;
      }

      save(modelData);
    },
    [baseUrl, data, save, reset, dispatch]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        id="min_inference_threshold"
        label="Inference threshold"
        info="Minimum confidence score to be applied before clustering."
        labelWidth={FIELD_LABEL_WIDTH}
        labelVerticalAlign="flex-start"
        error={
          formState.errors.inference_config?.class_attributes?.['*']
            ?.min_inference_threshold
        }
        required
      >
        <Controller
          name="inference_config.class_attributes.*.min_inference_threshold"
          control={control}
          defaultValue={0.1}
          render={({ field: { value, onChange } }) => (
            <FloatInput
              onChange={onChange}
              value={value}
              id="min_inference_threshold"
              min={0}
              max={1}
              step={0.01}
            />
          )}
          rules={{
            required: 'Please enter a minimum inference threshold.',
            min: {
              value: 0,
              message: 'Inference threshold must be at least 0.',
            },
            max: {
              value: 1,
              message: 'Inference threshold must be less than or equal to 1.',
            },
          }}
        />
      </Field>

      <Field
        id="post_cluster_threshold"
        label="Post-cluster threshold"
        info="Minimum confidence score to be applied after clustering."
        labelWidth={FIELD_LABEL_WIDTH}
        labelVerticalAlign="flex-start"
        error={
          formState.errors.inference_config?.class_attributes?.['*']
            ?.post_cluster_threshold
        }
      >
        <Controller
          name="inference_config.class_attributes.*.post_cluster_threshold"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FloatInput
              onChange={onChange}
              value={value}
              id="post_cluster_threshold"
              decimalScale={2}
              step={0.01}
            />
          )}
          rules={{
            min: {
              value: 0,
              message: 'Post-cluster threshold must be at least 0.',
            },
            max: {
              value: 1,
              message:
                'Post-cluster threshold must be less than or equal to 1.',
            },
          }}
        />
      </Field>

      <Separator />

      <Field
        id="object_min_size"
        label="Minimum object size"
        labelWidth={FIELD_LABEL_WIDTH}
        labelVerticalAlign="flex-start"
        error={
          formState.errors.inference_config?.class_attributes?.['*']
            ?.object_min_size
        }
      >
        <Controller
          name="inference_config.class_attributes.*.object_min_size"
          control={control}
          defaultValue={null}
          render={({ field: { value, onChange, onBlur } }) => (
            <ResolutionInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              id="object_min_size"
              presets={false}
            />
          )}
        />
      </Field>

      <Field
        id="object_max_size"
        label="Maximum object size"
        labelWidth={FIELD_LABEL_WIDTH}
        labelVerticalAlign="flex-start"
        error={
          formState.errors.inference_config?.class_attributes?.['*']
            ?.object_max_size
        }
      >
        <Controller
          name="inference_config.class_attributes.*.object_max_size"
          control={control}
          defaultValue={null}
          render={({ field: { value, onChange, onBlur } }) => (
            <ResolutionInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              id="object_max_size"
              presets={false}
            />
          )}
          rules={{
            validate(resolution) {
              if (!watchMinSize) {
                return;
              }
              const [watchWidth, watchHeight] =
                getWidthAndHeightFromResolutionString(watchMinSize);
              const [width, height] =
                getWidthAndHeightFromResolutionString(resolution);

              if (watchHeight > height || watchWidth > width) {
                return 'Maximum object size must be larger than minimum object size.';
              }
            },
          }}
        />
      </Field>

      <FormProvider
        {...{
          ...formMethods,
          control,
          formState,
          reset,
          register,
          getValues,
          handleSubmit,
          watch,
        }}
      >
        <ClusteringConfiguration />

        <Separator />
        <AdvancedConfiguration />

        <LeavePrompt />
      </FormProvider>

      <FormErrorMessage error={error} />

      <ButtonGroup>
        <Button type="submit" variant="primary" loading={isSaving}>
          {model ? 'Save changes' : 'Finish & Save model'}
        </Button>

        {model &&
          !isSaving &&
          !formState.isDirty &&
          formState.isSubmitSuccessful && (
            <InlineNotification intent="success" icon="check-circle">
              Saved!
            </InlineNotification>
          )}
      </ButtonGroup>
    </form>
  );
}
