import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Dashboard from 'types/dashboard';

import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';

export type UseDeleteDashboardOptions = UseMutationOptions<
  void,
  APIError,
  Dashboard['id']
>;

export function useDeleteDashboard({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseDeleteDashboardOptions = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete this dashboard?',
    (dashboardID) => {
      if (!dashboardID) {
        throw new Error('No dashboard ID provided.');
      }

      return api.dashboards.delete(dashboardID);
    },
    {
      ...options,
      action: 'delete',
      entity: 'dashboard',
      onMutate(dashboardID) {
        onMutate?.(dashboardID);
        return updateQueriesData({
          queryClient,
          listQueryKey: ['dashboards'],
          singleQueryKey: ['dashboard'],
          ids: [dashboardID],
          updateData: null,
        });
      },
      onError(error, updatedStream, context) {
        onError?.(error, updatedStream, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(...args) {
        onSettled?.(...args);
        queryClient.invalidateQueries(['dashboards']);
      },
    }
  );
}
