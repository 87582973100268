import { ConditionLexer } from './lexer';
import { parse } from 'peberminta';
import {
  AccessorParser,
  ConditionParser,
  ValueParser,
} from './condition_parser';
import { Condition } from './types';

export function parseCondition(input: string): Condition {
  const { tokens } = ConditionLexer(input);
  return parse(ConditionParser, tokens, input);
}

export function parseAccessor(input: string) {
  const { tokens } = ConditionLexer(input);
  return parse(AccessorParser, tokens, input);
}

export function parseValue(input: string) {
  const { tokens } = ConditionLexer(input);
  return parse(ValueParser, tokens, input);
}

export function conditionToString({
  accessor,
  comparator,
  value,
  and,
  or,
}: Condition): string {
  let result = `${accessor.text} ${comparator.text} ${value.text}`;

  if (and?.length) {
    result += ` and ${and.map(conditionToString).join(' and ')}`;
  } else if (or?.length) {
    result += ` or ${or.map(conditionToString).join(' or ')}`;
  }

  return result;
}

export function validateCondition(condition: Condition): string | true {
  try {
    parseAccessor(condition.accessor.text);
    parseValue(condition.value.text);

    if (condition.and) {
      for (const subCondition of condition.and) {
        const result = validateCondition(subCondition);
        if (result !== true) {
          return result;
        }
      }
    }

    if (condition.or) {
      for (const subCondition of condition.or) {
        const result = validateCondition(subCondition);
        if (result !== true) {
          return result;
        }
      }
    }

    return true;
  } catch (error) {
    return (error as Error).message;
  }
}
