import React from 'react';
import c from 'classnames';
import { useFormContext } from 'react-hook-form';

import Deployment, { DeploymentConfiguration } from 'types/deployment';
import Pipeline, { DeployTimeEditableNode } from 'types/pipeline';
import Gateway from 'types/gateway';

import { getScrollParent } from 'services/scroll_parent';
import { sanitizeObject } from 'services/object';
import { useActivePipeline } from 'pipelines/context/active_pipeline';
import { VideoSource } from 'hooks/api/useVideoSources';

import { FormMessage } from 'components/FormMessage/FormMessage';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';

import { DeploymentFormNode } from './Node/Node';
import { DeploymentFormSkeleton } from './Skeleton';
import { DeploymentFormWarnings } from './Warnings';
import { sortByNodeIDAndVideoSourcesFirst } from './sort_pipeline_nodes';
import { usePreloadDeploymentForm } from './usePreloadDeploymentForm';

export type DeploymentFormContentProps = {
  deployment?: Deployment;
  gateway?: Gateway;
  videoSource?: VideoSource;
  onLink?: () => void;
};

const DEFAULT_DEPLOY_TIME_EDITABLE_NODES: DeployTimeEditableNode[] = [];

export type DeploymentFormContentRef = {
  onSubmit: () => Promise<{
    deployment?: Deployment;
    gateway?: Gateway;
    videoSource?: VideoSource;
    pipeline: Pipeline;
    configuration: DeploymentConfiguration;
  }>;
  validate: () => Promise<boolean>;
};

function DeploymentFormContent(
  { deployment, gateway, videoSource, onLink }: DeploymentFormContentProps,
  ref: React.ForwardedRef<DeploymentFormContentRef>
) {
  const pipeline = useActivePipeline();

  const formRef = React.useRef<HTMLFormElement>(null);

  const { isLoading, error } = usePreloadDeploymentForm();

  const methods = useFormContext<DeploymentConfiguration>();

  const { trigger, getValues } = methods;

  React.useImperativeHandle(ref, () => ({
    async onSubmit() {
      const isValid = await trigger();

      if (!isValid) {
        const scrollParent = getScrollParent(formRef.current);
        scrollParent.scrollTo(0, 0);
        throw new Error('Deployment configuration is invalid.');
      }

      return {
        deployment,
        gateway,
        pipeline,
        videoSource,
        // Remove null values, particularly in nested objects
        configuration: sanitizeObject(getValues()),
      };
    },
    async validate() {
      const isValid = await trigger();

      if (!isValid) {
        const scrollParent = getScrollParent(formRef.current);
        scrollParent.scrollTo(0, 0);
      }

      return isValid;
    },
  }));

  if (!pipeline) {
    return null;
  }

  if (error) {
    return (
      <FormMessage intent="danger" icon="warning">
        <strong>{error.message || 'An unknown error occurred.'}</strong>
        <br />
        Please try again later.
      </FormMessage>
    );
  }

  if (isLoading) {
    return (
      <SkeletonList min={2} max={3} component={<DeploymentFormSkeleton />} />
    );
  }

  const hasError = Object.keys(methods.formState.errors).length > 0;

  return (
    <form
      className={c({ readonly: methods.formState.isSubmitting })}
      onKeyDown={(event) => event.stopPropagation()}
      onSubmit={(event) => event.preventDefault()}
      ref={formRef}
    >
      {hasError && (
        <FormMessage intent="danger" icon="warning">
          Please fix the highlighted errors before submitting.
        </FormMessage>
      )}

      <DeploymentFormWarnings pipeline={pipeline} onLinkClick={onLink} />

      {pipeline?.nodes
        .sort(sortByNodeIDAndVideoSourcesFirst)
        .map((node) => (
          <DeploymentFormNode
            node={node}
            deployTimeEditableNodes={
              pipeline.deployTimeEditableNodes ||
              DEFAULT_DEPLOY_TIME_EDITABLE_NODES
            }
            key={node.id}
          />
        ))}
    </form>
  );
}

const forwardRef = React.forwardRef<
  DeploymentFormContentRef,
  DeploymentFormContentProps
>(DeploymentFormContent);

export { forwardRef as DeploymentFormContent };
