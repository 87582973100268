import classnames from 'classnames/bind';
import { useClipboard } from '@mantine/hooks';

import { useSpringState } from 'hooks/useSpringState';

import { IconButton, IconButtonProps } from 'components/IconButton/IconButton';
import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './CopyToClipboardButton.module.scss';

const c = classnames.bind(styles);

export type CopyToClipboardButtonProps = {
  text: string;
  className?: string;
  buttonClassName?: string;
  inline?: boolean;
  /** Tooltip label */
  label?: string;
  /** Tooltip message that shows up after copying */
  message?: string;
} & Pick<IconButtonProps, 'variant'>;

export function CopyToClipboardButton({
  text,
  className,
  buttonClassName,
  inline,
  label = 'Copy to clipboard',
  message = 'Copied to clipboard',
  variant,
  ...iconButtonProps
}: CopyToClipboardButtonProps) {
  const [isCopied, setIsCopied] = useSpringState(false, 2000);
  const { copy } = useClipboard();

  function handleCopyClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setIsCopied(true);
    copy(text);
  }

  return (
    <Tooltip side="right" content={message} open={isCopied}>
      <div className={className}>
        <div className={c('button-wrap', { inline })}>
          <IconButton
            {...iconButtonProps}
            variant={variant || 'ghost'}
            className={c('button', buttonClassName)}
            onClick={handleCopyClick}
            icon="clipboard"
            label={label}
            size="small"
          />
        </div>
      </div>
    </Tooltip>
  );
}
