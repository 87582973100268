type HashParamsResult = {
  [key: string]: string;
};

export function getHashParams(hash: string) {
  return hash
    .substr(1)
    .split('&')
    .reduce<HashParamsResult>((result, item) => {
      const [key, value] = item.split('=');
      result[key] = value;
      return result;
    }, {});
}
