import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import StringResolutionInput, {
  StringResolutionInputProps,
} from 'components/ResolutionInput/ResolutionInput';

export default class ReteNumberInput extends ReteInput<
  ReteInputProps & StringResolutionInputProps
> {
  constructor(
    editor: NodeEditor,
    key: string,
    {
      presets,
      ...params
    }: Omit<ReteInputParams, 'type'> &
      Pick<StringResolutionInputProps, 'presets'>
  ) {
    super(editor, key, { type: 'string', ...params });

    this.props.presets = presets;
  }

  get component() {
    return ResolutionInputComponent;
  }
}

function ResolutionInputComponent(props: StringResolutionInputProps) {
  return <StringResolutionInput {...props} placeholder="auto" />;
}
