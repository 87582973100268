import React from 'react';
import { MqttClient } from 'mqtt';

export const MqttClientContext = React.createContext<MqttClient | undefined>(
  undefined
);

export function useMqttClient() {
  return React.useContext(MqttClientContext);
}
