import { Node as ReteNode } from 'rete';
import { OutputsData, NodeData as ReteNodeData } from 'rete/types/core/data';
import { captureMessage as sentryMessage } from '@sentry/react';

import { PipelineDefinitionNode } from 'types/pipeline';

import { flattenObject, isObject } from 'services/object';
import { ComponentType } from 'pipelines/services/rete/nodes/component';
import { getComponentByName } from 'pipelines/services/rete/nodes';
import { NodePropertyValueType } from 'pipelines/services/rete/nodes/types';
import ReteInput from 'pipelines/services/rete/controls/input';

export type NodeData = Record<string, unknown> & {
  /**
   * Dynamic nodes data
   */
  props?: Record<string, unknown>;
};

export default class Node {
  id?: string;
  name: string;
  /**
   * NOTE: Dynamic nodes store their data within `props` of this object.
   */
  data: NodeData;
  position: [number, number];
  outputs: OutputsData;

  // These properties allow traversing a pipeline as a graph of nodes directly
  // They are only set after connectNodeReferences was called.
  inputReferences?: InputReferences;
  outputReferences?: OutputReferences;

  constructor(
    id: string | undefined,
    name: string,
    data: NodeData,
    position: [number, number],
    outputs: OutputsData
  ) {
    this.id = id;
    this.name = name;
    this.data = data;
    this.position = position;
    this.outputs = outputs;
  }

  static fromReteNode(node: ReteNode): Node {
    let id = node.id as any;
    if (typeof id === 'number') {
      // Node was created in rete, so it has a numeric id instead of a string.
      // This means we haven't defined a proper id yet and it should be undefined.
      id = undefined;
    }

    const outputs: OutputsData = {};
    for (const [key, output] of node.outputs.entries()) {
      outputs[key] = output.toJSON();
    }

    return new Node(id, node.name, node.data, node.position, outputs);
  }

  static fromReteDefinition(node: ReteNodeData): Node {
    let id = node.id as any;
    if (typeof id === 'number') {
      // Node was created in rete, so it has a numeric id instead of a string.
      // This means we haven't defined a proper id yet and it should be undefined.
      id = undefined;
    }

    return new Node(id, node.name, node.data, node.position, node.outputs);
  }

  get component(): ComponentType | undefined {
    return getComponentByName(this.name);
  }

  get unfilledProperties(): string[] {
    // Flatten nested data structures into `parent.child` property names
    const flatData = flattenObject(this.data);

    return Object.keys(flatData).filter((property) => {
      const value = flatData[property];

      if (Boolean(value) && typeof value === 'object') {
        return JSON.stringify(value) === JSON.stringify({});
      }

      return value === null || value === undefined || value === '';
    });
  }

  get hasExportID() {
    return typeof this.id === 'string';
  }

  async getReteInstance(): Promise<ReteNode | undefined> {
    const { component } = this;

    if (!component) {
      console.error(
        `\`Component\` could not be found for node \`${this.name}\`.`
      );
      return undefined;
    }

    return new component().createNode({});
  }

  async toLumeoDefinition(
    deployTimeEditableControls?: Pick<ReteInput, 'key' | 'deploymentParam'>[],
    nodeDescriptions: Record<string, string> = {}
  ): Promise<PipelineDefinitionNode> {
    const reteNode = await this.getReteInstance();
    if (reteNode === undefined) {
      // FIXME: In the original JS implementation this was a TypeError but in any case the exception isn't handled
      throw new Error(
        "Can't create lumeo definition for node without component."
      );
    }

    const controls = reteNode.controls as Map<string, ReteInput>;
    const component = this.component!; // if this.getReteInstance works then there is also a component.

    const properties = Array.from(controls.values()).reduce(
      (properties, { key, type }) => {
        const value = getPropertyValue(this.data, key);
        return setPropertyValue(properties, key, typedProperty(value, type));
      },
      { type: component.exportType } as any
    );

    // Convert outputs to the correct format
    const wires: Record<string, string[]> = {};
    for (const [outputWire, { connections }] of Object.entries(this.outputs)) {
      wires[outputWire] = connections.map(
        ({ node: targetID, input }) => `${targetID}.${input}`
      );
    }

    if (!this.id) {
      // FIXME: This isn't valid, but the original JS implementation didn't care. At least we log it to sentry if it happens.
      sentryMessage(
        'Converting Node without id to PipelineDefinitionNode.',
        'error'
      );
    }

    return {
      id: this.id as any,
      properties,
      wires,
      extension: {
        console: {
          position: {
            x: this.position[0],
            y: this.position[1],
          },
          description: this.id ? nodeDescriptions[this.id] : undefined,
          deployTimeEditableControls: deployTimeEditableControls
            ? deployTimeEditableControls
            : undefined,
        },
      },
    };
  }

  // If this node has outputs, creates references to the output nodes and backreferences to the current node in them
  connectNodeReferences(nodesById: Map<string, Node>) {
    if (this.outputs === undefined) {
      return;
    }

    this.outputReferences = {};
    // This makes sure that every node that was connected has both its input and output references set to a value
    // This is relied upon to check if connection process has run on a given node.
    this.inputReferences = this.inputReferences ?? {};

    Object.entries(this.outputs).forEach(([outputName, outputData]) => {
      const outputReferences = outputData.connections.map((connectionData) => {
        // FIXME: Although the rete types expect the node id to be a number, we always write a string in there.
        const id = connectionData.node as any as string;

        const node = nodesById.get(id);
        if (node === undefined) {
          throw new Error(
            `Already connected: Tried to connect with node with an unknown id: ${id}`
          );
        }

        node.connectOutputOfNodeToInput(connectionData.input, outputName, this);

        return {
          node,
          input: connectionData.input,
        };
      });

      this.outputReferences![outputName] = outputReferences;
    });
  }

  protected connectOutputOfNodeToInput(
    inputName: string,
    outputName: string,
    node: Node
  ) {
    this.inputReferences = this.inputReferences ?? {};

    const existingInput = this.inputReferences[inputName];
    if (existingInput !== undefined) {
      throw new Error(
        `Tried to connect output '${outputName}' of node '${node.id}' to input '${inputName}' of node '${this.id}' which was already connected to output '${existingInput.output}' of node '${existingInput.node.id}'`
      );
    }

    this.inputReferences[inputName] = {
      node,
      output: outputName,
    };
  }

  allUpstreamNodes(): Set<Node> {
    if (this.inputReferences === undefined) {
      throw new Error(
        "Input references aren't set. Did you forget to call Node.connectNodeReferences before calling Node.allUpstreamNodes?"
      );
    }

    return Object.values(this.inputReferences)
      .flatMap((inputs) => inputs)
      .reduce((upstreamNodes, { node }) => {
        node.allUpstreamNodes().forEach((node) => upstreamNodes.add(node));
        return upstreamNodes.add(node);
      }, new Set<Node>());
  }
}

export interface OutputReferences {
  [output: string]: {
    node: Node;
    input: string;
  }[];
}

export interface InputReferences {
  [input: string]: {
    node: Node;
    output: string;
  };
}

// Convert a value into its equivalent in a different type. Valid types: 'number', 'string', 'boolean'
function typedProperty(value: unknown, type?: NodePropertyValueType): any {
  const isValueEmpty =
    (!value && value !== 0) || value === 'null' || String(value).trim() === '';

  /**
   * FIXME / DELETE
   * Some interim changes for ch2876 broke the video source display.
   * If the video source has been set or updated using those changes, the
   * pipeline can no longer be deployed nor its deployments updated.
   * This fixes the modal, but removes the previously selected source from the deployment.
   */
  if (value === '[object Object]') {
    return null;
  }

  switch (type) {
    case 'json': {
      if (isValueEmpty || JSON.stringify(value) === '{}') return null;
      if (typeof value === 'string') return JSON.parse(value);
      return value;
    }
    case 'list':
    case 'multiline': {
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return null;
        }
        return value;
      }

      if (typeof value === 'string') {
        return value.split(';');
      }

      return null;
    }
    case 'string':
    case 'enum':
      return !isValueEmpty ? String(value) : null;
    // FIXME: line and polygon should also be lists
    case 'enum-multi': {
      if (Array.isArray(value) && value.length > 0) {
        return value;
      }
      return null;
    }
    case 'polygon':
    case 'line': {
      if (typeof value === 'string' && value.trim()) {
        return value;
      }

      if (Array.isArray(value)) {
        return value.join(';');
      }

      return null;
    }
    case 'number':
    case 'float':
      return !isValueEmpty ? Number(value) || 0 : null;
    case 'boolean': {
      if (value === 'true') return true;
      if (value === 'false') return false;
      return value;
    }
    default:
      throw new Error(
        `Tried to cast value (${value}) to unknown type (${type})`
      );
  }
}

export function hasProperty(
  data: Record<string, unknown> | undefined | null,
  property: string
): boolean {
  if (!data || !isObject(data)) {
    return false;
  }

  return _hasProperty(data, property.split('.'));
}

function _hasProperty(
  data: Record<string, unknown>,
  [key, ...keys]: string[]
): boolean {
  if (!isObject(data)) {
    return true;
  }

  if (!(key in data)) {
    return false;
  }

  if (keys.length) {
    return _hasProperty(data[key] as Record<string, unknown>, keys);
  }

  return true;
}

export function getPropertyValue(
  data: Record<string, unknown> | undefined | null,
  property: string
): unknown {
  return property.split('.').reduce((acc, prop) => {
    if (!isObject(acc)) {
      return null;
    }
    return (acc as Record<string, unknown>)[prop];
  }, data as unknown);
}

export function setPropertyValue(
  data: Record<string, unknown>,
  property: string,
  value: unknown
): Record<string, unknown> {
  const keys = property.split('.');
  const nested = keys.reduceRight(
    (object, key) => ({ [key]: object }),
    value
  ) as Record<string, unknown>;
  return mergeProperties(data, nested);
}

function mergeProperties(
  target: Record<string, unknown>,
  source: Record<string, unknown>
): Record<string, unknown> {
  Object.entries(source).forEach(([key, sourceValue]) => {
    const targetValue = target[key];

    if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeProperties(
        Object.assign({}, targetValue) as Record<string, unknown>,
        sourceValue as Record<string, unknown>
      );
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}
