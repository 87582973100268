import axios, { AxiosProgressEvent } from 'axios';
import { USER_AGENT } from './constants';

export type FileUploadOptions = {
  onUploadProgress?: (event: AxiosProgressEvent) => void;
};

export async function handleFileUpload(
  url?: string,
  file?: Blob | File | null,
  options?: FileUploadOptions
): Promise<Response | void> {
  if (!url || !file || typeof file.text !== 'function') {
    return Promise.reject(
      new Error('No file or URL supplied for file upload.')
    );
  }

  try {
    const buffer = await new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);

      reader.addEventListener('load', (event) => {
        if (!event.target) {
          reject();
          return;
        }

        resolve(event.target.result);
      });

      reader.addEventListener('error', () => reject());
    });

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return await axios.request({
      url,
      method: 'put',
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
        'User-Agent': USER_AGENT,
      },
      data: buffer as BodyInit,
      ...options,
    });
  } catch (error) {
    return Promise.reject(error);
  } finally {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  }
}

function beforeUnloadHandler(event: BeforeUnloadEvent) {
  event.preventDefault();
  return '';
}
