import React from 'react';
import { CellProps } from 'react-table';

import Camera from 'types/camera';

import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';

export function CamerasTableStatusCell({ row }: CellProps<Camera>) {
  return <StatusIndicator status={row.original.status} />;
}
