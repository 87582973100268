import React from 'react';
import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import NumberInput, {
  NumberInputProps,
} from 'components/NumberInput/NumberInput';

export type ReteNumberInputOptions = Omit<ReteInputParams, 'inputType'> &
  Pick<NumberInputProps, 'min' | 'max' | 'step'>;

export default class ReteNumberInput extends ReteInput<
  ReteInputProps & NumberInputProps
> {
  constructor(
    editor: NodeEditor,
    key: string,
    { min, max, step, ...params }: Omit<ReteNumberInputOptions, 'type'>
  ) {
    super(editor, key, { ...params, type: 'number' });

    this.props.min = min;
    this.props.max = max;
    this.props.step = step;
  }

  get component() {
    return ReteNumberInputComponent;
  }
}

function ReteNumberInputComponent({ onChange, ...props }: NumberInputProps) {
  const handleChange = React.useCallback(
    (value: number | string) => {
      if (typeof value === 'string') {
        onChange?.('');
        return;
      }

      if (Number.isNaN(Number(value))) {
        return;
      }

      if (
        props.min !== undefined &&
        typeof props.min !== 'number' &&
        value < props.min
      ) {
        return;
      }
      if (
        props.max !== undefined &&
        typeof props.max !== 'number' &&
        value > props.max
      ) {
        return;
      }

      onChange?.(Number(value));
    },
    // eslint-disable-next-line
    [props.min, props.max]
  );

  return <NumberInput {...props} onChange={handleChange} />;
}
