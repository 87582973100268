import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Gateway from 'types/gateway';
import { GatewaySpecification } from 'types/gateway_specification';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function useGatewaySpecification(
  id?: Gateway['id'],
  {
    enabled,
    ...options
  }: UseQueryOptions<GatewaySpecification | null, APIError> = {}
) {
  const api = useAPI();

  return useAuthenticatedQuery(
    ['gateway-specification', id, api.applicationID],
    () => {
      if (!id) {
        return Promise.reject(new Error('No gateway ID provided.'));
      }

      return api.gateways.getSpecification(id);
    },
    {
      ...options,
      enabled: enabled !== undefined ? enabled && Boolean(id) : Boolean(id),
    }
  );
}
