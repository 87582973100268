import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';

import { CursorPaginated, OffsetPaginated } from 'types/pagination';
import { isNotNullOrUndefined } from 'services/nullable';

export type UpdateQueriesDataParams<TData extends object> = {
  queryClient: QueryClient;
  listQueryKey?: QueryKey;
  singleQueryKey?: QueryKey;
  ids: string[];
  updateData: ((updatedEntity: TData) => TData) | TData | null;
};

export type UpdateQueriesDataContext<TData> = {
  previousLists?: (readonly [
    QueryKey,
    (
      | OffsetPaginated<TData>
      | CursorPaginated<TData>
      | InfiniteData<OffsetPaginated<TData> | CursorPaginated<TData>>
      | undefined
    ),
  ])[];
  previousSingles?: (readonly [QueryKey, TData | undefined])[];
};

export function updateQueriesData<TData extends object>({
  queryClient,
  listQueryKey,
  singleQueryKey,
  ids,
  updateData,
}: UpdateQueriesDataParams<TData>) {
  const context: UpdateQueriesDataContext<TData> = {};

  const updateEntity = (entity: TData) => {
    if (
      'id' in entity &&
      typeof entity.id === 'string' &&
      ids.includes(entity.id)
    ) {
      return typeof updateData === 'function' ? updateData(entity) : updateData;
    }

    return entity;
  };

  if (listQueryKey) {
    const listQueries = queryClient.getQueriesData<
      | OffsetPaginated<TData>
      | CursorPaginated<TData>
      | InfiniteData<OffsetPaginated<TData> | CursorPaginated<TData>>
    >(listQueryKey);

    context.previousLists = listQueries;

    for (const [key, listQuery] of listQueries) {
      if (!listQuery) {
        continue;
      }

      // OffsetPaginated<TData> | CursorPaginated<TData>
      if ('data' in listQuery && listQuery.data) {
        // Query needs to be a new reference
        const updatedListQuery = structuredClone(listQuery);

        updatedListQuery.data = listQuery.data
          .map(updateEntity)
          .filter(isNotNullOrUndefined);

        queryClient.setQueryData(key, updatedListQuery);
      }

      // InfiniteData<OffsetPaginated<TData> | CursorPaginated<TData>>
      if ('pages' in listQuery && listQuery.pages && listQuery.pages.length) {
        // Query needs to be a new reference
        const updatedListQuery = structuredClone(listQuery);

        updatedListQuery.pages = listQuery.pages.map(({ data, ...rest }) => ({
          data: data.map(updateEntity).filter(isNotNullOrUndefined),
          ...rest,
        }));

        queryClient.setQueryData(key, updatedListQuery);
      }
    }
  }

  if (singleQueryKey) {
    const singleQueries = ids.flatMap((id) =>
      queryClient.getQueriesData<TData>([...singleQueryKey, id])
    );

    context.previousSingles = singleQueries;

    for (const [key, entity] of singleQueries) {
      if (!entity) {
        continue;
      }

      const updatedEl =
        typeof updateData === 'function' ? updateData(entity) : updateData;
      queryClient.setQueryData(key, updatedEl);
    }
  }

  return context;
}
