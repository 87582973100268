import classnames from 'classnames/bind';
import { Slot } from '@radix-ui/react-slot';

import styles from './Code.module.scss';

const c = classnames.bind(styles);

export type CodeProps = React.PropsWithChildren<{
  className?: string;
  size?: 'small' | string;
  asChild?: boolean;
}>;

export function Code({ className, size, asChild, children }: CodeProps) {
  const Cmp = asChild ? Slot : 'code';
  return <Cmp className={c(className, size, 'code')}>{children}</Cmp>;
}
