import React from 'react';
import classNames from 'classnames/bind';

import { StepProps } from './Step';
import styles from './Stepper.module.scss';

const c = classNames.bind(styles);

type Children = React.ReactElement<StepProps> | false | null | undefined;

export type RootProps = {
  className?: string;
  children: Children[];
};

export function Root({ className, children }: RootProps) {
  return (
    <ol className={c('stepper', className)}>
      {React.Children.toArray(children).map(
        (child, index) =>
          React.isValidElement(child) &&
          React.cloneElement(child, { ...child.props, index: index + 1 })
      )}
    </ol>
  );
}
