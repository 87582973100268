import Row, { emptyRow } from './JsonObjectInputRow';

export function serializeJsonObjectValues(values: Row[]) {
  // reduceRight to keep the first instance of a duplicate key
  return values.reduceRight<Record<string, string>>((acc, row: Row) => {
    if (!row.key.trim()) {
      return acc;
    }

    return { ...acc, ...row.getEntry() };
  }, {});
}

export function deserializeJsonObjectValues(
  initialValues: object | null = emptyRow.getEntry()
) {
  if (initialValues) {
    return Object.entries(initialValues).map(
      ([key, value]) => new Row(key, value)
    );
  }

  return [emptyRow];
}
