import classnames from 'classnames/bind';

import { useAccessTokens } from 'hooks/api/useAccessTokens';

import * as PageLayout from 'components/PageLayout';

import { TokenForm } from '../../components/TokenForm/TokenForm';
import { TokenTable } from '../../components/TokenTable/TokenTable';
import { UniversalBridgeInfo } from '../../components/UniversalBridgeInfo/UniversalBridgeInfo';
import styles from '../Settings.module.scss';

const c = classnames.bind(styles);

export function UniversalBridge() {
  const { data: accessTokens, isLoading } = useAccessTokens();

  const universalBridgeTokens = accessTokens?.filter(
    ({ type }) => type === 'mintable'
  );

  return (
    <PageLayout.Root size="small">
      <PageLayout.Container sidebar={<UniversalBridgeInfo />}>
        <div className={c('wrap')}>
          <section className={c('section')}>
            <TokenForm
              title="Generate new Universal bridge token"
              defaultExpiration="No expiration"
              types={['mintable']}
            />
          </section>

          <TokenTable tokens={universalBridgeTokens} isLoading={isLoading} />
        </div>
      </PageLayout.Container>
    </PageLayout.Root>
  );
}
