import React from 'react';

export type BulkAction =
  | { type: 'clear' }
  | { type: 'add'; ids: string[] }
  | { type: 'remove'; id: string };

export type FilesSearchContextProps = {
  selectedFileIds: string[];
  dispatch: React.Dispatch<BulkAction>;

  isDeleting: boolean;
  deleteSelectedFiles: () => void;
};

export const FilesSearchContext = React.createContext<
  FilesSearchContextProps | undefined
>(undefined);

export function useFilesSearchState() {
  const context = React.useContext(FilesSearchContext);

  if (context === undefined) {
    throw new Error(
      'Hook `useFilesSearchState` may only be used within <FilesSearch />.'
    );
  }

  return context;
}
