import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { createModelSchema, primitive } from 'serializr';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';

import { useAPI } from 'hooks/api/useAPI';
import { objectArrayOrNull } from 'services/serializr';

import { useAppQueryClient } from 'hooks/useAppQueryClient';

import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { useGateways } from './useGateways';

export class DiscoveryRequestResponse {
  application_id?: string;
  gateway_id?: string;
  expires_at?: string;
  id?: string;
  status?: 'pending' | 'success';
  result?: any | null;
}

createModelSchema(DiscoveryRequestResponse, {
  application_id: primitive(),
  gateway_id: primitive(),
  expires_at: primitive(),
  id: primitive(),
  status: primitive(),
  result: objectArrayOrNull(Camera),
});

export function useGatewayCameras(
  gatewayID: string,
  refetchInterval: boolean = false
) {
  const linkedCamerasResult = useLinkedCameras(gatewayID);
  const discoveredCamerasResult = useDiscoverCameras(
    gatewayID,
    refetchInterval
  );

  const [cameras, setCameras] = React.useState<Camera[]>([]);

  const linkCameraQuery = useLinkCamera(gatewayID);

  useDeepCompareEffect(() => {
    let cameras =
      linkedCamerasResult?.data?.reduce<Camera[]>((list, linkedCamera) => {
        if (linkedCamera) {
          list.push(linkedCamera);
        }
        return list;
      }, []) ?? [];

    if (discoveredCamerasResult.data?.length > 0) {
      cameras = cameras.concat(...discoveredCamerasResult.data);
    }

    setCameras(cameras);
  }, [linkedCamerasResult.data, discoveredCamerasResult.data]);

  const isCameraLinked = React.useCallback(
    (camera: Camera) => {
      return (
        Boolean(linkedCamerasResult.data) &&
        linkedCamerasResult.data!.some((linkedCamera) =>
          linkedCamera?.equals(camera)
        )
      );
    },
    [linkedCamerasResult.data]
  );

  return {
    cameras,
    linkCamera: linkCameraQuery.mutateAsync,
    isLinkingCamera: linkCameraQuery.isLoading,
    linkError: linkCameraQuery.error,
    isCameraLinked,
    isLoading: linkedCamerasResult.isLoading,
    isDiscovering: discoveredCamerasResult.isLoading,
    discover: discoveredCamerasResult.refetch,
  };
}

// TODO: Migrate to paginated API
function useDiscoverCameras(gatewayID: string, refetchInterval: boolean) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  const { data: gateways } = useGateways();
  const gateway = gateways?.data.find((gateway) => gateway.id === gatewayID);
  const isEnabled = gateway?.status === 'online';

  const cachedRequestID = queryClient.getQueryData<DiscoveryRequestResponse>([
    'requestID',
    gatewayID,
  ])?.id;
  const [requestID, setRequestID] = React.useState(cachedRequestID);

  const discoveredCameras = queryClient.getQueryData<Camera[]>([
    'discovered-cameras',
    gatewayID,
  ]);

  const [status, setStatus] = React.useState<'loading' | 'success' | 'error'>(
    !isEnabled || (cachedRequestID && discoveredCameras) ? 'success' : 'loading'
  );

  const { refetch: getNewRequestID } = useAuthenticatedQuery(
    ['requestID', gatewayID, api.applicationID],
    () => api.gateways.cameras.getDiscoveryID(gatewayID),
    {
      onSuccess: (data) => setRequestID(data.id),
      staleTime: Infinity,
      enabled: isEnabled,
    }
  );

  const queryResult = useAuthenticatedQuery(
    ['camera-discovery', gatewayID, api.applicationID],
    () => api.gateways.cameras.discover(gatewayID, requestID!),
    {
      onSuccess: (res) => {
        if (!res || res.status === 'pending') {
          return;
        }

        setStatus('success');
        queryClient.setQueryData<Camera[]>(
          ['discovered-cameras', gatewayID],
          res.result
        );
      },
      enabled: isEnabled && Boolean(requestID) && status === 'loading',
      refetchInterval: refetchInterval ? 1000 : false,
      staleTime: Infinity,
    }
  );

  async function refetch() {
    setStatus('loading');
    setRequestID(undefined);
    getNewRequestID();
  }

  return {
    ...queryResult,
    refetch,
    data: queryResult.data?.result as Camera[],
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    isError: status === 'error',
    status,
  };
}

// TODO: Migrate to paginated API
function useLinkedCameras(gatewayID: string) {
  const api = useAPI();

  return useAuthenticatedQuery<Camera[]>(
    ['linked-cameras', gatewayID, api.applicationID],
    () => api.gateways.listLinkedCameras(gatewayID),
    {
      enabled: Boolean(gatewayID),
    }
  );
}

export function useLinkCamera(
  gatewayID: string,
  {
    onSuccess,
    onSettled,
    ...options
  }: UseMutationOptions<Camera, APIError, Camera> = {}
) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  async function updateCameras(camera: Camera) {
    await queryClient.cancelQueries(['cameras']);

    const previousCameras =
      queryClient.getQueryData<Camera[]>(['cameras']) || [];

    if (previousCameras) {
      queryClient.setQueryData<Camera[]>(
        ['cameras'],
        [...previousCameras, camera]
      );
    }

    queryClient.invalidateQueries(['cameras']);
  }

  async function updateDiscoveredCameras(camera: Camera) {
    await queryClient.cancelQueries(['camera-discovery']);
    const discoveredCameras = queryClient.getQueryData<Camera[]>([
      'discovered-cameras',
      gatewayID,
    ]);
    const res = queryClient.getQueryData<DiscoveryRequestResponse>([
      'camera-discovery',
      gatewayID,
    ]);

    if (discoveredCameras && res) {
      res.result = res.result.filter(
        (discoveredCamera: Camera) =>
          discoveredCamera.name !== camera.mac_address &&
          discoveredCamera.mac_address !== camera.mac_address
      );
      queryClient.setQueryData<DiscoveryRequestResponse>(
        ['camera-discovery', gatewayID],
        res
      );
    }
  }

  async function updateLinkedCameras(camera: Camera) {
    let updatedLinkedCameras: Camera[] = [];

    const previousLinkedCameras = queryClient.getQueryData<Camera[]>([
      'linked-cameras',
      gatewayID,
    ]);

    if (previousLinkedCameras) {
      updatedLinkedCameras = [...previousLinkedCameras, camera];
      queryClient.setQueryData<Camera[]>(
        ['linked-cameras', gatewayID],
        updatedLinkedCameras
      );
    }

    queryClient.invalidateQueries(['linked-cameras', gatewayID]);
  }

  return useMutation<Camera, APIError, Camera>(
    (camera: Camera) => api.gateways.cameras.link(gatewayID, camera),
    {
      ...options,
      onSuccess: (camera, ...params) => {
        updateCameras(camera);
        updateDiscoveredCameras(camera);
        updateLinkedCameras(camera);
        onSuccess?.(camera, ...params);
      },
      onSettled: (...params) => {
        queryClient.invalidateQueries(['linked-cameras', gatewayID]);
        queryClient.invalidateQueries(['streams']);
        onSettled?.(...params);
      },
    }
  );
}
