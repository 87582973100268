import Rete, { Node } from 'rete';

import VideoSourceSelector from 'pipelines/services/rete/controls/video_source_selector';
import SingleLineInput from 'pipelines/services/rete/controls/text_input';
import Component from 'pipelines/services/rete/nodes/component';
import { RawVideo } from 'pipelines/services/rete/sockets';

import {
  FlipVideoInput,
  FrameRateInput,
  ResolutionInput,
} from '../controls/configured_inputs';
import ReteCropInput from 'pipelines/services/rete/controls/crop';
import ReteJsonInput from 'pipelines/services/rete/controls/json_input';
import ReteNumberInput from 'pipelines/services/rete/controls/number_input';
import ReteRadioSelect from 'pipelines/services/rete/controls/radio_select';
import {
  FixedRotationDisplayer,
  VideoSourceIDDisplayer,
} from 'pipelines/services/rete/displayers/video_source';

import { RESOLUTION_PRESETS } from 'components/ResolutionInput/ResolutionInput';

export default class VideoSource extends Component {
  static key = 'Video Source';
  static icon = 'video' as const;
  static exportType = 'video';
  static category = 'input' as const;
  static description = 'Use cameras or RTSP streams as video input';

  static editorDisplayers = {
    source_id: VideoSourceIDDisplayer,
    source_type: null,
    rotate_fixed_angle: FixedRotationDisplayer,
    rtsp: null,
  };
  static output = {
    type: 'raw',
  };

  static outputMetadata = ['Frame timestamps'];

  constructor() {
    super(VideoSource.key);
  }

  async builder(node: Node) {
    node
      .addControl(
        new SingleLineInput(this.editor!, 'source_type', {
          initial: null,
          type: 'string',
          inputType: 'hidden',
          deploymentParam: 'always',
        })
      )
      .addControl(
        // this will also set key source_type
        new VideoSourceSelector(this.editor!, 'source_id', {
          label: 'Video source',
          initial: '',
          deploymentParam: 'always',
          keyref: 'source_type',
          required: true,
        })
      )
      .addControl(
        FrameRateInput(this.editor!, {
          info: "Frame rate into which the source's frame rate is converted for this pipeline.",
        })
      )
      .addControl(
        ResolutionInput(this.editor!, {
          info: "Resolution into which the source's resolution is converted for this pipeline.",
          initial: '1280x720',
          presets: RESOLUTION_PRESETS,
          group: 'transformations',
        })
      )
      .addControl(
        new ReteRadioSelect(this.editor!, 'rotate_fixed_angle', {
          label: 'Fixed rotation angle',
          options: [
            { label: '0°', value: null },
            { label: '90°', value: 'clockwise90' },
            { label: '180°', value: 'clockwise180' },
            { label: '270°', value: 'counter_clockwise90' },
          ],
          info: 'Rotate video output without cropping pixels. May change resolution.',
          group: 'transformations',
        })
      )
      .addControl(
        new ReteNumberInput(this.editor!, 'rotate', {
          label: 'Free rotation angle',
          info: 'Rotate video output by arbitrary angle without changing resolution. Pixels may be cropped.',
          initial: '',
          placeholder: '0',
          unit: 'deg',
          min: 0,
          max: 360,
        })
      )
      .addControl(
        FlipVideoInput(this.editor!, { key: 'flip', group: 'transformations' })
      )
      .addControl(
        new ReteCropInput(this.editor!, 'crop', {
          label: 'Crop',
          info: 'Specify the amount of pixels to crop from each side.',
          group: 'transformations',
        })
      )
      .addControl(
        new ReteJsonInput(this.editor!, 'extra_metadata', {
          label: 'Metadata',
          info: 'Specify a constant metadata dictionary to be inserted with every frame originating from this source.',
        })
      )
      .addOutput(new Rete.Output('output', 'Output', RawVideo));
  }
}
