import Any from './any';
import RawVideo from './raw_video';
import EncodedVideo from './encoded_video';
import MultiplexedVideo from './multiplexed_video';
import UnencodedVideo from './unencoded_video';

UnencodedVideo.combineWith(Any);
UnencodedVideo.combineWith(RawVideo);
UnencodedVideo.combineWith(MultiplexedVideo);
RawVideo.combineWith(Any);
RawVideo.combineWith(UnencodedVideo);
EncodedVideo.combineWith(Any);
MultiplexedVideo.combineWith(UnencodedVideo);

export { Any, RawVideo, EncodedVideo, MultiplexedVideo, UnencodedVideo };

export const RETE_SOCKETS = {
  Any,
  'Raw video': RawVideo,
  'Encoded video': EncodedVideo,
  'Multiplexed video': MultiplexedVideo,
  'Raw or multiplexed video': UnencodedVideo,
};
