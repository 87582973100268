import React from 'react';

import { Metric } from 'graphql/types/metric';

import { usePropelMetrics } from 'dashboards/hooks/usePropelMetrics';

import { Select, SelectProps } from 'components/Select/Select';

export type MetricOption = {
  value: Metric['id'];
  label: Metric['uniqueName'];
};

type MetricSelectProps = SelectProps<MetricOption>;

export function MetricSelect(props: MetricSelectProps) {
  const { data, isLoading } = usePropelMetrics();

  const options = React.useMemo<MetricOption[]>(
    () =>
      data
        ? data.metrics.edges
            .map(
              ({ node }) =>
                ({ value: node.id, label: node.uniqueName } as MetricOption)
            )
            .sort((a, b) => (a.label < b.label ? -1 : 1))
        : [],
    [data]
  );

  return (
    <Select
      {...props}
      id="propel-metrics"
      options={options}
      isLoading={isLoading}
    />
  );
}
