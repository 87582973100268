import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { Select } from 'components/Select/Select';

import { MODEL_CLUSTERING_ALGORITHM_OPTIONS } from 'services/model_options';

import { FIELD_LABEL_WIDTH, Step2FieldValues } from '../Step2';

export function ClusteringAlgorithmInput() {
  const {
    control,
    formState: { errors },
  } = useFormContext<Step2FieldValues>();

  return (
    <Field
      id="cluster_mode"
      label="Clustering algorithm"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      error={errors.inference_config?.cluster_mode}
      required
    >
      <Controller
        name="inference_config.cluster_mode"
        control={control}
        render={({ field: { value, onChange } }) => {
          const selectedValue = MODEL_CLUSTERING_ALGORITHM_OPTIONS.find(
            (option) => option.value === value
          );

          return (
            <Select
              id="cluster_mode"
              options={MODEL_CLUSTERING_ALGORITHM_OPTIONS}
              onChange={(option) => onChange(option?.value)}
              value={selectedValue}
            />
          );
        }}
        rules={{ required: 'Please enter a value.' }}
      />
    </Field>
  );
}
