export type LumeoEvent = {
  id: string;
  event_ts: string;
  application_id: string;
  category: string;
  event_type: string;
  severity: LumeoEventSeverity;
  payload?: string;
  context: object;
  created_at: string;
  related_entities: RelatedEntities;
};

export const LUMEO_EVENT_SEVERITIES = [
  'error',
  'warning',
  'info',
  'debug',
] as const;

export type LumeoEventSeverity = (typeof LUMEO_EVENT_SEVERITIES)[number];

export const RELATED_ENTITIES_KEYS = [
  'camera_id',
  'deployment_id',
  'file_id',
  'gateway_id',
  'node_id',
  'stream_id',
] as const;

export type RelatedEntityKey = (typeof RELATED_ENTITIES_KEYS)[number];
export type RelatedEntities = Partial<Record<RelatedEntityKey, string>>;
