import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Deployment from 'types/deployment';

import { addErrorNotification } from 'services/notification';
import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useBillingMutation } from 'hooks/api/useBillingMutation';
import { useMutationWithError } from 'hooks/api/useMutationWithError';

export function useDeployment(
  deploymentId?: string,
  { enabled, ...options }: UseQueryOptions<Deployment, APIError> = {}
) {
  const api = useAPI();

  return useAuthenticatedQuery(
    ['deployment', deploymentId],
    () => {
      if (!deploymentId) {
        return Promise.reject('No deployment id provided.');
      }

      return api.deployments.read(deploymentId);
    },
    {
      ...options,
      enabled:
        enabled !== undefined
          ? Boolean(deploymentId) && enabled
          : Boolean(deploymentId),
    }
  );
}

type ToggleState = 'stopping' | 'deploying';
type ToggleOptions = UseMutationOptions<Deployment, APIError, Deployment, any>;

export function useToggleDeploymentState(options: ToggleOptions = {}) {
  const api = useAPI();

  const { mutate: start, isLoading: isStarting } = useBillingMutation(
    (deployment: Deployment) => api.deployments.start(deployment.id),
    {
      ...options,
      entity: 'deployment',
      action: 'start',
      onError(error) {
        addErrorNotification({ title: 'Failed to start deployment.', error });
      },
    }
  );
  const { mutate: stop, isLoading: isStopping } = useMutationWithError(
    (deployment: Deployment) => api.deployments.stop(deployment.id),
    {
      ...options,
      entity: 'deployment',
      action: 'stop',
      onError(error) {
        addErrorNotification({ title: 'Failed to stop deployment.', error });
      },
    }
  );

  function toggleState(deployment: Deployment): ToggleState {
    if (deployment.isActive) {
      stop(deployment);
      return 'stopping';
    }

    start(deployment);
    return 'deploying';
  }

  return {
    toggleState,
    isLoading: isStarting || isStopping,
  };
}
