import React from 'react';
import { useForm } from 'react-hook-form';

import Camera from 'types/camera';
import Gateway from 'types/gateway';

import { useLinkCamera } from 'hooks/api/useGatewayCameras';
import { useSpringState } from 'hooks/useSpringState';

import * as Dialog from 'components/Dialog';
import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import { Text } from 'components/Text/Text';

export type BulkLinkFieldValues = {
  password: string;
  username: string;
};

export type BulkLinkGatewayCamerasModalProps = Pick<
  Dialog.RootProps,
  'open' | 'onOpenChange'
> & {
  gatewayID: Gateway['id'];
  cameras?: Camera[];
  onSuccess: () => void;
};

export function BulkLinkGatewayCamerasDialog({
  gatewayID,
  cameras,
  open,
  onOpenChange,
  onSuccess,
}: BulkLinkGatewayCamerasModalProps) {
  const { register, reset, handleSubmit } = useForm<BulkLinkFieldValues>({
    mode: 'onBlur',
  });

  const [didLink, setDidLink] = useSpringState(false, 2000);

  const {
    mutateAsync: linkCamera,
    isLoading,
    error,
  } = useLinkCamera(gatewayID);

  React.useEffect(() => {
    if (!didLink || !onOpenChange) {
      return;
    }

    return () => {
      onOpenChange(false);
    };
  }, [didLink, onOpenChange]);

  if (error) {
    error.message ??= 'Unable to access the camera.';
  }

  async function handleBulkLinkCameras({
    password,
    username,
  }: BulkLinkFieldValues) {
    if (!cameras) {
      return;
    }

    try {
      await Promise.all(
        cameras.map((camera) => {
          if (username) {
            camera.username = username;
          }

          if (password) {
            camera.password = password;
          }

          return linkCamera(camera);
        })
      );

      setDidLink(true);
      reset();
      onSuccess();
    } catch (error: unknown) {
      // Errors handled via useMutation
    }
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Title asChild>
        <Heading level="2" asChild>
          <span>Finish setup for {cameras?.length ?? 0} cameras</span>
        </Heading>
      </Dialog.Title>

      {didLink && (
        <Dialog.Message intent="success">Cameras linked!</Dialog.Message>
      )}

      <form onSubmit={handleSubmit(handleBulkLinkCameras)}>
        <FormErrorMessage error={error} />

        <Text type="paragraph">
          Please provide camera credentials if required.
        </Text>

        <Field label="Username">
          <Input {...register('username')} disabled={didLink} />
        </Field>

        <Field label="Password">
          <Input type="password" {...register('password')} disabled={didLink} />
        </Field>

        <Button
          variant="primary"
          type="submit"
          loading={isLoading}
          disabled={didLink}
        >
          Connect cameras
        </Button>
      </form>
    </Dialog.Root>
  );
}
