import {
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useQueries,
  useQuery,
  QueriesOptions,
} from '@tanstack/react-query';

import APIError from 'types/api_error';

import { sanitizeQueryKey } from 'services/query_key';

import { useAPI } from './useAPI';
import { useAuthentication } from './useAuthentication';

/**
 * Extension of react-query `useQuery` that only fires the
 * (guarded) request if a user is authenticated.
 */
export function useAuthenticatedQuery<T, S = T>(
  key: QueryKey,
  queryFn: QueryFunction<T>,
  { retry, enabled, ...options }: UseQueryOptions<T, APIError, S> = {}
) {
  const { authToken } = useAuthentication();
  const { client } = useAPI();

  const hasAuthHeader =
    Boolean(authToken) && Boolean(client.defaults.headers.common.Authorization);
  const isEnabled =
    enabled !== undefined ? hasAuthHeader && enabled : hasAuthHeader;

  return useQuery<T, APIError, S>(sanitizeQueryKey(key), queryFn, {
    ...options,
    enabled: isEnabled,
    retry(failureCount, error) {
      if (
        error.status === 400 ||
        error.status === 401 ||
        error.status === 403 ||
        error.status === 404
      ) {
        return false;
      }

      if (typeof retry === 'number') {
        return failureCount <= Number(retry);
      }

      if (typeof retry === 'function') {
        return retry(failureCount, error);
      }

      return retry ?? failureCount <= 3;
    },
  });
}

export function useAuthenticatedInfiniteQuery<T>(
  key: QueryKey,
  queryFn: QueryFunction<T>,
  { enabled, ...options }: UseInfiniteQueryOptions<T, APIError> = {}
) {
  const { authToken } = useAuthentication();
  const { client } = useAPI();

  const hasAuthHeader =
    Boolean(authToken) && Boolean(client.defaults.headers.common.Authorization);
  const isEnabled =
    enabled !== undefined ? hasAuthHeader && enabled : hasAuthHeader;

  return useInfiniteQuery<T, APIError>(sanitizeQueryKey(key), queryFn, {
    ...options,
    enabled: isEnabled,
  });
}

/**
 * Extension of react-query `useQueries` that only fires the
 * (guarded) requests if a user is authenticated.
 */
export function useAuthenticatedQueries<T extends any[]>(
  queries: readonly [...QueriesOptions<T>]
) {
  const { authToken } = useAuthentication();
  const { client } = useAPI();

  const hasAuthHeader =
    Boolean(authToken) && Boolean(client.defaults.headers.common.Authorization);

  return useQueries({
    queries: queries.map(({ enabled, ...config }) => ({
      ...config,
      enabled: enabled !== undefined ? enabled && hasAuthHeader : Boolean(hasAuthHeader),
    })),
  });
}
