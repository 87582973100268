import React from 'react';
import { Input } from 'rete';
import { Socket } from 'rete-react-render-plugin';
import classnames from 'classnames/bind';

import { Tooltip } from 'components/Tooltip/Tooltip';

import { ReactComponent as TriangleIcon } from 'icons/input-triangle.svg';

import styles from './EditorNode.module.scss';

const c = classnames.bind(styles);

export type EditorNodeInputsProps = {
  inputs: Input[];
  bindControl: any;
  bindSocket: any;
};

export function EditorNodeInputs({ inputs, ...rest }: EditorNodeInputsProps) {
  return (
    <div className={c('inputs', { multiInput: inputs.length > 1 })}>
      {inputs.map((input) => (
        <EditorNodeInput {...rest} input={input} key={input.key} />
      ))}
    </div>
  );
}

type EditorNodeInputProps = {
  input: Input;
  bindSocket: any;
};

function EditorNodeInput({ input, bindSocket }: EditorNodeInputProps) {
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Tooltip content={input.socket.name} side="left" sideOffset={4}>
      <div
        className={c('input', { active: input.connections.length > 0 })}
        ref={ref}
      >
        <Socket
          type="input"
          socket={input.socket}
          io={input}
          innerRef={bindSocket}
        />
        <div className={c('input-icon-wrap')}>
          <TriangleIcon className={c('input-icon')} />
        </div>
      </div>
    </Tooltip>
  );
}
