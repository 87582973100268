import React from 'react';
import classnames from 'classnames/bind';
import { useUncontrolled } from '@mantine/hooks';

import {
  isOrganizationRole,
  ORGANIZATION_ROLES,
  OrganizationRole,
} from 'types/account_role';

import { formatPretty } from 'services/string';

import { Checkbox } from 'components/Checkbox/Checkbox';

import styles from './OrganizationRolesInput.module.scss';

const c = classnames.bind(styles);

type OrganizationRolesInputProps = {
  value?: OrganizationRole[];
  defaultValue?: OrganizationRole[];
  onChange?: (value: OrganizationRole[]) => void;
};

export function OrganizationRolesInput({
  value,
  defaultValue,
  onChange,
}: OrganizationRolesInputProps) {
  const [roles, setRoles] = useUncontrolled({
    value: value ? [...new Set(value)] : value,
    defaultValue: defaultValue ? [...new Set(defaultValue)] : defaultValue,
    onChange,
  });

  function handleToggleRole({
    target: { value, checked },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (!isOrganizationRole(value)) {
      return;
    }

    const updatedRoles = new Set(roles);

    if (checked) {
      updatedRoles.add(value);
    } else {
      updatedRoles.delete(value);
    }

    setRoles([...updatedRoles]);
  }

  return (
    <fieldset className={c('organization-roles')}>
      {ORGANIZATION_ROLES.map((role) => (
        <Checkbox
          key={role}
          id={`organization-roles-input-${role}`}
          value={role}
          label={formatPretty(role)}
          checked={Array.isArray(value) && value.includes(role)}
          onChange={handleToggleRole}
        />
      ))}
    </fieldset>
  );
}
