import React from 'react';
import classNames from 'classnames/bind';
import { MantineColor, Text } from '@mantine/core';

import { Icon, IconType } from 'components/Icon/Icon';

import styles from './InlineNotification.module.scss';

const c = classNames.bind(styles);

type InlineNotificationIntent = 'success' | 'danger' | 'info';

export type InlineNotificationProps = React.PropsWithChildren<{
  intent?: InlineNotificationIntent;
  icon?: IconType;
}>;

const ICON_MAP: Record<InlineNotificationIntent, IconType> = {
  success: 'check-circle',
  danger: 'error',
  info: 'info',
};

const COLOR_MAP: Record<InlineNotificationIntent, MantineColor> = {
  success: 'green',
  danger: 'red',
  info: 'blue',
};

export function InlineNotification({
  intent,
  icon,
  children,
}: InlineNotificationProps) {
  if (!icon && intent) {
    icon = ICON_MAP[intent];
  }

  const color = intent ? COLOR_MAP[intent] : undefined;

  return (
    <Text className={c('wrap', icon)} c={color} fw={500}>
      {icon && <Icon name={icon} className={c('icon')} unstyled />}
      <span>{children}</span>
    </Text>
  );
}
