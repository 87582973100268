export default class Row {
  key: string;
  value: string;

  constructor(key = '', value = '') {
    this.key = key;
    this.value = value;
  }

  getEntry() {
    return { [this.key]: this.value };
  }
}

export const emptyRow = new Row();
