import React from 'react';
import classnames from 'classnames/bind';

import { useAppRedirect } from 'hooks/useAppRedirect';
import { useCreatePipeline } from 'pipelines/hooks/useCreatePipeline';

import { Drawer } from 'components/Drawer/Drawer';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { PipelineTemplateGrid } from 'analytics-library/components/PipelineTemplateGrid/PipelineTemplateGrid';

import styles from './CreateNewPipeline.module.scss';

const c = classnames.bind(styles);

export type CreateNewPipelineProps = {
  open: boolean;
  onClose: () => void;
};

export function CreateNewPipeline({ open, onClose }: CreateNewPipelineProps) {
  const redirect = useAppRedirect();

  const { mutate: createPipeline, isLoading: isCreating } = useCreatePipeline({
    onSuccess(pipeline) {
      redirect(`/design/pipelines/${pipeline.id}`);
      onClose();
    },
  });

  function createBlankPipeline() {
    createPipeline({ definition: [] });
  }

  return (
    <Drawer
      title="Build pipeline from scratch"
      classNames={{
        content: c('templates-modal', 'userpilot-create-pipeline-modal'),
      }}
      containerClassName={c('templates-modal-container')}
      open={open}
      onClose={onClose}
    >
      <div className={c('wrap')}>
        <section>
          <div
            className={c('blank-item', 'item', {
              loading: isCreating,
            })}
          >
            <Icon name="pipeline" size="small" />
            <Heading level="4" className={c('blank-item-button')} asChild>
              <button onClick={createBlankPipeline}>Create new pipeline</button>
            </Heading>
          </div>
        </section>

        <section>
          <Heading level="3" className={c('templates-modal-heading')}>
            Or choose a template
          </Heading>

          <PipelineTemplateGrid
            id="create-new-pipeline-templates"
            onCreatePipeline={onClose}
            filters={{ limit: 20 }}
          />
        </section>
      </div>
    </Drawer>
  );
}
