import AIModel from 'types/ai_model';

import type {
  Step1FieldValues,
  Step2FieldValues,
  Step3FieldValues,
} from 'analytics-library/components/MultiStepForm';

export type GetModelFromValuesData = Step1FieldValues &
  Partial<Step2FieldValues & Step3FieldValues>;

export function getModelFromValues({
  weights_file,
  metadata_file,
  labels_file,

  // required values
  name,
  format,
  architecture,
  capability,

  ...values
}: GetModelFromValuesData) {
  if (!name || !format || !architecture || !capability) {
    const missingValues = [
      !name && 'name',
      !format && 'format',
      !architecture && 'architecture',
      !capability && 'capability',
    ].filter(Boolean);
    throw new Error(`Incomplete model data. Missing ${missingValues}`);
  }

  const model = AIModel.from({
    ...values,
    name,
    format,
    architecture,
    capability,
  });

  if (weights_file) {
    model.weights_file_name = weights_file.name;
  }

  if (metadata_file) {
    model.metadata_file_name = metadata_file.name;
  }

  if (labels_file) {
    model.labels_file_name = labels_file.name;
  }

  return model;
}
