import React from 'react';
import classnames from 'classnames/bind';
import {
  CellProps,
  Column,
  useFlexLayout,
  usePagination,
  useTable,
} from 'react-table';
import { Pagination } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Deployment from 'types/deployment';
import { PaginatedAPIResponse } from 'types/api';

import { useDeploymentsGateways } from 'deployments/hooks/useDeploymentsGateways';
import { useDeploymentsPipelines } from 'deployments/hooks/useDeploymentsPipelines';

import { DeploymentListItemCompact } from 'deployments/components/List/ItemCompact/ItemCompact';

import styles from './UniversalBridgeDeployments.module.scss';

const c = classnames.bind(styles);

export type DeploymentListProps = {
  queryResult: UseQueryResult<PaginatedAPIResponse<Deployment>, APIError>;
  pageSize: number;
  page: number | undefined;
  onPageChange: (page: number) => void;
};

export function ReferenceDeploymentsList({
  queryResult: { data: deployments },
  pageSize,
  page: currentPage = 1,
  onPageChange: onPageChange,
}: DeploymentListProps) {
  const data = React.useMemo(() => deployments?.data ?? [], [deployments]);

  const { data: gateways } = useDeploymentsGateways(data);
  const { data: pipelines } = useDeploymentsPipelines(data);

  const columns = React.useMemo<Column<Deployment>[]>(
    () => [
      {
        Header: 'Deployment',
        Cell({ row }: CellProps<Deployment>) {
          const gateway = gateways?.data.find(
            ({ id }) => id === row.original.gateway_id
          );
          const pipeline = pipelines?.data.find(
            ({ id }) => id === row.original.pipeline_id
          );

          return (
            <DeploymentListItemCompact
              deployment={row.original}
              gateway={gateway}
              pipeline={pipeline}
              isReferenceDeployment
            />
          );
        },
      },
    ],
    [gateways?.data, pipelines?.data]
  );

  const { getTableProps, getTableBodyProps, prepareRow, page, gotoPage } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: deployments ? deployments.current_page - 1 : 0,
          pageSize,
        },
        manualPagination: true,
        pageCount: deployments?.total_pages,
      },
      usePagination,
      useFlexLayout
    );

  if (!deployments) {
    return null;
  }

  return (
    <div className={c('wrap')}>
      <div {...getTableProps()}>
        <ul className={c('list')} {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <li {...rowProps} className={c('row')} key={key}>
                {row.cells.map((cell, index) => (
                  <React.Fragment key={index}>
                    {cell.render('Cell')}
                  </React.Fragment>
                ))}
              </li>
            );
          })}
        </ul>
      </div>

      {deployments.total_pages > 1 && (
        <Pagination
          value={currentPage}
          total={deployments.total_pages}
          onChange={(page) => {
            onPageChange(page);
            gotoPage(page);
          }}
        />
      )}
    </div>
  );
}
