import React from 'react';
import { DimensionInput } from '@propeldata/ui-kit';
import { MultiValue, OnChangeValue, SingleValue } from 'react-select';

import { usePropelMetric } from 'dashboards/hooks/usePropelMetric';

import { isMultiOption, Select, SelectProps } from 'components/Select/Select';

const EMPTY: DimensionOption[] = [];

export type DimensionOption = {
  value: DimensionInput['columnName'];
  label: DimensionInput['columnName'];
};

type DimensionSelectProps<MultiSelect extends boolean = false> = Omit<
  SelectProps<DimensionOption, MultiSelect>,
  'value' | 'onChange' | 'defaultValue'
> & {
  metric: { name: string };
  value: DimensionInput[] | null;
  onChange: (value: DimensionInput[] | null) => void;
};

export function DimensionSelect<MultiSelect extends boolean = false>({
  metric: { name },
  value: input,
  onChange,
  isMulti,
  ...props
}: DimensionSelectProps<MultiSelect>) {
  const { data, isLoading } = usePropelMetric({ uniqueName: name });

  const value = input
    ? input.map(({ columnName: value }) => ({ value, label: value }))
    : input;

  const options = data
    ? data.metricByName.dimensions.map(({ columnName }) => ({
        value: columnName,
        label: columnName,
      }))
    : EMPTY;

  function handleMultiSelect(option: readonly DimensionOption[]) {
    onChange?.(option.map(({ value: columnName }) => ({ columnName })));
  }

  function handleSingleSelect(option: DimensionOption) {
    onChange?.([{ columnName: option.value }]);
  }

  function handleOnChange(option: OnChangeValue<DimensionOption, MultiSelect>) {
    if (!option) {
      // Allow resetting selection
      onChange?.(option);
      return;
    }

    if (isMulti && isMultiOption<DimensionOption>(option)) {
      handleMultiSelect(option);
    } else if (isDimensionOption(option)) {
      handleSingleSelect(option);
    }
  }

  return (
    <Select<DimensionOption, MultiSelect>
      {...props}
      value={value}
      onChange={handleOnChange}
      options={options}
      isLoading={isLoading}
      isMulti={isMulti}
    />
  );
}

function isDimensionOption(
  option: MultiValue<DimensionOption> | SingleValue<DimensionOption>
): option is DimensionOption {
  return (
    option !== null &&
    typeof option === 'object' &&
    !Array.isArray(option) &&
    'value' in option &&
    option.value !== undefined
  );
}
