import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { Tag } from 'types/tag';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function useTag(
  id?: Tag['id'] | null,
  { enabled, ...options }: UseQueryOptions<Tag, APIError> = {}
) {
  const { applicationID, client } = useAPI();

  return useAuthenticatedQuery(
    ['tag', id],
    async () => {
      if (!id) {
        return Promise.reject('No tag id provided.');
      }

      const { data } = await client.get(`/apps/${applicationID}/tags/${id}`);

      return data;
    },
    {
      ...options,
      enabled: enabled !== undefined ? enabled && Boolean(id) : Boolean(id),
    }
  );
}
