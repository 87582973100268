import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { Userpilot } from 'userpilot';

import APIError from 'types/api_error';
import Pipeline from 'types/pipeline';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';

import { getOverloadedQueryParams } from 'services/get_overloaded_query_params';
import { useAPI } from 'hooks/api/useAPI';
import { useUser } from 'hooks/useAuth';

import { useAuthenticatedQuery } from '../../hooks/api/useAuthenticatedQuery';

export type UsePipelinesFilterParams = OffsetPaginationParams & {
  contains_name?: string;
  pipeline_ids?: Pipeline['id'][];
  pipeline_names?: Pipeline['name'][];
};

export type UsePipelinesOptions = UseQueryOptions<
  PaginatedAPIResponse<Pipeline>,
  APIError
>;

export function usePipelines(
  filters?: UsePipelinesFilterParams,
  options?: UsePipelinesOptions
): UseQueryResult<PaginatedAPIResponse<Pipeline>, APIError>;

export function usePipelines(
  queryKey?: QueryKey,
  filters?: UsePipelinesFilterParams,
  options?: UsePipelinesOptions
): UseQueryResult<PaginatedAPIResponse<Pipeline>, APIError>;

export function usePipelines(
  queryKeyOrFilters?: QueryKey | UsePipelinesFilterParams,
  filtersOrOptions?: UsePipelinesFilterParams | UsePipelinesOptions,
  queryOptions?: UsePipelinesOptions
) {
  const api = useAPI();
  const user = useUser();

  const {
    queryKey = ['pipelines'],
    filters: { pagination = 'offset', page = 1, limit = 50, ...filters } = {},
    options,
  } = getOverloadedQueryParams(
    queryKeyOrFilters,
    filtersOrOptions,
    queryOptions
  );

  const params = {
    ...filters,
    limit,
    page,
    pagination,
  };

  return useAuthenticatedQuery(
    [...queryKey, api.applicationID, params],
    () => api.pipelines.list(params),
    {
      ...options,
      onSuccess(data) {
        if (!user?.id) {
          return;
        }

        options?.onSuccess?.(data);

        const hasNonEmptyPipeline = data.data.some(
          (pipeline) => pipeline.definition.length > 0
        );

        Userpilot.identify(user.id, {
          has_nonempty_pipeline: hasNonEmptyPipeline,
          application_id: api.applicationID,
        });
      },
      // FIXME: Manually creating pipelines from JSON via API
      // saves definition as JSON string
      select(data) {
        const updatedPipelines = data.data.map(initializeAPIPipeline);

        const updatedData = {
          ...data,
          data: updatedPipelines,
        };

        if (options?.select) {
          return options.select(updatedData);
        }

        return updatedData;
      },
    }
  );
}

export function initializeAPIPipeline(pipeline: Pipeline) {
  if (typeof pipeline.definition === 'string') {
    try {
      (pipeline as any).definition = JSON.parse(pipeline.definition);
    } catch (error) {
      (pipeline as any).definition = [];
    }
  }

  pipeline.updateDeployTimeEditableNodesFromDefinition();
  pipeline.updateNodeDescriptionsFromDefinition();

  return pipeline;
}
