import Rete, { Node } from 'rete';

import ReteTextInput from 'pipelines/services/rete/controls/text_input';
import { RawVideo } from 'pipelines/services/rete/sockets';
import Component from 'pipelines/services/rete/nodes/component';

export default class AddMetadata extends Component {
  static key = 'Add Metadata';
  static icon = 'meta' as const;
  static exportType = 'metadata_add';
  static category = 'integrations' as const;
  static description = 'Inject a file’s contents as metadata to each frame';

  static input = {
    type: 'raw',
  };
  static output = {
    type: 'raw',
  };

  static outputMetadata = ['Metadata from URI'];

  constructor() {
    super(AddMetadata.key);
  }

  async builder(node: Node) {
    node
      .addControl(
        new ReteTextInput(this.editor!, 'source_uri', {
          label: 'Source URI',
          initial: null,
          placeholder: 'tcp:///',
        })
      )
      .addInput(new Rete.Input('input', 'Input', RawVideo, true))
      .addOutput(new Rete.Output('output', 'Output', RawVideo));
  }
}
