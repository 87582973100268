import { NodeEditor } from 'rete';

import ModelDropdown from 'pipelines/components/ModelDropdown/ModelDropdown';
import ReteInput, {
  ReteInputParams,
} from 'pipelines/services/rete/controls/input';

export default class ReteModelInput extends ReteInput {
  constructor(
    editor: NodeEditor,
    key: string,
    params: Omit<ReteInputParams, 'type'>
  ) {
    super(editor, key, { ...params, type: 'string', inputType: 'select' });
  }

  get component() {
    return ModelDropdown;
  }
}
