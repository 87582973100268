import React from 'react';
import classnames from 'classnames/bind';

import { Button } from 'components/Button/Button';
import { IconButton } from 'components/IconButton/IconButton';
import { Icon, IconType } from 'components/Icon/Icon';

import styles from './Notification.module.scss';

const c = classnames.bind(styles);

export type NotificationVariant = 'danger' | 'info' | 'warning' | 'success';

export type NotificationProps = {
  title: string;
  message?: string;
  onClose?: () => void;
  icon?: IconType;
  timed?: boolean | number;
  variant?: NotificationVariant;
  action?: React.ReactElement;
  onUndo?: () => void;
};

export function Notification({
  icon,
  title,
  message,
  variant,
  onClose,
  onUndo,
  action,
  timed = true,
}: NotificationProps) {
  const timeout = React.useRef<number>();
  const progressRef = React.useRef<HTMLDivElement>(null);

  const [isExpiring, setIsExpiring] = React.useState(Boolean(timed));

  let expiry: number = 12000;

  if (timed && typeof timed === 'number') {
    expiry = timed;
  }

  if (variant === 'danger' && !icon) {
    icon = 'error';
  }

  function pauseTimer() {
    if (!timed) {
      return;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    setIsExpiring(false);
  }

  function resumeTimer() {
    if (!timed || !onClose) {
      return;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = window.setTimeout(onClose, expiry);
    setIsExpiring(true);
  }

  React.useEffect(() => {
    if (timed && onClose) {
      timeout.current = window.setTimeout(onClose, expiry);
    }
  }, [timed, onClose, expiry]);

  return (
    <div
      className={c('notification')}
      role="alert"
      onMouseOver={pauseTimer}
      onMouseOut={resumeTimer}
    >
      {icon && (
        <div className={c('icon-wrap', `theme ${variant}`)}>
          <Icon className={c('icon')} name={icon} />
        </div>
      )}

      <h2 className={c('title')}>{title}</h2>
      {message && <div className={c('content')}>{message}</div>}

      <div className={c('actions')}>
        {onUndo && (
          <Button variant="secondary" size="small" onClick={onUndo}>
            Undo
          </Button>
        )}

        {action}
      </div>

      <IconButton
        className={c('close')}
        icon="cancel"
        label="Close notification"
        onClick={onClose}
        variant="ghost"
        size="small"
      />

      <div
        className={c('progress', 'theme', variant || 'primary', {
          start: isExpiring,
        })}
        ref={progressRef}
        style={{ animationDuration: `${expiry}ms` }}
      />
    </div>
  );
}
