import React from 'react';
import classNames from 'classnames/bind';

import { ContainerSize, Root } from 'components/PageLayout';

import { DetailViewHeader } from './DetailViewHeader/DetailViewHeader';
import { DetailViewStatus } from './DetailViewStatus/DetailViewStatus';
import styles from './DetailView.module.scss';

const c = classNames.bind(styles);

export type DetailViewProps = {
  className?: string;
  size?: ContainerSize;
  children?: React.ReactNode | React.ReactNode[];
};

function DetailView(
  { className, children, size = 'small' }: DetailViewProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <Root className={c(className, 'detail')} size={size} ref={ref}>
      {children}
    </Root>
  );
}

const forwardedRef = React.forwardRef(DetailView);

export { forwardedRef as DetailView };

export { DetailViewHeader, DetailViewStatus };
