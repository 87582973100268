import React from 'react';
import classNames from 'classnames/bind';
import { Navigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useToggle } from '@mantine/hooks';

import { StreamType } from 'types/stream';

import { UseVideoSourcesFilterParams } from 'hooks/api/useVideoSources';

import { AddCameraManuallyForm } from 'cameras/components/AddCameraManuallyForm/AddCameraManuallyForm';
import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { CameraTypeInfo } from 'cameras/components/CameraTypeInfo/CameraTypeInfo';
import { CreateInputStreamsDialog } from 'streams/components/CreateInputStreamsDialog';
import { DiscoverCameras } from 'cameras/components/DiscoverCameras/DiscoverCameras';
import { Drawer } from 'components/Drawer/Drawer';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Icon } from 'components/Icon/Icon';
import {
  ToggleSelect,
  ToggleSelectOption,
} from 'components/ToggleSelect/ToggleSelect';

import { RapidDeployLayout } from './Layout';
import { RapidDeployRoute } from '../routes';
import { RapidDeployVideoSourcesList } from './VideoSourcesList';
import { useRapidDeployState } from '../hooks/useRapidDeployState';
import styles from '../RapidDeploy.module.scss';

const c = classNames.bind(styles);

type FilterToggleSelectOption = ToggleSelectOption<
  UseVideoSourcesFilterParams,
  UseVideoSourcesFilterParams['fetch_type']
>;

const SOURCE_FILTER_OPTIONS: FilterToggleSelectOption[] = [
  {
    label: 'Streams',
    value: 'streams',
    icon: 'stream',
    data: {
      stream_types: [StreamType.RTSP, StreamType.WEBRTC],
      stream_sources: ['camera_stream', 'uri_stream'],
    },
  },
  {
    label: 'Cameras',
    value: 'cameras',
    icon: 'camera',
    data: {
      conn_types: ['local'],
    },
  },
];

export function RapidDeployVideoSources() {
  const queryClient = useQueryClient();

  const [isDiscoveringCameras, toggleIsDiscoveringCameras] = useToggle();
  const [isAddingCameraManually, toggleIsAddingCameraManually] = useToggle();
  const [isAddingStream, toggleIsAddingStream] = useToggle();

  const [selectedFilters, setSelectedFilters] = React.useState<
    ToggleSelectOption<
      UseVideoSourcesFilterParams,
      UseVideoSourcesFilterParams['fetch_type']
    >[]
  >(SOURCE_FILTER_OPTIONS);

  const { videoSources, gateway } = useRapidDeployState();

  const filters: UseVideoSourcesFilterParams = React.useMemo(
    () =>
      selectedFilters.reduce((acc, { data }) => ({ ...acc, ...data }), {
        fetch_type:
          selectedFilters.length > 1
            ? 'all'
            : selectedFilters.map(({ value }) => value).at(0),
        // Parent components would redirect if gateway is unset
        gateway_ids: gateway ? [gateway.id] : [],
      }),
    [gateway, selectedFilters]
  );

  if (!gateway) {
    return <Navigate to={`../${RapidDeployRoute.GATEWAY}`} replace />;
  }

  function handleDiscoveryClose() {
    toggleIsDiscoveringCameras(false);
    queryClient.invalidateQueries(['video-sources']);
  }

  return (
    <RapidDeployLayout
      backTo={RapidDeployRoute.GATEWAY}
      onContinue={`../${RapidDeployRoute.DEPLOY}`}
      canContinue={videoSources.length > 0}
    >
      <div className={c('view-switch-controls')}>
        <ToggleSelect
          id="rd-source-type-filter"
          options={SOURCE_FILTER_OPTIONS}
          value={selectedFilters}
          onChange={setSelectedFilters}
        />

        <ButtonGroup>
          <Button
            variant="secondary"
            size="small"
            onClick={() => toggleIsDiscoveringCameras()}
          >
            <Icon name="camera" size="small" />
            <span>Discover and link cameras</span>
          </Button>
          <Button
            variant="secondary"
            size="small"
            onClick={() => toggleIsAddingCameraManually()}
          >
            <Icon name="camera" size="small" />
            <span>Manually add camera</span>
          </Button>
          <Button
            variant="secondary"
            size="small"
            onClick={() => toggleIsAddingStream()}
          >
            <Icon name="stream" size="small" />
            <span>Add input stream</span>
          </Button>
        </ButtonGroup>
      </div>

      <RapidDeployVideoSourcesList filters={filters} />

      <Drawer open={isDiscoveringCameras} onClose={handleDiscoveryClose}>
        <div className={c('drawer-header')}>
          <ExternalLink href="https://docs.lumeo.com/docs/camera-setup-guide">
            Camera setup guide
          </ExternalLink>
        </div>
        <DiscoverCameras gateway={gateway} />
      </Drawer>

      <Drawer
        open={isAddingCameraManually}
        onClose={toggleIsAddingCameraManually}
      >
        <div className={c('drawer-header')}>
          <ExternalLink href="https://docs.lumeo.com/docs/camera-setup-guide">
            Camera setup guide
          </ExternalLink>
        </div>
        <CameraTypeInfo />
        <br />

        <AddCameraManuallyForm
          gateway={gateway}
          onSuccess={toggleIsAddingCameraManually}
        />
      </Drawer>

      <CreateInputStreamsDialog
        gateway={gateway}
        open={isAddingStream}
        onClose={toggleIsAddingStream}
      />
    </RapidDeployLayout>
  );
}
