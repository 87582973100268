import pLimit from 'p-limit';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Deployment from 'types/deployment';

import { updateQueriesData } from 'services/update_queries_data';
import { useUpdateDeployment } from 'hooks/api/useDeployment';

const limit = pLimit(50);

export function useBatchUpdateDeployments({
  onMutate,
  onSuccess,
  onSettled,
  ...options
}: UseMutationOptions<Deployment[], APIError, Deployment[]> = {}) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateDeployment();

  return useMutation(
    async (deployments: Deployment[]) => {
      let error: APIError | undefined = undefined;

      const results = await Promise.all(
        deployments.map((deployment) =>
          limit(() =>
            mutateAsync(deployment).catch((resError) => {
              // Set first error
              if (!error) {
                error = resError;
              }
            })
          )
        )
      );

      if (error) {
        throw error;
      }

      return results.filter((value) => value !== void 0);
    },
    {
      ...options,

      async onMutate(deployments, ...args) {
        await queryClient.cancelQueries(['deployments']);
        onMutate?.(deployments, ...args);

        updateQueriesData<Deployment>({
          queryClient,
          listQueryKey: ['deployments'],
          singleQueryKey: ['deployment'],
          ids: deployments.map(({ id }) => id),
          updateData(deployment) {
            return (
              deployments.find(({ id }) => id === deployment.id) ?? deployment
            );
          },
        });
      },
      async onSuccess(deployment, variables, context) {
        await queryClient.cancelQueries(['deployments']);
        onSuccess?.(deployment, variables, context);
      },
      onSettled(data, error, variables, context) {
        onSettled?.(data, error, variables, context);
        queryClient.invalidateQueries(['deployments']);
        queryClient.invalidateQueries(['deployment']);
      },
    }
  );
}
