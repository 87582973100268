import classnames from 'classnames/bind';

import styles from './EntityList.module.scss';

const c = classnames.bind(styles);

export function EntityListLoadingItem() {
  return (
    <div className={c('item', 'skeleton')} aria-label="Loading...">
      <div className={c('item-icon')} />
      <div className={c('item-label', 'skeleton-text')} />
      <div className={c('item-value', 'skeleton-text')} />
    </div>
  );
}
