import { QueryKey, UseMutationOptions } from '@tanstack/react-query';

import { useAPI } from 'hooks/api/useAPI';

import Stream from 'types/stream';
import APIError from 'types/api_error';

import { useAppQueryClient } from 'hooks/useAppQueryClient';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';
import {
  updateQueriesData,
  UpdateQueriesDataContext,
} from 'services/update_queries_data';

export function useDeleteStreams(
  queryKey: QueryKey,
  {
    onMutate,
    onError,
    onSettled,
    ...options
  }: UseMutationOptions<
    void[],
    APIError,
    Stream['id'][],
    UpdateQueriesDataContext<Stream> | void
  > = {}
) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete these streams?',
    (streamIDs) => {
      if (!streamIDs) {
        return Promise.resolve([]);
      }

      return Promise.all(streamIDs.map(api.streams.delete));
    },
    {
      ...options,
      async onMutate(streamIDs) {
        await queryClient.cancelQueries(queryKey);
        onMutate?.(streamIDs);

        return updateQueriesData<Stream>({
          queryClient,
          listQueryKey: queryKey,
          singleQueryKey: ['stream'],
          ids: streamIDs,
          updateData: null,
        });
      },
      onError(err, deletedStream, context) {
        onError?.(err, deletedStream, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      async onSettled(...args) {
        onSettled?.(...args);

        queryClient.invalidateQueries(queryKey);
      },
    }
  );
}
