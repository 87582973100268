import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useAccount } from 'hooks/api/useAccount';

export function useHasValidPaymentMethods(
  options?: UseQueryOptions<boolean, APIError>
) {
  const api = useAPI();
  const { data: organization } = useCurrentOrganization();
  const { data: user } = useAccount();

  return useAuthenticatedQuery(
    ['has_payment_methods', 'org', organization?.id],
    () => {
      if (!organization) {
        return Promise.reject(new Error('No organization.'));
      }

      if (!user?.isBilling(organization.id)) {
        return Promise.reject(new Error('Not authorized.'));
      }

      return api.organizations.billing.hasValidPaymentMethods(organization.id);
    },
    options
  );
}
