import React from 'react';

export type IntercomContextProps = {
  unreadCount: number;
};

export const IntercomContext = React.createContext<
  IntercomContextProps | undefined
>(undefined);

export function useIntercomState() {
  const context = React.useContext(IntercomContext);

  if (context === undefined) {
    throw new Error(
      '`useIntercomState` can only be used within an IntercomProvider.'
    );
  }

  return context;
}

export { IntercomProvider } from './Provider';
