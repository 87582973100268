import { gql } from 'graphql-request';
import {
  DimensionInput,
  FilterInput,
  Propeller,
  Sort,
  TimeRangeInput,
} from '@propeldata/ui-kit';

export type LeaderboardQueryVariables = {
  metric: { name: string };
  dimensions: DimensionInput[];
  timeRange: TimeRangeInput;
  rowLimit: number;
  sort?: Sort;
  filters?: FilterInput[];
  propeller?: Propeller;
  timeZone: string;
};

export type LeaderboardResponse = {
  leaderboard: { headers: string[]; rows: string[][] };
};

/**
 * Fetch a metric in leaderboard format.
 */
export const LEADERBOARD = gql`
  query Leaderboard($input: LeaderboardInput!) {
    leaderboard(input: $input) {
      headers
      rows
    }
  }
`;
