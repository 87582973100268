import {
  LEADERBOARD,
  LeaderboardQueryVariables,
  LeaderboardResponse,
} from 'graphql/queries/leaderboard';
import { UseQueryOptions } from '@tanstack/react-query';

import { usePropelQuery } from 'graphql/hooks/usePropelQuery';

export function useLeaderboard(
  variables: LeaderboardQueryVariables,
  options?: UseQueryOptions<LeaderboardResponse>
) {
  return usePropelQuery<
    LeaderboardResponse,
    { input: LeaderboardQueryVariables }
  >(LEADERBOARD, { input: variables }, ['leaderboard'], options);
}
