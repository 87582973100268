import c from 'classnames';
import { Heading, HeadingProps } from 'components/Heading/Heading';

export type TitleProps = Omit<HeadingProps, 'level' | 'asChild'> & {
  size?: 'small';
};

export function Title({ size, className, children, ...props }: TitleProps) {
  return (
    <Heading
      {...props}
      className={c(className, 'title')}
      level={size === 'small' ? '2' : '1'}
      asChild
    >
      <h1>{children}</h1>
    </Heading>
  );
}
