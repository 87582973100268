import React from 'react';
import { CellProps, Column } from 'react-table';

import Gateway from 'types/gateway';

import { relativeTimeFromNow } from 'services/date';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useHasAccess } from 'hooks/useHasAccess';

import { EmptyView } from 'components/EmptyView/EmptyView';
import { Icon } from 'components/Icon/Icon';
import { NameCell, TableLabelsCell } from 'components/Table/Table';
import {
  PaginatedTable,
  PaginatedTableProps,
} from 'components/Table/PaginatedTable';
import { Text } from 'components/Text/Text';

import { GatewaysTableStatusCell } from './StatusCell';

export type GatewaysTableProps = Pick<
  PaginatedTableProps<Gateway>,
  'queryResult' | 'pageSize' | 'page' | 'onPageChange'
> & {
  hasFilters: boolean;
  onCreate: () => void;
};

export function GatewaysTable({
  hasFilters,
  onCreate,
  ...props
}: GatewaysTableProps) {
  const { data: currentPlan, isSuccess } = useCurrentPlan();
  const [hasAccess] = useHasAccess();

  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);
  const canEditTags = hasAccess('deploy_edit', 'gateways');

  const columns = React.useMemo(() => {
    const columns: Column<Gateway>[] = [
      {
        Header: 'Gateway',
        Cell(props: CellProps<Gateway>) {
          const { id, version, model, isTrial } = props.row.original;
          return (
            <NameCell
              {...props}
              to={`/deploy/gateways/${id}`}
              info={
                model
                  ? `${model}${version && `• v${version}`}`
                  : version && `v${version}`
              }
              suffix={
                props.row.original.is_managed && (
                  <Text className="theme primary">
                    <strong>
                      <Icon name="cloud" />
                      {isTrial && <span>Trial</span>}
                    </strong>
                  </Text>
                )
              }
              entity="gateway"
            />
          );
        },
      },
      { Header: 'State', Cell: GatewaysTableStatusCell },
      {
        Header: 'Local IP',
        accessor(gateway: Gateway) {
          return gateway.ip_local;
        },
      },
    ];

    if (canCreateTags) {
      columns.push({
        Header: 'Labels',
        Cell(props: CellProps<Gateway>) {
          return (
            <TableLabelsCell
              {...props}
              entity="gateways"
              listQueryKey={['gateways']}
              singleQueryKey={['gateway']}
              readOnly={!canEditTags}
            />
          );
        },
      });
    }

    columns.push({
      id: 'updated_at',
      Header: 'Recent activity',
      accessor(gateway: Gateway) {
        return relativeTimeFromNow(gateway.updated_at);
      },
      sortType: 'date',
    });

    return columns;
  }, [canCreateTags, canEditTags]);

  return (
    <PaginatedTable
      {...props}
      id="gateways-table"
      label="gateways"
      columns={columns}
      onRowNavigate={({ id }) => `/deploy/gateways/${id}`}
      emptyMessage={
        hasFilters ? (
          <EmptyView title="No gateways matching the filter criteria found.">
            Please try another filter combination or clear filters.
          </EmptyView>
        ) : (
          <EmptyView
            title="You have not added any gateways yet."
            buttonText="Add gateway"
            onButtonClick={onCreate}
          />
        )
      }
    />
  );
}
