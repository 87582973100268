import { UnitLimit } from 'organizations/hooks/useUsageLimits';

export function isCloseToOrganizationUsageLimit({
  planLimit,
  userLimit,
  usage,
}: UnitLimit): boolean {
  if (usage && userLimit) {
    return usage >= userLimit * 0.8;
  }

  if (usage && planLimit) {
    return usage >= planLimit * 0.8;
  }

  return false;
}

export function isExceedingOrganizationUsageLimit({
  planLimit,
  userLimit,
  usage,
}: UnitLimit): boolean {
  if (usage && userLimit) {
    return usage >= userLimit;
  }

  if (usage && planLimit) {
    return usage >= planLimit;
  }

  return false;
}
