import React from 'react';
import { IdType } from 'react-table';
import classNames from 'classnames/bind';

import { Button, ButtonProps } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuAction } from 'components/ContextMenu/ContextMenuAction';
import { Icon, IconType } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';

import styles from './BulkActions.module.scss';

const c = classNames.bind(styles);

export type TableBulkActionType = {
  onClick: (selectedIds: string[]) => void;
  action: string;
  icon?: IconType;
  disabled?: (selectedIDs: string[]) => boolean;
} & Pick<ButtonProps, 'intent' | 'loading' | 'disabledTooltip'>;

export type TableBulkActionsProps<T extends object> = {
  selectedRowIds: Record<IdType<T>, boolean>;
  onClearSelection: () => void;
  onSelectAll?: () => void;
  actions?: TableBulkActionType[];
  totalRows: number;
  label: string;
};

export function TableBulkActions<T extends object>({
  selectedRowIds,
  onSelectAll,
  onClearSelection,
  label,
  actions,
  totalRows,
}: TableBulkActionsProps<T>) {
  const selectedIds = Object.keys(selectedRowIds).filter(
    (key) => selectedRowIds[key]
  );
  const count = selectedIds.length;

  if (!actions) {
    return (
      <p className={c('bulk-info')}>
        <strong>{totalRows}</strong> {label}
      </p>
    );
  }

  function performAction(
    action: TableBulkActionType['action'],
    onClick: TableBulkActionType['onClick']
  ) {
    if (action.toLowerCase() === 'delete') {
      onClearSelection();
    }

    onClick(selectedIds);
  }

  return (
    <div className={c('bulk-actions')}>
      <div className={c('columns valign-center')}>
        {onSelectAll && (
          <Button variant="ghost" size="small" onClick={onSelectAll}>
            Select all on this page
          </Button>
        )}

        {count ? (
          <>
            {onClearSelection && (
              <Button variant="ghost" size="small" onClick={onClearSelection}>
                Clear
              </Button>
            )}

            <p className={c('bulk-info')}>
              <strong className={c('bulk-info-count')}>{count}</strong>
              selected
            </p>
          </>
        ) : null}
      </div>

      <div className={c('buttons')}>
        <ButtonGroup>
          {actions &&
            actions.map(
              ({ onClick, action, intent, icon, disabled, ...rest }) => (
                <Button
                  {...rest}
                  onClick={() => performAction(action, onClick)}
                  variant="secondary"
                  size="small"
                  intent={intent}
                  disabled={disabled?.(selectedIds) || !count}
                  key={action}
                >
                  {icon && <Icon name={icon} />}
                  <span>{action}</span>
                </Button>
              )
            )}
        </ButtonGroup>
      </div>

      <div className={c('context-menu')}>
        {actions && (
          <ContextMenu
            trigger={
              <IconButton
                className={c('control')}
                icon="more-vertical"
                label="Reveal more actions"
                variant="ghost"
              />
            }
          >
            {actions.map(({ onClick, action, intent, icon, disabled }) => (
              <ContextMenuAction
                key={action}
                onClick={() => performAction(action, onClick)}
                icon={icon}
                intent={intent === 'danger' ? 'danger' : undefined}
                disabled={disabled?.(selectedIds) || !count}
              >
                {action}
              </ContextMenuAction>
            ))}
          </ContextMenu>
        )}
      </div>
    </div>
  );
}
