import React from 'react';

import { Role } from 'types/account_role';

import { APIObject } from 'services/api';
import { useAccount } from 'hooks/api/useAccount';
import { useCurrentApplication } from 'application/hooks/useCurrentApplication';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

export type AccessSection =
  | 'overview'

  // Main sections
  | 'design'
  | 'deploy'
  | 'deploy_edit'
  | 'monitor'

  // Settings
  | 'organization_settings'
  | 'application_settings'
  | 'access_tokens'
  | 'gateway_tokens'
  | 'universal_bridge'
  | 'metrics'
  | 'billing';

const HAS_ACCESS_MAP: Record<AccessSection, Role[]> = {
  overview: ['deployer', 'designer', 'owner', 'manager'],

  design: ['designer', 'manager'],
  deploy: ['deployer', 'monitor', 'manager'],
  deploy_edit: ['deployer', 'manager'],
  monitor: ['monitor', 'manager'],

  organization_settings: ['owner', 'manager'],
  application_settings: ['owner', 'manager'],
  access_tokens: ['owner', 'manager'],
  gateway_tokens: ['deployer', 'manager'],
  universal_bridge: ['owner', 'manager', 'deployer'],
  metrics: ['owner', 'manager', 'deployer'],
  billing: ['billing'],
};

const ACCESSIBLE_ENTITIES_FOR_SECTION: Partial<
  Record<AccessSection, readonly (keyof APIObject)[]>
> = {
  deploy: ['gateways', 'cameras', 'streams', 'files', 'deployments'] as const,
  deploy_edit: [
    'gateways',
    'cameras',
    'streams',
    'files',
    'deployments',
  ] as const,
  design: ['pipelines', 'models'] as const,
};

export function useHasAccess() {
  const { data: organization, isLoading: isLoadingOrganizations } =
    useCurrentOrganization();
  const { data: application, isLoading: isLoadingApplication } =
    useCurrentApplication();
  const { data: account, isLoading: isLoadingAccount } = useAccount();

  const appID = application?.id;
  const orgID = organization?.id;

  const hasAccess = React.useCallback(
    (section?: AccessSection, entity?: keyof APIObject) => {
      if (!orgID || !appID || !account) {
        return false;
      }

      if (!section) {
        return true;
      }

      const organizationRights = account.roles.filter(
        ({ organization_id }) => organization_id === orgID
      );
      const hasAccessInOrganization = organizationRights.some(({ name }) =>
        HAS_ACCESS_MAP[section].includes(name)
      );

      const applicationRights = account.roles.filter(
        ({ application_id }) => application_id === appID
      );
      const hasAccessInApplication = applicationRights.some(({ name }) =>
        HAS_ACCESS_MAP[section].includes(name)
      );

      if (entity && !(section in ACCESSIBLE_ENTITIES_FOR_SECTION)) {
        throw new Error(
          `Missing role entry in accessible entity map for section ${section}.`
        );
      }

      const hasAccessToEntity =
        !entity || ACCESSIBLE_ENTITIES_FOR_SECTION[section]!.includes(entity);

      return (
        (hasAccessInOrganization || hasAccessInApplication) && hasAccessToEntity
      );
    },
    [account, appID, orgID]
  );

  return [
    hasAccess,
    isLoadingOrganizations || isLoadingApplication || isLoadingAccount,
  ] as const;
}
