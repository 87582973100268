import React from 'react';

import APIError from 'types/api_error';

import { Button } from 'components/Button/Button';

import { Notification, NotificationProps } from './Notification';

export type ErrorNotificationProps = NotificationProps & {
  error?: APIError;
  onRetry?: () => void;
};

export function ErrorNotification({
  icon = 'error',
  error,
  onRetry,
  ...props
}: ErrorNotificationProps) {
  return (
    <Notification
      {...props}
      icon={icon}
      variant="danger"
      message={error?.message}
      action={
        onRetry && (
          <Button onClick={onRetry} size="small" variant="secondary">
            Retry
          </Button>
        )
      }
      timed
    />
  );
}
