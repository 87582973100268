import React from 'react';
import classnames from 'classnames/bind';
import { NodeEditor } from 'rete';

import { NodeCategory } from 'pipelines/services/rete/nodes/categories';
import {
  findFirstChild,
  findLastChild,
  findNextSibling,
  findPreviousSibling,
} from 'services/dom_elements';

import { Icon } from 'components/Icon/Icon';

import { DockNode } from '../DockNode/DockNode';
import styles from './EditorDocks.module.scss';

const c = classnames.bind(styles);

export type PipelineEditorDocksItemProps = {
  editor: NodeEditor;
  category: NodeCategory;
  index: number;
  isEmpty?: boolean;
  searchTerm: string;
  onReset: () => void;
};

export function PipelineEditorDocksItem({
  editor,
  category,
  index,
  isEmpty,
  searchTerm,
  onReset,
}: PipelineEditorDocksItemProps) {
  const id = `dock${index}`;
  const container = React.useRef<HTMLDetailsElement>(null);
  const nodeContainer = React.useRef<HTMLDivElement>(null);
  const dockNodes = React.useRef<HTMLDivElement[]>();

  function handleSummaryKeyDown(
    event: React.KeyboardEvent<HTMLDetailsElement>
  ) {
    if (!nodeContainer.current) {
      return;
    }

    if (!dockNodes.current) {
      initializeDockNodes();
    }

    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowDown': {
        event.preventDefault();

        // Find first dock-item and focus it
        const first = findFirstChild(nodeContainer.current);
        first?.focus();
        return;
      }
      case 'ArrowLeft':
      case 'ArrowUp': {
        event.preventDefault();

        // Find last dock-item and focus it
        const last = findLastChild(nodeContainer.current);
        last?.focus();
      }
    }
  }

  const handleDockNodeKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (!dockNodes.current || !nodeContainer.current) {
        return;
      }

      const idx = dockNodes.current.findIndex(
        (dockNode) => dockNode === event.currentTarget
      );
      const currentNode = event.currentTarget as HTMLElement;
      let nextNode: HTMLElement | undefined;

      switch (event.key) {
        case ' ':
        case 'Enter': {
          // Push to canvas handled by DockPlugin
          onReset();
          break;
        }

        case 'ArrowRight':
        case 'ArrowDown': {
          event.preventDefault();

          if (idx < dockNodes.current.length - 1) {
            nextNode = findNextSibling(currentNode);
          } else {
            nextNode = findFirstChild(nodeContainer.current);
          }

          break;
        }
        case 'ArrowLeft':
        case 'ArrowUp': {
          event.preventDefault();

          if (idx > 0) {
            nextNode = findPreviousSibling(currentNode);
          } else {
            nextNode = findLastChild(nodeContainer.current);
          }

          break;
        }
      }

      nextNode?.focus();
    },
    [onReset]
  );

  const initializeDockNodes = React.useCallback(() => {
    if (!container.current) {
      return;
    }

    dockNodes.current = Array.from(
      container.current.querySelectorAll('.dock-item')
    );
    dockNodes.current.forEach((node) => {
      node.addEventListener('keydown', handleDockNodeKeyDown);
    });
  }, [handleDockNodeKeyDown]);

  React.useEffect(() => {
    if (!searchTerm.trim() || searchTerm.length < 2) {
      dockNodes.current?.forEach((node) => {
        node.style.display = 'block';
      });
      container.current?.removeAttribute('data-disabled');
      return;
    }

    const searchStr = searchTerm.toUpperCase();

    if (!dockNodes.current) {
      initializeDockNodes();
    }

    const matches = dockNodes.current?.filter((node) => {
      const meta = Array.from<HTMLElement>(
        node.querySelectorAll('[data-search]')
      ).map((el) => el.innerText);

      const isMatchingTerm = meta.some((str) =>
        str.toUpperCase().includes(searchStr)
      );

      if (isMatchingTerm) {
        node.style.display = 'block';
      } else {
        node.style.display = 'none';
      }

      return isMatchingTerm;
    });

    if (!matches || matches.length === 0) {
      container.current?.setAttribute('data-disabled', 'true');
    } else {
      container.current?.removeAttribute('data-disabled');
    }
  }, [searchTerm, initializeDockNodes]);

  React.useEffect(() => {
    return () => {
      dockNodes.current?.forEach((node) =>
        node.removeEventListener('keydown', handleDockNodeKeyDown)
      );
    };
  }, [handleDockNodeKeyDown]);

  return (
    <details
      key={id}
      id={id}
      className={c('group', { empty: isEmpty })}
      ref={container}
      open
    >
      {!isEmpty && (
        <summary className={c('header')} onKeyDown={handleSummaryKeyDown}>
          <span>
            <Icon name="chevron-right" className={c('indicator')} />
            <span className={c('category-label', 'label')}>
              {category.name}
            </span>
          </span>
        </summary>
      )}

      <div id={`${id}_items`} className={c('items')} ref={nodeContainer}>
        {category.components.map((Component) => (
          <DockNode
            editor={editor}
            Component={Component}
            category={category}
            key={Component.key}
          />
        ))}
      </div>
    </details>
  );
}
