import Stream from 'types/stream';

import { useStream } from 'streams/hooks/useStream';

import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/Text/Text';

import { c } from './CameraDetailStreams';
import { StreamSnapshotListElement } from './StreamSnapshotListElement';

export type CameraStreamListElementProps = {
  streamID: Stream['id'];
  hideLoader?: boolean;
};

export function CameraStreamListElement({
  streamID,
  hideLoader,
}: CameraStreamListElementProps) {
  const { data: stream } = useStream(streamID);

  if (!stream && hideLoader) {
    return null;
  }

  if (!stream) {
    return <Loader text="Loading stream..." />;
  }

  return (
    <li className={c('stream', 'stream-container')}>
      <StreamSnapshotListElement streamID={stream.id} />
      <div className={c('stream-info')}>
        <strong>{stream.name}</strong>
        <Text>
          <CopyToClipboard key={stream.id} value={String(stream.id)} />
        </Text>
      </div>
    </li>
  );
}
