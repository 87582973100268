import React from 'react';
import classNames from 'classnames/bind';

import styles from './TagsTree.module.scss';

const c = classNames.bind(styles);

export function TagsTreeSkeletonItem() {
  return (
    <div className={c('item', 'skeleton')}>
      <span className={c('skeleton-text')} />
    </div>
  );
}
