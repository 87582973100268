import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';

import { useAPI } from 'hooks/api/useAPI';
import { useAppQueryClient } from 'hooks/useAppQueryClient';

export function useCreateCamera({
  onSettled,
  onMutate,
  ...options
}: UseMutationOptions<Camera, APIError, Camera> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useMutation(api.cameras.create, {
    ...options,
    onMutate: (camera) => {
      const previousCameras =
        queryClient.getQueryData<Camera[]>(['cameras']) || [];

      if (previousCameras) {
        queryClient.setQueryData(['cameras'], [...previousCameras, camera]);
      }

      return { previousCameras };
    },
    onSettled: (...args) => {
      if (onSettled) {
        onSettled(...args);
      }

      queryClient.invalidateQueries(['cameras']);
      queryClient.invalidateQueries(['video-sources']);
      queryClient.invalidateQueries(['linked-cameras']);
      queryClient.invalidateQueries(['discovered-cameras']);
    },
  });
}
