import { UseMutationOptions } from '@tanstack/react-query';

import Gateway from 'types/gateway';
import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';
import { useAppQueryClient } from 'hooks/useAppQueryClient';

type UseDeleteGatewayContext = {
  previousGateways: Gateway[] | undefined;
} | void;

export function useDeleteGateway({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<
  void,
  APIError,
  Gateway['id'],
  UseDeleteGatewayContext
> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useDangerousMutation(
    `Are you sure you want to delete this gateway?`,
    (gatewayID) => {
      if (!gatewayID) {
        throw new Error('No gateway specified.');
      }

      return api.gateways.delete(gatewayID);
    },
    {
      info: 'This will also delete cameras and streams linked to this gateway.',
      async onMutate(gatewayID) {
        await queryClient.cancelQueries(['gateways']);
        const gateways =
          queryClient.getQueryData<Gateway[]>(['gateways']) || [];
        const updatedGateways = gateways.filter(({ id }) => id !== gatewayID);

        queryClient.setQueryData(['gateways'], updatedGateways);

        onMutate?.(gatewayID);

        return { previousGateways: gateways };
      },
      onError(err, deletedGateway, context) {
        if (context?.previousGateways) {
          queryClient.setQueryData(['gateways'], context.previousGateways);
        }

        onError?.(err, deletedGateway, context);
      },
      onSettled(...args) {
        queryClient.invalidateQueries(['gateways']);
        queryClient.invalidateQueries(['usage_statistics']);
        onSettled?.(...args);
      },
      ...options,
    }
  );
}
