import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { MultiSelectInput } from 'components/MultiSelectInput/MultiSelectInput';

import { useModelFormState } from '../context/useModelFormState';
import { FIELD_LABEL_WIDTH, Step2FieldValues } from '../Step2';

export function FilterOutClassIDsInput() {
  const {
    formState: { errors },
    control,
  } = useFormContext<Step2FieldValues>();
  const { labels, data } = useModelFormState();

  if (!labels && !data?.labels) {
    return null;
  }

  const labelsString = data?.labels || labels || '';

  const labelsArray = labelsString.split('\n');
  const options = labelsArray.map((value) => ({ value }));

  return (
    <Field
      id="filter_out_class_ids"
      label="Filter out class IDs"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      info="Discard detection results for objects of the defined types."
      error={errors.inference_config?.filter_out_class_ids}
    >
      <Controller
        name="inference_config.filter_out_class_ids"
        control={control}
        render={({ field: { onChange, value } }) => (
          <MultiSelectInput
            id="filter_out_class_ids"
            options={options}
            onChange={onChange}
            selected={value}
          />
        )}
      />
    </Field>
  );
}
