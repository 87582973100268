import {
  QueryKey,
  UseMutationOptions,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { Userpilot } from 'userpilot';

import APIError from 'types/api_error';
import Camera from 'types/camera';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';
import Stream from 'types/stream';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';
import { TagParams } from 'tags/types/tagged';

import { addErrorNotification } from 'services/notification';
import { getOverloadedQueryParams } from 'services/get_overloaded_query_params';
import { pluralize } from 'services/string';
import {
  updateQueriesData,
  UpdateQueriesDataContext,
} from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';
import { useUser } from 'hooks/useAuth';

import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { useDangerousMutation } from './useDangerousMutation';
import { useMutationWithError } from './useMutationWithError';
import { useBillingMutation } from './useBillingMutation';

export type UseDeploymentsFilterParams = OffsetPaginationParams &
  TagParams & {
    deployment_ids?: Deployment['id'][];
    deployment_names?: Deployment['name'][];
    always_on?: boolean;
    states?: Deployment['state'][];

    video_source_ids?: (Camera | Stream)['id'][];
    video_source_names?: (Camera | Stream)['name'][];

    gateway_ids?: Gateway['id'][];
    gateway_names?: Gateway['name'][];

    pipeline_ids?: Pipeline['id'][];
    pipeline_names?: Pipeline['name'][];

    /** ISO date string */
    created_ts_since?: string;
    /** ISO date string */
    created_ts_until?: string;
    /** ISO date string */
    updated_ts_since?: string;
    /** ISO date string */
    updated_ts_until?: string;
    /** Private API parameters: this is a mitigation to counter https://www.notion.so/lumeo/High-Load-High-Data-transfer-5xx-2023-11-03-74d4e6a7221044c7a2234f9b9b03e78f
     * This should be removed in the future when https://app.shortcut.com/lumeo/story/9237/dedicated-internal-deployment-list-endpoint-for-console is implemented
     * */
    exclude_configuration?: boolean;
    exclude_definition?: boolean;
  };

export type UseDeploymentsOptions = UseQueryOptions<
  PaginatedAPIResponse<Deployment>,
  APIError
>;

/**
 * Retrieve deployment data using react-query.
 */
export function useDeployments(
  filters?: UseDeploymentsFilterParams,
  options?: UseDeploymentsOptions
): UseQueryResult<PaginatedAPIResponse<Deployment>, APIError>;

export function useDeployments(
  queryKey?: QueryKey,
  filters?: UseDeploymentsFilterParams,
  options?: UseDeploymentsOptions
): UseQueryResult<PaginatedAPIResponse<Deployment>, APIError>;

export function useDeployments(
  queryKeyOrFilters?: QueryKey | UseDeploymentsFilterParams,
  filtersOrOptions?: UseDeploymentsFilterParams | UseDeploymentsOptions,
  queryOptions?: UseDeploymentsOptions
) {
  const api = useAPI();
  const user = useUser();

  const {
    queryKey = ['deployments'],
    filters: {
      pagination = 'offset',
      page = 1,
      limit = 50,
      exclude_configuration = true, // eslint-disable-line
      exclude_definition = true, // eslint-disable-line
      ...filters
    } = {},
    options,
  } = getOverloadedQueryParams(
    queryKeyOrFilters,
    filtersOrOptions,
    queryOptions
  );

  const params: UseDeploymentsFilterParams = {
    ...filters,
    pagination,
    limit,
    page,
    exclude_configuration,
    exclude_definition,
  };

  return useAuthenticatedQuery<PaginatedAPIResponse<Deployment>>(
    [...queryKey, api.applicationID, params],
    () => api.deployments.list(params),
    {
      keepPreviousData: true,
      ...options,
      onSuccess(response) {
        if (!user?.id) {
          return;
        }

        queryOptions?.onSuccess?.(response);

        Userpilot.identify(user.id, {
          has_deployment: response.data.length > 0,
          application_id: api.applicationID,
        });
      },
    }
  );
}

export function useDeleteDeployments({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<
  void[],
  APIError,
  Deployment['id'][],
  UpdateQueriesDataContext<Deployment> | void
> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete these deployments?',
    (deploymentIds) => {
      if (!deploymentIds.length || !deploymentIds) {
        return Promise.resolve([]);
      }
      return Promise.all(deploymentIds.map(api.deployments.delete));
    },
    {
      ...options,
      async onMutate(deploymentIds) {
        await queryClient.cancelQueries(['deployments']);
        onMutate?.(deploymentIds);

        return updateQueriesData<Deployment>({
          queryClient,
          listQueryKey: ['deployments'],
          singleQueryKey: ['deployment'],
          ids: deploymentIds,
          updateData: null,
        });
      },
      onError(error, deletedDeploymentIDs, context) {
        onError?.(error, deletedDeploymentIDs, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(...args) {
        onSettled?.(...args);

        queryClient.invalidateQueries(['deployments']);
      },
    }
  );
}

export function useUpdateDeploymentsState() {
  const api = useAPI();

  const { mutate: start, isLoading: isStarting } = useBillingMutation(
    (deploymentIds: Deployment['id'][]) => {
      if (!deploymentIds.length || !deploymentIds) {
        return Promise.resolve([]);
      }

      return Promise.all(deploymentIds.map(api.deployments.start));
    },
    {
      entity: 'deployment',
      action: 'start',
      onError(error, deploymentIds) {
        addErrorNotification({
          title: `Unable to start ${pluralize(deploymentIds.length, 'deployment')}`,
          error,
        });
      },
    }
  );

  const { mutate: stop, isLoading: isStopping } = useMutationWithError(
    (deploymentIds: Deployment['id'][]) => {
      if (!deploymentIds.length || !deploymentIds) {
        return Promise.resolve([]);
      }

      return Promise.all(deploymentIds.map(api.deployments.stop));
    },
    {
      entity: 'deployment',
      action: 'stop',
      onError(error, deploymentIds) {
        addErrorNotification({
          title: `Unable to stop ${pluralize(deploymentIds.length, 'deployment')}`,
          error,
        });
      },
    }
  );

  return { start, stop, isStarting, isStopping };
}
