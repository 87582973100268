import React from 'react';
import { useToggle } from '@mantine/hooks';

import { Input, InputProps } from 'components/Input/Input';
import { IconButton } from 'components/IconButton/IconButton';

export type PasswordInputProps = Omit<InputProps, 'type' | 'button'>;

function PasswordInput(
  { className, size, ...props }: PasswordInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const [isVisible, toggleIsVisible] = useToggle();

  return (
    <div className={className}>
      <Input
        {...props}
        type={isVisible ? 'text' : 'password'}
        size={size}
        button={
          <IconButton
            icon={isVisible ? 'eye-off' : 'eye'}
            label=""
            onClick={() => toggleIsVisible()}
            size={size !== 'large' ? 'small' : undefined}
            variant="ghost"
          />
        }
        ref={ref}
      />
    </div>
  );
}

const ForwardRef = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  PasswordInput
);

export { ForwardRef as PasswordInput };
