import pLimit from 'p-limit';
import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Deployment from 'types/deployment';
import { OffsetPaginated } from 'types/pagination';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { UseDeploymentsFilterParams } from 'hooks/api/useDeployments';

export type UseAllDeploymentsOptions = UseQueryOptions<
  Deployment[],
  APIError
> & {
  params: Omit<UseDeploymentsFilterParams, 'pagination' | 'page'>;
};

const limit = pLimit(10);

export function useAllDeployments({
  params,
  ...options
}: UseAllDeploymentsOptions) {
  const { applicationID, client } = useAPI();

  return useAuthenticatedQuery<Deployment[]>(
    ['deployments', params, applicationID],
    async () => {
      let allDeployments: Deployment[] = [];
      let page = 1;
      let totalPages = 1;

      while (page <= totalPages) {
        const pageData = await limit(async () => {
          const { data } = await client.get<OffsetPaginated<Deployment>>(
            `/apps/${applicationID}/deployments`,
            {
              params: {
                ...params,
                pagination: 'offset',
                page,
              },
            }
          );
          return data;
        });

        totalPages = pageData.total_pages;
        allDeployments = [...allDeployments, ...pageData.data];
        page += 1;
      }

      return allDeployments;
    },
    options
  );
}
