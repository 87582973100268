import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Account from 'types/account';

import { updateQueriesData } from 'services/update_queries_data';

import { useAPI } from 'hooks/api/useAPI';
import { useAccount } from 'hooks/api/useAccount';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';

/** Remove member from organization */
export function useDeleteOrganizationMember({
  onMutate,
  onSettled,
  onError,
  ...options
}: UseMutationOptions<void, APIError, Account['id']> = {}) {
  const queryClient = useQueryClient();
  const { applicationID, organizations } = useAPI();
  const { data: currentAccount } = useAccount();

  const { data: organization } = useCurrentOrganization();
  const organizationId = organization?.id;

  return useDangerousMutation(
    'Are you sure you want remove this member from the organization?',
    (accountId) => {
      if (!organizationId || !accountId) {
        return Promise.reject('Missing organization or account id');
      }

      return organizations.members.deleteOrganizationMember(
        organizationId,
        accountId
      );
    },
    {
      ...options,
      action: 'delete',
      entity: 'member',
      onMutate(accountId) {
        onMutate?.(accountId);

        if (!organizationId || !accountId) {
          return;
        }

        if (currentAccount && currentAccount.id === accountId) {
          queryClient.cancelQueries(['account']);
        }

        queryClient.cancelQueries(['members']);
        queryClient.cancelQueries(['member', accountId, applicationID]);

        return updateQueriesData({
          queryClient,
          listQueryKey: ['members'],
          singleQueryKey: ['member'],
          ids: [accountId],
          updateData: null,
        });
      },
      onError(error, variables, context) {
        onError?.(error, variables, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(data, error, accountId, context) {
        onSettled?.(data, error, accountId, context);

        if (currentAccount && currentAccount.id === accountId) {
          queryClient.invalidateQueries(['account']);
        }

        queryClient.invalidateQueries(['member', accountId, applicationID]);
        queryClient.invalidateQueries(['members']);
      },
    }
  );
}
