import { createContext } from 'react';

import Pipeline from 'types/pipeline';
import Component from '../pipelines/services/rete/nodes/component';

type PipelineContextProps = {
  pipeline?: Pipeline;
  components?: Array<Component | typeof Component>;
  updatePipeline?: (pipeline: Pipeline) => Promise<Pipeline>;
  deletePipeline?: (pipeline: Pipeline) => Promise<void>;
  updatingDeployment?: string;
  getNodeID?: (exportType: string) => string;
};

const PipelineContext = createContext({} as PipelineContextProps);

export default PipelineContext;
