import React from 'react';

import Stream from 'types/stream';

import { useSnapshot } from 'hooks/useSnapshot';
import { useStream } from 'streams/hooks/useStream';

import { capitalizeFirst } from 'services/string';

import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/Text/Text';

import { MediaPreview } from 'components/MediaPreview/MediaPreview';
import { c } from './CameraDetailStreams';

export type StreamSnapshotListElementProps = {
  streamID: Stream['id'];
};

export function StreamSnapshotListElement({
  streamID,
}: StreamSnapshotListElementProps) {
  const { data: stream } = useStream(streamID);
  const {
    url,
    file,
    isLoading: isLoadingSnapshot,
    error,
  } = useSnapshot(stream);
  const [hasFullyLoadedImage, setHasFullyLoadedImage] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(
    isLoadingSnapshot || !url || !hasFullyLoadedImage
  );

  React.useEffect(() => {
    setIsLoading(isLoadingSnapshot || !url || !hasFullyLoadedImage);
  }, [hasFullyLoadedImage, isLoadingSnapshot, url]);

  function preview() {
    if (!stream || error || stream.isOffline) {
      return specialCases();
    }

    return (
      <>
        {file && url && (
          <img
            className={c('img-preview')}
            src={url}
            onLoad={() => setHasFullyLoadedImage(true)}
          />
        )}

        {isLoading && (
          <div className={c('img-spinner')}>
            <span className="loading-spinner small" />
          </div>
        )}
      </>
    );
  }

  function specialCases() {
    if (!stream) {
      return <Loader text="Loading streams..." size="small" />;
    }

    if (error) {
      if (error.status === 400) {
        error.message = 'Snapshot not available.';
      }

      return (
        <div>
          <Text className={c('img-info')}>
            {error.message ||
              'An unknown error occurred. Please try again later.'}
          </Text>
        </div>
      );
    }

    if (stream.isOffline) {
      return (
        <div>
          <Text className={c('img-info')}>
            {capitalizeFirst(stream.source_type)} is unavailable.
          </Text>
        </div>
      );
    }
  }

  return (
    <div className={c('stack')}>
      {preview()}
      {stream && stream.isStreamable && (
        <MediaPreview className={c('mediapreview-wrap')} stream={stream} />
      )}
    </div>
  );
}
