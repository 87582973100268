import React from 'react';
import classnames from 'classnames/bind';

import { generateId } from 'services/string';

import { useForwardedRef } from 'hooks/useForwardedRef';

import styles from './LightSwitch.module.scss';

const c = classnames.bind(styles);

export type LightSwitchProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type'
> & {
  onValueChange?: (checked: boolean) => void;
  indeterminate?: boolean;
};

function LightSwitch(
  {
    id,
    className,
    checked,
    indeterminate,
    onValueChange,
    onChange,
    ...props
  }: LightSwitchProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const innerRef = useForwardedRef(ref);
  const localId = id || generateId();

  React.useEffect(() => {
    if (!innerRef.current || indeterminate === undefined || checked === false) {
      return;
    }

    innerRef.current.indeterminate = indeterminate;
  }, [innerRef, indeterminate, checked]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(event);
    onValueChange?.(event.target.checked);
  }

  return (
    <div className={c('wrap', className)}>
      <input
        {...props}
        type="checkbox"
        className={c('sr-only')}
        id={localId}
        checked={checked}
        onChange={handleChange}
        ref={innerRef}
      />
      <label htmlFor={localId} className={c('faux')} aria-hidden="true"></label>
    </div>
  );
}

const ForwardRef = React.forwardRef<HTMLInputElement, LightSwitchProps>(
  LightSwitch
);

export { ForwardRef as LightSwitch };
