import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { Radio, RadioOption } from 'components/Radio/Radio';

import { FIELD_LABEL_WIDTH, Step2FieldValues } from '../Step2';

export function InputLayerOrderInput() {
  const {
    control,
    formState: { errors },
  } = useFormContext<Step2FieldValues>();

  return (
    <Field
      id="input_order"
      label="Input layer order"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      info="Determines order of input layer dimension values. Batch N, channels C, height H, width W."
      error={errors.inference_config?.input_order}
      required
    >
      <Controller
        name="inference_config.input_order"
        control={control}
        defaultValue="nchw"
        render={({ field: { value, onChange } }) => (
          <Radio id="input_order" value={value} onChange={onChange}>
            <RadioOption value="nc">NC</RadioOption>
            <RadioOption value="nchw">NCHW</RadioOption>
            <RadioOption value="nhwc">NHWC</RadioOption>
          </Radio>
        )}
        rules={{ required: 'Please select an input layer order.' }}
      />
    </Field>
  );
}
