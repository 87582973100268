export function capitalizeFirst(str?: string): string {
  if (!str) {
    return '';
  }

  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export function getFileNameFromUrl(str?: string): string {
  if (!str) {
    return '';
  }

  const startPos = str.lastIndexOf('/');
  const endPos = str.indexOf('?');

  if (endPos >= 0) {
    return str.slice(startPos + 1, endPos);
  }

  return str.slice(startPos + 1);
}

export function truncateMiddle(
  string: string,
  targetLength: number,
  separator = '...'
): string {
  if (string.length <= targetLength) {
    return string;
  }

  const length = targetLength - separator.length;
  const begin = string.substring(0, Math.ceil(length / 2));
  const end = string.substring(string.length - Math.floor(length / 2));

  return `${begin}${separator}${end}`;
}

export function generateId(): string {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

/**
 * Converts strings to human-readable format.
 *
 * @example
 * const snakeCase = 'My_String';
 * const camelCase = 'MyString';
 * const kebabCase = 'My-String';
 *
 * formatPretty(snakeCase); => 'My string'
 * formatPretty(camelCase); => 'My string'
 * formatPretty(kebabCase); => 'My string'
 */
export function formatPretty(string: string) {
  return string
    .toLowerCase()
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/-/g, ' ') // Replace hyphens with spaces
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add spacing if camel case
    .replace(/^./, (c) => c.toUpperCase()); // Capitalize the first letter
}

/**
 * Returns singular or plural form of the word based on count.
 *
 * @example
 * pluralize(0, 'book'); => 'books'
 * pluralize(1, 'book'); => 'book'
 * pluralize(2, 'book'); => 'books'
 * pluralize(2, 'child', 'children'); => 'children'
 */
export function pluralize(count: number, word: string, plural = `${word}s`) {
  return count === 1 ? word : plural;
}

export function hyphenate(str: string): string {
  return str.replace(/\s+/g, '-').toLowerCase();
}
