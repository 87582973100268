import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import { GatewayConfiguration } from 'types/gateway_configuration';

import { useAPI } from 'hooks/api/useAPI';
import { useCurrentApplication } from 'application/hooks/useCurrentApplication';
import { useUpdateApplication } from 'application/hooks/useUpdateApplication';

export type UseCreateGatewayConfigurationParams = Pick<
  GatewayConfiguration,
  'metrics'
>;

export function useCreateGatewayConfiguration({
  onSettled,
  ...options
}: UseMutationOptions<
  GatewayConfiguration,
  APIError,
  UseCreateGatewayConfigurationParams
> = {}) {
  const queryClient = useQueryClient();
  const api = useAPI();

  const { data: application } = useCurrentApplication();
  const { mutate: updateApplication } = useUpdateApplication();

  function updateDefaultGatewayConfigurationId(
    gatewayConfiguration: GatewayConfiguration
  ) {
    if (
      !gatewayConfiguration.id ||
      !gatewayConfiguration.metrics ||
      !application
    ) {
      return;
    }

    const updatedApplication = application.copy();
    updatedApplication.default_gateway_configuration_id =
      gatewayConfiguration.id;

    updateApplication(updatedApplication);
  }

  return useMutation(api.applications.gatewayConfiguration.create, {
    ...options,
    onSettled: (gatewayConfiguration, ...args) => {
      // Currently there can only be one gateway configuration. It is set as the default automatically.
      // In the future this should be user controlled.
      if (gatewayConfiguration) {
        updateDefaultGatewayConfigurationId(gatewayConfiguration);
      }

      onSettled?.(gatewayConfiguration, ...args);

      queryClient.invalidateQueries(['gateway-configurations']);
    },
  });
}
