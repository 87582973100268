import React from 'react';
import classnames from 'classnames/bind';

import { useCurrentApplication } from 'application/hooks/useCurrentApplication';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

import { Icon } from 'components/Icon/Icon';
import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './Table.module.scss';

const c = classnames.bind(styles);

export function OrganizationRolesHeader() {
  const { data: organization } = useCurrentOrganization();
  const organizationName = organization?.name;

  return (
    <div>
      {organizationName ? (
        <Tooltip
          content={`Roles in this column are assigned to a member across the ${organizationName} organization.`}
        >
          <div>
            <strong>{organizationName}</strong>
            <Icon name="help" size="xsmall" />
          </div>
        </Tooltip>
      ) : (
        <strong className={c('skeleton-text')}>
          Loading organization name...
        </strong>
      )}
    </div>
  );
}

export function ApplicationRolesHeader() {
  const { data: application } = useCurrentApplication();
  const applicationName = application?.name;

  return (
    <div>
      {applicationName ? (
        <Tooltip
          content={`Roles in this column are assigned to a member only for the ${applicationName} workspace.`}
        >
          <div>
            <strong>{applicationName}</strong>
            <Icon name="help" size="xsmall" />
          </div>
        </Tooltip>
      ) : (
        <strong className={c('skeleton-text')}>
          Loading application name...
        </strong>
      )}
    </div>
  );
}
