import React from 'react';
import classnames from 'classnames/bind';

import { Icon, IconType } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';

import styles from './Input.module.scss';

const c = classnames.bind(styles);

export type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size'
> & {
  className?: string;
  unit?: string;
  icon?: IconType;
  error?: boolean | null | string | 0;
  prefix?: string;
  suffix?: string;
  button?: React.ReactElement;
  size?: 'large' | 'small';
  loading?: boolean;
  keyrefValue?: unknown;
  onValueChange?: (newValue: string) => void;
};

function Input(
  {
    id,
    className,
    placeholder,
    type: inputType = 'text',
    disabled,
    readOnly,
    unit,
    icon,
    error,
    prefix,
    suffix,
    value,
    defaultValue,
    button,
    size,
    loading,
    keyrefValue,
    onChange,
    onValueChange,
    ...rest
  }: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const type = inputType === 'code' ? 'string' : inputType;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(event);
    onValueChange?.(event.target.value);
  }

  return (
    <div
      className={c('textbox', className, inputType, size, {
        disabled,
        readOnly,
        error,
      })}
    >
      {prefix && (
        <label htmlFor={id} className={c('prefix')}>
          {prefix}
        </label>
      )}

      {icon && <Icon name={icon} className={styles.icon} />}

      {unit && (
        <label htmlFor={id} className={c('unit')}>
          {unit}
        </label>
      )}
      <input
        {...rest}
        className={c('field')}
        id={id}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        ref={ref}
        value={value !== undefined ? value || '' : undefined}
        defaultValue={
          defaultValue !== undefined ? defaultValue || '' : undefined
        }
        onChange={handleChange}
      />

      {loading && (
        <span className={c('loader')}>
          <Loader size="xsmall" text="Loading..." />
        </span>
      )}

      {suffix && (
        <label htmlFor={id} className={c('suffix')}>
          {suffix}
        </label>
      )}

      {button && <div className={c('button')}>{button}</div>}
    </div>
  );
}

const ForwardRef = React.forwardRef<HTMLInputElement, InputProps>(Input);

export { ForwardRef as Input };
