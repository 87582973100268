import React from 'react';
import classnames from 'classnames/bind';

import { generateId } from 'services/string';

import {
  NumberInput,
  NumberInputProps,
} from 'components/NumberInput/NumberInput';
import { Radio, RadioOption, RadioValue } from 'components/Radio/Radio';

import { getWidthAndHeightFromResolutionString } from './get_resolution_from_string';
import styles from './ResolutionInput.module.scss';

const c = classnames.bind(styles);

export type ResolutionString = `${number}x${number}`;

export type ResolutionValueType = {
  width: number | ResolutionString | null;
  height: number | ResolutionString | null;
};

export const RESOLUTION_PRESETS = [
  {
    label: '1920x1080',
    value: '1920x1080' as ResolutionString,
  },
  {
    label: '1280x720',
    value: '1280x720' as ResolutionString,
  },
  { label: '1280x1280', value: '1280x1280' as ResolutionString },
  { label: '960x960', value: '960x960' as ResolutionString },
  {
    label: '640x480',
    value: '640x480' as ResolutionString,
  },
  {
    label: '640x360',
    value: '640x360' as ResolutionString,
  },
  {
    label: 'Source Default',
    value: null,
  },
];

type ResolutionOption = {
  label: string;
  value: string | null;
};

export type ResolutionInputProps = {
  id: string;
  onChange: (value: ResolutionValueType) => void;
  onBlur?: () => void;
  height?: number | null;
  width?: number | null;
  placeholder?: string;
  presets?: ResolutionOption[] | false;
  required?: boolean;
};

// Output type `WIDTHxHEIGHT`
export type StringResolutionInputProps = Omit<
  ResolutionInputProps,
  'onChange' | 'height' | 'width' | 'presets'
> & {
  value?: string | null;
  onChange?: (value: ResolutionString | null) => void;
  onBlur?: () => void;
  presets: ResolutionOption[] | false;
  required?: boolean;
};

const numberClassNames: NumberInputProps['classNames'] = {
  root: c('input-root'),
};

function ResolutionInput(
  {
    id,
    onChange,
    onBlur,
    placeholder,
    width: initialWidth = null,
    height: initialHeight = null,
    presets,
    required,
  }: ResolutionInputProps,
  ref: React.ForwardedRef<HTMLLabelElement>
) {
  const [width, setWidth] = React.useState(initialWidth ?? '');
  const [height, setHeight] = React.useState(initialHeight ?? '');

  const selectedPreset =
    width && height ? `${Number(width)}x${Number(height)}` : null;

  const [isWidthTouched, setIsWidthTouched] = React.useState(false);
  const [widthError, setWidthError] = React.useState<string>();
  const [isHeightTouched, setIsHeightTouched] = React.useState(false);
  const [heightError, setHeightError] = React.useState<string>();

  const [error, setError] = React.useState<string | null>('');

  id = id || generateId();

  function handleChange({ width, height }: ResolutionValueType) {
    if (!width && !height) {
      onChange({ width: null, height: null });
      setWidth('');
      setHeight('');
      return;
    }

    // only update when both values are valid
    if (Number.isFinite(Number(width)) && Number.isFinite(Number(height))) {
      setError(null);

      // whenever height or width are empty reset the value to automatic
      // and show an error message that both values must be either set or empty
      if (!height || !width) {
        setError('wh-required');
      }
    }

    if (!Number.isFinite(Number(width)) || !Number.isFinite(Number(height))) {
      setError('invalid');
    }

    onChange({ width: Number(width), height: Number(height) });
    setWidth(Number(width));
    setHeight(Number(height));
  }

  function handleWidthChange(width: number | string) {
    const numWidth = Number.isFinite(width) ? Number(width) : null;
    setWidth(Number.isFinite(width) ? Number(width) : '');
    handleChange({
      width: numWidth,
      height: (Number.isFinite(height) && Number(height)) || null,
    });
  }

  function handleHeightChange(height: number | string) {
    const numHeight = Number.isFinite(height) ? Number(height) : null;
    setHeight(Number.isFinite(height) ? Number(height) : '');
    handleChange({
      width: (Number.isFinite(width) && Number(width)) || null,
      height: numHeight,
    });
  }

  function handlePresetChange(preset: RadioValue) {
    const [width, height] = getWidthAndHeightFromResolutionString(
      String(preset)
    );
    setWidth(width);
    setHeight(height);
    handleChange({ width, height });
  }

  const props: Partial<NumberInputProps> = {
    classNames: numberClassNames,
    step: 1,
  };

  return (
    <div onBlur={onBlur} tabIndex={-1}>
      <label className={c('wrap', 'focus-ring-within')} htmlFor={id} ref={ref}>
        <label htmlFor={id} className={c('unit')}>
          W
        </label>
        <NumberInput
          {...props}
          id={id}
          onChange={handleWidthChange}
          onFocus={() => setIsWidthTouched(true)}
          onInvalid={(message) =>
            setWidthError(message ? `Width: ${message}` : undefined)
          }
          value={width}
          placeholder={placeholder}
          min={0}
          hideControls
        />
        <span className={c('times')}>&times;</span>
        <label htmlFor={`${id}-height`} className={c('unit')}>
          H
        </label>
        <NumberInput
          {...props}
          id={`${id}-height`}
          onChange={handleHeightChange}
          onFocus={() => setIsHeightTouched(true)}
          onInvalid={(message) =>
            setHeightError(message ? `Height: ${message}` : undefined)
          }
          value={height}
          placeholder={placeholder}
          min={0}
          hideControls
        />
      </label>
      {widthError && <p className="form-error theme danger">{widthError}</p>}
      {heightError && <p className="form-error theme danger">{heightError}</p>}
      {error && isWidthTouched && isHeightTouched && (
        <p className="form-error theme danger">
          {error === 'invalid'
            ? 'Please enter valid numbers.'
            : `Please set both width and height${
                !required ? ' or leave both blank' : ''
              }.`}
        </p>
      )}

      {presets && (
        <Radio
          id={`${id}-preset`}
          className={c('presets')}
          onChange={handlePresetChange}
          value={selectedPreset}
        >
          {presets.map(({ value, label }) => (
            <RadioOption value={value} key={value}>
              {label}
            </RadioOption>
          ))}
        </Radio>
      )}
    </div>
  );
}

const ForwardResolutionRef = React.forwardRef<
  HTMLLabelElement,
  ResolutionInputProps
>(ResolutionInput);

function StringResolutionInput(
  { onChange, value: initialValue, ...props }: StringResolutionInputProps,
  ref: React.ForwardedRef<HTMLLabelElement>
) {
  const [initialWidth, initialHeight] =
    getWidthAndHeightFromResolutionString(initialValue);

  function handleChange({ width, height }: ResolutionValueType) {
    if (!onChange) {
      return;
    }

    if (!width && !height) {
      onChange(null);
      return;
    }

    // only update when both values are valid
    if (!isNaN(Number(width)) && !isNaN(Number(height))) {
      let value: ResolutionString | null = `${Number(width)}x${Number(height)}`;

      width = Number(width) === 0 ? null : width;
      height = Number(height) === 0 ? null : height;

      // whenever height or width are empty reset the value to automatic
      // and show an error message that both values must be either set or empty
      if (!height || !width) {
        value = null;
      }

      onChange(value);
    }
  }

  return (
    <ForwardResolutionRef
      {...props}
      onChange={handleChange}
      width={initialWidth}
      height={initialHeight}
      ref={ref}
    />
  );
}

const ForwardStringResolutionRef = React.forwardRef<
  HTMLLabelElement,
  StringResolutionInputProps
>(StringResolutionInput);
const MemoizedResolutionInput = React.memo(ForwardResolutionRef);
const MemoizedStringResolutionInput = React.memo(ForwardStringResolutionRef);

export default MemoizedStringResolutionInput;
export {
  getWidthAndHeightFromResolutionString,
  MemoizedStringResolutionInput as StringResolutionInput,
  MemoizedResolutionInput as ResolutionInput,
};
