import React from 'react';
import classNames from 'classnames/bind';
import { useToggle } from '@mantine/hooks';

import Pipeline from 'types/pipeline';

import { useDeploymentForm } from 'deployments/components/Form';
import { useHasAccess } from 'hooks/useHasAccess';
import { useMarketplaceModels, useModels } from 'hooks/api/useModels';
import { usePipelineComponents } from 'pipelines/hooks/usePipelineComponents';
import { usePipelines } from 'pipelines/hooks/usePipelines';
import { useUsageLimits } from 'organizations/hooks/useUsageLimits';
import { type VideoSource } from 'hooks/api/useVideoSources';

import { Accordion } from 'components/Accordion/Accordion';
import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { DeploymentFormContentRef } from 'deployments/components/Form/Content';
import { DeploymentFormSkeleton } from 'deployments/components/Form/Skeleton';
import { Drawer } from 'components/Drawer/Drawer';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';

import { useRapidDeployState } from '../hooks/useRapidDeployState';
import { RapidDeployAnalyticsList } from './AnalyticsList';
import { RapidDeployConfigurationFormContent } from './ConfigurationFormContent';
import styles from '../RapidDeploy.module.scss';

const c = classNames.bind(styles);

export type RapidDeployConfigurationFormProps = {
  videoSource: VideoSource;
  defaultOpen?: boolean;
  onPipelineSelect: () => void;
};

function RapidDeployConfigurationForm(
  {
    videoSource,
    defaultOpen,
    onPipelineSelect,
  }: RapidDeployConfigurationFormProps,
  ref: React.ForwardedRef<DeploymentFormContentRef>
) {
  const { gateway } = useRapidDeployState();
  const { pipeline, setPipeline } = useDeploymentForm();
  const [hasAccess] = useHasAccess();
  const isOpenDefault = defaultOpen ?? false;
  const [isOpen, toggleIsOpen] = useToggle([isOpenDefault, !isOpenDefault]);

  const { data: pipelines } = usePipelines();
  const hasPipelines = pipelines ? pipelines.total_elements > 0 : false;

  const [isSelectingPipeline, toggleIsSelectingPipeline] = useToggle();

  // Preload
  const { isLoading: isLoadingUsageLimits, error: usageLimitsError } =
    useUsageLimits({
      enabled: hasAccess('deploy_edit'),
    });
  const [, { isLoading: isLoadingPipelineComponents }] =
    usePipelineComponents();
  const { isLoading: isLoadingModels } = useModels();
  const { isLoading: isLoadingMarketplaceModels } = useMarketplaceModels();

  const [isValid, setIsValid] = React.useState(false);

  const isLoading =
    isLoadingPipelineComponents ||
    isLoadingModels ||
    isLoadingMarketplaceModels ||
    isLoadingUsageLimits;

  if (!gateway) {
    return null;
  }

  if (usageLimitsError) {
    return (
      <FormMessage intent="danger" icon="warning">
        <strong>
          {usageLimitsError.message || 'An unknown error occurred.'}
        </strong>
        <br />
        Please try again later.
      </FormMessage>
    );
  }

  function handlePipelineSelect(pipeline: Pipeline) {
    setPipeline(pipeline);
    onPipelineSelect();
    toggleIsSelectingPipeline(false);
  }

  return (
    <Accordion
      className={c('deployments-form', { error: !isValid, isOpen })}
      label={
        <>
          <span>{videoSource.name}</span>
          {!isOpen && (
            <span
              className={c('deployments-header-pipeline')}
              aria-label="Pipeline"
            >
              {pipeline ? pipeline.name : 'No analytic pipeline selected'}
            </span>
          )}
          <StatusIndicator
            className={c('deployments-form-state')}
            status={pipeline && isValid ? 'valid' : 'invalid'}
            size="small"
          />
        </>
      }
      icon={videoSource.source_type}
      defaultOpen={defaultOpen}
      onOpenChange={toggleIsOpen}
    >
      <Box className={c('deployments-select-pipeline')} size="small">
        {pipeline ? (
          <Heading level="4" asChild>
            <strong>
              <Icon name="pipeline" />
              <span>{pipeline.name}</span>
            </strong>
          </Heading>
        ) : (
          <Text>No analytic pipeline selected</Text>
        )}
        <Button
          className={c('deployments-select-pipeline-btn')}
          variant="secondary"
          size="small"
          onClick={() => toggleIsSelectingPipeline()}
        >
          Select analytic pipeline
        </Button>
      </Box>

      {isLoading && pipeline && (
        <SkeletonList component={<DeploymentFormSkeleton />} />
      )}

      {!isLoading && pipeline && (
        <RapidDeployConfigurationFormContent
          videoSource={videoSource}
          pipeline={pipeline}
          onIsValidChange={setIsValid}
          ref={ref}
        />
      )}

      <Drawer
        title="Select analytic pipeline"
        open={isSelectingPipeline}
        onClose={toggleIsSelectingPipeline}
      >
        <RapidDeployAnalyticsList
          hasPipelines={hasPipelines}
          onPipelineSelect={handlePipelineSelect}
        />
      </Drawer>
    </Accordion>
  );
}

const ForwardInnerRef = React.forwardRef<
  DeploymentFormContentRef,
  RapidDeployConfigurationFormProps
>(RapidDeployConfigurationForm);

export { ForwardInnerRef as RapidDeployConfigurationForm };
