import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { isEmptyObject } from 'services/object';

import { useModelFormState } from '../context/useModelFormState';

export type UseModelFormStepOptions<TFieldValues extends FieldValues> = Pick<
  UseFormReturn<TFieldValues>,
  'reset' | 'getValues'
>;

export function useModelFormStep<TFieldValues extends FieldValues>({
  reset,
  getValues,
}: UseModelFormStepOptions<TFieldValues>) {
  const initialized = React.useRef(false);

  const { model, data, dispatch } = useModelFormState();

  // Initialize form default values from stored values or model
  React.useEffect(() => {
    if (initialized.current) {
      return;
    }

    if (data && !isEmptyObject(data)) {
      reset(data as unknown as TFieldValues);
      initialized.current = true;
    } else if (model) {
      reset(model as unknown as TFieldValues);
      initialized.current = true;
    }
  }, [data, model, reset]);

  // Save values when navigating between steps
  React.useEffect(() => {
    return () => {
      const data = getValues();
      dispatch({ type: 'update', data });
    };
  }, [getValues, dispatch]);
}
