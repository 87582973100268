import classnames from 'classnames/bind';
import { useParams } from 'react-router-dom';

import { Box } from 'components/Box/Box';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import { ApplicationParams } from 'application/views/App/AppContainer';

import styles from './UniversalBridgeInfo.module.scss';

const c = classnames.bind(styles);

export function UniversalBridgeInfo() {
  const { applicationID } = useParams<ApplicationParams>();

  if (!applicationID) {
    return null;
  }

  // Universal bridge username is URL safe base64 encoded application ID bytes.
  const ubUsername = Buffer.from(applicationID.replace(/-/g, ''), 'hex')
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  return (
    <Box className={c('wrap')}>
      <Heading level="3">
        <Icon name="camera" size="small" />
        <span>Universal bridge connection settings</span>
      </Heading>
      <Text type="paragraph">
        Use these settings to configure third-party devices and services to send
        clips or snapshots to Lumeo for processing using SMTP or FTP.
        <br />
        <ExternalLink href="https://docs.lumeo.com/docs/universal-bridge">
          Universal bridge docs
        </ExternalLink>
      </Text>

      <Heading level="4">SMTP settings</Heading>
      <dl className={c('list')}>
        <dt>Server</dt>
        <dd>
          <CopyToClipboard value="smtpgateway.lumeo.com" />
        </dd>

        <dt>Port</dt>
        <dd>
          <Text>
            <CopyToClipboard value="8025" /> (Plain / StartTLS)
          </Text>
          <Text>
            <CopyToClipboard value="8587" /> (SSL)
          </Text>
        </dd>

        <dt>User</dt>
        <dd>
          <CopyToClipboard value={ubUsername} />
        </dd>

        <dt>Password</dt>
        <dd>Universal bridge token</dd>

        <dt>From email</dt>
        <dd>
          A Camera-specific unique email address (can be non-functional). Ex.
          xyzcamera@email.com
        </dd>

        <dt>To email</dt>
        <dd>
          <CopyToClipboard value="default@pipeline.lumeo.com" />
        </dd>
      </dl>

      <Heading level="4">FTP settings</Heading>
      <dl className={c('list')}>
        <dt>Server</dt>
        <dd>
          <CopyToClipboard value="ftpgateway.lumeo.com" />
        </dd>

        <dt>Port</dt>
        <dd>
          <CopyToClipboard value="21" /> (FTP / FTPS)
        </dd>

        <dt>User</dt>
        <dd>
          <CopyToClipboard value={ubUsername} />
        </dd>

        <dt>Password</dt>
        <dd>Universal bridge token</dd>

        <dt>Path</dt>
        <dd>Camera-specific unique path, ex. /xyzcamera</dd>
      </dl>
    </Box>
  );
}
