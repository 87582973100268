import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import OrganizationUsageLimits from 'types/organization_usage_limits';

import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

import { useCurrentPlan } from './useCurrentPlan';
import { useUsageStatistics } from './useUsageStatistics';
import React from 'react';

export type UnitLimit = {
  planLimit?: number | null;
  userLimit?: number | null;
  usage?: number;
};

export function useUsageLimits(
  options?: UseQueryOptions<OrganizationUsageLimits | null, APIError>
) {
  const api = useAPI();
  const { data: organization, isInitialLoading: isLoadingOrganization } =
    useCurrentOrganization();
  const { data: plan, isInitialLoading: isLoadingPlan } = useCurrentPlan();
  const { data: usage, isInitialLoading: isLoadingUsage } =
    useUsageStatistics();

  const { data, isInitialLoading, error } =
    useAuthenticatedQuery<OrganizationUsageLimits | null>(
      ['usage_limits', 'org', organization?.id],
      () => {
        if (!organization) {
          return Promise.reject(new Error('No organization.'));
        }

        return api.organizations.readUsageLimits(organization.id);
      },
      options
    );

  return React.useMemo(
    () => ({
      error,
      cloudStorageBytes: {
        planLimit: plan?.cloud_storage_bytes_limit,
        userLimit: data?.cloud_storage_bytes_limit,
        usage: usage?.cloud_storage_bytes,
      },
      fileProcessingMinutes: {
        planLimit: plan?.file_processing_minutes_limit,
        userLimit: data?.file_processing_minutes_limit,
        usage: usage?.file_processing_minutes,
      },
      monthlyActiveLiveStreams: {
        planLimit: plan?.monthly_active_live_stream_limit,
        userLimit: data?.monthly_active_live_stream_limit,
        usage: usage?.realtime_stream_count,
      },
      isLoading:
        isInitialLoading ||
        isLoadingOrganization ||
        isLoadingPlan ||
        isLoadingUsage,
    }),
    [
      plan,
      usage,
      isInitialLoading,
      data,
      error,
      isLoadingOrganization,
      isLoadingPlan,
      isLoadingUsage,
    ]
  );
}
