import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import {
  RangeSlider,
  RangeSliderProps,
} from 'components/RangeSlider/RangeSlider';

export default class RangeSliderInput extends ReteInput<
  ReteInputProps & RangeSliderProps
> {
  constructor(
    editor: NodeEditor,
    key: string,
    {
      label,
      info,
      initial,
      group,
      keyref,
      requiresKeyrefValue,
      deploymentParam,
      ...props
    }: ReteInputParams & Partial<RangeSliderProps>
  ) {
    super(editor, key, {
      type: 'number',
      label,
      info,
      initial,
      group,
      keyref,
      requiresKeyrefValue,
      deploymentParam,
    });

    Object.assign(this.props, props);
  }

  get component() {
    return RangeSlider;
  }
}
