import { useParams } from 'react-router-dom';
import {
  QueryClient,
  QueryKey,
  Updater,
  useQueryClient,
} from '@tanstack/react-query';

import { ApplicationParams } from 'application/views/App/AppContainer';

/**
 * Extension of react-use `useQueryClient` to include the
 * applicationID in the queryKeys.
 *
 * @deprecated
 */
export function useAppQueryClient(): QueryClient {
  const queryClient = useQueryClient();
  const { applicationID } = useParams<ApplicationParams>();

  function getAppKeys(queryKey: QueryKey): QueryKey {
    if (Array.isArray(queryKey)) {
      return [...queryKey, applicationID];
    }
    return [queryKey, applicationID];
  }

  const clone: QueryClient = Object.assign(
    Object.create(Object.getPrototypeOf(queryClient)),
    queryClient
  );

  clone.getQueryData = function (queryKey: QueryKey, ...args: any[]) {
    return queryClient.getQueryData(getAppKeys(queryKey), ...args);
  };
  clone.setQueryData = function <T>(
    queryKey: QueryKey,
    updater: Updater<T | undefined, T>,
    ...args: any[]
  ) {
    return queryClient.setQueryData(getAppKeys(queryKey), updater, ...args);
  };
  clone.getQueryState = function <TData = unknown, TError = undefined>(
    queryKey: QueryKey,
    ...args: any[]
  ) {
    return queryClient.getQueryState<TData, TError>(
      getAppKeys(queryKey),
      ...args
    );
  };

  return clone;
}
