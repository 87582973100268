import React from 'react';
import classNames from 'classnames/bind';

import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import styles from './Dialog.module.scss';

const c = classNames.bind(styles);

export type MessageProps = React.PropsWithChildren<{
  intent: 'success';
}>;

export function Message({ intent, children }: MessageProps) {
  return (
    <div className={c('message', `theme ${intent}`)}>
      <div className={c('message-icon-wrap')}>
        <Icon className={'message-icon'} name="check-circle" size="large" />
      </div>
      <Text className={c('linked-text')}>{children}</Text>
    </div>
  );
}
