import classnames from 'classnames/bind';
import {
  Card,
  Title,
  Text,
  Button,
  Group,
  Skeleton,
  Badge,
} from '@mantine/core';

import styles from './ModelCard.module.scss';

const c = classnames.bind(styles);

export type ModelCardSkeletonProps = {
  isMarketplaceModel?: boolean;
};

export function ModelCardSkeleton({
  isMarketplaceModel,
}: ModelCardSkeletonProps) {
  return (
    <Card className={c('model-card')} shadow="sm" withBorder>
      {isMarketplaceModel && (
        <Card.Section>
          <Skeleton className={c('image')} radius="0" />
        </Card.Section>
      )}

      <Group
        gap="xs"
        mx="-2px"
        mb="sm"
        mt={isMarketplaceModel ? 'md' : undefined}
      >
        <Skeleton w="6rem" radius="lg">
          <Badge size="md">Architecture</Badge>
        </Skeleton>
        <Skeleton w="6rem" radius="lg">
          <Badge size="md">Capability</Badge>
        </Skeleton>
      </Group>

      <Skeleton>
        <Title order={3}>Name</Title>
      </Skeleton>

      <Skeleton mt="xs" mb="xs">
        <Text>Description</Text>
      </Skeleton>
      <Skeleton mb="md">
        <Text>Description</Text>
      </Skeleton>

      {!isMarketplaceModel && (
        <Skeleton radius="xl">
          <Button className={c('button')} mt="auto" size="sm">
            Edit
          </Button>
        </Skeleton>
      )}
    </Card>
  );
}
