import { Node as ReteNode } from 'rete';
import { useQuery } from '@tanstack/react-query';

import Node from 'types/node';

export function useReteNode(node: Node): ReteNode | undefined {
  const { data: reteNode, error } = useQuery(['rete-node', node.id], () => {
    if (!node.id) {
      return Promise.resolve(undefined);
    }

    return node.getReteInstance();
  });

  if (error) {
    throw error;
  }

  return reteNode;
}
