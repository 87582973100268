import React from 'react';

import { GraphQLClient } from 'graphql-request';

import { usePropelAuthentication } from 'dashboards/hooks/usePropelAuthentication';

const PROPEL_ENDPOINT = 'https://api.us-east-2.propeldata.com/graphql';
const client = new GraphQLClient(PROPEL_ENDPOINT);

export function usePropelClient() {
  const { data: token } = usePropelAuthentication();

  React.useEffect(() => {
    client.setHeader(
      'Authorization',
      token ? `Bearer ${token.access_token}` : ''
    );
  }, [token]);

  return client;
}
