import { useMutation } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { LumeoFile } from 'types/file';

import { useAPI } from 'hooks/api/useAPI';
import downloadFile from 'services/download_file';
import { USER_AGENT } from '../../services/constants';

export function useDownloadFile() {
  const api = useAPI();

  const {
    mutate: triggerDownload,
    isLoading,
    error,
  } = useMutation(
    (file: LumeoFile) => {
      if (!file.data_url) {
        return Promise.reject(
          new Error(`Missing download URL for file with ID ${file.id}.`)
        );
      }

      return fetch(file.data_url, {
        headers: {
          'User-Agent': USER_AGENT,
        },
      }).then((res) => {
        if (res.status === 200) {
          return res.blob();
        }

        return Promise.reject(new Error(res.statusText));
      });
    },
    {
      onSuccess(blob, { name, data_url }) {
        if (data_url) {
          downloadFile(name, data_url);
          return;
        }

        downloadFile(name, blob);
      },
    }
  );

  const { mutate } = useMutation<
    LumeoFile,
    APIError,
    LumeoFile['id'] | undefined
  >(
    (fileID) => {
      if (!fileID) {
        return Promise.reject(new Error('No file ID to downloaded provided.'));
      }

      return api.files.read(fileID);
    },
    {
      onSuccess(file) {
        triggerDownload(file);
      },
    }
  );

  return { download: mutate, isLoading, error };
}
