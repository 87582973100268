import React from 'react';
import classnames from 'classnames/bind';
import ReactMarkdown from 'react-markdown';
import {
  Controller,
  FieldError,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { Node as ReteNode } from 'rete';

import Node, { getPropertyValue } from 'types/node';
import ReteInput from 'pipelines/services/rete/controls/input';

import { customMarkdownAnchor } from 'services/markdown';

import { useKeyRefValue } from 'pipelines/hooks/useKeyRefValue';

import { Field } from 'components/Field/Field';
import { Icon } from 'components/Icon/Icon';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { useDeploymentForm } from '../context/deployment_form_context';
import { DeploymentEditableControl } from './EditableControl';
import { DeploymentReadonlyControl } from './ReadonlyControl';
import styles from './Control.module.scss';

const c = classnames.bind(styles);

export type DeploymentFormControlProps = {
  node: Node;
  property: string;
  control: ReteInput;
  defaultValue: unknown;
  reteNode: ReteNode;
  isDeployParam: boolean;
  isUnfilled: boolean;
  isUnusedHiddenKeyRef: boolean;
  isActiveKeyRef: boolean;
};

export function DeploymentFormControl({
  node,
  property,
  control,
  defaultValue,
  reteNode,
  isDeployParam,
  isUnfilled,
  isUnusedHiddenKeyRef,
  isActiveKeyRef,
}: DeploymentFormControlProps) {
  const { deployment, readonly } = useDeploymentForm();
  const { control: formControl } = useFormContext();
  const { errors } = useFormState({ control: formControl });
  const localKeyref = control.keyref
    ? `${node.id}.${control.keyref}`
    : undefined;
  const pipelineKeyrefValue = useKeyRefValue(control.keyref, node);
  const localKeyrefValue = useKeyRefValue(
    localKeyref,
    reteNode,
    pipelineKeyrefValue
  );
  const isDisabled = isActiveKeyRef && !isDeployParam;
  const id = `${deployment ? `${deployment.id}_` : ''}${node.id}.${property}`;

  if (!control) {
    return null;
  }

  const isEnabled =
    control.requiresKeyrefValue === undefined ||
    control.requiresKeyrefValue === localKeyrefValue;

  if (!isEnabled) {
    return null;
  }

  const pipelineDefinitionValue = getPropertyValue(node.data, property);
  const deploymentConfigValue = getPropertyValue(
    deployment?.configuration[node.id!],
    property
  );

  const hasPipelineValue =
    pipelineDefinitionValue !== undefined && pipelineDefinitionValue !== null;
  const isPipelineParameter =
    hasPipelineValue &&
    (deploymentConfigValue === undefined || deploymentConfigValue === null);

  const controlName = `${node.id}.${property}`;

  if (
    (!readonly &&
      !isDeployParam &&
      (!control.props.required || !isUnfilled) &&
      !isActiveKeyRef) ||
    isUnusedHiddenKeyRef
  ) {
    return null;
  }

  const label =
    control.label || control.info ? (
      <>
        <span>{control.label}</span>

        {readonly && isPipelineParameter && (
          <Tooltip side="right" content="Parameter defined in pipeline">
            <span className={c('control-pipeline-icon')}>
              <Icon name="pipeline" size="small" />
            </span>
          </Tooltip>
        )}
      </>
    ) : null;

  const isRequired = control.props.required && !hasPipelineValue;

  return (
    <div
      className={c(
        'form-row',
        control.key,
        control.inputType,
        control.props.type
      )}
    >
      <Field
        id={id}
        className={c('control')}
        label={label}
        labelWidth={readonly ? 180 : undefined}
        info={!readonly ? control.info : undefined}
        inline={readonly}
        required={isRequired}
        error={errors[node.id!]?.[property as keyof FieldError]}
      >
        {readonly ? (
          <DeploymentReadonlyControl
            control={control}
            node={node}
            reteNode={reteNode}
            property={property}
            value={defaultValue}
          />
        ) : (
          <Controller
            control={formControl}
            name={controlName}
            defaultValue={defaultValue ?? null}
            render={({ field }) => (
              <DeploymentEditableControl
                {...field}
                id={id}
                control={control}
                node={node}
                disabled={isDisabled}
                reteNode={reteNode}
                property={property}
                keyrefValue={localKeyrefValue}
              />
            )}
            rules={{
              required: isRequired ? 'This field is required.' : undefined,
            }}
          />
        )}

        {!readonly && control.description && (
          <ReactMarkdown
            className={c('markdown-description')}
            components={{ a: customMarkdownAnchor }}
          >
            {control.description}
          </ReactMarkdown>
        )}
      </Field>
    </div>
  );
}
