import { createModelSchema, identifier, primitive } from 'serializr';

type PricingPlanLimits = Pick<
  PricingPlan,
  | 'application_limit'
  | 'gateway_limit'
  | 'cloud_storage_bytes_limit'
  | 'included_cloud_storage_bytes'
  | 'file_processing_minutes_limit'
  | 'included_file_processing_minutes'
  | 'monthly_active_live_stream_limit'
  | 'included_monthly_active_live_streams'
  | 'running_deployment_limit'
>;

export default class PricingPlan {
  id: string;

  name: string;

  description: string;

  price_description: string;

  application_limit: number | null;

  gateway_limit: number | null;

  cloud_storage_bytes_limit: number | null;

  included_cloud_storage_bytes: number | null;

  monthly_active_live_stream_limit: number | null;

  included_monthly_active_live_streams: number | null;

  file_processing_minutes_limit: number | null;

  included_file_processing_minutes: number | null;

  running_deployment_limit: number | null;

  can_create_tags: boolean;

  can_search: boolean;

  constructor(
    id: string,
    name: string,
    description: string,
    priceDescription: string,
    canCreateTags: boolean = false,
    canSearch: boolean = false,
    {
      application_limit,
      gateway_limit,
      cloud_storage_bytes_limit,
      included_cloud_storage_bytes,
      monthly_active_live_stream_limit,
      included_monthly_active_live_streams,
      file_processing_minutes_limit,
      included_file_processing_minutes,
      running_deployment_limit,
    }: Partial<PricingPlanLimits> = {}
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.price_description = priceDescription;
    this.application_limit = application_limit || null;
    this.gateway_limit = gateway_limit || null;
    this.cloud_storage_bytes_limit = cloud_storage_bytes_limit || null;
    this.included_cloud_storage_bytes = included_cloud_storage_bytes || null;
    this.monthly_active_live_stream_limit =
      monthly_active_live_stream_limit || null;
    this.included_monthly_active_live_streams =
      included_monthly_active_live_streams || null;
    this.file_processing_minutes_limit = file_processing_minutes_limit || null;
    this.included_file_processing_minutes =
      included_file_processing_minutes || null;
    this.running_deployment_limit = running_deployment_limit || null;
    this.can_create_tags = canCreateTags;
    this.can_search = canSearch;
  }

  get can_create_dashboards() {
    return true;
  }
}

createModelSchema(PricingPlan, {
  id: identifier(),
  name: primitive(),
  description: primitive(),
  price_description: primitive(),
  application_limit: primitive(),
  gateway_limit: primitive(),
  cloud_storage_bytes_limit: primitive(),
  included_cloud_storage_bytes: primitive(),
  monthly_active_live_stream_limit: primitive(),
  included_monthly_active_live_streams: primitive(),
  file_processing_minutes_limit: primitive(),
  included_file_processing_minutes: primitive(),
  running_deployment_limit: primitive(),
  can_create_tags: primitive(),
  can_search: primitive(),
  can_create_dashboards: primitive(),
});
