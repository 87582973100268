import React from 'react';
import classNames from 'classnames/bind';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { AccessSection, useHasAccess } from 'hooks/useHasAccess';
import { useAuthentication } from 'hooks/api/useAuthentication';

import * as Dialog from 'components/Dialog';
import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';

import styles from './RequireAuth.module.scss';

const c = classNames.bind(styles);

export type RequireAuthProps = React.PropsWithChildren<{
  section?: AccessSection | AccessSection[];
  redirectTo?: string;
}>;

export function RequireAuth({
  children,
  section,
  redirectTo = '/login',
}: RequireAuthProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuthentication();
  const [hasAccess, isLoading] = useHasAccess();

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }

  if (isLoading) {
    return null;
  }

  if (
    Array.isArray(section)
      ? section.every((sec) => !hasAccess(sec))
      : !hasAccess(section)
  ) {
    const hasNavigatedInConsole = window.history.length > 50;

    function handleReturnClick() {
      if (hasNavigatedInConsole) {
        navigate(-1);
        return;
      }

      navigate('/');
    }

    function handleOpenChange(open: boolean) {
      if (!open) {
        handleReturnClick();
      }
    }

    return (
      <Dialog.Root onOpenChange={handleOpenChange} open>
        <div className={c('access-denied')}>
          <Icon
            className={c('access-denied-icon', 'theme danger')}
            name="logo-access-denied"
          />

          <Dialog.Title>
            <Heading level="2" asChild>
              <span>Access denied</span>
            </Heading>
          </Dialog.Title>
          <Dialog.Description>
            You do not have permission to access this part of the Lumeo Console.
            <br />
            Please refer to your organization administrator.
          </Dialog.Description>

          <ButtonGroup align="center">
            <Button onClick={handleReturnClick} variant="primary">
              Go back{!hasNavigatedInConsole && ' to overview'}
            </Button>
          </ButtonGroup>
        </div>
      </Dialog.Root>
    );
  }

  return <>{children}</>;
}
