import { Input, Output } from 'rete';

import Component from 'pipelines/services/rete/nodes/component';

import { Any, EncodedVideo, RawVideo } from '../sockets';
import ReteFloatInput from '../controls/float_input';
import ReteHiddenInput from '../controls/hidden_input';
import ReteLightswitch from '../controls/lightswitch';
import ReteMetadataListInput from '../controls/metadata_list_input';
import ReteMultiLineInput from '../controls/multi_line_input';
import ReteMultilineInput from '../controls/multisegment_line_input';
import ReteMultiSelect from '../controls/multi_select';
import ReteNodeDropdownInput from '../controls/node_dropdown';
import ReteNumberInput from '../controls/number_input';
import ReteOptionalRangeSlider from '../controls/range_slider_optional';
import ReteRadioSelect, {
  ReteRadioSelectOptionType,
} from '../controls/radio_select';
import ReteRangeSlider from '../controls/range_slider';
import ReteROIInput from '../controls/roi_input';
import ReteSingleLineInput from '../controls/text_input';
import ReteTriggerConditionInput from '../controls/trigger_condition_input';
import ReteUpstreamModelLabelInput from '../controls/upstream_model_labels';
import { DynamicNodeProperties, DynamicNodePropertyValueType } from './types';
import ReteJsonInput from '../controls/json_input';

type Controls = Record<DynamicNodePropertyValueType, any>;

const controls: Controls = {
  string: ReteSingleLineInput,
  list: ReteSingleLineInput,
  hidden: ReteHiddenInput,
  text: ReteMultiLineInput,
  number: ReteNumberInput,
  float: ReteFloatInput,
  slider: ReteRangeSlider,
  'slider-optional': ReteOptionalRangeSlider,
  enum: ReteRadioSelect,
  'enum-multi': ReteMultiSelect,
  bool: ReteLightswitch,
  line: ReteROIInput,
  multiline: ReteMultilineInput,
  'model-label': ReteUpstreamModelLabelInput,
  polygon: ReteROIInput,
  'trigger-condition': ReteTriggerConditionInput,
  node: ReteNodeDropdownInput,
  'metadata-list': ReteMetadataListInput,
  json: ReteJsonInput,
};

function getSocket(type: string) {
  switch (type) {
    case 'raw':
      return RawVideo;
    case 'encoded':
      return EncodedVideo;
    case 'any':
    // falls through
    default:
      return Any;
  }
}

export type DynamicComponentProperties = Record<
  string,
  {
    type: DynamicNodePropertyValueType;
    /** Helper property for ROI semicolon-separated values */
    stringType?: 'list';

    label: string;
    group?: string;
    info?: string;
    placeholder?: string;
    unit?: string;
    keyref?: string;

    default?: unknown;
    options?: ReteRadioSelectOptionType[];

    /** @default false */
    required?: boolean;
  }
>;

export function getDynamicComponentFromJson(cmp: any): Component {
  const cls: any = {};

  // eslint-disable-next-line
  cls[cmp.id] = class extends Component {
    static key = cmp.name;
    static icon = cmp.icon || 'settings';
    static exportType = cmp.id;
    static outputMetadata = cmp.output.metadata;
    static metadata = cmp.trigger_metadata;
    static category = cmp.category;
    static status = cmp.status;
    static input = cmp.input;
    static output = cmp.output;
    static description = cmp.description;
    static properties = cmp.properties;

    constructor() {
      super(cmp.name);
    }

    worker(_node: any, _inputs: any, _outputs: any) {}

    builder(node: any) {
      if (cmp.properties) {
        Object.entries(cmp.properties as DynamicNodeProperties).forEach(
          ([key, { type, default: initial, label, ...rest }]) => {
            const InputControl = controls[type];

            const numberProps = {
              step: 1,
            };

            try {
              node.addControl(
                new InputControl(this.editor, key, {
                  type,
                  label,
                  initial,
                  ...numberProps,
                  ...rest,
                })
              );
            } catch (error) {
              console.warn(
                `Invalid dynamic node definition in ${node.name}: Type \`${type}\` does not exist (check property \`${key}\`). The node will not be displayed.`
              );
            }
          }
        );
      }

      if (cmp.input?.type) {
        node.addInput(
          new Input(
            'input',
            'Input',
            getSocket(cmp.input.type),
            cmp.input.acceptMultiple
          )
        );
      }

      if (cmp.output?.type) {
        node.addOutput(
          new Output('output', 'Input', getSocket(cmp.output.type))
        );
      }

      return node;
    }
  };

  return cls[cmp.id];
}
