import React from 'react';
import { NavRoute, SettingsRoute } from 'application/types/routes';
import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import { ApplicationParams } from 'application/types/application_params';

import { useBilling } from 'hooks/api/useBilling';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useHasAccess } from 'hooks/useHasAccess';

import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { Icon } from 'components/Icon/Icon';
import { Separator } from 'components/Separator/Separator';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { SidebarLink } from 'components/Sidebar/SidebarLink';
import { Text } from 'components/Text/Text';

import styles from './Settings.module.scss';

const c = classnames.bind(styles);

export function SettingsSidebar() {
  const { applicationID } = useParams<ApplicationParams>();
  const { data: organization } = useCurrentOrganization();

  const { mutate: manageBilling } = useBilling();

  const [hasAccess] = useHasAccess();

  const shouldDisplayAppSettings =
    hasAccess('access_tokens') ||
    hasAccess('universal_bridge') ||
    hasAccess('metrics') ||
    hasAccess('application_settings');

  function handleManageBillingClick() {
    manageBilling();
  }

  return (
    <Sidebar>
      {shouldDisplayAppSettings && (
        <li>
          <Text>
            <strong>Workspace</strong>
          </Text>

          <ul>
            {hasAccess('application_settings') && (
              <SidebarLink
                to={`/${NavRoute.SETTINGS}/${SettingsRoute.WORKSPACE}`}
                icon="settings"
              >
                Manage workspace
              </SidebarLink>
            )}

            {hasAccess('access_tokens') && (
              <SidebarLink
                to={`/${NavRoute.SETTINGS}/${SettingsRoute.ACCESS_TOKENS}`}
                icon="account-key"
              >
                Access tokens
              </SidebarLink>
            )}

            {hasAccess('universal_bridge') && (
              <SidebarLink
                to={`/${NavRoute.SETTINGS}/${SettingsRoute.UNIVERSAL_BRIDGE}`}
                icon="camera"
              >
                Universal bridge
              </SidebarLink>
            )}

            {hasAccess('metrics') && (
              <SidebarLink
                to={`/${NavRoute.SETTINGS}/${SettingsRoute.METRICS}`}
                icon="metrics"
              >
                Metrics
              </SidebarLink>
            )}
          </ul>
        </li>
      )}
      {organization && (
        <li>
          {shouldDisplayAppSettings && <Separator />}

          <Text>
            <strong>Organization</strong>
          </Text>

          <ul>
            <SidebarLink
              to={`/${NavRoute.SETTINGS}/${SettingsRoute.ORGANIZATION}/${organization.id}`}
              icon="organization"
              end
            >
              Utilization
            </SidebarLink>

            <SidebarLink
              to={`/${NavRoute.SETTINGS}/${SettingsRoute.ORGANIZATION}/${organization.id}/${SettingsRoute.PLAN}`}
              icon="plan"
              end
            >
              Plan
            </SidebarLink>

            {hasAccess('billing') && (
              <a onClick={handleManageBillingClick} className={c('link')}>
                <Icon name="billing" />
                <span>Billing</span>
                <Icon className={c('external')} name="external" size="small" />
              </a>
            )}

            {hasAccess('organization_settings') && (
              <SidebarLink
                to={`/${NavRoute.SETTINGS}/${SettingsRoute.ORGANIZATION}/${organization.id}/${SettingsRoute.MEMBERS}`}
                icon="account"
                end
              >
                Members
              </SidebarLink>
            )}
          </ul>
        </li>
      )}

      <li>
        <Separator />

        <Text>
          <strong>Account</strong>
        </Text>

        <ul>
          <SidebarLink
            to={`/${NavRoute.SETTINGS}/${SettingsRoute.ACCOUNT}`}
            icon="account"
          >
            Manage account
          </SidebarLink>
          <SidebarLink
            to={`/${NavRoute.SETTINGS}/${SettingsRoute.PASSWORD}`}
            icon="key"
          >
            Password
          </SidebarLink>
        </ul>
      </li>

      <li>
        <Separator />

        {applicationID && (
          <Text className={c('workspace-id')}>
            <strong>Workspace ID</strong>
            <br />
            <Text size="small" inline>
              <CopyToClipboard value={applicationID} />
            </Text>
          </Text>
        )}

        {organization && (
          <Text>
            <strong>Organization</strong>
            <br />
            <Text size="small" inline>
              {organization.name}
            </Text>
          </Text>
        )}
      </li>
    </Sidebar>
  );
}
