import React from 'react';
import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import {
  RangeSlider,
  RangeSliderProps,
} from 'components/RangeSlider/RangeSlider';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { Text } from 'components/Text/Text';

export type ReteOptionalRangeSliderParams = {
  label: string;
  info?: string;
  initial?: ReteClassThresholdInputValue;
  defaultInitial?: number;
  group?: string;
} & Partial<
  Pick<
    RangeSliderProps,
    'min' | 'minLabel' | 'max' | 'maxLabel' | 'step' | 'input' | 'scale'
  >
>;

type Props = ReteInputProps &
  ReteOptionalRangeSliderParams & {
    id?: string;
  };

type ReteClassThresholdInputValue = number | null;

export default class ReteOptionalRangeSlider extends ReteInput<Props> {
  constructor(
    editor: NodeEditor,
    key: string,
    {
      info,
      min,
      max,
      step,
      scale,
      input,
      defaultInitial,
      ...params
    }: ReteInputParams & ReteOptionalRangeSliderParams
  ) {
    super(editor, key, {
      ...params,
      type: 'number',
    });

    this.props.info = info;
    this.props.min = min;
    this.props.max = max;
    this.props.step = step;
    this.props.scale = scale;
    this.props.input = input;
    this.props.defaultInitial = defaultInitial;
  }

  get component() {
    return _ReteOptionalRangeSlider;
  }
}

function _ReteOptionalRangeSlider({
  id,
  info,
  value,
  initial,
  defaultInitial,
  min = 0,
  max = 1,
  step = 0.01,
  scale,
  onChange,
}: Props) {
  const initialValue = value || initial;
  const [localValue, setLocalValue] = React.useState(initialValue);

  const initialEnabled = React.useRef(typeof initialValue === 'number');
  const [isEnabled, setIsEnabled] = React.useState(initialEnabled.current);

  function handleChange(value: number | string) {
    onChange?.(value);

    if (typeof value === 'number') {
      setLocalValue(value);
    }
  }

  React.useEffect(() => {
    if (typeof initialValue === 'number') {
      setLocalValue(initialValue);
    }
  }, [initialValue]);

  React.useEffect(() => {
    if (!isEnabled) {
      if (onChange) {
        onChange(null);
      }
      return;
    }

    handleChange(localValue || defaultInitial);
    // eslint-disable-next-line
  }, [isEnabled, localValue]);

  return (
    <div className="columns spaced valign-start">
      <LightSwitch
        id={`${id}-toggle`}
        onValueChange={setIsEnabled}
        checked={isEnabled}
      />
      <div className="column-stretch">
        <Text asChild>
          <label htmlFor={`${id}-toggle`}>{info}</label>
        </Text>
        <RangeSlider
          id={id}
          min={min}
          minLabel={min.toFixed(2)}
          max={max}
          maxLabel={max.toFixed(2)}
          step={step}
          scale={scale || 0.05}
          onChange={handleChange}
          defaultValue={localValue || 0}
          input
          disabled={!isEnabled}
        />
      </div>
    </div>
  );
}
