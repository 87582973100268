import React from 'react';
import { NodeModel, DropOptions } from '@minoru/react-dnd-treeview';
import { useMap } from 'react-use';

import { Tag } from 'types/tag';

import { useTags } from 'tags/hooks/useTags';
import { useTagsTreeStructure } from 'tags/hooks/useTagsTreeStructure';
import { useUpdateTag } from 'tags/hooks/useUpdateTag';

export function useTagsTreeItems() {
  const { data } = useTags({ params: { only_roots: true } });
  const { mutate: updateTag } = useUpdateTag();

  const [children, { set }] = useMap<Record<Tag['id'], Tag[]>>();
  const [allTags, setAllTags] = React.useState<Tag[]>([]);
  const tree = useTagsTreeStructure(allTags, true);

  const [searchResults, setSearchResults] = React.useState<Tag[]>();
  const searchTree = useTagsTreeStructure(searchResults);

  React.useEffect(() => {
    if (!data) {
      return;
    }

    const combined = [...data.data, ...Object.values(children).flat()];
    const uniqueTags = [
      ...new Map(combined.map((tag) => [tag.id, tag])).values(),
    ];

    setAllTags(uniqueTags);
  }, [data, children]);

  const onDrop = React.useCallback(
    (_tree: NodeModel<Tag>[], { dragSource, dropTarget }: DropOptions<Tag>) => {
      if (!dragSource?.data) {
        return;
      }

      const tag = structuredClone(dragSource.data);

      if (!dropTarget || !dropTarget.data) {
        // In the tree structure, parent=0 puts the tag at the top level.
        // We need to translate this back to `undefined` for API
        tag.parent = null;
      } else {
        tag.parent = dropTarget.data.id;
      }

      updateTag(tag);
    },
    [updateTag]
  );

  return { tree, searchTree, setSearchResults, onLoad: set, onDrop };
}
