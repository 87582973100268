import React from 'react';

import { WidgetType } from 'types/dashboard_widget';

import Dashboard from 'types/dashboard';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Drawer, DrawerProps } from 'components/Drawer/Drawer';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';

import { AddWidgetModalTypeSelect } from './TypeSelect';
import { WidgetForm } from '../Form';
import { WidgetModalHeader } from '../Header';

export type AddWidgetModalProps = Pick<DrawerProps, 'open' | 'onClose'> & {
  dashboard: Dashboard;
};

export function AddWidgetModal({
  dashboard,
  open,
  onClose,
}: AddWidgetModalProps) {
  const [type, setType] = React.useState<WidgetType>();
  const [isDirty, setIsDirty] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);

  function handleClose() {
    onClose?.();
    setType(undefined);
    setIsDirty(false);
    setIsUpdating(false);
  }

  return (
    <Drawer
      open={open}
      closeOnClickOutside={false}
      closeOnEscape={false}
      header={
        type && <WidgetModalHeader title="Add dashboard widget" type={type} />
      }
      footer={
        type && (
          <ButtonGroup align="end">
            {isDirty && (
              <InlineNotification>Unsaved changes</InlineNotification>
            )}

            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              form={`${type}-widget-form`}
              loading={isUpdating}
            >
              Save
            </Button>
          </ButtonGroup>
        )
      }
      onClose={handleClose}
    >
      {!type && <AddWidgetModalTypeSelect onSelect={setType} />}

      {type && (
        <WidgetForm
          type={type}
          dashboard={dashboard}
          onUpdate={setIsUpdating}
          onIsDirtyChange={setIsDirty}
        />
      )}
    </Drawer>
  );
}
