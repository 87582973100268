import React from 'react';
import classNames from 'classnames/bind';

import * as PageLayout from 'components/PageLayout';

import { Button, type ButtonProps } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';

import styles from '../RapidDeploy.module.scss';
import { LinkTypeButtonProps } from 'components/Button/LinkTypeButton';
import { ButtonTypeButtonProps } from 'components/Button/ButtonTypeButton';
import { useLocation } from 'react-router-dom';

const c = classNames.bind(styles);

export type RapidDeployLayoutProps = React.PropsWithChildren<{
  slot?: React.ReactNode;
  backTo?: ButtonProps['to'];
  continueText?: string;
  canContinue?: boolean;
  isContinueLoading?: boolean;
  onContinue?: string | (() => void);
}>;

export function RapidDeployLayout({
  slot,
  children,
  continueText = 'Continue',
  canContinue = true,
  isContinueLoading,
  onContinue,
  backTo,
}: RapidDeployLayoutProps) {
  const { search } = useLocation();

  const buttonProps: Partial<ButtonProps> = {
    variant: 'primary',
    disabled: !canContinue,
    loading: isContinueLoading,
    children: continueText,
  };

  return (
    <>
      <PageLayout.Content>{children}</PageLayout.Content>
      {(onContinue || slot) && (
        <PageLayout.Footer>
          <div className={c('footer')}>
            <div>{slot}</div>

            <ButtonGroup align="end">
              {Boolean(backTo) && <Button to={backTo}>Back</Button>}

              {typeof onContinue === 'string' && (
                <Button
                  {...(buttonProps as LinkTypeButtonProps)}
                  to={{ pathname: onContinue, search }}
                />
              )}

              {typeof onContinue === 'function' && (
                <Button
                  {...(buttonProps as ButtonTypeButtonProps)}
                  onClick={onContinue}
                />
              )}
            </ButtonGroup>
          </div>
        </PageLayout.Footer>
      )}
    </>
  );
}
