import React from 'react';

export function useIsMounted() {
  const isMountedRef = React.useRef(false);

  React.useEffect(() => {
    if (isMountedRef.current) {
      return;
    }

    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return React.useCallback(() => isMountedRef.current, []);
}
