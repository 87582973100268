import React from 'react';
import classNames from 'classnames/bind';
import { useIntercom } from 'react-use-intercom';

import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

import { Button } from 'components/Button/Button';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import styles from './SuspensionBanner.module.scss';

const c = classNames.bind(styles);

export function SuspensionBanner() {
  const { data: organization } = useCurrentOrganization();
  const { show } = useIntercom();

  React.useEffect(() => {
    if (!organization || !organization.is_suspended) {
      return;
    }

    document.documentElement.style.setProperty('--offset-y', '4.5rem');

    return () => {
      document.documentElement.style.removeProperty('--offset-y');
    };
  }, [organization]);

  if (!organization || !organization.is_suspended) {
    return null;
  }

  return (
    <section id="suspension-banner" className={c('banner', 'theme warning')}>
      <Icon name="warning" />

      <div className="banner-text">
        <Heading level="4">
          Your organization {organization.name} has been temporarily suspended.
        </Heading>
        <Text>
          You will not be able to create new deployments or upload new files.
          Please contact support to resolve your account status.
        </Text>
      </div>

      <Button size="small" variant="secondary" onClick={show}>
        Contact support
      </Button>
    </section>
  );
}
