export type LumeodMetricType =
  | 'Counter'
  | 'Histogram'
  | 'Gauge'
  | 'UpDownCounter';

export type LumeodMetricAttribute = {
  name: string;
  info?: string;
};

export default class LumeodMetric {
  /** Unique name and identifier */
  name: string;

  description: string;

  unit: string;

  attributes: LumeodMetricAttribute[];

  type: LumeodMetricType;

  constructor(
    name: string,
    description: string,
    unit: string,
    attributes: LumeodMetricAttribute[],
    type: LumeodMetricType
  ) {
    this.name = name;
    this.description = description;
    this.unit = unit;
    this.attributes = attributes;
    this.type = type;
  }
}
