import React from 'react';
import classNames from 'classnames/bind';
import { Link, useParams } from 'react-router-dom';

import { ApplicationParams } from 'application/types/application_params';

import {
  isCloseToOrganizationUsageLimit,
  isExceedingOrganizationUsageLimit,
} from 'organizations/services/usage_limits';
import { useAppRedirect } from 'hooks/useAppRedirect';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useUsageLimits } from 'organizations/hooks/useUsageLimits';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { DragDropFileUploadArea } from 'components/DragDropFileUploadArea/DragDropFileUploadArea';
import { FileDisplay } from 'components/FileDisplay/FileDisplay';
import { FormMessage } from 'components/FormMessage/FormMessage';

import mediaIcon from 'files/images/media-file.svg';
import {
  FileUploadState,
  useFilesUploadState,
} from 'files/hooks/useFilesUploadState';

import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import styles from './CreateFilesView.module.scss';

const c = classNames.bind(styles);

function isSuccessful(uploadState: Map<File, FileUploadState>) {
  if (!uploadState.size) {
    return false;
  }

  return Array.from(uploadState.values()).every((state) => state.isSuccess);
}

function isFailed(uploadState: Map<File, FileUploadState>) {
  if (!uploadState.size) {
    return false;
  }

  return Array.from(uploadState.values()).some((state) => state.isError);
}

/**
 * Form to upload files to the API & create streams from them.
 */
export function UploadFiles() {
  const { applicationID } = useParams<ApplicationParams>();

  const redirect = useAppRedirect();
  const { data: organization } = useCurrentOrganization();

  // Files to upload
  const [nextFiles, setNextFiles] = React.useState<File[]>([]);
  // Files that are uploading or have been uploaded
  const [uploadingFiles, { uploadFileStream, isLoading }] =
    useFilesUploadState();

  const { cloudStorageBytes } = useUsageLimits();

  const isCloseToStorageLimits =
    isCloseToOrganizationUsageLimit(cloudStorageBytes);
  const willExceedStorageLimits =
    isExceedingOrganizationUsageLimit(cloudStorageBytes);

  const isSuccess = nextFiles.length === 0 && isSuccessful(uploadingFiles);
  const isError = nextFiles.length === 0 && isFailed(uploadingFiles);

  React.useEffect(() => {
    if (!isLoading) {
      return;
    }

    function handleAppLeave() {
      return 'Are you sure you want to leave? File upload is not complete.';
    }

    window.addEventListener('beforeunload', handleAppLeave);

    return () => {
      window.removeEventListener('beforeunload', handleAppLeave);
    };
  }, [isLoading]);

  function handleFileChange(files: File[]) {
    setNextFiles((queue) => [...queue, ...files]);
  }

  function handleDeleteClick(index: number) {
    setNextFiles((queue) => queue.filter((_, i) => i !== index));
  }

  async function handleUploadClick() {
    if (nextFiles.length === 0) {
      return;
    }

    nextFiles.forEach((file) => uploadFileStream(file));
    setNextFiles([]);
  }

  React.useEffect(() => {
    if (!isSuccess) {
      return;
    }

    window.setTimeout(() => redirect('/deploy/files'), 2000);
  }, [isSuccess, redirect]);

  return (
    <div className={c('upload')}>
      <DragDropFileUploadArea
        id="upload-files"
        className={c('upload-area')}
        onChange={handleFileChange}
        imageSrc={mediaIcon}
        info="Video files"
        accept="video/*"
      />

      {organization && willExceedStorageLimits && (
        <FormMessage intent="danger" icon="warning">
          <strong>
            Your organization has reached the cloud storage limits of your
            current plan.
          </strong>
          <br />
          Please{' '}
          <Link
            className="link"
            to={`/applications/${applicationID}/settings/organization/${organization.id}/plans`}
          >
            upgrade your plan
          </Link>{' '}
          or delete files from the cloud before uploading more files to the
          cloud.
        </FormMessage>
      )}

      {organization && isCloseToStorageLimits && !willExceedStorageLimits && (
        <FormMessage intent="warning" icon="warning">
          <strong>
            Your organization has reached the cloud storage limits of your
            current plan.
          </strong>
          <br />
          File uploads may fail. Please{' '}
          <Link
            className="link"
            to={`/applications/${applicationID}/settings/organization/${organization.id}/plans`}
          >
            upgrade your plan
          </Link>{' '}
          or delete files from the cloud to prevent this.
        </FormMessage>
      )}

      <div className={c('upload-files')} aria-label="Uploaded files">
        {Array.from(uploadingFiles.entries()).map(([file, state], index) => (
          <FileDisplay {...state} file={file} key={`${file.name}_${index}`} />
        ))}
        {nextFiles.map((file, index) => (
          <FileDisplay
            file={file}
            onDelete={() => handleDeleteClick(index)}
            key={`${file.name}_${index}`}
          />
        ))}
      </div>

      <ButtonGroup>
        <Button
          className={c('upload-button')}
          variant="primary"
          onClick={handleUploadClick}
          disabled={organization?.is_suspended || willExceedStorageLimits}
        >
          Upload files
        </Button>

        {isSuccess && (
          <InlineNotification intent="success" icon="check-circle">
            Upload complete! Redirecting...
          </InlineNotification>
        )}

        {isError && (
          <InlineNotification intent="danger" icon="warning">
            Some files could not be uploaded.
          </InlineNotification>
        )}
      </ButtonGroup>
    </div>
  );
}
