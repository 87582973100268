import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import {
  LightSwitch,
  LightSwitchProps,
} from 'components/LightSwitch/LightSwitch';

type ReteLightSwitchProps = Omit<
  LightSwitchProps,
  'onChange' | 'onValueChange'
> & {
  onChange: LightSwitchProps['onValueChange'];
};

export default class ReteLightSwitch extends ReteInput<
  ReteInputProps & ReteLightSwitchProps
> {
  constructor(editor: NodeEditor, key: string, params: ReteInputParams) {
    super(editor, key, {
      ...params,
      type: 'boolean',
      inputType: 'checkbox',
    });
  }

  get component() {
    return ReteLightSwitchComponent;
  }
}

function ReteLightSwitchComponent({
  id,
  value,
  disabled,
  onChange,
}: ReteLightSwitchProps) {
  return (
    <LightSwitch
      id={id}
      checked={Boolean(value)}
      onValueChange={onChange}
      disabled={disabled}
    />
  );
}
