import classNames from 'classnames/bind';
import { useUncontrolled } from '@mantine/hooks';

import Category from 'types/category';

import { useCategories } from 'hooks/api/useCategories';

import {
  ToggleSelect,
  type ToggleSelectOption,
} from 'components/ToggleSelect/ToggleSelect';

import styles from './CategorySelect.module.scss';

const c = classNames.bind(styles);

export type HighlightedCategoryFilterProps = {
  id: string;
  value?: Category[];
  onChange: (categories: Category[]) => void;
};

export function HighlightedCategoryFilter({
  id,
  value,
  onChange,
}: HighlightedCategoryFilterProps) {
  const { data, isLoading } = useCategories(['highlighted-categories'], {
    only_highlighted: true,
  });

  const [categories, setCategories] = useUncontrolled({
    value: value?.map(getOptionFromCategory),
    finalValue: [],
    onChange(options) {
      onChange?.(options.map(({ data }) => data));
    },
  });

  if (isLoading) {
    return (
      <div className={c('loader')}>
        <span className="skeleton-bar" />
        <span className="skeleton-bar" />
        <span className="skeleton-bar" />
      </div>
    );
  }

  if (!data?.total_elements) {
    return null;
  }

  const options = data.data.map(getOptionFromCategory);

  return (
    <ToggleSelect
      id={id}
      options={options}
      value={categories}
      onChange={setCategories}
    />
  );
}

function getOptionFromCategory(
  category: Category
): ToggleSelectOption<Category> {
  const { icon_name, label, description } = category;
  return {
    icon: icon_name,
    label,
    description,
    value: label,
    data: category,
  };
}
