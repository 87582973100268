import React from 'react';
import classnames from 'classnames/bind';

import * as RadixTooltip from '@radix-ui/react-tooltip';

import { OptionalTooltip } from './OptionalTooltip';
import styles from './Tooltip.module.scss';

const c = classnames.bind(styles);

export type TooltipProps = Pick<
  RadixTooltip.TooltipContentProps,
  'sideOffset' | 'alignOffset'
> &
  Pick<
    RadixTooltip.TooltipProps,
    'delayDuration' | 'defaultOpen' | 'open' | 'onOpenChange'
  > &
  React.PropsWithChildren<{
    content: JSX.Element | JSX.Element[] | React.ReactText;
    /**
     * @default center
     */
    align?: RadixTooltip.TooltipContentProps['align'];
    /**
     * @default bottom
     */
    side?: RadixTooltip.TooltipContentProps['side'];
    className?: string;
    intent?: 'danger';
  }>;

/**
 * Tooltip component to wrap around an element to trigger a tooltip on mouseover.
 * Opens to the bottom and aligns to the center of the trigger (child) element.
 */
export function Tooltip({
  content,
  open,
  onOpenChange,
  delayDuration = 0,
  defaultOpen,
  className,
  children,
  align,
  sideOffset = 8,
  intent,
  ...props
}: TooltipProps) {
  return (
    <RadixTooltip.Root
      open={open}
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
      delayDuration={delayDuration}
    >
      <RadixTooltip.Trigger className={className} asChild>
        {children}
      </RadixTooltip.Trigger>

      <RadixTooltip.Portal>
        <RadixTooltip.Content
          {...props}
          align={align}
          sideOffset={sideOffset}
          className={c('tooltip', 'theme', intent || 'dark')}
          data-persist={open}
        >
          {content}
          <RadixTooltip.Arrow className={c('arrow')} offset={8} />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
}

export { OptionalTooltip };
