import React from 'react';
import classnames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';
import { Node as ReteNode, NodeEditor as ReteNodeEditor } from 'rete';

import ReteInput from 'pipelines/services/rete/controls/input';
import { CurrentNodeProvider } from 'deployments/hooks/useCurrentNode';
import { useGroupedNodeControls } from 'deployments/hooks/useGroupedNodeControls';

import { useDefaultValues } from './hooks/useDefaultValues';
import { NodePropertiesEditorControl } from './components/Control';

import styles from './NodePropertiesEditor.module.scss';

const c = classnames.bind(styles);

export type NodePropertiesEditorInnerProps = {
  reteNode: ReteNode;
  editor: ReteNodeEditor | undefined;
};

function NodePropertiesEditorInner({
  editor,
  reteNode,
}: NodePropertiesEditorInnerProps) {
  const controls = React.useMemo(
    () => (reteNode ? [...reteNode.controls.values()] : []),
    [reteNode]
  );

  const isInitialized = React.useRef(false);
  const defaultValues = useDefaultValues(reteNode, controls);
  const { reset } = useFormContext();

  React.useEffect(() => {
    if (isInitialized.current) {
      return;
    }

    reset(defaultValues);
    isInitialized.current = true;
  }, [defaultValues, reset]);

  const groupedControls = useGroupedNodeControls(controls);

  if (!editor) {
    return null;
  }

  return (
    <CurrentNodeProvider reteNode={reteNode} nodeID={reteNode.id as any}>
      {Object.keys(groupedControls).map((group, groupIdx) => {
        const controls = groupedControls[group];
        const groupID = `${reteNode.id}_${group}`;

        if (controls.length <= 0) {
          return null;
        }

        return (
          <fieldset
            className={c('group')}
            aria-labelledby={groupID}
            key={groupID}
          >
            {group && (
              <strong
                id={groupID}
                className={c('group-label', 'label-headline')}
              >
                {group}
              </strong>
            )}

            {controls.map((control, index) => (
              <NodePropertiesEditorControl
                key={control.key}
                nodeID={String(reteNode.id)}
                control={control as ReteInput}
                autoFocus={groupIdx === 0 && index === 0}
              />
            ))}
          </fieldset>
        );
      })}
    </CurrentNodeProvider>
  );
}

const Memoized = React.memo(NodePropertiesEditorInner);

export { Memoized as NodePropertiesEditorInner };
