import React from 'react';
import classnames from 'classnames/bind';

import { Icon } from 'components/Icon/Icon';

import styles from './ExternalLink.module.scss';

const c = classnames.bind(styles);

export function ExternalLink({
  children,
  className,
  ...props
}: React.PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>>) {
  return (
    <a
      {...props}
      className={c('external-link', className)}
      target="_blank"
      rel="noreferrer"
    >
      <strong>{children}</strong>
      <Icon name="external" size="small" />
    </a>
  );
}
