import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

import Organization from 'types/organization';

import { useAPI } from 'hooks/api/useAPI';
import { useAppQueryClient } from 'hooks/useAppQueryClient';

import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import APIError from 'types/api_error';

export function useOrganizations() {
  const api = useAPI();
  return useAuthenticatedQuery<Organization[]>(
    ['organizations'],
    api.organizations.list,
    {
      staleTime: Infinity,
      select: (orgs) =>
        orgs.sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
        ),
    }
  );
}

export function useUpdateOrganization({
  onSuccess,
  ...options
}: UseMutationOptions<
  Organization,
  APIError,
  Organization
> = {}): UseMutationResult<Organization, APIError, Organization> {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useMutation(api.organizations.update, {
    ...options,
    onSuccess: (...args) => {
      if (onSuccess) {
        onSuccess(...args);
      }

      queryClient.invalidateQueries(['organizations']);
    },
  });
}
