import { LUMEO_EVENT_SEVERITIES } from 'types/event';

import { formatPretty } from 'services/string';

import { Select, SelectProps } from 'components/Select/Select';

const OPTIONS = LUMEO_EVENT_SEVERITIES.map((value) => ({
  value,
  label: formatPretty(value),
}));

/** {@link LumeoEventSeverity } input */
export function SeveritySelect(props: SelectProps<(typeof OPTIONS)[number]>) {
  return <Select {...props} options={OPTIONS} placeholder="Severity" />;
}
