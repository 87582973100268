import { GraphQLClientRequestHeaders } from 'graphql-request/build/esm/types';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Variables } from 'graphql-request';

import { sanitizeQueryKey } from 'services/query_key';

import { usePropelClient } from 'graphql/client';
import { usePropelAuthentication } from 'dashboards/hooks/usePropelAuthentication';

/**
 * Hook to make Propel graphql requests with {@link usePropelClient} & `useQuery`.
 * Only use this if you need requests to be cached, otherwise make requests with {@link usePropelClient} directly.
 */
export function usePropelQuery<
  TData = unknown,
  TVariables extends Variables = Variables,
>(
  query: string,
  variables?: TVariables,
  queryKey: QueryKey = [],
  { enabled, ...options }: UseQueryOptions<TData> = {},
  headers: GraphQLClientRequestHeaders = {}
) {
  const { isSuccess: isAuthenticated } = usePropelAuthentication();
  const client = usePropelClient();

  return useQuery<TData>(
    sanitizeQueryKey([...queryKey, 'propel', variables]),
    () => client.request<TData>(query, variables, headers),
    {
      ...options,
      enabled:
        enabled !== undefined ? isAuthenticated && enabled : isAuthenticated,
    }
  );
}
