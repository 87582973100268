import React from 'react';
import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';

import Camera from 'types/camera';

import { useSpringState } from 'hooks/useSpringState';
import { useUpdateCamera } from 'cameras/hooks/useUpdateCamera';

import * as Dialog from 'components/Dialog';
import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import { Text } from 'components/Text/Text';

import styles from './AuthenticateCameraDialog.module.scss';

const c = classNames.bind(styles);

export type AuthenticateCameraFieldValues = {
  username: string;
  password: string;
};

export type AuthenticateCameraDialogProps = Pick<
  Dialog.RootProps,
  'open' | 'onOpenChange'
> & {
  camera?: Camera;
};

export function AuthenticateCameraDialog({
  camera,
  open,
  onOpenChange,
}: AuthenticateCameraDialogProps) {
  const { register, handleSubmit, reset } =
    useForm<AuthenticateCameraFieldValues>({
      mode: 'onBlur',
      defaultValues: {
        username: camera?.username || '',
        password: camera?.password || '',
      },
    });

  const {
    isLoading,
    error,
    mutate: updateCamera,
  } = useUpdateCamera({
    onSuccess() {
      setIsSuccess(true);
      reset();
    },
  });

  const [isSuccess, setIsSuccess] = useSpringState(false, 2000);

  // Close dialog when `isSuccess` resets to false
  React.useEffect(() => {
    if (!isSuccess || !onOpenChange) {
      return;
    }

    return () => {
      onOpenChange(false);
    };
  }, [isSuccess, onOpenChange]);

  if (!camera) {
    return null;
  }

  function handleUpdateCredentials({
    username,
    password,
  }: AuthenticateCameraFieldValues) {
    if (!camera) {
      return;
    }

    const updatedCamera = camera.copy();

    updatedCamera.username = username;
    updatedCamera.password = password;

    updateCamera(updatedCamera);
  }

  return (
    <Dialog.Root
      className={c('dialog')}
      open={open}
      onOpenChange={onOpenChange}
    >
      <Dialog.Title asChild>
        <Heading level="2" asChild>
          <span>Update credentials</span>
        </Heading>
      </Dialog.Title>

      <form onSubmit={handleSubmit(handleUpdateCredentials)}>
        <Text>
          Please provide camera credentials for <strong>{camera.name}</strong>{' '}
          if required.
        </Text>

        <FormErrorMessage error={error} />

        <Field label="Username">
          <Input {...register('username')} disabled={isSuccess} />
        </Field>

        <Field label="Password">
          <Input
            type="password"
            {...register('password')}
            disabled={isSuccess}
          />
        </Field>

        <Button
          variant="primary"
          type="submit"
          loading={isLoading}
          disabled={isSuccess}
        >
          Update
        </Button>
      </form>

      {isSuccess && (
        <Dialog.Message intent="success">Credentials updated!</Dialog.Message>
      )}
    </Dialog.Root>
  );
}
