import React, { DependencyList } from 'react';
import { debounce, DebounceSettings } from 'lodash-es';

type UseDebouncedCallbackOptions = DebounceSettings & { wait?: number };

export function useDebouncedCallback<T extends (...args: any) => any>(
  fn: T,
  deps: DependencyList = [],
  { wait = 200, ...options }: UseDebouncedCallbackOptions = {}
) {
  const debounced = React.useRef(debounce(fn, wait, options));

  React.useEffect(() => {
    debounced.current = debounce(fn, wait, options);

    return () => debounced.current.cancel();
  }, [fn, wait, options]);

  // eslint-disable-next-line
  return React.useCallback(debounced.current, deps);
}
