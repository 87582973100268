import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import classnames from 'classnames/bind';

import { IconButton } from 'components/IconButton/IconButton';

import styles from './SplitView.module.scss';

const c = classnames.bind(styles);

type SplitViewProps = Pick<
  RadixDialog.DialogProps,
  'open' | 'onOpenChange' | 'defaultOpen'
> &
  Pick<RadixDialog.DialogContentProps, 'children' | 'className'> & {
    isStacked?: boolean;
    easeOut?: boolean;
  };

export function SplitView({
  open,
  onOpenChange,
  defaultOpen,
  children,
  isStacked,
  easeOut,
}: SplitViewProps) {
  function preventAccidentalClose(event: Event) {
    event.preventDefault();
  }

  return (
    <RadixDialog.Root
      open={open}
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
      modal={false}
    >
      <RadixDialog.Content onInteractOutside={preventAccidentalClose}>
        <div
          className={c(
            'content',
            { stacked: isStacked },
            { 'ease-out': easeOut }
          )}
        >
          <RadixDialog.Close className={c('close')} asChild>
            <IconButton icon="cancel" label="Close" />
          </RadixDialog.Close>

          {children}
        </div>
      </RadixDialog.Content>
    </RadixDialog.Root>
  );
}
