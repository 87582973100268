import React from 'react';
import { SingleValue } from 'react-select';

import Pipeline from 'types/pipeline';
import { FilterInput } from 'graphql/types/filter';

import { usePipelines } from 'pipelines/hooks/usePipelines';

import { PipelineSelect } from 'pipelines/components/PipelineSelect/PipelineSelect';

import { Placeholder } from './SuggestionInput';
import { filterIds } from '../service';
import { useMetricFiltersState } from '../context';

export type PipelinesFilterInputProps = Pick<FilterInput, 'id' | 'value'>;

export function PipelineFilterInput({ id, value }: PipelinesFilterInputProps) {
  const { filters, updateFilter } = useMetricFiltersState();

  const pipelineIds = filterIds('pipeline_id', filters);

  const { data: pipelines, isInitialLoading } = usePipelines(
    ['metric-filter-pipelines'],
    { pipeline_ids: pipelineIds },
    { enabled: Boolean(pipelineIds.length), keepPreviousData: true }
  );

  if (!pipelines && isInitialLoading) {
    return Placeholder;
  }

  function handlePipelineChange(pipeline: SingleValue<Pipeline>) {
    updateFilter(id, 'value', pipeline?.id);
  }

  return (
    <PipelineSelect
      defaultValue={pipelines?.data.find(({ id }) => id === value)}
      onChange={handlePipelineChange}
      styles={{
        menu: (provided) => ({
          ...provided,
          width: 'auto',
        }),
      }}
    />
  );
}
