import React from 'react';
import classNames from 'classnames/bind';
import { Link, useParams } from 'react-router-dom';

import { ApplicationParams } from 'application/types/application_params';
import Camera from 'types/camera';

import { useDeleteCameras } from 'cameras/hooks/useDeleteCameras';

import { Button } from 'components/Button/Button';
import { Heading } from 'components/Heading/Heading';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';

import styles from './LinkedCameraCard.module.scss';

const c = classNames.bind(styles);

type LinkedCameraCardProps = {
  camera: Camera;
  displaySource: (camera: Camera) => string;
  onAuthenticate: () => void;
};

export function LinkedCameraCard({
  camera,
  displaySource,
  onAuthenticate,
}: LinkedCameraCardProps) {
  const { applicationID } = useParams<ApplicationParams>();
  const [requestDelete, { isLoading }] = useDeleteCameras();

  function removeCamera(cameraID?: string) {
    if (cameraID) {
      requestDelete([cameraID]);
    }
  }

  return (
    <Link
      className={c('camera-card')}
      to={`/applications/${applicationID}/deploy/cameras/${camera.id}`}
    >
      <div className={c('camera')}>
        <StatusIndicator
          className={c('camera-status')}
          status={camera.status}
          size="small"
        />
        <Heading className={c('camera-name')} level="4">
          {camera.name || camera.model}
        </Heading>
        <Text className={c('camera-source')}>{displaySource(camera)}</Text>
        {camera.status === 'unauthorized' && (
          <Text className={c('camera-error')} intent="danger">
            Unauthorized: Invalid credentials
          </Text>
        )}
      </div>
      <div className={c('button-wrap', 'columns')}>
        <Button
          size="small"
          loading={isLoading}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            removeCamera(camera.id);
          }}
        >
          Remove
        </Button>
        {camera.status === 'unauthorized' && (
          <Button
            size="small"
            variant="primary"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onAuthenticate();
            }}
          >
            Authenticate
          </Button>
        )}
      </div>
    </Link>
  );
}
