import classNames from 'classnames/bind';

import { pluralize } from 'services/string';

import { Button } from 'components/Button/Button';
import { ButtonGroup, Group, Pagination, Pill } from '@mantine/core';
import { Icon } from 'components/Icon/Icon';
import { TableManager } from 'components/Table/Table';

import { useFilesSearchState } from './search_context';
import styles from './Search.module.scss';

const c = classNames.bind(styles);

export type FilesBulkManagerProps = {
  total: number;
  page?: number;
  onPageChange: (page: number) => void;
  onSelectAllOnPage: () => string[] | undefined;
};

export function FilesBulkManager({
  total,
  page = 1,
  onPageChange,
  onSelectAllOnPage,
}: FilesBulkManagerProps) {
  const { selectedFileIds, isDeleting, dispatch, deleteSelectedFiles } =
    useFilesSearchState();

  function handleSelectAllClick() {
    const ids = onSelectAllOnPage();

    if (!ids) {
      return;
    }

    dispatch({ type: 'add', ids });
  }

  return (
    <TableManager className={c('bulk-manager')}>
      <Group>
        <Pagination total={total} value={page} onChange={onPageChange} />

        <Button onClick={handleSelectAllClick} variant="ghost" size="small">
          Select all on this page
        </Button>

        {selectedFileIds.length > 0 && (
          <>
            <Button size="small" onClick={() => dispatch({ type: 'clear' })}>
              Clear
            </Button>

            <Group gap="xs">
              <Pill>{selectedFileIds.length}</Pill>{' '}
              {pluralize(selectedFileIds.length, 'file')} selected
            </Group>
          </>
        )}
      </Group>

      <ButtonGroup>
        <Button
          loading={isDeleting}
          onClick={deleteSelectedFiles}
          intent="danger"
          size="small"
          disabled={selectedFileIds.length === 0}
        >
          <Icon name="delete" />
          <span>Delete</span>
        </Button>
      </ButtonGroup>
    </TableManager>
  );
}
