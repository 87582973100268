import { Link } from 'react-router-dom';

import { useAPI } from 'hooks/api/useAPI';
import { useCanDeployPipeline } from 'deployments/hooks/useCanDeployPipeline';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

import { FormMessage } from 'components/FormMessage/FormMessage';

import Pipeline from 'types/pipeline';

export type DeploymentFormNodesNotIncludedInPlanWarningProps = {
  pipeline: Pipeline | undefined;
  onLink?: () => void;
};

export function DeploymentFormNodesNotIncludedInPlanWarning({
  pipeline,
  onLink,
}: DeploymentFormNodesNotIncludedInPlanWarningProps) {
  const { applicationID } = useAPI();
  const { data: organization } = useCurrentOrganization();

  const canDeploy = useCanDeployPipeline(pipeline);

  if (!pipeline || canDeploy) {
    return null;
  }

  return (
    <FormMessage intent="danger" icon="warning">
      This pipeline contains nodes from a higher plan.
      <br />
      Please{' '}
      <Link
        to={`/applications/${applicationID}/settings/organization/${organization?.id}/plans`}
        className="link"
        onClick={() => onLink?.()}
      >
        upgrade your plan
      </Link>{' '}
      to continue.
    </FormMessage>
  );
}
