import React from 'react';
import mqtt, { MqttClient } from 'mqtt';
import { DateTime } from 'luxon';

import { MqttClientContext } from 'hooks/useMqttClient';

import LumeoJWT, { MqttJwtPayload } from 'types/jwt';
import { JwtParams, useJWT } from 'hooks/useJWT';
import { generateId } from 'services/string';
import { parseJwtWithoutVerification as parse } from 'services/jwt';
import { useAPI } from 'hooks/api/useAPI';

const host = process.env.REACT_APP_MQTT_HOST;
const port = process.env.REACT_APP_MQTT_PORT;

const url = `wss://${host}:${port}`;

export function MqttClientProvider({ children }: React.PropsWithChildren<{}>) {
  const { applicationID } = useAPI();

  // Using state instead of mutation result to associate token with an application
  const [jwt, setJwt] = React.useState<LumeoJWT & JwtParams<'mqtt'>>();
  // Using state instead of top level constant because client can only initialized with mqtt.connect()
  const [client, setClient] = React.useState<MqttClient>();

  const { mutate: createMqttToken, isLoading } = useJWT<'mqtt'>(
    ['mqtt-token'],
    {
      onSuccess(data, params) {
        setJwt({ ...data, ...params });
        setClient(
          mqtt.connect(url, {
            clientId: `console_${generateId()}`,
            username: `application:${applicationID}`,
            password: data.token,
          })
        );
      },
    }
  );

  React.useEffect(() => {
    if (isLoading || !applicationID) {
      return;
    }

    if (
      !jwt ||
      jwt.requested_application_id_for_user !== applicationID ||
      DateTime.now().toSeconds() >= (parse<MqttJwtPayload>(jwt.token)?.exp ?? 0)
    ) {
      createMqttToken({
        type: 'mqtt',
        requested_application_id_for_user: applicationID,
      });
    }

    return () => {
      if (client) {
        client.end();
      }
    };
  }, [applicationID, client, createMqttToken, isLoading, jwt]);

  return (
    <MqttClientContext.Provider value={client}>
      {children}
    </MqttClientContext.Provider>
  );
}
