import React from 'react';
import RGL from 'react-grid-layout';
import { Controller, useForm } from 'react-hook-form';
import {
  DimensionInput,
  Sort,
  TimeSeriesGranularity,
} from '@propeldata/ui-kit';

import Dashboard from 'types/dashboard';
import {
  ChartType,
  DataWidget,
  FilesWidget,
  IframeWidget,
  StreamWidget,
  MultiStreamWidget,
} from 'types/dashboard_widget';
import { FilterInput } from 'graphql/types/filter';
import { MetricsResponse } from 'graphql/queries/metrics';

import { generateId } from 'services/string';
import { useUpdateDashboard } from 'dashboards/hooks/useUpdateDashboard';

import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import { NumberInput } from 'components/NumberInput/NumberInput';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { Select } from 'components/Select/Select';

import { ChartSelect } from '../ChartSelect/ChartSelect';
import { DimensionSelect } from '../DimensionsSelect/DimensionSelect';
import {
  GranularitySelect,
  isGranularityOptionDisabled,
} from '../GranularitySelect/GranularitySelect';
import { MetricFiltersInput } from '../MetricFiltersInput/MetricFiltersInput';
import { MetricOption, MetricSelect } from '../MetricSelect/MetricSelect';
import { useWidgetModal } from '../WidgetModal';
import { validateMetricFilters } from '../MetricFiltersInput/validate';

const SORT_OPTIONS = Object.values(Sort).map((value) => ({
  value,
  label: value,
}));

export type WidgetFieldValues = { name: string };

export type WidgetFormProps<
  T extends
    | DataWidget<ChartType>
    | IframeWidget
    | StreamWidget
    | MultiStreamWidget
    | FilesWidget
> = {
  widget?: T;
  dashboard?: Dashboard;
  onIsDirtyChange: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: React.Dispatch<React.SetStateAction<boolean>>;
};

export type DataWidgetFieldValues = WidgetFieldValues & {
  metric: MetricOption;
  chartType: ChartType;
  granularity: TimeSeriesGranularity;
  dimensions: DimensionInput[] | null;
  rowLimit: number;
  sort: Sort;
  filters: FilterInput[];
};

type DataFormProps = WidgetFormProps<DataWidget<ChartType>> & {
  metrics: MetricsResponse;
};

export function DataForm({
  widget,
  dashboard,
  metrics,
  onIsDirtyChange,
  onUpdate,
}: DataFormProps) {
  const { onClose } = useWidgetModal();

  const { mutate: mutateDashboard, isLoading: isUpdating } = useUpdateDashboard(
    {
      onSuccess(data) {
        resetForm(data);
        onClose();
      },
    }
  );

  // Intentionally using `any` to be able to set all default values no matter the chart type
  const query = widget?.query as any;

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<DataWidgetFieldValues>({
    defaultValues: {
      name: widget?.name,
      metric: widget ? createMetricOption(widget) : undefined,
      chartType: widget?.chartType ?? 'timeseries-bar',
      granularity: query?.granularity ?? TimeSeriesGranularity.Day,
      dimensions: query?.dimensions,
      rowLimit: query?.rowLimit ?? 10,
      sort: query?.sort ?? Sort.Desc,
      filters: query?.filters,
    },
  });

  React.useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  React.useEffect(() => {
    onUpdate(isUpdating);
  }, [isUpdating, onUpdate]);

  const metric = watch('metric');
  const chartType = watch('chartType');
  const isTimeSeries = String(chartType).includes('timeseries');
  const isLeaderboard = String(chartType).includes('leaderboard');
  const isPieChart = chartType === 'pie';
  const isGroupedChart =
    chartType === 'timeseries-bar-stacked' ||
    chartType === 'timeseries-bar-grouped' ||
    chartType === 'timeseries-heatmap';

  function createMetricOption({ query: dataQuery }: DataWidget<ChartType>) {
    const edge = metrics.metrics.edges.find(
      ({ node }) => node.uniqueName === dataQuery.metric
    );

    if (!edge) {
      return;
    }

    const { node: metric } = edge;

    return { value: metric.id, label: metric.uniqueName };
  }

  function resetForm(updatedDashboard: Dashboard) {
    if (!widget?.id) {
      return;
    }

    const updatedWidget = updatedDashboard.getWidget(
      widget.id
    ) as DataWidget<ChartType>;

    if (!updatedWidget) {
      return;
    }

    // Intentionally using `any` to be able to reset all values no matter the chart type
    const updatedQuery = updatedWidget?.query as any;

    reset({
      name: updatedWidget?.name,
      metric: createMetricOption(updatedWidget),
      chartType: updatedQuery?.chartType,
      granularity: updatedQuery?.granularity,
      dimensions: updatedQuery?.dimensions,
      rowLimit: updatedQuery?.rowLimit,
      sort: updatedQuery?.sort,
      filters: updatedQuery?.filters,
    });
  }

  function onSubmit({
    name,
    chartType,
    metric,
    granularity,
    rowLimit,
    sort,
    dimensions,
    filters: metricFilters,
  }: DataWidgetFieldValues) {
    if (!dashboard) {
      return;
    }

    const id = widget?.id || generateId();
    const layout: Omit<RGL.Layout, 'i'> =
      widget?.layout || dashboard.getNewWidgetLayout(4, 2);

    const filters = metricFilters.length ? metricFilters : undefined;

    switch (chartType) {
      case 'timeseries-bar':
      case 'timeseries-line': {
        mutateDashboard(
          dashboard.upsertWidget(
            new DataWidget<'timeseries-bar' | 'timeseries-line'>(
              id,
              name,
              chartType,
              metric.value,
              { metric: metric.label, granularity, filters },
              layout
            )
          )
        );
        break;
      }
      case 'timeseries-bar-stacked':
      case 'timeseries-bar-grouped':
      case 'timeseries-heatmap': {
        if (!dimensions) {
          return;
        }

        // When switching between single and multi select
        // These chart types expect only one dimension
        const [dimension] = dimensions;

        mutateDashboard(
          dashboard.upsertWidget(
            new DataWidget<
              | 'timeseries-bar-stacked'
              | 'timeseries-bar-grouped'
              | 'timeseries-heatmap'
            >(
              id,
              name,
              chartType,
              metric.value,
              {
                metric: metric.label,
                granularity,
                dimensions: [dimension],
                filters,
              },
              layout
            )
          )
        );
        break;
      }
      case 'pie': {
        if (!dimensions) {
          return;
        }

        // When switching between single and multi select
        // Pie chart expects only one dimension
        const [dimension] = dimensions;

        mutateDashboard(
          dashboard.upsertWidget(
            new DataWidget<'pie'>(
              id,
              name,
              chartType,
              metric.value,
              {
                metric: metric.label,
                rowLimit,
                sort,
                dimensions: [dimension],
                filters,
              },
              layout
            )
          )
        );
        break;
      }
      case 'leaderboard-bar':
      case 'leaderboard-table': {
        if (!dimensions) {
          return;
        }

        mutateDashboard(
          dashboard.upsertWidget(
            new DataWidget<'leaderboard-bar' | 'leaderboard-table'>(
              id,
              name,
              chartType,
              metric.value,
              { metric: metric.label, rowLimit, sort, dimensions, filters },
              layout
            )
          )
        );
        break;
      }
      case 'counter': {
        mutateDashboard(
          dashboard.upsertWidget(
            new DataWidget<'counter'>(
              id,
              name,
              chartType,
              metric.value,
              { metric: metric.label, filters },
              widget?.layout || dashboard.getNewWidgetLayout(2, 1)
            )
          )
        );
        break;
      }
    }
  }

  return (
    <form id="data-widget-form" onSubmit={handleSubmit(onSubmit)}>
      <Field label="Name" error={errors.name}>
        <Input
          {...register('name', {
            required: 'Please choose a name for this widget.',
          })}
          type="text"
          autoComplete="off"
          spellCheck="false"
        />
      </Field>
      <Field label="Metric" error={errors.metric}>
        <Controller
          name="metric"
          control={control}
          render={({ field: { onChange, value } }) => (
            <MetricSelect
              value={value}
              onChange={(option) => {
                // Dimension options change when metric changes so we reset the selection
                setValue('dimensions', null);
                onChange(option);
              }}
            />
          )}
          rules={{ required: 'Please choose a metric for this widget.' }}
        />
      </Field>
      <Field label="Display type" error={errors.chartType}>
        <Controller
          name="chartType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ChartSelect value={value} onChange={onChange} />
          )}
        />
      </Field>

      {isTimeSeries && (
        <Field label="Granularity" error={errors.granularity}>
          <Controller
            name="granularity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <GranularitySelect
                value={value}
                onChange={onChange}
                isOptionDisabled={(option) =>
                  isGranularityOptionDisabled(option.value, chartType)
                }
              />
            )}
            rules={{
              validate: (granularity) =>
                isGranularityOptionDisabled(granularity, chartType)
                  ? 'Please select a different granularity.'
                  : true,
            }}
          />
        </Field>
      )}

      {(isGroupedChart || isPieChart) && (
        <Field label="Dimension" error={errors.dimensions}>
          {metric?.label ? (
            <Controller
              name="dimensions"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DimensionSelect
                  metric={{ name: metric.label }}
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{ required: 'Please select at dimension.' }}
            />
          ) : (
            <Tooltip content="Please select a metric first." side="top">
              <div>
                <Select isDisabled />
              </div>
            </Tooltip>
          )}
        </Field>
      )}

      {isLeaderboard && (
        <Field label="Dimensions" error={errors.dimensions}>
          {metric?.label ? (
            <Controller
              name="dimensions"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DimensionSelect
                  metric={{ name: metric.label }}
                  value={value}
                  onChange={onChange}
                  isMulti
                />
              )}
              rules={{ required: 'Please select at least one dimension.' }}
            />
          ) : (
            <Tooltip content="Please select a metric first." side="top">
              <div>
                <Select isDisabled />
              </div>
            </Tooltip>
          )}
        </Field>
      )}

      {(isLeaderboard || isPieChart) && (
        <Field label="Row limit" error={errors.rowLimit}>
          <Controller
            name="rowLimit"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                min={1}
                step={1}
                max={1000}
                value={value}
                onChange={onChange}
                unit="rows"
              />
            )}
            rules={{
              required: 'Row limit must be at least 1.',
            }}
          />
        </Field>
      )}

      {(isLeaderboard || isPieChart) && (
        <Field label="Sort" error={errors.sort}>
          <Controller
            name="sort"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                value={{ value, label: value }}
                onChange={(option) => onChange(option?.value)}
                options={SORT_OPTIONS}
              />
            )}
          />
        </Field>
      )}

      <Field label="Filters" error={errors.filters}>
        <Controller
          name="filters"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              {metric?.label ? (
                <MetricFiltersInput
                  metric={{ name: metric.label }}
                  value={value}
                  onChange={onChange}
                />
              ) : (
                <div>
                  <Button
                    variant="secondary"
                    size="xsmall"
                    disabledTooltip="Please select a metric first."
                    disabled
                  >
                    <Icon name="plus" size="xsmall" />
                    <span>Add filter</span>
                  </Button>
                </div>
              )}
            </>
          )}
          rules={{
            validate: validateMetricFilters,
          }}
        />
      </Field>
    </form>
  );
}
