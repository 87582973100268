import React from 'react';

import { Card } from 'components/Card/Card';
import { Icon, IconType } from 'components/Icon/Icon';
import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';
import classNames from 'classnames/bind';

import styles from './AddCameraManuallyButton.module.scss';

const c = classNames.bind(styles);

type AddCameraManuallyButtonProps = {
  onClick: () => void;
  headerText: string;
  bodyText: string;
  iconName: IconType;
};

export function AddCameraManuallyButton({
  onClick,
  headerText,
  bodyText,
  iconName,
}: AddCameraManuallyButtonProps) {
  return (
    <Card
      onClick={onClick}
      size="small"
      className={c('add-camera-manually-card')}
    >
      <div className={c('box')}>
        <Icon className={c('box-icon')} name={iconName} size="medium" />
        <Heading className={c('box-heading')} level="3">
          {headerText}
        </Heading>
        <Text className={c('box-text')} type="paragraph">
          {bodyText}
        </Text>
      </div>
    </Card>
  );
}
