import React from 'react';
import classnames from 'classnames/bind';

import { useAccessTokens } from 'hooks/api/useAccessTokens';

import * as PageLayout from 'components/PageLayout';

import { TokenForm } from '../../components/TokenForm/TokenForm';
import { TokenTable } from '../../components/TokenTable/TokenTable';
import styles from '../Settings.module.scss';

const c = classnames.bind(styles);

export function AccessTokens() {
  const { data: accessTokens, isLoading } = useAccessTokens();

  const userGeneratedTokens = accessTokens?.filter(
    ({ type }) =>
      type === 'application' ||
      type === 'user' ||
      type === 'gateway_provisioning'
  );

  return (
    <PageLayout.Root size="medium">
      <PageLayout.Container>
        <div className={c('wrap')}>
          <section className={c('section')}>
            <TokenForm
              title="Generate new access token"
              types={['application', 'gateway_provisioning']}
            />
            <TokenTable tokens={userGeneratedTokens} isLoading={isLoading} />
          </section>
        </div>
      </PageLayout.Container>
    </PageLayout.Root>
  );
}
