import React from 'react';
import classNames from 'classnames/bind';
import { captureException, captureMessage } from '@sentry/react';
import { deserialize } from 'serializr';
import { Navigate, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import APIError from 'types/api_error';
import Application from 'types/application';

import { useAuthentication } from 'hooks/api/useAuthentication';
import { useAPI } from 'hooks/api/useAPI';
import {
  useApplications,
  useCreateApplication,
} from 'hooks/api/useApplications';
import {
  useOrganizations,
  useUpdateOrganization,
} from 'hooks/api/useOrganizations';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import { Loader } from 'components/Loader/Loader';
import { LogoMark } from 'components/Logo/LogoMark';
import { Text } from 'components/Text/Text';
import { Field } from 'components/Field/Field';

import styles from './Register.module.scss';

const c = classNames.bind(styles);

export type WelcomeFieldValues = {
  organization_name: string;
  workspace_name: string;
};

const MAX_INPUT_LENGTH = 45;

/**
 * Welcome screen to greet user after confirming their email address.
 * Prompt to optionally pick organization and workspace names.
 * Falls back to generic defaults that should be displayed as placeholders.
 */
export function Welcome() {
  const api = useAPI();
  const navigate = useNavigate();

  const { data: organizations, isLoading } = useOrganizations();
  const { data: applications, isLoading: isLoadingApplications } =
    useApplications();

  const {
    isLoading: isCreating,
    isSuccess: didCreate,
    error: applicationError,
    mutate: create,
  } = useCreateApplication();

  const {
    isLoading: isUpdating,
    isSuccess: didUpdate,
    error: organizationError,
    mutate: update,
  } = useUpdateOrganization();

  const { logout } = useAuthentication();

  const { register, handleSubmit } = useForm<WelcomeFieldValues>();

  React.useEffect(() => {
    if (!organizations) {
      return;
    }

    if (!Array.isArray(organizations)) {
      captureMessage(
        `Invalid data format "${typeof organizations}" for organizations after signing up.`
      );
      return;
    }

    // There is only exactly one organization if the user is entering this screen
    // – and is not seeing it ever again. So we can be sure the one we are
    // looking for is the first.
    const [currentOrganization] = organizations;

    if (!currentOrganization) {
      captureException('Empty array for organizations after signing up.');
      return;
    }

    // if org name is too long try to remove the @email.com
    if (currentOrganization.name.length > MAX_INPUT_LENGTH) {
      currentOrganization.name = currentOrganization.name.replace(
        /(.*)@(.*)( .*)/,
        "$0's$2"
      );
    }

    // if still too long shorten organization -> org
    if (currentOrganization.name.length > MAX_INPUT_LENGTH) {
      currentOrganization.name = currentOrganization.name.replace(
        'organization',
        'org'
      );
    }
  }, [organizations]);

  if (!organizations || !Array.isArray(organizations)) {
    return null;
  }

  const [currentOrganization] = organizations;

  function onSubmit({ organization_name, workspace_name }: WelcomeFieldValues) {
    if (!currentOrganization) {
      return;
    }

    currentOrganization.name =
      organization_name === '' ? currentOrganization.name : organization_name;
    update(currentOrganization);

    const newApplication = deserialize(Application, {
      name: workspace_name.trim() || 'Default workspace',
      organization_id: currentOrganization.id,
    });

    create(newApplication);
  }

  function handleLogoutClick() {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('mqtt-token')) {
        try {
          const tokenID = JSON.parse(localStorage[key]).id;
          api.accessTokens.delete(tokenID);
        } catch (error) {
          // do nothing
        } finally {
          localStorage.removeItem(key);
        }
      }
    });
    localStorage.removeItem('console.token');

    logout();
    navigate('/login', { replace: true });
  }

  if (isLoading || isLoadingApplications) {
    return <Loader text="Loading organization..." />;
  }

  if (!isLoading && !currentOrganization) {
    return <Navigate to="/login" />;
  }

  if ((didUpdate && didCreate) || applications?.length) {
    return <Navigate to="/" />;
  }

  return (
    <main className={c('wrap', 'content')}>
      <div className={c('container')}>
        <header>
          <LogoMark className={c('logo')} />

          <Heading level="1">It's great to have you!</Heading>

          <Text size="large">What are you going to build?</Text>
        </header>

        <form className={c('form')} onSubmit={handleSubmit(onSubmit)}>
          <Field id="organization_name" label="Enter an organization name">
            <Input
              id="organization_name"
              type="organization_name"
              placeholder={currentOrganization?.name}
              size="large"
              defaultValue={currentOrganization?.name}
              {...register('organization_name', {
                maxLength: {
                  value: MAX_INPUT_LENGTH,
                  message: '',
                },
              })}
            />
          </Field>
          <Field id="workspace_name" label="Enter a workspace name">
            <Input
              id="workspace_name"
              placeholder="Default workspace"
              size="large"
              {...register('workspace_name', {
                maxLength: {
                  value: MAX_INPUT_LENGTH,
                  message: '',
                },
              })}
            />
          </Field>

          <FormErrorMessage error={organizationError as APIError} />
          <FormErrorMessage error={applicationError as APIError} />

          <ButtonGroup>
            <Button
              type="submit"
              variant="primary"
              size="large"
              loading={isUpdating || isCreating}
            >
              Start using Lumeo
            </Button>
          </ButtonGroup>
          <br />
          <Text>
            <Button variant="link" onClick={handleLogoutClick}>
              Logout
            </Button>
          </Text>
        </form>
      </div>
    </main>
  );
}
