import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { Radio, RadioOption } from 'components/Radio/Radio';

import { FIELD_LABEL_WIDTH, Step2FieldValues } from '../Step2';

export function ColorFormatInput() {
  const {
    control,
    formState: { errors },
  } = useFormContext<Step2FieldValues>();

  return (
    <Field
      id="color_format"
      label="Color format"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      error={errors.inference_config?.color_format}
      required
    >
      <Controller
        name="inference_config.color_format"
        control={control}
        defaultValue="rgb"
        render={({ field: { value, onChange } }) => (
          <Radio id="color_format" value={value} onChange={onChange}>
            <RadioOption value="rgb">RGB</RadioOption>
            <RadioOption value="bgr">BGR</RadioOption>
            <RadioOption value="grayscale">Grayscale</RadioOption>
          </Radio>
        )}
        rules={{ required: 'Please select a color format.' }}
      />
    </Field>
  );
}
