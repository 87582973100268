import classnames from 'classnames/bind';
import { Controller, useForm } from 'react-hook-form';

import Stream from 'types/stream';

import { useGateways } from 'hooks/api/useGateways';
import { useTakeSnapshot } from 'hooks/api/useTakeSnapshot';
import { useHasAccess } from 'hooks/useHasAccess';
import { useUpdateStream } from 'streams/hooks/useUpdateStream';

import { Button } from 'components/Button/Button';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton/CopyToClipboardButton';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Input } from 'components/Input/Input';
import { Radio, RadioOption, RadioSelectOption } from 'components/Radio/Radio';

import { Heading } from 'components/Heading/Heading';
import styles from './Summary.module.scss';

const c = classnames.bind(styles);

type StreamSummaryFieldValues = {
  uri: string;
  stream_type: 'global' | string;
};

export type StreamSummaryProps = {
  stream: Stream;
};

const FIELD_LABEL_WIDTH = 150;

export function StreamSummary({ stream }: StreamSummaryProps) {
  const [hasAccess] = useHasAccess();

  const {
    mutate: updateStream,
    isLoading,
    isSuccess,
    error,
  } = useUpdateStream(['streams']);

  const { control, handleSubmit, register } = useForm<StreamSummaryFieldValues>(
    {
      reValidateMode: 'onSubmit',
      defaultValues: {
        uri: stream.uri,
        stream_type: stream.gateway_id || 'global',
      },
    }
  );

  const { data: gateways } = useGateways();
  const gatewayOptions = gateways?.data.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const { mutate: takeSnapshot } = useTakeSnapshot(stream);
  const canEdit = hasAccess('deploy_edit');

  function save({ uri, stream_type }: StreamSummaryFieldValues) {
    if (!canEdit) {
      return;
    }

    const updatedStream = stream.copy();
    updatedStream.uri = uri;

    if (stream_type === 'global') {
      updatedStream.gateway_id = undefined;
    } else {
      updatedStream.gateway_id = stream_type;
    }

    updateStream(updatedStream);
    takeSnapshot();
  }

  return (
    <form onSubmit={handleSubmit(save)}>
      <Heading level="3">Stream settings</Heading>
      <FormErrorMessage error={error} />

      <Field
        label="Access"
        info={
          <>
            Global streams are accessible by any gateway in your workspace.
            <br />
            Select a gateway if the streams are accessible only through a
            specific gateway.
          </>
        }
        labelWidth={FIELD_LABEL_WIDTH}
        labelVerticalAlign="flex-start"
      >
        <Controller
          name="stream_type"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Radio
              id="new-input-stream_type"
              onChange={(value) => onChange(String(value))}
              value={value}
              disabled={!canEdit}
            >
              <RadioOption value="global" icon="global">
                Global
              </RadioOption>
              <RadioSelectOption icon="gateway" options={gatewayOptions}>
                Gateway
              </RadioSelectOption>
            </Radio>
          )}
        />
      </Field>

      <Field
        label="URL"
        labelWidth={FIELD_LABEL_WIDTH}
        labelVerticalAlign="flex-start"
      >
        <div className={c('columns spaced valign-start')}>
          <div className={c('uri')}>
            {stream.source === 'uri_stream' ? (
              <div className={c('uri-form')}>
                <Input
                  className={c('uri-field')}
                  {...register('uri', { required: true })}
                  disabled={!canEdit}
                />

                {canEdit && (
                  <div className="columns spaced valign-center">
                    <Button
                      type="submit"
                      variant="primary"
                      className={c('uri-form-button')}
                      loading={isLoading}
                      size="small"
                    >
                      Save stream settings
                    </Button>

                    {isSuccess && (
                      <InlineNotification intent="success" icon="check-circle">
                        Saved!
                      </InlineNotification>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <a
                className="link"
                href={stream.uri}
                target="_blank"
                rel="noreferrer noopener"
              >
                {stream.uri}
              </a>
            )}
          </div>
          {stream.uri && (
            <CopyToClipboardButton
              text={stream.uri}
              variant="secondary"
              inline
            />
          )}
        </div>
      </Field>
    </form>
  );
}
