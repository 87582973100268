import { Token } from 'leac';
import {
  Parser,
  ab,
  abc,
  eitherOr,
  many,
  map,
  satisfy,
  sepBy1,
  token,
} from 'peberminta';

import {
  ComparisonOperator,
  Condition,
  ConditionProperty,
  LogicalOperator,
} from './types';
import { NestedConditionBuilder } from './nested_condition_builder';

export const LogicalOperatorParser: Parser<
  Token,
  string,
  ConditionProperty<LogicalOperator>
> = token(({ name, text }) => {
  if (name !== 'LOGICAL_OPERATOR') {
    return undefined;
  }

  switch (text) {
    case 'and':
    case 'or':
      return {
        text: text as LogicalOperator,
      };
    case '&&':
    case '||':
    default:
      return {
        text: text as LogicalOperator,
        error: 'Invalid logical operator. Use "and" or "or" instead.',
      };
  }
});

export const ComparisonOperatorParser: Parser<
  Token,
  string,
  ConditionProperty<ComparisonOperator>
> = token(({ name, text }) => {
  if (name !== 'COMPARISON_OPERATOR') {
    return undefined;
  }

  switch (text) {
    case '=':
    case '!=':
    case '<=':
    case '>=':
    case '<':
    case '>':
      return {
        text: text as ComparisonOperator,
      };
    default:
      return {
        text: text as ComparisonOperator,
        error: 'Invalid comparison operator.',
      };
  }
});

export const ValueParser: Parser<Token, string, ConditionProperty> = token(
  ({ name, text }) => {
    if (name === 'STRING' || name === 'DECIMAL') {
      return { text };
    }

    if (name === 'BOOLEAN') {
      switch (text) {
        case 'True':
        case 'False':
          return { text };
        case 'true':
        case 'false':
        default:
          return {
            text,
            error: 'Invalid boolean value. Use "True" or "False" instead.',
          };
      }
    }

    if (name === 'SINGLE_QUOTED_STRING') {
      return {
        text,
        error:
          'Single quoted strings are not supported. Try double quotes instead.',
      };
    }

    if (name === 'WORD') {
      if (!Number.isNaN(text) && Number.isFinite(Number(text))) {
        return { text };
      }

      return {
        text,
        error: 'Strings must be enclosed in double quotes.',
      };
    }

    if (name === 'INCOMPLETE_DECIMAL') {
      return {
        text,
        error: 'Incomplete decimal value.',
      };
    }

    return undefined;
  }
);

const OpeningSquareBracketParser: Parser<Token, string, Token> = satisfy(
  (token) => token.name === '['
);
const ClosingSquareBracketParser: Parser<Token, string, Token> = satisfy(
  (token) => token.name === ']'
);
const PlaceholderParser: Parser<Token, string, Token> = satisfy(
  (token) => token.name === 'WORD' && /^[a-zA-Z0-9_]+$/.test(token.name)
);

const AccessorSegmentParser: Parser<Token, string, string> = eitherOr(
  abc(
    OpeningSquareBracketParser,
    PlaceholderParser,
    ClosingSquareBracketParser,
    (opening, value, closing) => `${opening.text}${value.text}${closing.text}`
  ),
  token(({ name, text }) =>
    name === 'WORD' || name === '*' ? text : undefined
  )
);

export const AccessorParser: Parser<Token, string, string> = map(
  sepBy1(
    AccessorSegmentParser,
    satisfy(({ name }) => name === '.')
  ),
  (segments) => segments.join('.')
);

const SingleConditionParser: Parser<Token, string, Condition> = eitherOr(
  abc(
    AccessorParser,
    ComparisonOperatorParser,
    ValueParser,
    (accessor, comparator, value) => ({
      accessor: { text: accessor },
      comparator,
      value,
    })
  ),
  map(AccessorParser, (accessor) => ({
    accessor: { text: accessor },
    comparator: { text: '>' as ComparisonOperator },
    value: { text: '0' },
  }))
);

export const ConditionParser: Parser<Token, string, Condition> = ab(
  SingleConditionParser,
  many(
    ab(LogicalOperatorParser, SingleConditionParser, (operator, condition) => ({
      operator,
      condition,
    }))
  ),
  (firstCondition, remaining) => {
    const builder = new NestedConditionBuilder(firstCondition);
    remaining.forEach(({ operator, condition }) =>
      builder.addCondition(operator.text, condition)
    );
    return builder.condition;
  }
);
