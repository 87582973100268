import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Stream, { StreamType } from 'types/stream';
import { PaginatedAPIResponse } from 'types/api';

import { useStreams, UseStreamsFilterParams } from 'streams/hooks/useStreams';

export function useFileStreams(
  params?: UseStreamsFilterParams,
  options?: UseQueryOptions<PaginatedAPIResponse<Stream>, APIError>
) {
  return useStreams(
    ['streams'],
    {
      stream_types: [StreamType.FILE],
      ...params,
    },
    options
  );
}
