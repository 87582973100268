import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AIModel from 'types/ai_model';

import {
  MODEL_ARCHITECTURE_OPTIONS,
  MODEL_CAPABILITY_OPTIONS,
  MODEL_FORMAT_OPTIONS,
} from 'services/model_options';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { FileDisplay } from 'components/FileDisplay/FileDisplay';
import { FileInput } from 'components/FileInput/FileInput';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Input } from 'components/Input/Input';
import { Radio, RadioOption } from 'components/Radio/Radio';
import { Select } from 'components/Select/Select';
import { Separator } from 'components/Separator/Separator';
import { Textarea } from 'components/Textarea/Textarea';

import { validateFilenames } from './services/validate';
import { useModelFormState } from './context/useModelFormState';
import { useModelFormStep } from './hooks/useModelFormStep';
import { LabelsInput } from './inputs/LabelsInput';
import { LeavePrompt } from './components/LeavePrompt';

export const FIELD_LABEL_WIDTH = 120;

export type Step1FieldValues = Partial<
  Pick<
    AIModel,
    | 'name'
    | 'description'
    | 'format'
    | 'architecture'
    | 'capability'
    | 'gallery_img_url'
  >
> & {
  labels?: string;
  labels_file?: File;
  weights_file?: File | null;
  metadata_file?: File | null;
  inference_config?: Pick<
    AIModel['inference_config'],
    'inference_engine' | 'class_attributes'
  >;
};

/**
 * Model base configuration.
 */
export function Step1() {
  const navigate = useNavigate();

  const { model, error, isSaving, isMarketplace, dispatch, save } =
    useModelFormState();
  const {
    control,
    formState,
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    setValue,
    ...formMethods
  } = useForm<Step1FieldValues>({
    mode: 'onBlur',
    defaultValues: model,
  });

  useModelFormStep({ reset, getValues });

  async function onSubmit({ labels, ...formData }: Step1FieldValues) {
    const labelsFile = new window.File([labels || ''], 'model.labels');

    // Update model
    if (model) {
      const res = await save({ ...formData, labels_file: labelsFile });
      reset(res);
      setValue('metadata_file', undefined);
      setValue('weights_file', undefined);
      return;
    }

    // Save values to context, go to next step
    dispatch({
      type: 'update',
      data: {
        ...formData,
        labels,
        labels_file: labelsFile,
      },
    });

    navigate('./parameters');
  }

  const weightsFile = watch('weights_file');
  const metadataFile = watch('metadata_file');

  const [metadataFileUrl, setMetadataFileUrl] = React.useState(
    model?.metadata_file_url
  );

  const [weightsFileUrl] = React.useState(model?.weights_file_url);

  const modelFormat =
    MODEL_FORMAT_OPTIONS.find(({ value }) => value === model?.format)?.label ||
    undefined;
  const localModelFormat = watch('format');
  const inferenceEngine = watch('inference_config.inference_engine');

  // Ask API to delete the currently linked metadata file
  function handleDeleteMetadataFile() {
    if (!model) {
      return;
    }

    reset({ ...getValues(), metadata_file: null });
    setMetadataFileUrl(undefined);
  }

  // Removes a newly selected metadata file from the form
  function handleRemoveMetadataFile() {
    reset({ ...getValues(), metadata_file: undefined });
  }

  // Removes a newly selected weights file from the form
  function handleRemoveWeightsFile() {
    reset({ ...getValues(), weights_file: undefined });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field id="name" label="Name" error={formState.errors.name} required>
        <Input
          id="name"
          {...register('name', { required: 'Please choose a model name.' })}
        />
      </Field>
      <Field id="description" label="Description">
        <Textarea id="description" {...register('description')} />
      </Field>

      {isMarketplace && (
        <Field id="gallery-image" label="Gallery Image URL">
          <Input id="gallery-image" {...register('gallery_img_url')} />
        </Field>
      )}

      <Separator />

      <Field
        id="format"
        label="Format"
        error={formState.errors.format}
        labelWidth={FIELD_LABEL_WIDTH}
        labelVerticalAlign="flex-start"
        required={!model}
      >
        {/* required on creation, cannot be updated later */}
        {modelFormat || (
          <Controller
            name="format"
            control={control}
            defaultValue={null}
            render={({ field: { value, onChange } }) => {
              const selectedValue = MODEL_FORMAT_OPTIONS.find(
                (option) => option.value === value
              );

              return (
                <Select
                  id="model-format"
                  value={selectedValue}
                  onChange={(option) => onChange(option?.value)}
                  options={MODEL_FORMAT_OPTIONS}
                />
              );
            }}
            rules={{ required: 'Please choose a format.' }}
          />
        )}
      </Field>

      <Field
        id="model-capability"
        label="Capability"
        error={formState.errors.capability}
        labelWidth={FIELD_LABEL_WIDTH}
        labelVerticalAlign="flex-start"
        required
      >
        <Controller
          name="capability"
          control={control}
          defaultValue={null}
          render={({ field: { value, onChange } }) => {
            const selectedValue = MODEL_CAPABILITY_OPTIONS.find(
              (option) => option.value === value
            );

            return (
              <Select
                id="model-capability"
                value={selectedValue}
                onChange={(option) => onChange(option?.value)}
                options={MODEL_CAPABILITY_OPTIONS}
              />
            );
          }}
          rules={{ required: 'Please choose a capability.' }}
        />
      </Field>

      <Field
        id="model-architecture"
        label="Architecture"
        error={formState.errors.architecture}
        labelWidth={FIELD_LABEL_WIDTH}
        labelVerticalAlign="flex-start"
        required
      >
        <Controller
          name="architecture"
          control={control}
          defaultValue={null}
          render={({ field: { value, onChange } }) => {
            const selectedValue = MODEL_ARCHITECTURE_OPTIONS.find(
              (option) => option.value === value
            );

            return (
              <Select
                id="model-architecture"
                value={selectedValue}
                onChange={(option) => onChange(option?.value)}
                options={MODEL_ARCHITECTURE_OPTIONS}
              />
            );
          }}
          rules={{ required: 'Please choose an architecture.' }}
        />
      </Field>

      {(localModelFormat === 'etlt' ||
        localModelFormat === 'onnx' ||
        localModelFormat === 'onnxlumeoyolo') && (
        <Field
          id="inference_engine"
          label="Inference engine"
          error={formState.errors.inference_config?.inference_engine}
          labelWidth={FIELD_LABEL_WIDTH}
          labelVerticalAlign="flex-start"
        >
          <Controller
            name="inference_config.inference_engine"
            control={control}
            defaultValue="default"
            render={({ field: { value, onChange } }) => {
              return (
                <Radio
                  id="inference_engine"
                  // Setting a fallback to apply when API returns `null` value
                  // which ignores the <Controller defaultValue />
                  value={value ?? 'default'}
                  onChange={onChange}
                >
                  <RadioOption value="default">Default</RadioOption>
                  <RadioOption value="parallelized">Parallelized</RadioOption>
                </Radio>
              );
            }}
          />
          {localModelFormat === 'onnx' &&
            inferenceEngine === 'parallelized' &&
            model &&
            !model.inference_config.input_blob_name && (
              <FormMessage intent="warning" icon="warning">
                Please specify <strong>Input layer name</strong> under the
                parameters tab.
              </FormMessage>
            )}
        </Field>
      )}

      <Separator />

      <div>
        {(String(formState.errors.metadata_file?.type) === 'filename' ||
          String(formState.errors.weights_file?.type) === 'filename') && (
          <p className="form-error theme danger">
            Please ensure both files have different file names.
          </p>
        )}

        <FormProvider
          {...{
            ...formMethods,
            reset,
            register,
            control,
            formState,
            handleSubmit,
            setValue,
            getValues,
            watch,
          }}
        >
          <LabelsInput />

          <LeavePrompt />
        </FormProvider>

        <Field
          id="model-weights_file"
          key="weights"
          label="Weights"
          error={formState.errors.weights_file}
          labelVerticalAlign="flex-start"
          labelWidth={FIELD_LABEL_WIDTH}
          required
        >
          {/* Weights file is required, but doesn't need to be re-supplied when editing */}
          <Controller
            name="weights_file"
            control={control}
            defaultValue={null}
            render={({ field: { onChange, name } }) => (
              <FileInput
                id="model_weights-file"
                onChange={onChange}
                name={name}
              />
            )}
            rules={{
              required:
                model && model.weights_file_url
                  ? false
                  : 'Please select a weights file.',
              validate: {
                filename: (file) => {
                  const { metadata_file } = getValues();
                  return validateFilenames(file, metadata_file);
                },
              },
            }}
          />

          {weightsFile && (
            <FileDisplay
              file={weightsFile}
              onDelete={handleRemoveWeightsFile}
            />
          )}

          {weightsFileUrl && <FileDisplay url={weightsFileUrl} isSuccess />}
        </Field>

        <Field
          id="model-metadata_file"
          key="metadata"
          label="Metadata"
          labelVerticalAlign="flex-start"
          labelWidth={FIELD_LABEL_WIDTH}
        >
          <Controller
            name="metadata_file"
            control={control}
            defaultValue={undefined}
            render={({ field: { onChange, name } }) => (
              <FileInput
                id="model_metadata-file"
                onChange={onChange}
                name={name}
              />
            )}
            rules={{
              validate: {
                filename: (file) => {
                  const { weights_file } = getValues();
                  return validateFilenames(file, weights_file);
                },
              },
            }}
          />

          {metadataFile && (
            <FileDisplay
              file={metadataFile}
              onDelete={handleRemoveMetadataFile}
            />
          )}

          {metadataFileUrl && (
            <FileDisplay
              url={metadataFileUrl}
              onDelete={handleDeleteMetadataFile}
              isSuccess
            />
          )}
        </Field>
      </div>

      <FormErrorMessage error={error} />

      <ButtonGroup>
        <Button type="submit" variant="primary" loading={isSaving}>
          {model ? 'Save changes' : 'Continue to parameters'}
        </Button>

        {model &&
          !isSaving &&
          !formState.isDirty &&
          formState.isSubmitSuccessful && (
            <InlineNotification intent="success" icon="check-circle">
              Saved!
            </InlineNotification>
          )}
      </ButtonGroup>
    </form>
  );
}
