import React from 'react';
import classNames from 'classnames/bind';
import * as RadixPortal from '@radix-ui/react-portal';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useCombobox } from 'downshift';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  size as floatingSize,
  useFloating,
} from '@floating-ui/react-dom';

import Camera from 'types/camera';
import Stream from 'types/stream';

import { VideoSource } from 'hooks/api/useDeploymentsVideoSources';
import { useVideoSource } from 'hooks/api/useVideoSource';
import { VideoSourceSelection } from 'pipelines/services/rete/controls/video_source_selector';

import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { Loader } from 'components/Loader/Loader';
import { Radio, RadioOption, RadioValue } from 'components/Radio/Radio';
import { Text } from 'components/Text/Text';
import { useDeploymentFormOptional } from 'deployments/components/Form/context';

import { TypeFilterValue, VideoSourceFilters } from './types';
import { useVideoSourceSelectItems } from './useVideoSourceSelectItems';
import { VideoSourceSelectItem } from './Item';
import styles from './VideoSourceSelect.module.scss';

const c = classNames.bind(styles);

export type VideoSourceSelectLegacyProps = {
  filters?: VideoSourceFilters;
  size?: 'small';
  isClearable?: boolean;
  value?: VideoSource | VideoSourceSelection | null;
  onChange?: (videoSource: VideoSource | null) => void;
};

/**
 * @deprecated
 * This component should only be used for deployment form/Rete components.
 * FIXME: update video_sources endpoint to filter by camera_ids and stream_ids
 * https://app.shortcut.com/lumeo/story/11031
 */
export function VideoSourceSelectLegacy({
  filters: superFilters,
  size,
  isClearable,
  value,
  onChange,
}: VideoSourceSelectLegacyProps) {
  const { allowedGatewayIds, allowedStreamIds, allowedCameraIds } =
    useDeploymentFormOptional();
  const { data: initialValue, isInitialLoading } = useVideoSource(value);

  const [typeFilter, setTypeFilter] = React.useState<TypeFilterValue>(null);
  const [inputValue, setInputValue] = React.useState<string>(() => {
    if (value instanceof Camera || value instanceof Stream) {
      return value.name;
    }

    return '';
  });

  const filters = {
    gateway_ids: allowedGatewayIds,
    stream_ids: allowedStreamIds,
    camera_ids: allowedCameraIds,
    ...superFilters,
  };

  const { items, isLoading, pageElements, totalElements } =
    useVideoSourceSelectItems({
      filters,
      typeFilter,
      inputValue,
    });

  const {
    isOpen,
    highlightedIndex,
    selectedItem,
    getLabelProps,
    getInputProps,
    getMenuProps,
    getToggleButtonProps,
    getItemProps,
    selectItem,
  } = useCombobox<VideoSource | null>({
    items,
    inputValue,
    itemToString(item) {
      if (!item) {
        return inputValue ?? '';
      }

      return item.name ?? String(item.id);
    },
    onInputValueChange({ inputValue }) {
      setInputValue(inputValue ?? '');
    },
    onSelectedItemChange({ selectedItem }) {
      onChange?.(selectedItem ?? null);
    },
  });

  React.useEffect(() => {
    if (!value) {
      return;
    }

    if (value instanceof Camera || value instanceof Stream) {
      selectItem(value);
      setInputValue(value.name);
      return;
    }
  }, [value, selectItem]);

  React.useEffect(() => {
    if (!initialValue) {
      return;
    }

    selectItem(initialValue);
  }, [initialValue, selectItem]);

  const [menuStyles, setMenuStyles] = React.useState<React.CSSProperties>({});

  const { x, y, strategy, refs, reference, floating, update } = useFloating({
    placement: 'bottom-start',
    middleware: [
      offset(8),
      shift({ padding: 8 }),
      flip(),
      floatingSize({
        padding: 8,
        apply({ width, height, reference }) {
          setMenuStyles((styles) => ({
            ...styles,
            maxHeight: `${Math.max(100, height)}px`,
            maxWidth: `${width}px`,
            width: `${Math.max(480, reference.width)}px`,
            pointerEvents: 'auto',
          }));
        },
      }),
    ],
  });

  const menuProps = React.useMemo(
    () => getMenuProps({ ref: floating }),
    [getMenuProps, floating]
  );

  React.useEffect(() => {
    if (isOpen && refs.reference.current && refs.floating.current) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [isOpen, refs.floating, refs.reference, update]);

  function handleTypeFilterChange(value: RadioValue) {
    setTypeFilter(value as TypeFilterValue);
  }

  function handleClearClick() {
    if (!isClearable) {
      return;
    }

    selectItem(null);
    setInputValue('');
    onChange?.(null);
  }

  return (
    <div className={c('wrap', size)}>
      <div ref={reference}>
        <label
          {...getLabelProps()}
          className={c('input-wrap', 'focus-ring-within')}
        >
          <VisuallyHidden>Select video source</VisuallyHidden>
          <input
            {...getInputProps()}
            className={c('input')}
            type="text"
            placeholder="Video source"
            disabled={isInitialLoading}
          />

          {isInitialLoading && <Loader size="xsmall" text="Loading..." />}

          {isClearable && (selectedItem || inputValue) && (
            <IconButton
              icon="cancel"
              label="Clear"
              size="small"
              onClick={handleClearClick}
            />
          )}

          <button
            {...getToggleButtonProps()}
            className={c('toggle-button')}
            type="button"
          >
            <Icon name="chevron-down" size="small" />
          </button>
        </label>
      </div>

      <RadixPortal.Root>
        <div
          {...menuProps}
          style={{
            position: strategy,
            top: 0,
            left: 0,
            transform: `translate3d(${x}px, ${y}px, 0)`,
            zIndex: 999,
          }}
        >
          {isOpen && (
            <div className={c('menu')} style={menuStyles}>
              <div className={c('filters')}>
                <Radio
                  id="vss_filters"
                  value={typeFilter}
                  onChange={handleTypeFilterChange}
                >
                  <RadioOption value={null}>All</RadioOption>
                  <RadioOption value="cameras" icon="camera">
                    USB Cameras
                  </RadioOption>
                  <RadioOption value="streams" icon="stream">
                    Streams
                  </RadioOption>
                  <RadioOption value="files" icon="file">
                    Files
                  </RadioOption>
                </Radio>

                <Text size="small">
                  Showing{' '}
                  <strong>
                    {pageElements} of {totalElements}
                  </strong>{' '}
                  elements
                </Text>
              </div>

              {isLoading && (
                <div className={c('loader')}>
                  <Loader size="small" text="Loading..." />
                  <Text>Loading cameras, streams, and files...</Text>
                </div>
              )}

              <ul className={c('suggestions')}>
                {items.map((item, index) => (
                  <VideoSourceSelectItem
                    {...getItemProps({ item, index })}
                    item={item}
                    active={highlightedIndex === index}
                    key={`${item.id}_${index}`}
                  />
                ))}
              </ul>
            </div>
          )}
        </div>
      </RadixPortal.Root>
    </div>
  );
}

export type { VideoSourceFilters };
