import React from 'react';

import { AppEntity, AppEntityObject } from 'hooks/useEntities';

import {
  AdvancedFilterDispatchAction,
  AdvancedFilterState,
  AdvancedSearchMatches,
  AdvancedSearchRelatedEntityFilterItem,
  AdvancedSearchRelatedEntityFilterList,
} from '../AdvancedSearch.types';
import { AdvancedSearchFilterPublicProps } from '../components/AdvancedSearchFilter/AdvancedSearchFilter';
import { AdvancedFilterOptions } from '../AdvancedSearch';

export type AdvancedSearchContextProps<
  T extends AppEntityObject = AppEntityObject
> =
  | {
      data: T[];
      filteredData: T[];
      isLoading?: boolean;

      isEmpty: boolean;
      isSearching: boolean;

      searchInput: string;
      setSearchInput: (searchInput: string) => void;

      suggestions: AdvancedSearchMatches;
      suggestionsCount: number;
      relatedEntityFilters: AdvancedSearchRelatedEntityFilterList<T>;
      setRelatedEntityFilter: (
        property: keyof T,
        filter: AdvancedSearchRelatedEntityFilterItem<T>['filter']
      ) => void;
      removeRelatedEntityFilter: (property?: keyof T) => void;

      handleSearch: () => void;

      getPropertyFromEntityName: (entity: AppEntity) => keyof T;

      filters: AdvancedFilterState;
      handleApplyFilters: () => void;
      dispatchFilters: React.Dispatch<AdvancedFilterDispatchAction>;
      filterOptions?: AdvancedFilterOptions<T>;
      AdvancedSearchFilter?: React.FunctionComponent<AdvancedSearchFilterPublicProps>;
    }
  | undefined;

export const AdvancedSearchContext =
  React.createContext<AdvancedSearchContextProps>(undefined);

export function useAdvancedSearch<T extends AppEntityObject>() {
  const context = React.useContext<AdvancedSearchContextProps<T>>(
    AdvancedSearchContext as React.Context<AdvancedSearchContextProps<T>>
  );

  if (context === undefined) {
    throw new Error(
      '`useAdvancedSearch` can only be used with an `AdvancedSearch` component.'
    );
  }

  return context;
}
