import Rete, { Node } from 'rete';

import Component from 'pipelines/services/rete/nodes/component';
import PasswordInput from 'pipelines/services/rete/controls/password_input';
import ReteRadioSelect from 'pipelines/services/rete/controls/radio_select';
import TextInput from 'pipelines/services/rete/controls/text_input';
import { RawVideo } from 'pipelines/services/rete/sockets';

import {
  MaxEdgeFilesInput,
  SaveLocationInput,
  TargetLocationInput,
  TriggerInput,
  // WebhookUrlInput,
} from 'pipelines/services/rete/controls/configured_inputs';

export default class CaptureSnapshot extends Component {
  static key = 'Save Snapshot';
  static icon = 'storage' as const;
  static exportType = 'snapshot';
  static category = 'media' as const;
  static description = 'Save a snapshot when a specific condition is met';

  static input = {
    type: 'raw',
  };
  static output = {
    type: 'raw',
  };

  static outputMetadata = ['Saved snapshot ID'];

  constructor() {
    super(CaptureSnapshot.key);
  }

  async builder(node: Node) {
    node
      .addControl(SaveLocationInput(this.editor!, { group: 'location' }))
      .addControl(
        TargetLocationInput(this.editor!, {
          group: 'location',
          keyref: 'location',
          requiresKeyrefValue: 'local',
        })
      )
      .addControl(
        MaxEdgeFilesInput(this.editor!, {
          initial: 5,
          group: 'location',
          keyref: 'location',
          requiresKeyrefValue: 'local',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'key_prefix', {
          label: 'S3 prefix',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          info:
            'Organize S3 data using prefixes for hierarchical sorting of objects, similar to file organization in folders. ' +
            'To group clips based on location, consider using prefixes like "entrance/" or "parking-lot/".',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'bucket', {
          label: 'S3 bucket name',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          required: true,
          placeholder: 'example-bucket',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'region', {
          label: 'S3 region',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          required: true,
          placeholder: 'us-west-1',
          info: 'An identifier representing the geographical location of your S3 bucket. Regions vary based on your S3 provider and hosting location.',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'endpoint', {
          label: 'S3 endpoint',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          required: true,
          placeholder: 'https://s3.region.provider.com',
          info: 'The S3 endpoint URL. Endpoints vary based on your S3 provider and region.',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'access_key_id', {
          label: 'S3 access key ID',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          required: true,
          info: 'Commonly found on the access key settings page of your S3 provider.',
        })
      )
      .addControl(
        new PasswordInput(this.editor!, 'secret_access_key', {
          label: 'S3 secret access key',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          required: true,
          info: 'Commonly found on the access key settings page of your S3 provider.',
        })
      )
      // .addControl(WebhookUrlInput(this.editor!, { group: 'other' }))
      .addControl(
        TriggerInput(this.editor!, {
          group: 'Snapshot condition',
          info: 'Save a snapshot every time this condition is met.',
          required: true,
        })
      )
      .addControl(
        new ReteRadioSelect(this.editor!, 'trigger_mode', {
          group: 'Snapshot condition',
          label: 'Trigger mode',
          initial: 'single',
          options: [
            {
              value: 'single',
              label: 'Single',
              info: 'Take a snapshot once the trigger condition is met.',
            },
            {
              value: 'exact',
              label: 'Exact',
              info: 'Take a snapshot of every frame for as long as the condition is met.',
            },
          ],
        })
      )
      .addInput(new Rete.Input('input', 'Input', RawVideo, false));
  }
}
