import React from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import classNames from 'classnames/bind';

import styles from './Tabs.module.scss';

const c = classNames.bind(styles);

function TabsContent(
  { children, className, ...props }: RadixTabs.TabsContentProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <RadixTabs.Content {...props} className={c(className)} ref={ref}>
      {children}
    </RadixTabs.Content>
  );
}

const forwardRef = React.forwardRef<HTMLDivElement, RadixTabs.TabsContentProps>(
  TabsContent
);

export { forwardRef as TabsContent };
