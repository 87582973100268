import classnames from 'classnames/bind';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import { DeployRoute } from 'application/types/routes';

import { CameraDetail } from 'cameras/views/CameraDetail/CameraDetail';
import { CamerasOverview } from 'cameras/views/CamerasOverview/CamerasOverview';
import { CreateFilesView } from 'files/views/CreateFilesView/CreateFilesView';
import { DeploymentDetail } from 'deployments/views/DeploymentDetail/DeploymentDetail';
import { DeploymentOverview } from 'deployments/views/DeploymentOverview/DeploymentOverview';
import { Drawer } from 'components/Drawer/Drawer';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { EventsOverview } from 'events/views/EventsOverview/EventsOverview';
import { FileDetail } from 'files/views/FileDetail/FileDetail';
import { FilesOverview } from 'files/views/FilesOverview/FilesOverview';
import { GatewayDetail } from 'gateways/views/GatewayDetail/GatewayDetail';
import { GatewayOverview } from 'gateways/views/GatewayOverview/GatewayOverview';
import { InputStreamsOverview } from 'streams/views/InputStreamsOverview/InputStreamsOverview';
import {
  deploymentFormDrawerClassName,
  NewDeploymentForm,
} from 'deployments/components/Form';
import {
  RapidDeploy,
  RapidDeployDeployments,
  RapidDeployGatewaysList,
  RapidDeployRoute,
  RapidDeployVideoSources,
} from 'deployments/views/RapidDeploy';
import { StreamDetail } from 'streams/views/StreamDetail/StreamDetail';

import { ReactComponent as EmptyDeploymentsIllustration } from 'deployments/images/empty-pipeline-deployments.svg';

import { DetailRouteWithDeploymentRef } from './DetailRouteWithDeploymentRef';
import { DeploySidebar } from './Sidebar';
import styles from './Deploy.module.scss';

const c = classnames.bind(styles);

export function Deploy() {
  const navigate = useNavigate();

  return (
    <div className={c('deploy')}>
      <DeploySidebar />

      <Routes>
        <Route index element={<Navigate to={DeployRoute.GATEWAYS} replace />} />

        {/* Gateways */}
        <Route
          path={`${DeployRoute.GATEWAYS}/*`}
          element={
            <>
              <GatewayOverview />
              <Outlet />
            </>
          }
        >
          <Route
            path=":gatewayID/*"
            element={
              <DetailRouteWithDeploymentRef>
                <GatewayDetail />
              </DetailRouteWithDeploymentRef>
            }
          />
        </Route>

        {/* Cameras */}
        <Route
          path={DeployRoute.CAMERAS}
          element={
            <>
              <CamerasOverview />
              <Outlet />
            </>
          }
        >
          <Route
            path=":cameraID/*"
            element={
              <DetailRouteWithDeploymentRef>
                <CameraDetail />
              </DetailRouteWithDeploymentRef>
            }
          />
        </Route>

        {/* Streams */}
        <Route
          path={DeployRoute.STREAMS}
          element={
            <>
              <InputStreamsOverview />
              <Outlet />
            </>
          }
        >
          <Route
            path=":streamID/*"
            element={
              <DetailRouteWithDeploymentRef>
                <StreamDetail />
              </DetailRouteWithDeploymentRef>
            }
          />
        </Route>

        {/* Files */}
        <Route
          path={`${DeployRoute.FILES}/*`}
          element={
            <>
              <FilesOverview />
              <Outlet />
            </>
          }
        >
          <Route
            path=":fileID/*"
            element={
              <DetailRouteWithDeploymentRef>
                <FileDetail />
              </DetailRouteWithDeploymentRef>
            }
          />
        </Route>

        <Route
          path={`${DeployRoute.FILES}/new`}
          element={<CreateFilesView />}
        />

        {/* Deployments */}
        <Route
          path={`${DeployRoute.DEPLOYMENTS}/*`}
          element={<DeploymentOverview />}
        >
          <Route
            path="new"
            element={
              <Drawer
                title="Deploy pipeline"
                containerClassName={deploymentFormDrawerClassName}
                onClose={() => {
                  navigate(DeployRoute.DEPLOYMENTS);
                }}
                open
              >
                <NewDeploymentForm
                  onSuccess={({ id }) =>
                    navigate(`${DeployRoute.DEPLOYMENTS}/${id}`)
                  }
                />
              </Drawer>
            }
          />
          <Route path=":deploymentID" element={<DeploymentDetail />} />
          <Route
            index
            element={
              <EmptyView image={EmptyDeploymentsIllustration}>
                Select a pipeline deployment on the left to view its output
                streams or files, function logs, and configuration.
              </EmptyView>
            }
          />
        </Route>

        {/* Rapid deploy */}
        <Route path={`${DeployRoute.RAPID_DEPLOY}/*`} element={<RapidDeploy />}>
          <Route
            path={RapidDeployRoute.GATEWAY}
            element={<RapidDeployGatewaysList />}
          />
          <Route
            path={RapidDeployRoute.VIDEO_SOURCES}
            element={<RapidDeployVideoSources />}
          />
          <Route
            path={RapidDeployRoute.DEPLOY}
            element={<RapidDeployDeployments />}
          />
          <Route
            index
            element={<Navigate to={RapidDeployRoute.GATEWAY} replace />}
          />
        </Route>

        {/* Events */}
        <Route path={DeployRoute.EVENTS} element={<EventsOverview />} />
      </Routes>
    </div>
  );
}
