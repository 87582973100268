import React from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import type { CellProps, Column } from 'react-table';

import Stream, { StreamType } from 'types/stream';
import { ApplicationParams } from 'application/types/application_params';

import { relativeTimeFromNow } from 'services/date';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useDeleteFileStreams } from 'files/hooks/useDeleteFileStreams';
import { useDownloadFile } from 'files/hooks/useDownloadFile';
import { useFileStreams } from 'files/hooks/useFileStreams';
import { useHasAccess } from 'hooks/useHasAccess';
import { useStreams, UseStreamsFilterParams } from 'streams/hooks/useStreams';
import { useUpdateStream } from 'streams/hooks/useUpdateStream';
import { useTagsFilterParams } from 'tags/hooks/useTagsFilterParams';

import { Button } from 'components/Button/Button';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { NameCell, TableLabelsCell } from 'components/Table/Table';
import { PaginatedTable } from 'components/Table/PaginatedTable';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { TableBulkActionType } from 'components/Table/components/BulkActions/BulkActions';
import { Text } from 'components/Text/Text';

import { SourceCell } from './SourceCell';
import styles from './Table.module.scss';

const c = classNames.bind(styles);

export function FilesOverviewTable() {
  const navigate = useNavigate();
  const [hasAccess] = useHasAccess();
  const { applicationID } = useParams<ApplicationParams>();

  const tagFilters = useTagsFilterParams();
  const [queryFilters, setQueryFilters] =
    React.useState<UseStreamsFilterParams>({ page: 1 });

  const queryResult = useFileStreams(
    {
      ...tagFilters,
      ...queryFilters,
    },
    { keepPreviousData: true }
  );

  const [searchFilters, setSearchFilters] =
    React.useState<UseStreamsFilterParams>({});
  const searchQueryResult = useStreams(
    ['file-streams-suggestions'],
    {
      ...tagFilters,
      ...searchFilters,
      limit: 20,
      stream_types: [StreamType.FILE],
    },
    {
      enabled: Object.keys(searchFilters).length > 0,
      keepPreviousData: false,
    }
  );

  const { data: currentPlan, isSuccess } = useCurrentPlan();
  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);

  const { mutate: updateStream } = useUpdateStream(['streams']);
  const [requestDelete, { isLoading: isDeleting }] = useDeleteFileStreams();
  const { download, isLoading: isDownloading } = useDownloadFile();

  const [isRenamingFile, setIsRenamingFile] = React.useState<Stream['id']>();

  const columns = React.useMemo(() => {
    const columns: Column<Stream>[] = [
      {
        Header: 'File',
        Cell(props: CellProps<Stream>) {
          function handleRename(name: string) {
            updateStream(props.row.original.withName(name));
            setIsRenamingFile(undefined);
          }

          function cancelRename() {
            setIsRenamingFile(undefined);
          }

          return (
            <NameCell
              {...props}
              isRenaming={isRenamingFile === props.row.original.id}
              onNameChange={handleRename}
              onCancel={cancelRename}
              entity="file"
            />
          );
        },
      },
      {
        id: 'source',
        Header: 'Source',
        Cell: SourceCell,
      },
    ];

    if (canCreateTags) {
      columns.push({
        Header: 'Labels',
        Cell(props: CellProps<Stream>) {
          return (
            <TableLabelsCell
              {...props}
              entity="streams"
              singleQueryKey={['stream']}
              listQueryKey={['streams']}
              readOnly={!hasAccess('deploy_edit', 'streams')}
            />
          );
        },
      });
    }

    columns.push({
      id: 'updated_at',
      Header: 'Uploaded',
      sortType: 'date',
      accessor(file: Stream) {
        return relativeTimeFromNow(file.updated_at);
      },
    });

    return columns;
  }, [canCreateTags, isRenamingFile, hasAccess, updateStream]);

  const handleSearchInput = React.useCallback((value?: string) => {
    if (!value) {
      setSearchFilters({});
      setQueryFilters({ page: 1 });
      return;
    }

    setSearchFilters({
      stream_names: [value],
    });
  }, []);

  function handleApplyFilters() {
    setQueryFilters({ ...searchFilters, page: 1 });
  }

  function handleSuggestionSelect({ id }: Stream) {
    navigate(id);
  }

  const hasFilters =
    Object.keys(queryFilters).length > 0 ||
    Object.values(tagFilters).some(Boolean);

  const bulkActions = React.useMemo(() => {
    function handleBulkDownload(fileIds: string[]) {
      fileIds.forEach((file) => download(file));
    }

    if (hasAccess('deploy_edit')) {
      return [
        {
          action: 'Download',
          loading: isDownloading,
          onClick: handleBulkDownload,
        },
        {
          action: 'Delete',
          icon: 'delete',
          intent: 'danger',
          loading: isDeleting,
          onClick: requestDelete,
        },
      ] as TableBulkActionType[];
    }
  }, [requestDelete, download, hasAccess, isDeleting, isDownloading]);

  return (
    <div className={c('wrap')}>
      <QuerySearch
        entityName="file"
        searchLabel="Search files..."
        searchResultField="name"
        searchQueryResult={searchQueryResult}
        onApplyFilters={handleApplyFilters}
        onSuggestionSelect={handleSuggestionSelect}
        onValueChange={handleSearchInput}
        totalElements={queryResult.data?.total_elements}
      />

      <PaginatedTable
        id="files-table"
        label="files"
        queryResult={queryResult}
        columns={columns}
        pageSize={50}
        page={queryFilters.page}
        onPageChange={(page) =>
          setQueryFilters((previous) => ({ ...previous, page }))
        }
        onRowNavigate={({ id }) => `/deploy/files/${id}`}
        emptyMessage={
          <div className={c('empty')}>
            {hasFilters ? (
              <EmptyView title="No files matching the filter criteria found.">
                Please try another filter combination or clear filters.
              </EmptyView>
            ) : (
              <EmptyView title="No files yet.">
                <Text type="paragraph">
                  Configure pipelines to upload files to the cloud or upload
                  files manually.
                </Text>
                <br />
                <Button to={`/applications/${applicationID}/deploy/files/new`}>
                  Upload files
                </Button>
              </EmptyView>
            )}
          </div>
        }
        bulkActions={bulkActions}
      />
    </div>
  );
}
