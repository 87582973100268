import classnames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthentication } from 'hooks/api/useAuthentication';

import {
  ContextMenu,
  ContextMenuAction,
  ContextMenuSeparator,
} from 'components/ContextMenu/ContextMenu';
import { Icon } from 'components/Icon/Icon';

import styles from './NavUserMenu.module.scss';

const c = classnames.bind(styles);

export function NavUserMenu() {
  const navigate = useNavigate();
  const { accessTokens } = useAPI();
  const { user, logout } = useAuthentication();

  if (!user) {
    return null;
  }

  const { email } = user;

  function handleLogoutClick() {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('mqtt-token')) {
        try {
          const tokenID = JSON.parse(localStorage[key]).id;
          accessTokens.delete(tokenID);
        } catch (error) {
        } finally {
          localStorage.removeItem(key);
        }
      }
    });
    localStorage.removeItem('console.token');

    logout();
  }

  function handleManageAccountClick() {
    navigate('settings/account');
  }

  return (
    <ContextMenu
      side="bottom"
      align="start"
      trigger={
        <button className={c('button')}>
          <Icon name="account" size="small" />
          <span>{email}</span>
        </button>
      }
    >
      <ContextMenuAction onClick={handleManageAccountClick} icon="settings">
        Manage account
      </ContextMenuAction>
      <ContextMenuSeparator />

      <ContextMenuAction onClick={handleLogoutClick} icon="logout">
        Logout
      </ContextMenuAction>
    </ContextMenu>
  );
}
