import React from 'react';
import classNames from 'classnames/bind';

import { NUMBER_FORMAT } from 'services/number';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useUpdateUsageLimits } from 'organizations/hooks/useUpdateUsageLimits';
import { useUsageLimits } from 'organizations/hooks/useUsageLimits';
import { useUser } from 'hooks/useAuth';

import { Card } from 'components/Card/Card';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { Meter, MeterValue } from 'components/Meter';
import {
  UtilizationLimitDialog,
  UtilizationLimitFieldValues,
} from 'organizations/components/UtilizationLimitDialog/UtilizationLimitDialog';

import styles from '../OrganizationUtilization.module.scss';

const c = classNames.bind(styles);

export function RecordedUtilizationCard() {
  const user = useUser();

  const { data: currentPlan } = useCurrentPlan();
  const {
    isLoading,
    fileProcessingMinutes: { usage, userLimit, planLimit },
  } = useUsageLimits();
  const { data: organization } = useCurrentOrganization();

  const {
    isLoading: isSaving,
    isSuccess,
    mutateAsync: updateUsageLimits,
  } = useUpdateUsageLimits();

  const hasUsage = Number.isFinite(usage);

  if (!hasUsage || currentPlan === undefined || isLoading) {
    return null;
  }

  const includedInPlan = currentPlan?.included_file_processing_minutes;

  async function handleSave({
    shouldLimitUtilization,
    utilizationLimit,
  }: UtilizationLimitFieldValues) {
    if (shouldLimitUtilization && Number.isFinite(utilizationLimit)) {
      const res = await updateUsageLimits({
        file_processing_minutes_limit: Number(utilizationLimit),
      });
      return res?.file_processing_minutes_limit ?? null;
    } else if (!shouldLimitUtilization) {
      const res = await updateUsageLimits({
        file_processing_minutes_limit: null,
      });
      return res?.file_processing_minutes_limit ?? null;
    }

    return Promise.reject(new Error('Unable to set utilization limits.'));
  }

  function validateMinutes(minutes: number | '' | undefined) {
    if (Number.isFinite(minutes) && Number(minutes) % 10000 === 0) {
      return true;
    }

    return 'Please enter a multiple of 10,000 or 0.';
  }

  return (
    <Card className={c('item')}>
      <Heading level="4" asChild>
        <h3>Recorded analytics</h3>
      </Heading>

      <div className={c('meter-value')}>
        <MeterValue value={usage!} max={includedInPlan} unit="minutes" />

        {organization && user?.isBilling(organization.id) && !planLimit && (
          <UtilizationLimitDialog
            item="Recorded analytics"
            usage={usage!}
            unit="minutes"
            includedInPlan={includedInPlan}
            includedInPlanInfo={`${
              includedInPlan
                ? NUMBER_FORMAT.format(includedInPlan)
                : 'Unlimited'
            } minutes`}
            userLimit={userLimit}
            limitUnit="minutes"
            step={10_000}
            isLoading={isSaving}
            isSuccess={isSuccess}
            onSave={handleSave}
            validate={validateMinutes}
            trigger={
              <IconButton
                variant="ghost"
                icon="settings"
                label="Manage utilization limits for recorded analytics"
                size="small"
              />
            }
          />
        )}
      </div>

      {includedInPlan && <Meter value={usage!} max={includedInPlan} />}
    </Card>
  );
}
