import React from 'react';
import classNames from 'classnames/bind';
import { Slot } from '@radix-ui/react-slot';

import styles from './Heading.module.scss';

const c = classNames.bind(styles);

export type HeadingLevel = 1 | 2 | 3 | 4 | '1' | '2' | '3' | '4';

export type HeadingProps = React.PropsWithChildren<{
  className?: string;
  level: HeadingLevel;
  asChild?: boolean;
}>;

function Heading(
  { level, className, asChild, children }: HeadingProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const Cmp = asChild ? Slot : `h${level}`;
  return (
    <Cmp className={c('heading', `is-${level}`, className)} ref={ref}>
      {children}
    </Cmp>
  );
}

const ForwardRef = React.forwardRef<HTMLElement, HeadingProps>(Heading);

export { ForwardRef as Heading };
