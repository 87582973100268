export default function downloadFile(
  fileName: string,
  blobOrURL: Blob | string
): void {
  const isBlob = blobOrURL instanceof Blob;

  const url = isBlob ? window.URL.createObjectURL(blobOrURL) : blobOrURL;
  const elem = document.createElement('a');
  elem.href = url;
  elem.download = fileName;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}
