import React from 'react';
import { useClickAway } from 'react-use';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames/bind';

import { objectHas } from 'services/object';

import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Checkbox } from 'components/Checkbox/Checkbox';

import { useAdvancedSearch } from './../../services/AdvancedSearchContext';
import { AdvancedFilterDispatchAction } from './../../../AdvancedSearch/AdvancedSearch.types';

import styles from './AdvancedSearchFilter.module.scss';

const c = classnames.bind(styles);

export type AdvancedSearchFilterPublicProps = {
  className?: string;
  filterClassName?: string;
  inline?: boolean;
};

export type AdvancedSearchFilterProps = AdvancedSearchFilterPublicProps & {};

export function AdvancedSearchFilter({
  className,
  filterClassName,
  inline,
}: AdvancedSearchFilterProps) {
  const { state } = useLocation();
  const { filterOptions, filters, dispatchFilters, handleApplyFilters } =
    useAdvancedSearch();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const ref = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  useClickAway(ref, (event) => {
    if (inline) {
      return;
    }

    if (!buttonRef.current?.contains(event.target as any)) {
      setIsExpanded(false);
    }
  });

  React.useEffect(() => {
    if (!filterOptions) {
      return;
    }

    filterOptions.forEach(({ property, options }) => {
      if (state?.filters && objectHas(state.filters, property)) {
        options.forEach(({ value, checked }) => {
          if (state.filters[property] === value) {
            dispatchFilters({ property, key: value, value: checked });
          } else {
            dispatchFilters({ property, key: value, value: false });
          }
        });
        handleApplyFilters();
        return;
      }

      options.forEach(({ value, checked }) => {
        dispatchFilters({ property, key: value, value: checked });
      });
    });
  }, [filterOptions, dispatchFilters, state, handleApplyFilters]);

  function toggleExpanded() {
    setIsExpanded((previous) => !previous);
  }

  function handleFilterChange(updatedFilters: AdvancedFilterDispatchAction) {
    dispatchFilters(updatedFilters);
    handleApplyFilters();
  }

  if (!filterOptions) {
    return null;
  }

  // TODO: use Radix primitives
  return (
    <>
      <Button
        id="as_filter"
        className={c('button', className, { active: isExpanded, inline })}
        variant="ghost"
        aria-haspopup="listbox"
        aria-expanded={isExpanded}
        onClick={toggleExpanded}
        ref={buttonRef}
      >
        <Icon name="filter" />
        <span>Filter</span>
        <span className={c('active-filters')}></span>
      </Button>
      {isExpanded && (
        <div
          role="listbox"
          id="as_filter-box"
          className={c('box', filterClassName, { inline })}
          ref={ref}
        >
          <div className={c('box-content')}>
            {filterOptions.map(({ property, label, options }) => {
              if (!filters[property]) {
                return null;
              }

              return (
                <section className={c('section')} key={property}>
                  <h3 className={c('section-label', 'text-label')}>{label}</h3>
                  <ul className={c('section-options')}>
                    {options.map(({ label, value, icon }) => {
                      const id = `${property}-${value}`;

                      return (
                        <li key={value}>
                          <label className={c('option')} htmlFor={id}>
                            <Checkbox
                              id={id}
                              onChange={(checked) =>
                                handleFilterChange({
                                  property,
                                  key: value,
                                  value: checked,
                                })
                              }
                              checked={filters[property]?.[value]}
                            />
                            {icon && (
                              <span className={c('option-icon')}>
                                {typeof icon === 'string' ? (
                                  <Icon name={icon} />
                                ) : (
                                  icon
                                )}
                              </span>
                            )}
                            <span className={c('option-label')}>{label}</span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </section>
              );
            })}
          </div>

          {inline && (
            <button
              className={c('close-button')}
              aria-label="Close filters"
              onClick={toggleExpanded}
            >
              <Icon
                name="chevron-down"
                className={c('close-button-icon')}
                size="small"
              />
            </button>
          )}
        </div>
      )}
    </>
  );
}
