import React from 'react';
import classnames from 'classnames/bind';
import { useUncontrolled } from '@mantine/hooks';

import { Heading, HeadingLevel } from 'components/Heading/Heading';
import { Icon, IconProps } from 'components/Icon/Icon';

import styles from './Accordion.module.scss';

const c = classnames.bind(styles);

export type AccordionProps = React.PropsWithChildren<{
  className?: string;

  label: string | React.ReactNode;
  subline?: React.ReactElement | string;
  icon?: IconProps['name'];
  variant?: 'default' | 'flat';
  level?: HeadingLevel;

  disabled?: boolean;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
}>;

export function Accordion({
  className,
  label,
  subline,
  icon,
  variant = 'default',
  level = 3,
  children,
  disabled,
  defaultOpen = false,
  open,
  onOpenChange,
}: AccordionProps) {
  const [isOpen, setIsOpen, isControlled] = useUncontrolled({
    value: open,
    defaultValue: defaultOpen,
    onChange: onOpenChange,
  });

  function handleSummaryClick(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();

    if (disabled) {
      return;
    }

    if (!isControlled) {
      setIsOpen(!isOpen);
    }

    onOpenChange?.(!isOpen);
  }

  return (
    <details
      className={c('accordion', className, variant, { open: isOpen, disabled })}
      aria-disabled={disabled}
      open={isOpen}
    >
      <summary className={c('header')} onClick={handleSummaryClick}>
        <Icon name="chevron-right" className={c('indicator')} />

        {icon && <Icon name={icon} />}

        <div className={c('header-content')}>
          <Heading className={c('heading')} level={level}>
            {label}
          </Heading>
          {subline && <div>{subline}</div>}
        </div>
      </summary>

      <div className={c('content', { open: isOpen })}>{children}</div>
    </details>
  );
}
