import React from 'react';
import classnames from 'classnames/bind';
import ReactMarkdown from 'react-markdown';
import {
  Controller,
  FieldError,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { Node as ReteNode } from 'rete';

import Node, { getPropertyValue } from 'types/node';
import ReteInput from 'pipelines/services/rete/controls/input';

import { customMarkdownAnchor } from 'services/markdown';

import { useKeyRefValue } from 'pipelines/hooks/useKeyRefValue';

import { Field } from 'components/Field/Field';
import { Icon } from 'components/Icon/Icon';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { useDeploymentForm } from '../context/deployment_form_context';
import { DeploymentEditableControl } from '../Control/EditableControl';
import styles from '../Control/Control.module.scss';

const c = classnames.bind(styles);

export type DeploymentBulkOverrideFormControlProps = {
  node: Node;
  property: string;
  control: ReteInput;
  defaultValue: unknown;
  reteNode: ReteNode;
  isDeployParam: boolean;
  isUnfilled: boolean;
  isUnusedHiddenKeyRef: boolean;
  isActiveKeyRef: boolean;
};

export function DeploymentBulkOverrideFormControl({
  node,
  property,
  control,
  defaultValue,
  reteNode,
  isDeployParam,
  isUnfilled,
  isUnusedHiddenKeyRef,
  isActiveKeyRef,
}: DeploymentBulkOverrideFormControlProps) {
  const { deployment } = useDeploymentForm();
  const { control: formControl, unregister } = useFormContext();
  const { errors } = useFormState({ control: formControl });

  const localKeyref = control.keyref
    ? `${node.id}.${control.keyref}`
    : undefined;
  const pipelineKeyrefValue = useKeyRefValue(control.keyref, node);
  const localKeyrefValue = useKeyRefValue(
    localKeyref,
    reteNode,
    pipelineKeyrefValue
  );
  const id = `${node.id}.${property}`;

  const [shouldOverride, setShouldOverride] = React.useState(false);
  const isDisabled = !shouldOverride || (isActiveKeyRef && !isDeployParam);

  const isEnabled =
    control.requiresKeyrefValue === undefined ||
    control.requiresKeyrefValue === localKeyrefValue;

  if (!control) {
    return null;
  }

  if (!isEnabled) {
    return null;
  }

  const pipelineDefinitionValue = getPropertyValue(node.data, property);
  const deploymentConfigValue = getPropertyValue(
    deployment?.configuration[node.id!],
    property
  );

  const isPipelineParameter =
    pipelineDefinitionValue !== undefined &&
    pipelineDefinitionValue !== null &&
    (deploymentConfigValue === undefined || deploymentConfigValue === null);

  const controlName = `${node.id}.${property}`;
  const controlKeyrefName = `${node.id}.${control.keyref}`;

  if (
    (!isDeployParam &&
      (!control.props.required || !isUnfilled) &&
      !isActiveKeyRef) ||
    isUnusedHiddenKeyRef
  ) {
    return null;
  }

  const label =
    control.label || control.info ? (
      <>
        <span>{control.label}</span>

        {isPipelineParameter && (
          <Tooltip side="right" content="Parameter defined in pipeline">
            <span className={c('control-pipeline-icon')}>
              <Icon name="pipeline" size="small" />
            </span>
          </Tooltip>
        )}
      </>
    ) : null;

  function handleToggle(checked: boolean) {
    setShouldOverride(checked);

    if (!checked) {
      unregister(controlName);
      unregister(controlKeyrefName);
    }
  }

  const isRequired = shouldOverride && control.props.required;

  return (
    <div
      className={c(
        'form-row',
        control.key,
        control.inputType,
        control.props.type
      )}
    >
      <div className={c('control', 'override-control')}>
        <LightSwitch
          className={c('override-control-toggle')}
          id={`${id}_toggle`}
          onValueChange={handleToggle}
          checked={shouldOverride}
        />

        <Field
          id={id}
          className={c('override-control-field')}
          label={label}
          info={control.info}
          required={isRequired}
          disabled={isDisabled}
          error={errors[node.id!]?.[property as keyof FieldError]}
        >
          <Controller
            control={formControl}
            name={controlName}
            defaultValue={defaultValue ?? null}
            render={({ field }) => (
              <DeploymentEditableControl
                {...field}
                id={id}
                control={control}
                node={node}
                disabled={isDisabled}
                reteNode={reteNode}
                property={property}
                keyrefValue={localKeyrefValue}
              />
            )}
            rules={{
              required: isRequired ? 'This field is required.' : undefined,
            }}
          />

          {control.description && (
            <ReactMarkdown
              className={c('markdown-description')}
              components={{ a: customMarkdownAnchor }}
            >
              {control.description}
            </ReactMarkdown>
          )}
        </Field>
      </div>
    </div>
  );
}
