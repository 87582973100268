import Dashboard from 'types/dashboard';
import DashboardConfiguration from 'types/dashboard_configuration';
import { ChartType, DataWidget } from 'types/dashboard_widget';

/**
 * Migrates filters to a new format that got introduced with SC-9589.
 * A flat array of implicitly AND linked filters becomes `[{ ...rootFilter, and: [...allOtherFilters]}]`.
 * {@link https://app.shortcut.com/lumeo/story/9589/add-metrics-or-filtering-and-filter-groups-support}
 */
export function migrateMetricFilters(dashboard: Dashboard): Dashboard {
  const updatedDashboard = dashboard.copy();

  const { widgets } = updatedDashboard;

  if (!widgets || !widgets.length) {
    return dashboard;
  }

  const updatedWidgets = widgets.map((widget) => {
    if (widget.type === 'data') {
      const query = (widget as DataWidget<ChartType>)?.query;
      const filters = query?.filters;

      if (!query || !filters || filters.length <= 1) {
        return widget;
      }

      const [rootFilter, ...andFilters] = filters;

      return {
        ...widget,
        query: {
          ...query,
          filters: [
            {
              ...rootFilter,
              and: andFilters.length ? [...andFilters] : undefined,
            },
          ],
        },
      } as DataWidget<ChartType>;
    }

    return widget;
  });

  updatedDashboard.configuration = new DashboardConfiguration(updatedWidgets);

  return updatedDashboard;
}
