import Rete, { Node } from 'rete';

import Pipeline from 'types/pipeline';

import ModelDropdown from 'pipelines/services/rete/controls/model_dropdown';
import NodeDropdown from 'pipelines/services/rete/controls/node_dropdown';
import NumberInput from 'pipelines/services/rete/controls/number_input';
import ReteOptionalRangeSlider from 'pipelines/services/rete/controls/range_slider_optional';
import Component from 'pipelines/services/rete/nodes/component';
import { UnencodedVideo } from 'pipelines/services/rete/sockets';
import { ResolutionInput } from 'pipelines/services/rete/controls/configured_inputs';

import {
  InferenceDisplayer,
  IntervalDisplayer,
  ModelIDDisplayer,
} from 'pipelines/services/rete/displayers/infer_with_model';

export default class AIModel extends Component {
  static key = 'AI Model';
  static icon = 'wallpaper' as const;
  static exportType = 'model_inference';
  static category = 'recognition' as const;
  static description = 'Run AI model inference on input frames';

  static editorDisplayers = {
    model_id: ModelIDDisplayer,
    infer_on_node: InferenceDisplayer,
    inference_interval: IntervalDisplayer,
  };
  static release = 'alpha1';
  static input = {
    type: 'raw',
  };
  static output = {
    type: 'raw',
  };
  static inheritOutputFromInput = true;

  static outputMetadata = ['Inference outputs'];
  static outputParams = [UnencodedVideo, false] as const;

  constructor() {
    super(AIModel.key);
  }

  async builder(node: Node) {
    // Sometimes we need to build the node outside of an editor
    // An example of this is when deploying a pipeline
    if (this.editor) {
      this.editor.on('noderemoved', async () => {
        if (!(await this.idExists(String(node.data.parent_inference_node)))) {
          node.data.parent_inference_node = '';
        }
      });
    }

    node
      .addControl(
        new ModelDropdown(this.editor!, 'model_id', {
          label: 'AI Model',
          initial: null,
          required: true,
        })
      )
      .addControl(
        new NodeDropdown(this.editor!, 'infer_on_node', {
          label: 'Infer on node',
          info: 'Select another model inference node from this pipeline to infer only on objects previously detected by that node.',
          initial: null,
          nodeType: 'model_inference',
        })
      )
      .addControl(
        new NumberInput(this.editor!, 'inference_interval', {
          label: 'Inference interval',
          unit: 'frames',
          initial: 1,
          min: 1,
          step: 1,
        })
      )
      .addControl(
        new ReteOptionalRangeSlider(
          this.editor!,
          'class_properties.*.min_inference_threshold',
          {
            label: 'Inference threshold',
            info: 'Enable to override the default minimum inference threshold for all classes.',
            min: 0,
            max: 1,
            defaultInitial: 0.5,
          }
        )
      )
      .addControl(
        new ReteOptionalRangeSlider(this.editor!, 'class_properties.*.eps', {
          label: 'Duplicate merge threshold',
          info: 'Increase the threshold to reduce potential duplicate detections of a single object.',
          min: 0.01,
          max: 2,
          input: true,
          defaultInitial: 1,
        })
      )
      .addControl(
        ResolutionInput(this.editor!, {
          key: 'class_properties.*.object_min_size',
          label: 'Minimum object size',
          info: 'The size a detected object must exceed to be considered.',
        })
      )
      .addInput(new Rete.Input('input', 'Input', UnencodedVideo, false))
      .addOutput(new Rete.Output('output', 'Output', UnencodedVideo));
  }

  async idExists(id: string) {
    const json = this.editor!.toJSON();
    const pipeline = await Pipeline.fromReteDefinition(json);
    return pipeline.hasNodeWithID(id);
  }
}
