import classnames from 'classnames/bind';

import { Icon } from 'components/Icon/Icon';

import styles from '../Select.module.scss';

const c = classnames.bind(styles);

export function DropdownIndicator() {
  return <Icon name="chevron-down" size="small" className={c('indicator')} />;
}
