import { deserialize } from 'serializr';

import DashboardConfiguration from 'types/dashboard_configuration';

const DashboardTemplates = [
  {
    name: 'Blank',
    id: 'blank',
    configuration: { widgets: [] },
  },
  {
    name: 'Monitoring',
    id: 'monitoring',
    configuration: {
      widgets: [
        {
          id: 'id1',
          name: 'All online cameras',
          type: 'multistream',
          layout: {
            w: 4,
            h: 6,
            x: 0,
            y: 0,
            i: 'id1',
          },
          options: {
            status: ['online'],
            sources: ['camera_stream'],
          },
          autoPlay: false,
          isInteractive: true,
        },
        {
          id: 'id2',
          name: 'All online analytics streams',
          type: 'multistream',
          layout: {
            w: 5,
            h: 6,
            x: 4,
            y: 0,
            i: 'id2',
          },
          options: {
            status: ['online'],
            sources: ['pipeline_stream'],
          },
          autoPlay: false,
          isInteractive: true,
        },
        {
          id: 'id4',
          name: 'Saved clips & snapshots',
          type: 'files',
          layout: {
            w: 3,
            h: 6,
            x: 9,
            y: 0,
            i: 'id4',
          },
          options: {},
        },
      ],
    },
  },
  {
    name: 'Video Wall',
    id: 'videowall',
    configuration: {
      widgets: [
        {
          id: 'id1',
          name: 'All online cameras',
          type: 'multistream',
          layout: {
            w: 6,
            h: 6,
            x: 0,
            y: 0,
            i: 'id1',
          },
          options: {
            status: ['online'],
            sources: ['camera_stream'],
          },
          autoPlay: true,
          isInteractive: false,
        },
        {
          id: 'id2',
          name: 'All online analytics streams',
          type: 'multistream',
          layout: {
            w: 6,
            h: 6,
            x: 6,
            y: 0,
            i: 'id2',
          },
          options: {
            status: ['online'],
            sources: ['pipeline_stream'],
          },
          autoPlay: true,
          isInteractive: false,
        },
      ],
    },
  },
  {
    name: 'Occupancy & Counting',
    id: 'occupancy',
    configuration: {
      widgets: [
        {
          id: '_i0o0zc90m',
          name: 'Visitor count',
          type: 'data',
          query: {
            metric: 'Occupancy : Object Entered Sum',
            filters: [],
            dimensions: [
              {
                columnName: 'roi_label',
              },
            ],
            granularity: 'HOUR',
          },
          layout: {
            w: 3,
            h: 2,
            x: 0,
            y: 2,
            i: '_i0o0zc90m',
            moved: false,
            static: false,
          },
          metricId: 'MET01H2TCH5FXGTJPW5BYHB1KVRJX',
          chartType: 'timeseries-bar-stacked',
        },
        {
          id: '_9ciqegj6v',
          name: 'Peak occupancy',
          type: 'data',
          query: {
            metric: 'Occupancy : Max Occupancy',
            filters: [],
            dimensions: [
              {
                columnName: 'roi_label',
              },
            ],
            granularity: 'HOUR',
          },
          layout: {
            w: 3,
            h: 2,
            x: 3,
            y: 2,
            i: '_9ciqegj6v',
            moved: false,
            static: false,
          },
          metricId: 'MET01H2TCH5HHZDE4QVRYKKT9QHR7',
          chartType: 'timeseries-bar-stacked',
        },
        {
          id: '_lkhpwt53r',
          name: 'Line crossing counts',
          type: 'data',
          query: {
            metric: 'Line Counter : Total Objects Crossed Sum',
            dimensions: [
              {
                columnName: 'line_label',
              },
            ],
            granularity: 'HOUR',
          },
          layout: {
            w: 3,
            h: 2,
            x: 0,
            y: 0,
            i: '_lkhpwt53r',
            moved: false,
            static: false,
          },
          metricId: 'MET01H44VP1XGA8EMJDBRFV9W6BCG',
          chartType: 'timeseries-bar-stacked',
        },
        {
          id: '_tciey0m72',
          name: 'Line crossings by time',
          type: 'data',
          query: {
            metric: 'Line Counter : Total Objects Crossed Sum',
            dimensions: [
              {
                columnName: 'line_label',
              },
            ],
            granularity: 'HOUR',
          },
          layout: {
            w: 3,
            h: 2,
            x: 3,
            y: 0,
            i: '_tciey0m72',
            moved: false,
            static: false,
          },
          metricId: 'MET01H44VP1XGA8EMJDBRFV9W6BCG',
          chartType: 'timeseries-heatmap',
        },
        {
          id: '_0xakrwb7q',
          name: 'Line crossing totals',
          type: 'data',
          query: {
            metric: 'Line Counter : Total Objects Crossed Sum',
          },
          layout: {
            w: 3,
            h: 2,
            x: 6,
            y: 0,
            i: '_0xakrwb7q',
            moved: false,
            static: false,
          },
          metricId: 'MET01H44VP1XGA8EMJDBRFV9W6BCG',
          chartType: 'counter',
        },
        {
          id: '_fazibio1j',
          name: 'Average occupancy',
          type: 'data',
          query: {
            metric: 'Occupancy : Average Occupancy',
            filters: [],
            dimensions: [
              {
                columnName: 'roi_label',
              },
            ],
            granularity: 'HOUR',
          },
          layout: {
            w: 3,
            h: 2,
            x: 6,
            y: 2,
            i: '_fazibio1j',
            moved: false,
            static: false,
          },
          metricId: 'MET01H2TCH5Q4YH6P76XPY27Y02TS',
          chartType: 'timeseries-bar-stacked',
        },
        {
          id: '_c8aacmwfi',
          name: 'Average time in ROI',
          type: 'data',
          query: {
            metric: 'Occupancy : Avg Wait Time',
            filters: [],
            dimensions: [
              {
                columnName: 'roi_label',
              },
            ],
            granularity: 'HOUR',
          },
          layout: {
            w: 3,
            h: 2,
            x: 9,
            y: 2,
            i: '_c8aacmwfi',
            moved: false,
            static: false,
          },
          metricId: 'MET01HA5PTTMGTXNSPBMB3ZZ73SGX',
          chartType: 'timeseries-bar-stacked',
        },
        {
          id: '_m512736yv',
          name: 'Visitors by time of day heatmap',
          type: 'data',
          query: {
            metric: 'Occupancy : Object Entered Sum',
            filters: [],
            dimensions: [
              {
                columnName: 'roi_label',
              },
            ],
            granularity: 'HOUR',
          },
          layout: {
            w: 6,
            h: 2,
            x: 0,
            y: 6,
            i: '_m512736yv',
            moved: false,
            static: false,
          },
          metricId: 'MET01H2TCH5FXGTJPW5BYHB1KVRJX',
          chartType: 'timeseries-heatmap',
        },
        {
          id: '_odehl7633',
          name: 'Latest occupancy',
          type: 'data',
          query: {
            metric: 'Occupancy : Latest Occupancy',
            filters: [],
          },
          layout: {
            w: 3,
            h: 2,
            x: 9,
            y: 0,
            i: '_odehl7633',
            moved: false,
            static: false,
          },
          metricId: 'MET01HCWW6F7KTX8VTN90MZ66Z7PY',
          chartType: 'counter',
        },
        {
          id: '_z11tkclpz',
          name: 'Highly engaged visitors (>5 min presence)',
          type: 'data',
          query: {
            metric: 'Occupancy : Number of Objects Above Max Waiting Time',
            filters: [],
            dimensions: [
              {
                columnName: 'roi_label',
              },
            ],
            granularity: 'HOUR',
          },
          layout: {
            w: 6,
            h: 2,
            x: 6,
            y: 6,
            i: '_z11tkclpz',
            moved: false,
            static: false,
          },
          metricId: 'MET01H58QDMFE55QD8ZKHYA6EFH3F',
          chartType: 'timeseries-bar-grouped',
        },
      ],
    },
  },
];

export function getDashboardConfigurationForTemplate(templateId: string) {
  const selectedTemplate = DashboardTemplates.filter(
    (template) => template.id === templateId
  );
  if (selectedTemplate.length > 0) {
    return deserialize(
      DashboardConfiguration,
      selectedTemplate[0].configuration
    );
  }
  return new DashboardConfiguration([]);
}

export function getTemplateOptions() {
  return DashboardTemplates.map((template) => ({
    value: template.id,
    label: template.name,
  }));
}
