import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';
import Deployment from 'types/deployment';
import Stream from 'types/stream';
import { OffsetPaginated, OffsetPaginationParams } from 'types/pagination';
import { SharedStream } from 'types/shared_stream';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export type SharedStreamsParams = {
  camera_ids?: Camera['id'][];
  deployment_ids?: Deployment['id'][];
  stream_ids?: Stream['id'][];
};

export type UseSharedStreamsParams = SharedStreamsParams &
  OffsetPaginationParams;

export function useSharedStreams({
  queryKey = ['shared-streams'],
  params,
  ...options
}: UseQueryOptions<OffsetPaginated<SharedStream>, APIError> & {
  params?: UseSharedStreamsParams;
} = {}) {
  const { client, applicationID } = useAPI();

  const queryParams = { pagination: 'offset', limit: 50, page: 1, ...params };

  return useAuthenticatedQuery(
    [...queryKey, applicationID, queryParams],
    async () => {
      const { data } = await client.get<OffsetPaginated<SharedStream>>(
        `/apps/${applicationID}/shared_streams`,
        {
          params: queryParams,
        }
      );

      return data;
    },
    {
      ...options,
    }
  );
}
