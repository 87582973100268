import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';
import Gateway from 'types/gateway';
import Stream from 'types/stream';
import { FilesSearchResult } from 'types/files_search_result';
import { OffsetPaginated, OffsetPaginationParams } from 'types/pagination';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

import { useFilesSearchClient } from './useFilesSearchClient';

export type FilesSearchEntity = 'objects' | 'events' | 'texts' | 'vectors';

// FIXME: Tags support via TaggedAPIFilters type
export type UseFilesSearchParams = OffsetPaginationParams & {
  query?: string;

  /** Leave undefined to apply search to all types */
  search_entities?: FilesSearchEntity[];

  /**
   * @min 0
   * @max 1
   * @step 0.1
   */
  confidence?: number;

  /** @default 'relevance' */
  sort_by?: 'relevance' | 'created_at';

  created_ts_since?: string;
  created_ts_until?: string;

  camera_ids?: Camera['id'][];
  stream_ids?: Stream['id'][];
  gateway_ids?: Gateway['id'][];
};

export type UseFilesSearchOptions = UseQueryOptions<
  OffsetPaginated<FilesSearchResult>,
  APIError
>;

export function useFilesSearch(
  {
    pagination = 'offset',
    page = 1,
    limit = 50,
    ...filters
  }: UseFilesSearchParams = {},
  options: UseFilesSearchOptions = {}
) {
  const client = useFilesSearchClient();
  const { applicationID } = useAPI();

  const params = { pagination, page, limit, ...filters };

  return useAuthenticatedQuery(
    ['files-search', applicationID, params],
    async () => {
      const { data } = await client.get<OffsetPaginated<FilesSearchResult>>(
        `/apps/${applicationID}/search`,
        {
          params,
        }
      );

      return data;
    },
    options
  );
}
