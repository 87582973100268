import React from 'react';

import { useLockPipeline, useUnlockPipeline } from 'hooks/api/usePipeline';

type PipelineLockAction = 'lock' | 'unlock';

export type PipelineLockStatus = 'pending' | 'locked' | 'unlocked';
export type PipelineLockHeld = 'pending' | 'locked' | 'unlocked';

export function usePipelineLock(pipelineID: string) {
  const [isInitializing, setIsInitializing] = React.useState(true);
  const [status, dispatch] = React.useReducer(lockReducer, 'pending');
  /**
   * Whether the current user controls the pipeline lock status.
   * `undefined` for pending status.
   */
  const lockHeld = React.useRef<boolean>();

  const { mutate: lock } = useLockPipeline(pipelineID, {
    onSuccess: () => {
      dispatch('unlock');
      lockHeld.current = true;
    },
    onError: () => {
      dispatch('lock');
      lockHeld.current = false;
    },
    onSettled: () => setIsInitializing(false),
  });
  const { mutate: unlock } = useUnlockPipeline(pipelineID);

  React.useEffect(() => {
    lock();

    return () => {
      if (lockHeld.current) {
        unlock();
      }

      dispatch('unlock');
    };
  }, [pipelineID, lock, unlock]);

  return {
    isLoading: isInitializing,
    isLocked: status === 'locked',
    lockHeld,
    lock,
  };
}

function lockReducer(
  _status: PipelineLockStatus,
  action: PipelineLockAction
): PipelineLockStatus {
  switch (action) {
    case 'lock':
      return 'locked';
    case 'unlock':
      return 'unlocked';
    default:
      return 'pending';
  }
}
