import { ReteInputProps } from 'pipelines/services/rete/controls/input';

import { useActivePipeline } from 'pipelines/context/active_pipeline';
import { useCurrentNode } from 'deployments/hooks/useCurrentNode';

import { Select } from 'components/Select/Select';

export type NodeDropdownProps = ReteInputProps & {
  nodeType?: string;
};

type NodeDropdownOptionType = {
  value: string | null;
  label: string;
};

export default function NodeDropdown({
  id,
  value,
  onChange,
  nodeType,
  disabled,
}: NodeDropdownProps) {
  const { nodes } = useActivePipeline();
  const { nodeID } = useCurrentNode();

  const options: NodeDropdownOptionType[] = nodes
    .filter(({ id, component }) =>
      nodeType
        ? String(component?.exportType).toLowerCase() ===
            nodeType.toLowerCase() && nodeID !== id
        : nodeID !== id
    )
    .map(({ id }) => ({
      value: String(id),
      label: String(id),
    }));

  options.unshift({ value: null, label: 'None' });

  const selectedOption = options.find((option) => option.value === value);

  return (
    <Select
      id={id}
      onChange={(selection) => {
        if (onChange) {
          onChange(selection?.value);
        }
      }}
      defaultValue={selectedOption}
      options={options}
      isDisabled={disabled}
      isSearchable={false}
    />
  );
}
