import classNames from 'classnames/bind';

import { isEmptyObject } from 'services/object';

import * as Stepper from 'components/Stepper';

import { useModelFormState } from 'analytics-library/components/MultiStepForm';

import styles from './CreateModelView.module.scss';

const c = classNames.bind(styles);

export function CreateModelViewStepper() {
  const { data } = useModelFormState();

  const hasNoData = !data || isEmptyObject(data);

  return (
    <Stepper.Root className={c('stepper')}>
      <Stepper.Step label="Type &amp; weights" to="" />
      <Stepper.Step label="Parameters" to="parameters" disabled={hasNoData} />

      {(hasNoData || data.capability === 'detection') && (
        <Stepper.Step
          label="Inference parameters"
          to="inference-config"
          disabled={
            hasNoData ||
            data.capability !== 'detection' ||
            !data.inference_config?.cluster_mode
          }
        />
      )}
    </Stepper.Root>
  );
}
