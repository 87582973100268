import React from 'react';
import classNames from 'classnames/bind';
import { Link, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import APIError from 'types/api_error';

import {
  AuthenticationRedirect,
  getAuthenticationRedirect,
} from 'services/api_auth';
import { API_BASE_URL } from 'services/api';
import { truncateMiddle } from 'services/string';
import { useAuthentication } from 'hooks/api/useAuthentication';

import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import { Logo } from 'components/Logo/Logo';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { Text } from 'components/Text/Text';

import styles from './Auth.module.scss';

const c = classNames.bind(styles);

type EmailFieldValues = { email: string };
type PasswordFieldValues = { password: string };

export function AuthLogin() {
  const auth = useAuthentication();

  const {
    formState: emailFormState,
    getValues: getEmailForm,
    handleSubmit: submitEmailForm,
    register: registerEmailForm,
    watch: watchEmailForm,
  } = useForm<EmailFieldValues>();

  const {
    formState: passwordFormState,
    handleSubmit: submitPasswordForm,
    register: registerPasswordForm,
    reset: resetPasswordForm,
  } = useForm<PasswordFieldValues>();

  const [authenticationRedirect, setAuthenticationRedirect] =
    React.useState<AuthenticationRedirect>();
  const [isLoadingAuthenticationRedirect, setIsLoadingAuthenticationRedirect] =
    React.useState(false);
  const [authenticationRedirectError, setAuthenticationRedirectError] =
    React.useState<APIError>();

  function requestAuthenticationRedirect({ email }: EmailFieldValues) {
    setIsLoadingAuthenticationRedirect(true);

    getAuthenticationRedirect(email)
      .then((res) => setAuthenticationRedirect(res))
      .catch(() =>
        setAuthenticationRedirectError({
          code: 'server-error',
          message:
            'We are unable to process your request right now. Please try again later.',
          status: 500,
        })
      )
      .finally(() => setIsLoadingAuthenticationRedirect(false));
  }

  function changeLoginEmail() {
    auth.loginReset();
    // Reset isSubmitted state but keep values
    resetPasswordForm((values) => ({ ...values }));
    setAuthenticationRedirect(undefined);
    setAuthenticationRedirectError(undefined);
  }

  function signIn({ password }: PasswordFieldValues) {
    auth.login({ email: getEmailForm('email'), password });
  }

  if (auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (auth.loginError?.code === 'email-not-verified') {
    auth.loginReset();
    return (
      <Navigate
        to="/register"
        state={{ unverifiedEmailFromLogin: getEmailForm('email') }}
      />
    );
  }

  if (authenticationRedirect?.type === 'saml_sso_redirect') {
    const loginLocation: string = `${API_BASE_URL}/internal/saml/redirect?identity_provider=${authenticationRedirect.identity_provider}&redirect_url=${window.location.origin}/saml_return`;
    window.location.href = loginLocation;
  }

  return (
    <div className={c('wrap')}>
      <div className={c('card', 'auth')}>
        <div className="card-content">
          <Logo className={c('logo')} aria-label="Lumeo logo." />

          <div className={c('auth-text')}>
            <Heading level="1">Sign in to continue</Heading>
          </div>

          {authenticationRedirect?.type === 'password' ? (
            <form onSubmit={submitPasswordForm(signIn)}>
              <button
                className={c('link', 'text-button')}
                type="button"
                onClick={changeLoginEmail}
              >
                <Icon name="arrow-left" />
                <span>{truncateMiddle(watchEmailForm('email'), 37)}</span>
              </button>
              <Field
                id="password"
                label={
                  <span className={c('label-wrap')}>
                    <span>Password</span>
                    <Link
                      to="/forgot-password"
                      className={c('link', 'forgot-password-link')}
                    >
                      Forgot password?
                    </Link>
                  </span>
                }
                error={passwordFormState.errors.password}
              >
                <PasswordInput
                  id="password"
                  {...registerPasswordForm('password', {
                    required: 'Please enter your password.',
                  })}
                  autoFocus
                />
              </Field>

              <FormErrorMessage error={auth.loginError} />

              <Button
                className={c('auth-button')}
                type="submit"
                variant="primary"
                loading={auth.isLoggingIn}
              >
                Sign in
              </Button>
            </form>
          ) : (
            <form onSubmit={submitEmailForm(requestAuthenticationRedirect)}>
              <Field
                id="email"
                label="Email"
                error={emailFormState.errors.email}
              >
                <Input
                  id="email"
                  type="email"
                  {...registerEmailForm('email', {
                    required: 'Please enter your email address.',
                  })}
                />
              </Field>

              <FormErrorMessage error={authenticationRedirectError} />

              <Button
                className={c('auth-button')}
                type="submit"
                variant="primary"
                loading={isLoadingAuthenticationRedirect}
              >
                Continue
              </Button>
            </form>
          )}

          <div className={c('auth-text-secondary')}>
            <Text>
              Don't have an account?{' '}
              <Link to="/register" className="link">
                Sign up for free
              </Link>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
