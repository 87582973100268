import { useQuery } from '@tanstack/react-query';
import { useAPI } from './useAPI';

export type UseServerVersionResponse = {
  tag: string | null;
  commit: string;
  rust_version: string;
};

export function useServerVersion() {
  const api = useAPI();
  return useQuery(['api-version'], api.internal.readServerVersion);
}
