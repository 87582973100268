import React from 'react';

export function useSet<T>(initialValues?: Iterable<T>) {
  const [set, setSet] = React.useState(new Set<T>(initialValues));

  const add = React.useCallback((value: T) => {
    setSet((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.add(value);
      return newSet;
    });
  }, []);

  const remove = React.useCallback((value: T) => {
    setSet((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(value);
      return newSet;
    });
  }, []);

  const clear = React.useCallback(() => {
    setSet(new Set());
  }, []);

  const replace = React.useCallback((value: Iterable<T>) => {
    setSet(new Set(value));
  }, []);

  return [Array.from(set), { add, remove, set: replace, clear }] as const;
}
