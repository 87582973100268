import dayjs from 'dayjs';

export type RelativeDateRange =
  | 'TODAY'
  | 'LAST_24_HOURS'
  | 'LAST_7_DAYS'
  | 'LAST_30_DAYS'
  | 'THIS_WEEK'
  | 'THIS_MONTH'
  | 'THIS_YEAR'
  | 'LAST_WEEK'
  | 'LAST_MONTH'
  | 'LAST_YEAR';

export type DateRangePreset = {
  preset: RelativeDateRange;
  value: () => [Date, Date];
};

export const DATE_RANGE_PRESETS: DateRangePreset[] = [
  {
    preset: 'TODAY',
    value: () => [
      dayjs().startOf('day').toDate(),
      dayjs().endOf('day').toDate(),
    ],
  },
  {
    preset: 'LAST_24_HOURS',
    value: () => [dayjs().subtract(23, 'hours').toDate(), dayjs().toDate()],
  },
  {
    preset: 'LAST_7_DAYS',
    value: () => [
      dayjs().subtract(6, 'days').startOf('day').toDate(),
      dayjs().endOf('day').toDate(),
    ],
  },
  {
    preset: 'LAST_30_DAYS',
    value: () => [
      dayjs().subtract(29, 'days').startOf('day').toDate(),
      dayjs().endOf('day').toDate(),
    ],
  },

  {
    preset: 'THIS_WEEK',
    value: () => [
      dayjs().startOf('week').toDate(),
      dayjs().endOf('day').toDate(),
    ],
  },
  {
    preset: 'THIS_MONTH',
    value: () => [
      dayjs().startOf('month').toDate(),
      dayjs().endOf('day').toDate(),
    ],
  },
  {
    preset: 'THIS_YEAR',
    value: () => [
      dayjs().startOf('year').toDate(),
      dayjs().endOf('day').toDate(),
    ],
  },

  {
    preset: 'LAST_WEEK',
    value: () => [
      dayjs().subtract(1, 'week').startOf('isoWeek').toDate(),
      dayjs().subtract(1, 'week').endOf('isoWeek').toDate(),
    ],
  },
  {
    preset: 'LAST_MONTH',
    value: () => [
      dayjs().subtract(1, 'month').startOf('month').toDate(),
      dayjs().subtract(1, 'month').endOf('month').toDate(),
    ],
  },
  {
    preset: 'LAST_YEAR',
    value: () => [
      dayjs().subtract(1, 'year').startOf('year').toDate(),
      dayjs().subtract(1, 'year').endOf('year').toDate(),
    ],
  },
];

export function getPresetDateRange(preset: RelativeDateRange) {
  return DATE_RANGE_PRESETS.find((value) => value.preset === preset)?.value()!;
}
