import classnames from 'classnames/bind';
import { IndicatorsContainerProps } from 'react-select';

import styles from '../Select.module.scss';

const c = classnames.bind(styles);

export function IndicatorsContainer<
  OptionType,
  MultiSelect extends boolean = false
>({ innerProps, children }: IndicatorsContainerProps<OptionType, MultiSelect>) {
  return (
    <div {...innerProps} className={c('indicator-wrap')}>
      {children}
    </div>
  );
}
