import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Dashboard from 'types/dashboard';

import { useAPI } from 'hooks/api/useAPI';

export function useCreateDashboard({
  onMutate,
  onSettled,
  onError,
  ...options
}: UseMutationOptions<Dashboard, APIError, Dashboard> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation(api.dashboards.create, {
    ...options,
    onSettled(...args) {
      onSettled?.(...args);
      queryClient.invalidateQueries(['dashboards']);
    },
  });
}
