import Rete, { Node } from 'rete';

import RangeSlider from 'pipelines/services/rete/controls/range_slider';
import Component from 'pipelines/services/rete/nodes/component';
import { EncodedVideo, RawVideo } from 'pipelines/services/rete/sockets';

import { CodecInput } from 'pipelines/services/rete/controls/configured_inputs';

export default class EncodeVideo extends Component {
  static key = 'Encode Video';
  static icon = 'encode' as const;
  static exportType = 'encode';
  static category = 'media' as const;
  static description = 'Encode raw video frames and output an encoded stream';

  static input = {
    type: 'raw',
  };
  static output = {
    type: 'encoded',
  };

  constructor() {
    super(EncodeVideo.key);
  }

  async builder(node: Node) {
    node
      .addControl(CodecInput(this.editor!, {}))
      .addControl(
        new RangeSlider(this.editor!, 'bitrate', {
          label: 'Bit rate',
          initial: 750,
          info: 'Cannot exceed the maximum bit rate.',
          placeholder: '750',
          type: 'number',
          min: 100,
          minLabel: 'Low Quality',
          max: 5000,
          maxLabel: 'High Quality',
          step: 245,
          input: true,
        })
      )
      .addInput(new Rete.Input('input', 'Input', RawVideo, false))
      .addOutput(new Rete.Output('output', 'Output', EncodedVideo));
  }
}
