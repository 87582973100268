function getSubvalueTypeOrder(segment: string) {
  if (segment.startsWith('[') && segment.endsWith(']')) {
    return 2; // Placeholder
  }

  if (segment === '*') {
    return 1; // Wildcard
  }

  return 0; // Regular value
}

export function compareValues(a: string, b: string) {
  const aSubvalues = a.split('.');
  const bSubvalues = b.split('.');

  for (let i = 0; i < Math.max(aSubvalues.length, bSubvalues.length); i++) {
    const aSubvalue = aSubvalues[i] || '';
    const bSubvalue = bSubvalues[i] || '';

    const aTypeOrder = getSubvalueTypeOrder(aSubvalue);
    const bTypeOrder = getSubvalueTypeOrder(bSubvalue);

    if (aTypeOrder !== bTypeOrder) {
      return aTypeOrder - bTypeOrder;
    }

    const comparison = aSubvalue.localeCompare(bSubvalue);
    if (comparison !== 0) {
      return comparison;
    }
  }

  return 0;
}
