import classNames from 'classnames/bind';
import * as RadixSeparator from '@radix-ui/react-separator';

import styles from './Separator.module.scss';

const c = classNames.bind(styles);

export type SeparatorProps = RadixSeparator.SeparatorProps;

export function Separator(props: SeparatorProps) {
  return <RadixSeparator.Root {...props} className={c('separator')} />;
}
