export function coordinatesToPairs(coordinates: number[]) {
  const pairs: (readonly [number, number])[] = [];

  for (let i = 0; i < coordinates.length; i += 2) {
    const x = coordinates[i];
    const y = coordinates[i + 1];

    if (Number.isFinite(y)) {
      pairs.push([x, y]);
    }
  }

  return pairs;
}
