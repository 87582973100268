import { UseQueryOptions } from '@tanstack/react-query';

import {
  METRIC,
  MetricResponse,
  MetricQueryVariables,
} from 'graphql/queries/metrics';

import { usePropelQuery } from 'graphql/hooks/usePropelQuery';

export function usePropelMetric(
  variables: MetricQueryVariables,
  options?: UseQueryOptions<MetricResponse>
) {
  return usePropelQuery<MetricResponse, MetricQueryVariables>(
    METRIC,
    variables,
    ['metric'],
    options
  );
}
