import React from 'react';
import classnames from 'classnames/bind';
import {
  Column,
  SortingRule,
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { Pagination } from '@mantine/core';

import { Text } from 'components/Text/Text';

import { useCheckboxColumn } from './hooks/useCheckboxColumn';
import {
  TableBulkActionType,
  TableBulkActions,
} from './components/BulkActions/BulkActions';
import { NameCell } from './components/TableNameCell/TableNameCell';

import { TableLabelsCell } from './components/LabelsCell/LabelsCell';
import { TableRow, TableRowProps } from './TableRow';
import { deselectReducer } from './service/deselect';
import { getRowId } from './service/row';
import styles from './Table.module.scss';

const c = classnames.bind(styles);

export type TableProps<T extends {}> = {
  className?: string;
  id: string;
  columns: Column<T>[];
  data: T[];

  label: string;
  emptyMessage?: JSX.Element | React.ReactText;
  pageSize?: number;
  loading?: boolean;
  sortBy?: SortingRule<T>[];
  onRowNavigate?: (element: T) => string;
  onRowClick?: TableRowProps<T>['onClick'];

  bulkActions?: TableBulkActionType[];
};

export function Table<T extends {}>({
  className,
  id,
  columns,
  data,
  pageSize,
  emptyMessage,
  loading = false,
  label,
  bulkActions = undefined,
  sortBy = [
    {
      id: 'updated_at',
      desc: true,
    },
  ],
  onRowNavigate,
  onRowClick,
}: TableProps<T>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: allRows,
    prepareRow,

    dispatch,
    // Toggle visible rows
    toggleAllRowsSelected,
    state: { selectedRowIds, pageIndex },

    // Pagination
    page,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize,
        sortBy,
      },
      sortTypes: {
        date: (rowA: any, rowB: any, id, desc) => {
          const a = +new Date(rowA.original[id]);
          const b = +new Date(rowB.original[id]);
          return desc ? a - b : b - a;
        },
      },
      bulkEdit: Boolean(bulkActions),
      id,
      getRowId,
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
      stateReducer: deselectReducer,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useCheckboxColumn
  );

  const rows = pageSize ? page : allRows;

  function handleSelectAll() {
    toggleAllRowsSelected(true);
  }

  function handleClearSelection() {
    dispatch({ type: 'deselectAllRows' });
  }

  const emptyTableContent = () => {
    if (loading) {
      return <div className="loading-spinner"></div>;
    }

    return (
      emptyMessage || <Text>No results found for the active filters.</Text>
    );
  };

  return (
    <div className={c('wrap', className)}>
      <table key="table" {...getTableProps()} className={c('table')}>
        <thead className={c('head')}>
          {headerGroups.map((headerGroup) => {
            const { key, ...groupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr {...groupProps} key={key}>
                {headerGroup.headers.map((column) => {
                  const { key: colKey, ...headerProps } =
                    column.getHeaderProps();
                  return (
                    <Text size="xxsmall" key={colKey} asChild>
                      <th
                        {...headerProps}
                        className={c('header', {
                          autoSize: column.width === 0,
                        })}
                      >
                        {column.render('Header')}
                      </th>
                    </Text>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length === 0 ? (
            <tr className={c('row')}>
              <td
                colSpan={bulkActions ? columns.length + 1 : columns.length}
                className={c('cell', 'empty')}
              >
                <div className={c('empty-wrap')}>{emptyTableContent()}</div>
              </td>
            </tr>
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  row={row}
                  to={onRowNavigate?.(row.original)}
                  onClick={onRowClick}
                  key={row.id}
                />
              );
            })
          )}
        </tbody>
      </table>

      {(pageSize && pageCount > 1) || bulkActions ? (
        <TableManager>
          {pageCount > 1 ? (
            <Pagination
              key="pages"
              value={pageIndex}
              total={pageCount}
              onChange={gotoPage}
            />
          ) : null}

          <TableBulkActions
            selectedRowIds={selectedRowIds}
            label={label}
            onClearSelection={handleClearSelection}
            onSelectAll={handleSelectAll}
            totalRows={allRows.length}
            actions={bulkActions}
          />
        </TableManager>
      ) : null}
    </div>
  );
}

export function TableManager({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={c('bulk-manager', className)}
      role="dialog"
      aria-live="polite"
    >
      {children}
    </div>
  );
}

export { NameCell, TableLabelsCell, TableBulkActions, getRowId };
