import React from 'react';
import classNames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { IconButton } from 'components/IconButton/IconButton';
import { Input } from 'components/Input/Input';

import { getNameFromStreamURI } from '../../services/input_streams';

import {
  CreateInputStreamFieldValues,
  CreateInputStreamsRowValue,
} from './types';
import styles from './CreateInputStreamsDialog.module.scss';

const c = classNames.bind(styles);

export type CreateInputStreamsRowProps = {
  streams: CreateInputStreamsRowValue[];
  index: number;
  onRemove: (index: number) => void;
};

export function CreateInputStreamsRow({
  streams,
  index,
  onRemove,
}: CreateInputStreamsRowProps) {
  const {
    formState: { errors },
    watch,
    register,
    getValues,
  } = useFormContext<CreateInputStreamFieldValues>();

  const uriId = `streams.${index}.uri` as const;
  const nameId = `streams.${index}.name` as const;

  const uri = watch(uriId, '');
  const uriError = errors.streams?.[index]?.uri?.message;

  const isNameRequired =
    Boolean(uri.trim()) && !getNameFromStreamURI(uri).trim();
  const nameError = errors.streams?.[index]?.name?.message;

  const isOnlyRow = streams.length === 1;

  return (
    <fieldset className={c('row-wrap')}>
      <div className={c('row')}>
        <Field id={uriId} label="URI" className={c('row-field')}>
          <Input
            id={uriId}
            placeholder="http://user:pass@0.0.0.0/video/1"
            error={Boolean(uriError)}
            {...register(uriId, {
              validate: {
                required: (value) => {
                  const nameValue = getValues(nameId);
                  const hasName =
                    typeof nameValue === 'string' && nameValue.trim();

                  if (!value?.trim() && hasName) {
                    return 'Please enter a stream URI.';
                  }

                  return true;
                },
                isValidURI: (value) => {
                  if (!value.trim()) {
                    return true;
                  }

                  if (value.startsWith('file://')) {
                    return 'File URLs are not supported.';
                  }

                  try {
                    new URL(value);
                    return true;
                  } catch (error) {
                    return 'Please enter a valid stream URI.';
                  }
                },
              },
            })}
          />
        </Field>
        <Field
          id={nameId}
          label="Name"
          className={c('row-field')}
          required={isNameRequired}
        >
          <Input
            id={nameId}
            placeholder={getNameFromStreamURI(uri)}
            error={Boolean(nameError)}
            {...register(nameId, {
              validate: {
                required(name) {
                  if (isNameRequired && !name?.trim()) {
                    return 'Please enter a stream name.';
                  }

                  return true;
                },
              },
            })}
          />
        </Field>
        <IconButton
          icon="delete"
          label="Remove row"
          className={c('row-button')}
          onClick={() => onRemove(index)}
          disabled={isOnlyRow}
        />
      </div>

      {uriError && <p className="form-error theme danger">{uriError}</p>}
      {nameError && <p className="form-error theme danger">{nameError}</p>}
    </fieldset>
  );
}
