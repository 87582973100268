import { UseQueryOptions } from '@tanstack/react-query';

import Gateway from 'types/gateway';
import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export function useGateway(
  id?: Gateway['id'],
  { enabled, ...options }: UseQueryOptions<Gateway, APIError> = {}
) {
  const api = useAPI();

  return useAuthenticatedQuery<Gateway>(
    ['gateway', id],
    () => {
      if (!id) {
        return Promise.reject(new Error('No gateway ID provided.'));
      }

      return api.gateways.read(id);
    },
    {
      ...options,
      enabled: enabled !== undefined ? enabled && Boolean(id) : Boolean(id),
    }
  );
}
