import { PipelineNode } from 'pipelines/types/pipeline_node';

import { isObject } from 'services/object';

export function validatePipelineNode(node: PipelineNode) {
  const propertiesValidation = validatePipelineNodeProperties(node.properties);
  const hasValidProperties = propertiesValidation === true;

  if (!hasValidProperties) {
    return propertiesValidation;
  }

  const wiresValidation = validatePipelineNodeWires(node.wires);
  const hasValidWires = wiresValidation === true;

  if (!hasValidWires) {
    return wiresValidation;
  }

  return true;
}

export function validatePipelineNodeProperties(
  properties: unknown
): Error | true {
  if (!properties) {
    return Error('Node does not define any properties.');
  }

  if (!isObject(properties)) {
    return Error('Node does not define valid properties.');
  }

  if (!('type' in (properties as object))) {
    return Error('Node does not specify a type.');
  }

  return true;
}

export function validatePipelineNodeWires(wires: unknown): Error | true {
  if (!wires) {
    return Error('Node does not define any wires.');
  }

  if (!isObject(wires)) {
    return Error('Node does not define valid wires.');
  }

  return true;
}
