import React from 'react';
import c from 'classnames';

import { Icon, IconType } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import { FormErrorMessage } from './FormErrorMessage';

export type FormMessageProps = React.PropsWithChildren<{
  className?: string;
  icon?: IconType;
  intent?: 'danger' | 'warning' | 'success' | 'info';
}>;

export function FormMessage({
  children,
  className,
  icon,
  intent,
}: FormMessageProps) {
  return (
    <Text
      className={c('form-message', className, { [`theme ${intent}`]: intent })}
      type="paragraph"
      asChild
    >
      <div>
        <div className={c('form-message-content')}>
          {icon && <Icon name={icon} />}
          <span>{children}</span>
        </div>
      </div>
    </Text>
  );
}

export { FormErrorMessage };
