import {
  createModelSchema,
  deserialize,
  identifier,
  list,
  primitive,
  raw,
  serialize,
} from 'serializr';
import {
  NodesData,
  OutputConnectionData,
  OutputsData,
  Data as ReteEditorData,
} from 'rete/types/core/data';
import { captureException, captureMessage } from '@sentry/react';

import Node from 'types/node';
import PipelineLock from 'types/pipeline_lock';
import PipelineTemplate from 'types/pipeline_template';

import ReteInput from 'pipelines/services/rete/controls/input';
import { objectOrNull } from 'services/serializr';
import { objectHas } from 'services/object';
import { ComponentType } from 'pipelines/services/rete/nodes/component';
import { getComponentByExportType } from 'pipelines/services/rete/nodes';
import { hasPopulatedCategories } from 'pipelines/services/rete/nodes/categories';
import { PipelineNode } from 'pipelines/types/pipeline_node';
import { validatePipelineNode } from 'pipelines/services/validate_pipeline_node';
import { removeInvalidPipelineNodes } from 'pipelines/services/remove_invalid_pipeline_nodes';

export type PipelineDefinitionNode = PipelineNode;

export type PipelineDefinition = PipelineNode[];

export type DeployTimeEditableNode = {
  nodeID: string;
  controls: Pick<ReteInput, 'key' | 'deploymentParam'>[];
};

export default class Pipeline {
  id: string;
  name: string;
  application_id: string;
  definition: PipelineDefinition;
  edit_lock: PipelineLock | null = null;
  /** ISO date string */
  created_at: string;
  /** ISO date string */
  updated_at: string;

  /** Console only fields */
  private cachedReteDefinition?: ReteEditorData;
  nodeDescriptions: Record<string, string> = {};
  deployTimeEditableNodes?: DeployTimeEditableNode[];

  constructor(
    id: string,
    name: string,
    applicationID: string,
    definition: PipelineDefinition
  ) {
    this.id = id;
    this.name = name;

    // usually the dates come from the deserialized API response,
    // so these values are mostly just a fallback for locally created pipelines
    this.created_at = new Date().toISOString();
    this.updated_at = new Date().toISOString();

    this.application_id = applicationID;
    this.definition = definition;
  }

  static fromTemplate(template?: PipelineTemplate): Pipeline {
    return Pipeline.fromDefinition(template?.definition || []);
  }

  get reteDefinition(): ReteEditorData {
    if (this.cachedReteDefinition !== undefined) {
      return this.cachedReteDefinition;
    }

    const reteDefinition = lumeoToReteDefinition(this.definition);
    if (reteDefinitionIsValid(reteDefinition)) {
      this.cachedReteDefinition = reteDefinition;
    }

    return reteDefinition;
  }

  get nodes(): Node[] {
    return Object.values(this.reteDefinition.nodes).map(
      Node.fromReteDefinition
    );
  }

  // Returns nodes by their ids with inputReference and outputReferences populated so that they
  // are connected as an in-memory graph.
  get nodeGraph(): Map<string, Node> {
    const nodes = this.getNodesById();
    nodes.forEach((node) => node.connectNodeReferences(nodes));
    return nodes;
  }

  private getNodesById(): Map<string, Node> {
    return this.nodes.reduce(
      (previous, current) => previous.set(current.id!, current),
      new Map<string, Node>()
    );
  }

  get isLocked(): boolean {
    return Boolean(this.edit_lock);
  }

  get hasSourceNode(): boolean {
    return this.definition.some((node) => {
      const cmp = getComponentByExportType(node.properties.type);
      return !cmp?.input?.type;
    });
  }

  get disconnectedNodes(): PipelineDefinitionNode[] {
    const connections = this.definition
      .map((node) => Object.values(node.wires))
      .flat(2)
      .filter((connection) => connection);

    return this.definition.filter((node) => {
      const cmp = getComponentByExportType(node.properties.type);
      let isConnected = true;

      if (!cmp) {
        return !isConnected;
      }

      if (cmp.input?.type) {
        isConnected = connections.some((io) => io.includes(node.id));
      }

      return !isConnected;
    });
  }

  hasNodeWithID(id: string): boolean {
    return this.nodes.some((node) => node.id === id);
  }

  static fromDefinition(definition: PipelineDefinition): Pipeline {
    if (!Array.isArray(definition)) {
      throw new Error(
        `Node definition must be an array, was ${typeof definition}`
      );
    }

    definition.forEach((node) => {
      const validation = validatePipelineNode(node);

      if (validation !== true) {
        throw validation;
      }
    });

    // FIXME: Don't set the name to undefined. Currently it's necessary though so that api-server can automatically assign a name on creation.
    return new Pipeline('', undefined as any, '', definition);
  }

  static async fromReteDefinition(
    reteDefinition: ReteEditorData
  ): Promise<Pipeline> {
    const lumeoDefinition = await reteToLumeoDefinition(reteDefinition);

    return Pipeline.fromDefinition(lumeoDefinition);
  }

  static withInvalidNodesRemoved(pipeline: Pipeline) {
    const restoredPipeline = pipeline.copy();
    restoredPipeline.definition = removeInvalidPipelineNodes(
      pipeline.definition
    );
    return restoredPipeline;
  }

  async updateFromReteDefinition(
    reteDefinition: ReteEditorData
  ): Promise<void> {
    this.resetCaches();

    if (reteDefinitionIsValid(reteDefinition)) {
      this.cachedReteDefinition = reteDefinition;
    }

    if (this.deployTimeEditableNodes === undefined) {
      return;
    }

    this.definition = await reteToLumeoDefinition(
      reteDefinition,
      this.deployTimeEditableNodes,
      this.nodeDescriptions
    );
  }

  updateDeployTimeEditableNodesFromDefinition() {
    const nodesFromDefinition = getDeployTimeEditableNodesFromDefinition(
      this.definition
    );

    /**
     * Lines wrapped in "---" migrate older pipelines that existed
     * before `deploymentParam = 'never'`got introduced to the `code` property
     *
     * Added 2022-11-22
     */
    // -----------------------------------------------------------------
    nodesFromDefinition.forEach((definitionNode) => {
      definitionNode.controls.forEach((control) => {
        if (control.key === 'code') {
          control.deploymentParam = false;
        }
      });
    });
    // -----------------------------------------------------------------

    const nodesFromPipeline = this.deployTimeEditableNodes || [];

    nodesFromPipeline.map((pipelineNode) => {
      const definitionNode = nodesFromDefinition.find(
        (node) => node.nodeID === pipelineNode.nodeID
      );

      if (definitionNode) {
        return {
          nodeID: pipelineNode.nodeID,
          controls: definitionNode.controls,
        };
      }

      return pipelineNode;
    });

    nodesFromDefinition.forEach(({ nodeID, controls }) => {
      const hasNode = nodesFromPipeline.some(
        (pipelineNode) => nodeID === pipelineNode.nodeID
      );

      if (!hasNode) {
        nodesFromPipeline.push({ nodeID, controls });
      }
    });

    this.deployTimeEditableNodes = nodesFromPipeline;
  }

  updateNodeDescriptionsFromDefinition() {
    const definition = this.definition.filter(({ extension }) =>
      Boolean(extension?.console?.description)
    );

    this.nodeDescriptions = Object.fromEntries(
      definition.map(({ id, extension }) => [
        id,
        extension?.console?.description,
      ])
    ) as Record<string, string>;
  }

  private resetCaches() {
    this.cachedReteDefinition = undefined;
  }

  copy(): Pipeline {
    // NOTE: Typescript chooses the wrong overload here, that's why it thinks that Pipeline[] is returned instead of Pipeline
    return deserialize(Pipeline, serialize(this)) as unknown as Pipeline;
  }

  withName(name: string) {
    const newPipeline = this.copy();
    newPipeline.name = name;
    return newPipeline;
  }

  hasId(): boolean {
    // the id is assigned by api-server,
    return this.id !== undefined;
  }

  containsUnreleasedComponents(components: ComponentType[]) {
    return this.definition.some((node) => {
      const component = components.find(
        ({ exportType }) => exportType === node.properties.type
      );
      return Boolean(component?.status !== 'available');
    });
  }
}

function lumeoToReteDefinition(
  lumeoDefinition: PipelineDefinition
): ReteEditorData {
  // Convert each Lumeo node to a Rete node without inputs
  const nodes: NodesData = {};

  let definition = lumeoDefinition;

  if (typeof lumeoDefinition === 'string') {
    try {
      definition = JSON.parse(lumeoDefinition);
      captureMessage('Parsed JSON string pipeline definition from API', {
        extra: {
          definition: lumeoDefinition,
        },
        level: 'warning',
      });
    } catch (error) {
      captureException(error, {
        extra: {
          definition: lumeoDefinition,
        },
      });
      definition = [];
    }
  }

  for (const lumeoNode of definition) {
    const { id, extension, properties, wires } = lumeoNode;
    const component = getComponentByExportType(properties.type);
    if (component === undefined) {
      console.warn(
        `Failed to find component of type '${
          properties.type
        }'. Node categories were${
          hasPopulatedCategories() ? '' : "n't"
        } populated.`
      );
    }

    const name = component ? new component().name : null;
    const { type, ...data } = properties;
    const { x, y } = extension?.console?.position ?? { x: 0, y: 0 };

    nodes[id] = {
      id: id as any, // FIXME: Rete expects a number here but we have a string
      data,
      name: name as any, // FIXME: Rete expects a string here but we have string | null
      outputs: lumeoToReteOutputs(wires),
      inputs: {},
      position: [x, y],
    };
  }

  connectReteNodeInputsFromOutputs(nodes);

  // The ID is arbitrary
  return { id: 'demo@0.1.0', nodes };
}

function getDeployTimeEditableNodesFromDefinition(
  lumeoDefinition: PipelineDefinition
): DeployTimeEditableNode[] {
  const deployTimeEditableNodes: DeployTimeEditableNode[] = [];

  for (const lumeoNode of lumeoDefinition) {
    const { id, extension } = lumeoNode;
    const controls = extension?.console.deployTimeEditableControls;

    if (controls) {
      deployTimeEditableNodes.push({ nodeID: id, controls });
    }
  }
  return deployTimeEditableNodes;
}

function lumeoToReteOutputs(lumeoOutputs: {
  [outputKey: string]: string[];
}): OutputsData {
  const outputs: OutputsData = {};

  for (const [output, wires] of Object.entries(lumeoOutputs)) {
    const connections: OutputConnectionData[] = [];

    for (const target of wires) {
      const [lumeoNodeID, input] = target.split('.', 2);
      connections.push({
        node: lumeoNodeID as any, // FIXME: Rete expects a number here but we have a string
        input,
        data: {},
      });
    }

    outputs[output] = { connections };
  }

  return outputs;
}

// Given rete nodes with only outputs, connects them to the corresponding inputs.
function connectReteNodeInputsFromOutputs(nodes: NodesData) {
  for (const sourceNode of Object.values(nodes)) {
    for (const [outputWire, { connections }] of Object.entries(
      sourceNode.outputs
    )) {
      for (const { node: nodeID, input } of connections) {
        const targetNode = nodes[nodeID];

        if (targetNode === undefined) {
          console.warn(
            `Found connection to non-existent node with id ${nodeID}. Ignoring!`
          );
          continue;
        }

        if (objectHas(targetNode.inputs, input)) {
          // FIXME: This is currently ignored the way it was previously but we log it to sentry.
          captureMessage(
            `Tried to connect output '${outputWire}' of node '${sourceNode.id}' to input '${input}' of node '${targetNode.id}' which was already connected.`,
            'error'
          );
        } else {
          targetNode.inputs[input] = { connections: [] };
        }
        targetNode.inputs[input].connections.push({
          node: sourceNode.id,
          output: outputWire,
          data: {},
        });
      }
    }
  }
}

async function reteToLumeoDefinition(
  reteDefinition: ReteEditorData,
  deployTimeEditableNodes?: DeployTimeEditableNode[],
  nodeDescriptions?: Record<string, string>
): Promise<PipelineDefinition> {
  if (typeof reteDefinition !== 'object') {
    throw new Error(
      `definition must be an object, was ${typeof reteDefinition}`
    );
  }

  const nodes = reteDefinitionToNodes(reteDefinition);
  return (await Promise.all(
    nodes.map((node) =>
      node.toLumeoDefinition(
        deployTimeEditableNodes?.find(({ nodeID }) => node.id === nodeID)
          ?.controls,
        nodeDescriptions
      )
    )
  )) as PipelineDefinition;
}

function reteDefinitionToNodes(reteDefinition: ReteEditorData): Node[] {
  return Object.values(reteDefinition.nodes).map((node) =>
    Node.fromReteDefinition(node)
  );
}

function reteDefinitionIsValid(definition: ReteEditorData): boolean {
  // A node is invalid if it doesn't have a name. This usually happens when the node categories haven't been populated yet.
  return !Object.values(definition.nodes).some((node) => !node.name);
}

createModelSchema(Pipeline, {
  id: identifier(),
  application_id: primitive(),
  name: primitive(),
  definition: raw(),
  created_at: primitive(),
  updated_at: primitive(),
  edit_lock: objectOrNull(PipelineLock),
  nodeDescriptions: raw(),
  deployTimeEditableNodes: list(raw()),
});
