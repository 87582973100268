import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Pipeline from 'types/pipeline';
import { DeploymentQueue, DeploymentQueueEntry } from 'types/deployment_queue';
import { OffsetPaginated, OffsetPaginationParams } from 'types/pagination';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export type UseDeploymentsQueueEntriesParams = OffsetPaginationParams & {
  entry_ids?: DeploymentQueueEntry['id'][];
  deployment_names?: string[];
  pipeline_ids?: Pipeline['id'];
};

type UseDeploymentsQueueEntriesOptions = UseQueryOptions<
  OffsetPaginated<DeploymentQueueEntry>,
  APIError
> & {
  params?: UseDeploymentsQueueEntriesParams;
};

export function useDeploymentsQueueEntries(
  queueId: DeploymentQueue['id'] | undefined,
  { params, enabled, ...options }: UseDeploymentsQueueEntriesOptions = {}
) {
  const { applicationID, client } = useAPI();

  const queryParams = {
    pagination: 'offset',
    page: 1,
    ...params,
  };

  return useAuthenticatedQuery(
    ['deployment-queue-entries', queueId, queryParams, applicationID],
    async () => {
      if (!queueId) {
        return Promise.reject('No queue id provided.');
      }

      const { data } = await client.get<OffsetPaginated<DeploymentQueueEntry>>(
        `/apps/${applicationID}/deployment_queues/${queueId}/entries`,
        {
          params: queryParams,
        }
      );

      return data;
    },
    {
      ...options,
      enabled:
        enabled !== undefined ? Boolean(queueId) && enabled : Boolean(queueId),
    }
  );
}
