import Rete, { Node } from 'rete';

import Component from 'pipelines/services/rete/nodes/component';
import { MultiplexedVideo, RawVideo } from 'pipelines/services/rete/sockets';
import ReteSocketControl from 'pipelines/services/rete/controls/socket_control';

import { RESOLUTION_PRESETS } from 'components/ResolutionInput/ResolutionInput';

import { ResolutionInput } from '../controls/configured_inputs';

export default class MultiplexVideo extends Component {
  static key = 'Multiplex Video';
  static icon = 'merge' as const;
  static exportType = 'multiplex';
  static category = 'transformations' as const;
  static input = {
    type: 'raw',
  };
  static inputParams = [RawVideo, false] as const;
  static output = {
    type: 'multiplexed',
  };
  static dynamicSocketKeys = {
    input: 'num_streams',
  };

  static description =
    'Interleave two or more streams into one multiplexed stream for optimized processing.';

  constructor() {
    super(MultiplexVideo.key);
  }

  async builder(node: Node) {
    node
      .addControl(
        new ReteSocketControl(this.editor!, 'num_streams', {
          label: 'Number input streams',
          initial: 2,
        })
      )
      .addControl(
        ResolutionInput(this.editor!, {
          key: 'resolution',
          presets: RESOLUTION_PRESETS,
        })
      )
      // inputs added by DynamicSockets plugin
      .addOutput(new Rete.Output('output', 'Output', MultiplexedVideo));
  }

  worker() {}
}
