import React from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';

import {
  DEPLOYMENT_ACTIVE_STATES,
  DEPLOYMENT_ERROR_STATES,
  DEPLOYMENT_INACTIVE_STATES,
} from 'types/deployment';

import { useDeployments } from 'hooks/api/useDeployments';

import { Card } from 'components/Card/Card';
import { Heading } from 'components/Heading/Heading';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { DashboardNumber } from 'application/views/Overview/Number/Number';
import styles from './Overview.module.scss';

const c = classnames.bind(styles);

export function OverviewDeployments() {
  const { data: active } = useDeployments({
    limit: 1,
    states: [...DEPLOYMENT_ACTIVE_STATES],
  });
  const { data: stopped } = useDeployments({
    limit: 1,
    states: [...DEPLOYMENT_INACTIVE_STATES],
  });
  const { data: error } = useDeployments({
    limit: 1,
    states: [...DEPLOYMENT_ERROR_STATES],
  });

  const activeCount = active ? active.total_elements : 0;
  const stoppedCount = stopped ? stopped.total_elements : 0;
  const errorCount = error ? error.total_elements : 0;

  return (
    <Card className={c('item')}>
      <Heading level="4" className={c('item-name')} asChild>
        <h3>
          <Link to="deploy/deployments" className={c('item-link')}>
            Deployments
          </Link>
        </h3>
      </Heading>

      <dl className={c('item-deployment-numbers')}>
        <DashboardNumber
          number={activeCount}
          status="running"
          to="deploy/deployments"
          state={{
            filters: {
              state: 'running',
            },
          }}
        />
        <DashboardNumber
          number={errorCount}
          status="offline"
          to="deploy/deployments"
          state={{
            filters: {
              state: 'error',
            },
          }}
        />
        <DashboardNumber
          number={stoppedCount}
          status="stopped"
          to="deploy/deployments"
          state={{
            filters: {
              state: 'stopped',
            },
          }}
        />
      </dl>

      <div className={c('item-graph')} aria-hidden="true">
        {activeCount > 0 && (
          <Tooltip side="top" content={`${activeCount} deployments running`}>
            <div
              className={c('item-graph-line')}
              style={{ '--count': activeCount } as React.CSSProperties}
            >
              <div className={c('item-graph-line-fill', 'theme success')}>
                <Link
                  className={c('item-graph-link')}
                  to="deploy/deployments"
                  state={{
                    filters: {
                      state: 'running',
                    },
                  }}
                />
              </div>
            </div>
          </Tooltip>
        )}
        {errorCount > 0 && (
          <Tooltip
            side="top"
            content={`${errorCount} deployments in error state`}
          >
            <div
              className={c('item-graph-line')}
              style={{ '--count': errorCount } as React.CSSProperties}
            >
              <div className={c('item-graph-line-fill', 'theme danger')}>
                <Link
                  className={c('item-graph-link')}
                  to="deploy/deployments"
                  state={{
                    filters: {
                      state: 'error',
                    },
                  }}
                />
              </div>
            </div>
          </Tooltip>
        )}
        {stoppedCount > 0 && (
          <Tooltip side="top" content={`${stoppedCount} deployments stopped`}>
            <div
              className={c('item-graph-line')}
              style={{ '--count': stoppedCount } as React.CSSProperties}
            >
              <div className={c('item-graph-line-fill', 'theme warning')}>
                <Link
                  className={c('item-graph-link')}
                  to="deploy/deployments"
                  state={{
                    filters: {
                      state: 'stopped',
                    },
                  }}
                />
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    </Card>
  );
}
