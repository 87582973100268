import classnames from 'classnames/bind';
import { useClipboard } from '@mantine/hooks';
import { CellProps } from 'react-table';

import Stream, { StreamType } from 'types/stream';

import { Button } from 'components/Button/Button';
import {
  ContextMenu,
  ContextMenuAction,
  ContextMenuSeparator,
} from 'components/ContextMenu/ContextMenu';
import { IconButton } from 'components/IconButton/IconButton';
import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './Table.module.scss';

const c = classnames.bind(styles);

export type StreamsTableActionsCellProps = CellProps<Stream> & {
  onEmbedClick?: (streamID: string) => void;
  onRenameClick?: (streamID: string) => void;
  onDeleteClick?: (streamID: string) => void;
};

export function StreamsTableActionsCell({
  row,
  onEmbedClick,
  onRenameClick,
  onDeleteClick,
}: StreamsTableActionsCellProps) {
  const stream = row.original;

  const { copied: isCopied, copy } = useClipboard({ timeout: 2000 });

  function handleRenameClick() {
    if (!stream.id) {
      return;
    }

    onRenameClick?.(stream.id);
  }

  function handleDeleteClick() {
    if (!stream.id) {
      return;
    }

    onDeleteClick?.(stream.id);
  }

  return (
    <div className={c('stream-actions', 'columns halign-end valign-center')}>
      {stream.uri && (
        <Tooltip content="Copied to clipboard!" side="right" open={isCopied}>
          <Button
            variant="ghost"
            size="xsmall"
            onClick={() => {
              copy(stream.uri!);
            }}
          >
            Copy URL
          </Button>
        </Tooltip>
      )}

      {stream.stream_type === StreamType.WEBRTC && onEmbedClick && (
        <Button
          variant="secondary"
          onClick={() => onEmbedClick(stream.id as string)}
          size="xsmall"
        >
          Embed
        </Button>
      )}

      {(onRenameClick || onDeleteClick) && (
        <ContextMenu
          trigger={
            <IconButton
              icon="more-vertical"
              label="Reveal more actions"
              variant="ghost"
            />
          }
        >
          {onRenameClick && (
            <ContextMenuAction onClick={handleRenameClick} icon="text">
              Rename
            </ContextMenuAction>
          )}
          {onRenameClick && onDeleteClick && <ContextMenuSeparator />}
          {onDeleteClick && (
            <ContextMenuAction
              onClick={handleDeleteClick}
              icon="delete"
              intent="danger"
            >
              Delete
            </ContextMenuAction>
          )}
        </ContextMenu>
      )}
    </div>
  );
}
