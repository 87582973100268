import React from 'react';
import classnames from 'classnames/bind';
import { Column } from 'react-table';

import OrganizationInvitation from 'types/invitation';

import { EmptyView } from 'components/EmptyView/EmptyView';
import {
  PaginatedTable,
  PaginatedTableProps,
} from 'components/Table/PaginatedTable';

import { ApplicationRoleCell, OrganizationRoleCell } from '../Cell';
import { ApplicationRolesHeader, OrganizationRolesHeader } from '../Header';
import { OrganizationInvitationCell } from './Cell';
import styles from '../Table.module.scss';

const c = classnames.bind(styles);

export type OrganizationInvitationsTableProps = Pick<
  PaginatedTableProps<OrganizationInvitation>,
  'queryResult' | 'pageSize' | 'page' | 'onPageChange'
>;

export function OrganizationInvitationsTable(
  props: OrganizationInvitationsTableProps
) {
  const columns = React.useMemo<Column<OrganizationInvitation>[]>(() => {
    return [
      {
        Header: 'Member',
        Cell: OrganizationInvitationCell,
      },
      {
        Header: 'Organization',
        Cell: OrganizationRolesHeader,
        columns: [
          {
            Header: 'Owner',
            Cell(props) {
              return <OrganizationRoleCell props={props} role="owner" />;
            },
          },
          {
            Header: 'Billing',
            Cell(props) {
              return <OrganizationRoleCell props={props} role="billing" />;
            },
          },
          {
            Header: 'Manager',
            Cell(props) {
              return <OrganizationRoleCell props={props} role="manager" />;
            },
          },
        ],
      },
      {
        Header: 'Application',
        Cell: ApplicationRolesHeader,
        columns: [
          {
            Header: 'Design',
            Cell(props) {
              return <ApplicationRoleCell props={props} role="designer" />;
            },
          },
          {
            Header: 'Deploy',
            Cell(props) {
              return <ApplicationRoleCell props={props} role="deployer" />;
            },
          },
          {
            Header: 'Monitor',
            Cell(props) {
              return <ApplicationRoleCell props={props} role="monitor" />;
            },
          },
        ],
      },
    ];
  }, []);

  return (
    <PaginatedTable<OrganizationInvitation>
      {...props}
      id="invited-members-table"
      className={c('table')}
      label="Invitations"
      columns={columns}
      emptyMessage={<EmptyView>No pending invitations.</EmptyView>}
    />
  );
}
