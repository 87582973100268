import { NodeEditor } from 'rete';

import ReteInput, {
  CommonReteInputParams,
} from 'pipelines/services/rete/controls/input';

import {
  VideoSourceROI,
  VideoSourceROIValue,
} from 'pipelines/components/VideoSourceROI/VideoSourceROI';

export type ReteROIInputParams = CommonReteInputParams & {
  type?: 'line' | 'polygon';
  roiLabel?: string;
};

export default class ReteROIInput extends ReteInput {
  constructor(
    editor: NodeEditor,
    key: string,
    { type, roiLabel, ...params }: ReteROIInputParams
  ) {
    type = type === 'line' || type === 'polygon' ? type : 'line';
    super(editor, key, { type, ...params });
    this.props.type = type;
    this.props.roiLabel = roiLabel;
  }

  get component(): any {
    return VideoSourceROI;
  }
}

export function serializeROI(
  key: string,
  keyref: string | undefined,
  value: VideoSourceROIValue,
  setValue: (key: string, value: any) => void
) {
  if (!value) {
    return;
  }

  const coordinates = value.coordinates?.join(';');
  setValue(key, coordinates || null);

  if (keyref) {
    setValue(keyref, value.labels?.join(';') || null);
  }

  return coordinates;
}
