import classnames from 'classnames/bind';
import { components, IndicatorSeparatorProps } from 'react-select';

import styles from '../Select.module.scss';

const c = classnames.bind(styles);

export function IndicatorSeparator<
  OptionType,
  MultiSelect extends boolean = false
>(
  props: React.PropsWithChildren<
    IndicatorSeparatorProps<OptionType, MultiSelect>
  >
) {
  return (
    <components.IndicatorSeparator
      {...props}
      className={c('indicator-separator')}
    />
  );
}
