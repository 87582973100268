import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { updateQueriesData } from 'services/update_queries_data';

import { useAPI } from 'hooks/api/useAPI';
import { useAccount } from 'hooks/api/useAccount';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';

import APIError from 'types/api_error';
import Account from 'types/account';
import { isOrganizationRole, OrganizationRole } from 'types/account_role';

type DeleteOrganizationRolesVariables = {
  account: Account;
  deletedRoles: OrganizationRole[];
};

/** Remove organization roles from a member */
export function useDeleteOrganizationRoles({
  onMutate,
  onSettled,
  onError,
  ...options
}: UseMutationOptions<void, APIError, DeleteOrganizationRolesVariables> = {}) {
  const queryClient = useQueryClient();
  const { applicationID, organizations } = useAPI();
  const { data: currentAccount } = useAccount();

  const { data: organization } = useCurrentOrganization();
  const organizationId = organization?.id;

  return useDangerousMutation(
    'Are you sure you want to remove this role from the member?',
    ({ account, deletedRoles }) => {
      if (!organizationId || !account.id) {
        return Promise.reject('Missing organization or account id');
      }

      return organizations.members.deleteOrganizationRoles(
        organizationId,
        account.id,
        {
          roles: deletedRoles,
        }
      );
    },
    {
      ...options,
      action: 'remove',
      entity: 'role',
      onMutate({ account, deletedRoles }) {
        onMutate?.({ account, deletedRoles });

        if (!organizationId || !account.id) {
          return;
        }

        if (currentAccount && currentAccount.id === account.id) {
          queryClient.cancelQueries(['account']);
        }

        queryClient.cancelQueries(['members']);
        queryClient.cancelQueries(['member', account.id, applicationID]);

        const newAccount = account.copy();

        newAccount.roles = newAccount.roles.filter(({ name }) => {
          return isOrganizationRole(name) ? !deletedRoles.includes(name) : true;
        });

        return updateQueriesData({
          queryClient,
          listQueryKey: ['members'],
          singleQueryKey: ['member'],
          ids: [account.id],
          updateData: newAccount,
        });
      },
      onError(error, variables, context) {
        onError?.(error, variables, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(data, error, { account, deletedRoles }, context) {
        onSettled?.(data, error, { account, deletedRoles }, context);

        if (currentAccount && currentAccount.id === account.id) {
          queryClient.invalidateQueries(['account']);
        }

        queryClient.invalidateQueries(['member', account.id, applicationID]);
        queryClient.invalidateQueries(['members']);
      },
    }
  );
}
