import AIModel from 'types/ai_model';

import type {
  Step1FieldValues,
  Step2FieldValues,
  Step3FieldValues,
} from '../components/MultiStepForm';

export type GetModelFromValuesData = Step1FieldValues &
  Partial<Step2FieldValues> &
  Partial<Step3FieldValues>;

export function getUpdatedModelFromValues(
  model: AIModel,
  {
    name,
    architecture,
    capability,
    description,
    gallery_img_url,

    inference_config,
    parameters,
  }: GetModelFromValuesData
) {
  const { class_attributes, input_blob_name } = inference_config || {};

  return AIModel.from({
    ...model,
    name: name || model.name,
    description: description || model.description,
    gallery_img_url: gallery_img_url || model.gallery_img_url,
    architecture: architecture || model.architecture,
    capability: capability || model.capability,
    inference_config: {
      ...model.inference_config,
      ...inference_config,
      input_blob_name:
        model.format === 'onnx' &&
        inference_config?.inference_engine === 'default'
          ? undefined
          : input_blob_name,
      class_attributes: {
        '*': {
          ...model.inference_config?.class_attributes?.['*'],
          ...class_attributes?.['*'],
        },
      },
    },
    parameters: parameters ?? model.parameters,
  });
}
