import React from 'react';

import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';

import { VideoSourceSelection } from 'pipelines/services/rete/controls/video_source_selector';
import { VideoSource } from 'hooks/api/useVideoSources';

export type DeploymentVideoSource = {
  [nodeId: string]: VideoSourceSelection & { deployment_id?: string };
};

export type DeploymentFormContextProps = {
  pipeline: Pipeline | null;
  setPipeline: (pipeline: Pipeline | null) => void;

  deployment?: Deployment;
  readonly?: boolean;
  allowedGatewayIds?: string[];
  /** Selected gateway */
  gateway?: Gateway | null;
  setGateway?: React.Dispatch<React.SetStateAction<Gateway | null>>;

  isLoading: boolean;

  allowedStreamIds?: string[];
  allowedCameraIds?: string[];

  /**
   * Preselected video source via rapid deploy
   */
  initialSource?: VideoSource;

  sources: DeploymentVideoSource;
  setSources: React.Dispatch<React.SetStateAction<DeploymentVideoSource>>;
};

export type OptionalDeploymentFormContextProps =
  | DeploymentFormContextProps
  | Partial<DeploymentFormContextProps>;

export const DeploymentFormContext = React.createContext<
  DeploymentFormContextProps | undefined
>(undefined);

export function useDeploymentForm() {
  const context = React.useContext(DeploymentFormContext);

  if (context === undefined) {
    throw new Error(
      'Hook `useDeploymentForm` can only be used within a DeploymentFormProvider.'
    );
  }

  return context;
}

export function useDeploymentFormOptional(): OptionalDeploymentFormContextProps {
  const context = React.useContext(DeploymentFormContext);
  return context || {};
}
