import classnames from 'classnames/bind';
import { useClipboard } from '@mantine/hooks';

import Stream from 'types/stream';

import { relativeTimeFromNow } from 'services/date';

import { Button } from 'components/Button/Button';
import { Heading } from 'components/Heading/Heading';
import {
  MediaPreview,
  MediaPreviewSkeleton,
} from 'components/MediaPreview/MediaPreview';
import { Text } from 'components/Text/Text';
import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './List.module.scss';

const c = classnames.bind(styles);

export type StreamsListItemProps = {
  stream: Stream;
};

export function StreamsListItem({ stream }: StreamsListItemProps) {
  const { copied: isCopied, copy } = useClipboard({ timeout: 2000 });

  const time = relativeTimeFromNow(stream.updated_at);
  const info = () => {
    let text;

    // TODO: Retrieve this information
    if (stream.source === 'pipeline_stream') {
      text = 'Created by pipeline node';
    }

    if (text) {
      return `• ${text}`;
    }
  };

  return (
    <article className={c('stream')}>
      <MediaPreview className={c('stream-preview')} stream={stream} />
      <div>
        <Heading level="4" className={c('stream-title')}>
          {stream.name}
        </Heading>
        <Text className={c('stream-info')}>
          {time} {info()}
        </Text>

        <div className={c('stream-buttons')}>
          {stream.uri && (
            <span className={c('stream-button')}>
              <Tooltip
                content="Copied to clipboard!"
                side="right"
                open={isCopied}
              >
                <Button
                  variant="ghost"
                  size="xsmall"
                  onClick={() => {
                    copy(stream.uri!);
                  }}
                >
                  Copy URL
                </Button>
              </Tooltip>
            </span>
          )}
        </div>
      </div>
    </article>
  );
}

export function StreamsListItemSkeleton() {
  return (
    <div className={c('stream', 'skeleton')}>
      <MediaPreviewSkeleton className={c('stream-preview')} />

      <div>
        <Heading level="4" className={c('stream-title', 'skeleton-text')} />
        <Text className={c('stream-info', 'skeleton-text')} />

        <div className={c('stream-buttons')}>
          <Button variant="secondary" size="small" disabled>
            Embed
          </Button>
          <Button variant="tertiary" size="small" disabled>
            Copy URL
          </Button>
        </div>
      </div>
    </div>
  );
}
