import { UseQueryOptions } from '@tanstack/react-query';

import {
  TimerSeriesResponse,
  TIMESERIES,
  TimeSeriesQueryVariables,
} from 'graphql/queries/timeseries';

import { usePropelQuery } from 'graphql/hooks/usePropelQuery';

export function useTimeSeries(
  variables: TimeSeriesQueryVariables,
  options?: UseQueryOptions<TimerSeriesResponse>
) {
  return usePropelQuery<
    TimerSeriesResponse,
    { input: TimeSeriesQueryVariables }
  >(TIMESERIES, { input: variables }, ['timeseries'], options);
}
