import React from 'react';
import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';

import { useAuthentication } from 'hooks/api/useAuthentication';
import {
  useAcceptAndCreateAccount,
  useAcceptInvitation,
  useInvitationHasAccount,
} from 'hooks/api/useInvitations';

import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { Loader } from 'components/Loader/Loader';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { Text } from 'components/Text/Text';

import { InviteWrapper } from './Invite';

import styles from '../Auth.module.scss';

const c = classNames.bind(styles);

type PasswordFieldValues = { password: string };

export function Accept() {
  const { search, pathname } = useLocation();
  const { logout } = useAuthentication();
  const params = new URLSearchParams(search);
  const token = params.get('token');

  const [didFetchAccount, setDidFetchAccount] = React.useState(false);

  const { isInitialLoading: isLoadingHasAccount, error: hasAccountError } =
    useInvitationHasAccount(token as string, {
      retry: false,
      staleTime: Infinity,
      enabled: !didFetchAccount && token !== null,
      onSettled() {
        setDidFetchAccount(true);
      },
    });

  const {
    mutate: accept,
    error: acceptError,
    isLoading: isLoadingAccept,
    isSuccess: isAcceptInvitationSuccess,
  } = useAcceptInvitation();

  const {
    mutate: acceptAndCreate,
    error: acceptAndCreateError,
    isLoading: isLoadingAcceptAndCreate,
    isSuccess: isAcceptAndCreateAccountSuccess,
  } = useAcceptAndCreateAccount();

  const isInvitationSuccess =
    isAcceptInvitationSuccess || isAcceptAndCreateAccountSuccess;

  const { formState, handleSubmit, register } = useForm<PasswordFieldValues>();

  function createAccount({ password }: PasswordFieldValues) {
    acceptAndCreate({ token: token as string, password, name: '' });
    logout();
  }

  if (isLoadingHasAccount) {
    return (
      <InviteWrapper>
        <Loader text="Loading..." />
      </InviteWrapper>
    );
  }

  if (!token || Array.isArray(token) || hasAccountError?.status === 400) {
    return (
      <InviteWrapper>
        <div className={c('auth-text')}>
          <Heading level="1">Invalid invitation</Heading>
        </div>
        <div className={c('auth-text')}>
          <Text type="paragraph">This invitation link is not valid.</Text>
        </div>
      </InviteWrapper>
    );
  }

  if (
    hasAccountError?.status !== undefined &&
    hasAccountError?.status >= 500 &&
    hasAccountError?.status <= 599
  ) {
    return (
      <InviteWrapper>
        <div className={c('auth-text')}>
          <Heading level="1">Server Error</Heading>
        </div>
        <FormErrorMessage error={hasAccountError} />
        <div className={c('auth-text')}>
          <Text type="paragraph">Please try again later.</Text>
        </div>
      </InviteWrapper>
    );
  }

  if (isInvitationSuccess) {
    return (
      <InviteWrapper>
        <div className={c('auth-text')}>
          <Heading level="1">Invitation accepted</Heading>
          <Text type="paragraph">
            Please sign in using your email and password.
          </Text>
        </div>
        <Button className={c('auth-button')} variant="primary" to="/login">
          Login
        </Button>
      </InviteWrapper>
    );
  }

  if (hasAccountError?.status === 404) {
    return (
      <InviteWrapper>
        <div className={c('auth-text')}>
          <Heading level="1">Accept invitation</Heading>
        </div>
        <form onSubmit={handleSubmit(createAccount)}>
          <Field
            id="password"
            label="Choose a password"
            error={formState.errors.password}
          >
            <PasswordInput
              id="password"
              {...register('password', {
                required: 'Please enter your password.',
              })}
              autoFocus
            />
          </Field>

          <FormErrorMessage error={acceptAndCreateError} />

          {isLoadingAcceptAndCreate && (
            <Loader text="Accepting invitation and creating account..." />
          )}
          <Button
            className={c('auth-button')}
            type="submit"
            variant="primary"
            loading={isLoadingAcceptAndCreate}
          >
            Continue
          </Button>
          <div className={c('auth-text-secondary')}>
            <Link
              to={`${pathname.replace('accept', 'decline')}?token=${token}`}
              className={c('link')}
            >
              Did you mean to decline instead?
            </Link>
          </div>
        </form>
      </InviteWrapper>
    );
  }

  return (
    <InviteWrapper>
      <div className={c('auth-text')}>
        <Heading level="1">Accept invitation</Heading>
        <Text type="paragraph">
          Accepting the invitation will grant you access to the invitee's
          organization stated in the invitation email.
        </Text>
      </div>
      <FormErrorMessage error={acceptError} />
      {isLoadingAccept && <Loader text="Accepting invitation..." />}
      <Button
        className={c('auth-button')}
        variant="primary"
        onClick={() => {
          accept({ token });
          logout();
        }}
        loading={isLoadingAccept}
      >
        Accept invitation
      </Button>
      <div className={c('auth-text-secondary')}>
        <Link
          to={`${pathname.replace('accept', 'decline')}?token=${token}`}
          className={c('link')}
        >
          Did you mean to decline instead?
        </Link>
      </div>
    </InviteWrapper>
  );
}
