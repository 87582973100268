import React from 'react';
import classNames from 'classnames/bind';
import { useClickOutside } from '@mantine/hooks';

import { PADDING } from './Label';
import { ROI_CONFIG } from '../config';
import styles from '../ROICanvas.module.scss';

const c = classNames.bind(styles);

export type ROILabelInputProps = {
  x: number;
  y: number;
  offsetX: number;
  offsetY: number;
  rotation: number;
  defaultValue: string;
  onValidate: (text: string) => boolean;
  onSave: (text: string) => void;
  onCancel: () => void;
};

export function ROILabelInput({
  x,
  y,
  offsetX,
  offsetY,
  rotation,
  defaultValue,
  onValidate,
  onSave,
  onCancel,
}: ROILabelInputProps) {
  const ref = useClickOutside(onCancel);

  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.focus();
    ref.current.select();
  }, [ref]);

  function handleChange() {
    setHasError(false);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    switch (event.key) {
      case 'Escape': {
        onCancel();
        setHasError(false);
        break;
      }

      case 'Enter': {
        if (onValidate(event.currentTarget.value)) {
          onSave(event.currentTarget.value);
          break;
        }

        setHasError(true);
        break;
      }
    }
  }

  return (
    <input
      className={c('input', { error: hasError })}
      defaultValue={defaultValue}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      style={
        {
          color: ROI_CONFIG.labelText.fill,
          fontSize: ROI_CONFIG.labelText.fontSize,
          backgroundColor: ROI_CONFIG.labelBg.fill,
          borderRadius: ROI_CONFIG.labelBg.cornerRadius,
          padding: PADDING,
          position: 'absolute',
          top: y,
          left: x,
          transformOrigin: '0 0',
          '--rotation': `${rotation}deg`,
          '--offsetX': `${-offsetX}px`,
          '--offsetY': `${-offsetY}px`,
        } as React.CSSProperties
      }
      ref={ref}
    />
  );
}
