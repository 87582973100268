import { useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { Input } from 'components/Input/Input';

import { FIELD_LABEL_WIDTH, Step2FieldValues } from '../Step2';

export type InputLayerDimensionsInputProps = {
  format: string;
};

export function InputLayerDimensionsInput({
  format,
}: InputLayerDimensionsInputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<Step2FieldValues>();

  const info =
    format !== 'onnx'
      ? 'Specify dimensions in the input layer order specified above.'
      : 'Specify dimensions in the order channels C, height H, width W (C;H;W).';

  return (
    <Field
      id="infer_dims"
      label="Input layer dimensions"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      info={info}
      error={errors.inference_config?.infer_dims}
      required
    >
      <Input
        id="infer_dims"
        placeholder="ex. 3;240;240"
        {...register('inference_config.infer_dims', {
          required: 'Please enter a value.',
        })}
      />
    </Field>
  );
}
