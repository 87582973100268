import React from 'react';

import Deployment from 'types/deployment';

import { useMqttDeploymentLogs } from 'deployments/hooks/useMqttDeploymentLogs';

import { Logs } from 'components/Logs/Logs';

export type DeploymentDetailLogsProps = {
  deployment: Deployment;
};

export function DeploymentLogs({ deployment }: DeploymentDetailLogsProps) {
  const logs = useMqttDeploymentLogs(deployment);

  const message = deployment.isActive
    ? 'Function node output will appear here...'
    : 'Start deployment to see function node output.';

  return <Logs logs={logs} emptyMessage={message} />;
}
