import { useForm } from 'react-hook-form';

import Account from 'types/account';

import { useUpdateAccountPassword } from 'hooks/api/useAccount';

import { Button } from 'components/Button/Button';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { Field } from 'components/Field/Field';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { FormErrorMessage } from 'components/FormMessage/FormMessage';

export type UpdatePasswordParams = {
  oldPassword: string;
  newPassword: string;
};

export type ChangePasswordFormProps = {
  account: Account;
};

export function ChangePasswordForm({ account }: ChangePasswordFormProps) {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<UpdatePasswordParams>();
  const {
    error,
    mutate: updatePassword,
    isLoading,
    isSuccess,
  } = useUpdateAccountPassword({
    onSuccess() {
      reset();
    },
  });

  if (!account) {
    return null;
  }

  function onSubmit({ oldPassword, newPassword }: UpdatePasswordParams) {
    if (!account) {
      return;
    }

    updatePassword({ oldPassword, newPassword });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        id="pw_old"
        label="Current password"
        error={errors.oldPassword}
        required
      >
        <PasswordInput
          {...register('oldPassword', {
            required: 'Please enter your current password.',
          })}
        />
      </Field>
      <Field
        id="pw_new"
        label="Choose a new password"
        error={errors.newPassword}
        required
      >
        <PasswordInput
          {...register('newPassword', {
            required: 'Please enter a new password.',
          })}
        />
      </Field>

      <FormErrorMessage error={error} />

      <div className="columns spaced valign-center">
        <Button type="submit" variant="primary" loading={isLoading}>
          Update password
        </Button>

        {isSuccess && (
          <>
            <br />
            <p>
              <InlineNotification intent="success">
                Successfully updated password.
              </InlineNotification>
            </p>
          </>
        )}
      </div>
    </form>
  );
}
