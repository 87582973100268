import {
  createModelSchema,
  identifier,
  list,
  object,
  primitive,
  raw,
  serializeAll,
} from 'serializr';

import { PipelineDefinition } from 'types/pipeline';

import Category from './category';

export interface IPipelineTemplate {
  id: string;
  name: string;
  description?: string;
  gallery_img_url?: string;
  definition: PipelineDefinition;

  created_at: string;
  updated_at: string;
}

@serializeAll
export class PipelineTemplateCategory {
  label: Category['label'];

  icon_name: Category['icon_name'];

  constructor(label: Category['label'], iconName: Category['icon_name']) {
    this.label = label;
    this.icon_name = iconName;
  }
}

export default class PipelineTemplate {
  id?: string;
  name: string;
  description?: string;
  gallery_img_url?: string;
  definition: PipelineDefinition;

  created_at: string;
  updated_at: string;

  categories: PipelineTemplateCategory[];

  constructor(
    id: string | undefined,
    definition: PipelineDefinition = [],
    name = 'blank',
    categories = []
  ) {
    this.id = id;
    this.definition = definition;
    this.name = name;
    this.categories = categories;

    const nowISO = new Date().toISOString();
    this.created_at = nowISO;
    this.updated_at = nowISO;
  }
}

createModelSchema(PipelineTemplate, {
  id: identifier(),
  name: primitive(),
  gallery_img_url: primitive(),
  description: primitive(),
  categories: list(object(PipelineTemplateCategory)),
  definition: raw(),
  created_at: primitive(),
  updated_at: primitive(),
});
