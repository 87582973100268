import React from 'react';
import classnames from 'classnames/bind';

import NumberInput, {
  NumberInputProps,
} from 'components/NumberInput/NumberInput';

import styles from './RangeSlider.module.scss';

const c = classnames.bind(styles);

export type RangeSliderProps = Pick<
  NumberInputProps,
  'defaultValue' | 'value' | 'onChange'
> & {
  id?: string;

  min: number;
  minLabel?: string;
  max: number;
  maxLabel?: string;
  step: number;
  scale?: number;

  input?: boolean;
  disabled?: boolean;
};

export function RangeSlider({
  id = undefined,
  min = 0,
  minLabel,
  max,
  maxLabel,
  onChange,
  defaultValue = min,
  value: initialValue,
  step = 1,
  scale = step,
  input,
  disabled = false,
}: RangeSliderProps) {
  const [value, setValue] = React.useState<string>(String(initialValue || min));
  const [numberInputValue, setNumberInputValue] = React.useState(
    initialValue || defaultValue
  );
  const [width, setWidth] = React.useState(0);
  const totalWidth = React.useRef(1);
  const wrapRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (initialValue) {
      setValue(String(initialValue));
    } else if (defaultValue) {
      setValue(String(defaultValue));
    }
  }, [initialValue, defaultValue]);

  React.useEffect(() => {
    if (wrapRef.current) {
      totalWidth.current = wrapRef.current.getBoundingClientRect().width;
    }
  }, [wrapRef]);

  React.useEffect(() => {
    const num = Number(value);
    const width = !Number.isNaN(num) ? (num - min) / (max - min) : 0;
    setWidth(width);
  }, [value, max, min]);

  React.useEffect(() => {
    setNumberInputValue(Number(value));
  }, [value]);

  const Scale = React.useCallback(() => {
    if (Number.isNaN(max) || Number.isNaN(min) || Number.isNaN(scale)) {
      return null;
    }

    const helperArray = new Array(Math.floor((max - min + scale) / scale)).fill(
      1
    );
    return (
      <div className={c('scale')}>
        {helperArray.map((_, index) => (
          <span key={index} className={c('line')}>
            {minLabel && index === 0 && (
              <span className={c('line-label', 'label')}>{minLabel}</span>
            )}

            {maxLabel && index === helperArray.length - 1 && (
              <span className={c('line-label', 'label')}>{maxLabel}</span>
            )}
          </span>
        ))}
      </div>
    );
  }, [max, maxLabel, min, minLabel, scale]);

  function handleChange(value: string | number | null) {
    if (value === null) {
      return;
    }

    if (typeof value === 'number' || /^\d*[.,]?\d*$/.test(value)) {
      setValue(String(value));
      onChange?.(Number(value));
    }
  }

  return (
    <div
      id={id}
      className={c('wrap', { 'has-input': input }, { disabled })}
      ref={wrapRef}
      style={
        {
          '--offset': `${width}`,
          '--totalWidth': `${totalWidth.current}px`,
        } as React.CSSProperties
      }
    >
      <div className={c('bar')}>
        <div className={c('background')}>
          <div className={c('fill')} />
        </div>
        <div className={c('thumb')}>
          <span className={c('dragger')} />
        </div>
        <input
          id={`${id}-slider`}
          type="range"
          className={c('input')}
          min={min}
          max={max}
          value={value}
          onChange={(event) => handleChange(event.target.value)}
          onMouseUp={(_) => onChange?.(Number(value))}
          step={step}
          disabled={disabled}
        />
      </div>
      <Scale />

      {input && (
        <div className={c('mirror-input')}>
          <NumberInput
            id={`${id}-input`}
            min={min}
            max={max}
            value={numberInputValue}
            onChange={(value) => {
              if (value) {
                setNumberInputValue(value || '');
              }

              if (
                value !== null &&
                !Number.isNaN(Number(value)) &&
                Number.isFinite(value)
              ) {
                if (Number(value) <= max && Number(value) >= min) {
                  handleChange(value);
                }
              }
            }}
            step={Math.min(step, 1)}
            size="small"
            decimalScale={2}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
}
