import classnames from 'classnames/bind';
import { components, OptionProps } from 'react-select';

import styles from '../Select.module.scss';

const c = classnames.bind(styles);

export function Option<OptionType, MultiSelect extends boolean = false>(
  props: React.PropsWithChildren<OptionProps<OptionType, MultiSelect>>
) {
  return (
    <components.Option
      {...props}
      className={c(
        'option',
        { [c('selected', 'theme primary')]: props.isSelected },
        { disabled: props.isDisabled },
        { hover: props.isFocused }
      )}
    />
  );
}
