import React from 'react';
import { SingleValue } from 'react-select';

import { FilterInput } from 'graphql/types/filter';

import { usePropelMetric } from 'dashboards/hooks/usePropelMetric';

import { Select } from 'components/Select/Select';

import { FILTER_DIMENSIONS_WITH_SUGGESTIONS } from './types';
import { useMetricFiltersState } from './context';

// Users should select *_id dimensions instead.
const HiddenDimensions = [
  'deployment_name',
  'gateway_name',
  'pipeline_name',
  'source_name',
];

type ColumnOption = {
  value: string;
  label: string;
};

export type ColumInputProps = Pick<FilterInput, 'id' | 'column'>;

export function ColumnInput({ id, column }: ColumInputProps) {
  const {
    metric: { name },
    updateFilter,
  } = useMetricFiltersState();

  const { data, isLoading } = usePropelMetric({ uniqueName: name });

  const columnOptions = React.useMemo<ColumnOption[]>(
    () =>
      data
        ? data.metricByName.dimensions
            .filter(({ columnName }) => !HiddenDimensions.includes(columnName))
            .map(
              ({ columnName }) =>
                ({ value: columnName, label: columnName } as ColumnOption)
            )
            .sort((a, b) => (a.value < b.value ? -1 : 1))
        : [],
    [data]
  );

  function handleColumnChange(selectedColumn: SingleValue<ColumnOption>) {
    if (!selectedColumn?.value) {
      return;
    }

    // Clear value when switching to and from a dropdown input
    if (
      FILTER_DIMENSIONS_WITH_SUGGESTIONS.includes(selectedColumn.value) ||
      (FILTER_DIMENSIONS_WITH_SUGGESTIONS.includes(column) &&
        !FILTER_DIMENSIONS_WITH_SUGGESTIONS.includes(selectedColumn.value))
    ) {
      updateFilter(id, 'value', '');
    }

    updateFilter(id, 'column', selectedColumn.value);
  }

  return (
    <Select
      placeholder="Select dimension..."
      defaultValue={{ value: column, label: column }}
      options={columnOptions}
      onChange={handleColumnChange}
      isLoading={isLoading}
    />
  );
}
