import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { ClazzOrModelSchema, deserialize, serialize } from 'serializr';
import { QueryClient, useQueryClient } from '@tanstack/react-query';

import AIModel, { ModelFiles } from 'types/ai_model';
import Account from 'types/account';
import Application from 'types/application';
import Camera from 'types/camera';
import Category from 'types/category';
import Dashboard from 'types/dashboard';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import LumeoJWT from 'types/jwt';
import Organization from 'types/organization';
import OrganizationInvitation from 'types/invitation';
import OrganizationUsageLimits from 'types/organization_usage_limits';
import OrganizationUsageStatistics from 'types/organization_usage_statistics';
import Pipeline from 'types/pipeline';
import PipelineTemplate from 'types/pipeline_template';
import PricingPlan from 'types/pricing_plan';
import PropelToken from 'types/propel_token';
import Stream from 'types/stream';
import { Tag } from 'types/tag';
import {
  CameraPaginatedAPIResponse,
  CategoryPaginatedAPIResponse,
  DashboardPaginatedAPIResponse,
  DeploymentPaginatedAPIResponse,
  MembersPaginatedAPIResponse,
  OrganizationInvitationsPaginatedAPIResponse,
  PaginatedAPIResponse,
  PipelinePaginatedAPIResponse,
  PipelineTemplatesPaginatedAPIResponse,
  StreamPaginatedAPIResponse,
  VideoSourcesPaginatedAPIResponse,
} from 'types/api';
import { GatewayConfiguration } from 'types/gateway_configuration';
import { GatewaySpecification } from 'types/gateway_specification';
import { LumeoFile } from 'types/file';
import { OffsetPaginated } from 'types/pagination';

import errorResponseInterceptor from 'services/api_error_handler';
import { DiscoveryRequestResponse } from 'hooks/api/useGatewayCameras';
import { useAuthentication } from 'hooks/api/useAuthentication';

import Component from 'pipelines/services/rete/nodes/component';
import { APIObject, API_BASE_URL } from 'services/api';
import { getDynamicComponentFromJson } from 'pipelines/services/rete/nodes/dynamic_components';
import { handleFileUpload } from 'services/file_upload';
import { JwtType, JwtParams } from '../useJWT';
import { VideoSource, UseVideoSourcesFilterParams } from './useVideoSources';
import { USER_AGENT } from '../../services/constants';

function createClient(
  authToken: string | undefined,
  queryClient: QueryClient,
  logout: () => void
) {
  const client = axios.create({
    baseURL: API_BASE_URL,
    responseType: 'json',
    headers: {
      'User-Agent': USER_AGENT,
    },
  });

  if (authToken) {
    client.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  }

  client.interceptors.response.use(
    (res) => res,
    (error) =>
      errorResponseInterceptor(error, queryClient, {
        redirectToLogin: logout,
      })
  );

  return client;
}

/** @deprecated */
export async function deserializeReq<T>(
  TargetModel: ClazzOrModelSchema<T>,
  req: Promise<AxiosResponse>
): Promise<T | T[] | undefined> {
  const { status, data } = await req;

  if (status >= 400) {
    return;
  }

  if (!data) {
    return data;
  }

  return deserialize(TargetModel, data);
}

export const APIContext = React.createContext<APIObject | undefined>(undefined);

export function APIProvider({ children }: React.PropsWithChildren) {
  const queryClient = useQueryClient();
  const { authToken, logout } = useAuthentication();
  const [applicationID, setApplicationID] = React.useState<string>();

  const [client, setClient] = React.useState(() =>
    createClient(authToken, queryClient, logout)
  );

  React.useEffect(() => {
    if (
      !authToken ||
      client.defaults.headers.common.Authorization === `Bearer ${authToken}`
    ) {
      return;
    }

    setClient(() => createClient(authToken, queryClient, logout));
  }, [authToken, client, logout, queryClient]);

  const api = React.useMemo<APIObject>(() => {
    async function createCamera(camera: Camera) {
      return deserializeReq(
        Camera,
        client.post(`/apps/${applicationID}/cameras`, camera)
      ) as Promise<Camera>;
    }

    return {
      authToken,
      applicationID,
      setApplicationID,
      client,

      async sendBeacon(url, data) {
        const params = data || new URLSearchParams();
        params.set('access_token', String(authToken));

        return navigator.sendBeacon(
          `${API_BASE_URL}/internal/apps/${applicationID}${url}`,
          params
        );
      },

      /** @deprecated */
      internal: {
        async readServerVersion() {
          return (await client.get('/internal/version')).data;
        },
      },

      categories: {
        async list(params) {
          return deserializeReq(
            CategoryPaginatedAPIResponse,
            client.get(`/categories`, { params })
          ) as Promise<PaginatedAPIResponse<Category>>;
        },
      },

      organizations: {
        async read(organizationID) {
          return deserializeReq(
            Organization,
            client.get(`/orgs/${organizationID}`)
          ) as Promise<Organization | undefined>;
        },
        async list() {
          return deserializeReq(
            Organization,
            client.get('/internal/orgs')
          ) as Promise<Organization[]>;
        },
        async update(organization) {
          return client.put(`/orgs/${organization.id}`, {
            name: organization.name,
          });
        },
        async readCurrentPlan(organizationID) {
          return deserializeReq(
            PricingPlan,
            client.get(`/internal/orgs/${organizationID}/plan`)
          ) as Promise<PricingPlan | null>;
        },
        async readUsageStatistics(organizationID) {
          return deserializeReq(
            OrganizationUsageStatistics,
            client.get(`/internal/orgs/${organizationID}/usage_statistics`)
          ) as Promise<OrganizationUsageStatistics>;
        },
        async readUsageLimits(organizationID) {
          const { data } = await client.get(
            `/internal/orgs/${organizationID}/usage_limits`
          );

          if (!data) {
            return null;
          }

          return data as OrganizationUsageLimits;
        },
        async updateUsageLimits(organizationID, usageStatistics) {
          const { data } = await client.put(
            `/internal/orgs/${organizationID}/usage_limits`,
            usageStatistics
          );

          if (!data) {
            return null;
          }

          return data as OrganizationUsageLimits;
        },
        async switchPlan(organizationID, planID) {
          return client.put(`/internal/orgs/${organizationID}/plan`, {
            id: planID,
          });
        },
        applications: {
          async list(organizationId) {
            return deserializeReq(
              Application,
              client.get(`/orgs/${organizationId}/apps`)
            ) as Promise<Application[]>;
          },
        },
        billing: {
          async createUrl({ organizationID, ...data }) {
            return client.post(
              `/internal/orgs/${organizationID}/stripe_customer_portal`,
              data
            );
          },
          async setupPayment(organizationID) {
            const { data } = await client.post(
              `/internal/orgs/${organizationID}/payment_setup`
            );
            return data;
          },
          async hasValidPaymentMethods(organizationID) {
            const { data } = await client.get(
              `/internal/orgs/${organizationID}/has_valid_payment_methods`
            );
            return data as boolean;
          },
        },
        plans: {
          async list(organizationID) {
            return deserializeReq(
              PricingPlan,
              client.get(`/internal/orgs/${organizationID}/plans`)
            ) as Promise<PricingPlan[]>;
          },
          async listIncludedNodes(organizationID, planID) {
            const { data } = await client.get(
              `/internal/orgs/${organizationID}/plans/${planID}/included_nodes`
            );
            return data as string[];
          },
        },
        members: {
          async read(organizationId, accountId) {
            return deserializeReq(
              Account,
              client.get(
                `/internal/orgs/${organizationId}/members/${accountId}`
              )
            ) as Promise<Account>;
          },
          async list(organizationId, params) {
            return deserializeReq(
              MembersPaginatedAPIResponse,
              client.get(`/internal/orgs/${organizationId}/members`, { params })
            ) as Promise<PaginatedAPIResponse<Account>>;
          },
          async addApplicationRoles(accountId, newRoles) {
            return client.post(
              `/internal/apps/${applicationID}/members/${accountId}/roles`,
              newRoles
            );
          },
          async addOrganizationRoles(organizationId, accountId, newRoles) {
            return client.post(
              `/internal/orgs/${organizationId}/members/${accountId}/roles`,
              newRoles
            );
          },
          async deleteOrganizationRoles(organizationId, accountId, params) {
            return client.delete(
              `/internal/orgs/${organizationId}/members/${accountId}/roles`,
              { params }
            );
          },
          async deleteApplicationRoles(accountId, params) {
            return client.delete(
              `/internal/apps/${applicationID}/members/${accountId}/roles`,
              { params }
            );
          },
          async deleteOrganizationMember(organizationId, accountId) {
            return client.delete(
              `/internal/orgs/${organizationId}/members/${accountId}`
            );
          },
        },

        invitations: {
          async list(organizationId, params) {
            return deserializeReq(
              OrganizationInvitationsPaginatedAPIResponse,
              client.get(`/internal/orgs/${organizationId}/invitations`, {
                params,
              })
            ) as Promise<PaginatedAPIResponse<OrganizationInvitation>>;
          },
          async create(organizationId, variables) {
            return deserializeReq(
              OrganizationInvitation,
              client.post(
                `/internal/orgs/${organizationId}/invitations`,
                variables
              )
            ) as Promise<OrganizationInvitation>;
          },
          async delete(organizationId, organizationInvitationId) {
            return client.delete(
              `/internal/orgs/${organizationId}/invitations/${organizationInvitationId}`
            );
          },
        },
      },

      applications: {
        async read(applicationID) {
          return deserializeReq(
            Application,
            client.get(`/apps/${applicationID}`)
          ) as Promise<Application>;
        },
        async list() {
          return deserializeReq(Application, client.get('/apps')) as Promise<
            Application[]
          >;
        },
        async create(application) {
          return deserializeReq(
            Application,
            client.post(
              `/orgs/${application.organization_id}/apps`,
              serialize(application)
            )
          ) as Promise<Application>;
        },
        async update(application) {
          return client.put(`/apps/${application.id}`, serialize(application));
        },
        async delete(applicationID) {
          return client.delete(`/apps/${applicationID}`);
        },

        gatewayConfiguration: {
          async read(gatewayConfigurationId) {
            const { data } = await client.get<GatewayConfiguration>(
              `/apps/${applicationID}/gateway_configurations/${gatewayConfigurationId}`
            );

            return data;
          },
          async list(params) {
            const { data } = await client.get<
              OffsetPaginated<GatewayConfiguration>
            >(`/apps/${applicationID}/gateway_configurations`, {
              params,
            });

            return data;
          },
          async create(params) {
            const { data } = await client.post(
              `/apps/${applicationID}/gateway_configurations`,
              params
            );

            return data;
          },
          async update({ id, metrics }) {
            const { data } = await client.put(
              `/apps/${applicationID}/gateway_configurations/${id}`,
              { metrics }
            );

            return data;
          },
        },
      },

      accessTokens: {
        async list() {
          return (
            await client.get(`/internal/apps/${applicationID}/access_tokens`)
          ).data;
        },
        async create(params) {
          return (
            await client.post(
              `/internal/apps/${applicationID}/access_tokens`,
              params
            )
          ).data;
        },
        async delete(tokenID) {
          return client.delete(
            `/internal/apps/${applicationID}/access_tokens/${tokenID}`
          );
        },
      },

      jwt: {
        async create<Type extends JwtType>(variables: JwtParams<Type>) {
          return deserializeReq(
            LumeoJWT,
            client.post(`/internal/auth/jwt`, variables)
          ) as Promise<LumeoJWT>;
        },
        async createSearchToken() {
          return deserializeReq(
            LumeoJWT,
            client.get(`/internal/apps/${applicationID}/auth/search_jwt`)
          ) as Promise<LumeoJWT>;
        },
      },

      accounts: {
        async read(accountID) {
          return deserializeReq(
            Account,
            client.get(`/internal/accounts/${accountID}`)
          ) as Promise<Account>;
        },
        async update(account) {
          return deserializeReq(
            Account,
            client.put(`/internal/accounts/${account.id}`, account)
          ) as Promise<Account>;
        },
        async updatePassword(accountID, { oldPassword, newPassword }) {
          return client.post(
            `/internal/accounts/${accountID}/update_password`,
            {
              old: oldPassword,
              new: newPassword,
            }
          );
        },
      },

      pipelines: {
        async list(params) {
          return deserializeReq(
            PipelinePaginatedAPIResponse,
            client.get(`/apps/${applicationID}/pipelines`, { params })
          ) as Promise<PaginatedAPIResponse<Pipeline>>;
        },
        async read(pipelineID) {
          return (await deserializeReq(
            Pipeline,
            client.get(`/apps/${applicationID}/pipelines/${pipelineID}`)
          )) as Pipeline;
        },
        async create(variables) {
          return deserializeReq(
            Pipeline,
            client.post(`/apps/${applicationID}/pipelines`, variables)
          ) as Promise<Pipeline>;
        },
        async update({ id, ...variables }) {
          return deserializeReq(
            Pipeline,
            client.put(`/apps/${applicationID}/pipelines/${id}`, {
              ...variables,
            })
          ) as Promise<Pipeline>;
        },
        async delete(pipelineID) {
          return client.delete(
            `/apps/${applicationID}/pipelines/${pipelineID}`
          );
        },
        async deploy(deployment) {
          return deserializeReq(
            Deployment,
            client.post(
              `/apps/${applicationID}/deployments`,
              serialize(deployment)
            )
          ) as Promise<Deployment>;
        },
        async lock(pipelineID) {
          return client.post(
            `/internal/apps/${applicationID}/pipelines/${pipelineID}/lock`
          );
        },
        async unlock(pipelineID) {
          return client.post(
            `/internal/apps/${applicationID}/pipelines/${pipelineID}/unlock`
          );
        },

        dynamicComponents: {
          async list() {
            const dynamicComponents = (await deserializeReq(
              Component,
              client.get('/internal/dynamic_nodes')
            )) as Component[];

            return (
              dynamicComponents
                // filter out dynamic nodes with shared names
                .reduce((acc, cmp) => {
                  if (!acc.find(({ name }) => name === cmp.name)) {
                    return [...acc, cmp];
                  }
                  return acc;
                }, [] as Component[])
                // transform into component class
                .map((json) => getDynamicComponentFromJson(json))
            );
          },
        },

        templates: {
          async list(params) {
            return deserializeReq(
              PipelineTemplatesPaginatedAPIResponse,
              client.get('/pipeline_templates', { params })
            ) as Promise<PaginatedAPIResponse<PipelineTemplate>>;
          },
        },
      },

      deployments: {
        async read(deploymentID) {
          return deserializeReq(
            Deployment,
            client.get(`/apps/${applicationID}/deployments/${deploymentID}`)
          ) as Promise<Deployment>;
        },
        async list(params) {
          return deserializeReq(
            DeploymentPaginatedAPIResponse,
            client.get(`/apps/${applicationID}/deployments`, { params })
          ) as Promise<PaginatedAPIResponse<Deployment>>;
        },
        async delete(deploymentID) {
          return client.delete(
            `/apps/${applicationID}/deployments/${deploymentID}`
          );
        },
        async update(deployment) {
          return deserializeReq(
            Deployment,
            client.put(
              `/apps/${applicationID}/deployments/${deployment.id}`,
              serialize(deployment)
            )
          ) as Promise<Deployment>;
        },
        async start(deploymentID) {
          return client.post(
            `/apps/${applicationID}/deployments/${deploymentID}/start`
          );
        },
        async stop(deploymentID) {
          return client.post(
            `/apps/${applicationID}/deployments/${deploymentID}/stop`
          );
        },
        async rename(deploymentID, name) {
          const params = new URLSearchParams();
          params.set('update_definition', 'false');

          return deserializeReq(
            Deployment,
            client.put(
              `/apps/${applicationID}/deployments/${deploymentID}`,
              { name },
              { params }
            )
          ) as Promise<Deployment>;
        },
        addTags(deploymentID, tagIDs) {
          return client.post(
            `/apps/${applicationID}/deployments/${deploymentID}/tags`,
            tagIDs
          ) as Promise<Tag['id'][]>;
        },
        removeTags(deploymentID, tagIDs) {
          return client.delete(
            `/apps/${applicationID}/deployments/${deploymentID}/tags`,
            { params: { tag_ids: tagIDs } }
          ) as Promise<Tag['id'][]>;
        },
      },

      streams: {
        async read(streamID) {
          return deserializeReq(
            Stream,
            client.get(`/apps/${applicationID}/streams/${streamID}`)
          ) as Promise<Stream>;
        },
        async list(params) {
          return deserializeReq(
            StreamPaginatedAPIResponse,
            client.get(`/internal/apps/${applicationID}/streams`, { params })
          ) as Promise<PaginatedAPIResponse<Stream>>;
        },
        async create(stream) {
          return deserializeReq(
            Stream,
            client.post(`/apps/${applicationID}/streams`, serialize(stream))
          ) as Promise<Stream>;
        },
        async update(stream) {
          return deserializeReq(
            Stream,
            client.put(
              `/apps/${applicationID}/streams/${stream.id}`,
              serialize(stream)
            )
          ) as Promise<Stream>;
        },
        delete(streamID) {
          return client.delete(`/apps/${applicationID}/streams/${streamID}`);
        },
        addTags(streamID, tagIDs) {
          return client.post(
            `/apps/${applicationID}/streams/${streamID}/tags`,
            tagIDs
          ) as Promise<Tag['id'][]>;
        },
        removeTags(streamID, tagIDs) {
          return client.delete(
            `/apps/${applicationID}/streams/${streamID}/tags`,
            {
              params: {
                tag_ids: tagIDs,
              },
            }
          ) as Promise<Tag['id'][]>;
        },
      },

      cameras: {
        read(cameraID) {
          return deserializeReq(
            Camera,
            client.get(`/apps/${applicationID}/cameras/${cameraID}`)
          ) as Promise<Camera>;
        },
        list(params) {
          return deserializeReq(
            CameraPaginatedAPIResponse,
            client.get(`/apps/${applicationID}/cameras`, { params })
          ) as Promise<PaginatedAPIResponse<Camera>>;
        },
        update(camera) {
          return deserializeReq(
            Camera,
            client.put(
              `/apps/${applicationID}/cameras/${camera.id}`,
              serialize(camera)
            )
          ) as Promise<Camera>;
        },
        create: createCamera,
        delete(cameraID) {
          return client.delete(`/apps/${applicationID}/cameras/${cameraID}`);
        },
        addTags(cameraID, tagIDs) {
          return client.post(
            `/apps/${applicationID}/cameras/${cameraID}/tags`,
            tagIDs
          ) as Promise<Tag['id'][]>;
        },
        removeTags(cameraID, tagIDs) {
          return client.delete(
            `/apps/${applicationID}/cameras/${cameraID}/tags`,
            { params: { tag_ids: tagIDs } }
          ) as Promise<Tag['id'][]>;
        },
        async createReferencePipelines(camera) {
          const response: AxiosResponse<Camera['reference_pipeline_ids']> =
            await client.post(
              `/apps/${applicationID}/cameras/${camera.id}/reference_pipelines`,
              camera.reference_pipeline_ids
            );
          return response.data;
        },
        deleteReferencePipelines(cameraID, pipelineIDs) {
          return client.delete(
            `/apps/${applicationID}/cameras/${cameraID}/reference_pipelines`,
            {
              params: {
                pipeline_ids: pipelineIDs,
              },
            }
          ) as Promise<Camera['reference_pipeline_ids']>;
        },
      },

      videoSources: {
        async list<T extends VideoSource = VideoSource>(
          params: UseVideoSourcesFilterParams
        ) {
          return deserializeReq(
            VideoSourcesPaginatedAPIResponse,
            client.get(`/internal/console/${applicationID}/video_sources`, {
              params,
            })
          ) as Promise<PaginatedAPIResponse<T>>;
        },
      },

      async snapshot(source) {
        const response = await client.post(
          `/apps/${applicationID}/${source.source_type}s/${source.id}/snapshot`,
          {
            gateway_id: source.gateway_id,
          }
        );
        return response.data;
      },

      files: {
        async create(file) {
          const { data } = await client.post(
            `/apps/${applicationID}/files`,
            file
          );

          return data;
        },
        async read(fileId) {
          const { data } = await client.get<LumeoFile>(
            `/apps/${applicationID}/files/${fileId}`
          );

          return data;
        },
        async updateStatus(fileID, cloudStatus) {
          return client.put(
            `/apps/${applicationID}/files/${fileID}/cloud_status`,
            cloudStatus
          );
        },
        addTags(fileId, tagIds) {
          return client.post(
            `/apps/${applicationID}/files/${fileId}/tags`,
            tagIds
          ) as Promise<Tag['id'][]>;
        },
        removeTags(fileId, tagIds) {
          return client.delete(`/apps/${applicationID}/files/${fileId}/tags`, {
            params: { tag_ids: tagIds },
          }) as Promise<Tag['id'][]>;
        },
      },

      gateways: {
        async list(params) {
          const { data } = await client.get<OffsetPaginated<Gateway>>(
            `/apps/${applicationID}/gateways`,
            { params }
          );

          return {
            ...data,
            data: data.data.map((gateway) => new Gateway(gateway)),
          };
        },
        async read(gatewayID) {
          const { data } = await client.get<Gateway>(
            `/apps/${applicationID}/gateways/${gatewayID}`
          );

          return new Gateway(data);
        },
        async update(gateway) {
          const { data } = await client.put<Gateway>(
            `/apps/${applicationID}/gateways/${gateway.id}`,
            gateway
          );

          return new Gateway(data);
        },
        async delete(gatewayID) {
          return client.delete(`/apps/${applicationID}/gateways/${gatewayID}`);
        },
        async getSpecification(gatewayID) {
          const { data } = await client.get<GatewaySpecification>(
            `/apps/${applicationID}/gateways/${gatewayID}/specification`
          );

          if (!data) {
            throw new Error('Unable to fetch specification for this gateway.');
          }

          return data;
        },
        addTags(gatewayID, tagIDs) {
          return client.post(
            `/apps/${applicationID}/gateways/${gatewayID}/tags`,
            tagIDs
          ) as Promise<Tag['id'][]>;
        },
        removeTags(gatewayID, tagIDs) {
          return client.delete(
            `/apps/${applicationID}/gateways/${gatewayID}/tags`,
            { params: { tag_ids: tagIDs } }
          ) as Promise<Tag['id'][]>;
        },

        cameras: {
          async link(gatewayID, camera) {
            camera = camera.copy();
            camera.gateway_id = gatewayID;
            return createCamera(camera);
          },
          async getDiscoveryID(gatewayID: string) {
            return deserializeReq(
              DiscoveryRequestResponse,
              client.post(
                `/apps/${applicationID}/gateways/${gatewayID}/discovery_request`
              )
            ) as DiscoveryRequestResponse;
          },
          async discover(gatewayID, requestID) {
            return deserializeReq(
              DiscoveryRequestResponse,
              client.get(
                `/apps/${applicationID}/gateways/${gatewayID}/discovery_request/${requestID}?unlinked_only=true`
              )
            ) as Promise<DiscoveryRequestResponse>;
          },
        },
        async listLinkedCameras(gatewayID) {
          return deserializeReq(
            Camera,
            client.get(
              `/apps/${applicationID}/gateways/${gatewayID}/linked_cameras`
            )
          ) as Promise<Camera[]>;
        },

        cloud: {
          async create(name, type) {
            const { data } = await client.post<Gateway>(
              `/internal/apps/${applicationID}/cloud_gateways/create`,
              {
                gateway_type: type,
                name,
              }
            );

            return new Gateway(data);
          },
          retry(gatewayID) {
            return client.post(
              `/internal/apps/${applicationID}/gateways/${gatewayID}/retry`
            );
          },
          start(gatewayID) {
            return client.post(
              `/internal/apps/${applicationID}/gateways/${gatewayID}/start`
            );
          },
          stop(gatewayID) {
            return client.post(
              `/internal/apps/${applicationID}/gateways/${gatewayID}/stop`
            );
          },
          restart(gatewayID) {
            return client.post(
              `/apps/${applicationID}/gateways/${gatewayID}/restart`,
              {}
            );
          },
        },
      },

      models: {
        async read(modelID) {
          return deserializeReq(
            AIModel,
            client.get(`/apps/${applicationID}/models/${modelID}`)
          ) as Promise<AIModel>;
        },
        async list() {
          return deserializeReq(
            AIModel,
            client.get(`/apps/${applicationID}/models`)
          ) as Promise<AIModel[]>;
        },
        async create(model, files) {
          model.labels_file_name =
            (files.labels_file as File).name || 'model.labels';

          if (files.metadata_file) {
            model.metadata_file_name = (files.metadata_file as File).name;
          }

          if (files.weights_file) {
            model.weights_file_name = (files.weights_file as File).name;
          }

          const savedModel = (await deserializeReq(
            AIModel,
            client.post(`/apps/${applicationID}/models`, model)
          )) as AIModel;

          await handleModelFilesUpload(savedModel, files);

          return savedModel;
        },
        async update(model, files = {}) {
          const updateModel = { ...model };

          if (files.labels_file) {
            updateModel.labels_file_name = (files.labels_file as File).name;
          }

          if (files.metadata_file) {
            updateModel.metadata_file_name = (files.metadata_file as File).name;
          } else if (files.metadata_file === null) {
            // Delete existing file
            updateModel.metadata_file_name = null;
          } else {
            // Do not request a new upload URL
            delete updateModel.metadata_file_name;
          }

          if (files.weights_file) {
            updateModel.weights_file_name = (files.weights_file as File).name;
          }

          const savedModel = (await deserializeReq(
            AIModel,
            client.put(
              `/apps/${applicationID}/models/${updateModel.id}`,
              updateModel
            )
          )) as AIModel;

          await handleModelFilesUpload(savedModel, files);

          return savedModel;
        },
        async delete(modelID) {
          return client.delete(`/apps/${applicationID}/models/${modelID}`);
        },

        marketplace: {
          async read(modelID) {
            return deserializeReq(
              AIModel,
              client.get(`/marketplace/models/${modelID}`)
            ) as Promise<AIModel>;
          },
          async list() {
            return deserializeReq(
              AIModel,
              client.get('/internal/marketplace/models')
            ) as Promise<AIModel[]>;
          },
          async create(model, files) {
            const savedModel = (await deserializeReq(
              AIModel,
              client.post('/internal/marketplace/models', model)
            )) as AIModel;

            await handleModelFilesUpload(savedModel, files);

            return savedModel;
          },
          async update(model, files) {
            const updateModel = { ...model };

            if (files.labels_file) {
              updateModel.labels_file_name = (files.labels_file as File).name;
            }

            if (files.metadata_file) {
              updateModel.metadata_file_name = (
                files.metadata_file as File
              ).name;
            } else if (files.metadata_file === null) {
              // Delete existing file
              updateModel.metadata_file_name = null;
            } else {
              // Do not request a new upload URL
              delete updateModel.metadata_file_name;
            }

            if (files.weights_file) {
              updateModel.weights_file_name = (files.weights_file as File).name;
            }

            const savedModel = (await deserializeReq(
              AIModel,
              client.put(
                `/internal/marketplace/models/${updateModel.id}`,
                updateModel
              )
            )) as AIModel;

            await handleModelFilesUpload(savedModel, files);

            return savedModel;
          },
          async delete(modelID) {
            return client.delete(`/internal/marketplace/models/${modelID}`);
          },
        },
      },

      dashboards: {
        list(params) {
          return deserializeReq(
            DashboardPaginatedAPIResponse,
            client.get(`/internal/apps/${applicationID}/dashboards`, { params })
          ) as Promise<PaginatedAPIResponse<Dashboard>>;
        },
        read(dashboardID) {
          return deserializeReq(
            Dashboard,
            client.get(
              `/internal/apps/${applicationID}/dashboards/${dashboardID}`
            )
          ) as Promise<Dashboard>;
        },
        create(dashboard) {
          return deserializeReq(
            Dashboard,
            client.post(
              `/internal/apps/${applicationID}/dashboards`,
              serialize(dashboard)
            )
          ) as Promise<Dashboard>;
        },
        update(dashboard) {
          return deserializeReq(
            Dashboard,
            client.put(
              `/internal/apps/${applicationID}/dashboards/${dashboard.id}`,
              serialize(dashboard)
            )
          ) as Promise<Dashboard>;
        },
        delete(dashboardID) {
          return client.delete(
            `/internal/apps/${applicationID}/dashboards/${dashboardID}`
          );
        },
        propel: {
          authenticate() {
            return deserializeReq(
              PropelToken,
              client.get(
                `/internal/apps/${applicationID}/dashboards/access_token`
              )
            ) as Promise<PropelToken>;
          },
        },
      },

      invitations: {
        async accept(variables) {
          return client.post(`/internal/invitations/accept`, variables);
        },
        async acceptAndCreateAccount(variables) {
          return client.post(
            `/internal/invitations/accept_and_create_account`,
            variables
          );
        },
        async decline(variables) {
          return client.post(`/internal/invitations/decline`, variables);
        },
        async hasAccount(token: string) {
          return client.get(`/internal/invitations/has_account?token=${token}`);
        },
      },

      webrtc: {
        async getOffer(streamID) {
          const { data } = await client.get(
            `/apps/${applicationID}/streams/${streamID}/webrtc/get_offer`
          );
          return data;
        },

        async close(streamID, peerID) {
          const { data } = await client.get(
            `/apps/${applicationID}/streams/${streamID}/webrtc/${peerID}/hang_up`
          );
          return data;
        },

        async setAnswer(streamID, peerID, data) {
          return client.post(
            `/apps/${applicationID}/streams/${streamID}/webrtc/${peerID}/set_answer`,
            data
          );
        },

        async getCandidates(streamID, peerID) {
          const { data } = await client.get(
            `/apps/${applicationID}/streams/${streamID}/webrtc/${peerID}/get_candidates`
          );
          return data;
        },

        async addCandidate(streamID, peerID, candidate) {
          const { data } = await client.post(
            `/apps/${applicationID}/streams/${streamID}/webrtc/${peerID}/add_candidate`,
            candidate.toJSON()
          );
          return data;
        },
      },
    };
  }, [client, authToken, applicationID]);

  return <APIContext.Provider value={api}>{children}</APIContext.Provider>;
}

export function useAPI() {
  const context = React.useContext(APIContext);

  if (context === undefined) {
    throw new Error(
      'Hook `useAPI` and other API hooks can only be used within an APIProvider.'
    );
  }

  return context;
}

async function handleModelFilesUpload(
  model: AIModel,
  { weights_file, metadata_file, labels_file }: ModelFiles
) {
  const promises: Promise<void | Response>[] = [];

  if (model.weights_file_url && weights_file) {
    promises.push(handleFileUpload(model.weights_file_url, weights_file));
  }

  if (model.metadata_file_url && metadata_file) {
    promises.push(handleFileUpload(model.metadata_file_url, metadata_file));
  }

  if (model.labels_file_url && labels_file) {
    promises.push(handleFileUpload(model.labels_file_url, labels_file));
  }

  return Promise.all(promises);
}
