import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Stream, { StreamType } from 'types/stream';
import { PaginatedAPIResponse } from 'types/api';

import { useStreams, UseStreamsFilterParams } from './useStreams';

export function useOutputStreams(
  params?: UseStreamsFilterParams,
  options?: UseQueryOptions<PaginatedAPIResponse<Stream>, APIError>
) {
  return useStreams(
    ['streams'],
    {
      stream_types: [StreamType.RTSP, StreamType.WEBRTC],
      sources: ['camera_stream', 'pipeline_stream'],
      ...params,
    },
    options
  );
}
