import React from 'react';
import { CellProps, Column } from 'react-table';

import Camera from 'types/camera';
import { TableBulkActionType } from 'components/Table/components/BulkActions/BulkActions';

import { isNotNullOrUndefined } from 'services/nullable';
import { relativeTimeFromNow } from 'services/date';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useDeleteCameras } from 'cameras/hooks/useDeleteCameras';
import { useGateways } from 'hooks/api/useGateways';
import { useHasAccess } from 'hooks/useHasAccess';
import { useUpdateCamera } from 'cameras/hooks/useUpdateCamera';

import { EmptyView } from 'components/EmptyView/EmptyView';
import { NameCell, TableLabelsCell } from 'components/Table/Table';
import {
  PaginatedTable,
  PaginatedTableProps,
} from 'components/Table/PaginatedTable';

import { CamerasTableGatewayCell } from './GatewayCell';
import { CamerasTableStatusCell } from './StatusCell';
import { CamerasTableActionsCell } from './ActionsCell';

export type CamerasTableProps = Pick<
  PaginatedTableProps<Camera>,
  'queryResult' | 'pageSize' | 'page' | 'onPageChange'
> & {
  hasFilters: boolean;
  onCreate: () => void;
};

function CamerasTable({ hasFilters, onCreate, ...props }: CamerasTableProps) {
  const { data: currentPlan, isSuccess } = useCurrentPlan();
  const [hasAccess] = useHasAccess();

  const canCreateTags =
    (isSuccess && currentPlan === null) ||
    Boolean(currentPlan?.can_create_tags);

  const gatewayIDs = [
    ...new Set(
      props.queryResult?.data?.data
        .map(({ gateway_id }) => gateway_id)
        .filter(isNotNullOrUndefined)
    ),
  ];
  const { data: gateways } = useGateways({ gateway_ids: gatewayIDs });

  const [isRenamingCamera, setIsRenamingCamera] = React.useState<
    Camera['id'] | null
  >(null);
  const [requestDelete, { isLoading }] = useDeleteCameras();

  const { mutate: updateCamera } = useUpdateCamera();

  const columns = React.useMemo(() => {
    const columns: Column<Camera>[] = [
      {
        Header: 'Camera',
        Cell(props: CellProps<Camera>) {
          function handleNameChange(newName: string) {
            updateCamera(props.row.original.withName(newName));
            setIsRenamingCamera(null);
          }

          function cancelRename() {
            setIsRenamingCamera(null);
          }

          return (
            <NameCell
              {...props}
              to={`/deploy/cameras/${props.row.original.id}`}
              isRenaming={isRenamingCamera === props.row.original.id}
              onNameChange={handleNameChange}
              onCancel={cancelRename}
              entity="camera"
            />
          );
        },
      },
      { Header: 'State', Cell: CamerasTableStatusCell },
      { Header: 'Model', accessor: (camera: Camera) => camera.model },
      {
        Header: 'Gateway',
        Cell(props: CellProps<Camera>) {
          const { gateway_id } = props.row.original;

          const gateway = gateway_id
            ? gateways?.data.find(({ id }) => id === gateway_id)
            : undefined;

          return <CamerasTableGatewayCell {...props} gateway={gateway} />;
        },
      },
      { Header: 'Address', accessor: (camera: Camera) => camera.ip_local },
    ];

    if (canCreateTags) {
      columns.push({
        Header: 'Labels',
        Cell(props: CellProps<Camera>) {
          return (
            <TableLabelsCell
              {...props}
              entity="cameras"
              listQueryKey={['cameras']}
              singleQueryKey={['camera']}
              readOnly={!hasAccess('deploy_edit')}
            />
          );
        },
      });
    }

    columns.push({
      id: 'updated_at',
      Header: 'Recent Activity',
      accessor: (camera: Camera) => relativeTimeFromNow(camera.updated_at),
      sortType: 'date',
    });

    if (hasAccess('deploy_edit')) {
      columns.push({
        Header: ' ',
        Cell(props: CellProps<Camera>) {
          return (
            <CamerasTableActionsCell
              {...props}
              onRenameClick={setIsRenamingCamera}
              onDeleteClick={(cameraID) => requestDelete([cameraID])}
            />
          );
        },
      });
    }

    return columns;
  }, [
    gateways,
    isRenamingCamera,
    canCreateTags,
    hasAccess,
    updateCamera,
    requestDelete,
  ]);

  const bulkActions = React.useMemo(() => {
    if (hasAccess('deploy_edit')) {
      return [
        {
          action: 'Delete',
          intent: 'danger',
          onClick: requestDelete,
          loading: isLoading,
        },
      ] as TableBulkActionType[];
    }
  }, [isLoading, hasAccess, requestDelete]);

  return (
    <PaginatedTable
      {...props}
      id="cameras-table"
      label="cameras"
      columns={columns}
      bulkActions={bulkActions}
      onRowNavigate={({ id }) => `/deploy/cameras/${id}`}
      emptyMessage={
        hasFilters ? (
          <EmptyView title="No cameras matching the filter criteria found.">
            Please try another filter combination or clear filters.
          </EmptyView>
        ) : (
          <EmptyView
            title="You have not added any cameras yet."
            buttonText="Add camera"
            onButtonClick={onCreate}
          />
        )
      }
    />
  );
}

const Memoized = React.memo(CamerasTable);

export { Memoized as CamerasTable };
