import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import AccessToken, {
  AccessTokenMintingSubType,
  AccessTokenType,
} from 'types/token';
import APIError from 'types/api_error';

import { useAppQueryClient } from 'hooks/useAppQueryClient';
import { useHasAccess } from 'hooks/useHasAccess';

import { useAPI } from './useAPI';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { useDangerousMutation } from './useDangerousMutation';

export function useAccessTokens({
  enabled,
  ...options
}: UseQueryOptions<AccessToken[], APIError> = {}) {
  const api = useAPI();
  const [hasAccess] = useHasAccess();

  return useAuthenticatedQuery(
    ['access-tokens', api.applicationID],
    api.accessTokens.list,
    {
      ...options,
      enabled:
        enabled !== undefined
          ? hasAccess('access_tokens') && enabled
          : hasAccess('access_tokens'),
    }
  );
}

export type CreateAccessTokenVariables = {
  name: string;
  type: AccessTokenType;
  sub_type_minting?: AccessTokenMintingSubType;
  expires_at?: string;
};

export function useCreateAccessToken({
  onSettled,
  ...options
}: UseMutationOptions<AccessToken, APIError, CreateAccessTokenVariables> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation(api.accessTokens.create, {
    ...options,
    onSettled: (...args) => {
      if (onSettled) {
        onSettled(...args);
      }

      queryClient.invalidateQueries(['access-tokens']);
    },
  });
}

type UseDeleteAccessTokenContext = {
  previousTokens: AccessToken[] | undefined;
} | void;

export function useDeleteAccessToken({
  onMutate,
  onSettled,
  onError,
  ...options
}: UseMutationOptions<
  void,
  APIError,
  AccessToken,
  UseDeleteAccessTokenContext
> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete this token?',
    (token) => api.accessTokens.delete(token.id),
    {
      ...options,
      onMutate: async (token) => {
        await queryClient.cancelQueries(['access-tokens']);
        const tokens = queryClient.getQueryData<AccessToken[]>([
          'access-tokens',
        ]);

        if (tokens) {
          const updatedAccessTokens = tokens.filter(
            ({ id }) => id !== token.id
          );
          queryClient.setQueryData(['access-tokens'], updatedAccessTokens);
        }

        if (onMutate) {
          onMutate(token);
        }

        return { previousTokens: tokens };
      },
      onError: (error, deletedAccessToken, context) => {
        if (context?.previousTokens) {
          queryClient.setQueryData(['streams'], context.previousTokens);
        }

        if (onError) {
          onError(error, deletedAccessToken, context);
        }
      },
      onSettled: (...args) => {
        if (onSettled) {
          onSettled(...args);
        }

        queryClient.invalidateQueries(['access-tokens']);
      },
    }
  );
}
