import { Row } from 'react-table';

import { AppEntityObject } from 'hooks/useEntities';

export function getRowId<T extends AppEntityObject>(
  row: T,
  relativeIndex: number,
  parent?: Row<T>
) {
  if (row?.id && !parent) {
    return String(row.id);
  }

  return String(relativeIndex);
}
