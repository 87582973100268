type APIError = {
  code: string;
  status: number;
  message: string;
  context?: Record<string, unknown>;
};

export default APIError;

export function isAPIError(error: any): error is APIError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'code' in error &&
    'status' in error &&
    'message' in error
  );
}
