import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { MultiSelectInput } from 'components/MultiSelectInput/MultiSelectInput';

import { FIELD_LABEL_WIDTH, Step2FieldValues } from '../Step2';

export function OutputBlobNamesInput() {
  const {
    formState: { errors },
    control,
  } = useFormContext<Step2FieldValues>();

  return (
    <Field
      id="output_blob_names"
      label="Output blob names"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      info="Multiple values allowed. Press Enter key for every blob name."
      error={errors.inference_config?.output_blob_names}
      required
    >
      <Controller
        name="inference_config.output_blob_names"
        render={({ field: { onChange, value } }) => (
          <MultiSelectInput
            id="output_blob_names"
            onChange={onChange}
            selected={value}
            allowCreate
          />
        )}
        rules={{
          required: 'Please enter a value.',
        }}
        control={control}
      />
    </Field>
  );
}
