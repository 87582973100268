import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Dashboard from 'types/dashboard';

import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';

export function useUpdateDashboard({
  onMutate,
  onSettled,
  onError,
  ...options
}: UseMutationOptions<Dashboard, APIError, Dashboard> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation(api.dashboards.update, {
    ...options,
    onMutate(dashboard) {
      onMutate?.(dashboard);

      return updateQueriesData({
        queryClient,
        listQueryKey: ['dashboards'],
        singleQueryKey: ['dashboard'],
        ids: [dashboard.id],
        updateData: dashboard,
      });
    },
    onError(error, updatedStream, context) {
      onError?.(error, updatedStream, context);

      context?.previousLists?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSettled(data, ...args) {
      onSettled?.(data, ...args);
      queryClient.invalidateQueries(['dashboards']);
      queryClient.invalidateQueries(['dashboard', data?.id].filter(Boolean));
    },
  });
}
