import React from 'react';
import classNames from 'classnames/bind';

import { Input } from 'components/Input/Input';
import { Textarea } from 'components/Textarea/Textarea';
import { Text } from 'components/Text/Text';

import Row from './JsonObjectInputRow';
import styles from './JsonObjectInput.module.scss';

const c = classNames.bind(styles);

export type JsonObjectInputRowFieldProps = {
  id: string;
  value: Row;
  error?: boolean;
  onChange: (value: Row) => void;
  onBlur: (event: React.FocusEvent<HTMLElement>) => void;
};

export function JsonObjectInputRowField({
  id,
  value: { key, value },
  error,
  onChange,
  onBlur,
}: JsonObjectInputRowFieldProps) {
  return (
    <div className={c('form-group')} key={id}>
      <div className={c('form-item')}>
        <label htmlFor={`${id}-key`} className="sr-only">
          Key
        </label>
        <Input
          id={`${id}-key`}
          placeholder="Key"
          value={key}
          onValueChange={(updatedKey) => onChange(new Row(updatedKey, value))}
          error={error}
          onBlur={onBlur}
        />
        {error && (
          <Text intent="danger">
            Key is not unique. Duplicate keys will be removed.
          </Text>
        )}
      </div>
      <div className={c('form-item')}>
        <label htmlFor={`${id}-value`} className="sr-only">
          Value
        </label>
        <Textarea
          id={`${id}-value`}
          placeholder="Value"
          value={value}
          onChange={({ target }) => onChange(new Row(key, target.value))}
          onBlur={onBlur}
          variant="code"
          size="small"
        />
      </div>
    </div>
  );
}
