import { DateTime } from 'luxon';
import { FilterInput, FilterOperator } from '@propeldata/ui-kit';
import { gql } from 'graphql-request';

import {
  CustomTimeSeriesQueryVariables,
  TimerSeriesResponse,
  TimeSeriesQueryVariables,
} from 'graphql/queries/timeseries';

import { useLeaderboard } from 'dashboards/hooks/useLeaderboard';
import { usePropelQuery } from 'graphql/hooks/usePropelQuery';

import { WIDGET_REFETCH_INTERVAL } from '../services/interval';

export type CustomTimeSeriesResult = {
  data?: { seriesLabels: string[]; seriesData: TimerSeriesResponse };
  isLoading: boolean;
  error?: string;
};

/**
 * Fetches data in stacked bar chart format.
 * Propel API doesn't support this yet so this is a workaround.
 *
 * 1. Parameter {@link dimensions} contains the dimension the groups should be divided by.
 * 2. Unique values of this dimension are fetched with leaderboard API.
 * 3. For each of the unique values there is a timeseries query where the filter is set to the dimension and the unique value.
 * 4. Each of these queries return a timeseries response with data points that make up one stack of the bar.
 */
export function useCustomTimeSeries({
  metric,
  dimensions,
  granularity,
  timeRange,
  filters: userFilters,
  timeZone,
}: CustomTimeSeriesQueryVariables): CustomTimeSeriesResult {
  const {
    data: leaderboardResponse,
    isLoading: isLoadingLeaderboard,
    error: leaderboardError,
  } = useLeaderboard(
    {
      metric,
      dimensions,
      timeRange,
      rowLimit: 1000,
      filters: userFilters,
      timeZone,
    },
    { keepPreviousData: true }
  );

  const rows = leaderboardResponse?.leaderboard.rows;
  // The dimension which divides the bars
  const column = leaderboardResponse?.leaderboard.headers[0];

  // Collect all unique values associated with the column
  const values = new Set<string>();
  rows?.forEach(([value]) => {
    // FIXME Remove the \x00 filtering once null-character issue is resolved
    if (value !== null && value !== undefined && value !== '\x00') {
      values.add(value);
    }
  });

  const uniqueValues = [...values];
  const queryCount = uniqueValues.length;
  // Limit stacked bar chart to 100 unique values
  const hasReachedLimit = queryCount > 100;

  const dynamicQuery = uniqueValues
    .map(
      (_, index) => gql`
        timeSeries${index}: timeSeries(input: $input${index}) {
          labels
          values
        }
      `
    )
    .join('');

  const dynamicParameters = uniqueValues
    .map((_, index) => `$input${index}: TimeSeriesInput!`)
    .join(', ');

  const timeSeriesQuery = gql`
    query TimeSeries(${dynamicParameters}){
      ${dynamicQuery}
    }
  `;

  const filters: FilterInput[] = column
    ? uniqueValues.map((value) => ({
        column,
        operator: FilterOperator.Equals,
        value,
        and: userFilters,
      }))
    : [];

  const variables: Record<string, TimeSeriesQueryVariables> = {};
  filters.forEach(
    (filter, index) =>
      (variables[`input${index}`] = {
        metric,
        granularity,
        timeRange,
        filters: [filter],
        timeZone,
      })
  );

  const {
    data: timeSeriesResponse,
    isLoading,
    error,
  } = usePropelQuery<TimerSeriesResponse>(
    timeSeriesQuery,
    variables,
    ['timeseries-custom-grouped'],
    {
      enabled: Boolean(queryCount) && !hasReachedLimit,
      keepPreviousData: true,
      refetchInterval: WIDGET_REFETCH_INTERVAL,
    }
  );

  if (!leaderboardResponse) {
    return {
      isLoading: isLoadingLeaderboard,
      error: leaderboardError ? 'Failed to fetch dimension values.' : undefined,
    };
  }

  if (leaderboardResponse?.leaderboard.rows.length === 0) {
    // Show empty time series when leaderboard api returns no value
    const start = DateTime.fromISO(timeRange.start);
    const end = DateTime.fromISO(timeRange.stop);

    const labels: string[] = [];
    let currentDay = start;

    while (currentDay <= end) {
      labels.push(currentDay.toUTC().toISO()!);
      currentDay = currentDay.plus({ days: 1 });
    }

    return {
      data: {
        seriesLabels: uniqueValues,
        seriesData: {
          timeSeries: { labels, values: Array(labels.length).fill(0) },
        },
      },
      isLoading: false,
    };
  }

  if (hasReachedLimit) {
    return {
      isLoading: false,
      error: `${column} has too many values. Please select another dimension.`,
    };
  }

  return {
    data: timeSeriesResponse
      ? { seriesLabels: uniqueValues, seriesData: timeSeriesResponse }
      : undefined,
    isLoading,
    error: error
      ? 'Time Series chart encountered unexpected error.'
      : undefined,
  };
}
