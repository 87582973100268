import React from 'react';
import classnames from 'classnames/bind';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import APIError from 'types/api_error';

import { useAccount } from 'hooks/api/useAccount';
import { useAPI } from 'hooks/api/useAPI';
import { useApplications } from 'hooks/api/useApplications';
import { useAuthentication } from 'hooks/api/useAuthentication';
import { useOrganizations } from 'hooks/api/useOrganizations';

import AppContainer from 'application/views/App/AppContainer';

import { Heading } from 'components/Heading/Heading';
import { InstanceBanner } from 'components/InstanceBanner/InstanceBanner';
import { Loader } from 'components/Loader/Loader';
import { Logo } from 'components/Logo/Logo';
import { Nav } from 'components/Nav/Nav';
import { RequireAuth } from 'components/RequireAuth/RequireAuth';
import { SuspensionBanner } from 'organizations/components/SuspensionBanner/SuspensionBanner';

import styles from './Root.module.scss';

const c = classnames.bind(styles);

type AppOrgErrorProps = {
  error: APIError;
};

function AppOrgError({ error }: AppOrgErrorProps) {
  return (
    <div className={c('error-screen', 'error-message')}>
      <Logo className={c('error-screen-logo')} />

      <div className="error-message-content">
        <Heading level="1">{error.message}</Heading>
      </div>
    </div>
  );
}

export function Root() {
  const organizations = useOrganizations();
  const applications = useApplications();
  const account = useAccount();
  const isLoading =
    organizations.isLoading || applications.isLoading || account.isLoading;
  const location = useLocation();

  const { setApplicationID } = useAPI();

  const { isAuthenticated } = useAuthentication();

  const nextAppID = React.useMemo(() => {
    if (!applications.data || !applications.data.length) {
      return;
    }

    const [firstApp] = applications.data;
    const lastUsedAppID = localStorage.getItem('console.applicationID');

    return lastUsedAppID &&
      applications.data.some(({ id }) => id === lastUsedAppID)
      ? lastUsedAppID
      : firstApp.id;
  }, [applications.data]);

  React.useEffect(() => {
    if (!nextAppID) {
      return;
    }

    setApplicationID(nextAppID);
  }, [nextAppID, setApplicationID]);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (organizations.error || applications.error) {
    return (
      <AppOrgError
        error={
          (organizations.error || applications.error) as unknown as APIError
        }
      />
    );
  }

  if (isLoading || !organizations.data || !applications.data) {
    return <Loader text="Loading your workspace..." showLogo />;
  }

  if (!applications.data.length) {
    return <Navigate to="/welcome" />;
  }

  return (
    <div className={c('page-wrap')}>
      <InstanceBanner />

      <main className={c('page')}>
        <Routes>
          <Route
            path="/applications/:applicationID/*"
            element={
              <RequireAuth>
                <SuspensionBanner />
                <Nav className={c('navigation')} />
                <AppContainer />
              </RequireAuth>
            }
          />

          <Route
            path="*"
            element={
              <RequireAuth>
                <Navigate to={`/applications/${nextAppID}`} />
              </RequireAuth>
            }
          />
        </Routes>
      </main>
    </div>
  );
}
