import { useForm } from 'react-hook-form';

import Deployment, { DeploymentConfiguration } from 'types/deployment';
import Pipeline from 'types/pipeline';
import { VideoSource } from 'hooks/api/useVideoSources';

import { useDeploymentParameters } from 'deployments/hooks/useDeploymentParameters';
import { useActivePipelineOptional } from 'pipelines/context/active_pipeline';
import { useDeploymentForm } from './context';

export function useDeploymentFormContent(
  deployment?: Deployment,
  videoSource?: VideoSource,
  pipeline?: Pipeline
) {
  const { readonly } = useDeploymentForm();

  const pipelineOverride = useActivePipelineOptional();
  const defaultValues = useDeploymentParameters(
    deployment,
    pipelineOverride ?? pipeline,
    videoSource
  );

  return useForm<DeploymentConfiguration>({
    defaultValues,
    mode: 'onChange',
    shouldUnregister: !readonly,
  });
}
