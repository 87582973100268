import React from 'react';
import { IntercomProvider as BaseProvider } from 'react-use-intercom';

import { IntercomContext, useIntercomState } from '.';

export function IntercomProvider({ children }: React.PropsWithChildren<{}>) {
  const [unreadCount, setUnreadCount] = React.useState(0);

  return (
    <BaseProvider
      appId="ybntxa5z"
      shouldInitialize={process.env.REACT_APP_INTERCOM_ENABLED === 'true'}
      onUnreadCountChange={setUnreadCount}
    >
      <IntercomContext.Provider value={{ unreadCount }}>
        {children}
      </IntercomContext.Provider>
    </BaseProvider>
  );
}

export { useIntercomState };
