import { deserialize, serialize, custom } from 'serializr';

export function objectOrNull(ObjectModel) {
  function toJson(modelValue) {
    if (!modelValue) {
      return null;
    }

    return serialize(ObjectModel, modelValue);
  }

  function toObject(jsonValue, _callback, _context) {
    if (!jsonValue || typeof jsonValue === 'undefined') {
      return null;
    }

    return deserialize(ObjectModel, jsonValue);
  }

  return custom(toJson, toObject);
}

export function anyObject() {
  function getObject(object) {
    if (!object) {
      return {};
    }

    if (typeof object === 'string') {
      return JSON.parse(object);
    }

    return object;
  }

  return custom(getObject, getObject);
}

export function objectArrayOrNull(ObjectModel) {
  function modelToJson(modelValue) {
    if (!modelValue) {
      return null;
    }

    return serialize(ObjectModel, modelValue);
  }

  function jsonToModel(jsonValue, _callback, _context) {
    if (!jsonValue) {
      return [];
    }

    return deserialize(ObjectModel, jsonValue);
  }

  return custom(modelToJson, jsonToModel);
}
