import * as RadixDialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import classNames from 'classnames/bind';

import styles from './Dialog.module.scss';

const c = classNames.bind(styles);

export type TitleProps = RadixDialog.DialogTitleProps & {
  visuallyHidden?: boolean;
};

export function Title({ visuallyHidden, className, ...props }: TitleProps) {
  if (visuallyHidden) {
    return (
      <VisuallyHidden>
        <RadixDialog.Title {...props} />
      </VisuallyHidden>
    );
  }

  return <RadixDialog.Title className={c(className, 'title')} {...props} />;
}
