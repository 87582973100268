export function lastElement<T>(array: T[]): T | undefined {
  return array.length > 0 ? array[array.length - 1] : undefined;
}

/**
 * Checks if two arrays are equal.
 * Only does shallow comparison and does not take sort order into account.
 *
 * @param {any[]} a - The first array to compare.
 * @param {any[]} b - The second array to compare.
 * @returns {boolean} - True if the arrays are equal.
 */
export function areEqualArrays(a: any[], b: any[]) {
  return a.length === b.length && a.every((value, index) => value === b[index]);
}
