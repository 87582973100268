import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';

import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';

/**
 * Delete reference pipelines from universal bridge.
 */
export function useDeleteReferencePipelines(
  camera: Camera,
  {
    onMutate,
    onError,
    onSettled,
    ...options
  }: UseMutationOptions<
    Camera['reference_pipeline_ids'],
    APIError,
    string[]
  > = {}
) {
  const queryClient = useQueryClient();
  const { cameras } = useAPI();

  return useMutation(
    ['delete-reference-pipelines'],
    (pipelineIDs) => {
      if (!camera.id) {
        return Promise.reject('No camera ID provided.');
      }

      if (!pipelineIDs || !pipelineIDs.length) {
        return Promise.resolve([]);
      }

      return cameras.deleteReferencePipelines(camera.id, pipelineIDs);
    },
    {
      ...options,
      async onMutate(pipelineIDs) {
        await queryClient.cancelQueries(['cameras']);
        await queryClient.cancelQueries(['camera', camera.id]);

        onMutate?.(pipelineIDs);

        return updateQueriesData({
          queryClient,
          listQueryKey: ['cameras'],
          singleQueryKey: ['camera'],
          ids: [camera.id],
          updateData() {
            const updatedCamera = camera.copy();
            updatedCamera.reference_pipeline_ids =
              camera.reference_pipeline_ids.filter(
                (id) => !pipelineIDs.some((pipelineID) => pipelineID === id)
              );
            return updatedCamera;
          },
        });
      },
      async onError(error, camera, context) {
        onError?.(error, camera, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(...args) {
        onSettled?.(...args);

        queryClient.invalidateQueries(['camera', camera.id]);
        queryClient.invalidateQueries(['cameras']);
      },
    }
  );
}
