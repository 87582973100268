import { Controller, useFormContext } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { Radio, RadioOption } from 'components/Radio/Radio';

import { FIELD_LABEL_WIDTH, Step2FieldValues } from '../Step2';

export function NetworkModeInput() {
  const {
    control,
    formState: { errors },
  } = useFormContext<Step2FieldValues>();

  return (
    <Field
      id="network_mode"
      label="Network precision"
      labelWidth={FIELD_LABEL_WIDTH}
      labelVerticalAlign="flex-start"
      error={errors.inference_config?.network_mode}
      required
    >
      <Controller
        name="inference_config.network_mode"
        control={control}
        defaultValue="float16"
        render={({ field: { value, onChange } }) => (
          <Radio id="network_mode" value={value} onChange={onChange}>
            <RadioOption value="float16">Float16</RadioOption>
            <RadioOption value="float32">Float32</RadioOption>
            <RadioOption value="int8">Int8</RadioOption>
          </Radio>
        )}
        rules={{ required: 'Please select a network mode.' }}
      />
    </Field>
  );
}
