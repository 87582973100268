import React from 'react';

import { DashboardWidget } from 'types/dashboard_widget';

export type WidgetModalContextProps = {
  editWidget: (widgetID: DashboardWidget['id']) => void;
  onClose: () => void;
};

export const WidgetModalContext = React.createContext<
  WidgetModalContextProps | undefined
>(undefined);

export function useWidgetModal() {
  const context = React.useContext(WidgetModalContext);

  if (context === undefined) {
    throw new Error(
      'Hook `useWidgetModal` can only be used within a `<WidgetModalContext.Provider />`.'
    );
  }

  return context;
}
