import React from 'react';
import classNames from 'classnames/bind';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useUncontrolled } from '@mantine/hooks';

import { Icon, IconType } from 'components/Icon/Icon';
import { OptionalTooltip } from 'components/Tooltip/OptionalTooltip';

import { RadioContext, RadioContextProps, RadioValue } from './Radio';
import styles from './Radio.module.scss';

const c = classNames.bind(styles);

export type RadioOptionProps = React.PropsWithChildren<{
  value: RadioValue;
  icon?: IconType;
  disabled?: boolean | ((keyrefValue: any) => boolean);
  disabledTooltip?: JSX.Element | string;
  keyrefValue?: any;
}>;

export function RadioOption(props: RadioOptionProps) {
  const context = React.useContext(RadioContext);

  if (!context) {
    throw new Error(
      "Can't use component `RadioOption` outside of a `Radio` component."
    );
  }

  return <RadioOptionInner {...props} />;
}

function RadioOptionInner({
  children,
  value,
  icon,
  disabled,
  disabledTooltip,
  keyrefValue,
}: RadioOptionProps) {
  const {
    value: radioValue,
    onChange,
    ...context
  } = React.useContext(RadioContext) as RadioContextProps;

  const [isChecked, setIsChecked] = useUncontrolled({
    value: radioValue === value,
    defaultValue: radioValue === value,
    onChange(isChecked) {
      if (isChecked) {
        onChange(value);
      }
    },
  });
  const id = `${context.id}_${value}`;
  const htmlValue = value ? String(value) : '';

  const isOptionDisabled =
    context.disabled ||
    (typeof disabled === 'function' ? disabled(keyrefValue) : disabled);

  function handleLabelClick(event: React.MouseEvent<HTMLLabelElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (isOptionDisabled) {
      return;
    }

    setIsChecked(!isChecked);
  }

  return (
    <OptionalTooltip content={disabledTooltip} showTooltip={Boolean(disabled)}>
      <div className={c('option')}>
        <VisuallyHidden asChild>
          <input
            id={id}
            name={context.id}
            type="radio"
            value={htmlValue}
            disabled={isOptionDisabled}
            checked={isChecked}
            onChange={(event) => setIsChecked(event.target.checked)}
          />
        </VisuallyHidden>
        <label htmlFor={id} className={c('faux')} onClick={handleLabelClick}>
          {icon && <Icon name={icon} className={c('icon')} size="small" />}

          <span className={c('content')}>{children}</span>

          {radioValue === value && (
            <Icon name="check" className={c('active-marker')} size="small" />
          )}
        </label>
      </div>
    </OptionalTooltip>
  );
}
