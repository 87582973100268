import { useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { Tag } from 'types/tag';

import { useAPI } from 'hooks/api/useAPI';
import {
  useBillingMutation,
  UseBillingMutationOptions,
} from 'hooks/api/useBillingMutation';

type UseCreateTagParams = {
  name: Tag['name'];
  parent?: Tag['id'];
};

type UseCreateTagOptions = Omit<
  UseBillingMutationOptions<Tag, APIError, UseCreateTagParams>,
  'action' | 'entity'
>;

export function useCreateTag({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseCreateTagOptions = {}) {
  const queryClient = useQueryClient();
  const { applicationID, client } = useAPI();

  return useBillingMutation(
    async (params) => {
      const { data } = await client.post<Tag>(
        `/apps/${applicationID}/tags`,
        params
      );

      return data;
    },
    {
      ...options,
      action: 'create',
      entity: 'tag',
      onSettled(...args) {
        onSettled?.(...args);

        queryClient.invalidateQueries(['tags']);
      },
    }
  );
}
