import { useQueryClient } from '@tanstack/react-query';
import Stream, { isStreamState } from 'types/stream';

import Camera from 'types/camera';
import { OffsetPaginated } from 'types/pagination';

import {
  OnMessagePayload,
  SubscriptionMap,
  useMqttSubscribe,
} from 'hooks/useMqttSubscribe';
import { isNotNullOrUndefined } from 'services/nullable';
import { updateQueriesData } from 'services/update_queries_data';
import { useAPI } from 'hooks/api/useAPI';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';

export function useMqttStreamsState(ids: Stream['id'][]) {
  const queryClient = useQueryClient();
  const { applicationID } = useAPI();

  const invalidate = useDebouncedCallback(
    (streamId: Stream['id']) => {
      queryClient.invalidateQueries(['streams']);
      queryClient.invalidateQueries(['stream', streamId]);
    },
    [queryClient]
  );

  async function onMessage({ topic, message }: OnMessagePayload) {
    const [, , , streamId] = topic.split('/');

    if (!isStreamState(message)) {
      return;
    }

    const listQueries = queryClient.getQueriesData<OffsetPaginated<Stream>>([
      'streams',
    ]);

    const singleQueries = queryClient.getQueriesData<Camera>([
      'stream',
      streamId,
    ]);

    let isStaleListQuery = false;
    let isStaleSingleQuery = false;

    if (listQueries.length) {
      const listQueryStates = listQueries
        .map(([queryKey]) =>
          queryClient.getQueryState<OffsetPaginated<Stream>>(queryKey)
        )
        .filter(isNotNullOrUndefined);

      if (listQueryStates.length) {
        const { data: latestStreams } = listQueryStates.reduce(
          (latest, current) =>
            latest.dataUpdatedAt > current.dataUpdatedAt ? latest : current
        );

        if (!latestStreams || !latestStreams.data) return;

        isStaleListQuery = latestStreams.data.some(
          ({ id, status }) => id === streamId && status !== message
        );
      }
    }

    if (singleQueries.length) {
      const singleQueryStates = singleQueries
        .map(([queryKey]) => queryClient.getQueryState<Stream>(queryKey))
        .filter(isNotNullOrUndefined);

      if (singleQueryStates.length) {
        const { data: latestStream } = singleQueryStates.reduce(
          (latest, current) =>
            latest.dataUpdatedAt > current.dataUpdatedAt ? latest : current
        );

        if (!latestStream) return;

        isStaleSingleQuery = latestStream.status !== message;
      }
    }

    if (isStaleListQuery || isStaleSingleQuery) {
      await queryClient.cancelQueries(['streams']);
      await queryClient.cancelQueries(['stream', streamId]);

      updateQueriesData<Stream>({
        queryClient,
        listQueryKey: ['streams'],
        singleQueryKey: ['stream'],
        ids: [streamId],
        updateData(stream) {
          stream.status = message;
          return stream;
        },
      });

      invalidate(streamId);
    }
  }

  const subscriptions: SubscriptionMap = {};

  if (applicationID) {
    for (const id of ids) {
      const topic = `apps/${applicationID}/streams/${id}/status`;
      subscriptions[topic] = { onMessage };
    }
  }

  useMqttSubscribe(subscriptions);
}
