import React from 'react';
import classnames from 'classnames/bind';
import { useToggle } from '@mantine/hooks';

import Deployment from 'types/deployment';
import Pipeline from 'types/pipeline';

import { pluralize } from 'services/string';
import { useAllDeployments } from 'deployments/hooks/useAllDeployments';
import { usePipeline } from 'hooks/api/usePipeline';
import { useBatchUpdateDeployments } from 'deployments/hooks/useBatchUpdateDeployments';

import { Drawer } from 'components/Drawer/Drawer';
import { Text } from 'components/Text/Text';

import { BulkOverrideDeploymentsFormContent } from './Content';
import { DeploymentFormActions } from '../Actions';
import styles from '../Form.module.scss';

const c = classnames.bind(styles);

type BulkOverrideDeploymentsFormProps = {
  pipelineId: Pipeline['id'];
  deploymentIds?: Record<Deployment['id'], boolean>;
  open?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
};

export function BulkOverrideDeploymentsForm({
  pipelineId,
  deploymentIds = {},
  onSuccess,
  onCancel,
  ...drawerProps
}: BulkOverrideDeploymentsFormProps) {
  const { data: pipeline, isInitialLoading: isLoadingPipeline } =
    usePipeline(pipelineId);

  const [formStep, setFormStep] = React.useState(1);

  const [selection, setSelection] = React.useState(deploymentIds);
  const selectedDeploymentsCount = Object.keys(selection).length;

  const { data: deployments } = useAllDeployments({
    params: {
      deployment_ids:
        selectedDeploymentsCount > 0 ? Object.keys(selection) : undefined,
      pipeline_ids: [pipelineId],
    },
    enabled: formStep > 1,
  });
  const deploymentsCount = deployments?.length || Object.keys(selection).length;

  const [shouldStartImmediately, toggleShouldStartImmediately] = useToggle([
    true,
    false,
  ]);

  const formRef = React.useRef<HTMLFormElement>(null);

  const {
    mutate,
    error,
    isLoading: isUpdating,
  } = useBatchUpdateDeployments({
    onSuccess() {
      onSuccess?.();
      resetForm();
    },
  });

  function resetForm() {
    setSelection({});
    setFormStep(1);
  }

  function handleClose() {
    onCancel?.();
    resetForm();
  }

  return (
    <Drawer
      {...drawerProps}
      title="Bulk update deployments"
      onClose={handleClose}
      containerClassName={c('form-container')}
      footer={
        formStep === 2 && (
          <DeploymentFormActions
            pipeline={pipeline}
            buttonText={`Update ${deploymentsCount} ${pluralize(deploymentsCount, 'deployment')}`}
            onSubmit={formRef.current?.onSubmit}
            isDeploying={isUpdating}
            startImmediately={shouldStartImmediately}
            onToggleStartImmediately={toggleShouldStartImmediately}
            deployments={deployments}
            isUpdateDeploymentsForm
            borderless
          />
        )
      }
    >
      {isLoadingPipeline && <Text>Loading...</Text>}

      {pipeline && (
        <BulkOverrideDeploymentsFormContent
          shouldStartImmediately={shouldStartImmediately}
          pipeline={pipeline}
          deploymentIds={deploymentIds}
          onFormStepChange={setFormStep}
          onSelect={setSelection}
          mutate={mutate}
          error={error}
          ref={formRef}
        />
      )}
    </Drawer>
  );
}
