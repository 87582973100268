import { NodeEditor } from 'rete';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import { Input, InputProps } from 'components/Input/Input';

export type ReteHiddenInputParams = Omit<
  ReteInputParams,
  'inputType' | 'unit' | 'placeholder' | 'info'
>;

export default class ReteHiddenInput extends ReteInput<
  ReteInputProps & InputProps
> {
  constructor(editor: NodeEditor, key: string, params: ReteHiddenInputParams) {
    super(editor, key, { ...params, type: 'string' });
    this.props.type = 'hidden';
  }

  get component() {
    return ReteHiddenInputComponent;
  }
}

function ReteHiddenInputComponent(props: ReteInputProps & InputProps) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange) {
      props.onChange(event.target.value ?? null);
    }
  }
  return <Input {...props} onChange={handleChange} />;
}
