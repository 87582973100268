import React from 'react';
import classNames from 'classnames/bind';

import { ItemProps } from './Item';
import styles from './List.module.scss';

const c = classNames.bind(styles);

type AllowedChildren = React.ReactElement<ItemProps> | null | false;

export type RootProps = {
  children: AllowedChildren | AllowedChildren[];
  className?: string;
  ordered?: boolean;
};

export function Root({ children, ordered, className }: RootProps) {
  const Element = ordered ? 'ol' : 'ul';
  return (
    <Element className={c(className, 'root', { ordered })}>{children}</Element>
  );
}
