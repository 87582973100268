import classNames from 'classnames/bind';

import { Radio, RadioOption } from 'components/Radio/Radio';

import { LogicalOperator } from 'pipelines/services/condition_parser';
import styles from './ConditionInput.module.scss';

const c = classNames.bind(styles);

export type OperatorInputProps = {
  defaultValue?: LogicalOperator;
  path: number[];
  onChange: (value: LogicalOperator) => void;
};

export function OperatorSwitch({
  defaultValue,
  path,
  onChange,
}: OperatorInputProps) {
  return (
    <Radio
      className={c('operator-switch')}
      id={`${path.join('.')}-operator`}
      defaultValue={defaultValue}
      onChange={(value) => onChange(value as LogicalOperator)}
    >
      <RadioOption value="and">and</RadioOption>
      <RadioOption value="or">or</RadioOption>
    </Radio>
  );
}
