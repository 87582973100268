import React from 'react';
import classNames from 'classnames/bind';
import { components, PlaceholderProps } from 'react-select';
import { useLocation } from 'react-router-dom';

import Gateway from 'types/gateway';

import { useGateway } from 'gateways/hooks/useGateway';
import { useGateways } from 'hooks/api/useGateways';

import { Box } from 'components/Box/Box';
import { Field } from 'components/Field/Field';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Select } from 'components/Select/Select';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';

import { LinkGatewayCamera } from '../LinkGatewayCamera/LinkGatewayCamera';
import styles from './DiscoverCameras.module.scss';

const c = classNames.bind(styles);

export type DiscoverCamerasProps = {
  gateway?: Gateway;
};

export function DiscoverCameras({
  gateway: defaultGateway,
}: DiscoverCamerasProps) {
  const { data: gateways } = useGateways();
  const { state } = useLocation();

  const defaultGatewayID =
    defaultGateway?.id ??
    (state && state?.fromGateway ? state.fromGateway : undefined);

  const [gatewayID, setGatewayID] = React.useState(defaultGatewayID);
  const { data: gateway } = useGateway(gatewayID, {
    enabled: Boolean(gatewayID),
  });
  const isGatewayOffline =
    defaultGateway?.isOffline() ?? gateway?.isOffline() ?? false;

  const gatewayOptions = gateways?.data.map((gateway) => ({
    label: gateway.name,
    value: gateway.id,
  }));

  React.useEffect(() => {
    if (defaultGatewayID) {
      setGatewayID(defaultGatewayID);
    }
  }, [defaultGatewayID]);

  return (
    <>
      {!defaultGateway && (
        <Field label="Select gateway">
          <Select
            id="up-add-camera_gateway"
            options={gatewayOptions}
            placeholder="Select gateway..."
            defaultValue={gatewayOptions?.find(
              ({ value }) => value === defaultGatewayID
            )}
            onChange={(selected) => setGatewayID(selected?.value)}
            formatOptionLabel={formatOptionLabel}
            components={{ Placeholder }}
            isSearchable
          />
        </Field>
      )}

      <div id="up-add-camera_content">
        {!isGatewayOffline && gatewayID && (
          <LinkGatewayCamera gatewayID={gatewayID!} />
        )}

        {!gatewayID && (
          <Box className={c('no-selection-hint')}>
            <Text>Please select a gateway to continue.</Text>
          </Box>
        )}

        {gatewayID && isGatewayOffline && (
          <Box className={c('box')}>
            <StatusIndicator
              className={c('box-icon')}
              status="offline"
              size="small"
            />
            <Heading className={c('box-heading')} level="3">
              The selected gateway is offline.
            </Heading>
            <Text className={c('box-text')} type="paragraph">
              Camera discovery is unavailable. Please make sure your gateway is
              connected to the internet or try adding a camera manually.
            </Text>
          </Box>
        )}
      </div>
    </>
  );
}

type GatewayOption = {
  label: string;
  value: string;
};

function formatOptionLabel({ label }: GatewayOption) {
  return (
    <div className={c('option')}>
      <Icon name="gateway" size="small" />
      <span>{label}</span>
    </div>
  );
}

function Placeholder({ cx, ...props }: PlaceholderProps<GatewayOption, false>) {
  return (
    <components.Placeholder {...props} cx={cx} className={c('option')}>
      <Icon name="gateway" size="small" />
      <span>{props.children}</span>
    </components.Placeholder>
  );
}
