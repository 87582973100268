import React from 'react';
import { SingleValue } from 'react-select';

import { Select, SelectProps } from 'components/Select/Select';
import { Status } from 'components/StatusIndicator/StatusIndicator';

type StateOption = {
  value: Status;
  label: Status;
};

export type StateSelectProps = Partial<
  Pick<SelectProps<StateOption>, 'size' | 'className'>
> & {
  options: Status[] | readonly Status[];
  value?: Status;
  defaultValue?: Status;
  onChange?: (state: Status | null) => void;
};

export function StateSelect({
  options,
  value,
  defaultValue,
  onChange,
  ...props
}: StateSelectProps) {
  const mappedOptions = React.useMemo<StateOption[]>(
    () =>
      options?.map((state) => ({ value: state, label: state }) as StateOption),
    [options]
  );
  const mappedValue = mappedOptions.find((option) => option.value === value);
  const mappedDefaultValue = mappedOptions.find(
    (option) => option.value === defaultValue
  );

  function handleChange(option: SingleValue<StateOption>) {
    onChange?.(option?.value ?? null);
  }

  return (
    <Select
      {...props}
      options={mappedOptions}
      defaultValue={mappedDefaultValue}
      value={mappedValue}
      onChange={handleChange}
      placeholder="State"
      isClearable
    />
  );
}
