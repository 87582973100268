import Stream from 'types/stream';
import { useNavigate } from 'react-router-dom';

import { useDeleteStream } from 'streams/hooks/useDeleteStream';
import { useMqttStreamsState } from 'streams/hooks/useMqttStreamsState';

import { DetailViewStatus } from 'components/DetailView/DetailView';
import { IconButton } from 'components/IconButton/IconButton';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';

export type StreamDetailActionsProps = {
  stream: Stream;
  isEditable?: boolean;
  onRename?: () => void;
};

export function StreamDetailActions({
  stream,
  isEditable = true,
  onRename,
}: StreamDetailActionsProps) {
  const navigate = useNavigate();

  useMqttStreamsState([stream.id]);

  const [requestDelete, { isLoading }] = useDeleteStream(['streams'], {
    // Navigate to overview
    onMutate() {
      navigate('./..');
    },
  });

  function handleDeleteClick() {
    requestDelete(stream.id);
  }

  return (
    <DetailViewStatus entity={stream} onRename={onRename}>
      <StatusIndicator status={stream.status} />

      {isEditable && stream.source === 'uri_stream' && (
        <IconButton
          icon="delete"
          label="Delete"
          intent="danger"
          variant="secondary"
          onClick={handleDeleteClick}
          loading={isLoading}
          size="small"
          showLabel
        />
      )}
    </DetailViewStatus>
  );
}
