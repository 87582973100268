import React from 'react';
import classnames from 'classnames/bind';
import { DateTime } from 'luxon';
import { Skeleton } from '@mantine/core';
import { Link, To } from 'react-router-dom';

import { RELATED_ENTITIES_KEYS } from 'types/event';

import { useCameras } from 'cameras/hooks/useCameras';
import { useCurrentApplication } from 'application/hooks/useCurrentApplication';
import { useDeployments } from 'hooks/api/useDeployments';
import { useEvents } from 'events/hooks/useEvents';
import { useFiles } from 'files/hooks/useFiles';
import { useGateways } from 'hooks/api/useGateways';
import { useHasAccess } from 'hooks/useHasAccess';
import { useStreams } from 'streams/hooks/useStreams';

import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Code } from 'components/Code/Code';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { Heading } from 'components/Heading/Heading';
import { Icon, IconType } from 'components/Icon/Icon';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';

import styles from './Events.module.scss';

const c = classnames.bind(styles);

export function OverviewEvents() {
  const { data: application } = useCurrentApplication();

  return (
    <Card className={c('events')}>
      <header className={c('header')}>
        <Heading level="4">
          Recent events {application ? `in ${application.name}` : ''}
        </Heading>

        <Button size="xsmall" to="deploy/events">
          View all events
        </Button>
      </header>

      <ul className={c('list')}>
        <OverviewEventsListItems />
      </ul>
    </Card>
  );
}

function OverviewEventsListItems() {
  const { data: events, isLoading: isLoadingEvents } = useEvents();

  const ids: Record<string, Array<string>> = Object.fromEntries(
    RELATED_ENTITIES_KEYS.map((key) => [`${key}s`, []])
  );

  if (events) {
    events.data.forEach((event) => {
      Object.entries(event.related_entities).forEach(([key, value]) => {
        value && !ids[`${key}s`].includes(value) && ids[`${key}s`].push(value);
      });
    });
  }

  const { camera_ids, deployment_ids, file_ids, gateway_ids, stream_ids } = ids;

  const { data: cameras, isInitialLoading: isLoadingCameras } = useCameras(
    { camera_ids, limit: camera_ids.length },
    { enabled: camera_ids.length > 0 }
  );

  const { data: deployments, isInitialLoading: isLoadingDeployments } =
    useDeployments(
      { deployment_ids, limit: deployment_ids.length },
      { enabled: deployment_ids.length > 0 }
    );

  const { data: files, isInitialLoading: isLoadingFiles } = useFiles({
    params: { file_ids, limit: file_ids.length },
    enabled: file_ids.length > 0,
  });

  const { data: gateways, isInitialLoading: isLoadingGateways } = useGateways(
    {
      gateway_ids,
      limit: gateway_ids.length,
    },
    { enabled: gateway_ids.length > 0 }
  );

  const { data: streams, isInitialLoading: isLoadingStreams } = useStreams(
    ['streams'],
    { stream_ids, limit: stream_ids.length },
    { enabled: stream_ids.length > 0 }
  );

  const isLoading =
    isLoadingEvents ||
    isLoadingCameras ||
    isLoadingDeployments ||
    isLoadingFiles ||
    isLoadingGateways ||
    isLoadingStreams;

  if (isLoading) {
    return (
      <SkeletonList
        component={
          <li className={c('event')}>
            <div className={c('timestamp')}>
              <Skeleton height="3rem" width="5rem" />
            </div>
            <div className={c('info')}>
              <div className={'icon'}>
                <Skeleton height="1rem" width="1rem" radius="1rem" />
              </div>
              <div className={c('payload')}>
                <Skeleton height="1rem" width="80%" />
              </div>
              <div className={c('entities')}>
                <Skeleton height="1rem" width="50%" />
              </div>
            </div>
            <div>
              <Skeleton height="1.5rem" width="10rem" />
            </div>
          </li>
        }
        min={10}
        max={10}
      />
    );
  }

  if (events && events.data.length === 0) {
    return <EmptyView>No events recorded yet.</EmptyView>;
  }

  if (!events) {
    return null;
  }

  return (
    <>
      {events.data.map((event) => {
        const { related_entities } = event;

        const camera =
          related_entities.camera_id &&
          cameras?.data.find(({ id }) => id === related_entities.camera_id);

        const deployment =
          related_entities.deployment_id &&
          deployments?.data.find(
            ({ id }) => id === related_entities.deployment_id
          );

        const file =
          related_entities.file_id &&
          files?.data.find(({ id }) => id === related_entities.file_id);

        const gateway =
          related_entities.gateway_id &&
          gateways?.data.find(({ id }) => id === related_entities.gateway_id);

        const stream =
          related_entities.stream_id &&
          streams?.data.find(({ id }) => id === related_entities.stream_id);

        return (
          <li key={event.id} className={c('event')}>
            <div className={c('timestamp')}>
              {DateTime.fromISO(event.event_ts).toLocaleString(
                DateTime.DATE_MED
              )}
              <br />
              {DateTime.fromISO(event.event_ts).toLocaleString(
                DateTime.TIME_SIMPLE
              )}
            </div>
            <div className={c('info')}>
              <div className={'icon'}>
                <StatusIndicator
                  className={'icon'}
                  status={event.severity}
                  size="small"
                />
              </div>
              <Text className={c('payload')} inline>
                {event.payload}
              </Text>
              <div className={c('entities')}>
                {camera && (
                  <EntityLink
                    to={`deploy/cameras/${camera.id}`}
                    name={camera.name}
                    icon="camera"
                  />
                )}
                {deployment && (
                  <EntityLink
                    to={`deploy/deployments/${deployment.id}`}
                    name={deployment.name || ''}
                    icon="deployment"
                  />
                )}
                {file && (
                  <div className={c('center')}>
                    <Icon name="file" size="small" />
                    <span>{file.name}</span>
                  </div>
                )}
                {gateway && (
                  <EntityLink
                    to={`deploy/gateways/${gateway.id}`}
                    name={gateway.name}
                    icon="gateway"
                  />
                )}
                {stream && (
                  <EntityLink
                    to={`deploy/streams/${stream.id}`}
                    name={stream.name}
                    icon="stream"
                  />
                )}
              </div>
            </div>
            <div>
              <Code size="small">{event.event_type}</Code>
            </div>
          </li>
        );
      })}
    </>
  );
}

type EntityLinkProps = {
  to: To;
  name: string;
  icon: IconType;
};

function EntityLink({ to, name, icon }: EntityLinkProps) {
  const [hasAccess] = useHasAccess();

  if (hasAccess('deploy')) {
    return (
      <Link to={to}>
        <div className={c('center')}>
          <Icon name={icon} size="small" />
          <span className={c('entity-link')}>{name}</span>
        </div>
      </Link>
    );
  }

  return (
    <div className={c('center')}>
      <Icon name="stream" size="small" />
      <span>{name}</span>
    </div>
  );
}
