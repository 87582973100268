import React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import classnames from 'classnames/bind';

import APIError from 'types/api_error';

import { Icon, IconType } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import styles from './Field.module.scss';

const c = classnames.bind(styles);

export type FieldProps = React.PropsWithChildren<{
  id?: string;
  label?: JSX.Element | React.ReactText | null;
  info?: JSX.Element | React.ReactText | null;
  className?: string;
  required?: boolean;
  error?:
    | Error
    | APIError
    | DeepMap<any, FieldError>
    | JSX.Element
    | React.ReactText;

  disabled?: boolean;
  disabledText?: string;

  icon?: IconType;

  inline?: boolean;
  labelWidth?: number;
  labelVerticalAlign?: React.CSSProperties['alignSelf'];

  inputWidth?: number;

  hidden?: boolean;
}>;

export function Field({
  className,
  label,
  id,
  icon,
  children,
  inline,
  labelWidth,
  inputWidth,
  labelVerticalAlign = 'center',
  disabled,
  disabledText,
  info,
  required,
  error,
  hidden,
}: FieldProps) {
  const labelStyle: React.CSSProperties = {
    alignSelf: labelVerticalAlign,
  };
  if (labelWidth) {
    labelStyle.flex = `0 0 ${labelWidth}px`;
    inline = true;
  }

  const inputStyle: React.CSSProperties = {};
  if (inputWidth) {
    inputStyle.flex = `0 0 ${inputWidth}px`;
  }

  return (
    <div
      className={c('field', { inline, required, error, hidden }, className)}
      aria-label={
        typeof label === 'string' ? label + (required ? ' (required)' : '') : ''
      }
    >
      {label && (
        <label
          htmlFor={id}
          className={c('input-label', 'label')}
          style={labelStyle}
        >
          <strong>
            {icon && <Icon name={icon} />}
            {label}
          </strong>
        </label>
      )}

      {!labelWidth && info && (
        <Text className={styles.info} type="paragraph" asChild>
          <label htmlFor={id}>{info}</label>
        </Text>
      )}

      <div className={styles.content} style={inputStyle}>
        {disabled && disabledText}
        {!disabled && children}

        {labelWidth && info && (
          <Text className={styles.info} type="paragraph">
            {info}
          </Text>
        )}

        {error && (
          <p className={c('error-message')}>
            <Icon name="warning" size="small" />
            <span>{(error as any).message || 'This field is required.'}</span>
          </p>
        )}
      </div>
    </div>
  );
}
