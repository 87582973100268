import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';
import classnames from 'classnames/bind';

import Application from 'types/application';
import Organization from 'types/organization';

import { useAPI } from 'hooks/api/useAPI';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useGroupedApplications } from 'application/hooks/useGroupedApplications';

import {
  ContextMenu,
  ContextMenuAction,
  ContextMenuGroup,
  ContextMenuLabel,
  ContextMenuOption,
  ContextMenuOptionGroup,
  ContextMenuSeparator,
} from 'components/ContextMenu/ContextMenu';
import { CreateApplication } from 'components/CreateApplication/CreateApplication';
import { Icon } from 'components/Icon/Icon';
import { LogoMark } from 'components/Logo/LogoMark';
import { Pill } from 'components/Pill/Pill';
import { Text } from 'components/Text/Text';
import { TitledModal } from 'components/TitledModal/TitledModal';

import styles from './ApplicationSelect.module.scss';

const c = classnames.bind(styles);

export function ApplicationSelect() {
  const queryClient = useQueryClient();
  const applications = queryClient.getQueryData<Application[]>([
    'applications',
  ]);
  const organizations = queryClient.getQueryData<Organization[]>([
    'organizations',
  ]);

  const navigate = useNavigate();

  const groupedApps = useGroupedApplications();

  const { applicationID } = useAPI();

  const [currentApplication, setCurrentApplication] =
    React.useState<Application>();
  const currentOrganization = organizations?.find(
    ({ id }) => currentApplication?.organization_id === id
  );
  const { data: plan, isLoading: isLoadingPlan } = useCurrentPlan();

  const [isCreating, toggleIsCreating] = useToggle();

  React.useEffect(() => {
    if (!applications) {
      return;
    }

    const current = applications.find(({ id }) => id === applicationID);

    if (current) {
      setCurrentApplication(current);
      localStorage.setItem('console.applicationID', current.id);
    }
  }, [applications, applicationID]);

  if (!applications || !organizations) {
    return null;
  }

  function handleApplicationSelect(applicationID: string, redirect = true) {
    if (!applications) {
      return;
    }

    const application = applications.find(({ id }) => id === applicationID);

    if (!application) {
      return;
    }

    setCurrentApplication(application);

    if (redirect && applicationID !== currentApplication?.id) {
      navigate(`/applications/${application.id}`);
    }
  }

  const isStarterPlan = plan?.id === process.env.REACT_APP_STARTER_PLAN_ID;

  return (
    <>
      <div className={c('wrap')}>
        <ContextMenu
          trigger={
            <button className={c('trigger')} aria-label="Select workspace">
              <div className={c('workspace-picture')}>
                <LogoMark
                  className={c('workspace-picture-img')}
                  alt="Lumeo logo"
                />
              </div>
              {currentApplication ? (
                <div>
                  {currentOrganization && (
                    <Text size="small">{currentOrganization.name}</Text>
                  )}
                  <Text className={c('application-name')}>
                    {currentApplication.name}
                  </Text>
                </div>
              ) : (
                <Text className={c('application-name', 'default')}>
                  Select workspace...
                </Text>
              )}

              <div className={c('icon-wrap')}>
                <Icon
                  name="unfold-vertical"
                  className={c('indicators')}
                  size="medium"
                />
              </div>
            </button>
          }
          align="start"
        >
          <ContextMenuOptionGroup
            value={currentApplication?.id}
            onValueChange={handleApplicationSelect}
          >
            {groupedApps.map(({ id, name, applications }) => (
              <ContextMenuGroup key={id}>
                <ContextMenuLabel>{name}</ContextMenuLabel>
                {applications.map((application) => (
                  <ContextMenuOption
                    value={application.id}
                    key={application.id}
                  >
                    {application.name}
                  </ContextMenuOption>
                ))}
              </ContextMenuGroup>
            ))}
          </ContextMenuOptionGroup>
          <ContextMenuSeparator />
          <ContextMenuAction icon="plus" onClick={toggleIsCreating}>
            Add new workspace
          </ContextMenuAction>
        </ContextMenu>

        {isLoadingPlan && (
          <Pill className={c('plan-info-skeleton')} aria-hidden="true">
            Starter
          </Pill>
        )}

        {plan && (
          <Pill intent={isStarterPlan ? 'info' : 'default'}>{plan.name}</Pill>
        )}
      </div>

      {isCreating && (
        <TitledModal
          title="Add Workspace"
          onRequestClose={() => toggleIsCreating(false)}
        >
          <CreateApplication
            onClose={() => toggleIsCreating(false)}
            onFinishCreating={() => toggleIsCreating(false)}
          />
        </TitledModal>
      )}
    </>
  );
}
