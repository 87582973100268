import { DateTime } from 'luxon';
import { TimeRangeInput } from '@propeldata/ui-kit';
import { useLocation } from 'react-router-dom';

import { USER_TIMEZONE } from 'services/timezone';

export type TimeRangeParams = Required<
  Pick<TimeRangeInput, 'start' | 'stop'>
> & {
  preset: string;
  timezone: string;
};

export function adjustTZAndKeepLocalTime(
  preset: string,
  timezone: string,
  from?: DateTime,
  to?: DateTime
): string | null {
  let adjustedDateISO = null;

  if (from) {
    adjustedDateISO =
      preset === 'CUSTOM'
        ? from.startOf('day').setZone(timezone, { keepLocalTime: true }).toISO()
        : from.setZone(timezone, { keepLocalTime: true }).toISO();
  }

  if (to) {
    adjustedDateISO =
      preset === 'CUSTOM'
        ? to.endOf('day').setZone(timezone, { keepLocalTime: true }).toISO()
        : to.setZone(timezone, { keepLocalTime: true }).toISO();
  }

  return adjustedDateISO;
}

export function useTimeRangeParams(): TimeRangeParams {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const preset = params.get('preset') || 'TODAY';
  const timezone = params.get('timezone') || USER_TIMEZONE;
  const start =
    params.get('start') ||
    adjustTZAndKeepLocalTime(preset, timezone, DateTime.now().startOf('day'));
  const stop =
    params.get('stop') ||
    adjustTZAndKeepLocalTime(
      preset,
      timezone,
      undefined,
      DateTime.now().endOf('day')
    );

  return {
    preset,
    start,
    stop,
    timezone,
  };
}
