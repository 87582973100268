import { Chart as ChartJS, ChartData, ScriptableContext } from 'chart.js';
import { DateTime } from 'luxon';
import { TimeSeriesGranularity } from '@propeldata/ui-kit';

import {
  CustomTimeSeriesQueryVariables,
  TimerSeriesResponse,
} from 'graphql/queries/timeseries';
import { useCustomTimeSeries } from './useCustomTimeSeries';
import { useTimeRangeParams } from './useTimeRangeParams';

import { Hsla, hslaToString, MAIN_CHART_COLOR } from '../services/color';
import { useChartColors } from './useChartColors';

export type HeatmapChartResult = {
  seriesLabels?: string[];
  data?: ChartData<'matrix', Object[], string>;
  isLoading: boolean;
  error?: string;
};

export function useHeatmapChart(
  variables: CustomTimeSeriesQueryVariables
): HeatmapChartResult {
  const { timezone } = useTimeRangeParams();
  const {
    data,
    isLoading: isLoadingTimeSeries,
    error,
  } = useCustomTimeSeries(variables);
  const { data: colors, isLoading: isLoadingColors } = useChartColors(
    data?.seriesLabels
  );

  const { granularity } = variables;
  const isLoading = isLoadingTimeSeries && isLoadingColors;

  if (!data || !colors) {
    return { isLoading, error };
  }

  const { seriesLabels, seriesData } = data;

  const labelColors = Object.fromEntries(
    seriesLabels.map((label, index) => [label, colors[index]])
  );

  return {
    seriesLabels,
    data:
      granularity === TimeSeriesGranularity.Hour
        ? createChartData(
            seriesLabels,
            groupByHourOfDay(timezone, seriesData),
            labelColors
          )
        : createChartData(
            seriesLabels,
            groupByDayOfWeek(timezone, seriesData),
            labelColors
          ),
    isLoading,
    error,
  };
}

const HOURS_OF_DAY = Array.from({ length: 24 }, (_, i) =>
  DateTime.fromObject({ year: 2000, month: 1, day: 1, hour: i }).toFormat('ha')
);

/**
 * Group data by hour of day.
 */
export function groupByHourOfDay(
  timezone: string,
  data: TimerSeriesResponse
): TimerSeriesResponse {
  const newData: TimerSeriesResponse = {};

  Object.entries(data).forEach(([key, { labels, values }]) => {
    const groupedData: { [k: string]: number } = {};

    labels.forEach((label, i) => {
      const timeGroup = DateTime.fromISO(label, { zone: timezone }).toFormat(
        'ha'
      );
      groupedData[timeGroup] =
        (groupedData[timeGroup] || 0) + Number(values[i]);
    });

    newData[key] = {
      labels: HOURS_OF_DAY,
      values: HOURS_OF_DAY.map((label) => groupedData[label]),
    };
  });

  return newData;
}

const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

/**
 * Group data by day of week.
 */
export function groupByDayOfWeek(
  timezone: string,
  data: TimerSeriesResponse
): TimerSeriesResponse {
  const newData: TimerSeriesResponse = {};

  Object.entries(data).forEach(([key, { labels, values }]) => {
    const groupedData: { [k: string]: number } = {};

    labels.forEach((label, i) => {
      const timeGroup =
        DAYS_OF_WEEK[DateTime.fromISO(label, { zone: timezone }).weekday % 7];
      groupedData[timeGroup] =
        (groupedData[timeGroup] || 0) + Number(values[i]);
    });

    newData[key] = {
      labels: DAYS_OF_WEEK,
      values: DAYS_OF_WEEK.map((label) => groupedData[label]),
    };
  });

  return newData;
}

/**
 * Create chart data for the `matrix` chart type.
 */
function createChartData(
  seriesLabels: string[],
  data: TimerSeriesResponse,
  colors: Record<string, Hsla>
) {
  const [{ labels }] = Object.values(data);

  const dataPoints = Object.entries(data).flatMap(([_, timeseries], index) =>
    timeseries.labels.map((label, i) => ({
      x: label,
      y: seriesLabels[index],
      v: timeseries.values[i],
    }))
  );

  const minV = Math.min(...dataPoints.map((dataPoint) => dataPoint?.v || 0));
  const maxV = Math.max(...dataPoints.map((dataPoint) => dataPoint?.v || 0));

  const datasets = [
    {
      label: 'Dataset',
      borderRadius: 2,
      data: dataPoints,
      backgroundColor(context: ScriptableContext<'matrix'>) {
        const dataPoint = context.dataset.data[context.dataIndex];
        // @ts-ignore the `matrix` chart type uses the `v` property but it's not defined in the types so we can't use explicit types here
        const value = dataPoint.v;
        const label = String(dataPoint.y);
        const color = colors[label] ?? MAIN_CHART_COLOR;
        const alpha = (value - minV) / (maxV - minV);
        return hslaToString({ ...color, a: alpha });
      },
      width: ({ chart }: { chart: ChartJS }) =>
        (chart.chartArea || {}).width / labels.length - 1,
      height: ({ chart }: { chart: ChartJS }) =>
        (chart.chartArea || {}).height / seriesLabels.length - 1,
    },
  ];

  return { seriesLabels, labels, datasets };
}
