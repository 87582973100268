export const MODEL_CAPABILITY_OPTIONS = [
  { label: 'Classification', value: 'classification' },
  { label: 'Detection', value: 'detection' },
  { label: 'Other', value: 'other' },
] as const;

export const LUMEO_MODEL_CAPABILITY_OPTIONS = [
  ...MODEL_CAPABILITY_OPTIONS,
  { label: 'Segmentation', value: 'segmentation' },
  { label: 'Transformation', value: 'transformation' },
] as const;

export const MODEL_ARCHITECTURE_OPTIONS = [
  { label: 'DetectNet', value: 'detectnet' },
  { label: 'FRCNN', value: 'frcnn' },
  { label: 'MobileNet', value: 'mobilenet' },
  { label: 'ResNet', value: 'resnet' },
  { label: 'SSD', value: 'ssd' },
  { label: 'SSD - Azure Custom Vision', value: 'ssd_customvision' },
  { label: 'YOLO - Azure Custom Vision', value: 'yolo_customvision' },
  { label: 'YOLOv2', value: 'yolov2' },
  { label: 'YOLOv3', value: 'yolov3' },
  { label: 'YOLOv4', value: 'yolov4' },
  { label: 'YOLOv5', value: 'yolov5' },
  { label: 'YOLOV6', value: 'yolov6' },
  { label: 'YOLOV7', value: 'yolov7' },
  { label: 'YOLOV8', value: 'yolov8' },
  { label: 'YOLOv2 Tiny', value: 'yolov2_tiny' },
  { label: 'YOLOv3 Tiny', value: 'yolov3_tiny' },
  { label: 'YOLOv4 Tiny', value: 'yolov4_tiny' },
  { label: 'YOLOV7 Tiny', value: 'yolov7_tiny' },
  { label: 'YOLO-NAS', value: 'yolonas' },
  { label: 'YOLOR', value: 'yolor' },
  { label: 'YOLOX', value: 'yolox' },
  { label: 'PP-YOLOE', value: 'ppyoloe' },
  { label: 'Other', value: 'other' },
] as const;

export const MODEL_FORMAT_OPTIONS = [
  { label: 'Caffe', value: 'caffe' },
  { label: 'YOLO Native', value: 'yolonative' },
  { label: 'ONNX Lumeo YOLO', value: 'onnxlumeoyolo' },
  { label: 'ONNX', value: 'onnx' },
  { label: 'UFF', value: 'uff' },
  { label: 'ETLT', value: 'etlt' },
  // sc-4221: we don't support TensorFlow and OpenVINO yet
  // { label: 'TensorFlow', value: 'tensorflow' },
  // { label: 'OpenVINO', value: 'openvino' },
] as const;

export const MODEL_CLUSTERING_ALGORITHM_OPTIONS = [
  { label: 'NMS', value: 'nms' },
  { label: 'DBSCAN', value: 'dbscan' },
  { label: 'DBSCAN and NMS Hybrid', value: 'dbscan_nms_hybrid' },
  { label: 'OpenCvGroupRectangles', value: 'open_cv_group_rectangles' },
  { label: 'No clustering', value: 'no_clustering' },
] as const;
