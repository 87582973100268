import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

import { RequireAuth } from 'components/RequireAuth/RequireAuth';

import { ComparePlansView } from './ComparePlansView/ComparePlansView';
import { Members } from 'settings/views/Members/Members';
import { OrganizationUtilization } from './OrganizationUtilization/OrganizationUtilization';

export function Organizations() {
  const { data: organization } = useCurrentOrganization();

  return (
    <Routes>
      {organization && (
        <Route path="/" element={<Navigate to={organization.id} replace />} />
      )}

      <Route
        path=":organizationID/members"
        element={
          <RequireAuth section={['organization_settings']}>
            <Members />
          </RequireAuth>
        }
      />

      <Route path=":organizationID/plans" element={<ComparePlansView />} />

      <Route path=":organizationID" element={<OrganizationUtilization />} />
    </Routes>
  );
}
