import Rete, { Node } from 'rete';

import Component from 'pipelines/services/rete/nodes/component';
import FileSizeInput from 'pipelines/services/rete/controls/file_size_input';
import NumberInput from 'pipelines/services/rete/controls/number_input';
import PasswordInput from 'pipelines/services/rete/controls/password_input';
import TextInput from 'pipelines/services/rete/controls/text_input';
import { EncodedVideo } from 'pipelines/services/rete/sockets';

import {
  MaxEdgeFilesInput,
  SaveLocationInput,
  TargetLocationInput,
  TriggerInput,
  // WebhookUrlInput,
} from 'pipelines/services/rete/controls/configured_inputs';
import ReteRadioSelect from 'pipelines/services/rete/controls/radio_select';

import { FileSizeDisplayer } from '../displayers/file_size';

export default class CaptureClip extends Component {
  static key = 'Save Clip';
  static icon = 'storage' as const;
  static exportType = 'clip';
  static category = 'media' as const;
  static description = 'Save a clip when a specific condition is met';

  static input = {
    type: 'encoded',
  };

  static editorDisplayers = {
    max_size: FileSizeDisplayer,
  };

  static output = {
    type: 'encoded',
  };

  static outputMetadata = ['Save in progress', 'Saved clip ID'];

  constructor() {
    super(CaptureClip.key);
  }

  async builder(node: Node) {
    node
      .addControl(
        new NumberInput(this.editor!, 'max_duration', {
          label: 'Maximum duration',
          info: 'This includes the pre-buffer interval, if defined.',
          initial: 3600,
          unit: 'seconds',
        })
      )
      .addControl(
        new FileSizeInput(this.editor!, 'max_size', {
          label: 'Maximum size',
          initial: 500 * 1048576,
          displayUnit: 'MiB',
        })
      )
      .addControl(
        new NumberInput(this.editor!, 'prebuffer_interval', {
          label: 'Pre-buffer interval',
          info:
            'Duration of content from before the triggering of the capture condition to be included in the clip. ' +
            'This does not extend the clip beyond the defined maximum duration.',
          initial: 0,
          unit: 'seconds',
        })
      )
      .addControl(SaveLocationInput(this.editor!, { group: 'location' }))
      .addControl(
        TargetLocationInput(this.editor!, {
          group: 'location',
          keyref: 'location',
          requiresKeyrefValue: 'local',
        })
      )
      .addControl(
        MaxEdgeFilesInput(this.editor!, {
          initial: 5,
          group: 'location',
          keyref: 'location',
          requiresKeyrefValue: 'local',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'key_prefix', {
          label: 'S3 prefix',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          info:
            'Organize S3 data using prefixes for hierarchical sorting of objects, similar to file organization in folders. ' +
            'To group clips based on location, consider using prefixes like "entrance/" or "parking-lot/".',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'bucket', {
          label: 'S3 bucket name',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          required: true,
          placeholder: 'example-bucket',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'region', {
          label: 'S3 region',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          required: true,
          placeholder: 'us-west-1',
          info: 'An identifier representing the geographical location of your S3 bucket. Regions vary based on your S3 provider and hosting location.',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'endpoint', {
          label: 'S3 endpoint',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          required: true,
          placeholder: 'https://s3.region.provider.com',
          info: 'The S3 endpoint URL. Endpoints vary based on your S3 provider and region.',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'access_key_id', {
          label: 'S3 access key ID',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          required: true,
          info: 'Commonly found on the access key settings page of your S3 provider.',
        })
      )
      .addControl(
        new PasswordInput(this.editor!, 'secret_access_key', {
          label: 'S3 secret access key',
          type: 'string',
          group: 'location',
          autoComplete: 'off',
          spellCheck: false,
          keyref: 'location',
          requiresKeyrefValue: 's3_bucket',
          required: true,
          info: 'Commonly found on the access key settings page of your S3 provider.',
        })
      )
      // .addControl(WebhookUrlInput(this.editor!, { group: 'other' }))
      .addControl(
        TriggerInput(this.editor!, {
          group: 'Capture condition',
          info: 'Start recording a clip once the trigger condition is met.',
        })
      )
      .addControl(
        new ReteRadioSelect(this.editor!, 'trigger_mode', {
          group: 'Capture condition',
          label: 'Trigger mode',
          initial: 'exact',
          options: [
            {
              value: 'exact',
              label: 'Exact',
              info: 'Record for as long as the trigger condition is met, but only up to the defined maximum duration.',
            },
            {
              value: 'fixed_duration',
              label: 'Fixed duration',
              info: 'Record for the defined maximum duration once the trigger condition is met.',
            },
          ],
        })
      )
      .addInput(new Rete.Input('input', 'Input', EncodedVideo, false))
      .addOutput(new Rete.Output('output', 'Output', EncodedVideo));
  }
}
