import React from 'react';
import classNames from 'classnames/bind';
import { OptionProps, components } from 'react-select';

import Category from 'types/category';

import {
  UseCategoriesFilterParams,
  useCategories,
} from 'hooks/api/useCategories';

import { Icon } from 'components/Icon/Icon';
import { Select, SelectOption, SelectProps } from 'components/Select/Select';
import { Text } from 'components/Text/Text';

import styles from './CategorySelect.module.scss';

const c = classNames.bind(styles);

export type CategorySelectProps<IsMulti extends boolean = false> = SelectProps<
  Category,
  IsMulti
> & {
  queryFilters?: UseCategoriesFilterParams;
};

function Option<IsMulti extends boolean = false>({
  data,
  children,
  ...rest
}: OptionProps<Category, IsMulti>) {
  const { icon_name, label, description } = data;

  return (
    <SelectOption {...rest} data={data}>
      <div>
        <Text>
          <Icon name={icon_name} />
          <span>{label}</span>
        </Text>
        {description && (
          <Text size="small" className={c('option-description')}>
            {description}
          </Text>
        )}
      </div>
    </SelectOption>
  );
}

export function CategorySelect<IsMulti extends boolean = false>({
  placeholder = 'More categories',
  isLoading,
  queryFilters,
  value,
  ...props
}: CategorySelectProps<IsMulti>) {
  const { data, isLoading: isLoadingOptions } = useCategories(
    ['category-select-categories'],
    queryFilters
  );

  return (
    <Select
      {...props}
      options={data?.data}
      placeholder={placeholder}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ label }) => label}
      isLoading={isLoading || isLoadingOptions}
      components={{
        Option,
        ValueContainer({ children, getValue, hasValue, ...props }) {
          const innerProps = { getValue, hasValue, ...props };

          if (!hasValue) {
            return (
              <components.ValueContainer {...innerProps}>
                {children}
              </components.ValueContainer>
            );
          }

          const valueCount = getValue().length;
          const [, otherChildren] = children as Array<React.ReactNode>;

          return (
            <components.ValueContainer {...innerProps}>
              <span className={c('select-label')}>
                More categories
                {valueCount > 0 && (
                  <span
                    className={c('select-label-count')}
                    aria-label="Selected categories"
                  >
                    {valueCount}
                  </span>
                )}
              </span>
              {otherChildren}
            </components.ValueContainer>
          );
        },
      }}
      hideSelectedOptions={false}
      size="small"
    />
  );
}
