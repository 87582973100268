import React from 'react';
import classNames from 'classnames/bind';

import styles from './Box.module.scss';

const c = classNames.bind(styles);

export type BoxProps = React.PropsWithChildren<{
  className?: string;
  size?: 'small';
  variant?: 'primary' | 'secondary';
  theme?: 'inverted';
}>;

/**
 * Simple styled component that displays a soft border
 * around its content.
 */
export function Box({ className, children, size, variant, theme }: BoxProps) {
  return (
    <div
      className={c(className, 'box', size, theme, {
        [`theme ${variant}`]: variant,
      })}
    >
      {children}
    </div>
  );
}

export { BoxError } from './Error';
