import React from 'react';
import classnames from 'classnames/bind';

import { truncateMiddle } from 'services/string';

import { Icon } from 'components/Icon/Icon';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';

import styles from './TokenSuccessMessage.module.scss';

const c = classnames.bind(styles);

export type TokenSuccessMessageProps = React.PropsWithChildren<{
  title: string;
  token: string;
}>;

/** Banner to copy token to clipboard */
export function TokenSuccessMessage({
  title,
  token,
  children,
}: TokenSuccessMessageProps) {
  return (
    <div className={c('message', 'theme success')}>
      <div className={c('icon')}>
        <Icon name="check-circle" />
      </div>
      <strong className={c('title')}>{title}</strong>
      <div className={c('token')}>
        <CopyToClipboard
          className={c('token-value')}
          value={token}
          displayValue={truncateMiddle(token, 50)}
        />
      </div>
      <div className={c('info')}>{children}</div>
    </div>
  );
}
