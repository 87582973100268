import { getLineRotation } from './get_line_rotation';

export function getLabelPosition(shape: number[]) {
  const [x, y] = shape;

  let rotation = getLineRotation(shape);
  let offsetModifier = 0;

  if (rotation < -90) {
    rotation += 180;
    offsetModifier = 1;
  } else if (rotation > 90) {
    rotation -= 180;
    offsetModifier = 1;
  }

  return {
    rotation,
    position: { x, y },
    offsetModifier,
  };
}
