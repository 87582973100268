import React from 'react';

import APIError from 'types/api_error';
import Stream from 'types/stream';

import { useCreateStream } from 'streams/hooks/useCreateStream';

import { useUploadFile } from './useUploadFile';

export type FileUploadState = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: APIError | null;
  /**
   * Percentage value between 0 and 1.
   */
  progress: number | null;
};

type ClearAction = {
  type: 'clear';
  payload?: never;
};

type RemoveAction = {
  type: 'remove';
  payload: {
    file: File;
    isLoading?: never;
    isSuccess?: never;
    isError?: never;
    error?: never;
    progress?: never;
  };
};

type SetAction = {
  type: 'set';
  payload: {
    file: File;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: APIError | null;
    progress: number | null;
  };
};

type ProgressAction = {
  type: 'progress';
  payload: {
    file: File;
    progress: number;
    isLoading?: never;
    isSuccess?: never;
    isError?: never;
    error?: never;
  };
};

type Action = ClearAction | RemoveAction | SetAction | ProgressAction;

function reducer(
  state: Map<File, FileUploadState>,
  { type, payload }: Action
): Map<File, FileUploadState> {
  if (type === 'clear') {
    return new Map();
  }

  if (!payload) {
    throw new Error('Payload is required.');
  }

  const { file } = payload;

  if (type === 'remove') {
    state.delete(file);
    return new Map(state);
  }

  const stateFile = state.get(file);
  const isLoading = stateFile?.isLoading ?? false;
  const isSuccess = stateFile?.isSuccess ?? false;
  const isError = stateFile?.isError ?? false;
  const error = stateFile?.error ?? null;
  const progress = stateFile?.progress ?? null;

  if (type === 'set') {
    return new Map(
      state.set(file, {
        isLoading: payload.isLoading ?? isLoading,
        isSuccess: payload.isSuccess ?? isSuccess,
        isError: payload.isError ?? isError,
        error: payload.error ?? error,
        progress: payload.progress ?? progress,
      })
    );
  }

  if (type === 'progress') {
    return new Map(
      state.set(file, {
        isLoading,
        isSuccess,
        isError,
        error,
        progress: payload.progress ?? progress,
      })
    );
  }

  return state;
}

export function useFilesUploadState() {
  const [state, dispatch] = React.useReducer(
    reducer,
    new Map<File, FileUploadState>()
  );
  const { uploadFile, isLoading } = useUploadFile();
  const { mutateAsync: createStream } = useCreateStream(['streams'], {
    action: 'link',
    entity: 'files',
  });

  const clear = React.useCallback(() => dispatch({ type: 'clear' }), []);

  /** Upload file to API */
  const upload = React.useCallback(
    async (file: File) => {
      dispatch({
        type: 'set',
        payload: {
          file,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: null,
          progress: 0,
        },
      });

      try {
        const lumeoFile = await uploadFile(file, {
          onUploadProgress({ loaded, total }) {
            if (total) {
              dispatch({
                type: 'progress',
                payload: {
                  file,
                  progress: loaded / total,
                },
              });
            }
          },
        });
        dispatch({
          type: 'set',
          payload: {
            file,
            isLoading: false,
            isSuccess: true,
            isError: false,
            error: null,
            progress: 1,
          },
        });
        return lumeoFile;
      } catch (error) {
        dispatch({
          type: 'set',
          payload: {
            file,
            isLoading: false,
            isSuccess: false,
            isError: true,
            error: error as APIError,
            progress: null,
          },
        });
      }
    },
    [uploadFile]
  );

  /** Upload file to API and immediately create file stream from it. */
  const uploadFileStream = React.useCallback(
    async (file: File) => {
      dispatch({
        type: 'set',
        payload: {
          file,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: null,
          progress: 0,
        },
      });

      try {
        const lumeoFile = await uploadFile(file, {
          onUploadProgress({ loaded, total }) {
            if (total) {
              dispatch({
                type: 'progress',
                payload: {
                  file,
                  progress: loaded / total,
                },
              });
            }
          },
        });

        if (lumeoFile) {
          const stream = Stream.fromFile(lumeoFile);
          await createStream(stream);
        }

        dispatch({
          type: 'set',
          payload: {
            file,
            isLoading: false,
            isSuccess: Boolean(lumeoFile),
            isError: !lumeoFile,
            error: null,
            progress: lumeoFile ? 1 : null,
          },
        });

        return lumeoFile;
      } catch (error) {
        dispatch({
          type: 'set',
          payload: {
            file,
            isLoading: false,
            isSuccess: false,
            isError: true,
            error: error as APIError,
            progress: null,
          },
        });
      }
    },
    [uploadFile, createStream]
  );

  return [
    state,
    {
      isLoading,
      clear,
      uploadFile: upload,
      uploadFileStream,
    },
  ] as const;
}
