import React from 'react';
import classNames from 'classnames/bind';
import { Link, useLocation } from 'react-router-dom';

import { useDeclineInvitation } from 'hooks/api/useInvitations';

import { Button } from 'components/Button/Button';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/Text/Text';

import { InviteWrapper } from './Invite';

import styles from '../Auth.module.scss';

const c = classNames.bind(styles);

export function Decline() {
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');

  const {
    mutate: decline,
    isLoading,
    error,
    isSuccess,
  } = useDeclineInvitation();

  if (!token || Array.isArray(token)) {
    return (
      <InviteWrapper>
        <div className={c('auth-text')}>
          <Heading level="1">Invalid invitation</Heading>
        </div>
        <div className={c('auth-text')}>
          <Text type="paragraph">This invitation link is not valid.</Text>
        </div>
      </InviteWrapper>
    );
  }

  if (isSuccess) {
    return (
      <InviteWrapper>
        <div className={c('auth-text')}>
          <Heading level="1">Invitation declined</Heading>
        </div>
        <div className={c('auth-text')}>
          <Text type="paragraph">
            The invitation was declined. You may close this page.
          </Text>
        </div>
      </InviteWrapper>
    );
  }

  return (
    <InviteWrapper>
      <div className={c('auth-text')}>
        <Heading level="1">Decline invitation</Heading>
      </div>
      <div className={c('auth-text')}>
        <Text type="paragraph">
          Please confirm that you want to decline the invitation.
        </Text>
      </div>
      {isLoading && <Loader text="Declining invitation..." />}
      <FormErrorMessage error={error} />
      <Button
        variant="primary"
        className={c('auth-button')}
        onClick={() => decline({ token })}
      >
        Decline invitation
      </Button>
      <div className={c('auth-text-secondary')}>
        <Link
          to={`${pathname.replace('/decline', '/accept')}?token=${token}`}
          className={c('link')}
        >
          Did you mean to accept instead?
        </Link>
      </div>
    </InviteWrapper>
  );
}
