import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { DimensionInput, FilterInput, Sort } from '@propeldata/ui-kit';
import { Pie } from 'react-chartjs-2';

import { useChartColors } from 'dashboards/hooks/useChartColors';
import { useLeaderboard } from 'dashboards/hooks/useLeaderboard';
import { useTimeRangeParams } from 'dashboards/hooks/useTimeRangeParams';

import { ChartError } from './components/ChartError';
import { ChartLoader } from './components/ChartLoader';
import { WIDGET_REFETCH_INTERVAL } from '../services/interval';
import { hslaToString } from '../services/color';

ChartJS.register(ArcElement, Legend, Title, Tooltip);

const EMPTY: string[] = [];

export type PieChartProps = {
  query: {
    metric: string;
    dimensions: DimensionInput[];
    rowLimit: number;
    sort: Sort;
    filters?: FilterInput[];
  };
};

export function PieChart({
  query: { metric, dimensions, rowLimit, sort, filters },
}: PieChartProps) {
  const { start, stop, timezone } = useTimeRangeParams();

  const {
    data,
    isLoading: isLoadingLeaderboard,
    error,
  } = useLeaderboard(
    {
      metric: { name: metric },
      dimensions,
      rowLimit,
      sort,
      timeRange: { start, stop },
      filters,
      timeZone: timezone,
    },
    { refetchInterval: WIDGET_REFETCH_INTERVAL, keepPreviousData: true }
  );

  const labels = data?.leaderboard.rows.map(([label]) => label) || EMPTY;

  const { data: colors, isLoading: isLoadingColors } = useChartColors(labels);

  const isLoading = isLoadingLeaderboard || isLoadingColors;

  if (error) {
    return (
      <ChartError
        error={{ title: 'No data', body: 'Failed to fetch values.' }}
      />
    );
  }

  if (!data && isLoading) {
    return <ChartLoader />;
  }

  if (!data || !colors) {
    return null;
  }

  const { leaderboard } = data;
  const values = leaderboard.rows.map(([_, value]) => value);
  const total = values.reduce(
    (accumulator, currentValue) => accumulator + Number(currentValue),
    0
  );

  function formatTooltipWithPercentage({
    formattedValue,
    parsed,
  }: TooltipItem<'pie'>) {
    const percentage = ((parsed / total) * 100).toFixed(2);

    return `${formattedValue} - ${percentage}%`;
  }

  const datasets = [
    {
      data: values,
      backgroundColor: colors.map(hslaToString),
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: `Total: ${total}`,
        position: 'bottom' as const,
      },
      tooltip: { callbacks: { label: formatTooltipWithPercentage } },
    },
  };

  return <Pie data={{ labels, datasets }} options={options} />;
}
