export const STROKE_COLOR = '#28AAEB';

export const ROI_CONFIG = {
  padding: 20,
  clickablePadding: 4,

  line: {
    stroke: STROKE_COLOR,
    strokeWidth: 2.5,
    hitStrokeWidth: 10,
  },

  anchor: {
    radius: 5,
    stroke: STROKE_COLOR,
    strokeWidth: 2.5,
    fill: 'white',
  },

  directionIndicator: {
    offsetX: 9,
    scaleX: 1.5,
    sides: 3,
    strokeWidth: 1,
  },

  labelBg: {
    cornerRadius: 3,
    fill: 'black',
  },

  labelText: {
    fill: 'white',
    fontSize: 13,
  },
};
