import React from 'react';
import classNames from 'classnames/bind';
import { filesize } from 'filesize';

import Gateway from 'types/gateway';

import { pluralize } from 'services/string';
import { useGatewaySpecification } from 'gateways/hooks/useGatewaySpecification';

import { Heading } from 'components/Heading/Heading';
import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/Text/Text';

import styles from './GatewayDetail.module.scss';

const c = classNames.bind(styles);

export type GatewayDetailSpecificationProps = {
  gateway: Gateway;
};

export function GatewayDetailSpecification({
  gateway,
}: GatewayDetailSpecificationProps) {
  const { data, isLoading } = useGatewaySpecification(gateway.id);

  if (isLoading) {
    return <Loader text="Loading..." />;
  }

  if (!data?.specification) {
    return <Text>No specification available.</Text>;
  }

  const {
    product,
    serial_number,
    hostname,
    memory_size_bytes,
    cpu: cpus,
    inference_hardware,
    file_systems,
  } = data.specification;

  const filteredFileSystems = file_systems.filter(
    ({ mount_point }) => mount_point === '/var/lib/lumeo'
  );

  return (
    <dl className={c('specification')}>
      {product && (
        <>
          <dt>Product</dt>
          <dd>{product}</dd>
        </>
      )}

      {serial_number && (
        <>
          <dt>Serial number</dt>
          <dd>{serial_number}</dd>
        </>
      )}

      <dt>Hostname</dt>
      <dd>{hostname}</dd>

      {Number.isFinite(memory_size_bytes) && (
        <>
          <dt>Memory</dt>
          <dd>{filesize(memory_size_bytes!)}</dd>
        </>
      )}

      <Heading level="3" className={c('specification-heading')}>
        {pluralize(cpus.length, 'CPU')}
      </Heading>

      {cpus.map(({ core_count, model }, index) => (
        <div className={c('specification-group')} key={index}>
          <dt>Number of cores</dt>
          <dd>{core_count}</dd>

          <dt>Type</dt>
          <dd>{model}</dd>
        </div>
      ))}

      {inference_hardware?.gpus && (
        <>
          <Heading level="3" className={c('specification-heading')}>
            {pluralize(inference_hardware.gpus.length, 'GPU')}
          </Heading>

          {inference_hardware.gpus.map(({ uuid, name, memory_size_bytes }) => (
            <div className={c('specification-group')} key={uuid}>
              <dt>Name</dt>
              <dd>{name}</dd>

              <dt>Memory</dt>
              <dd>{filesize(memory_size_bytes)}</dd>
            </div>
          ))}
        </>
      )}

      {inference_hardware && (
        <>
          <Heading level="3" className={c('specification-heading')}>
            Inference hardware
          </Heading>

          <dt>Host CUDA</dt>
          <dd>{inference_hardware.cuda_version}</dd>

          {inference_hardware.type !== 'nvidia_jetson' && (
            <>
              <dt>Host GPU driver version</dt>
              <dd>{inference_hardware.driver_version}</dd>
            </>
          )}
        </>
      )}

      {filteredFileSystems.length > 0 && (
        <>
          <Heading level="3" className={c('specification-heading')}>
            Storage
          </Heading>
          {filteredFileSystems.map(
            ({ total_space_bytes, disk_logical_name }) => (
              <React.Fragment key={disk_logical_name}>
                <dt>Size</dt>
                <dd>
                  {Number.isFinite(total_space_bytes) &&
                    filesize(total_space_bytes!)}
                </dd>
              </React.Fragment>
            )
          )}
        </>
      )}
    </dl>
  );
}
