import { TimeSeriesGranularity } from '@propeldata/ui-kit';
import { TimeUnit } from 'chart.js';

export const TimeUnitMap: Record<TimeSeriesGranularity, TimeUnit | false> = {
  [TimeSeriesGranularity.Year]: 'year',
  [TimeSeriesGranularity.Month]: 'month',
  [TimeSeriesGranularity.Week]: 'week',
  [TimeSeriesGranularity.Day]: 'day',
  [TimeSeriesGranularity.Hour]: 'hour',
  [TimeSeriesGranularity.Minute]: 'minute',
  [TimeSeriesGranularity.FiveMinutes]: false,
  [TimeSeriesGranularity.TenMinutes]: false,
  [TimeSeriesGranularity.FifteenMinutes]: false,
};
