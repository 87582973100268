import React from 'react';
import c from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ApplicationParams } from 'application/types/application_params';
import Deployment, { DeploymentConfiguration } from 'types/deployment';

import { useActivePipeline } from 'pipelines/context/active_pipeline';
import { useDeploymentParameters } from 'deployments/hooks/useDeploymentParameters';

import { FormMessage } from 'components/FormMessage/FormMessage';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';

import { DeploymentBulkOverrideFormNode } from './BulkOverrideFormNode';
import { DeploymentFormSkeleton } from '../Skeleton';
import { DeploymentFormWarnings } from '../Warnings';
import { sortByNodeIDAndVideoSourcesFirst } from '../sort_pipeline_nodes';
import { usePreloadDeploymentForm } from '../usePreloadDeploymentForm';

export type DeploymentBulkOverrideFormStep2Props = {
  isLoading: boolean;
  isError: boolean;
  onSubmit: (data: DeploymentConfiguration) => void;
  onLink?: () => void;
};

function DeploymentBulkOverrideFormStep2(
  {
    isLoading,
    isError,
    onSubmit,
    onLink,
  }: DeploymentBulkOverrideFormStep2Props,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const { applicationID } = useParams<ApplicationParams>();
  const pipeline = useActivePipeline();

  const { isLoading: isLoadingFormComponents, error } =
    usePreloadDeploymentForm();

  const [overrideDeployment] = React.useState(
    new Deployment('__override__', applicationID!, pipeline.id, '')
  );
  const defaultValues = useDeploymentParameters(overrideDeployment);
  const methods = useForm<DeploymentConfiguration>({
    defaultValues,
    mode: 'onChange',
    shouldUnregister: true,
  });

  const { trigger, getValues } = methods;

  React.useImperativeHandle<HTMLFormElement, any>(ref, () => ({
    async onSubmit() {
      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      onSubmit(getValues());
    },
    validate: trigger,
  }));

  if (!pipeline) {
    return null;
  }

  if (error || isError) {
    return (
      <FormMessage intent="danger" icon="warning">
        <strong>{error?.message || 'An unknown error occurred.'}</strong>
        <br />
        Please try again later.
      </FormMessage>
    );
  }

  if (isLoading || isLoadingFormComponents) {
    return (
      <SkeletonList min={2} max={3} component={<DeploymentFormSkeleton />} />
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        className={c({ readonly: methods.formState.isSubmitting })}
        onKeyDown={(event) => event.stopPropagation()}
        ref={ref}
      >
        <DeploymentFormWarnings pipeline={pipeline} onLinkClick={onLink} />

        {pipeline.nodes.sort(sortByNodeIDAndVideoSourcesFirst).map((node) => (
          <DeploymentBulkOverrideFormNode
            node={node}
            deployTimeEditableNodes={pipeline.deployTimeEditableNodes}
            key={node.id}
          />
        ))}
      </form>
    </FormProvider>
  );
}

const forwardRef = React.forwardRef<
  HTMLFormElement,
  DeploymentBulkOverrideFormStep2Props
>(DeploymentBulkOverrideFormStep2);

export { forwardRef as DeploymentBulkOverrideFormStep2 };
