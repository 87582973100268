import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import APIError from 'types/api_error';

import { useAPI } from './useAPI';

export type AcceptAndCreateAccountVariables = {
  token: string;
  password: string;
  name: string;
};

export type InvitationVariables = {
  token: string;
};

export function useAcceptInvitation(
  options: UseMutationOptions<void, APIError, InvitationVariables> = {}
) {
  const api = useAPI();
  return useMutation(api.invitations.accept, options);
}

export function useAcceptAndCreateAccount(
  options: UseMutationOptions<
    void,
    APIError,
    AcceptAndCreateAccountVariables
  > = {}
) {
  const api = useAPI();
  return useMutation(api.invitations.acceptAndCreateAccount, options);
}

export function useDeclineInvitation(
  options: UseMutationOptions<void, APIError, InvitationVariables> = {}
) {
  const api = useAPI();
  return useMutation(api.invitations.decline, options);
}

export function useInvitationHasAccount(
  token: string,
  options: UseQueryOptions<void, APIError> = {}
) {
  const api = useAPI();
  return useQuery<void, APIError>(
    ['hasAccount', token],
    () => api.invitations.hasAccount(token),
    options
  );
}
