import { UseQueryOptions } from '@tanstack/react-query';
import { Userpilot } from 'userpilot';

import APIError from 'types/api_error';
import Stream, { StreamType } from 'types/stream';
import { PaginatedAPIResponse } from 'types/api';

import { useAPI } from 'hooks/api/useAPI';
import { useUser } from 'hooks/useAuth';

import { useStreams, UseStreamsFilterParams } from './useStreams';

export function useInputStreams(
  params?: UseStreamsFilterParams,
  options?: UseQueryOptions<PaginatedAPIResponse<Stream>, APIError>
) {
  const api = useAPI();
  const user = useUser();

  return useStreams(
    ['streams'],
    {
      stream_types: [StreamType.RTSP, StreamType.WEBRTC],
      sources: ['uri_stream'],
      ...params,
    },
    {
      ...options,
      onSuccess: (pages) => {
        if (!user?.id) {
          return;
        }

        Userpilot.identify(user.id, {
          has_input_streams: pages.data.length > 0,
          application_id: api.applicationID,
        });
      },
    }
  );
}
