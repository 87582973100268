import { NodeEditor } from 'rete';

import { IconType } from 'components/Icon/Icon';
import {
  JsonObjectInput,
  JsonObjectInputProps,
} from 'components/JsonObjectInput/JsonObjectInput';

import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

export type ReteJsonInputParams = Omit<ReteInputParams, 'type'> & {
  icon?: IconType;
};

export default class ReteJsonInput extends ReteInput<
  ReteInputProps & JsonObjectInputProps
> {
  constructor(
    editor: NodeEditor,
    key: string,
    { inputType, icon, ...params }: ReteJsonInputParams
  ) {
    super(editor, key, { ...params, inputType, type: 'json' });

    this.props.type = inputType;
    this.props.icon = icon;
  }

  get component() {
    return JsonObjectInput;
  }
}
