import React from 'react';
import classNames from 'classnames/bind';

import { usePageLayoutOptional } from '../context/page_layout';
import styles from '../PageLayout.module.scss';

const c = classNames.bind(styles);

export type ContainerSize = 'small' | 'medium' | 'default' | 'full';

export type ContainerProps = React.PropsWithChildren<{
  className?: string;
  size?: ContainerSize;
  sidebar?: React.ReactNode;
}>;

export function Container({
  size,
  className,
  children,
  sidebar,
}: ContainerProps) {
  const { size: layoutSize } = usePageLayoutOptional();

  return (
    <div className={c(className, 'container', size || layoutSize)}>
      <div className={c('container-content')}>{children}</div>
      {sidebar && <div className={c('container-sidebar')}>{sidebar}</div>}
    </div>
  );
}
