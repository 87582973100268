import * as RadixDialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import classNames from 'classnames/bind';

import styles from './Dialog.module.scss';

const c = classNames.bind(styles);

export type DescriptionProps = RadixDialog.DialogDescriptionProps & {
  visuallyHidden?: boolean;
};

export function Description({
  visuallyHidden,
  className,
  ...props
}: DescriptionProps) {
  if (visuallyHidden) {
    return (
      <VisuallyHidden>
        <RadixDialog.Description {...props} />
      </VisuallyHidden>
    );
  }

  return (
    <RadixDialog.Description
      className={c(className, 'description')}
      {...props}
    />
  );
}
