import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Category from 'types/category';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';

import { getOverloadedQueryParams } from 'services/get_overloaded_query_params';

import { useAPI } from './useAPI';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';

export type UseCategoriesFilterParams = OffsetPaginationParams & {
  only_highlighted?: boolean;
  only_non_highlighted?: boolean;
  labels?: Category['label'][];
};

export type UseCategoriesOptions = UseQueryOptions<
  PaginatedAPIResponse<Category>,
  APIError
>;

export function useCategories(
  filters?: UseCategoriesFilterParams,
  options?: UseCategoriesOptions
): UseQueryResult<PaginatedAPIResponse<Category>, APIError>;

export function useCategories(
  queryKey?: QueryKey,
  filters?: UseCategoriesFilterParams,
  options?: UseCategoriesOptions
): UseQueryResult<PaginatedAPIResponse<Category>, APIError>;

export function useCategories(
  queryKeyOrFilters?: QueryKey | UseCategoriesFilterParams,
  filtersOrOptions?: UseCategoriesFilterParams | UseCategoriesOptions,
  queryOptions?: UseCategoriesOptions
) {
  const api = useAPI();

  const {
    queryKey = ['categories'],
    filters: { pagination = 'offset', page = 1, limit = 50, ...filters } = {},
    options,
  } = getOverloadedQueryParams(
    queryKeyOrFilters,
    filtersOrOptions,
    queryOptions
  );

  const params = { ...filters, limit, page, pagination };

  return useAuthenticatedQuery(
    [...queryKey, params],
    () => api.categories.list(params),
    options
  );
}
