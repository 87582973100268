import React from 'react';
import classNames from 'classnames/bind';

import Camera from 'types/camera';
import Stream, { StreamType } from 'types/stream';

import { Icon } from 'components/Icon/Icon';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';

import { getVideoSourceDisplayInfo } from './get_display_info';
import { getVideoSourceIcon } from './get_icon';
import styles from './VideoSourceSelect.module.scss';

const c = classNames.bind(styles);

export type ItemProps = {
  item: Camera | Stream;
  active: boolean;
};

function VideoSourceSelectItem(
  { item, active, ...props }: ItemProps,
  ref: React.ForwardedRef<HTMLLIElement>
) {
  const { status } = item;
  const info = getVideoSourceDisplayInfo(item);

  return (
    <li {...props} className={c('suggestion-item', { active })} ref={ref}>
      {!(
        item.source_type === 'stream' &&
        (item as Stream).stream_type === StreamType.FILE
      ) && (
        <StatusIndicator
          className={c('suggestion-item-status')}
          status={status}
          size="small"
        />
      )}

      <Icon
        className={c('suggestion-item-icon')}
        name={getVideoSourceIcon(item)}
      />

      <Text className={c('suggestion-item-label')} inline>
        {item.name ?? String(item.id)}
      </Text>

      {info && (
        <Text className={c('suggestion-item-info')} size="small" inline>
          {info}
        </Text>
      )}
    </li>
  );
}

const forwardedRef = React.forwardRef(VideoSourceSelectItem);

export { forwardedRef as VideoSourceSelectItem };
