import React from 'react';
import classNames from 'classnames/bind';

import Deployment from 'types/deployment';
import Pipeline from 'types/pipeline';

import { useAllDeployments } from 'deployments/hooks/useAllDeployments';
import { usePipeline } from 'hooks/api/usePipeline';

import { Text } from 'components/Text/Text';

import { DeploymentsFormSelect } from '../DeploymentsSelect/DeploymentsSelect';
import { UpdateDeploymentsFormStep2 } from './Step2';
import { UpdateDeploymentsFormStepper } from './Stepper';
import styles from '../Form.module.scss';

const c = classNames.bind(styles);

export type UpdateDeploymentsFormProps = {
  pipelineId: Pipeline['id'];
  onSuccess?: (deployments: Deployment[]) => void;
};

export function UpdateDeploymentsForm({
  pipelineId,
  onSuccess,
}: UpdateDeploymentsFormProps) {
  const { data: pipeline, isLoading: isLoadingPipeline } =
    usePipeline(pipelineId);

  const [formStep, setFormStep] = React.useState(1);

  const [selection, setSelection] = React.useState<
    Record<Deployment['id'], boolean>
  >({});
  const selectedDeploymentsCount = Object.keys(selection).length;

  const {
    data: deployments,
    isLoading,
    isError,
  } = useAllDeployments({
    params: {
      deployment_ids:
        selectedDeploymentsCount > 0 ? Object.keys(selection) : undefined,
      pipeline_ids: [pipelineId],
    },
    enabled: formStep > 1,
  });
  const deploymentsCount = deployments?.length || Object.keys(selection).length;

  function resetForm() {
    setSelection({});
    setFormStep(1);
  }

  const handleStep1Continue = React.useCallback(
    (selectedDeployments: Record<Deployment['id'], boolean>) => {
      setSelection(selectedDeployments);
      setFormStep(2);
    },
    []
  );

  return (
    <div className={c('form-container')}>
      <UpdateDeploymentsFormStepper
        currentStep={formStep}
        count={deploymentsCount}
        onNavigate={setFormStep}
      />

      {(!pipeline || isLoadingPipeline) && <Text>Loading...</Text>}

      {pipeline && formStep === 1 && (
        <DeploymentsFormSelect
          pipeline={pipeline}
          defaultValue={selection}
          onConfirmSelection={handleStep1Continue}
        />
      )}

      {pipeline && formStep === 2 && (
        <UpdateDeploymentsFormStep2
          pipeline={pipeline}
          count={deploymentsCount}
          deployments={deployments}
          isLoading={isLoading}
          isError={isError}
          onSuccess={(deployments) => {
            onSuccess?.(deployments);
            resetForm();
          }}
        />
      )}
    </div>
  );
}
