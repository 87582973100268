import { MODEL_CAPABILITY_OPTIONS } from 'services/model_options';

import { Heading } from 'components/Heading/Heading';
import { Separator } from 'components/Separator/Separator';

import { useModelFormState } from '../context/useModelFormState';
import { ClusteringAlgorithmInput } from '../inputs/ClusteringAlgorithmInput';
import { FilterOutClassIDsInput } from '../inputs/FilterOutClassIDsInput';
import { ClassifierThresholdInput } from '../inputs/ClassifierThresholdInput';

const CONFIGURABLE_CAPABILITIES = ['classification', 'detection'];

export function CapabilityConfiguration() {
  const { data, model } = useModelFormState();

  if (!data && !model) {
    return null;
  }

  const capability = data?.capability || model?.capability;
  const capabilityLabel = MODEL_CAPABILITY_OPTIONS.find(
    ({ value }) => value === capability
  )?.label;

  if (
    !capability ||
    !CONFIGURABLE_CAPABILITIES.includes(capability) ||
    !capabilityLabel
  ) {
    return null;
  }

  return (
    <>
      <Separator />

      <Heading level="2">{capabilityLabel} configuration</Heading>

      {capability === 'detection' && (
        <>
          <ClusteringAlgorithmInput />
          <FilterOutClassIDsInput />
        </>
      )}

      {capability === 'classification' && <ClassifierThresholdInput />}
    </>
  );
}
