import React from 'react';
import classNames from 'classnames/bind';
import { Textarea } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';

import { useForwardedRef } from 'hooks/useForwardedRef';

import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './EditableText.module.scss';

const c = classNames.bind(styles);

export type EditableTextProps = {
  className?: string;
  value?: string;
  defaultValue?: string;
  tooltipContent?: string;
  onSave?: (value: string) => void;
  onCancel?: () => void;
};

function EditableText(
  {
    className,
    tooltipContent = 'Rename',
    value,
    defaultValue,
    onSave,
    onCancel,
  }: EditableTextProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) {
  const internalRef = useForwardedRef(ref);

  const [currentValue, setCurrentValue] = useUncontrolled({
    value,
    defaultValue,
  });

  function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (!internalRef.current) {
      return;
    }

    switch (event.key) {
      case 'Escape': {
        event.stopPropagation();
        setCurrentValue(value ?? defaultValue ?? ''); // Restore the original value
        onCancel?.();
        internalRef.current.blur();
        break;
      }
      case 'Enter': {
        event.preventDefault();
        internalRef.current.blur();
        break;
      }
    }
  }

  function handleBlur() {
    if (!internalRef.current) {
      return;
    }

    if (
      (defaultValue && internalRef.current.value !== defaultValue) ||
      (value && internalRef.current.value !== value)
    ) {
      onSave?.(internalRef.current.value);
    }
  }

  return (
    <Tooltip content={tooltipContent} delayDuration={500}>
      <Textarea
        classNames={{ input: c('input', className) }}
        value={currentValue}
        onChange={(e) => setCurrentValue(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        ref={internalRef}
        autosize
      />
    </Tooltip>
  );
}

const ForwardedRef = React.forwardRef(EditableText);

export { ForwardedRef as EditableText };
