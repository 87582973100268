import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
} from '@tanstack/react-query';

import AIModel, { ModelFiles } from 'types/ai_model';
import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';

import { useAppQueryClient } from 'hooks/useAppQueryClient';

import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { useDangerousMutation } from './useDangerousMutation';
import { useModels } from './useModels';

export type UseCreateModelVariables = {
  model: AIModel;
  files: ModelFiles;
};

export type UseUpdateModelVariables = {
  model: AIModel;
  files?: ModelFiles;
};

export function useModel(
  modelID?: AIModel['id'],
  options?: UseQueryOptions<AIModel, APIError>
) {
  const api = useAPI();
  const { data: models } = useModels();

  return useAuthenticatedQuery<AIModel>(
    ['model', modelID, api.applicationID],
    () => {
      if (!modelID) {
        return Promise.reject('No model id provided.');
      }

      return api.models.read(modelID);
    },
    {
      ...options,
      enabled: !!modelID,
      placeholderData() {
        return models?.find(({ id }) => id === modelID);
      },
    }
  );
}

export function useUpdateModel({
  onMutate,
  onSettled,
  ...options
}: UseMutationOptions<AIModel, APIError, UseUpdateModelVariables> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useMutation(
    ({
      model: { weights_file_url, metadata_file_url, labels_file_url, ...model },
      files = {},
    }) => api.models.update(model, files),
    {
      ...options,
      onMutate: ({ model }) => {
        const previousModels =
          queryClient.getQueryData<AIModel[]>(['models']) || [];

        if (previousModels) {
          queryClient.setQueryData(
            ['models'],
            previousModels.map((previousModel) => {
              if (previousModel.id === model.id) {
                return model;
              }

              return previousModel;
            })
          );
        }

        return { previousModels };
      },
      onSettled: (model, ...args) => {
        if (onSettled) {
          onSettled(model, ...args);
        }

        queryClient.invalidateQueries(['models']);

        if (model) {
          queryClient.invalidateQueries(['model', model.id]);
        }
      },
    }
  );
}

export function useCreateModel({
  onMutate,
  onSuccess,
  onSettled,
  ...options
}: UseMutationOptions<AIModel, APIError, UseCreateModelVariables> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useMutation(({ model, files }) => api.models.create(model, files), {
    ...options,
    onSettled: (...args) => {
      if (onSettled) {
        onSettled(...args);
      }

      queryClient.invalidateQueries(['models']);
    },
    onSuccess: async (model, ...rest) => {
      await queryClient.invalidateQueries(['models']);

      if (onSuccess) {
        onSuccess(model, ...rest);
      }
    },
  });
}

type UseDeleteModelContext = {
  previousModels: AIModel[] | undefined;
} | void;

export function useDeleteModel({
  onMutate,
  onError,
  onSettled,
}: UseMutationOptions<void, APIError, AIModel, UseDeleteModelContext> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useDangerousMutation<void, UseDeleteModelContext, AIModel>(
    'Are you sure you want to delete this AI model?',
    (model) => api.models.delete(model.id),
    {
      onMutate: async (model) => {
        await queryClient.cancelQueries(['models']);
        const models = queryClient.getQueryData<AIModel[]>(['models']) || [];
        const updatedModels = models.filter(({ id }) => id !== model.id);

        queryClient.setQueryData(['models'], updatedModels);

        onMutate?.(model);

        return { previousModels: models };
      },
      onError: (err, deletedModel, context) => {
        if (context?.previousModels) {
          queryClient.setQueryData(['models'], context.previousModels);
        }

        onError?.(err, deletedModel, context);
      },
      onSettled: (...args) => {
        queryClient.invalidateQueries(['models']);
        onSettled?.(...args);
      },
    }
  );
}
