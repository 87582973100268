import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

export function useSetupPayment(options?: UseQueryOptions<string, APIError>) {
  const api = useAPI();
  const { data: organization } = useCurrentOrganization();

  return useAuthenticatedQuery(
    ['client_key'],
    () => {
      if (!organization) {
        return Promise.reject(new Error('No organization.'));
      }

      return api.organizations.billing.setupPayment(organization.id);
    },
    { ...options, cacheTime: 0 || options?.cacheTime }
  );
}
