import React from 'react';
import { useLocation } from 'react-router-dom';

import { isNotNullOrUndefined } from 'services/nullable';

import { useCameras } from 'cameras/hooks/useCameras';
import { useStreams } from 'streams/hooks/useStreams';

import {
  DeploymentFormContext,
  DeploymentFormContextProps,
  DeploymentVideoSource,
} from './deployment_form_context';
import Pipeline from 'types/pipeline';

export type DeploymentFormProviderProps = React.PropsWithChildren<
  Pick<
    DeploymentFormContextProps,
    | 'gateway'
    | 'deployment'
    | 'initialSource'
    | 'readonly'
    | 'allowedStreamIds'
    | 'allowedCameraIds'
  >
> & {
  pipeline?: Pipeline;
};

export function DeploymentFormProvider({
  children,
  pipeline: initialPipeline,
  gateway: initialGateway,
  initialSource,
  ...props
}: DeploymentFormProviderProps) {
  const location = useLocation();

  const [pipeline, setPipeline] = React.useState(initialPipeline ?? null);
  const [gateway, setGateway] = React.useState(initialGateway ?? null);
  const [sources, setSources] = React.useState<DeploymentVideoSource>(() => {
    if (!pipeline) {
      return {};
    }

    const videoSourceNodeIDs = pipeline.definition
      .filter(({ properties: { type } }) => type === 'video')
      .map(({ id }) => id)
      .sort();
    const videoSources: DeploymentVideoSource = {};

    // Initialize video sources from search params
    // ?source_typeN=TYPE&source_idN=ID
    const searchParams = new URLSearchParams(location.search);
    searchParams.sort();

    if (searchParams.has('pipeline_id')) {
      searchParams.delete('pipeline_id');
    }

    // Find video source ID+type pairs
    searchParams.forEach((value, idKey) => {
      if (!idKey.includes('source_id')) {
        return;
      }

      const num = Number(idKey.match(/\d/)?.[0]);
      const typeKey = `source_type${num}`;

      if (!num || Number.isNaN(num) || !value || !searchParams.has(typeKey)) {
        return;
      }

      const nodeID = videoSourceNodeIDs[num];

      if (!nodeID) {
        return;
      }

      videoSources[nodeID] = {
        source_id: value ?? undefined,
        source_type: searchParams.get(typeKey) ?? undefined,
      };
    });

    return videoSources;
  });

  const cameraIds = Object.values(sources)
    .filter(({ source_type }) => source_type === 'camera')
    .map(({ source_id }) => source_id)
    .filter(isNotNullOrUndefined);
  const { data: cameras, isLoading: isLoadingCameras } = useCameras(
    ['cameras', 'deployment-gateway'],
    {
      camera_ids: cameraIds,
      limit: cameraIds.length,
    },
    { enabled: cameraIds.length > 0, keepPreviousData: false }
  );

  const streamIds = Object.values(sources)
    .filter(({ source_type }) => source_type === 'stream')
    .map(({ source_id }) => source_id)
    .filter(isNotNullOrUndefined);
  const { data: streams, isLoading: isLoadingStreams } = useStreams(
    ['streams'],
    {
      stream_ids: streamIds,
      limit: streamIds.length,
    },
    { enabled: streamIds.length > 0, keepPreviousData: false }
  );

  const selectedGatewayIds = React.useMemo(() => {
    if (gateway) {
      return [gateway.id];
    }

    if (initialGateway) {
      return [initialGateway.id];
    }

    return undefined;
  }, [gateway, initialGateway]);

  const allowedGatewayIds = React.useMemo(() => {
    if (selectedGatewayIds) {
      return selectedGatewayIds;
    }

    return [
      ...new Set(
        [...(cameras ? cameras.data : []), ...(streams ? streams.data : [])]
          .map(({ gateway_id }) => gateway_id)
          .filter(isNotNullOrUndefined)
      ),
    ];
  }, [cameras, streams, selectedGatewayIds]);

  // Reset selected gateway when resetting video sources
  React.useEffect(() => {
    if (Object.keys(sources).length === 0) {
      setGateway(null);
    }
  }, [sources]);

  function updatePipeline(pipeline: Pipeline | null) {
    setPipeline(pipeline);

    if (!initialSource || !pipeline) {
      return;
    }

    const [firstVideoSourceNodeID] = pipeline.definition
      .filter(({ properties: { type } }) => type === 'video')
      .map(({ id }) => id)
      .sort();
    const videoSources: DeploymentVideoSource = {};

    if (firstVideoSourceNodeID) {
      videoSources[firstVideoSourceNodeID] = {
        source_id: initialSource.id,
        source_type: initialSource.source_type,
      };
    }

    setSources(videoSources);
  }

  return (
    <DeploymentFormContext.Provider
      value={{
        ...props,
        pipeline,
        allowedGatewayIds,
        isLoading: isLoadingCameras || isLoadingStreams,
        sources,
        gateway,
        setGateway,
        setPipeline: updatePipeline,
        setSources,
      }}
    >
      {children}
    </DeploymentFormContext.Provider>
  );
}
