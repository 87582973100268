import React from 'react';
import classNames from 'classnames/bind';

import styles from './Loader.module.scss';

const c = classNames.bind(styles);

export type PercentageLoaderProps = {
  progress?: number | null;
  size?: 'small' | 'xsmall';
};

const RADIUS = 10;
const MAX_PROGRESS = Math.PI * 2 * RADIUS;

export function PercentageLoader({ size, progress }: PercentageLoaderProps) {
  const progressPercent = Math.round(Number(progress) * 100);
  const offset = Math.round((1 - Number(progress)) * MAX_PROGRESS);

  return (
    <div
      className={c('loader progress-loader', size)}
      aria-label={`${progressPercent}% complete`}
    >
      <svg
        className={c('progress-loader-icon')}
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle
          className={c('progress-loader-base')}
          cx="12"
          cy="12"
          r={RADIUS}
          strokeWidth="3"
        />
        <circle
          className={c('progress-loader-fill')}
          cx="12"
          cy="12"
          r={RADIUS}
          strokeWidth="3"
          strokeDasharray={MAX_PROGRESS}
          strokeDashoffset={offset}
        />
      </svg>
    </div>
  );
}
