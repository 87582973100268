import React, { ChangeEvent } from 'react';
import classnames from 'classnames/bind';
import { DateTime } from 'luxon';
import { Menu, Pagination, Skeleton } from '@mantine/core';
import { filesize } from 'filesize';
import { useParams } from 'react-router-dom';

import { DeploymentParams } from 'deployments/views/DeploymentDetail/DeploymentDetail';
import { DeploymentState } from 'types/deployment';
import { LumeoFile } from 'types/file';

import download from 'services/download_file';
import { addErrorNotification } from 'services/notification';
import { isAPIError } from 'types/api_error';
import { pluralize } from 'services/string';
import { useAPI } from 'hooks/api/useAPI';
import {
  useDeleteFiles,
  UseDeleteFilesParams,
} from 'files/hooks/useDeleteFiles';
import { useFiles, UseFilesParams } from 'files/hooks/useFiles';
import { useModal } from 'hooks/useModal';
import { useSet } from 'hooks/useSet';

import { Button } from 'components/Button/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { ReactComponent as EmptyIllustration } from 'files/images/empty-files-illustration.svg';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Text } from 'components/Text/Text';
import { Thumbnail } from 'components/Thumbnail/Thumbnail';

import styles from './DeploymentFiles.module.scss';

const c = classnames.bind(styles);

type DeploymentFilesProps = { state: DeploymentState };

export function DeploymentFiles({ state }: DeploymentFilesProps) {
  const { client } = useAPI();
  const { applicationID, deploymentID } = useParams<DeploymentParams>();

  const { open } = useModal();

  const [selectedFiles, { add, set, clear, remove }] = useSet<string>();

  const [params, setParams] = React.useState<UseFilesParams & { page: number }>(
    {
      deployment_ids: deploymentID ? [deploymentID] : undefined,
      with_thumbnail: true,
      include_snapshots: false,
      page: 1,
    }
  );

  const {
    data: files,
    isFetching,
    isPreviousData,
    isLoading,
  } = useFiles({
    params,
    enabled: Boolean(deploymentID),
    keepPreviousData: true,
    refetchInterval: state === 'running' ? 10000 : false,
  });

  const { mutateAsync: deleteFiles } = useDeleteFiles({
    onSuccess: (_, params) => handleDeleteFilesSuccess(params),
  });

  function handleDeleteFilesSuccess(params: UseDeleteFilesParams) {
    if ('deployment_ids' in params) {
      clear();
      return;
    }

    if ('file_ids' in params && params.file_ids) {
      const newSelectedFiles = [...selectedFiles].filter(
        (id) => params.file_ids && !params.file_ids.includes(id)
      );
      set(newSelectedFiles);
    }
  }

  function handleDeleteAllDeploymentFiles() {
    if (!deploymentID) {
      return;
    }

    open('confirm-delete', {
      title: 'Are you sure you want to delete all files of this deployment?',
      onConfirm: () => deleteFiles({ deployment_ids: [deploymentID] }),
    });
  }

  function handleDeleteFiles(ids: LumeoFile['id'][]) {
    open('confirm-delete', {
      title: `Are you sure you want to delete ${pluralize(ids.length, 'this', String(ids.length))} ${pluralize(ids.length, 'file')}?`,
      onConfirm: () => deleteFiles({ file_ids: ids }),
    });
  }

  async function handleDownloadMetadata(id: LumeoFile['id']) {
    if (!applicationID) {
      return;
    }

    try {
      const { data: file } = await client.get<LumeoFile>(
        `/apps/${applicationID}/files/${id}`
      );

      if (!file.metadata_url) {
        addErrorNotification({
          title: 'Unable to download metadata',
          message: 'Metadata URL is missing.',
        });
        return;
      }

      download(file.name, file.metadata_url);
    } catch (error) {
      addErrorNotification({
        title: 'Unable to download metadata.',
        error: isAPIError(error) ? error : undefined,
      });
    }
  }

  async function handleDownloadFile(id: LumeoFile['id']) {
    if (!applicationID) {
      return;
    }

    try {
      const { data: file } = await client.get<LumeoFile>(
        `/apps/${applicationID}/files/${id}`
      );

      if (!file.data_url) {
        addErrorNotification({
          title: 'Unable to download file',
          message: 'Data URL is missing.',
        });
        return;
      }

      download(file.name, file.data_url);
    } catch (error) {
      addErrorNotification({
        title: 'Unable to download file',
        error: isAPIError(error) ? error : undefined,
      });
    }
  }

  function handleCheckboxSelectFile({
    target: { checked, value },
  }: ChangeEvent<HTMLInputElement>) {
    if (checked) {
      add(value);
    } else {
      remove(value);
    }
  }

  function handleSelectAllOnPage() {
    if (!files) {
      return;
    }

    set(files.data.map(({ id }) => id));
  }

  const hasFiles = files && files.data.length > 0;
  const hasSelectedFiles = selectedFiles.length > 0;
  const hasPagination = files && files.total_pages > 1;

  return (
    <div className={c('wrap')}>
      <div className={c('header', 'border')}>
        <Button
          intent="danger"
          size="small"
          onClick={handleDeleteAllDeploymentFiles}
          disabled={!hasFiles}
        >
          Delete all
        </Button>
      </div>
      <ul className={c('list')}>
        {isLoading || (isFetching && isPreviousData) ? (
          <SkeletonList
            min={hasFiles ? files.data.length : 5}
            max={hasFiles ? files.data.length : 5}
            component={
              <div className={c('item')}>
                <Checkbox id="file-checkbox-skeleton" disabled />
                <div className={c('button')}>
                  <Thumbnail className={c('thumbnail')} isLoading />
                  <Skeleton height="var(--size-md)" width="40%" />
                  <Skeleton height="var(--size-md)" width="10rem" />
                </div>
                <IconButton
                  icon="more-vertical"
                  label="More actions"
                  disabled
                />
              </div>
            }
          />
        ) : (
          <>
            {!hasFiles && (
              <EmptyView image={EmptyIllustration}>
                No recordings generated yet.
              </EmptyView>
            )}

            {hasFiles && (
              <>
                {files.data.map((file) => (
                  <li key={file.id} className={c('item', 'border')}>
                    <Checkbox
                      id={`file-checkbox-${file.id}`}
                      checked={selectedFiles.includes(file.id)}
                      value={file.id}
                      onChange={handleCheckboxSelectFile}
                    />
                    <button
                      className={c('button')}
                      onClick={() => open('file', { id: file.id })}
                    >
                      <Thumbnail
                        className={c('thumbnail')}
                        thumbnail_url={file.thumbnail_url}
                        mime_type={file.mime_type}
                      />
                      <Heading className={c('name')} level="4">
                        {file.name}
                      </Heading>
                      <Text>
                        {DateTime.fromISO(file.created_at).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </Text>
                    </button>
                    <Menu position="bottom-start">
                      <Menu.Target>
                        <IconButton icon="more-vertical" label="More actions" />
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={() => handleDownloadFile(file.id)}
                          leftSection={<Icon name="download" size="small" />}
                        >
                          Download file ({filesize(file.size)})
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => handleDownloadMetadata(file.id)}
                          leftSection={<Icon name="export" size="small" />}
                        >
                          Download metadata
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          onClick={() => handleDeleteFiles([file.id])}
                          className="theme danger"
                          leftSection={<Icon name="delete" size="small" />}
                        >
                          Delete file
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </li>
                ))}
              </>
            )}
          </>
        )}

        {/* FIXME: Extract the bulk feature into a component that works with <Pagination /> and <PaginatedTable /> */}
        {(hasSelectedFiles || hasPagination) && (
          <div className={c('controls')}>
            {hasPagination && (
              <Pagination
                total={files.total_pages}
                value={params.page}
                onChange={(page) => {
                  setParams((previousParams) => ({ ...previousParams, page }));
                }}
              />
            )}

            <div className={c('bulk-actions')}>
              <div className={c('left')}>
                <Button
                  variant="ghost"
                  size="small"
                  onClick={handleSelectAllOnPage}
                >
                  Select all on this page
                </Button>

                {hasSelectedFiles && (
                  <>
                    <Button
                      className={c('clear')}
                      variant="ghost"
                      size="small"
                      onClick={clear}
                    >
                      Clear
                    </Button>

                    <div className={c('bulk-info')}>
                      <strong className={c('bulk-info-count')}>
                        {selectedFiles.length}
                      </strong>
                      selected
                    </div>
                  </>
                )}
              </div>

              <Button
                variant="secondary"
                size="small"
                intent="danger"
                onClick={() => handleDeleteFiles(selectedFiles)}
                disabled={!hasSelectedFiles}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
      </ul>
    </div>
  );
}
