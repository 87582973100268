import classnames from 'classnames/bind';

import PipelineTemplate from 'types/pipeline_template';

import { Button } from 'components/Button/Button';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import { PipelineTemplateCardSkeleton } from './PipelineTemplateCardSkeleton';
import styles from './PipelineTemplateCard.module.scss';

const c = classnames.bind(styles);

export type PipelineTemplateCardProps = {
  template: PipelineTemplate;
  isLoading?: boolean;
  showCreateButton?: boolean;
  onClick: (template: PipelineTemplate) => void;
};

export function PipelineTemplateCard({
  template,
  isLoading,
  showCreateButton,
  onClick,
}: PipelineTemplateCardProps) {
  function handleClick() {
    onClick(template);
  }

  return (
    <article className={c('wrap')}>
      <div className={c('content')}>
        <div className={c('template')}>
          {template.categories.length > 0 && (
            <div className={c('template-categories')}>
              {template.categories.map(({ label, icon_name }) => (
                <Text
                  className={c('template-category')}
                  size="small"
                  key={label}
                  inline
                >
                  <Icon name={icon_name} size="small" />
                  <span>{label}</span>
                </Text>
              ))}
            </div>
          )}

          <Heading level="4" className={c('template-name')}>
            {template.name}
          </Heading>

          <div className={c('template-img-wrap')}>
            {template.gallery_img_url ? (
              <img
                className={c('template-img')}
                src={template.gallery_img_url}
                alt=""
              />
            ) : (
              <div className={c('template-img')}>Preview unavailable</div>
            )}

            {isLoading && (
              <div className="loading-spinner-wrap">
                <span className="loading-spinner" />
              </div>
            )}
          </div>

          {template?.description && (
            <p className={c('template-description')}>{template.description}</p>
          )}
        </div>

        <Button
          variant="none"
          id={String(template.id)}
          className={c('create-button', { visible: showCreateButton })}
          onClick={handleClick}
          aria-label={`Create pipeline based on template ${template.name}`}
          disabled={isLoading}
          size="small"
        >
          {showCreateButton && 'Create pipeline from template'}
        </Button>
      </div>
    </article>
  );
}

export { PipelineTemplateCardSkeleton };
