import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import classnames from 'classnames/bind';

import {
  GatewayConfiguration,
  GatewayMetrics,
  MetricEventType,
  MetricExporter,
  MetricExporterType,
} from 'types/gateway_configuration';

import { useCreateGatewayConfiguration } from 'gateways/hooks/useCreateGatewayConfiguration';
import { useCurrentApplication } from 'application/hooks/useCurrentApplication';
import { useGatewayConfiguration } from 'gateways/hooks/useGatewayConfiguration';
import { useSpringState } from 'hooks/useSpringState';
import { useUpdateGatewayConfiguration } from 'gateways/hooks/useUpdateGatewayConfiguration';

import * as PageLayout from 'components/PageLayout';
import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Input } from 'components/Input/Input';
import { JsonObjectInput } from 'components/JsonObjectInput/JsonObjectInput';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { Loader } from 'components/Loader/Loader';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { Radio, RadioOption } from 'components/Radio/Radio';
import { Separator } from 'components/Separator/Separator';
import { Text } from 'components/Text/Text';

import { MetricsConfigTable } from './ConfigTable';
import styles from '../Settings.module.scss';

const c = classnames.bind(styles);

type MetricsFieldValues = {
  type: MetricExporterType;
  endpoint: string;
  sendEvents: MetricEventType;
  headers: object;

  // Only used for HTTP
  username: string;
  password: string;
};

export function Metrics() {
  const { data: application } = useCurrentApplication();

  const {
    data: gatewayConfiguration,
    isInitialLoading: isLoadingGatewayConfiguration,
  } = useGatewayConfiguration(application?.default_gateway_configuration_id);

  const [isEnabled, setIsEnabled] = React.useState(false);

  const [isSuccess, setIsSuccess] = useSpringState(false, 2000);
  const [isInitialized, setIsInitialized] = React.useState(false);

  const {
    mutate: createGatewayConfiguration,
    isLoading: isCreating,
    error: createError,
  } = useCreateGatewayConfiguration({
    onSuccess(data) {
      setIsSuccess(true);
      resetForm(data);
    },
  });

  const {
    mutate: updateGatewayConfiguration,
    isLoading: isUpdating,
    error: updateError,
  } = useUpdateGatewayConfiguration({
    onSuccess(data) {
      setIsSuccess(true);
      resetForm(data);
    },
  });

  const isLoading = isLoadingGatewayConfiguration || isCreating || isUpdating;

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<MetricsFieldValues>();

  const isDirty = Object.keys(dirtyFields).length > 0;

  function resetForm({ metrics }: GatewayConfiguration) {
    if (!metrics) {
      setIsEnabled(false);
      reset({
        type: 'otlp',
        endpoint: '',
        sendEvents: 'disabled',
        headers: {},
        username: '',
        password: '',
      });
      return;
    }

    const [exporter] = metrics.exporters;
    const { type } = exporter;
    const headers = exporter?.headers ?? {};

    const [username, password] = parseAuthHeader(headers);

    reset({
      type,
      endpoint: exporter.endpoint,
      sendEvents: exporter.send_events,
      headers: type === 'otlp' ? exporter.headers : {},
      username: type === 'otlp_http' ? username : '',
      password: type === 'otlp_http' ? password : '',
    });
  }

  function handleEnabledChange({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) {
    setIsEnabled(checked);

    if (!checked && gatewayConfiguration?.metrics) {
      // Disable metrics
      updateGatewayConfiguration({
        id: gatewayConfiguration.id,
        metrics: null,
      });
    }
  }

  function createAuthHeader(username?: string, password?: string) {
    if (!username || !password) {
      return {};
    }

    const base64Credentials = Buffer.from(
      `${username}:${password}`,
      'utf-8'
    ).toString('base64');

    return { authorization: `Basic ${base64Credentials}` };
  }

  function parseAuthHeader(headers: object) {
    if (
      'authorization' in headers &&
      typeof headers.authorization === 'string' &&
      headers.authorization.startsWith('Basic ')
    ) {
      const auth = headers.authorization.replace('Basic ', '');

      const credentials = Buffer.from(auth, 'base64').toString('utf-8');

      // Split only at the first ":" colon
      const [username, ...rest] = credentials.split(':');
      const password = rest.join(':');

      return [username, password];
    }

    return [];
  }

  function onSubmit({
    type,
    endpoint,
    sendEvents,
    headers,
    username,
    password,
  }: MetricsFieldValues) {
    if (isLoading) {
      return;
    }

    // otlp_http does currently not support arbitrary headers
    const exporter: MetricExporter =
      type === 'otlp_http'
        ? {
            type,
            endpoint,
            send_events: sendEvents,
            headers: createAuthHeader(username, password),
          }
        : {
            type,
            endpoint,
            send_events: sendEvents,
            headers,
          };

    const metrics: GatewayMetrics = { exporters: [exporter] };

    if (gatewayConfiguration?.id) {
      updateGatewayConfiguration({ id: gatewayConfiguration.id, metrics });
    } else {
      createGatewayConfiguration({ metrics });
    }
  }

  React.useEffect(() => {
    if (isInitialized) {
      return;
    }

    // Wait for gatewayConfiguration object to load if default config id is set
    if (
      application &&
      application.default_gateway_configuration_id &&
      !gatewayConfiguration
    ) {
      return;
    }

    setIsEnabled(Boolean(gatewayConfiguration?.metrics));

    const exporter = gatewayConfiguration?.metrics?.exporters[0];
    const type = exporter?.type ?? 'otlp';
    const headers = exporter?.headers ?? {};

    const [username, password] = parseAuthHeader(headers);

    reset({
      type,
      endpoint: exporter?.endpoint,
      sendEvents: exporter?.send_events ?? 'disabled',
      headers: type === 'otlp' ? headers : {},
      username: type === 'otlp_http' ? username : '',
      password: type === 'otlp_http' ? password : '',
    });

    setIsInitialized(true);
  }, [application, gatewayConfiguration, isInitialized, reset]);

  return (
    <PageLayout.Root size="small">
      <PageLayout.Container>
        <div className={c('wrap')}>
          <form className={c('section')} onSubmit={handleSubmit(onSubmit)}>
            <Box variant="secondary">
              {(isLoadingGatewayConfiguration || !isInitialized) && (
                <div className={c('box-loader')}>
                  <Loader
                    size="small"
                    text="Loading metrics configuration..."
                  />
                  <Text aria-hidden="true" align="center">
                    Loading metrics configuration...
                  </Text>
                </div>
              )}

              <label htmlFor="metrics-toggle" className={c('toggle-field')}>
                <LightSwitch
                  id="metrics-toggle"
                  checked={isEnabled}
                  onChange={handleEnabledChange}
                />
                <strong>Enable metrics</strong>
              </label>

              {isEnabled && (
                <>
                  <Separator />

                  <Field label="Export via" error={errors.type}>
                    <Controller
                      name="type"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Radio
                          id="metrics-export-type"
                          value={value}
                          onChange={onChange}
                          disabled={isLoading}
                        >
                          <RadioOption value="otlp">gRPC</RadioOption>
                          <RadioOption value="otlp_http">HTTP</RadioOption>
                        </Radio>
                      )}
                    />
                  </Field>

                  <Field
                    id="metrics-endpoint"
                    label="Metrics endpoint"
                    error={errors.endpoint}
                    required
                  >
                    <Input
                      id="metrics-endpoint"
                      autoComplete="off"
                      spellCheck="false"
                      disabled={isLoading}
                      {...register('endpoint', { required: true })}
                    />

                    <div>
                      <Text type="paragraph">
                        Metrics and traces will be sent to the specified URI
                        using OTLP.
                        <br />
                        <ExternalLink href="https://docs.lumeo.com/docs/metrics">
                          Metrics docs
                        </ExternalLink>
                      </Text>
                    </div>
                  </Field>

                  <Field label="Send events" error={errors.sendEvents}>
                    <Controller
                      name="sendEvents"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Radio
                          id="metrics-event-type"
                          value={value}
                          onChange={onChange}
                          disabled={isLoading}
                        >
                          <RadioOption value="as_logs">As logs</RadioOption>
                          <RadioOption value="as_traces">As traces</RadioOption>
                          <RadioOption value="disabled">Disabled</RadioOption>
                        </Radio>
                      )}
                    />
                  </Field>

                  {watch('type') === 'otlp' && (
                    <Field
                      label="Headers"
                      info="incl. authentication"
                      error={errors.headers}
                    >
                      <Controller
                        name="headers"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <JsonObjectInput value={value} onChange={onChange} />
                        )}
                      />
                    </Field>
                  )}

                  {watch('type') === 'otlp_http' && (
                    <>
                      <Heading level={4}>Authentication</Heading>
                      <Field label="Username" error={errors.username}>
                        <Input
                          autoComplete="off"
                          spellCheck="false"
                          disabled={isLoading}
                          {...register('username', {
                            required: !!watch('password'),
                            validate: (value) =>
                              !value.includes(':') ||
                              // RFC 7617
                              'Username cannot contain a colon',
                          })}
                        />
                      </Field>

                      <Field label="Password" error={errors.password}>
                        <PasswordInput
                          autoComplete="off"
                          spellCheck="false"
                          disabled={isLoading}
                          {...register('password', {
                            required: !!watch('username'),
                          })}
                        />
                      </Field>
                    </>
                  )}

                  <FormErrorMessage error={createError ?? updateError} />

                  <ButtonGroup>
                    <Button
                      type="submit"
                      variant="primary"
                      loading={isUpdating || isCreating}
                    >
                      Save metrics configuration
                    </Button>

                    {isDirty && (
                      <InlineNotification>Unsaved changes</InlineNotification>
                    )}

                    {isSuccess && (
                      <InlineNotification intent="success">
                        Saved!
                      </InlineNotification>
                    )}
                  </ButtonGroup>
                </>
              )}
            </Box>
          </form>

          <MetricsConfigTable />
        </div>
      </PageLayout.Container>
    </PageLayout.Root>
  );
}
