import React from 'react';
import { useLocation } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import Gateway, { GATEWAY_STATES, GatewayState } from 'types/gateway';

import { useAppRedirect } from 'hooks/useAppRedirect';
import { useGateways, UseGatewaysFilterParams } from 'hooks/api/useGateways';
import { useMqttGatewaysState } from 'gateways/hooks/useMqttGatewaysState';
import { useTagsFilterParams } from 'tags/hooks/useTagsFilterParams';

import * as PageLayout from 'components/PageLayout';
import { AddGatewayModal } from 'gateways/components/AddGateway';
import { Button } from 'components/Button/Button';
import { GatewaysTable } from 'gateways/components/Table/Table';
import { Icon } from 'components/Icon/Icon';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { StateSelect } from 'components/StateSelect/StateSelect';

export function GatewayOverview() {
  const redirect = useAppRedirect();
  const { state } = useLocation();
  const defaultStateFilter = state?.filters?.status;

  const [isAddingGateway, toggleIsAddingGateway] = useToggle();

  const tagFilters = useTagsFilterParams();
  const [queryFilters, setQueryFilters] =
    React.useState<UseGatewaysFilterParams>({
      page: 1,
      statuses: defaultStateFilter ? [defaultStateFilter] : undefined,
    });

  const queryResult = useGateways(
    {
      ...tagFilters,
      ...queryFilters,
    },
    { keepPreviousData: true }
  );

  useMqttGatewaysState(
    queryResult.data?.data ? queryResult.data.data.map(({ id }) => id) : []
  );

  const [searchFilters, setSearchFilters] =
    React.useState<UseGatewaysFilterParams>({});

  const searchQueryResult = useGateways(
    ['gateways-suggestion'],
    {
      ...tagFilters,
      ...searchFilters,
      limit: 20,
    },
    { enabled: Object.keys(searchFilters).length > 0, keepPreviousData: false }
  );

  const handleSearchInput = React.useCallback((value?: string) => {
    if (!value) {
      setSearchFilters({});
      setQueryFilters({ page: 1 });
      return;
    }

    setSearchFilters({
      gateway_names: [value],
    });
  }, []);

  function handleApplyFilters() {
    setQueryFilters({ ...searchFilters, page: 1 });
  }

  function handleSuggestionSelect({ id }: Gateway) {
    redirect(`/deploy/gateways/${id}`);
  }

  function handleStateFilterChange(state: string | null) {
    setQueryFilters((previousFilters) => ({
      ...previousFilters,
      statuses: state ? [state as GatewayState] : undefined,
      page: 1,
    }));

    setSearchFilters((previousFilters) => ({
      ...previousFilters,
      statuses: state ? [state as GatewayState] : undefined,
    }));
  }

  function handleRefreshClick() {
    queryResult.refetch();
  }

  const hasFilters =
    Object.keys(queryFilters).length > 0 ||
    Object.values(tagFilters).some(Boolean);

  return (
    <PageLayout.Root>
      <QuerySearch
        entityName="gateway"
        searchLabel="Search gateways..."
        searchResultField="name"
        searchQueryResult={searchQueryResult}
        onApplyFilters={handleApplyFilters}
        onSuggestionSelect={handleSuggestionSelect}
        onValueChange={handleSearchInput}
        totalElements={queryResult.data?.total_elements}
        filters={
          <StateSelect
            onChange={handleStateFilterChange}
            options={GATEWAY_STATES}
            defaultValue={defaultStateFilter}
            size="small"
          />
        }
        actions={
          <Button
            size="small"
            variant="secondary"
            onClick={handleRefreshClick}
            loading={queryResult.isRefetching}
          >
            <Icon name="rotate" />
            <span>Refresh</span>
          </Button>
        }
      />

      <div id="up-gateways-list">
        <GatewaysTable
          queryResult={queryResult}
          pageSize={50}
          page={queryFilters.page}
          onPageChange={(page) =>
            setQueryFilters((previous) => ({ ...previous, page }))
          }
          hasFilters={hasFilters}
          onCreate={toggleIsAddingGateway}
        />
      </div>

      <AddGatewayModal open={isAddingGateway} onClose={toggleIsAddingGateway} />
    </PageLayout.Root>
  );
}
