import { ComponentType } from './component';

import AddMetadata from './add_metadata';
import CaptureClip from './capture_clip';
import CaptureSnapshot from './capture_snapshot';
import DemultiplexVideo from './demultiplex_video';
import MultiplexVideo from './multiplex_video';
// import ConvertVideo from './convert_video';
import DetectMotion from './detect_motion';
import EncodeVideo from './encode_video';
import AIModel from './ai_model';
import CustomFunction from './custom_function';
// import OpenCVFunction from './opencv_function';
import OverlayMetadata from './overlay_metadata';
import StreamRTSP from './stream_rtsp';
import StreamWebRTC from './stream_webrtc';
import TileInGrid from './tile_in_grid';
import TransformVideo from './transform_video';
import TrackObjects from './track_objects';
import VideoSource from './video_source';

import { nodeCategories } from './categories';

export const components: ComponentType[] = [
  CustomFunction,
  TransformVideo,
  // ConvertVideo,
  EncodeVideo,
  // OpenCVFunction,
  MultiplexVideo,
  DemultiplexVideo,
  OverlayMetadata,
  DetectMotion,
  AIModel,
  StreamRTSP,
  StreamWebRTC,
  VideoSource,
  AddMetadata,
  TileInGrid,
  CaptureClip,
  CaptureSnapshot,
  TrackObjects,
];

export function getAllComponents(): ComponentType[] {
  return Object.values(nodeCategories).flatMap(({ components }) => components);
}

export function getComponentByName(name: string): ComponentType | undefined {
  return getAllComponents().find(
    (component: any) => new component().name === name
  );
}

export function getComponentByExportType(
  type: string
): ComponentType | undefined {
  return getAllComponents().find((component) => component.exportType === type);
}
