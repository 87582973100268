import classnames from 'classnames/bind';
import React from 'react';
import RGL from 'react-grid-layout';
import { Control, Controller, useFieldArray, useForm } from 'react-hook-form';

import { generateId } from 'services/string';

import { useUpdateDashboard } from 'dashboards/hooks/useUpdateDashboard';
import { useDeployments } from 'hooks/api/useDeployments';
import { useGateways } from 'hooks/api/useGateways';
import { useStreams, UseStreamsFilterParams } from 'streams/hooks/useStreams';

import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Stream, { StreamSource, StreamType } from 'types/stream';
import { MultiStreamWidget } from 'types/dashboard_widget';

import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { Input } from 'components/Input/Input';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { Loader } from 'components/Loader/Loader';
import { MultiSelectInput } from 'components/MultiSelectInput/MultiSelectInput';
import { Select } from 'components/Select/Select';
import { DeploymentSelect } from 'deployments/components/DeploymentSelect/DeploymentSelect';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { StreamSelect } from 'streams/components/StreamSelect/StreamSelect';

import { useWidgetModal } from '../WidgetModal';
import { WidgetFieldValues, WidgetFormProps } from './DataForm';
import styles from './FilesForm.module.scss';

const c = classnames.bind(styles);

const FILTER_OPTIONS = [
  { label: 'Gateway ID', value: 'gateway_ids' },
  { label: 'Deployment ID', value: 'deployment_ids' },
  { label: 'Stream ID', value: 'stream_ids' },
  { label: 'Stream name', value: 'stream_names' },
  { label: 'Stream source', value: 'sources' },
] as const;

type QueryFilter = {
  entityType: string | undefined;
  // FIXME Refactor types & remove any
  entities: any[];
};

export type MultiStreamWidgetFieldValues = WidgetFieldValues & {
  filters: QueryFilter[];
  showOnlineStreamsOnly: boolean;
  autoPlay: boolean;
};

export function MultiStreamForm({
  widget,
  dashboard,
  onIsDirtyChange,
  onUpdate,
}: WidgetFormProps<MultiStreamWidget>) {
  const initialGatewayIds = widget?.options?.gateway_ids ?? [];

  const { data: initialGateways, isFetching: isGatewaysLoading } = useGateways(
    [`${widget?.id}_form_gateways`],
    {
      gateway_ids: initialGatewayIds,
    },
    {
      enabled: initialGatewayIds.length > 0,
    }
  );

  const initialDeploymentIds = widget?.options?.deployment_ids ?? [];

  const { data: initialDeployments, isFetching: isDeploymentsLoading } =
    useDeployments(
      [`${widget?.id}_form_deployments`],
      {
        deployment_ids: initialDeploymentIds,
      },
      {
        enabled: initialDeploymentIds.length > 0,
      }
    );

  const initialStreamIds = widget?.options?.stream_ids ?? [];

  const { data: initialStreams, isFetching: isStreamsLoading } = useStreams(
    [`${widget?.id}_form_streams`],
    {
      stream_ids: initialStreamIds,
    },
    {
      enabled: initialStreamIds.length > 0,
    }
  );

  const isFilterLoading =
    isGatewaysLoading || isDeploymentsLoading || isStreamsLoading;

  const { onClose } = useWidgetModal();

  const { mutate, isLoading: isUpdating } = useUpdateDashboard({
    onSuccess: onClose,
  });

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    register,
    reset,
  } = useForm<MultiStreamWidgetFieldValues>({
    defaultValues: {
      name: widget?.name,
      showOnlineStreamsOnly:
        widget?.options?.status?.includes('online') || false,
      autoPlay: widget?.autoPlay ?? true,
    },
  });

  React.useEffect(() => {
    const gatewayFilters = initialGateways
      ? [{ entityType: 'gateway_ids', entities: initialGateways.data }]
      : [];

    const deploymentFilters = initialDeployments
      ? [
          {
            entityType: 'deployment_ids',
            entities: initialDeployments.data,
          },
        ]
      : [];

    const streamFilters = initialStreams
      ? [{ entityType: 'stream_ids', entities: initialStreams.data }]
      : [];

    const streamNameFilters = widget?.options?.stream_names
      ? [
          {
            entityType: 'stream_names',
            entities: widget.options.stream_names,
          },
        ]
      : [];

    const streamSourceFilters = widget?.options?.sources
      ? [{ entityType: 'sources', entities: widget.options.sources }]
      : [];

    const initialFilters = [
      ...gatewayFilters,
      ...deploymentFilters,
      ...streamFilters,
      ...streamNameFilters,
      ...streamSourceFilters,
    ];

    reset({
      filters: initialFilters,
      showOnlineStreamsOnly:
        widget?.options?.status?.includes('online') || false,
      autoPlay: widget?.autoPlay ?? true,
    });
  }, [
    reset,
    initialGateways,
    initialDeployments,
    initialStreams,
    widget?.options?.stream_names,
    widget?.options?.sources,
    widget?.options?.status,
    widget?.autoPlay,
  ]);

  const {
    fields: filterFields,
    append: appendFilter,
    remove: removeFilter,
  } = useFieldArray<MultiStreamWidgetFieldValues>({
    control,
    name: 'filters',
  });

  React.useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  React.useEffect(() => {
    onUpdate(isUpdating || isFilterLoading);
  }, [isUpdating, onUpdate, isFilterLoading]);

  function onSubmit({
    name,
    filters,
    showOnlineStreamsOnly,
    autoPlay,
  }: MultiStreamWidgetFieldValues) {
    if (!dashboard) {
      return;
    }

    const id = widget?.id || generateId();
    const layout: Omit<RGL.Layout, 'i'> =
      widget?.layout || dashboard.getNewWidgetLayout(6, 6);

    const gatewayIds = filters
      .filter((filter) => filter.entityType === 'gateway_ids')
      .at(0)
      ?.entities.map((entity) => entity.id);

    const deploymentIds = filters
      .filter((filter) => filter.entityType === 'deployment_ids')
      .at(0)
      ?.entities.map((entity) => entity.id);

    const streamIds = filters
      .filter((filter) => filter.entityType === 'stream_ids')
      .at(0)
      ?.entities.map((entity) => entity.id);

    const streamNames = filters
      .filter((filter) => filter.entityType === 'stream_names')
      .at(0)
      ?.entities.map((entity) => entity);

    const streamSources = filters
      .filter((filter) => filter.entityType === 'sources')
      .at(0)
      ?.entities.map((entity) => entity);

    const options: UseStreamsFilterParams = {
      gateway_ids: gatewayIds,
      deployment_ids: deploymentIds,
      stream_ids: streamIds,
      stream_names: streamNames,
      sources: streamSources,
      status: showOnlineStreamsOnly ? ['online'] : undefined,
    };

    mutate(
      dashboard.upsertWidget(
        new MultiStreamWidget(id, name, options, autoPlay, layout)
      )
    );
  }

  function getAvailableSelects() {
    return FILTER_OPTIONS.filter(
      (option) =>
        !new Set(
          getValues()
            .filters?.filter((filter) => filter.entityType)
            .map((filter) => filter.entityType) ?? []
        ).has(option.value)
    );
  }

  const filters = watch('filters');
  const canAdd = filters?.length < FILTER_OPTIONS.length;

  return (
    <form id="multistream-widget-form" onSubmit={handleSubmit(onSubmit)}>
      <Field label="Name" error={errors.name}>
        <Input
          {...register('name', {
            required: 'Please choose a name for this widget.',
          })}
          type="text"
          autoComplete="off"
          spellCheck="false"
        />
      </Field>
      <Field label="Show online Streams only" id="show-online-streams-only">
        <LightSwitch
          id="show-online-streams-only"
          {...register('showOnlineStreamsOnly')}
        />
      </Field>
      <Field label="Enable autoplay" id="autoplay">
        <LightSwitch id="autoplay" {...register('autoPlay')} />
      </Field>

      <Field label="Filters" error={errors.filters}>
        <div className={c('filters')}>
          {isFilterLoading && <Loader size="small" text="Loading filters" />}
          {!isFilterLoading &&
            filterFields.map((field, index) => (
              <div className={c('filter')} key={field.id}>
                <div>
                  <Controller
                    name={`filters.${index}.entityType`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      const selectedValue = FILTER_OPTIONS.find(
                        (option) => option.value === value
                      );

                      return (
                        <Select
                          aria-label="Select type"
                          options={getAvailableSelects()}
                          onChange={(option) => {
                            setValue(`filters.${index}.entities`, []);
                            onChange(option?.value);
                          }}
                          value={selectedValue}
                        />
                      );
                    }}
                    rules={{ required: true }}
                  />
                </div>
                <div>
                  <FilterSelect
                    type={watch(`filters.${index}.entityType`)}
                    control={control}
                    index={index}
                  />
                </div>
                <IconButton
                  icon="cancel"
                  label="Remove filter"
                  onClick={() => removeFilter(index)}
                />
              </div>
            ))}
        </div>
        <div>
          <Button
            variant="secondary"
            size="small"
            onClick={() =>
              appendFilter({
                entityType: undefined,
                entities: [],
              })
            }
            disabled={!canAdd || isFilterLoading}
            disabledTooltip={
              isFilterLoading
                ? 'Loading Filters...'
                : 'Maximum amount of filters selected.'
            }
          >
            <Icon name="plus" size="xsmall" />
            <span>Add filter</span>
          </Button>
        </div>
      </Field>
    </form>
  );
}

type FilterSelectProps = {
  type?: string;
  control: Control<MultiStreamWidgetFieldValues>;
  index: number;
};

function FilterSelect({ type, control, index }: FilterSelectProps) {
  return (
    <Controller
      name={`filters.${index}.entities`}
      control={control}
      render={({ field: { value, onChange } }) => {
        switch (type) {
          case 'gateway_ids':
            return (
              <GatewaySelect
                value={value as Gateway[]}
                onChange={onChange}
                isMulti
              />
            );
          case 'deployment_ids':
            return (
              <DeploymentSelect
                value={value as Deployment[]}
                onChange={onChange}
                isMulti
              />
            );
          case 'stream_ids':
            return (
              <StreamSelect
                value={value as Stream[]}
                queryFilters={{
                  stream_types: [StreamType.WEBRTC, StreamType.RTSP],
                }}
                onChange={onChange}
                isMulti
              />
            );
          case 'stream_names':
            return (
              <MultiSelectInput
                id="stream_names_multistream_filter"
                selected={value}
                onChange={onChange}
                allowCreate
              />
            );
          case 'sources':
            return (
              <MultiSelectInput
                id="sources_multistream_filter"
                options={[
                  { value: 'uri_stream' },
                  { value: 'camera_stream' },
                  { value: 'pipeline_stream' },
                ]}
                selected={value as StreamSource[]}
                onChange={onChange}
              />
            );
          default:
            return <Select options={[]} isDisabled />;
        }
      }}
      rules={{ required: true }}
    />
  );
}
