import React from 'react';
import classNames from 'classnames/bind';
import { Input } from '@mantine/core';

import { type Condition } from 'pipelines/services/condition_parser';
import { parseValue } from 'pipelines/services/condition_parser/util';

import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { Tooltip } from 'components/Tooltip/Tooltip';

import {
  type ComparisonOperatorOption,
  COMPARISON_OPERATOR_OPTIONS,
  ComparisonOperatorSelect,
} from './ComparisonOperatorSelect';
import { AccessorInput } from './AccessorInput';
import { useConditionInputState } from './ConditionInput';
import styles from './ConditionInput.module.scss';

const c = classNames.bind(styles);

export type ComparisonInputProps = {
  path: number[];
  condition: Condition;
  onRemove: () => void;
};

export function ComparisonInput({
  path,
  condition,
  onRemove,
}: ComparisonInputProps) {
  const { name, updateCondition } = useConditionInputState();

  const accessorName = `${name}_${path.join('.')}-accessor`;

  const comparator =
    COMPARISON_OPERATOR_OPTIONS.find(
      ({ value }) => value === condition.comparator.text
    ) ?? null;
  const comparatorName = `${name}_${path.join('.')}-comparator`;

  const valueName = `${name}_${path.join('.')}-value`;

  function handleComparatorChange(
    newComparator: ComparisonOperatorOption | null
  ) {
    if (!newComparator) {
      return;
    }

    updateCondition(path, 'comparator', { text: newComparator.value });
  }

  function handleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value;

    if (newValue === '') {
      const error = 'Value cannot be empty.';
      updateCondition(path, 'value', { text: newValue, error });
      return;
    }

    try {
      parseValue(newValue);
      updateCondition(path, 'value', { text: newValue });
    } catch (err) {
      let error = (err as Error).message;

      if (error.includes('Partial match')) {
        error =
          'Invalid value format. Try entering a boolean, number, or string.';
      }

      updateCondition(path, 'value', { text: newValue, error });
    }
  }

  return (
    <div className={c('comparison')}>
      <AccessorInput
        id={accessorName}
        path={path}
        value={condition.accessor.text}
        error={condition.accessor.error}
      />

      <ComparisonOperatorSelect
        id={comparatorName}
        value={comparator}
        onChange={handleComparatorChange}
      />

      <Input
        aria-label="Value"
        id={valueName}
        classNames={{ input: c('input') }}
        type="text"
        size="sm"
        value={condition.value.text}
        onChange={handleValueChange}
        rightSection={
          condition.value.error && (
            <Tooltip content={condition.value.error}>
              <Icon name="error" className="theme danger" />
            </Tooltip>
          )
        }
        error={Boolean(condition.value.error)}
      />

      <IconButton icon="cancel" label="Remove filter" onClick={onRemove} />
    </div>
  );
}
