import { FilterInput } from 'graphql/types/filter';

import { OPERATORS_WITHOUT_VALUE } from './OperatorInput';

function hasInvalidFields(filters: FilterInput[]): boolean {
  return filters.some(({ column, operator, value, and, or }) => {
    if (!column || (!value && !OPERATORS_WITHOUT_VALUE.includes(operator))) {
      return true;
    }

    if (and) {
      return hasInvalidFields(and);
    }

    if (or) {
      return hasInvalidFields(or);
    }

    return false;
  });
}

/**
 * Validation function that can be passed as a `Controller` validation rule.
 * @example
 * <Controller rules={{ validate: validateMetricFilters }} />
 **/
export function validateMetricFilters(filters?: FilterInput[]) {
  if (!filters) {
    return;
  }

  if (hasInvalidFields(filters)) {
    return 'Contains invalid filters.';
  }
}
