import React from 'react';
import classnames from 'classnames/bind';

import { useForwardedRef } from 'hooks/useForwardedRef';

import styles from './Checkbox.module.scss';

const c = classnames.bind(styles);

export type CheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'id'
> & {
  id: string;
  indeterminate?: boolean;
  label?: string;
};

function Checkbox(
  { className, id, indeterminate, label, disabled, ...rest }: CheckboxProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const innerRef = useForwardedRef(ref);

  React.useEffect(() => {
    if (!innerRef.current || indeterminate === undefined) {
      return;
    }

    innerRef.current.indeterminate = indeterminate;
  }, [innerRef, indeterminate]);

  return (
    <div className={c('wrap', className)}>
      <input
        className={c('input', 'sr-only')}
        type="checkbox"
        id={id}
        ref={innerRef}
        {...rest}
      />
      <label
        className={c('faux', { disabled })}
        htmlFor={id}
        aria-hidden="true"
      />
      {label && (
        <label htmlFor={id} className={c('label', { disabled })}>
          {label}
        </label>
      )}
    </div>
  );
}

const ForwardRef = React.forwardRef<HTMLInputElement, CheckboxProps>(Checkbox);

export { ForwardRef as Checkbox };
