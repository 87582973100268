import React from 'react';
import classNames from 'classnames/bind';
import { useUncontrolled } from '@mantine/hooks';

import { RadioOptionProps } from './RadioOption';
import styles from './Radio.module.scss';

const c = classNames.bind(styles);

export type RadioValue = string | number | null;

export type RadioProps = {
  id: string;
  children:
    | React.ReactElement<RadioOptionProps>
    | React.ReactElement<RadioOptionProps>[];
  className?: string;
  value?: RadioValue;
  defaultValue?: RadioValue;
  disabled?: boolean;
  size?: 'default' | 'large';
  onChange?: (value: RadioValue) => void;
};

function Radio(
  {
    id,
    children,
    className,
    value,
    defaultValue,
    disabled,
    size,
    onChange,
  }: RadioProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const [internalValue, setValue] = useUncontrolled({
    value,
    defaultValue,
    onChange,
  });

  if (!children) {
    return null;
  }

  return (
    <RadioContext.Provider
      value={{
        id,
        value: internalValue,
        onChange: setValue,
        disabled: Boolean(disabled),
      }}
    >
      <div id={id} className={c('wrap', className, size)} ref={ref}>
        {children}
      </div>
    </RadioContext.Provider>
  );
}

const ForwardRef = React.forwardRef<HTMLDivElement, RadioProps>(Radio);

export { ForwardRef as Radio };

export type RadioContextProps = {
  id: string;
  value: RadioValue | undefined;
  onChange: (value: RadioValue) => void;
  disabled: boolean;
};

export type RadioContextType = RadioContextProps | undefined;

export const RadioContext = React.createContext<RadioContextType>(undefined);

export { RadioOption } from './RadioOption';
export type { RadioOptionProps } from './RadioOption';
export { RadioSelectOption } from './RadioSelectOption';
export { RadioButton } from './RadioButton';
