import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Deployment from 'types/deployment';

import { useAPI } from 'hooks/api/useAPI';
import {
  updateQueriesData,
  UpdateQueriesDataContext,
} from 'services/update_queries_data';

import { useDangerousMutation } from './useDangerousMutation';
import { useBillingMutation } from './useBillingMutation';

export function useUpdateDeployment({
  onMutate,
  onSuccess,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<Deployment, APIError, Deployment> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useBillingMutation<
    Deployment,
    APIError,
    Deployment,
    UpdateQueriesDataContext<Deployment> | void
  >(api.deployments.update, {
    ...options,
    action: 'update',
    entity: 'deployment',
    async onMutate(deployment, ...args) {
      await queryClient.cancelQueries(['deployments']);
      onMutate?.(deployment, ...args);

      return updateQueriesData({
        queryClient,
        listQueryKey: ['deployments'],
        singleQueryKey: ['deployment'],
        ids: [deployment.id],
        updateData: deployment,
      });
    },
    async onSuccess(deployment, variables, context) {
      await queryClient.cancelQueries(['deployments']);
      onSuccess?.(deployment, variables, context);
    },
    onError(error, variables, context) {
      onError?.(error, variables, context);

      context?.previousLists?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSettled(data, error, variables, context) {
      onSettled?.(data, error, variables, context);

      queryClient.invalidateQueries(['deployments']);
      queryClient.invalidateQueries(['deployment', variables.id]);
    },
  });
}

export type UseRenameDeploymentParams = UseMutationOptions<
  Deployment,
  APIError,
  {
    id: Deployment['id'];
    name: string;
  }
> & {
  params?: URLSearchParams;
};

export function useRenameDeployment({
  onSuccess,
  onSettled,
  ...options
}: UseRenameDeploymentParams = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation(({ id, name }) => api.deployments.rename(id, name), {
    ...options,
    onSuccess: async (deployment, ...args) => {
      await queryClient.cancelQueries(['deployments']);
      onSuccess?.(deployment, ...args);

      return updateQueriesData({
        queryClient,
        listQueryKey: ['deployments'],
        singleQueryKey: ['deployment'],
        ids: [deployment.id],
        updateData: deployment,
      });
    },
    onSettled: (...args) => {
      if (onSettled) {
        onSettled(...args);
      }

      queryClient.invalidateQueries(['deployments']);
    },
  });
}

export function useCreateDeployment({
  onSettled,
  ...options
}: UseMutationOptions<Deployment, APIError, Deployment> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useBillingMutation(api.pipelines.deploy, {
    ...options,
    action: 'deploy',
    entity: 'pipeline',
    onSettled: (...args) => {
      if (onSettled) {
        onSettled(...args);
      }

      queryClient.invalidateQueries(['deployments']);
    },
  });
}

export function useDeleteDeployment({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<
  void,
  APIError,
  Deployment['id'],
  UpdateQueriesDataContext<Deployment> | void
> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete this pipeline deployment?',
    (deploymentID) => api.deployments.delete(deploymentID),
    {
      ...options,
      async onMutate(deploymentID) {
        await queryClient.cancelQueries(['deployments']);
        onMutate?.(deploymentID);

        return updateQueriesData<Deployment>({
          queryClient,
          listQueryKey: ['deployments'],
          singleQueryKey: ['deployment'],
          ids: [deploymentID],
          updateData: null,
        });
      },
      onError(error, deletedDeploymentID, context) {
        onError?.(error, deletedDeploymentID, context);

        context?.previousLists?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
        context?.previousSingles?.forEach(([queryKey, data]) =>
          queryClient.setQueryData(queryKey, data)
        );
      },
      onSettled(...args) {
        onSettled?.(...args);
        queryClient.invalidateQueries(['deployments']);
      },
    }
  );
}
