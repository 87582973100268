import React from 'react';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';

import ReteInput from 'pipelines/services/rete/controls/input';
import { DeployTimeEditableNode } from 'types/pipeline';

import { customMarkdownAnchor } from 'services/markdown';
import { serializeMultilineROI } from 'pipelines/services/rete/controls/multisegment_line_input';
import { serializeROI } from 'pipelines/services/rete/controls/roi_input';
import { useKeyRefValue } from 'pipelines/hooks/useKeyRefValue';
import { usePipelineDetailState } from 'pipelines/context/pipeline_detail_view';

import { FormMessage } from 'components/FormMessage/FormMessage';
import { Icon } from 'components/Icon/Icon';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { OptionalTooltip } from 'components/Tooltip/Tooltip';
import { VideoSourceROIValue } from 'pipelines/components/VideoSourceROI/VideoSourceROI';

import styles from './../NodePropertiesEditor.module.scss';

const c = classnames.bind(styles);

const EMPTY: DeployTimeEditableNode[] = [];

export type NodePropertiesEditorControlProps = {
  nodeID: string;
  control: ReteInput<any>;
  autoFocus?: boolean;
};

export function NodePropertiesEditorControl({
  nodeID,
  control,
  autoFocus,
}: NodePropertiesEditorControlProps) {
  const ref = React.useRef<HTMLLabelElement>(null);

  const { pipeline } = usePipelineDetailState();
  const {
    control: formControl,
    formState: { errors },
  } = useFormContext();

  const error = React.useRef<string>();

  const keyrefValue = useKeyRefValue(control.keyref);
  const isEnabled =
    control.requiresKeyrefValue === undefined ||
    control.requiresKeyrefValue === keyrefValue;

  const deployTimeEditableNodes = pipeline.deployTimeEditableNodes || EMPTY;

  function isDeployParam(): boolean {
    const deployNode = deployTimeEditableNodes.find(
      (node) => node.nodeID === nodeID
    );

    const userSpecifiedControl = deployNode?.controls.find(
      ({ key }) => key === control.key
    );
    const isUserChecked = userSpecifiedControl
      ? userSpecifiedControl.deploymentParam &&
        userSpecifiedControl.deploymentParam !== 'never'
      : undefined;

    const isDefaultChecked =
      control.deploymentParam && control.deploymentParam !== 'never';

    // 'always' is filtered out before but TypeScript doesn't know
    return (isUserChecked ?? isDefaultChecked) as unknown as boolean;
  }

  React.useEffect(() => {
    if (!ref.current || !autoFocus) {
      return;
    }

    ref.current.focus();
  }, [autoFocus]);

  React.useEffect(() => {
    if (
      control.deploymentParam === 'always' ||
      control.deploymentParam === 'never'
    ) {
      return;
    }

    control.deploymentParam = isDeployParam();
    // eslint-disable-next-line
  }, [control]);

  const handleErrorChange = React.useCallback(
    (newError: string | undefined) => {
      error.current = newError;
    },
    []
  );

  if (
    !control.component ||
    !isEnabled ||
    control.deploymentParam === 'always'
  ) {
    return null;
  }

  const id = `${nodeID}_${control.key}`;
  const hasInfo = Boolean(control.info);

  return (
    <div className={c('control', control.props.type)}>
      <LightSwitch
        className={c('control-toggle')}
        id={control.key}
        onValueChange={(checked) => {
          control.deploymentParam = checked;
        }}
        defaultChecked={isDeployParam()}
        disabled={control.deploymentParam === 'never'}
      />

      <label className={c('text-label', 'label')} htmlFor={id} ref={ref}>
        <div className={c('label-wrap')}>
          <OptionalTooltip
            content={control.info}
            side="right"
            align="center"
            showTooltip={hasInfo}
          >
            <div className={c('label-content')}>
              <span>{control.label}</span>
              {hasInfo && <Icon name="help" size="small" />}
            </div>
          </OptionalTooltip>
        </div>
      </label>

      <div className={c('input')}>
        {errors[control.key] && (
          <FormMessage intent="danger" icon="error">
            {String(errors[control.key]!.message)}
          </FormMessage>
        )}

        <Controller
          name={control.key}
          control={formControl}
          defaultValue={null}
          render={({ field: { onChange, value } }) => (
            <Inner
              id={id}
              control={control}
              value={value}
              onChange={onChange}
              onErrorChange={handleErrorChange}
              keyrefValue={keyrefValue}
            />
          )}
          rules={{
            validate() {
              return error.current ?? true;
            },
          }}
        />
        {control.description && (
          <ReactMarkdown
            className={c('markdown-description')}
            components={{
              a: customMarkdownAnchor,
            }}
          >
            {control.description}
          </ReactMarkdown>
        )}
      </div>
    </div>
  );
}

export type InnerProps = Omit<
  NodePropertiesEditorControlProps,
  'nodeID' | 'deployTimeEditableNodes'
> & {
  id: string;
  value?: any;
  keyrefValue: any;
  onChange: (value: any) => void;
  onErrorChange: (error: string | undefined) => void;
};

function Inner({
  id,
  control: {
    type,
    key,
    keyref,
    component: Component,
    // exclude required from the props since everything is optional
    // in the pipeline node editor
    props: { required, ...props },
  },
  value,
  keyrefValue,
  onChange,
  onErrorChange,
}: InnerProps) {
  const { setValue } = useFormContext();

  const [internalValue, setInternalValue] = React.useState<
    VideoSourceROIValue | string
  >(value);

  const handleChange = React.useCallback(
    (value: any, errorMessage?: string) => {
      switch (type) {
        case 'line':
        case 'polygon': {
          serializeROI(key, keyref, value, setValue);
          break;
        }
        case 'multiline': {
          serializeMultilineROI(key, keyref, value, setValue);
          break;
        }
        default: {
          setInternalValue(value);

          if (value === '') {
            onChange(null);
            break;
          }

          onChange(value);
        }
      }

      // ACE code editor does not adhere to (value, errorMessage)
      // parameter pair and doesn't really support validation
      // This also handles trigger condition errors
      if (!errorMessage || typeof errorMessage === 'string') {
        onErrorChange(errorMessage || undefined);
      }
    },
    [key, type, keyref, setValue, onChange, onErrorChange]
  );

  return (
    <Component
      {...props}
      id={id}
      value={internalValue}
      onChange={handleChange}
      keyrefValue={keyrefValue}
    />
  );
}
