import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Gateway from 'types/gateway';

import { useAPI } from 'hooks/api/useAPI';
import { useMutationWithError } from 'hooks/api/useMutationWithError';
import {
  UpdateQueriesDataContext,
  updateQueriesData,
} from 'services/update_queries_data';

export function useStopCloudGateway({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<
  void,
  APIError,
  Gateway['id'],
  UpdateQueriesDataContext<Gateway>
> = {}) {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutationWithError(api.gateways.cloud.stop, {
    ...options,
    action: 'stop',
    entity: 'cloud gateway',
    async onMutate(gatewayID) {
      await queryClient.cancelQueries(['gateways']);
      await queryClient.cancelQueries(['gateway', gatewayID]);

      onMutate?.(gatewayID);

      return updateQueriesData({
        queryClient,
        listQueryKey: ['gateways'],
        singleQueryKey: ['gateway'],
        ids: [gatewayID],
        updateData(gateway) {
          gateway.status = 'stopping';
          gateway.stops_automatically_at = null;
          return gateway;
        },
      });
    },
    onError(error, gatewayId, context) {
      onError?.(error, gatewayId, context);

      context?.previousLists?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
      context?.previousSingles?.forEach(([queryKey, data]) =>
        queryClient.setQueryData(queryKey, data)
      );
    },
    onSettled(data, error, gatewayId, ...args) {
      onSettled?.(data, error, gatewayId, ...args);

      queryClient.invalidateQueries(['gateways']);
      queryClient.invalidateQueries(['gateway', gatewayId]);
    },
  });
}
