import { Link, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';

import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';

import { relativeTimeFromNow } from 'services/date';
import { truncateMiddle } from 'services/string';

import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { OptionalTooltip } from 'components/Tooltip/Tooltip';
import {
  STATE_FORMATTED,
  StatusIndicator,
} from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';
import { TextAppLink } from 'components/TextAppLink/TextAppLink';

import { DeploymentParams } from 'deployments/views/DeploymentDetail/DeploymentDetail';

import styles from './Item.module.scss';

const c = classNames.bind(styles);

export type DeploymentListItemProps = {
  deployment: Deployment;
  gateway?: Gateway;
  isLoadingGateways?: boolean;
  pipeline?: Pipeline;
  isLoadingPipelines?: boolean;
};

export function DeploymentListItem({
  deployment,
  gateway,
  isLoadingGateways,
  pipeline,
  isLoadingPipelines,
}: DeploymentListItemProps) {
  const { deploymentID } = useParams<DeploymentParams>();
  const { search } = useLocation();

  const timeFromNow = relativeTimeFromNow(deployment.updated_at);

  return (
    <article className={c('item', { active: deploymentID === deployment.id })}>
      <Heading level="4" className={c('item-name')} asChild>
        <Link
          to={`/applications/${deployment.application_id}/deploy/deployments/${
            deployment.id + search
          }`}
          className={c('item-link')}
        >
          {deployment.name || deployment.id}
        </Link>
      </Heading>

      <Text className={c('item-info')}>
        <StatusIndicator
          className={c('item-status')}
          status={deployment.state}
          size="small"
        />
        <strong className={c('item-info-status')}>
          {STATE_FORMATTED[deployment.state] ?? deployment.state}
        </strong>
        {timeFromNow}
      </Text>

      <div>
        <p className={c('item-entity')}>
          <Icon name="pipeline" size="small" />

          {isLoadingPipelines && (
            <Text aria-hidden="true" inline>
              <span className="skeleton-text">Pipeline</span>
            </Text>
          )}

          {!isLoadingPipelines && pipeline && (
            <Text inline>
              <OptionalTooltip
                showTooltip={pipeline.name.length > 32}
                content={pipeline.name}
              >
                <TextAppLink to={`/design/pipelines/${deployment.pipeline_id}`}>
                  {truncateMiddle(pipeline.name, 48)}
                </TextAppLink>
              </OptionalTooltip>
            </Text>
          )}

          {!isLoadingPipelines && !pipeline && (
            <Text inline>Deleted pipeline</Text>
          )}
        </p>

        <p className={c('item-entity')}>
          <Icon name="gateway" size="small" />

          {isLoadingGateways && (
            <Text aria-hidden="true" inline>
              <span className="skeleton-text">Gateway</span>
            </Text>
          )}

          {!isLoadingGateways && gateway && (
            <Text inline>
              <OptionalTooltip
                showTooltip={gateway.name.length > 32}
                content={gateway.name}
              >
                <TextAppLink to={`/deploy/gateways/${deployment.gateway_id}`}>
                  {truncateMiddle(gateway.name, 32)}
                </TextAppLink>
              </OptionalTooltip>
            </Text>
          )}

          {!isLoadingGateways && !gateway && (
            <Text inline>Deleted gateway</Text>
          )}
        </p>
      </div>
    </article>
  );
}
