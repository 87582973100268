import { QueryKey } from '@tanstack/react-query';

/**
 * Removes duplicates, `null`, `undefined`, `""` empty strings, `[]` empty arrays, and `{}` empty objects.
 */
export function sanitizeQueryKey(queryKey: QueryKey): QueryKey {
  return [
    ...new Set(
      queryKey.filter(
        (key) =>
          key !== null && // Remove null
          key !== undefined && // Remove undefined
          !(typeof key === 'string' && key === '') && // Remove empty strings
          !(Array.isArray(key) && key.length === 0) && // Remove empty arrays
          !(typeof key === 'object' && Object.keys(key).length === 0) // Remove empty objects
      )
    ),
  ];
}
