import { NodeEditor } from 'rete';

function install(editor: NodeEditor) {
  editor.on('connectioncreate', ({ input, output }) => {
    if (input.node && output.node && input.node.id === output.node.id) {
      return false;
    }
  });
}

const plugin = { name: 'DisableSelfConnectingNodes', install };

export default plugin;
