import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Userpilot } from 'userpilot';

import { useAccessTokens } from 'hooks/api/useAccessTokens';
import { useApplication } from 'application/hooks/useApplication';
import { useFilesSearchClient } from 'files/hooks/useFilesSearchClient';
import { useHasAccess } from 'hooks/useHasAccess';
import { useIsLumeoAdmin, useUser } from 'hooks/useAuth';
import { usePropelAuthentication } from 'dashboards/hooks/usePropelAuthentication';

import { Admin } from 'application/views/Admin/Admin';
import { Overview } from 'application/views/Overview/Overview';
import { Deploy } from 'application/views/Deploy/Deploy';
import { Design } from 'application/views/Design/Design';
import { Monitor } from 'application/views/Monitor/Monitor';
import { Settings } from 'application/views/Settings/Settings';

import { Button } from 'components/Button/Button';
import { Heading } from 'components/Heading/Heading';
import { RequireAuth } from 'components/RequireAuth/RequireAuth';
import { Text } from 'components/Text/Text';

import { ApplicationParams } from './AppContainer';

export function App() {
  const isAdmin = useIsLumeoAdmin();
  const [hasAccess] = useHasAccess();

  const location = useLocation();
  const { update } = useIntercom();

  const { applicationID } = useParams<ApplicationParams>();
  const { data: application } = useApplication(applicationID);
  const user = useUser();

  // Pre-load
  useAccessTokens();
  useFilesSearchClient();
  usePropelAuthentication();

  React.useEffect(() => {
    update();
    Userpilot.reload();
  }, [update, location]);

  React.useEffect(() => {
    if (!application || !user?.id) {
      return;
    }

    Userpilot.identify(user.id, { application });
  }, [user, application]);

  if (!application) {
    return null;
  }

  return (
    <Routes>
      {/* OVERVIEW */}
      {hasAccess('overview') && (
        <Route
          path="*"
          element={
            <RequireAuth>
              <Overview />
            </RequireAuth>
          }
        />
      )}

      {!hasAccess('overview') &&
        (hasAccess('monitor') ? (
          <Route path="*" element={<Navigate to="../monitor" replace />} />
        ) : (
          <Route path="*" element={<Navigate to="../settings" replace />} />
        ))}

      {/* DESIGN */}
      <Route
        path="design/*"
        element={
          <RequireAuth section="design">
            <Design />
          </RequireAuth>
        }
      />

      {/* DEPLOY */}
      <Route
        path="deploy/*"
        element={
          <RequireAuth section="deploy_edit">
            <Deploy />
          </RequireAuth>
        }
      />

      {/* MONITOR */}
      <Route
        path="monitor/*"
        element={
          <RequireAuth section="monitor">
            <Monitor />
          </RequireAuth>
        }
      />

      {/* SETTINGS */}
      <Route path="settings/*" element={<Settings />} />

      {/* ADMIN */}
      {/* Not handled as a private route so we can pretend it's a 404 */}
      {isAdmin && <Route path="admin/*" element={<Admin />} />}

      {/* 404 */}
      <Route
        path="*"
        element={
          <div className="error-screen error-message">
            <div className="error-message-content">
              <Text>404</Text>
              <Heading level="1">Page not found.</Heading>
              <p></p>
              <Button to="/">Go to dashboard</Button>
            </div>
          </div>
        }
      />
    </Routes>
  );
}
