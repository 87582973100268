import React from 'react';
import classNames from 'classnames/bind';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';

import illustration from 'pipelines/images/error-pipeline.svg';

import styles from './Error.module.scss';

const c = classNames.bind(styles);

export type PipelineDetailErrorProps = {
  isRestoring: boolean;
  isDeleting: boolean;
  resetErrorBoundary: (...args: unknown[]) => void;
  onRestore: () => void;
  onDelete: () => void;
};

export function PipelineDetailError({
  isRestoring,
  isDeleting,
  resetErrorBoundary,
  onRestore,
  onDelete,
}: PipelineDetailErrorProps) {
  function handleRestoreClick() {
    onRestore();
    resetErrorBoundary();
  }

  return (
    <div className={c('wrap')}>
      <img className={c('image')} src={illustration} alt="" />
      <Heading level="2">Unable to view pipeline.</Heading>
      <Text>The pipeline is malformed and cannot be displayed.</Text>
      <Text>Do you want to restore the pipeline to a valid state?</Text>

      <ButtonGroup>
        <Button
          variant="primary"
          onClick={handleRestoreClick}
          loading={isRestoring}
        >
          Restore
        </Button>
        <Button
          variant="secondary"
          intent="danger"
          onClick={onDelete}
          loading={isDeleting}
        >
          Delete
        </Button>
      </ButtonGroup>
    </div>
  );
}
