import React from 'react';
import { useForm } from 'react-hook-form';

import Camera from 'types/camera';
import Gateway from 'types/gateway';

import { useLinkCamera } from 'hooks/api/useGatewayCameras';
import { useSpringState } from 'hooks/useSpringState';

import * as Dialog from 'components/Dialog';
import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import { Text } from 'components/Text/Text';

export type LinkFieldValues = {
  cameraName: string;
  password: string;
  username: string;
};

export type LinkGatewayCameraModalProps = Pick<
  Dialog.RootProps,
  'open' | 'onOpenChange'
> & {
  gatewayID: Gateway['id'];
  camera?: Camera;
  onSuccess: (camera: Camera) => void;
};

export function LinkGatewayCameraDialog({
  gatewayID,
  camera,
  open,
  onOpenChange,
  onSuccess,
}: LinkGatewayCameraModalProps) {
  const {
    formState: { errors },
    register,
    reset,
    handleSubmit,
  } = useForm<LinkFieldValues>({
    mode: 'onBlur',
  });

  const [didLink, setDidLink] = useSpringState(false, 2000);

  const {
    mutate: linkCamera,
    isLoading,
    error,
  } = useLinkCamera(gatewayID, {
    onSuccess() {
      setDidLink(true);
      reset();

      if (!camera) {
        return;
      }

      onSuccess(camera);
    },
  });

  React.useEffect(() => {
    if (!didLink || !onOpenChange) {
      return;
    }

    return () => {
      onOpenChange(false);
    };
  }, [didLink, onOpenChange]);

  if (error) {
    error.message = 'Unable to access the camera.';
  }

  function handleLinkCamera({
    cameraName,
    password,
    username,
  }: LinkFieldValues) {
    if (!camera) {
      return;
    }

    const linkedCamera = camera.copy();

    linkedCamera.name = cameraName;

    if (username) {
      linkedCamera.username = username;
    }

    if (password) {
      linkedCamera.password = password;
    }

    linkCamera(linkedCamera);
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Title asChild>
        <Heading level="2" asChild>
          <span>Finish setup</span>
        </Heading>
      </Dialog.Title>

      {didLink && (
        <Dialog.Message intent="success">Camera linked!</Dialog.Message>
      )}

      <form onSubmit={handleSubmit(handleLinkCamera)}>
        <Field label="Camera name" error={errors.cameraName} required>
          <Input
            disabled={didLink}
            defaultValue={camera?.name}
            {...register('cameraName', { required: 'Camera name is required' })}
          />
        </Field>

        <FormErrorMessage error={error} />

        <Text type="paragraph">
          Please provide camera credentials if required.
        </Text>

        <Field label="Username">
          <Input {...register('username')} disabled={didLink} />
        </Field>

        <Field label="Password">
          <Input type="password" {...register('password')} disabled={didLink} />
        </Field>

        <Button
          variant="primary"
          type="submit"
          loading={isLoading}
          disabled={didLink}
        >
          Connect camera
        </Button>
      </form>
    </Dialog.Root>
  );
}
