import { ColumnType, FilterOperator } from '@propeldata/ui-kit';
import { SingleValue } from 'react-select';

import { FilterInput } from 'graphql/types/filter';

import { formatPretty } from 'services/string';
import { usePropelMetric } from 'dashboards/hooks/usePropelMetric';

import { Select } from 'components/Select/Select';

import { useMetricFiltersState } from './context';

export const OPERATORS_WITHOUT_VALUE = [
  FilterOperator.IsNull,
  FilterOperator.IsNotNull,
];

const operatorOptions: OperatorOption[] = Object.values(FilterOperator).map(
  (value) => ({
    value,
    label: formatPretty(value).toLowerCase(),
  })
);

type OperatorOption = {
  value: FilterOperator;
  label: string;
};

export type OperatorInputProps = Omit<FilterInput, 'value'>;

export function OperatorInput({ id, column, operator }: OperatorInputProps) {
  const {
    metric: { name },
    updateFilter,
  } = useMetricFiltersState();

  const { data, isLoading } = usePropelMetric({ uniqueName: name });

  const type = data?.metricByName.dimensions.find(
    ({ columnName }) => columnName === column
  )?.type;

  function isOptionDisabled(operator: OperatorOption) {
    switch (type) {
      case ColumnType.Boolean:
      case ColumnType.Json:
        return [
          FilterOperator.GreaterThan,
          FilterOperator.GreaterThanOrEqualTo,
          FilterOperator.LessThan,
          FilterOperator.LessThanOrEqualTo,
        ].includes(operator?.value);
      default:
        return false;
    }
  }

  function handleOperatorChange(operator: SingleValue<OperatorOption>) {
    if (!operator?.value) {
      return;
    }

    // Clear value when switching to operators without value
    if (OPERATORS_WITHOUT_VALUE.includes(operator.value)) {
      updateFilter(id, 'value', '');
    }

    updateFilter(id, 'operator', operator.value);
  }

  return (
    <Select
      defaultValue={operatorOptions.find(({ value }) => value === operator)}
      onChange={handleOperatorChange}
      options={operatorOptions}
      isOptionDisabled={isOptionDisabled}
      isLoading={isLoading}
    />
  );
}
