import React from 'react';
import { Node as ReteNode } from 'rete';

import Node from 'types/node';

export type CurrentNodeContextProps = {
  node: Node;
  nodeID: string;
  reteNode: ReteNode;
};

const CurrentNodeContext = React.createContext<
  CurrentNodeContextProps | undefined
>(undefined);

export function useCurrentNode(): CurrentNodeContextProps {
  const context = React.useContext(CurrentNodeContext);

  if (context === undefined) {
    throw new Error(
      'Hook `useNodeState` may only be used within a <NodeProvider />.'
    );
  }

  return context;
}

export type NodeProviderProps = React.PropsWithChildren<
  Pick<CurrentNodeContextProps, 'reteNode' | 'nodeID'> & {
    node?: Node;
  }
>;

function CurrentNodeProvider({
  children,
  nodeID,
  reteNode,
  node = Node.fromReteNode(reteNode),
}: NodeProviderProps) {
  return (
    <CurrentNodeContext.Provider value={{ reteNode, nodeID, node }}>
      {children}
    </CurrentNodeContext.Provider>
  );
}

const memoizedProvider = React.memo(CurrentNodeProvider);

export { memoizedProvider as CurrentNodeProvider };
