import React from 'react';
import classNames from 'classnames/bind';

import { WidgetType } from 'types/dashboard_widget';

import { Heading } from 'components/Heading/Heading';
import { Icon, IconType } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import styles from './AddWidgetModal.module.scss';

const c = classNames.bind(styles);

export type AddWidgetModalTypeSelectProps = {
  onSelect: (type: WidgetType) => void;
};

export function AddWidgetModalTypeSelect(props: AddWidgetModalTypeSelectProps) {
  return (
    <>
      <SelectTypeButton
        {...props}
        type="data"
        icon="metrics"
        info="Analyze data output from pipeline deployments"
      />
      <SelectTypeButton
        {...props}
        type="stream"
        icon="stream"
        info="Display a video player for a selected stream"
      />
      <SelectTypeButton
        {...props}
        type="multistream"
        icon="grid"
        info="Display video players for streams matching filter criteria"
      />
      <SelectTypeButton
        {...props}
        type="files"
        icon="file"
        info="Display a list of files matching filter criteria"
      />
      <SelectTypeButton
        {...props}
        type="iframe"
        icon="metrics"
        info="Embed content from a web URL"
      />
    </>
  );
}

type SelectTypeButtonProps = {
  type: WidgetType;
  icon: IconType;
  info: string;
  onSelect: (type: WidgetType) => void;
};

function SelectTypeButton({
  type,
  icon,
  info,
  onSelect,
}: SelectTypeButtonProps) {
  function handleClick() {
    onSelect(type);
  }

  return (
    <button className={c('link')} onClick={handleClick}>
      <Icon className={c('icon')} name={icon} size="large" />
      <Heading className={c('heading')} level="3">
        {type}
      </Heading>
      <Text className={c('info')} align="start" type="paragraph">
        {info}
      </Text>
      <Icon className={c('chevron')} name="chevron-right" />
    </button>
  );
}
