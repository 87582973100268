import React from 'react';
import { Control } from 'rete';

export type GroupedControls = {
  '': Control[];
  [key: string]: Control[];
};

export function useGroupedNodeControls(controls?: any[]): GroupedControls {
  const [grouped, setGrouped] = React.useState<GroupedControls>({ '': [] });

  React.useEffect(() => {
    if (!controls) {
      return;
    }

    const groupedControls: GroupedControls = { '': [] };

    for (const control of controls.values()) {
      if (control.group) {
        if (!groupedControls[control.group]) {
          groupedControls[control.group] = [control];
        } else {
          groupedControls[control.group].push(control);
        }
      } else {
        groupedControls[''].push(control);
      }
    }

    setGrouped(groupedControls);
  }, [controls]);

  return grouped;
}
