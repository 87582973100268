import React from 'react';
import classNames from 'classnames/bind';

import { usePageLayout } from '../context/page_layout';
import { Container, ContainerProps } from './Container';
import styles from '../PageLayout.module.scss';

const c = classNames.bind(styles);

export type FooterProps = React.PropsWithChildren<{
  className?: string;
  size?: ContainerProps['size'];
}>;

export function Footer({ className, size, children }: FooterProps) {
  const { size: layoutSize } = usePageLayout();

  return (
    <div className={c(className, 'footer')}>
      <Container
        className={c(className, 'footer-container')}
        size={size ?? layoutSize}
      >
        {children}
      </Container>
    </div>
  );
}
