import classnames from 'classnames/bind';
import { useClipboard } from '@mantine/hooks';

import { Icon } from 'components/Icon/Icon';
import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './CopyToClipboard.module.scss';

const c = classnames.bind(styles);

export type CopyToClipboardProps = {
  className?: string;
  value: string;
  displayValue?: string;
};

export function CopyToClipboard({
  className,
  value,
  displayValue,
}: CopyToClipboardProps) {
  const { copied: isCopied, copy } = useClipboard({ timeout: 2000 });

  return (
    <Tooltip content="Copied to clipboard!" side="right" open={isCopied}>
      <span className={c('wrap', className)} onClick={() => copy(value)}>
        <span className={c('value')}>{displayValue ?? value}</span>
        <Icon name="clipboard" size="small" />
      </span>
    </Tooltip>
  );
}
