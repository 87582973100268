import { NavRoute, DeployRoute } from 'application/types/routes';

import { useAPI } from 'hooks/api/useAPI';

import { Button } from 'components/Button/Button';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { SidebarLink } from 'components/Sidebar/SidebarLink';

export function DeploySidebar() {
  const { applicationID } = useAPI();

  return (
    <Sidebar hasTagsTree>
      <SidebarLink
        to={`/${NavRoute.DEPLOY}/${DeployRoute.GATEWAYS}`}
        icon="gateway"
        keepSearchParams
      >
        Gateways
      </SidebarLink>
      <SidebarLink
        to={`/${NavRoute.DEPLOY}/${DeployRoute.CAMERAS}`}
        icon="camera"
        keepSearchParams
      >
        Cameras
      </SidebarLink>
      <SidebarLink
        to={`/${NavRoute.DEPLOY}/${DeployRoute.STREAMS}`}
        icon="stream"
        keepSearchParams
      >
        Streams
      </SidebarLink>
      <SidebarLink
        to={`/${NavRoute.DEPLOY}/${DeployRoute.FILES}`}
        icon="file"
        keepSearchParams
      >
        Files
      </SidebarLink>
      <SidebarLink
        to={`/${NavRoute.DEPLOY}/${DeployRoute.DEPLOYMENTS}`}
        icon="deployment"
        keepSearchParams
      >
        Deployments
      </SidebarLink>
      <SidebarLink
        to={`/${NavRoute.DEPLOY}/${DeployRoute.EVENTS}`}
        icon="event"
      >
        Events
      </SidebarLink>

      <Button
        to={`/applications/${applicationID}/${NavRoute.DEPLOY}/${DeployRoute.RAPID_DEPLOY}`}
        variant="primary"
        size="small"
      >
        Quick deploy
      </Button>
    </Sidebar>
  );
}
