import c from 'classnames';

import { usePageLayout } from '../context/page_layout';
import { Container, ContainerProps } from './Container';

export type ContentProps = Omit<ContainerProps, 'size'>;

/**
 * PageLayout container that inherits the layout size.
 */
export function Content({ className, ...props }: ContentProps) {
  const { size } = usePageLayout();
  return (
    <Container {...props} className={c(className, 'content')} size={size} />
  );
}
