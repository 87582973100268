import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { Userpilot } from 'userpilot';

import APIError from 'types/api_error';
import Camera from 'types/camera';
import Gateway from 'types/gateway';
import { OffsetPaginationParams } from 'types/pagination';
import { PaginatedAPIResponse } from 'types/api';
import { TagParams } from 'tags/types/tagged';

import { getOverloadedQueryParams } from 'services/get_overloaded_query_params';
import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';
import { useUser } from 'hooks/useAuth';

export type UseCamerasFilterParams = OffsetPaginationParams &
  TagParams & {
    statuses?: Camera['status'][];

    camera_ids?: Camera['id'][];
    camera_models?: Camera['model'][];
    camera_names?: Camera['name'][];
    gateway_ids?: Gateway['id'][];
    gateway_names?: Gateway['name'][];

    /** ISO date string */
    created_ts_since?: string;
    /** ISO date string */
    created_ts_until?: string;
    /** ISO date string */
    updated_ts_since?: string;
    /** ISO date string */
    updated_ts_until?: string;
  };

export type UseCamerasOptions = UseQueryOptions<
  PaginatedAPIResponse<Camera>,
  APIError
>;

export function useCameras(
  filters?: UseCamerasFilterParams,
  options?: UseCamerasOptions
): UseQueryResult<PaginatedAPIResponse<Camera>, APIError>;

export function useCameras(
  queryKey?: QueryKey,
  filters?: UseCamerasFilterParams,
  options?: UseCamerasOptions
): UseQueryResult<PaginatedAPIResponse<Camera>, APIError>;

export function useCameras(
  queryKeyOrFilters?: QueryKey | UseCamerasFilterParams,
  filtersOrOptions?: UseCamerasFilterParams | UseCamerasOptions,
  queryOptions?: UseCamerasOptions
) {
  const api = useAPI();
  const user = useUser();

  const {
    queryKey = ['cameras'],
    filters: { pagination = 'offset', page = 1, limit = 50, ...filters } = {},
    options,
  } = getOverloadedQueryParams(
    queryKeyOrFilters,
    filtersOrOptions,
    queryOptions
  );

  const params = { ...filters, pagination, limit, page };

  return useAuthenticatedQuery(
    [...queryKey, api.applicationID, params],
    () => api.cameras.list(params),
    {
      ...options,
      onSuccess: (cameras) => {
        if (!user?.id) {
          return;
        }

        options?.onSuccess?.(cameras);

        Userpilot.identify(user.id, {
          has_camera: cameras.data.length > 0,
          application_id: api.applicationID,
        });
      },
    }
  );
}
