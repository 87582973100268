import { createLexer } from 'leac';

export const ConditionLexer = createLexer([
  { name: 'SPACE', regex: /\s+/, discard: true },

  { name: 'LOGICAL_OPERATOR', regex: /and|or|&&|\|\|/ },
  { name: 'COMPARISON_OPERATOR', regex: /[<>]=?|[!=]?=/ },

  // Values
  { name: 'STRING', regex: /"[^"]*"/ },
  { name: 'SINGLE_QUOTED_STRING', regex: /'[^']*'/ },
  { name: 'BOOLEAN', regex: /True|False|true|false/ },

  { name: 'DECIMAL', regex: /\d+\.\d+/ },
  { name: 'INCOMPLETE_DECIMAL', regex: /\d+\./ },

  { name: 'WORD', regex: /\w+/ },

  // Accessor
  { name: '*' },
  { name: '[' },
  { name: ']' },
  { name: '.' },
]);
