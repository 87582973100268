import { getFileNameFromUrl } from 'services/string';

export function validateFilenames(
  originFile?: File | null,
  fileB?: File | null,
  modelFilename?: string
) {
  if (originFile && fileB) {
    return originFile.name !== fileB.name;
  } else if (originFile && modelFilename) {
    return originFile.name !== getFileNameFromUrl(modelFilename);
  }

  return true;
}
