import c from 'classnames';
import { Link } from 'react-router-dom';

import { isCloseToOrganizationUsageLimit } from 'organizations/services/usage_limits';

import { useAPI } from 'hooks/api/useAPI';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useUsageLimits } from 'organizations/hooks/useUsageLimits';

import { FormMessage } from 'components/FormMessage/FormMessage';

const numberFormat = new Intl.NumberFormat('en-us');

export type DeploymentFormUtilizationLimitWarningProps = {
  onLink?: () => void;
};

export function DeploymentFormUtilizationLimitWarning({
  onLink,
}: DeploymentFormUtilizationLimitWarningProps) {
  const { applicationID } = useAPI();
  const { data: organization } = useCurrentOrganization();
  const { fileProcessingMinutes, monthlyActiveLiveStreams } = useUsageLimits();

  const isCloseToMinuteLimit = isCloseToOrganizationUsageLimit(
    fileProcessingMinutes
  );
  const isCloseToLiveStreamLimit = isCloseToOrganizationUsageLimit(
    monthlyActiveLiveStreams
  );

  const minuteInfo = `${numberFormat.format(
    fileProcessingMinutes.usage || 0
  )} of ${numberFormat.format(
    fileProcessingMinutes.userLimit || fileProcessingMinutes.planLimit || 0
  )} minutes`;

  const liveStreamInfo = `${numberFormat.format(
    monthlyActiveLiveStreams.usage || 0
  )} of ${numberFormat.format(
    monthlyActiveLiveStreams.userLimit ||
      monthlyActiveLiveStreams.planLimit ||
      0
  )}`;

  return (
    <>
      {(isCloseToMinuteLimit || isCloseToLiveStreamLimit) && (
        <FormMessage icon="warning" intent="warning">
          {isCloseToLiveStreamLimit && isCloseToMinuteLimit && (
            <>
              Your organization has almost reached the limit of monthly-active
              live streams ({liveStreamInfo})<br />
              and file processing limits ({minuteInfo}) included in your plan.
            </>
          )}
          {/* File processing limits */}
          {!isCloseToLiveStreamLimit && isCloseToMinuteLimit && (
            <>
              Your organization has almost reached the file processing limits
              included in your plan ({minuteInfo}).
            </>
          )}
          {/* Monthly active live streams */}
          {isCloseToLiveStreamLimit && !isCloseToMinuteLimit && (
            <>
              Your organization has almost reached the limit of monthly-active
              live streams included in your plan ({liveStreamInfo}).
            </>
          )}
          <br />
          <strong>The deployment may stop unexpectedly.</strong> Consider{' '}
          <Link
            className={c('link')}
            to={`/applications/${applicationID}/settings/organization/${organization?.id}/plans`}
            onClick={() => onLink?.()}
          >
            upgrading your plan
          </Link>{' '}
          {/* TODO: "or manage your personal limits" */}
          to prevent this.
        </FormMessage>
      )}
    </>
  );
}
