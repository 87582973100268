import Node from 'types/node';

import { getComponentByName } from 'pipelines/services/rete/nodes';
import { DynamicNodeMetadata } from 'pipelines/services/rete/nodes/component';

import { type ConditionInputSuggestion } from 'components/ConditionInput';

function isLeaf(value: DynamicNodeMetadata[string]) {
  return (
    !value ||
    typeof value !== 'object' ||
    'order' in value ||
    'description' in value ||
    'type' in value
  );
}

export function getSuggestionsFromNodeMetadata(
  upstreamNodes: Set<Node>,
  typeFilters?: string[]
) {
  const suggestions: Record<string, ConditionInputSuggestion> = {};

  upstreamNodes.forEach((node) => {
    const NodeComponent = getComponentByName(node.name);

    // Only dynamic nodes have metadata and properties
    if (!NodeComponent?.metadata || !NodeComponent?.properties || !node.id) {
      return;
    }

    const nodeId = node.id;
    const { properties, metadata } = NodeComponent;

    function generateMetadata(obj: DynamicNodeMetadata, path: string[] = []) {
      Object.entries(obj).forEach(([key, value]) => {
        if (key === '[node_id]') {
          traverseSuggestion(value, path, nodeId);
          return;
        }

        // FIXME: Node metadata placeholders should match node property keys
        switch (key) {
          case '[roi_id]':
          case '[roi_label]':
            key = '[roi_labels]';
            break;
          case '[line_id]':
          case '[line_label]':
            key = '[line_labels]';
            break;
        }

        if (key.startsWith('[') && key.endsWith(']')) {
          const plainKey = key.replace(/\[|\]/g, '');
          const nodeValue = node.data[plainKey];

          if (properties[plainKey] && nodeValue) {
            let nodeValues: string[] = [];

            if (typeof nodeValue === 'string') {
              const { stringType } = properties[plainKey];
              nodeValues =
                stringType === 'list' ? nodeValue.split(';') : [nodeValue];
            }

            if (Array.isArray(nodeValue)) {
              nodeValues = nodeValue;
            }

            nodeValues.forEach((nodeValue) => {
              if (nodeValue) {
                traverseSuggestion(value, path, nodeValue);
              }
            });
          }

          traverseSuggestion(value, path, '*');
          traverseSuggestion(value, path, key);
          return;
        }

        traverseSuggestion(value, path, key);
      });
    }

    function traverseSuggestion(
      value: DynamicNodeMetadata[keyof DynamicNodeMetadata],
      path: string[],
      key: string
    ) {
      if (!isLeaf(value)) {
        generateMetadata(value as DynamicNodeMetadata, [...path, key]);
        return;
      }

      addSuggestion(value, [...path, key]);
    }

    function addSuggestion(
      option: DynamicNodeMetadata[keyof DynamicNodeMetadata],
      path: string[]
    ) {
      const value = path.join('.');

      const suggestionOption: ConditionInputSuggestion = {
        ...(typeof option === 'object' && option ? option : {}),
        value,
      };

      const { type } = suggestionOption;

      if (!typeFilters || (type && typeFilters.includes(type))) {
        suggestions[value] = suggestionOption;
      }
    }

    generateMetadata(metadata);
  });

  return suggestions;
}
