import { ComparisonOperator } from 'pipelines/services/condition_parser';

import { Select } from 'components/Select/Select';

export type ComparisonOperatorOption = {
  value: ComparisonOperator;
  label: string;
};

export type ComparisonOperatorSelectProps = {
  id: string;
  value: ComparisonOperatorOption | null;
  onChange: (value: ComparisonOperatorOption | null) => void;
};

export const COMPARISON_OPERATOR_OPTIONS: ComparisonOperatorOption[] = [
  {
    value: '=',
    label: 'equals',
  },
  {
    value: '!=',
    label: 'does not equal',
  },
  {
    value: '<',
    label: 'is less than',
  },
  {
    value: '<=',
    label: 'is less than or equal to',
  },
  {
    value: '>',
    label: 'is greater than',
  },
  {
    value: '>=',
    label: 'is greater than or equal to',
  },
];

const DEFAULT_VALUE = COMPARISON_OPERATOR_OPTIONS.find(
  ({ value }) => value === '='
);

export function ComparisonOperatorSelect({
  id,
  value,
  onChange,
}: ComparisonOperatorSelectProps) {
  return (
    <Select
      aria-label="Comparison operator"
      id={id}
      options={COMPARISON_OPERATOR_OPTIONS}
      value={value}
      onChange={onChange}
      defaultValue={DEFAULT_VALUE}
      size="small"
    />
  );
}
