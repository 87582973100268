import React from 'react';
import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';

import Gateway from 'types/gateway';
import { StreamType } from 'types/stream';

import { VideoSource, useVideoSources } from 'hooks/api/useVideoSources';

import * as PageLayout from 'components/PageLayout';

import { RAPID_DEPLOY_VIDEO_SOURCES_PAGE_SIZE } from './components/VideoSourcesList';
import { RapidDeployContext } from './hooks/useRapidDeployState';
import { RapidDeployStepper } from './Stepper';
import styles from './RapidDeploy.module.scss';

const c = classNames.bind(styles);

type ClearReducerParams = {
  type: 'clear';
  data?: never;
};

export type RapidDeployVideoSourcesReducerParams =
  | ClearReducerParams
  | {
      type: 'add' | 'remove' | 'toggle';
      data: VideoSource;
    };

function videoSourcesReducer(
  state: VideoSource[],
  { type, data }: RapidDeployVideoSourcesReducerParams
): VideoSource[] {
  switch (type) {
    case 'toggle':
      if (state.some((source) => source.id === data.id)) {
        return state.filter((source) => source.id !== data.id);
      }
      return [...state, data];
    case 'add':
      if (!state.some((source) => source.id === data.id)) {
        return [...state, data];
      }
      return state;
    case 'remove':
      return state.filter((source) => source.id !== data.id);
    case 'clear':
      return [];
    default:
      return state;
  }
}

export function RapidDeploy() {
  const [gateway, setGateway] = React.useState<Gateway>();
  const [videoSources, dispatchVideoSources] = React.useReducer(
    videoSourcesReducer,
    []
  );

  // Preload video sources with default filters
  useVideoSources(
    {
      fetch_type: 'all',
      gateway_ids: gateway ? [gateway.id] : undefined,
      stream_types: [StreamType.RTSP, StreamType.WEBRTC],
      stream_sources: ['camera_stream', 'uri_stream'],
      conn_types: ['local'],
      limit: RAPID_DEPLOY_VIDEO_SOURCES_PAGE_SIZE + 20,
    },
    { enabled: Boolean(gateway) }
  );

  const updateGateway = React.useCallback((gateway: Gateway) => {
    setGateway(gateway);
    dispatchVideoSources({ type: 'clear' });
  }, []);

  return (
    <div className={c('rapid-deploy')}>
      <RapidDeployContext.Provider
        value={{
          gateway,
          setGateway: updateGateway,
          videoSources,
          dispatchVideoSources,
        }}
      >
        <PageLayout.Root size="medium">
          <PageLayout.Header>
            <div className={c('header')}>
              <PageLayout.Title>Deploy</PageLayout.Title>
              <RapidDeployStepper />
            </div>
          </PageLayout.Header>

          <Outlet />
        </PageLayout.Root>
      </RapidDeployContext.Provider>
    </div>
  );
}
