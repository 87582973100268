import React from 'react';
import classNames from 'classnames/bind';
import { Node as ReteNode, NodeEditor } from 'rete';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import ReteInput from 'pipelines/services/rete/controls/input';
import { getPropertyValue } from 'types/node';

import { hyphenate } from 'services/string';
import { usePipelineDetailState } from 'pipelines/context/pipeline_detail_view';

import { Drawer } from 'components/Drawer/Drawer';

import { NodePropertiesEditorActions } from './components/Actions';
import { NodePropertiesEditorHeader } from './components/Header';
import { NodePropertiesEditorInner } from './Inner';

import styles from './NodePropertiesEditor.module.scss';

const c = classNames.bind(styles);

export type NodePropertiesEditorProps = {
  editor: NodeEditor | undefined;
  node: ReteNode | undefined;
  onDeleteNode: (node: ReteNode) => void;
  onClose: () => void;
};

function NodePropertiesEditor({
  editor,
  node: reteNode,
  onClose,
  onDeleteNode,
}: NodePropertiesEditorProps) {
  const { transientPipeline } = usePipelineDetailState();

  const formMethods = useForm();

  const nodeDescriptionsRef = React.useRef<Record<string, string>>({});

  function updateNodeDescriptions() {
    if (!reteNode) {
      return;
    }

    const nodeId = String(reteNode.id);
    const description = nodeDescriptionsRef.current[nodeId];

    if (!description && nodeId in transientPipeline.current.nodeDescriptions) {
      delete transientPipeline.current.nodeDescriptions[nodeId];
      return;
    }

    if (!description) {
      return;
    }

    transientPipeline.current.nodeDescriptions = {
      ...transientPipeline.current.nodeDescriptions,
      [nodeId]: description,
    };
  }

  /**
   * Stores all controls that have been checked as deploy-time parameters with their associated nodeID
   */
  function updateDeployTimeEditableNodes(
    nodeID: string,
    controls: Pick<ReteInput, 'key' | 'deploymentParam'>[]
  ) {
    if (!transientPipeline.current) {
      return;
    }

    const newDeployNodes =
      transientPipeline.current.deployTimeEditableNodes || [];

    const hasNode = newDeployNodes.some((node) => node.nodeID === nodeID);

    if (hasNode) {
      transientPipeline.current.deployTimeEditableNodes = newDeployNodes.map(
        (node) => {
          if (node.nodeID === nodeID) {
            return { nodeID, controls };
          }

          return node;
        }
      );
    } else {
      newDeployNodes.push({ nodeID, controls });
      transientPipeline.current.deployTimeEditableNodes = newDeployNodes;
    }
  }

  function handleSave(
    values: FieldValues,
    event?: React.BaseSyntheticEvent<object, any, any>
  ) {
    if (!editor || !reteNode) {
      event?.preventDefault();
      return;
    }

    const controls = [...reteNode.controls.values()];

    const userSpecifiedControls = (controls as ReteInput[]).map(
      ({ key, deploymentParam }) => ({ key, deploymentParam })
    );
    updateDeployTimeEditableNodes(String(reteNode.id), userSpecifiedControls);
    updateNodeDescriptions();

    controls.forEach((control) => {
      const value = getPropertyValue(values, control.key);
      control.putData(control.key, value);
    });

    reteNode.update();
    onClose();

    editor.trigger('nodeupdated', reteNode);
  }

  return (
    <Drawer
      title={`Edit ${reteNode?.name} node`}
      classNames={{ content: c('drawer', hyphenate(reteNode?.name ?? '')) }}
      onClose={onClose}
      closeOnClickOutside={false}
      closeOnEscape={false}
      open={Boolean(reteNode)}
      header={
        <NodePropertiesEditorHeader
          node={reteNode}
          nodeDescriptionsRef={nodeDescriptionsRef}
        />
      }
      footer={
        <NodePropertiesEditorActions
          node={reteNode}
          onCancel={onClose}
          onDelete={onDeleteNode}
          hasError={false}
        />
      }
    >
      <form
        id="node-properties-form"
        onSubmit={formMethods.handleSubmit(handleSave)}
      >
        <FormProvider {...formMethods}>
          {reteNode && (
            <NodePropertiesEditorInner editor={editor} reteNode={reteNode} />
          )}
        </FormProvider>
      </form>
    </Drawer>
  );
}

const Memoized = React.memo(NodePropertiesEditor);

export { Memoized as NodePropertiesEditor };
