import classNames from 'classnames/bind';

import styles from './ChartLoader.module.scss';

const c = classNames.bind(styles);

/**
 * Skeleton loader replicating Propel components loader
 */
export function ChartLoader() {
  return <div className={c('chart-loader')} />;
}
