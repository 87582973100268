import { FilterInput, TimeRangeInput } from '@propeldata/ui-kit';
import { gql } from 'graphql-request';

export type CounterQueryVariables = {
  metric: { name: string };
  timeRange: TimeRangeInput;
  filters?: FilterInput[];
  timeZone: string;
};

export type CounterResponse = {
  counter: { value: string | null };
};

/**
 * Fetch a metric in counter format.
 */
export const COUNTER = gql`
  query Counter($input: CounterInput!) {
    counter(input: $input) {
      value
    }
  }
`;
