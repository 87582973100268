import React from 'react';
import classNames from 'classnames/bind';

import Dashboard from 'types/dashboard';
import {
  ChartType,
  DashboardWidget,
  DataWidget,
  FilesWidget,
  IframeWidget,
  MultiStreamWidget,
  StreamWidget,
  WidgetType,
} from 'types/dashboard_widget';

import { usePropelMetrics } from 'dashboards/hooks/usePropelMetrics';

import { Loader } from 'components/Loader/Loader';

import { DataForm } from '../Form/DataForm';
import { FilesForm } from '../Form/FilesForm';
import { IframeForm } from '../Form/IframeForm';
import { MultiStreamForm } from '../Form/MultiStreamForm';
import { StreamForm } from '../Form/StreamForm';

import styles from './WidgetModal.module.scss';

const c = classNames.bind(styles);

type FormProps = {
  dashboard: Dashboard;
  onIsDirtyChange: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: React.Dispatch<React.SetStateAction<boolean>>;
};

type AddFormProps = FormProps & {
  type: WidgetType;
  widget?: never;
};

type EditFormProps = FormProps & {
  widget: DashboardWidget;
  type?: never;
};

export type WidgetFormProps = AddFormProps | EditFormProps;

export function WidgetForm({ type, widget, ...formProps }: WidgetFormProps) {
  const widgetType = type ?? widget?.type;

  const { data: metrics } = usePropelMetrics();

  return (
    <div className={c('form')}>
      {widgetType === 'data' && (
        <>
          {!metrics ? (
            <Loader text="Loading metrics..." />
          ) : (
            <DataForm
              {...formProps}
              widget={widget as DataWidget<ChartType>}
              metrics={metrics}
            />
          )}
        </>
      )}
      {widgetType === 'iframe' && (
        <IframeForm {...formProps} widget={widget as IframeWidget} />
      )}
      {widgetType === 'stream' && (
        <StreamForm {...formProps} widget={widget as StreamWidget} />
      )}
      {widgetType === 'multistream' && (
        <MultiStreamForm {...formProps} widget={widget as MultiStreamWidget} />
      )}
      {widgetType === 'files' && (
        <FilesForm {...formProps} widget={widget as FilesWidget} />
      )}
    </div>
  );
}
