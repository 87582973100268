import React from 'react';
import RGL from 'react-grid-layout';
import { useForm } from 'react-hook-form';

import Dashboard from 'types/dashboard';
import { IframeWidget } from 'types/dashboard_widget';

import { generateId } from 'services/string';
import { useUpdateDashboard } from 'dashboards/hooks/useUpdateDashboard';

import { Field } from 'components/Field/Field';
import { Input } from 'components/Input/Input';

import { useWidgetModal } from '../WidgetModal';
import { WidgetFieldValues, WidgetFormProps } from './DataForm';

type IframeWidgetFieldValues = WidgetFieldValues & { url: string };

type IframeFormProps = WidgetFormProps<IframeWidget>;

export function IframeForm({
  widget,
  dashboard,
  onIsDirtyChange,
  onUpdate,
}: IframeFormProps) {
  const { onClose } = useWidgetModal();

  const { mutate, isLoading: isUpdating } = useUpdateDashboard({
    onSuccess(data) {
      resetForm(data);
      onClose();
    },
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<IframeWidgetFieldValues>({
    defaultValues: { name: widget?.name, url: widget?.url },
  });

  React.useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  React.useEffect(() => {
    onUpdate(isUpdating);
  }, [isUpdating, onUpdate]);

  function resetForm(updatedDashboard: Dashboard) {
    if (!widget?.id) {
      return;
    }

    const updatedWidget = updatedDashboard.getWidget(widget.id) as IframeWidget;

    if (!updatedWidget) {
      return;
    }

    reset({ name: updatedWidget.name, url: updatedWidget.url });
  }

  function onSubmit({ name, url }: IframeWidgetFieldValues) {
    if (!dashboard) {
      return;
    }

    const id = widget?.id || generateId();

    const layout: Omit<RGL.Layout, 'i'> =
      widget?.layout || dashboard.getNewWidgetLayout(5, 5);

    mutate(dashboard.upsertWidget(new IframeWidget(id, name, url, layout)));
  }

  return (
    <form id="iframe-widget-form" onSubmit={handleSubmit(onSubmit)}>
      <Field label="Name" error={errors.name}>
        <Input
          {...register('name', {
            required: 'Please choose a name for this widget.',
          })}
          type="text"
          autoComplete="off"
          spellCheck="false"
        />
      </Field>
      <Field label="URL" error={errors.url}>
        <Input
          {...register('url', {
            required: 'Please specify a url for this widget.',
          })}
        />
      </Field>
    </form>
  );
}
