import { UseMutationOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';

import { useAPI } from 'hooks/api/useAPI';
import { useAppQueryClient } from 'hooks/useAppQueryClient';
import { useDangerousMutation } from 'hooks/api/useDangerousMutation';

export type UseDeleteCameraContext = {
  previousCameras: Camera[] | undefined;
} | void;

export function useDeleteCameras({
  onMutate,
  onError,
  onSettled,
  ...options
}: UseMutationOptions<
  void[],
  APIError,
  Camera['id'][],
  UseDeleteCameraContext
> = {}) {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useDangerousMutation(
    'Are you sure you want to delete these cameras?',
    (cameraIDs) => {
      if (!cameraIDs.length || !cameraIDs) {
        return Promise.resolve([]);
      }
      return Promise.all(cameraIDs.map(api.cameras.delete));
    },
    {
      onMutate: async (cameraIDs) => {
        await queryClient.cancelQueries(['cameras']);
        await queryClient.cancelQueries(['linked-cameras']);
        const previousCameras = queryClient.getQueryData<Camera[]>(['cameras']);

        if (cameraIDs.length && previousCameras) {
          const updatedCameras = previousCameras.filter(async (camera) => {
            if (camera.gateway_id) {
              const linkedCameras = queryClient.getQueryData<Camera[]>([
                'linked-cameras',
                camera.gateway_id,
              ]);

              if (linkedCameras) {
                const updatedLinkedCameras = linkedCameras.filter(
                  (linkedCameras) => linkedCameras.id !== camera.id
                );
                queryClient.setQueryData(
                  ['linked-cameras', camera.gateway_id],
                  updatedLinkedCameras
                );
              }
            }

            return cameraIDs.find((id) => id !== camera.id);
          });

          queryClient.setQueryData(['cameras'], updatedCameras);
        }

        if (onMutate) {
          onMutate(cameraIDs);
        }

        return { previousCameras };
      },
      onError: (_err, _deletedCameras, context) => {
        if (context?.previousCameras) {
          queryClient.setQueryData(['cameras'], context.previousCameras);
        }
      },
      onSettled: (...args) => {
        queryClient.invalidateQueries(['cameras']);
        queryClient.invalidateQueries(['linked-cameras']);
        onSettled?.(...args);
      },
      ...options,
    }
  );
}
