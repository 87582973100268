import classnames from 'classnames/bind';

import { IconButton } from 'components/IconButton/IconButton';
import { Icon } from 'components/Icon/Icon';

import { getFileNameFromUrl, truncateMiddle } from 'services/string';

import { Card } from 'components/Card/Card';
import { Heading } from 'components/Heading/Heading';
import { Loader, PercentageLoader } from 'components/Loader/Loader';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';

import styles from './FileDisplay.module.scss';

const c = classnames.bind(styles);

type CommonProps = {
  onDelete?: () => void;
  isError?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  progress?: number | null;
};

type URLProps = CommonProps & {
  url: string;
  file?: never;
};

type FileObjProps = CommonProps & {
  url?: never;
  file: File;
};

export type FileProps = URLProps | FileObjProps;

export function FileDisplay(props: URLProps): JSX.Element;
export function FileDisplay(props: FileObjProps): JSX.Element;

export function FileDisplay({
  url,
  file,
  isLoading,
  isSuccess,
  isError,
  progress,
  onDelete,
}: FileProps) {
  let displayName = '';

  if (url) {
    displayName = getFileNameFromUrl(url);
  } else if (file) {
    displayName = file.name;
  }

  displayName = truncateMiddle(displayName, 36);

  return (
    <Card className={c('file', { error: isError })} size="xsmall">
      <article className={c('wrap')}>
        {(isError || isSuccess) && (
          <span className={c('file-status-wrap')}>
            <StatusIndicator
              className={c('file-status')}
              status={isError ? 'error' : 'success'}
              size="small"
            />
          </span>
        )}

        {Boolean(isLoading && Number.isFinite(progress)) && (
          <span className={c('file-status-wrap')}>
            <PercentageLoader progress={progress} size="xsmall" />
          </span>
        )}

        {isLoading && !Number.isFinite(progress) && (
          <span className={c('file-status-wrap')}>
            <Loader size="xsmall" text="Loading..." />
          </span>
        )}

        <p className={c('content')}>
          <Icon name="file" size="small" />

          {url ? (
            <a
              href={url}
              aria-label={`Download file "${displayName}"`}
              title={displayName}
              download
            >
              <Heading level="4" asChild>
                <strong>{displayName}</strong>
              </Heading>
            </a>
          ) : (
            <Heading level="4" asChild>
              <strong>{displayName}</strong>
            </Heading>
          )}
        </p>

        <footer className={c('actions')}>
          {onDelete && (
            <IconButton
              onClick={onDelete}
              className={c('delete')}
              icon="delete"
              label="Delete this file"
              variant="ghost"
              intent="danger"
              size="small"
            />
          )}

          {url && (
            <IconButton
              href={url}
              className={c('download')}
              icon="download"
              label="Download this file"
              variant="ghost"
              aria-hidden="true"
              size="small"
              download
            />
          )}
        </footer>
      </article>
    </Card>
  );
}

export default FileDisplay;
