import { NodeEditor } from 'rete';

import ReteInput, {
  CommonReteInputParams,
} from 'pipelines/services/rete/controls/input';

import {
  VideoSourceROI,
  VideoSourceROIValue,
} from 'pipelines/components/VideoSourceROI/VideoSourceROI';

export type ReteMultilineInputParams = CommonReteInputParams & {
  roiLabel?: string;
};

export default class ReteMultilineInput extends ReteInput {
  constructor(
    editor: NodeEditor,
    key: string,
    { roiLabel, ...params }: ReteMultilineInputParams
  ) {
    super(editor, key, { type: 'multiline', ...params });
    this.props.type = 'multiline';
    this.props.roiLabel = roiLabel;
  }

  get component(): any {
    return VideoSourceROI;
  }
}

export function serializeMultilineROI(
  key: string,
  keyref: string | undefined,
  value: VideoSourceROIValue,
  setValue: (key: string, value: any) => void
) {
  if (!value) {
    return;
  }

  if (!value.coordinates || value.coordinates.length === 0) {
    setValue(key, null);

    if (keyref) {
      setValue(keyref, null);
    }

    return;
  }

  setValue(key, value.coordinates);

  // Don't save labels without coordinates
  if (keyref) {
    setValue(keyref, value.labels);
  }

  return value.coordinates;
}
