import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';

import APIError from 'types/api_error';
import Camera from 'types/camera';

import { useAPI } from 'hooks/api/useAPI';
import { useAppQueryClient } from 'hooks/useAppQueryClient';

export function useUpdateCamera({
  onSettled,
  onMutate,
  ...options
}: UseMutationOptions<Camera, APIError, Camera> = {}): UseMutationResult<
  Camera,
  APIError,
  Camera
> {
  const api = useAPI();
  const queryClient = useAppQueryClient();

  return useMutation(api.cameras.update, {
    ...options,
    onMutate: (camera) => {
      const previousCameras =
        queryClient.getQueryData<Camera[]>(['cameras']) || [];

      if (previousCameras) {
        queryClient.setQueryData(
          ['cameras'],
          previousCameras.map((c) => {
            if (c.id === camera.id) {
              camera.updated_at = new Date().toISOString();
              return camera;
            }
            return c;
          })
        );
      }

      return { previousCameras };
    },
    onSettled: (...args) => {
      if (onSettled) {
        onSettled(...args);
      }

      queryClient.invalidateQueries(['cameras']);

      const [camera] = args;
      if (camera) {
        queryClient.invalidateQueries(['camera', camera.id]);
      }
    },
  });
}
