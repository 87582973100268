import React from 'react';
import classnames from 'classnames/bind';
import { DateTime } from 'luxon';
import { Skeleton } from '@mantine/core';

import { useEvents, UseEventsParams } from 'events/hooks/useEvents';

import { Code } from 'components/Code/Code';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { Pagination } from 'components/Pagination/Pagination';
import { SeveritySelect } from 'components/SeveritySelect/SeveritySelect';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';

import styles from './List.module.scss';

const c = classnames.bind(styles);

type EventsListProps = {
  params: UseEventsParams;
};

export function EventsList({ params }: EventsListProps) {
  const [queryParams, setQueryParams] = React.useState<
    UseEventsParams & { page: number }
  >({
    page: 1,
  });

  const {
    data: events,
    isFetching,
    isLoading,
    isPreviousData,
  } = useEvents({
    params: { ...params, ...queryParams },
    keepPreviousData: true,
  });

  const hasEvents = events && events.data.length > 0;

  return (
    <div className={c('wrap')}>
      <div className={c('header', 'border')}>
        <SeveritySelect
          size="small"
          onChange={(option) =>
            option
              ? setQueryParams((previousState) => ({
                  ...previousState,
                  severities: [option.value],
                  page: 1,
                }))
              : setQueryParams((previousState) => ({
                  ...previousState,
                  severities: undefined,
                  page: 1,
                }))
          }
          isClearable
        />
      </div>

      <ul className={c('list')}>
        {isLoading || (isFetching && isPreviousData) ? (
          <SkeletonList
            component={
              <li className={c('item')}>
                <Skeleton height="3rem" width="5rem" />
                <Skeleton height="1rem" width="1rem" radius="1rem" />
                <Skeleton height="1rem" width="80%" />
                <Skeleton height="1.5rem" width="10rem" />
              </li>
            }
            min={hasEvents ? events.data.length : 5}
            max={hasEvents ? events.data.length : 5}
          />
        ) : (
          <>
            {!hasEvents && (
              <EmptyView>
                {queryParams?.severities && queryParams.severities.length
                  ? `No events with ${queryParams.severities[0]} severity.`
                  : 'No events recorded yet.'}
              </EmptyView>
            )}

            {hasEvents && (
              <>
                {events.data.map((event) => {
                  return (
                    <li key={event.id} className={c('item', 'border')}>
                      <div className={c('timestamp')}>
                        {DateTime.fromISO(event.event_ts).toLocaleString(
                          DateTime.DATE_MED
                        )}
                        <br />
                        {DateTime.fromISO(event.event_ts).toLocaleString(
                          DateTime.TIME_SIMPLE
                        )}
                      </div>
                      <div className={c('center')}>
                        <StatusIndicator status={event.severity} size="small" />
                      </div>
                      <Text className={c('center')} inline>
                        {event.payload}
                      </Text>
                      <div>
                        <Code size="small">{event.event_type}</Code>
                      </div>
                    </li>
                  );
                })}
              </>
            )}
          </>
        )}

        {hasEvents && (
          <Pagination
            className={c('pagination')}
            total={events.total_pages}
            value={queryParams.page}
            onChange={(page) =>
              setQueryParams((previousState) => ({ ...previousState, page }))
            }
          />
        )}
      </ul>
    </div>
  );
}
