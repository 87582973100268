import classNames from 'classnames/bind';
import { Outlet, useNavigate } from 'react-router-dom';

import { DesignRoute, NavRoute } from 'application/types/routes';

import { useCreateModel } from 'hooks/api/useModel';

import * as PageLayout from 'components/PageLayout';

import { ModelFormProvider } from 'analytics-library/components/MultiStepForm';
import { CreateModelViewStepper } from './CreateModelViewStepper';
import styles from './CreateModelView.module.scss';

const c = classNames.bind(styles);

export function CreateModelView() {
  const navigate = useNavigate();

  const {
    mutateAsync,
    isLoading: isCreating,
    error,
  } = useCreateModel({
    onSuccess() {
      navigate(`../${DesignRoute.MODELS}`);
    },
  });

  return (
    <ModelFormProvider
      baseUrl={`/${NavRoute.DESIGN}/${DesignRoute.MODELS}/new`}
      onSave={mutateAsync}
      isSaving={isCreating}
      error={error}
    >
      <PageLayout.Root size="small">
        <PageLayout.Header className={c('header')}>
          <div>
            <PageLayout.Title>Create new AI model</PageLayout.Title>
            <CreateModelViewStepper />
          </div>
        </PageLayout.Header>

        <PageLayout.Content>
          <Outlet />
        </PageLayout.Content>
      </PageLayout.Root>
    </ModelFormProvider>
  );
}
