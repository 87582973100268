import Rete, { Node } from 'rete';

import TextInput from 'pipelines/services/rete/controls/text_input';
import Component from 'pipelines/services/rete/nodes/component';

import { MultiplexedVideo, RawVideo } from 'pipelines/services/rete/sockets';
import { ResolutionInput } from 'pipelines/services/rete/controls/configured_inputs';

import { RESOLUTION_PRESETS } from 'components/ResolutionInput/ResolutionInput';

export default class TileVideoInGrid extends Component {
  static key = 'Tile Video in Grid';
  static icon = 'grid' as const;
  static exportType = 'grid';
  static category = 'transformations' as const;
  static description =
    'Arrange the constituent streams of a multiplexed stream into a grid';

  static input = {
    type: 'multiplexed',
  };
  static output = {
    type: 'raw',
  };

  constructor() {
    super(TileVideoInGrid.key);
  }

  async builder(node: Node) {
    node
      .addControl(
        new TextInput(this.editor!, 'rows', {
          label: 'Rows',
          initial: 1,
          type: 'number',
        })
      )
      .addControl(
        new TextInput(this.editor!, 'columns', {
          label: 'Columns',
          initial: 2,
          type: 'number',
        })
      )
      .addControl(
        ResolutionInput(this.editor!, {
          initial: '1280x720',
          presets: RESOLUTION_PRESETS,
        })
      )
      .addInput(new Rete.Input('input', 'Input', MultiplexedVideo, false))
      .addOutput(new Rete.Output('output', 'Output', RawVideo));
  }
}
