import React from 'react';
import classnames from 'classnames/bind';

import { FilterInput } from 'graphql/types/filter';

import { IconButton } from 'components/IconButton/IconButton';

import { ColumnInput } from './ColumnInput';
import { OperatorInput } from './OperatorInput';
import { SuggestionInput } from './SuggestionInput/SuggestionInput';
import { useMetricFiltersState } from './context';
import styles from './MetricFiltersInput.module.scss';

const c = classnames.bind(styles);

export function MetricFilter(filter: FilterInput) {
  const { removeFilter } = useMetricFiltersState();

  const { id, column, operator } = filter;

  function handleCancelClick() {
    removeFilter(id);
  }

  return (
    <div className={c('group-item')}>
      <div className={c('filter')}>
        <ColumnInput id={id} column={column} />
        <OperatorInput id={id} column={column} operator={operator} />
        <SuggestionInput {...filter} />

        <IconButton
          icon="cancel"
          label="Remove filter"
          onClick={handleCancelClick}
        />
      </div>
    </div>
  );
}
