import React from 'react';
import classnames from 'classnames/bind';
import { CellProps } from 'react-table';

import OrganizationInvitation from 'types/invitation';

import { useDeleteOrganizationInvitation } from 'settings/hooks/useDeleteOrganizationInvitation';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';

import styles from '../Table.module.scss';

const c = classnames.bind(styles);

export function OrganizationInvitationCell({
  row: { original },
}: CellProps<OrganizationInvitation>) {
  const [requestDeleteOrganizationInvitation] =
    useDeleteOrganizationInvitation();

  function deleteOrganizationInvitation() {
    requestDeleteOrganizationInvitation(original.id);
  }

  return (
    <div className={c('member')}>
      <div>
        <Heading level="4">{original.email}</Heading>
      </div>

      <ContextMenu
        trigger={
          <IconButton
            icon="more-vertical"
            label="Reveal more actions"
            variant="ghost"
            size="small"
          />
        }
      >
        <ContextMenuAction
          onClick={deleteOrganizationInvitation}
          icon="delete"
          intent="danger"
        >
          Revoke invitation
        </ContextMenuAction>
      </ContextMenu>
    </div>
  );
}
