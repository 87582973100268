import React from 'react';
import { Node as ReteNode } from 'rete';
import { useFormContext } from 'react-hook-form';

import Node from 'types/node';

import Input from 'pipelines/services/rete/controls/input';
import { objectHas } from 'services/object';
import { serializeMultilineROI } from 'pipelines/services/rete/controls/multisegment_line_input';
import { serializeROI } from 'pipelines/services/rete/controls/roi_input';
import { VideoSourceROIValue } from 'pipelines/components/VideoSourceROI/VideoSourceROI';
import { VideoSourceSelection } from 'pipelines/services/rete/controls/video_source_selector';

import { useDeploymentForm } from '../context/deployment_form_context';

export type DeploymentEditableControlProps = {
  onChange: (event: any) => void;
  id: string;
  control: Input;
  node: Node;
  disabled: boolean;
  reteNode: ReteNode;
  property: string;
  value: any;
  keyrefValue: any;
};

export function DeploymentEditableControl({
  id,
  control,
  property,
  node,
  disabled,
  value,
  keyrefValue,
  onChange,
}: DeploymentEditableControlProps) {
  const { setSources } = useDeploymentForm();
  const { setValue } = useFormContext();

  const [internalValue, setInternalValue] = React.useState<
    VideoSourceROIValue | VideoSourceSelection | string | undefined
  >(() => {
    if (
      control.type === 'line' ||
      control.type === 'multiline' ||
      control.type === 'polygon' ||
      property === 'source_id'
    ) {
      return undefined;
    }

    return value;
  });

  React.useEffect(() => {
    setInternalValue(value ?? node.data[property]);
  }, [value, node.data, property]);

  React.useEffect(() => {
    if (property === 'source_id') {
      if (value && keyrefValue) {
        setSources((sources) => ({
          ...sources,
          [node.id!]: {
            source_id: value,
            source_type: keyrefValue,
          },
        }));
        setInternalValue({
          source_id: value,
          source_type: keyrefValue,
        });
        return;
      }

      setSources(({ [node.id!]: removedSource, ...sources }) => sources);
      setInternalValue(undefined);
    }

    if (control.type === 'line' || control.type === 'polygon') {
      const labels = control.keyref ? keyrefValue : undefined;

      setInternalValue({
        labels,
        coordinates: value,
      });
      return;
    }

    if (control.type === 'multiline') {
      let labels = control.keyref ? keyrefValue : undefined;
      let coordinates = value;

      if (Array.isArray(labels)) {
        labels = labels.join(';');
      }
      if (Array.isArray(coordinates)) {
        coordinates = coordinates.join(';');
      }

      setInternalValue({
        labels,
        coordinates,
      });
      return;
    }

    setInternalValue(value);
  }, [
    property,
    setSources,
    node.id,
    control.type,
    control.keyref,
    value,
    keyrefValue,
  ]);

  const handleChange = React.useCallback(
    (value: any) => {
      if (
        property === 'source_id' &&
        (!value || objectHas(value, 'source_id'))
      ) {
        setValue(`${node.id}.source_id`, value?.source_id || null);
        setValue(`${node.id}.source_type`, value?.source_type || null);
        onChange(value?.source_id || null);
        return;
      }

      switch (control.type) {
        case 'line':
        case 'polygon': {
          const roiCoordinates = serializeROI(
            `${node.id}.${control.key}`,
            `${node.id}.${control.keyref}`,
            value,
            setValue
          );
          onChange(roiCoordinates);
          break;
        }
        case 'multiline': {
          const roiCoordinates = serializeMultilineROI(
            `${node.id}.${control.key}`,
            `${node.id}.${control.keyref}`,
            value,
            setValue
          );
          onChange(roiCoordinates);
          break;
        }
        default: {
          if (value === '') {
            onChange(null);
            break;
          }

          onChange(value);
        }
      }
    },
    [
      onChange,
      setValue,
      property,
      node.id,
      control.key,
      control.type,
      control.keyref,
    ]
  );

  return (
    <control.component
      {...control.props}
      id={id}
      value={internalValue}
      disabled={disabled}
      onChange={handleChange}
      keyrefValue={keyrefValue}
    />
  );
}
