import { NodeEditor } from 'rete';

import { InnerProps } from 'pipelines/components/NodePropertiesEditor/components/Control';
import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import { Textarea, TextareaProps } from 'components/Textarea/Textarea';

type ReteTextareaProps = Omit<TextareaProps, 'onChange'> & {
  onChange: InnerProps['onChange'];
};

export default class ReteTextarea extends ReteInput<
  ReteInputProps & ReteTextareaProps
> {
  constructor(editor: NodeEditor, key: string, params: ReteInputParams) {
    super(editor, key, { ...params, type: 'string', inputType: 'textarea' });
  }

  get component() {
    return ReteTextareaComponent;
  }
}

export function ReteTextareaComponent({
  onChange,
  ...props
}: ReteTextareaProps) {
  return (
    <Textarea
      {...props}
      onChange={({ target: { value } }) => onChange(value)}
    />
  );
}
