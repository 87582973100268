import React from 'react';
import classNames from 'classnames/bind';

import type {
  Condition,
  LogicalOperator,
} from 'pipelines/services/condition_parser';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Icon } from 'components/Icon/Icon';

import { ComparisonInput } from './ComparisonInput';
import { OperatorSwitch } from './OperatorSwitch';
import { useConditionInputState } from './ConditionInput';
import styles from './ConditionInput.module.scss';

const c = classNames.bind(styles);

export type GroupProps = {
  path: number[];
  condition?: Condition;
};

export function Group({ path, condition }: GroupProps) {
  const {
    condition: topLevelCondition,
    addCondition,
    addGroup,
    removeCondition,
    updateLogicalOperator,
  } = useConditionInputState();

  const currentCondition = condition ?? topLevelCondition;

  const logicalOperator: LogicalOperator = currentCondition?.or ? 'or' : 'and';

  const chainedConditions = currentCondition?.and ?? currentCondition?.or;

  function handleLogicalOperatorChange(newLogicalOperator: LogicalOperator) {
    updateLogicalOperator(path, newLogicalOperator);
  }

  return (
    <div className={c('group')}>
      {currentCondition && (
        <>
          <OperatorSwitch
            path={path}
            defaultValue={logicalOperator}
            onChange={handleLogicalOperatorChange}
          />

          <ComparisonInput
            path={path}
            condition={currentCondition}
            onRemove={() => {
              removeCondition(path);
            }}
            key={`${path.join('.')}`}
          />
        </>
      )}

      {chainedConditions?.map((chainedCondition, index) => {
        const hasOwnChainedConditions = Boolean(
          chainedCondition.and || chainedCondition.or
        );

        if (hasOwnChainedConditions) {
          return (
            <Group
              path={[...path, index]}
              condition={chainedCondition}
              key={[...path, index].join('.')}
            />
          );
        }

        return (
          <ComparisonInput
            path={[...path, index]}
            condition={chainedCondition}
            onRemove={() => {
              removeCondition([...path, index], index);
            }}
            key={`${[...path, index].join('.')}`}
          />
        );
      })}

      <div className={c('group-controls')}>
        <ButtonGroup>
          <Button
            size="xsmall"
            variant="tertiary"
            onClick={() => addCondition(path, logicalOperator)}
          >
            <Icon size="xsmall" name="plus" />
            <span>Add filter</span>
          </Button>
          {path.length < 1 && (
            <Button
              size="xsmall"
              variant="tertiary"
              onClick={() => addGroup(path, logicalOperator)}
              disabled={!currentCondition}
            >
              <Icon size="xsmall" name="link" />
              <span>Add group</span>
            </Button>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
}
