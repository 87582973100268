import React from 'react';
import { useNavigate } from 'react-router-dom';

import Gateway from 'types/gateway';
import Stream, { StreamType, StreamState, STREAM_STATES } from 'types/stream';

import { useInputStreams } from 'streams/hooks/useInputStreams';
import { useMqttStreamsState } from 'streams/hooks/useMqttStreamsState';
import { useStreams, UseStreamsFilterParams } from 'streams/hooks/useStreams';
import { useTagsFilterParams } from 'tags/hooks/useTagsFilterParams';

import * as PageLayout from 'components/PageLayout';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { StateSelect } from 'components/StateSelect/StateSelect';

import { InputStreamsTable } from './Table';

export function InputStreamsOverview() {
  const navigate = useNavigate();

  const tagFilters = useTagsFilterParams();
  const [queryFilters, setQueryFilters] =
    React.useState<UseStreamsFilterParams>({});
  const queryResult = useInputStreams(
    {
      ...tagFilters,
      ...queryFilters,
    },
    { keepPreviousData: true }
  );

  useMqttStreamsState(
    queryResult.data?.data ? queryResult.data.data.map(({ id }) => id) : []
  );

  const [searchFilters, setSearchFilters] =
    React.useState<UseStreamsFilterParams>({ page: 1 });
  const searchQueryResult = useStreams(
    ['input-streams-suggestions'],
    {
      ...tagFilters,
      ...searchFilters,
      limit: 20,
      stream_types: [StreamType.RTSP, StreamType.WEBRTC],
      sources: ['uri_stream'],
    },
    {
      enabled: Object.keys(searchFilters).length > 0,
      keepPreviousData: false,
    }
  );

  const handleSearchInput = React.useCallback((value?: string) => {
    if (!value) {
      setSearchFilters({});
      setQueryFilters({ page: 1 });
      return;
    }

    setSearchFilters({
      stream_names: [value],
    });
  }, []);

  function handleApplyFilters() {
    setQueryFilters({ ...searchFilters, page: 1 });
  }

  function handleSuggestionSelect({ id }: Stream) {
    navigate(id);
  }

  const handleGatewayFilterChange = React.useCallback(
    (gateway: Gateway | null) => {
      setQueryFilters((previousFilters) => ({
        ...previousFilters,
        gateway_ids: gateway ? [gateway.id] : undefined,
        page: 1,
      }));
      setSearchFilters((previousFilters) => ({
        ...previousFilters,
        gateway_ids: gateway ? [gateway.id] : undefined,
      }));
    },
    []
  );

  function handleStateFilterChange(state: string | null) {
    setQueryFilters((previousFilters) => ({
      ...previousFilters,
      status: state ? [state as StreamState] : undefined,
      page: 1,
    }));

    setSearchFilters((previousFilters) => ({
      ...previousFilters,
      status: state ? [state as StreamState] : undefined,
    }));
  }

  const hasFilters =
    Object.keys(queryFilters).length > 0 ||
    Object.values(tagFilters).some(Boolean);

  return (
    <PageLayout.Root>
      <QuerySearch
        entityName="stream"
        searchLabel="Search input streams..."
        searchResultField="name"
        searchQueryResult={searchQueryResult}
        onApplyFilters={handleApplyFilters}
        onSuggestionSelect={handleSuggestionSelect}
        onValueChange={handleSearchInput}
        totalElements={queryResult.data?.total_elements}
        filters={
          <>
            <StateSelect
              onChange={handleStateFilterChange}
              options={STREAM_STATES}
              size="small"
            />
            <GatewaySelect
              onChange={handleGatewayFilterChange}
              size="small"
              isClearable
            />
          </>
        }
      />

      <InputStreamsTable
        queryResult={queryResult}
        pageSize={50}
        page={queryFilters.page}
        onPageChange={(page) =>
          setQueryFilters((previous) => ({ ...previous, page }))
        }
        hasFilters={hasFilters}
      />
    </PageLayout.Root>
  );
}
