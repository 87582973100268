import React, { CSSProperties } from 'react';
import classNames from 'classnames/bind';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';

import { ScrollArea } from 'components/ScrollArea/ScrollArea';

import {
  ContextMenuSeparator,
  ContextMenuSeparatorProps,
} from './ContextMenuSeparator';
import { ContextMenuLabel, ContextMenuLabelProps } from './ContextMenuLabel';
import { ContextMenuAction, ContextMenuActionProps } from './ContextMenuAction';
import { ContextMenuGroup, ContextMenuGroupProps } from './ContextMenuGroup';
import { ContextMenuOption, ContextMenuOptionProps } from './ContextMenuOption';
import {
  ContextMenuOptionGroup,
  ContextMenuOptionGroupProps,
} from './ContextMenuOptionGroup';

import styles from './ContextMenu.module.scss';

const c = classNames.bind(styles);

export type ContextMenuChild =
  | React.ReactElement<
      | ContextMenuSeparatorProps
      | ContextMenuLabelProps
      | ContextMenuActionProps
      | ContextMenuOptionProps
      | ContextMenuOptionGroupProps
      | ContextMenuGroupProps
    >
  | undefined
  | null
  | false;

export type ContextMenuProps = {
  align?: RadixDropdownMenu.DropdownMenuContentProps['align'];
  side?: RadixDropdownMenu.DropdownMenuContentProps['side'];
  trigger: React.ReactElement;
  children: ContextMenuChild | ContextMenuChild[];
  width?: CSSProperties['width'];
};

export type ContextMenuStateProps = {
  open: boolean;
  setOpen: (nextOpen: boolean) => void;
};

const ContextMenuState = React.createContext<ContextMenuStateProps | undefined>(
  undefined
);

export function useContextMenuState() {
  const context = React.useContext(ContextMenuState);

  if (context === undefined) {
    throw new Error(
      'Hook `useContextMenuState` can only be used within a `ContextMenu` component.'
    );
  }

  return context;
}

const MAX_HEIGHT_REM = 25;

function pxToRem(px: number) {
  const bodyFontSize = getComputedStyle(document.documentElement).fontSize;
  return px / parseFloat(bodyFontSize);
}

/** @deprecated Use Mantine's Menu component instead. */
export function ContextMenu({
  trigger,
  children,
  align = 'end',
  side,
  width,
  ...rest
}: ContextMenuProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const resizeMenu = React.useCallback(() => {
    window.setTimeout(() => {
      const el = ref.current;

      if (!el) {
        return;
      }

      const rect = el.getBoundingClientRect();
      const padding = 8;
      const availableSpace = window.innerHeight - rect.top - padding;
      const availableSpaceRem = pxToRem(availableSpace);

      el.style.setProperty(
        '--max-height',
        `${Math.min(availableSpaceRem, MAX_HEIGHT_REM)}rem`
      );
    });
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      resizeMenu();
    }
  }, [isOpen, resizeMenu]);

  React.useEffect(() => {
    let timeout: number;

    function resizeListener() {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(resizeMenu, 400);
    }

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [resizeMenu]);

  function handleCloseAutoFocus(event: Event) {
    event.preventDefault();
  }

  return (
    <ContextMenuState.Provider value={{ open: isOpen, setOpen: setIsOpen }}>
      <RadixDropdownMenu.Root {...rest} open={isOpen} onOpenChange={setIsOpen}>
        <RadixDropdownMenu.Trigger asChild>{trigger}</RadixDropdownMenu.Trigger>

        <RadixDropdownMenu.Portal>
          <RadixDropdownMenu.Content
            className={c('menu')}
            onCloseAutoFocus={handleCloseAutoFocus}
            sideOffset={8}
            align={align}
            side={side}
            style={{ width }}
            loop
            ref={ref}
          >
            <ScrollArea className={c('content')}>{children}</ScrollArea>
          </RadixDropdownMenu.Content>
        </RadixDropdownMenu.Portal>
      </RadixDropdownMenu.Root>
    </ContextMenuState.Provider>
  );
}

export {
  ContextMenuGroup,
  ContextMenuSeparator,
  ContextMenuLabel,
  ContextMenuOption,
  ContextMenuOptionGroup,
  ContextMenuAction,
};
