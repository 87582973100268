import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames/bind';

import { postAuthAPI } from 'services/api_auth';

import { useAuthentication } from 'hooks/api/useAuthentication';
import { useHashParams } from 'hooks/useHashParams';

import { Button } from 'components/Button/Button';
import { Field } from 'components/Field/Field';
import {
  FormErrorMessage,
  FormMessage,
} from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Loader } from 'components/Loader/Loader';
import { LogoMark } from 'components/Logo/LogoMark';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';

import styles from './Auth.module.scss';

const c = classNames.bind(styles);

type ResetPasswordParams = {
  password: string;
};

const EXPIRED_ERROR = 'This link has expired.';
const INVALID_ERROR = 'This link is invalid.';

export function AuthResetPassword() {
  const auth = useAuthentication();
  const hashParams = useHashParams();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ResetPasswordParams>();

  const [isLoading, setIsLoading] = React.useState(true);
  const { mutateAsync: checkToken } = useMutation((token: string) =>
    postAuthAPI(
      '/internal/auth/check_email_token',
      { token },
      { credentials: 'omit' }
    )
  );

  const [tokenError, setTokenError] = React.useState<string>();

  React.useEffect(() => {
    async function verifyToken() {
      if ('token' in hashParams) {
        try {
          await checkToken(hashParams.token);
        } catch (error) {
          setTokenError(EXPIRED_ERROR);
        } finally {
          setIsLoading(false);
        }
      } else {
        setTokenError(INVALID_ERROR);
        setIsLoading(false);
      }
    }

    verifyToken();
  }, [hashParams, checkToken]);

  if (isLoading) {
    return <Loader text="Verifying token..." />;
  }

  function onSubmit({ password }: ResetPasswordParams) {
    if ('setPassword' in auth && 'token' in hashParams) {
      auth.setPassword({ password, token: hashParams.token });
    }
  }

  return (
    <div className={c('wrap')}>
      <div className={c('card', 'auth')}>
        <div className="card-content">
          <LogoMark className={c('logo')} aria-label="Lumeo logo." />

          <div className={c('auth-text')}>
            <Heading level="1">Reset password</Heading>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {tokenError && (
              <FormMessage intent="danger">
                <strong>{tokenError}</strong>
                <p>
                  <Link className={c('link')} to="/forgot-password">
                    Click here
                  </Link>{' '}
                  to request a new link to reset your password.
                </p>
              </FormMessage>
            )}

            <Field
              id="password"
              label="Choose a new password"
              error={errors.password}
            >
              <PasswordInput
                id="password"
                {...register('password', { required: 'Enter your password.' })}
              />
            </Field>

            <FormErrorMessage error={auth.setPasswordError} />

            <Button
              className={c('auth-button')}
              type="submit"
              variant="primary"
              loading={auth.isSettingPassword}
            >
              Set password
            </Button>
          </form>

          <div className={c('auth-text-secondary')}>
            <Link to="/login" className="link">
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
